import ACTION from '../actionTypes';

const initialState = {
  data: [],
  form: {
    agencia: null,
    comunicacao: null,
    dtChegada: null,
    dtChegadaTransporte: null,
    dtCriacao: null,
    dtDespacho: null,
    dtEmRota: null,
    dtEncerramento: null,
    dtTransporte: null,
    endereco: null,
    finalizacoes: null,
    grupo: null,
    ocorrencia: null,
    ocorrenciaCronologiaList: [],
    subTipoCodigo: null,
    subTipoDesc: null,
    tipoCodigo: null,
    tipoDesc: null,
    viaturasAtribuidas: null,
    latLng: null,
  },
  loading: false,
  error: null,
  totalElements: 0,
};

export default function ocorrencia(state = initialState, action) {
  switch (action.type) {
    case ACTION.OCORRENCIA_LIST_REQUEST:
    case ACTION.OCORRENCIA_GET_ID_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ACTION.OCORRENCIA_LIST_SUCCESS: {
      const { content, totalElements } = action.data;
      return {
        ...state,
        loading: false,
        error: null,
        data: (content || []).map(row => ({ ...row, key: row.ocorrencia })),
        totalElements,
      };
    }
    case ACTION.OCORRENCIA_GET_ID_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: null,
        form: action.data,
      };
    }
    case ACTION.OCORRENCIA_ERROR:
      return {
        ...state,
        data: [],
        loading: false,
        form: {
          agencia: null,
          comunicacao: null,
          dtChegada: null,
          dtChegadaTransporte: null,
          dtCriacao: null,
          dtDespacho: null,
          dtEmRota: null,
          dtEncerramento: null,
          dtTransporte: null,
          endereco: null,
          finalizacoes: null,
          grupo: null,
          ocorrencia: null,
          ocorrenciaCronologiaList: [],
          subTipoCodigo: null,
          subTipoDesc: null,
          tipoCodigo: null,
          tipoDesc: null,
          viaturasAtribuidas: null,
        },
        error: action.message,
      };
    default:
      return state;
  }
}
