import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import logo64 from '../../../imgs/encoded/brasaosspds.base64';

let contents = [];

export function printData(results) {
  contents = results;
}

export const pdfGen = () => {
  var win = window.open('', '_blank');
  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  const reportTitle = [
    {
      image: logo64,
      width: 179,
      height: 63,
      alignment: 'center',
      margin: [0, 10, 0, 0],
    },
    {
      text: 'Relatório Histórico Raio. \n',
      bold: true,
      fontSize: 13,
      alignment: 'center',
      margin: [0, 4, 0, 0],
    },
    {
      text: 'Dados de posicionamento fornecidos pelas empresas Oi/CSBrasil\n',
      bold: false,
      fontSize: 9,
      alignment: 'center',
      margin: [0, 4, 0, 0],
    },
  ];

  const results = contents.map(index => {
    return [
      { text: index.placa, fontSize: 9, margin: [0, 2, 0, 2] },
      { text: index.position.x, fontSize: 9, margin: [0, 2, 0, 2] },
      { text: index.position.y, fontSize: 9, margin: [0, 2, 0, 2] },
      {
        text: index.engineOn ? 'Ligado' : 'Desligado',
        fontSize: 9,
        margin: [0, 2, 0, 2],
      },
      { text: index.speed + 'Km/h', fontSize: 9, margin: [0, 2, 0, 2] },
      { text: `${new Date(
        index.datePosition
      ).toLocaleDateString()} ${new Date(
        index.datePosition
      ).toLocaleTimeString()}`, fontSize: 9, margin: [0, 2, 0, 2] }
    ];
  });

  const details = [
    {
      alignment: 'center',
      margin: [2, 0, 0, 0],
      layout: {
        fillColor: function(rowIndex, node, columnIndex) {
          return rowIndex % 2 === 0 ? '#CCCCCC' : null;
        },
      },
      table: {
        headerRows: 1,
        widths: ['auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
        body: [
          [
            { text: 'Placa/Prefixo', style: 'tableHeader', fontSize: 10 },
            { text: 'Latitude', style: 'tableHeader', fontSize: 10 },
            { text: 'Longitude', style: 'tableHeader', fontSize: 10 },
            { text: 'Estado motor', style: 'tableHeader', fontSize: 10 },
            { text: 'Velocidade', style: 'tableHeader', fontSize: 10 },
            { text: 'Data posição', style: 'tableHeader', fontSize: 10 },
            //{ text: 'Logradouro', style: 'tableHeader', fontSize: 10 },
          ],
          ...results,
        ],
      },
    },
  ];
  const footer = [
    {
      text: [
        {
          text:
            'Secretaria de Segurança Pública e Defesa Social. Avenida Bezerra de Menezes, 581 - São Gerardo\n CEP 60325-003 . Fortaleza Ceará . Fone: (85) 3101-6501',
        },
      ],
      fontSize: 8,
      margin: [0, 10, 0, 45],
      alignment: 'center',
    },
  ];
  const docDefinitions = {
    pageMargins: [40, 140, 40, 40],
    pageSize: 'A4',
    alignment: 'center',
    header: [reportTitle],
    content: [details],
    footer: [footer],
  };
  pdfMake.createPdf(docDefinitions).open({}, win);
};

export default (printData(), pdfGen);
