import { TrackingHistoryContext } from 'context/tracking_history';
import { useContextSelector } from 'use-context-selector';

export function useHistoryMap() {
  const map = useContextSelector(
    TrackingHistoryContext,
    context => context.map
  );

  const setMap = useContextSelector(
    TrackingHistoryContext,
    context => context.setMap
  );

  return {
    map,
    setMap,
  };
}

