import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useJsApiLoader } from '@react-google-maps/api';
import { useDispatch } from 'react-redux';
import styles from './styles.module.css';
import Filters from './filtro';
import { ErrorLoadingMap } from './error';

import Panel from './panel';
import * as ACTIONS from 'store/modules/monitoring/actions';
import ENV from 'config';
import Spinner from '../ui/spinner';
import MapLegend from './legend';
import { GenerateHistoryReport } from './reports/generate_history_report';
import { AlertModal } from './modals/alertModal';
import { WrapperHistoryMap } from './history_map/wrapper_mapa';
import { WrapperMonitoringMap } from './monitoring_map/wrapper_mapa';

import { AddressProps } from 'interfaces/vehicle';
import { useFiltersMapSelectedAis } from 'hooks/filtersMap/useFiltersMapSelectedAis';
import { googleLibraries } from 'utils/functionsUtils';

export default function ContainerMapa() {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: ENV.googleMapsApiKey,
    libraries: googleLibraries,
    region: 'BR',
    language: 'pt-br',
  });

  const location = useLocation();

  const { selectedAis: objRoleAis } = useFiltersMapSelectedAis();

  const dispatch = useDispatch();

  const [searchAddress, setSearchAddress] = useState<AddressProps>({
    show: true,
    address: {
      id: '',
      bairro: '',
      logradouro: '',
      numero: '',
      municipio: '',
      latitude: null,
      longitude: null,
    },
  });
  const [collapsed, setCollapsed] = useState(true);

  const [isOpenModalAlerts, setIsOpenModalAlerts] = useState(false);
  const [isDrawing, setIsDrawing] = useState(false);
  const [filtersVisible, setFiltersVisible] = useState(false);
  const [isErasing, setIsErasing] = useState(false);

  const clickedAlerts = useRef<string[]>([]);

  const handleSetSearchAddress = useCallback((newProperties: any) => {
    setSearchAddress(prev => ({ ...prev, ...newProperties }));
  }, []);

  useEffect(() => {
    dispatch(ACTIONS.getAllAisRequest());

    dispatch(ACTIONS.getAllVinRequest());
  }, [dispatch]);

  const alternaAis = useCallback(
    (ais: { id: string; aisNumber: number }) => {
      try {
        const _numAis = ais.id ? ais.id.split('-')[1] : ais.aisNumber;

        let _txtAis = _numAis;

        if (ais.id && Number(_txtAis) < 10) {
          _txtAis = `0${_txtAis}`;
        }

        if (objRoleAis[_txtAis] !== undefined) {
          objRoleAis[_txtAis] = !objRoleAis[_txtAis];
        }
      } catch (e) {
        //
      }
    },
    [objRoleAis]
  );

  const renderMap = useCallback(() => {
    if (location.pathname === '/mapa/monitoramento') {
      return (
        <WrapperMonitoringMap
          searchAddress={searchAddress}
          setSearchAddress={handleSetSearchAddress}
          collapsed={collapsed}
          setIsOpenModalAlerts={setIsOpenModalAlerts}
        />
      );
    }
    if (location.pathname === '/mapa/historico') {
      return (
        <>
          <WrapperHistoryMap
            searchAddress={searchAddress}
            setSearchAddress={handleSetSearchAddress}
            collapsed={collapsed}
            isDrawing={isDrawing}
            setIsDrawing={setIsDrawing}
            setFiltersVisible={setFiltersVisible}
            isErasing={isErasing}
            setIsErasing={setIsErasing}
          />
          <GenerateHistoryReport />
        </>
      );
    }

    return <ErrorLoadingMap />;
  }, [
    collapsed,
    handleSetSearchAddress,
    location.pathname,
    searchAddress,
    isDrawing,
    isErasing,
  ]);

  return (
    <>
      <div className={styles.slotMapaRotas}>
        {isLoaded ? renderMap() : <ErrorLoadingMap />}
        <Filters
          alternaAis={alternaAis}
          setIsDrawing={setIsDrawing}
          filtersVisible={filtersVisible}
          setFiltersVisible={setFiltersVisible}
          setIsErasing={setIsErasing}
        />

        <MapLegend />
      </div>

      <Panel
        clickedAlerts={clickedAlerts}
        collapsed={collapsed}
        setCollapsed={setCollapsed}
      />

      <AlertModal
        isOpenModalAlerts={isOpenModalAlerts}
        setIsOpenModalAlerts={setIsOpenModalAlerts}
      />

      <Spinner />
    </>
  );
}
