import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import ENV from 'config';
import {
  DatePicker,
  Card,
  Input,
  Form,
  Spin,
  Divider,
  Typography,
  Button,
  Tooltip,
  Modal,
  Select,
  Switch,
  Breadcrumb,
  Radio,
  Row,
  Col,
  Popconfirm,
} from 'antd';
import {
  CloudDownloadOutlined,
  LinkOutlined,
  SaveOutlined,
  ShareAltOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import { toast } from 'react-toastify';
import { Link, useHistory, useParams } from 'react-router-dom';
import style from './styles.module.css';
import { PageHeader } from '../../../components/PageHeader';
import moment from 'moment';
import PontoBaseCerca from '../../../imgs/ponto_base_cerca.svg';

import {
  GoogleMap,
  LoadScriptProps,
  useJsApiLoader,
  Polyline,
  Polygon,
} from '@react-google-maps/api';
import { ServiceCerca } from '../../../services/modulos/serviceCerca';

import dadosLatLngAis from '../../../dados/ais/dadosAis.json';
import { rgba } from 'polished';
import {
  isValidCPF,
  microregioesCEFormatted,
  bairrosCEFormatted,
  municipiosCEFormatted,
  allAis,
  googleLibraries,
} from '../../../utils/functionsUtils';
import { useAuthData } from 'hooks/auth/useAuthData';
import { useGlobalFences } from 'hooks/global/useGlobalFences';
import { useGlobalVehicles } from 'hooks/global/useGlobalVehicles';
import { useGlobalLoadingRequest } from 'hooks/global/useLoadingRequest';
import { useGlobalLoadFences } from 'hooks/global/useGlobalLoadFences';
import { BasisPoints, Fence, LinkedDevices } from 'interfaces/fences';
import {
  BairrosCEFormatted,
  Coordinates,
  MicrorregioesCEFormatted,
  MunicipiosCEFormatted,
} from 'interfaces/functionsUtils';
import {
  ButtonsContainer,
  Container,
  ModalBody,
  ModalBodyT,
  ModalTitle,
  ShareContainer,
} from './styles';

interface MarkersProps {
  marker: any;
  coords: any;
}

interface DataFenceprops {
  name: string;
  paths: Coordinates[];
}

interface FencesProps {
  data: DataFenceprops[];
  loading: boolean;
}

interface VehicleAlreadylinkedProps {
  plate: string;
  prefix: string;
  fences: string[];
}

const { Text } = Typography;
const { RangePicker } = DatePicker;
const { TextArea } = Input;

const defaultCenter = {
  lat: -3.7332465960156123,
  lng: -38.55049563789216,
};

const containerStyle = {
  width: '100%',
  height: '100%',
  borderRadius: 12,
};

export function CriarCercaEletronica() {
  const { id } = useParams() as any;
  const { data: dataAuth } = useAuthData();
  const { isFenceAdmin, roles, client: clientAuth } = dataAuth;

  const { loadFences } = useGlobalLoadFences();
  const { loadingRequest } = useGlobalLoadingRequest();
  const { vehicles } = useGlobalVehicles();
  const { fences: AllFences } = useGlobalFences();

  const history = useHistory();
  const [form] = Form.useForm();

  const [map, setMap] = useState<google.maps.Map>();
  const [mapModal, setMapModal] = useState<google.maps.Map>();
  const [timeRange, setTimeRange] = useState(0);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isLoadTemplateModalVisible, setIsLoadTemplateModalVisible] = useState(
    false
  );
  const [isLinkedModalVisible, setIsLinkedModalVisible] = useState(false);
  const [
    isDuplicatedVehicleModalVisible,
    setIsDuplicatedVehicleModalVisible,
  ] = useState(false);
  const [centerMap, setCenterMap] = useState<
    google.maps.LatLng | null | google.maps.LatLngLiteral
  >();
  const [centerMapModal, setCenterMapModal] = useState<
    google.maps.LatLng | null | google.maps.LatLngLiteral
  >();
  const [alloweds, setAlloweds] = useState([
    {
      ...dataAuth.client,
      id: dataAuth.client ? dataAuth.client.clientId : '',
    },
  ]);
  const [polygonPoints, setPolygonPoints] = useState<Coordinates[]>(
    [] as Coordinates[]
  );

  const [active, setActive] = useState(false);
  const [vehicleAlreadylinked, setVehicleAlreadylinked] = useState<
    VehicleAlreadylinkedProps
  >({
    plate: '',
    prefix: '',
    fences: [],
  });
  // const [loadingVehicles, setLoadingVehicles] = useState(false);
  const [fences, setFences] = useState<FencesProps>({
    data: [],
    loading: false,
  });
  const [showFences, setShowFences] = useState(false);

  const [polygonTemplates, setPolygonTemplates] = useState<
    BairrosCEFormatted[] | MunicipiosCEFormatted[] | MicrorregioesCEFormatted[]
  >([]);
  const [polygonTemplateSelected, setPolygonTemplateSelected] = useState<
    Coordinates[]
  >([] as Coordinates[]);
  const [editFence, setEditFence] = useState(false);

  const drawingManager = useRef<google.maps.drawing.DrawingManager>();
  const currentPolygon = useRef<google.maps.Polygon>();
  const currentMarkers = useRef<MarkersProps[]>([{} as MarkersProps]);
  const isAuthor = useRef<boolean>(false);

  const defaultMarkers = useRef<BasisPoints[]>([]);
  const linkedVehicles = useRef<LinkedDevices[]>([]);
  const allFencesPolygons = useRef<google.maps.Polygon[]>([]);
  const isLoadingTemplate = useRef(false);

  const updateFences = useCallback((newProperties: any) => {
    setFences(prev => ({ ...prev, ...newProperties }));
  }, []);

  const [state, setState] = useState({
    loading: false,
    entidade: {
      fenceName: '',
      description: '',
      startDate: '',
      endDate: '',
      period: [],
    },
  });

  const { loading } = state;

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: ENV.googleMapsApiKey,
    libraries: googleLibraries,
    region: 'BR',
    language: 'pt-br',
  });

  const onMapLoad = (mapa: google.maps.Map) => {
    setMap(mapa);
  };

  const onMapModalLoad = (mapa: google.maps.Map) => {
    setMapModal(mapa);
  };

  const updateState = useCallback((newProperties: any) => {
    setState(prev => ({ ...prev, ...newProperties }));
  }, []);

  const paths = (targets: any) => {
    return targets.map((target: any) => ({
      lat: target.y,
      lng: target.x,
    }));
  };

  const handleCloseModal = () => {
    Modal.destroyAll();
    setIsModalVisible(false);
    setIsLinkedModalVisible(false);
    setIsLoadTemplateModalVisible(false);
  };

  const formatTags = useCallback(
    (newValues?: any) => {
      let formattedTags = [];
      if (newValues) {
        formattedTags = newValues.map((tag: any) =>
          tag.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
        );
      } else if (alloweds.length > 0) {
        formattedTags = alloweds.map(
          tag =>
            tag.cpf &&
            tag?.cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
        );
      }

      return formattedTags;
    },
    [alloweds]
  );

  const loadFencesByPlate = useCallback(async (plate: string) => {
    try {
      return ServiceCerca.getCercaByPlate(plate);
    } catch (e) {}
  }, []);

  const handleSelectLinkeds = async (value: any) => {
    try {
      let [placa, prefixo, agencia] = value.split(`_`);

      if (placa === 'null') throw new Error();
      if (prefixo === 'null') prefixo = null;
      if (agencia === 'null') agencia = null;

      const newlinked = {
        placa,
        prefixo,
        agencia,
      };

      const associatedFences = await loadFencesByPlate(placa);

      if (
        linkedVehicles.current.filter(v => v.placa === newlinked.placa).length >
        0
      ) {
        toast.info('Viatura já está presente na lista');
      } else if (associatedFences && associatedFences.length > 0) {
        setVehicleAlreadylinked({
          plate: placa,
          prefix: prefixo,
          fences: associatedFences,
        });
        setIsDuplicatedVehicleModalVisible(true);
      } else {
        linkedVehicles.current.push(newlinked);
      }
    } catch (e) {
      toast.error('Erro ao selecionar viatura.');
    } finally {
      form.setFieldsValue({
        linkedDevices: linkedVehicles.current.map(
          v => `${v.placa} ${v.prefixo ? '- ' + v.prefixo : ''}`
        ),
      });
    }
  };

  const handleDeselectLinkeds = useCallback(
    async (value: any) => {
      try {
        let placa: string;

        if (value.split('-').length > 1) {
          placa = value.split('-')[0];
        } else {
          placa = value.split(`_`)[0];
        }

        if (placa === 'null') throw new Error();

        linkedVehicles.current = linkedVehicles.current.filter(
          v => v.placa.trim() !== placa.trim()
        );
      } catch (e) {
        toast.error('Erro ao remover viatura.');
      }
    },
    [linkedVehicles.current]
  );

  const handleDeselectAlloweds = (cpf: string) => {
    try {
      if (cpf) {
        cpf = cpf.replace(/[^0-9 ]/g, '');
        setAlloweds(prev => {
          return prev.filter(p => p.cpf !== cpf);
        });
      }
    } catch (e) {
      //
    }
  };

  const handleSelectAlloweds: (cpf: string) => Promise<void> = async (
    cpf: string
  ) => {
    const currentCpfs = alloweds.map(a => a.cpf);
    try {
      if (cpf) {
        cpf = cpf.replace(/[^0-9 ]/g, '');
        if (!isValidCPF(cpf)) {
          throw new Error();
        }
        const newAllowed = await ServiceCerca.searchByCPF(cpf);

        form.setFieldsValue({
          allowedCpfs: formatTags([...currentCpfs, newAllowed.cpf]),
        });

        setAlloweds(prev => [...prev, newAllowed]);
      }
    } catch (e) {
      toast.error('CPF inválido!');
      form.setFieldsValue({
        allowedCpfs: formatTags(currentCpfs),
      });
    }
  };

  const onPolygonComplete = useCallback(
    async (polygon: google.maps.Polygon) => {
      let coords: { lat: number; lng: number }[] = [];
      currentMarkers.current.map(m => m?.marker?.setMap(null));
      currentMarkers.current = [];
      const currentPoints = await polygon.getPath().getArray();
      if (currentPoints != undefined) {
        coords = currentPoints.map((coord: any) => {
          return {
            lng: coord.lng(),
            lat: coord.lat(),
          };
        });
      }
      setPolygonPoints(coords);
    },
    []
  );

  const mapOptions: google.maps.MapOptions = {
    center:
      editFence && polygonPoints.length
        ? null
        : centerMap
        ? centerMap
        : map
        ? map.getCenter()
        : defaultCenter,
    zoom: map ? map.getZoom() : 12,
    // styles: [
    //   {
    //     elementType: 'labels',
    //     featureType: 'poi',
    //     stylers: [{ visibility: 'on' }],
    //   },
    // ],
    mapId: ENV.mapId,
    panControl: true,
    panControlOptions: {
      position: 4,
    },
    mapTypeControl: true,
    mapTypeControlOptions: {
      style: window.google?.maps?.MapTypeControlStyle?.DROPDOWN_MENU,
      position: window.google?.maps?.ControlPosition?.TOP_LEFT,
    },
    fullscreenControl: false,
    zoomControl: true,
    streetViewControl: true,
  };

  const mapModalOptions = {
    center: centerMapModal
      ? centerMapModal
      : mapModal
      ? mapModal.getCenter()
      : defaultCenter,
    zoom: map ? map.getZoom() : 12,
    styles: [
      {
        elementType: 'labels',
        featureType: 'poi',
        stylers: [{ visibility: 'on' }],
      },
    ],
    panControl: false,
    mapTypeControl: false,
    fullscreenControl: false,
    zoomControl: true,
    streetViewControl: false,
  };

  const handleChangeDatePicker = (value: any) => {
    if (value && value.length === 2) {
      const today = moment(new Date());

      let tr = 0;

      if (value[1] !== null) {
        if (!today.isBetween(value[0], value[1])) {
          setActive(false);
        }

        tr = value[1].diff(value[0], 'days') + 1;

        if (tr == 199999999) {
          tr = 0;
        }
      } else if (today.isBefore(value[0])) {
        setActive(false);
      }
      setTimeRange(tr);
    }
  };

  const handleChangeActive = (value: any) => {
    if (value) {
      const today = moment(new Date());
      const fence = form.getFieldsValue();

      if (
        fence.period &&
        fence.period.length === 2 &&
        ((fence.period[1] &&
          today.isBetween(fence.period[0], fence.period[1])) ||
          today.isSameOrAfter(fence.period[0]))
      ) {
        setActive(true);
      } else {
        toast.info('Para ativar a cerca, escolha uma data válida!');
      }
    } else {
      setActive(false);
    }
  };

  const handleCloseDuplicatedVehicleModal = () => {
    setIsDuplicatedVehicleModalVisible(false);
  };

  const insidePolygon = useCallback(
    (
      pointsPolygon: { lat: number; lng: number }[],
      lat: number,
      lng: number
    ) => {
      let inside = false;

      let formattedPointsPolygon: any = pointsPolygon;

      if (id || isLoadingTemplate.current) {
        formattedPointsPolygon = pointsPolygon.map((p: any) => [p.lng, p.lat]);
      }

      for (
        let i = 0, j = formattedPointsPolygon.length - 1;
        i < formattedPointsPolygon.length;
        j = i++
      ) {
        const latI = formattedPointsPolygon[i][1];
        const lngI = formattedPointsPolygon[i][0];
        const latJ = formattedPointsPolygon[j][1];
        const lngJ = formattedPointsPolygon[j][0];
        const interception =
          lngI > lng !== lngJ > lng &&
          lat < ((latJ - latI) * (lng - lngI)) / (lngJ - lngI) + latI;

        if (interception) {
          inside = !inside;
        }
      }
      return inside;
    },
    []
  );

  const handleAddMarker = useCallback(
    (marker: any) => {
      if (!polygonPoints || polygonPoints.length === 0) {
        marker.setMap(null);
        toast.info('Informe a cerca!');
        return;
      }

      const inside = insidePolygon(
        polygonPoints,
        marker.getPosition().lat(),
        marker.getPosition().lng()
      );

      if (!inside) {
        marker.setMap(null);
        toast.info('O ponto precisa estar dentro da cerca!');
        return;
      }

      const newMarker = {
        marker,
        coords: [marker.getPosition().lng(), marker.getPosition().lat()],
      };

      currentMarkers.current.push(newMarker);

      // new window.google.maps.event.addListener(marker, 'click', function() {
      //   marker.setMap(null);
      // });
      window.google.maps.event.addListener(marker, 'click', function() {
        marker.setMap(null);
      });
    },
    [polygonPoints]
  );

  const loadFence = useCallback(async () => {
    try {
      if (id) {
        updateState({ loading: true });
        let params: Fence = {} as Fence;
        if (isFenceAdmin) {
          params = await ServiceCerca.getCercaAdminById({ id });
        } else {
          params = await ServiceCerca.getCercaById({ id });
        }

        const formattedPoints = paths(params.geom?.points);

        if (window.google) {
          let bounds = new window.google.maps.LatLngBounds();

          formattedPoints.map((point: any) => {
            bounds.extend(point);
          });
          setCenterMap(bounds.getCenter());
        }

        setAlloweds(params.alloweds);
        setPolygonPoints(formattedPoints);
        setActive(params.ativo);

        defaultMarkers.current = params.pontosBase;
        linkedVehicles.current = params.veiculosAssociados;

        if (Number(params.autor || '') === clientAuth.clientId) {
          isAuthor.current = true;
        }

        // params.dataInicial[1] = params.dataInicial[1] - 1;
        const dataInicialFormatted = moment(params.dataInicial);
        let dataFinalFormatted = null;

        if (params.dataFinal) {
          // params.dataFinal[1] = params.dataFinal[1] - 1;
          dataFinalFormatted = moment(params.dataFinal);
        }

        const period = [dataInicialFormatted, dataFinalFormatted];

        const fence = {
          fenceName: params.nome,
          description: params?.descricao,
          active: params.ativo,
          startDate: params.dataInicial,
          endDate: params.dataFinal,
          ais: params.grupos,
          period,
        };

        handleChangeDatePicker(fence.period);

        form.setFieldsValue(fence);
        updateState({ loading: false, entidade: fence });
      }
    } catch (error) {
      toast.error('Erro ao carregar informações.');
    }
  }, [clientAuth]);

  // const loadVehicles = useCallback(async () => {
  //   try {
  //     setLoadingVehicles(true);
  //     const veiculos = await getVeiculosServices.get();

  //     setVehicles(veiculos);
  //   } catch (e) {
  //     //
  //   } finally {
  //     setLoadingVehicles(false);
  //   }
  // }, []);

  const loadAllFences = useCallback(async () => {
    try {
      updateFences({
        loading: true,
      });

      const responseFiltered = AllFences.current.data.map(f => ({
        name: f.nome,
        paths: f.geom?.points.map((p: any) => ({ lat: p.y, lng: p.x })),
      }));

      updateFences({
        loading: false,
        data: responseFiltered,
      });
    } catch (e) {
      //
    }
  }, [JSON.stringify(AllFences.current.data)]);

  const handleChangeLocalTypeMapModal = useCallback(
    (type: 'bairros' | 'municipios' | 'microrregioes') => {
      const templates = {
        bairros: bairrosCEFormatted,
        municipios: municipiosCEFormatted,
        microrregioes: microregioesCEFormatted,
      };
      setPolygonTemplates(templates[type]);
    },
    []
  );

  const handleSelectPolygonTemplate = (e: any) => {
    polygonTemplates.map(pt => {
      if (pt.name === e) {
        let bounds = new window.google.maps.LatLngBounds();

        pt.geometry.coordinates.map((point: any) => {
          bounds.extend(point);
        });

        setCenterMapModal(bounds.getCenter());
        setPolygonTemplateSelected(pt.geometry.coordinates);
      }
    });
  };

  const handleConfirmSelectFenceTemplate = useCallback(() => {
    if (polygonTemplateSelected.length > 0) {
      isLoadingTemplate.current = true;
      setPolygonPoints(polygonTemplateSelected);
    }
    setIsLoadTemplateModalVisible(false);
  }, [polygonTemplateSelected]);

  const canEditVehicles = useMemo(() => {
    return roles?.includes('ROLE_ROTAS_EDIT_VEICULOS_CERCA');
  }, [roles]);

  const handleSubmit = useCallback(
    async (values: any) => {
      try {
        updateState({ loading: true });

        let finalPolygonPoints = polygonPoints;

        const {
          fenceName: nome,
          description: descricao,
          period: periodo,
          ais,
        } = values;

        if (!periodo || periodo.length !== 2 || !periodo[0]) {
          toast.error('Por favor informe a data inicial');
          return;
        }

        if (finalPolygonPoints.length == 0) {
          toast.error('Por favor informe a cerca');
          return;
        }

        if (id || isLoadingTemplate.current) {
          finalPolygonPoints = finalPolygonPoints.map(point => {
            return {
              lng: point.lng,
              lat: point.lat,
            };
          });
        } else {
          finalPolygonPoints.push(finalPolygonPoints[0]);
        }

        const dataInicial = moment(new Date(periodo[0])).format('YYYY-MM-DD');
        let dataFinal = '';

        if (periodo[1]) {
          dataFinal = moment(new Date(periodo[1])).format('YYYY-MM-DD');
        }

        const pontosBase = currentMarkers.current
          .filter(m => m.marker?.getMap())
          .map(m => m.coords);

        const finalPolygonPointsFormmated = finalPolygonPoints.map(point => [
          point.lng,
          point.lat,
        ]);

        const newFence: Fence = {
          nome,
          descricao,
          dataInicial,
          dataFinal,
          points: finalPolygonPointsFormmated,
          pontosBase,
          // @ts-ignore
          alloweds: alloweds.map(al => ({ id: al.id + '', cpf: al?.cpf + '' })),
          ativo: active,
          veiculosAssociados: linkedVehicles.current,
          grupos: ais,
        };

        if (id) {
          if (isFenceAdmin) {
            await ServiceCerca.updateAdminCerca(id, newFence);
          } else if (!isAuthor.current && canEditVehicles) {
            await ServiceCerca.updateVeiculosCerca(
              id,
              newFence.veiculosAssociados
            );
          } else {
            await ServiceCerca.updateCerca(id, newFence);
          }
        } else if (isFenceAdmin) {
          await ServiceCerca.createAdminCerca(newFence);
        } else {
          await ServiceCerca.createCerca(newFence);
        }

        toast.success(`Cerca ${id ? 'editada' : 'criada'} com sucesso!`);
        loadFences();
        history.push('/cerca');
      } catch (e) {
        toast.error(`Erro ao ${id ? 'editar' : 'criar'} cerca`);
        console.error(e);
      } finally {
        updateState({ loading: false });
      }
    },
    [
      history,
      alloweds,
      polygonPoints,
      currentMarkers,
      active,
      isFenceAdmin,
      linkedVehicles.current,
    ]
  );

  useEffect(() => {
    if (map) {
      if (drawingManager.current) {
        drawingManager.current?.setMap(null);
      }

      if (fences.data.length > 0) {
        if (showFences) {
          fences.data.map(f => {
            const fencePolygon = new window.google.maps.Polygon({
              map,
              paths: f.paths,
              strokeColor: 'purple',
              strokeOpacity: 1,
              strokeWeight: 1,
              fillColor: rgba(138, 43, 226, 0.5),
            });

            let bounds = new window.google.maps.LatLngBounds();
            f.paths.map(point => {
              bounds.extend(point);
            });

            const toolTip = new window.google.maps.InfoWindow({
              content: `<div class="labelFences"><b>${f.name}</b></div>`,
              maxWidth: 150,
              //@ts-ignore
              maxHeight: 100,
              zIndex: 999,
              disableAutoPan: true,
            });

            fencePolygon.addListener('mouseover', () => {
              toolTip.setPosition(bounds.getCenter());
              //@ts-ignore
              toolTip.setOptions({ shouldFocus: true });
              toolTip.open(map);
            });

            fencePolygon.addListener('mouseout', () => {
              toolTip.close();
            });
            allFencesPolygons.current.push(fencePolygon);
          });
        } else {
          allFencesPolygons.current.map(f => f.setMap(null));
        }
      }

      const newDrawingModes = [window.google?.maps.drawing.OverlayType.MARKER];

      if (!id) {
        newDrawingModes.push(window.google?.maps.drawing?.OverlayType.POLYGON);
      }

      let drawingManagerAux = new window.google.maps.drawing.DrawingManager({
        drawingControl: true,
        drawingControlOptions: {
          position: window.google?.maps.ControlPosition.TOP_RIGHT,
          drawingModes: newDrawingModes,
        },
        markerOptions: {
          draggable: false,
          icon: {
            url: PontoBaseCerca,
            strokeWeight: 0,
            scaledSize: new window.google.maps.Size(22, 25),
          },
        },
      });

      drawingManagerAux.setMap(map);

      window.google.maps.event.addListener(
        drawingManagerAux,
        'polygoncomplete',
        function(polygon: any) {
          isLoadingTemplate.current = false;
          onPolygonComplete(polygon);
          if (currentPolygon.current) {
            currentPolygon.current?.setMap(null);
          }
          currentPolygon.current = polygon;
        }
      );

      window.google.maps.event.addListener(
        drawingManagerAux,
        'markercomplete',
        (marker: google.maps.marker.AdvancedMarkerElement) =>
          handleAddMarker(marker)
      );
      drawingManager.current = drawingManagerAux;

      if (id || isLoadingTemplate.current) {
        if (currentPolygon.current) {
          currentPolygon.current.setMap(null);
        }
        const fencePolygon = new window.google.maps.Polygon({
          map,
          //@ts-ignore
          path: polygonPoints, // obs 21/09: change path to paths
          editable: editFence,
        });
        currentPolygon.current = fencePolygon;

        let currentPath = fencePolygon.getPath();

        window.google.maps.event.addListener(currentPath, 'set_at', () => {
          const newPoints = fencePolygon
            ?.getPath()
            ?.getArray()
            .map(point => ({
              lat: point.lat(),
              lng: point.lng(),
            }));

          if (newPoints[0].lat !== newPoints[newPoints.length - 1].lat) {
            if (newPoints[0].lng !== newPoints[newPoints.length - 1].lng) {
              const arrayAux = { lat: newPoints[0].lat, lng: newPoints[0].lng };
              newPoints.push(arrayAux);
            }
          }

          if (newPoints.length > 0) {
            setPolygonPoints(newPoints);
          }
        });

        currentMarkers.current.map(m => {
          if (m.marker?.getMap()) {
            const position = m.marker.getPosition();
            if (!insidePolygon(polygonPoints, position.lat(), position.lng())) {
              m.marker.setMap(null);
            }
          }
        });

        defaultMarkers.current.map(dm => {
          const position = new window.google.maps.LatLng(dm.y, dm.x);
          const icon = document.createElement('img');
          icon.src = PontoBaseCerca;

          const defaultMarker = new window.google.maps.marker.AdvancedMarkerElement(
            {
              position,
              content: icon,
              map,
              zIndex: 999,
            }
          );

          handleAddMarker(defaultMarker);
        });

        defaultMarkers.current = [];
      }
    }
  }, [
    id,
    map,
    showFences,
    polygonPoints,
    defaultMarkers.current,
    fences,
    isLoadingTemplate.current,
    editFence,
  ]);

  useEffect(() => {
    loadFence();
  }, [loadFence]);

  useEffect(() => {
    formatTags();
  }, [formatTags]);

  useEffect(() => {
    loadAllFences();
  }, [loadAllFences]);

  return (
    <Container>
      <Breadcrumb style={{ padding: '10px' }}>
        <Breadcrumb.Item>
          {/* @ts-expect-error Server Component */}
          <Link to="/">Página Inicial</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          {/* @ts-expect-error Server Component */}
          <Link to="/cerca">Cercas</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{id ? 'Editar Cerca' : 'Nova Cerca'}</Breadcrumb.Item>
      </Breadcrumb>

      <Card className="cardBody">
        <ShareContainer style={{ zIndex: 1 }}>
          <Tooltip title="Carregar Modelo">
            <Button
              className={`buttonc ${
                id && !isFenceAdmin && !isAuthor.current ? '' : 'modelsButton'
              }`}
              disabled={id && !isFenceAdmin && !isAuthor.current}
              shape="round"
              icon={<CloudDownloadOutlined />}
              size="large"
              onClick={() => {
                setIsLoadTemplateModalVisible(true);
              }}
              style={
                {
                  // border: `${
                  //   id && !isFenceAdmin && !isAuthor.current
                  //     ? ''
                  //     : ''
                  // }`,
                  // color: ,
                }
              }
            >
              Modelos
            </Button>
          </Tooltip>
          <Tooltip title="Associar viaturas">
            <Button
              className={`buttonc ${
                id && !isFenceAdmin && !isAuthor.current && !canEditVehicles
                  ? ''
                  : 'linkButton'
              }`}
              disabled={
                id && !isFenceAdmin && !isAuthor.current && !canEditVehicles
              }
              shape="round"
              icon={<LinkOutlined />}
              size="large"
              onClick={() => {
                setIsLinkedModalVisible(true);
              }}
              style={
                {
                  // border: `${
                  //   id && !isFenceAdmin && !isAuthor.current && !canEditVehicles
                  //     ? ''
                  //     : '1px solid blue'
                  // }`,
                  // width: '200px',
                  // marginLeft: '10px',
                }
              }
            >
              Associar
            </Button>
          </Tooltip>
          <Tooltip title="Compartilhar cerca com outros usuários.">
            <Button
              className={`buttonc ${
                id && !isFenceAdmin && !isAuthor.current ? '' : 'shareButton'
              }`}
              disabled={id && !isFenceAdmin && !isAuthor.current}
              shape="round"
              icon={<ShareAltOutlined />}
              size="large"
              onClick={() => {
                setIsModalVisible(true);
              }}
              // style={{
              //   border: `${
              //     id && !isFenceAdmin && !isAuthor.current
              //       ? ''
              //       : '1px solid green'
              //   }`,
              //   width: '200px',
              //   marginLeft: '10px',
              // }}
            >
              Compartilhar
            </Button>
          </Tooltip>
        </ShareContainer>
        <PageHeader
          title={`${id ? 'Edição' : 'Cadastro'} - ${'Cerca eletrônica'}`}
          subTitle=""
          baseRole="TIPO_VEICULO"
          activeBackHistory
        />

        <Spin spinning={loading}>
          <div className={style.container}>
            <Modal
              key="loadTemplateModal"
              title={
                <ModalTitle>
                  <p className="title">Carregar Modelo</p>
                </ModalTitle>
              }
              open={isLoadTemplateModalVisible}
              footer={
                <div>
                  <Popconfirm
                    placement="top"
                    title={
                      <div>
                        <h3>Atenção!</h3>
                        <p>
                          Carregar um local irá sobrescrever a atual cerca
                          desenhada. Deseja continuar?
                        </p>
                      </div>
                    }
                    // description={description}
                    onConfirm={handleConfirmSelectFenceTemplate}
                    okText="Sim"
                    cancelText="Não"
                  >
                    <Button>Carregar</Button>
                  </Popconfirm>
                </div>
              }
              onOk={handleCloseModal}
              onCancel={handleCloseModal}
              destroyOnClose
            >
              <>
                <Form layout="vertical" form={form}>
                  <Row style={{ justifyContent: 'space-around' }}>
                    <Col span={10}>
                      <Form.Item name="type">
                        <Select
                          placeholder="Selecione o tipo"
                          virtual={false}
                          getPopupContainer={trigger => trigger.parentElement}
                          allowClear
                          style={{
                            width: '100%',
                            height: '90%',
                          }}
                          onChange={handleChangeLocalTypeMapModal}
                          options={[
                            { value: 'bairros', label: 'Bairros' },
                            { value: 'microrregioes', label: 'Microrregiões' },
                            { value: 'municipios', label: 'Municípios' },
                          ]}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={10}>
                      <Form.Item name="local">
                        <Select
                          showSearch
                          placeholder="Selecione o local"
                          virtual={false}
                          getPopupContainer={trigger => trigger.parentElement}
                          allowClear
                          style={{
                            width: '100%',
                            height: '90%',
                          }}
                          disabled={polygonTemplates.length === 0}
                          onChange={handleSelectPolygonTemplate}
                          options={polygonTemplates.map(pt => {
                            return {
                              label: pt.name,
                              value: pt.name,
                            };
                          })}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
                {isLoaded && (
                  <div style={{ width: 470, height: 450 }}>
                    <GoogleMap
                      onLoad={onMapModalLoad}
                      mapContainerStyle={containerStyle}
                      options={mapModalOptions}
                      id="map2"
                      key="map2"
                    >
                      {[...Array(25).keys()].map((ais: any, index: number) => (
                        <Polyline
                          key={index}
                          path={dadosLatLngAis[parseInt(ais, 10)].map(a => ({
                            lat: a[0],
                            lng: a[1],
                          }))}
                          options={{
                            strokeColor: 'black',
                            strokeWeight: 0.5,
                            //@ts-ignore
                            fillColor: rgba(255, 255, 255, 0),
                          }}
                        />
                      ))}
                      {polygonTemplateSelected.length > 0 && (
                        <Polygon paths={polygonTemplateSelected} />
                      )}
                      {/* {microregioesCEFormatted &&
                    microregioesCEFormatted.map(micro => (
                      <Polygon paths={micro.geometry.coordinates} />
                    ))} */}
                      {/* {bairrosCEFormatted.length > 0 &&
                    bairrosCEFormatted.map(bairro => (
                      <Polygon paths={bairro.geometry.coordinates}></Polygon>
                    ))} */}
                      {/* {municipiosCEFormatted &&
                    municipiosCEFormatted.map(micro => (
                      <Polygon paths={micro.geometry.coordinates} />
                    ))} */}
                    </GoogleMap>
                  </div>
                )}
              </>
            </Modal>

            <Modal
              key="shareFenceModal"
              title={
                <ModalTitle>
                  <p className="title">Compartilhar Cerca</p>
                  <p className="subtitle">
                    Informe o(s) CPF(s) que pode(m) visualizar esta Cerca
                  </p>
                </ModalTitle>
              }
              open={isModalVisible}
              onOk={handleCloseModal}
              onCancel={handleCloseModal}
              destroyOnClose
            >
              <ModalBodyT>
                <Form layout="vertical" form={form}>
                  <Form.Item name="allowedCpfs">
                    <Select
                      virtual={false}
                      getPopupContainer={trigger => trigger.parentElement}
                      mode="tags"
                      style={{
                        width: '100%',
                        height: '90%',
                      }}
                      // onKeyUp={e => handleChangeSelectInput(e)}
                      onSelect={(value: any) => handleSelectAlloweds(value)}
                      onDeselect={(value: any) => handleDeselectAlloweds(value)}
                      defaultValue={formatTags}
                      popupClassName="selectWithoutDropdown"
                    />
                  </Form.Item>
                </Form>
              </ModalBodyT>
            </Modal>
            <Modal
              key="linkVehiclesModal"
              title={
                <ModalTitle>
                  <p className="title">Associar Veículos</p>
                  <p className="subtitle">
                    Informe a(s) viatura(s) pertencente(s) a esta Cerca
                  </p>
                </ModalTitle>
              }
              open={isLinkedModalVisible}
              onOk={handleCloseModal}
              onCancel={handleCloseModal}
              destroyOnClose
            >
              <Form layout="vertical" form={form}>
                <Form.Item name="linkedDevices">
                  <Select
                    virtual={false}
                    getPopupContainer={trigger => trigger.parentElement}
                    allowClear
                    mode="multiple"
                    style={{
                      width: '100%',
                      height: '90%',
                    }}
                    loading={loadingRequest?.vehicles}
                    onSelect={handleSelectLinkeds}
                    onDeselect={handleDeselectLinkeds}
                    options={Object.keys(vehicles).map(k => ({
                      label: `${vehicles[k].placa} ${
                        vehicles[k].prefixo &&
                        vehicles[k].prefixo !== vehicles[k].placa
                          ? '- ' + vehicles[k].prefixo
                          : ''
                      }`,
                      value: `${vehicles[k].placa}_${vehicles[k].prefixo}_${vehicles[k].agencia}`,
                    }))}
                    defaultValue={linkedVehicles.current.map(
                      v => `${v.placa} ${v.prefixo ? '- ' + v.prefixo : ''}`
                    )}
                  />
                </Form.Item>
              </Form>
            </Modal>
            <Modal
              key="confirmVehicleAssociationModal"
              title={
                <ModalTitle style={{ color: 'red', fontWeight: 600 }}>
                  VIATURA JÁ VINCULADA
                </ModalTitle>
              }
              open={isDuplicatedVehicleModalVisible}
              onOk={handleCloseDuplicatedVehicleModal}
              // onCancel={handleCancelNewLink}
              destroyOnClose
              cancelButtonProps={{ style: { display: 'none' } }}
              className="confirmVehicleAssociationModal"
            >
              <ModalBody>
                <div className="alertIconContainer">
                  <WarningOutlined className="alertIconBody" />
                </div>
                <div style={{ padding: '10px' }}>
                  {vehicleAlreadylinked.fences.map((f, index) =>
                    f ? (
                      <p key={index}>
                        Viatura vinculada à cerca:{' '}
                        <b style={{ color: 'red' }}>{f}</b>
                      </p>
                    ) : (
                      <p key={index}>Viatura já está vinculada a uma cerca</p>
                    )
                  )}{' '}
                  <br />
                  Desvincule a viatura de sua atual cerca para uma nova
                  associação.
                </div>
              </ModalBody>
            </Modal>

            <div className={style.campos}>
              <Form
                layout="vertical"
                form={form}
                initialValues={{ ativo: true }}
                onFinish={handleSubmit}
              >
                <Form.Item
                  label="Nome"
                  name="fenceName"
                  rules={[
                    {
                      required: true,
                      message: 'Por favor, informe o nome',
                    },
                  ]}
                >
                  <Input
                    disabled={id && !isFenceAdmin && !isAuthor.current}
                    className={style.input}
                  />
                </Form.Item>
                <Form.Item label="Descrição" name="description">
                  <TextArea
                    disabled={id && !isFenceAdmin && !isAuthor.current}
                    style={{
                      borderRadius: '10px',
                    }}
                    autoSize={{ minRows: 3, maxRows: 3 }}
                    placeholder="Descreva aqui a finalidade da cerca."
                  />
                </Form.Item>

                <Form.Item label="AIS Associadas" name="ais">
                  <Select
                    virtual={false}
                    getPopupContainer={trigger => trigger.parentElement}
                    mode="multiple"
                    allowClear
                    style={{ width: '100%' }}
                    placeholder="selecione as ais"
                    options={allAis}
                    disabled={id && !isFenceAdmin && !isAuthor.current}
                  />
                </Form.Item>

                <Form.Item
                  label="Periodo"
                  name="period"
                  rules={[
                    {
                      required: false,
                      message: 'Informe um periodo de vigência',
                    },
                  ]}
                >
                  <RangePicker
                    disabled={id && !isFenceAdmin && !isAuthor.current}
                    style={{ width: '100%' }}
                    className={style.rangePicker}
                    showTime={false}
                    size="large"
                    onChange={handleChangeDatePicker}
                    format={'DD/MM/YYYY'}
                    allowEmpty={[false, true]}
                  />
                </Form.Item>

                <Form.Item label="Ativo">
                  <Switch
                    disabled={id && !isFenceAdmin && !isAuthor.current}
                    checked={active}
                    onChange={handleChangeActive}
                    style={{
                      width: '15%',
                      background: active ? 'green' : 'grey',
                    }}
                  />

                  {active && (
                    <Text
                      style={{ marginLeft: '10px', transition: `500` }}
                      type="secondary"
                    >
                      {timeRange >= 1
                        ? 'Válido por ' + timeRange + ' dia(s).'
                        : 'Válido por tempo indeterminado.'}
                    </Text>
                  )}
                </Form.Item>
              </Form>
              <Divider type="vertical" />
            </div>
            <div className={style.campoMap}>
              {isLoaded && (
                <GoogleMap
                  id={ENV.mapId}
                  onLoad={onMapLoad}
                  mapContainerStyle={containerStyle}
                  options={mapOptions}
                  key="map"
                >
                  {[...Array(25).keys()].map((ais: any, index: number) => (
                    <Polyline
                      key={index}
                      path={dadosLatLngAis[parseInt(ais, 10)].map(a => ({
                        lat: a[0],
                        lng: a[1],
                      }))}
                      options={{
                        strokeColor: 'black',
                        strokeWeight: 0.5,
                        //@ts-ignore
                        fillColor: rgba(255, 255, 255, 0),
                      }}
                    />
                  ))}
                  {/* {microregioesCEFormatted &&
                    microregioesCEFormatted.map(micro => (
                      <Polygon paths={micro.geometry.coordinates} />
                    ))} */}
                  {/* {bairrosCEFormatted.length > 0 &&
                    bairrosCEFormatted.map(bairro => (
                      <Polygon paths={bairro.geometry.coordinates}></Polygon>
                    ))} */}
                  {/* {municipiosCEFormatted &&
                    municipiosCEFormatted.map(micro => (
                      <Polygon paths={micro.geometry.coordinates} />
                    ))} */}

                  {!fences.loading && (
                    <ButtonsContainer>
                      <Radio
                        // htmlType="radio"
                        checked={showFences}
                        onClick={() => setShowFences(prev => !prev)}
                        className="showFencesButton"
                      >
                        Cercas
                      </Radio>
                      <Radio
                        disabled={!id && !isLoadingTemplate.current}
                        // htmlType="radio"
                        checked={editFence}
                        onChange={e => {
                          if (e.target.checked) {
                            toast.warn(
                              'Modo edição pode causar lentidão no sistema!'
                            );
                          }
                        }}
                        onClick={() => setEditFence(prev => !prev)}
                        className="editFencesButton"
                      >
                        Editar
                      </Radio>
                    </ButtonsContainer>
                  )}
                </GoogleMap>
              )}
            </div>
            <div className={style.botoes}>
              <Button
                className="greenButton"
                disabled={
                  id && !isFenceAdmin && !isAuthor.current && !canEditVehicles
                }
                onClick={() => {
                  form.submit();
                }}
                size="large"
                icon={<SaveOutlined />}
                shape="round"
                style={{
                  width: '200px',
                  height: '50px',
                }}
              >
                Salvar
              </Button>
            </div>
          </div>
        </Spin>
      </Card>
    </Container>
  );
}
