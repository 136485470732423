import styled from 'styled-components';

export const Container = styled.div`
  .card {
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
  }
  .grid {
    display: grid;
  }

  .grid-template-columns {
    grid-template-columns: 60% 40%;

    margin: 0 auto;
  }

  .row {
    grid-template-columns: 50% 50%;
    display: grid;
    margin: 0 auto;
  }

  .row-02 {
    grid-template-columns: 70% 20% 15%;
    grid-gap: 10;
    display: grid;
    margin: 0 auto;
    align-items: center;
  }

  .item {
    margin: 0 4%;
    text-align: left;
    font-size: 1.5em;
    padding: 0;
  }

  .grupo:before,
  .grupo:after {
    content: ' ';
    display: table;
  }

  .grupo:after {
    clear: both;
  }

  .campo {
    margin-bottom: 1em;
  }

  .campo label {
    margin-bottom: 0.2em;
    color: #666;
    display: black;
    font-size: 1rem;
  }

  .botoes {
    display: flex;
    justify-content: flex-end;
  }

  .botao-salvar {
    font-size: 1.5em;
    background: #1a784a;
    border: 0;
    margin-top: 20px;
    margin-right: 20px;
    margin-bottom: 1em;
    color: #fff;
    padding: 0.2em 0.6em;
    /* box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5); */
    border-radius: 30px;
    width: 200px;
    height: 40px;
    font-size: 1em;
  }

  .botao-cancelar {
    font-size: 1.5em;
    background: #fc6836;
    border: 0;
    margin-top: 20px;
    margin-right: 20px;
    margin-bottom: 1em;
    color: #fff;
    padding: 0.2em 0.6em;
    /* box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5); */
    border-radius: 30px;
    width: 200px;
    height: 40px;
    font-size: 1em;
  }

  .botao-cheque {
    background: #1a784a;
    border: none;
  }

  .botao-add {
    background: #1d4e6e;
    border: none;
  }

  .anticon-close {
    margin-right: 5px;
  }

  .anticon-save {
    margin-right: 5px;
  }

  .ant-table {
    border: 1px solid #ccc;
  }

  .row-modal {
    grid-template-columns: 70% 20% 15%;
    grid-gap: 10;
    display: grid;
    margin: 0 auto;
    align-items: center;
    background: red !important;
  }
`;
