import ACTION from "../actionTypes";

const initialState = {
  data: [],
  form: {},
  loading: false,
  error: null,
  totalElements: 0
};

export default function tipoVeiculo(state = initialState, action) {
  switch (action.type) {
    case ACTION.TIPO_VEICULO_GET_SEARCH_PAGE:
    case ACTION.TIPO_VEICULO_GET_ALL_REQUEST:
    case ACTION.TIPO_VEICULO_POST_REQUEST:
    case ACTION.TIPO_VEICULO_GET_ID_REQUEST:
    case ACTION.TIPO_VEICULO_UPDATE_REQUEST:
    case ACTION.TIPO_VEICULO_DELETE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };
    case ACTION.TIPO_VEICULO_GET_ALL_SUCCESS: {
      return {
        ...state,
        loading: false
      };
    }
    case ACTION.TIPO_VEICULO_GET_SEARCH_PAGE_SUCCESS: {
      const { content, totalElements } = action.data;
      return {
        ...state,
        loading: false,
        error: null,
        data: (content || []).map(row => ({ ...row, key: row.id })),
        totalElements
      };
    }
    case ACTION.TIPO_VEICULO_POST_SUCCESS:
      return {
        ...state,
        loading: false
      };
    case ACTION.TIPO_VEICULO_UPDATE_SUCCESS:
    case ACTION.TIPO_VEICULO_GET_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        form: action.data
      };
    case ACTION.TIPO_VEICULO_DELETE_SUCCESS:
      return {
        ...state
      };
    case ACTION.TIPO_VEICULO_ERROR:
      return {
        ...state,
        data: [],
        loading: false,
        error: action.message
      };
    default:
      return state;
  }
}
