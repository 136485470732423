import styled from 'styled-components';

export const Container = styled.div`
  z-index: 408;
  position: relative;
  top: -144px;
  background-color: #171f25d9;
  width: 100%;
  height: 144px;
  margin: 0 auto;
  border-top: 1px solid #121924;

  #scrollmenu {
    display: flex;
    overflow-x: scroll;
    overflow-y: hidden;
    width: 100%;
    &::-webkit-scrollbar {
      width: 20px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px grey;
      border-radius: 10px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #008442;
      border-radius: 10px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #008442;
    }
  }

  .line {
    position: absolute;
    width: 100%;
    height: 3px;
    left: 0px;
    top: 46px;
    background: #2d405b;
  }

  .card-itens {
    z-index: 500;
    min-width: 275;
    margin: 14px;
    height: 102px;
    left: 0px;
    top: 0px;
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }

  .tabela {
    text-align: center;
    width: 250px;
  }

  .title {
    line-height: 0;
    font-size: 12px;
    color: rgb(221, 221, 221);
    font-weight: 500;
    -webkit-text-stroke-width: 0.2px;
    /* largura da borda */
    -webkit-text-stroke-color: rgb(0, 0, 0);
    /* cor da borda */
  }

  .sub-title {
    font-size: 10px;
    color: grey;
  }

  .circular-icon {
    background-color: #008442;
    height: 45px;
    width: 45px;
    align-items: center;
    justify-items: center;
    display: grid;
    border-radius: 50px;
    margin: -3px 108px 8px;
    cursor: pointer;
  }

  .btnFechaHistorico {
    --tamanho: 2rem;
    --corUsada: var(--corComplementar);

    ${'' /* position: absolute; */}
    width: var(--tamanho);
    height: var(--tamanho);
    font-size: 20pt;
    padding-bottom: 2.2rem;
    ${'' /* right: 0; */}
    ${'' /* bottom: 100%; */}
    border: none;
    /* border-color:var(--corUsada); */
    background: rgba(5, 10, 20, 0.5);
    font-weight: bold;
    color: var(--corUsada);
  }

  .btnFechaHistorico:hover,
  .btnFechaHistorico:focus {
    background: var(--corUsada);
    color: var(--corBranco);
  }
`;

export const ButtonSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
  align-items: space-around;
  flex-direction: row;
  top: 60px;
  left: 30%;
  position: absolute;
  z-index: 998;
  color: red;
  font-size: 14px;
  width: 30%;
  min-width: 400px;

  .button {
    color: rgb(150, 150, 150) !important;
    border-radius: 3px !important;
    height: 32px !important;
    width: 200px !important;
    box-shadow: 0 2px 2px rgb(0 0 0 / 11%);
    font-weight: 500;
    border: none !important;
  }

  .alertButton {
    background-color: white !important;
  }

  .alertIcon {
    width: 18px;
    height: 18px;
    margin-right: 8px;
    filter: grayscale(1);
  }

  .button:hover {
    .alertIcon {
      filter: grayscale(0);
    }
    color: #1a784a !important;
  }

  .gerarRelatorio {
    margin-right: 10px;
  }

  @media only screen and (max-width: 1400px) {
    .button {
      width: 50px !important;
    }

    .gerarRelatorio {
      margin-right: 10px;
    }
  }

  /* media  */
  @media only screen and (max-width: 768px) {
    left: 12%;

    .button {
      width: 50px !important;
    }

    #text {
      display: none !important;
    }

    .gerarRelatorio {
      margin-right: 10px;
    }
  }
  @media only screen and (max-width: 1000px) {
    left: 12% !important;
    min-width: 300px;
  }
`;

