/* eslint-disable */
import React from 'react';
import { ApiOutlined } from '@ant-design/icons';
import speedLimitIcon from '../../../../../imgs/speed_limit.svg';
import timeOutIcon from '../../../../../imgs/time_out.svg';
import iconMap from '../../../../../imgs/open-map.svg';
import fenceIcon from '../../../../../imgs/fora_cerca.svg';
import { v4 as uuidv4 } from 'uuid';
import './styles.css';

const STYLETYPE = [
  'SAIU_DA_CERCA',
  'LIMITE_VELOCIDADE',
  'PARADA_SUPERIOR_15_MIN',
  'FORA_AIS',
];

export default function TipoAlerta({
  isAIS,
  styleType,
  children,
  prefixo,
}) {
  const checkStyle = STYLETYPE.includes(styleType) ? styleType : STYLETYPE[0];

  return (
    <div key={uuidv4()} className="tipoAlerta">
      <div className="linhaVertical" />
      <div key={uuidv4()} className={checkStyle}>
        {styleType === 'SAIU_DA_CERCA' ? (
          isAIS ? (
            <img src={iconMap} alt="map.icon" id="mapIcon" key="mapIcon" />
          ) : (
            <img
              src={fenceIcon}
              alt="map.icon"
              id="fenceIcon"
              key="fenceIcon"
            />
          )
        ) : (
          <></>
        )}
        {styleType === 'LIMITE_VELOCIDADE' && (
          <img
            src={speedLimitIcon}
            alt="limite_velocidade.icon"
            id="speedIcon"
            key="speedIcon"
          />
        )}
        {styleType === 'PARADA_SUPERIOR_15_MIN' && (
          <img
            src={timeOutIcon}
            alt="limite_velocidade.icon"
            id="timeIcon"
            key="timeIcon"
          />
        )}
        {styleType === 'semPosicao' && <ApiOutlined />}
      </div>

      <div className="alerta">
        <div className="titleAlerta">
          {children}
          <p>{prefixo}</p>
        </div>
      </div>
    </div>
  );
}

