/* eslint-disable */
import React from 'react';
import { Button } from 'antd';
import { MapTrifold } from 'phosphor-react';
import { dataPropertiesAux } from 'interfaces/vehicle';

interface templateProps {
  setIsModalOpen: (value: boolean) => void;
  setMapPosition: (value: any) => void;
}

export default function columns({
  setIsModalOpen,
  setMapPosition,
}: templateProps) {
  return [
    {
      title: <b>Placa</b>,
      dataIndex: 'placa',
      sorter: (a: dataPropertiesAux, b: dataPropertiesAux) =>
        a.placa < b.placa ? -1 : a.placa > b.placa ? 1 : 0,
    },
    {
      title: <b> Prefixo</b>,
      dataIndex: 'prefixo',
      sorter: (a: dataPropertiesAux, b: dataPropertiesAux) =>
        a.prefixo < b.prefixo ? -1 : a.prefixo > b.prefixo ? 1 : 0,
    },
    {
      title: <b> Agencia</b>,
      dataIndex: 'agencia',
      sorter: (a: dataPropertiesAux, b: dataPropertiesAux) =>
        a.agencia < b.agencia ? -1 : a.agencia > b.agencia ? 1 : 0,
      responsive: ['md'],
    },
    {
      title: <b>Ultima Posição</b>,
      dataIndex: 'datePosition',
      sorter: (a: dataPropertiesAux, b: dataPropertiesAux) =>
        a.datePosition < b.datePosition
          ? -1
          : a.datePosition > b.datePosition
          ? 1
          : 0,
      responsive: ['sm'],
      render: (text: string) =>
        `${new Date(text).toLocaleDateString()} ${new Date(
          text
        ).toLocaleTimeString()}`,
    },
    {
      title: <b>Logradouro</b>,
      dataIndex: 'logradouro',
      align: 'center',
      render: (text: string, record: dataPropertiesAux) => {
        const lat = record.latitude;
        const lng = record.longitude;
        return (
          <Button
            style={{
              border: 'none',
              backgroundColor: 'none',
            }}
            onClick={() => {
              setIsModalOpen(true);
              setMapPosition([lat, lng]);
            }}
          >
            <MapTrifold color="#18A779" size={36} />
          </Button>
        );
      },
    },
  ];
}

