import React, { useState } from 'react';
import { GoogleMap, Marker } from '@react-google-maps/api';
import {
  StaticGoogleMap,
  Marker as MarkerStatic,
} from 'react-static-google-map';
import api from '~/services/api.ts';
import ENV from 'config';

const containerStyle = {
  width: '100%',
  height: '400px',
};

export const OcorrenciaMapa = ({
  isLoaded,
  latLong,
  setMapImg,
  setLogradouro,
}) => {
  const [map, setMap] = useState();

  const node = document.getElementById('map-container');
  setMapImg(node);

  const adress = async (lat, lng) => {
    let log = await api.get(
      `https://spca.sspds.ce.gov.br/api/nominatim/reverse.php?format=json&lat=${lat}&lon=${lng}`
    );

    if (log.address) {
      const { road, city, postcode, state, suburb } = log.address;
      log = `${road || ' - '}, ${suburb || ' - '} - ${city ||
        ' - '} - ${state || ' - '} - ${postcode || ' - '} `;
      setLogradouro(log);
    }
    return log || '';
  };

  adress(latLong.lat, latLong.lng);

  const mapOptions = {
    center: map ? map.getCenter() : latLong,
    zoom: map ? map.getZoom() : 12,
    mapTypeControl: false,
    fullscreenControl: false,
    zoomControl: false,
    streetViewControl: false,
    gestureHandling: 'cooperative',
    mapId: ENV.mapId,
  };

  const onLoad = newMap => {
    setMap(newMap);
  };

  return (
    <>
      {isLoaded && (
        <>
          <GoogleMap
            id={ENV.mapId}
            mapContainerStyle={containerStyle}
            onLoad={onLoad}
            options={mapOptions}
          >
            <Marker position={latLong} />
          </GoogleMap>
          <div style={{ display: 'none' }}>
            <StaticGoogleMap
              id="map-container"
              zoom={16}
              size="600x600"
              apiKey="AIzaSyDVrj9xt0YqO-_5deYI1H4B0x5Q-RSS5ak"
            >
              <MarkerStatic location={latLong} color="red" />
            </StaticGoogleMap>
          </div>
        </>
      )}
    </>
  );
};
