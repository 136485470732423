import styled from 'styled-components';

export const StatusContainer = styled.div`
  border: 1px dashed green;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  padding: 5px;
  min-width: 150px;
  margin: 5px;
`;

