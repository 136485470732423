import React, { PureComponent, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { printData, pdfGen } from './reports.js';
import { CSVLink } from 'react-csv';
import { Item } from './Item';
import '../styles.css';
import './raioTableStyle.css';
import { ServiceVeiculo } from '~/services/modulos/service_veiculo';

let dadosPdfPai;
let info;
let historyData;

const _separador = {
  inicial: 15,
  padrao: 16,
};

const _pagina = {
  total: 1,
  atual: 1,
};

export const PdfHistoricoRaio = () => {
  const componentRef = useRef();

  const sessaoDados = dados => {
    const _chave = 'dadosHistoricoRaio';
    console.log('dados: ', dados);

    if (dados) {
      //sessionStorage.setItem(`rotas/${_chave}`, JSON.stringify(dados));
      historyData = JSON.stringify(dados);
      //console.log('dados do historico', historyData);
    } else {
      //console.log('sem dados');
      //return JSON.parse(sessionStorage.getItem(`rotas/${_chave}`));
      return historyData;
    }
  };

  if (window.navigator && window.navigator.dadosPdf) {
    dadosPdfPai = window.navigator.dadosPdf;
    info = window.navigator.info;

    sessaoDados(dadosPdfPai);
  } else {
    dadosPdfPai = sessaoDados();
  }
  printData(dadosPdfPai);

  //Dados para gerar o CSV
  const csvHeader = [
    'Prefixo',
    'Latitude',
    'Longitude',
    'Estado do Motor',
    'Velocidade',
    'Data da Posição',
  ];

  const csvData = dadosPdfPai.map(index => {
    return [
      index.placa,
      index.position.x,
      index.position.y,
      index.engineOne ? 'Desligado': 'Ligado',
      index.speed + " Km/h",
      `${new Date(index.datePosition).toLocaleDateString()} ${new Date(
        index.datePosition
      ).toLocaleTimeString()}`
    ]
  });

  return (
    <>
      <div className="slotBtnDoc">
        <button type="button" onClick={pdfGen}>
          Gerar PDF
        </button>
      </div>

      {/* <div className="slotBtnDoc">
        <button type="button" onClick={preparaImpressao}>
          Imprimir
        </button>
      </div> */}

      <PdfImprimir
        dadosPdfPai={dadosPdfPai}
        //info={info}
        //image={image}
        ref={componentRef}
      />

      <CSVLink
        headers={csvHeader}
        data={csvData}
        filename={'Report Raio'}
        className="slotBtnDocCsv"
        target="_blank"
      >
        <button type="button">
          Gerar CSV
        </button>
      </CSVLink>
    </>
  );
};

export class PdfImprimir extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      txtData: {
        inicio: undefined,
        fim: undefined,
      },
      veiculos: [],
    };
  }

  componentDidMount() {
    const { state, props } = this;
    let { txtData } = { ...state };
    const { dadosPai } = props;

    ServiceVeiculo.resumoVeiculo().then(veiculos => {
      this.setState({ veiculos });
    });

    this.setState({ dadosPdfPai: { ...dadosPdfPai }, txtData: { ...txtData } });
  }

  sessaoDados = dados => {
    const _chave = 'dadosHistoricoIdentificador';

    if (dados) {
      sessionStorage.setItem(`rotas/${_chave}`, JSON.stringify(dados));
    } else {
      return JSON.parse(sessionStorage.getItem(`rotas/${_chave}`));
    }
  };
  render() {
    const { state, props } = this;
    const { dadosPdfPai, info, image } = props;

    let _contador = 2;
    let _numeroPagina = 1;
    _pagina.atual = 1;

    let _numeroItens = dadosPdfPai?.length - _separador.inicial;

    if (_numeroItens > 0) {
      let _totalPaginas = 1;

      _totalPaginas += Math.ceil(_numeroItens / _separador.padrao);
      _pagina.total = _totalPaginas;
    }

    const plusContador = () => {
      return _contador++;
    };

    const plusPagina = () => {
      return (_numeroPagina += 1);
    };

    console.log(dadosPdfPai);
    return (
      <div className="corpoAbaImpressao">
        <div id="slotImpressao" className="secaoImpressao">
          <div className="espacoUtilImpressao">
            <div className="cabecalhoImpressao">
              <span className="slotBrasaoImpressao">
                <img
                  alt="brasaoCentral"
                  src={require('~/imgs/logoEstado.png')}
                />
              </span>
            </div>

            <div className="corpoImpressao">
              <h2>Relatório histórico de raio</h2>
              <span className="veiculoImpressao">
                <p>
                  Dados de posicionamento fornecidos pelas empresas{' '}
                  <i>Oi/CSBrasil</i>
                </p>
              </span>
              <br />
              <div className="slotListaImpressao">
                <ul className="listaImpressao">
                  <li className="itemListaImpressao cabecalhoListaImpressao">
                    <p className="headPlacaRaio">Prefixo</p>
                    <p className="headLatRaio">Latitude</p>
                    <p className="headLngRaio">Longitude</p>
                    <p className="headMotorRaio">Motor</p>
                    <p className="headVelRaio">Velocidade</p>
                    <p className="headDtPosRaio">Data posição</p>
                  </li>
                  {dadosPdfPai?.map(d => (
                    <Item
                      veiculos={this.state.veiculos}
                      d={d}
                      key={d.dataPosicao}
                      plus={plusContador}
                      plusPagina={plusPagina}
                      _numeroPagina={_numeroPagina}
                      _separador={_separador}
                      _contador={_contador++}
                      dadosPdfPai={dadosPdfPai}
                    />
                  ))}
                </ul>
              </div>
            </div>
            <div className="slotFaixasImpressao">
              <div className="rodapeImpressao">
                <div className="slotTextoRodapeImpressao">
                  <p>
                    Secretaria de Segurança Pública e Defesa Social. Avenida
                    Bezerra de Menezes, 581 - São Gerardo
                  </p>
                  <p>CEP 60325-003 . Fortaleza Ceará . Fone: (85) 3101-6501</p>
                </div>
                <span className="faixaLaranjaImpressao" />
                <span className="faixaVerdeImpressao" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
