// @ts-nocheck
import React, { useCallback, useEffect, useState } from 'react';
import { Input, Select } from 'antd';
import style from './styles.module.css';
import { toast } from 'react-toastify';
import api from 'services/api';
import { DataPosition, dataPropertiesAux } from 'interfaces/vehicle';
import { useDataAndSelectEfetivo } from 'hooks/monitoring/useDataAndSelectEfetivo';
import { LoadFormattedAddress } from 'utils/functionsUtils';

const { Option } = Select;

let csvData: any = [];
let count = 0;

export default function Grupos() {
  // @ts-ignore
  const { data, handleSelectEfetivo } = useDataAndSelectEfetivo();
  const [loadingLogradouro, setLoadingLogradouro] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [grupo, setGrupo] = useState('');

  let addresses = [];

  const [grupos, setGrupos] = useState<string[]>([]);
  const [ativos, setAtivos] = useState([]);

  const handleClickVeiculo = (efetivo: string) => {
    handleSelectEfetivo(efetivo);
  };

  function handleChange(value: string) {
    setGrupo(value);
  }

  const loadAdderesses = async () => {
    try {
      setLoadingLogradouro(true);
      if (csvData) {
        let numReqs = 0;
        for (let i = 0; i < csvData.length; ++i) {
          let currentAddress = null;
          for (let j = 0; j < addresses.length; ++j) {
            if (
              csvData[i][2] === addresses[j][0] &&
              csvData[i][3] === addresses[j][1]
            ) {
              currentAddress = addresses[j][2];
            }
          }
          if (!currentAddress) {
            currentAddress = await LoadFormattedAddress(
              csvData[i][2],
              csvData[i][3]
            );
            const address = [csvData[i][2], csvData[i][3], currentAddress];
            addresses.push(address);
            numReqs++;
          }
          csvData[i][10] = currentAddress;
        }
      }
    } catch (err) {
      toast.error('Erro ao carregar endereços.');
      // console.log('Erro no carregamento dos endereços', err);
    } finally {
      setLoadingLogradouro(false);
    }
  };

  if (isModalVisible && count < 1) {
    loadAdderesses();
    count++;
  }

  const groupBy = useCallback((array, key) => {
    // Return the end result
    return array.reduce((result: any, currentValue: string) => {
      // If an array already present for key, push it to the array. Else create an array and push the object
      (result[currentValue[key]] = result[currentValue[key]] || []).push(
        currentValue
      );
      // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
      return result;
    }, {}); // empty object is the initial value for result object
  }, []);

  useEffect(() => {
    const conectados: dataPropertiesAux[] = [];
    const desconectados: dataPropertiesAux[] = [];

    data.map((d: DataPosition) => {
      if (d.properties.conectado) {
        conectados.push(d.properties);
      } else {
        desconectados.push(d.properties);
      }
    });

    const dataFormatted = conectados.map(v => {
      let { grupo } = v;
      if (!grupo && v.numAis) {
        grupo = `AIS${v.numAis} conectados`;
      }
      return { ...v, grupo: grupo || 'SEM GRUPO' };
    });
    const desconectadosFormatted = desconectados.map(v => {
      let { grupo } = v;
      if (!grupo && v.numAis) {
        grupo = `AIS${v.numAis} desconectados`;
      }
      return { ...v, grupo: grupo || 'SEM GRUPO' };
    });
    const newData = groupBy(dataFormatted, 'grupo');
    const newDesconectados = groupBy(desconectadosFormatted, 'grupo');
    const newArray = { ...newData, ...newDesconectados };

    setAtivos(newArray);
    setGrupos(Object.keys(newArray).sort());
  }, [data, groupBy]);

  return (
    <>
      <div className={style.grupos}>
        <br />
        <Select
          key="select"
          virtual={false}
          getPopupContainer={trigger => trigger.parentElement}
          showSearch
          value={grupo}
          className={style.options}
          onChange={handleChange}
        >
          <Option value="">Selecione</Option>
          {grupos.map(v => {
            return (
              <Option key={v} value={v}>{`${v} (${ativos[v].length})`}</Option>
            );
          })}
        </Select>
      </div>
      {grupo !== '' && (
        <div className={style.grupoContainer}>
          <Input
            placeholder="Pesquisar"
            onChange={e => setSearchText(e.target.value)}
            style={{ marginBottom: '10px' }}
          />
          <span>{`Conectado CIOPS: ${grupo} - ${
            (ativos[grupo] || []).length
          } veículos`}</span>
          {(ativos[grupo] || [])
            .filter(
              v =>
                !searchText ||
                v.placa.toLowerCase().indexOf(searchText.toLowerCase()) >= 0 ||
                (v.prefixo &&
                  v.prefixo.toLowerCase().indexOf(searchText.toLowerCase()) >=
                    0)
            )
            .sort((a, b) => (a.vinculada > b.vinculada ? -1 : 1))
            .map((value, index) => (
              <a
                key={value.idDevice}
                onClick={() => handleClickVeiculo(value.idDevice)}
              >
                <div key={value.placa} className={style.grupoButton}>
                  <div className={style.grupoDescrition}>
                    <div className={style.grupoChave}>{index + 1}:</div>
                    <div className={style.grupoValor}>{`${value.placa} ${
                      value.prefixo && value.prefixo !== value.placa
                        ? `- ${value.prefixo}`
                        : ''
                    }`}</div>
                  </div>

                  <div className={style.grupoDescrition}>
                    {/*    <div className={style.grupoChave}>Vinc:</div> */}
                    <div className={style.grupoValor}>{value.vinculada}</div>
                  </div>
                </div>
              </a>
            ))}
          {/* <div className={style.grupoIcon}>
            <EyeOutlined />
          </div> */}
        </div>
      )}
    </>
  );
}
