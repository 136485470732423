import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  getAllAisSuccess,
  getAllVeichlesResumeSuccess,
  getAllVinSuccess,
  error,
} from './actions';

import { ServiceAIS } from '~/services/modulos/serviceAIS';
import { ServiceVeiculo } from '~/services/modulos/service_veiculo';
import { ServiceVinculada } from '~/services/modulos/serviceVinculada';

import ACTION from '../actionTypes';

function* listAisAll() {
  try {
    const list = yield call(ServiceAIS.getAll);
    yield put(getAllAisSuccess(list));
  } catch (e) {
    yield put(error(e));
  }
}

function* listInputResume() {
  try {
    const list = yield call(ServiceVeiculo.resumoVeiculo);
    yield put(getAllVeichlesResumeSuccess(list));
  } catch (e) {
    yield put(error(e));
  }
}

function* listVinResume() {
  try {
    const list = yield call(ServiceVinculada.getAll);
    yield put(getAllVinSuccess(list));
  } catch (e) {
    yield put(error(e));
  }
}

export default all([
  takeLatest(ACTION.MONITORING_AIS_ALL_REQUEST, listAisAll),
  takeLatest(ACTION.MONITORING_VEI_ALL_REQUEST, listInputResume),
  takeLatest(ACTION.MONITORING_VIN_ALL_REQUEST, listVinResume),
]);

