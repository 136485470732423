import React from 'react';

import { Footer } from './styles';

export default function NewFooter() {
  return (
    <Footer>
      <div className="row">
        <div className="sigla">
          <p>SSPDS.CE.GOV.BR</p>
          <h2>
            © 2017 - 2024 – GOVERNO DO ESTADO DO CEARÁ TODOS OS DIREITOS
            RESERVADOS
          </h2>
        </div>
        <div className="endereco">
          <h2>SECRETARIA DA SEGURANÇA PÚBLICA E DEFESA SOCIAL</h2>

          <p>
            AV. AGUANAMBI - AEROPORTO, FORTALEZA - CE, 60415-390. TELEFONE: (85)
            3101-6501
          </p>
        </div>
        <div className="redesSociais">
          <h1>Desenvolvido por:</h1>
          <div className="icon">
            <p>
              <b>COTIC</b> - Coordenadoria de Tecnologia da Informação e
              Comunicação
            </p>
          </div>
        </div>
      </div>
    </Footer>
  );
}

