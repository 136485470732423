import React, { ReactNode } from 'react';
import { AuthProvider } from './auth';

const AppProvider = ({ children }: { children: ReactNode }) => (
  <>
    <AuthProvider>{children}</AuthProvider>
  </>
);

export default AppProvider;
