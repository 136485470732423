import { all } from 'redux-saga/effects';

import tipoVeiculo from './tipoVeiculo/sagas';
import veiculo from './veiculo/sagas';
import equipamento from './equipamento/sagas';
import vinculada from './vinculada/sagas';
import lotacao from './lotacao/sagas';
import prefixo from './prefixo/sagas';
import ais from './ais/sagas';
import monitoring from './monitoring/sagas';
import gruposAtivos from './gruposAtivos/sagas';
import ocorrencia from './ocorrencia/sagas';

export default function* rootSaga() {
  yield all([
    tipoVeiculo,
    veiculo,
    equipamento,
    vinculada,
    lotacao,
    prefixo,
    ais,
    monitoring,
    gruposAtivos,
    ocorrencia,
  ]);
}
