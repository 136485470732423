import React from 'react';
import { Button, Divider, Popconfirm } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import { ContainerFlex } from './styles';

export function GroupAcoes({
  moduloSistema,
  onDelete,
  record,
  showModal,
  setRegistroSelected,
  setVisibleModal,
}) {
  const history = useHistory();

  const handleShowModal = () => {
    setRegistroSelected(record.prefixo ? record.prefixo.id : record.key);
    setVisibleModal(true);
  };

  return (
    <ContainerFlex>
      <Button
        type="primary"
        icon={<EditOutlined />}
        onClick={
          showModal
            ? handleShowModal
            : () => history.push(`/${moduloSistema}/form/${record.key}`)
        }
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      />

      <Divider type="vertical" />
      <Popconfirm
        title="Deseja excluir o registro?"
        onConfirm={() => onDelete(record)}
      >
        <Button
          type="danger"
          icon={<DeleteOutlined />}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        />
      </Popconfirm>
    </ContainerFlex>
  );
}

GroupAcoes.propTypes = {
  moduloSistema: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
  record: PropTypes.shape({
    key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
  showModal: PropTypes.bool,
  setRegistroSelected: PropTypes.func,
  setVisibleModal: PropTypes.func,
};

GroupAcoes.defaultProps = {
  showModal: false,
  setRegistroSelected: null,
  setVisibleModal: null,
};
