import React, { useCallback, useEffect, useState } from 'react';

import { Modal, Form, Row, Col, Spin } from 'antd';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { SelectAntd } from '~/components/FormAntd/SelectComponents';
import { InputAntdCustom } from '~/components/FormAntd/InputAntd';
import { ServiceTipoEquipamento } from '~/services/modulos/serviceTipoEquipamento';
import { ServiceTipoTecnologia } from '~/services/modulos/serviceTipoTecnologia';
import { ServiceEquipamento } from '~/services/modulos/serviceEquipamento';

export function ModalCadastroEquipamento({ visible, onCancel, onOk }) {
  const [form] = Form.useForm();

  const [tipoEquipamentoList, setTipoEquipamentoList] = useState([]);

  const [tipoTecnologiaList, setTipoTecnologiaList] = useState([]);

  const [loading, setLoading] = useState(false);

  const getAllTipoEquipamento = useCallback(async () => {
    const dados = await ServiceTipoEquipamento.getAll();

    setTipoEquipamentoList(dados.map(d => ({ key: d.id, value: d.nome })));
  }, []);

  const getAllTipoTecnologia = useCallback(async () => {
    const dados = await ServiceTipoTecnologia.getAll();

    setTipoTecnologiaList(dados.map(d => ({ key: d.id, value: d.empresa })));
  }, []);

  useEffect(() => {
    getAllTipoEquipamento();

    getAllTipoTecnologia();
  }, [getAllTipoEquipamento, getAllTipoTecnologia]);

  const onFinish = useCallback(
    async values => {
      try {
        setLoading(true);

        values.ativo = true;

        values.tipoTecnologia = {
          id: values.tipoTecnologia,
        };

        values.tipoEquipamento = {
          id: values.tipoEquipamento,
        };

        await ServiceEquipamento.create(values);

        onOk();

        form.resetFields();
      } catch (err) {
        toast.error('Falha ao salvar os dados');
      } finally {
        setLoading(false);
      }
    },

    [form, onOk]
  );

  return (
    <Modal
      title="Cadastro de Equipamento"
      className="modal-recupera-login"
      open={visible}
      onOk={form.submit}
      onCancel={onCancel}
      destroyOnClose
    >
      <Spin spinning={loading}>
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Row justify="space-around">
            <Col span={10}>
              <Form.Item
                label="Tipo de equipamento"
                name="tipoEquipamento"
                rules={[
                  {
                    required: true,
                    message: 'Por favor, informe o Tipo do Equipamento',
                  },
                ]}
              >
                <SelectAntd size="large" data={tipoEquipamentoList} />
              </Form.Item>
            </Col>

            <Col span={10}>
              <Form.Item label="Módulo" name="modulo">
                <InputAntdCustom size="large" />
              </Form.Item>
            </Col>
          </Row>

          <Row justify="space-around">
            <Col span={10}>
              <Form.Item
                label="Empresa"
                name="tipoTecnologia"
                rules={[
                  {
                    required: true,
                    message: 'Por favor, informe o Tipo da Tecnologia',
                  },
                ]}
              >
                <SelectAntd size="large" data={tipoTecnologiaList} />
              </Form.Item>
            </Col>

            <Col span={10}>
              <Form.Item
                label="IMEI/Nº série"
                name="identificacao"
                rules={[
                  {
                    required: true,

                    message: 'Por favor, informe o identificador do aparelho',
                  },
                ]}
              >
                <InputAntdCustom size="large" />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Modal>
  );
}

ModalCadastroEquipamento.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onOk: PropTypes.func.isRequired,
};
