import React, { useCallback, useState, useRef, ReactNode } from 'react';
import { toast } from 'react-toastify';

import { createContext } from 'use-context-selector';
import moment from 'moment';
import { PositionVehicle, VehicleComposition } from 'interfaces/vehicle';
import { AuthDataProps } from 'interfaces/auth';
import {
  TrackingHistoryProps,
  VehiclePositionHistory,
} from 'interfaces/history';
import { HistoryService } from 'services/modulos/serviceHistory';
import { useAuthData } from 'hooks/auth/useAuthData';
import { useFiltersMapSelectedAis } from 'hooks/filtersMap/useFiltersMapSelectedAis';
import { useGlobalFences } from 'hooks/global/useGlobalFences';
import { useFencesSelectedInfo } from 'hooks/monitoring/useFencesSelectedInfo';
import { rgba } from 'polished';

import PontoBaseCerca from '../imgs/ponto_base_cerca.svg';
import { HistoryTeltronicService } from 'services/modulos/serviceHistoricoTeltronic';

export const TrackingHistoryContext = createContext({} as TrackingHistoryProps);

interface HistoryProviderProps {
  children: ReactNode;
}

export const TrackingHistoryProvider = ({ children }: HistoryProviderProps) => {
  const { data: dataAuth } = useAuthData();
  const { fences, showFencesName } = useGlobalFences();

  const [dataSource, setDataSource] = useState('COTIC');

  const { roles }: AuthDataProps = dataAuth || {};
  const [map, setMap] = useState<google.maps.Map>();
  const [loadingFences, setLoadingFences] = useState(false);
  const [aisAuditoria, setAisAuditoria] = useState('');
  const [dataHistorico, setDataHistorico] = useState<VehiclePositionHistory[]>(
    []
  );

  const [selectedPosicaoEfetivo, setSelectedPosicaoEfetivo] = useState<
    number | string
  >(0);
  const { onSelectedAis } = useFiltersMapSelectedAis();
  const { fencesSelected } = useFencesSelectedInfo();

  const dataType = useRef('monitoramento');
  const prefixosHistoricoRef = useRef({
    conectadas: [],
    desconectadas: [],
    radio: [],
  });

  const [polygonsCustomAudit, setPolygonsCustomAudit] = useState([]);

  const isPermittedComposition = () => {
    const role = roles?.find(r => r === 'ROLE_ROTAS_HISTORICO_CONEXAO');
    return role;
  };

  const [loadingHistorico, setLoadingHistorico] = useState(false);
  const [choosingOrigin, setChoosingOrigin] = useState(false);
  const [originPoint, setOriginPoint] = useState({});
  const [raioPonto, setRaioPonto] = useState(250);
  const [searchedDate, setSearchedDate] = useState({
    dataInicio: '',
    dataFim: '',
  });
  const [searchedDateSlider, setSearchedDataSlider] = useState({
    dataInicio: '',
    dataFim: '',
  });
  const [numberFiltersActive, setNumberFiltersActive] = useState(0);
  // const [selectedVehiclesHistory, setSelectedVehiclesHistory] = useState<
  //   string[]
  // >([]);

  const vehiclesWithFullHistoryLoaded = useRef<string[]>([]); // veículos com histórico individual carregado

  const handleSetPrefixosHistorico = useCallback((newProperties: any) => {
    prefixosHistoricoRef.current = {
      ...prefixosHistoricoRef.current,
      ...newProperties,
    };
  }, []);

  const searchByPlatesAndDate = useCallback(
    async ({
      dataInicio,
      dataFim,
      placas,
    }: {
      dataInicio: string;
      dataFim: string;
      placas: string[];
    }) => {
      try {
        setLoadingHistorico(true);

        searchedDate.dataInicio = dataInicio;
        searchedDate.dataFim = dataFim;

        setSearchedDate({ ...searchedDate });

        let positionsHistory: VehiclePositionHistory[] = [];

        if (dataSource === 'COTIC') {
          const body = {
            dataInicio,
            dataFim,
            placas,
          };

          positionsHistory = await HistoryService.getByPlates(body);
        } else if (dataSource === 'TELTRONIC') {
          const body = {
            dataInicio,
            dataFim,
            idDevices: placas,
          };

          positionsHistory = await HistoryTeltronicService.getByIdDevices(body);
        }

        dataType.current = 'historicoIndividual';
        setSelectedPosicaoEfetivo('');

        vehiclesWithFullHistoryLoaded.current = placas;

        // let composition: VehicleComposition[] = [];

        if (!positionsHistory || positionsHistory.length === 0) {
          throw Error();
        }

        let composition: any = {};

        if (isPermittedComposition() && dataSource === 'COTIC') {
          await Promise.all(
            positionsHistory.map(history =>
              HistoryService.loadComposition({
                prefixo: history.positions[0].prefixo,
                dataInicio,
                dataFim,
              }).then(result => {
                if (result.length) {
                  composition[result[0].viatura] = result;
                }
              })
            )
          );
        }

        const historyFormatted = positionsHistory.map(history => {
          return {
            placa:
              dataSource === 'COTIC' ? history.placa : history.idDevice || '',
            positions: history.positions
              .filter(p => {
                return Boolean(p.position?.x && p.position?.y);
              })
              .map(p => {
                let comp = [];

                if (composition[p.prefixo]) {
                  comp = composition[p.prefixo].map((c: any) =>
                    moment(p.datePosition).isSameOrAfter(
                      moment(c.dataHoraConexao)
                    )
                  );
                }

                let newObj = {
                  ...p,
                  latitude: p.position?.x || 0,
                  longitude: p.position?.y || 0,
                  latLng: { lat: p.position?.x || 0, lng: p.position?.y || 0 },
                  position: p.position
                    ? p.position
                    : {
                        x: p.latitude,
                        y: p.longitude,
                        coordinates: [p.latitude, p.longitude],
                        type: 'Point',
                      },
                };

                if (comp.length) {
                  newObj.motorista = comp.motorista;
                  newObj.comandante = comp.comandante;
                  newObj.patrulheiro = comp.patrulheiro;
                }

                return newObj;
              }),
          };
        });

        setDataHistorico(historyFormatted);
      } catch (err) {
        throw Error('Erro ao consultar, verifique a conexão com a internet');
      } finally {
        setTimeout(() => {
          setLoadingHistorico(false);
        }, 2000);
      }
    },
    [searchedDate, dataSource]
  );

  const searchByAisAndDate = useCallback(
    async ({
      dataInicio,
      dataFim,
      nome,
      apenasAis,
    }: {
      dataInicio: string;
      dataFim: string;
      nome: string;
      apenasAis: boolean;
    }) => {
      try {
        const body = {
          nome: nome.replaceAll(' ', ''),
          dataInicio,
          dataFim,
          apenasAis,
        };

        setLoadingHistorico(true);

        searchedDate.dataInicio = dataInicio;
        searchedDate.dataFim = dataFim;

        setSearchedDate({ ...searchedDate });

        let respostaHistory: VehiclePositionHistory[] = [];

        if (dataSource === 'COTIC') {
          respostaHistory = await HistoryService.loadAuditPolygonHistory(body);
        } else {
          respostaHistory = await HistoryTeltronicService.loadAuditPolygonHistory(
            body
          );
        }

        dataType.current = 'auditoria';

        if (!respostaHistory || respostaHistory.length === 0) {
          throw new Error('Consulta sem posições de viaturas');
        }

        setDataHistorico(
          respostaHistory.map(v => {
            v.positions = v.positions
              .filter(p => {
                return Boolean(p.position?.x && p.position?.y);
              })
              .map((p: PositionVehicle) => ({
                ...p,
                latitude: p.position?.x || 0,
                longitude: p.position?.y || 0,
                latLng: { lat: p.position?.x || 0, lng: p.position?.y || 0 },
                position: p.position
                  ? p.position
                  : {
                      x: p.latitude || 0,
                      y: p.longitude || 0,
                      coordinates: [p.latitude || 0, p.longitude || 0],
                      type: 'Point',
                    },
              }));
            return v;
          })
        );
      } catch (err) {
        throw Error('Erro ao consultar, verifique a conexão com a internet');
      }

      setTimeout(() => {
        setLoadingHistorico(false);
      }, 2000);
    },
    [searchedDate]
  );

  const searchByRadiusAll = useCallback(
    async ({
      dataInicio,
      dataFim,
      origem,
      raio,
      selectedGroups,
    }: {
      dataInicio: string;
      dataFim: string;
      origem: {
        lat: number;
        lng: number;
      };
      raio: number;
      selectedGroups: string[];
    }) => {
      try {
        setLoadingHistorico(true);

        searchedDate.dataInicio = dataInicio;
        searchedDate.dataFim = dataFim;

        let groups: string[] = [];

        setSearchedDate({ ...searchedDate });

        if (selectedGroups && !selectedGroups.includes('all')) {
          groups = selectedGroups.map(g => g.replaceAll(' ', ''));
        }

        const body = {
          dataInicio,
          dataFim,
          distanciaKM: raio / 1000, // Conversão em KM,
          latitude: origem.lat,
          longitude: origem.lng,
          groups,
        };

        let positionsHistory: VehiclePositionHistory[] = [];

        if (dataSource === 'COTIC') {
          positionsHistory = await HistoryService.loadSphericalDistance(body);
        } else {
          positionsHistory = await HistoryTeltronicService.loadSphericalDistance(
            body
          );
        }

        if (!positionsHistory || positionsHistory.length === 0) {
          throw new Error('Consulta sem resultado');
          // toast.error('Erro na consulta');
        }

        setDataHistorico(
          positionsHistory.map(v => {
            v.positions = v.positions
              .filter(p => {
                return Boolean(p.position?.x && p.position?.y);
              })
              .map((p: PositionVehicle) => ({
                ...p,
                latitude: p.position?.x || 0,
                longitude: p.position?.y || 0,
                latLng: { lat: p.position?.x || 0, lng: p.position?.y || 0 },
                position: p.position
                  ? p.position
                  : {
                      x: p.latitude || 0,
                      y: p.longitude || 0,
                      coordinates: [p.latitude || 0, p.longitude || 0],
                      type: 'Point',
                    },
              }));
            return v;
          })
        );
      } catch (err) {
        toast.info(
          // @ts-ignore
          err.message === 'Consulta sem resultado'
            ? 'Consulta sem resultado'
            : 'Erro na consulta'
        );
        // throw Error('Erro na consulta');
      } finally {
        setLoadingHistorico(false);
      }
    },
    [searchedDate, dataSource]
  );

  const resetAllData = useCallback(() => {
    setDataHistorico([]);
    setSearchedDataSlider({
      dataInicio: '',
      dataFim: '',
    });
    vehiclesWithFullHistoryLoaded.current = [];
  }, []);

  const cleanSelectedAis = useCallback(() => {
    onSelectedAis((prev: any) => {
      Object.keys(prev).map(p => {
        prev[p] = true;
        return prev[p];
      });
      return prev;
    });
  }, []);

  const renderFencesOnHistoryMap = useCallback(() => {
    if (map) {
      try {
        fencesSelected.current.map(fenceId => {
          try {
            if (!fences.current.idList.includes(fenceId)) {
              fences.current.idList.push(fenceId);
              const fence = fences.current.data.find(f => f.id === fenceId);

              const polygonPath = fence?.geom.points.map(
                (p: { x: number; y: number }) => ({
                  lat: p.y,
                  lng: p.x,
                })
              );

              const newMarkers: google.maps.marker.AdvancedMarkerElement[] = [];
              const listeners = [];

              const bounds = new google.maps.LatLngBounds();
              let i;

              for (i = 0; i < polygonPath.length; i++) {
                bounds.extend(polygonPath[i]);
              }

              const center = {
                lat: bounds.getCenter().lat(),
                lng: bounds.getCenter().lng(),
              };

              const fencePolygon = new window.google.maps.Polygon({
                paths: polygonPath,
                strokeColor: 'black',
                strokeOpacity: 1,
                strokeWeight: 1,
                fillColor: rgba(0, 0, 0, 0.8),
                map,
                zIndex: 401,
              });

              const infoWindow = new window.google.maps.InfoWindow({
                content: `<div style="padding: 0px 10px 10px 0px"><b>${fence?.nome}</b></div>`,
                maxWidth: 150,
                zIndex: 999,
                disableAutoPan: true,
                position: center,
              });

              if (showFencesName.current) {
                infoWindow.open(map);
              }

              const openInfoWindow = fencePolygon.addListener('click', () => {
                infoWindow.open(map);
              });

              listeners.push(openInfoWindow);

              fence?.pontosBase.map(pb => {
                let initialPosition = new window.google.maps.LatLng(pb.y, pb.x);
                const icon = document.createElement('img');
                icon.src = PontoBaseCerca;

                const basePoint = new window.google.maps.marker.AdvancedMarkerElement(
                  {
                    position: initialPosition,
                    content: icon,
                    map,
                    zIndex: 401,
                  }
                );

                newMarkers.push(basePoint);
              });

              fences.current.mapFences.push({
                polygon: fencePolygon,
                markers: newMarkers,
                infoWindow,
                listeners,
                id: fenceId,
                veiculosAssociados: fence?.veiculosAssociados,
                foraCerca: false,
                polygonPath,
                fenceName: fence?.nome,
              });
            }
          } catch {
            //
          }
        });

        fences.current.mapFences = fences.current.mapFences.filter(fence => {
          if (!fencesSelected.current.includes(fence.id)) {
            fences.current.idList = fences.current.idList.filter(
              id => id !== fence.id
            );
            if (fence.infoWindow) {
              fence.infoWindow.close();
            }
            fence.markers.map((m: any) => m.setMap(null));
            fence.listeners.map((l: any) => {
              window.google.maps.event.removeListener(l);
            });
            if (fence.polygon) {
              fence.polygon.setMap(null);
            }
            return false;
          }

          return true;
        });
      } catch {}
    }
  }, [map]);

  return (
    <TrackingHistoryContext.Provider
      value={{
        map,
        setMap,
        dataSource,
        setDataSource,
        aisAuditoria,
        setAisAuditoria,
        dataHistorico,
        setDataHistorico,
        searchByPlatesAndDate,
        searchByAisAndDate,
        searchByRadiusAll,
        loadingHistorico,
        setLoadingHistorico,
        numberFiltersActive,
        setNumberFiltersActive,
        choosingOrigin,
        setChoosingOrigin,
        originPoint,
        setOriginPoint,
        raioPonto,
        setRaioPonto,
        resetAllData,
        cleanSelectedAis,
        prefixosHistoricoRef,
        handleSetPrefixosHistorico,
        selectedPosicaoEfetivo,
        setSelectedPosicaoEfetivo,
        dataType,
        loadingFences,
        setLoadingFences,
        searchedDate,
        setSearchedDate,
        polygonsCustomAudit,
        setPolygonsCustomAudit,
        searchedDateSlider,
        setSearchedDataSlider,
        vehiclesWithFullHistoryLoaded,
        renderFencesOnHistoryMap,
      }}
    >
      {children}
    </TrackingHistoryContext.Provider>
  );
};
