export const templateColumns = () => {
  return [
    {
      title: 'Placa',
      dataIndex: 'placa',
      key: 'placa',
      align: 'center',
    },
    {
      title: 'Prefixo',
      dataIndex: 'prefixo',
      key: 'prefixo',
      align: 'center',
      responsive: ['sm'],
    },
    {
      title: 'Agência',
      dataIndex: 'agencia',
      key: 'agencia',
      align: 'center',
    },
  ];
};

