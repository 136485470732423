import React, { useState, useEffect } from 'react';
import { Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';

import { toast } from 'react-toastify';
import { useJsApiLoader } from '@react-google-maps/api';
import { pdfGen } from './reports';
import columns from '../../../columns/PDF/Desconectados/columns';
import PdfImprimir from '../../../components/PdfImprimir';
import Filter from '../Filter';

import ENV from 'config';
import '../styles.css';
import { PositionVehicle } from 'interfaces/vehicle';
import { useLoadLastPositions } from 'hooks/global/useLoadLastPositions';
import { googleLibraries } from 'utils/functionsUtils';

export function PdfDesconectados() {
  const [dadosLast, setDadosLast] = useState<PositionVehicle[]>([]);
  const [loadingTable, setLoadingTable] = useState<boolean>(false);
  const [dataFiltered, setDataFiltered] = useState<PositionVehicle[]>([]);
  const { loadLastPositions } = useLoadLastPositions();

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: ENV.googleMapsApiKey,
    libraries: googleLibraries,
    region: 'BR',
    language: 'pt-br',
  });

  const isDateGreaterThan48Hours = (dateForCompare: string) => {
    const date = Number(new Date(dateForCompare));
    const currentDate = Number(new Date());
    const differenceInMs = currentDate - date;
    const differenceInHours = differenceInMs / (1000 * 60 * 60);

    if (differenceInHours > 48) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    const load = async () => {
      try {
        setLoadingTable(true);

        const lastPositions = await loadLastPositions();

        const vehiclesDisconnected = (lastPositions || []).filter(
          v =>
            !v.statusConexaoViatura && isDateGreaterThan48Hours(v.datePosition)
        );

        setDadosLast(vehiclesDisconnected);
        setDataFiltered(vehiclesDisconnected);
      } catch (err) {
        toast.error('Erro ao carregar dados');
      } finally {
        setLoadingTable(false);
      }
    };
    load();
  }, [loadLastPositions]);

  return (
    <>
      <Breadcrumb style={{ padding: '10px' }}>
        <Breadcrumb.Item>
          {/* @ts-expect-error Server Component */}
          <Link to="/">Página Inicial</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          {/* @ts-expect-error Server Component */}
          <Link to="/relatorios">Relatorios</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Desconectados</Breadcrumb.Item>
      </Breadcrumb>
      <Filter data={dadosLast} setDataFiltered={setDataFiltered} />
      <PdfImprimir
        dadosLast={dataFiltered}
        loadingTable={loadingTable}
        columnWithModal
        columns={columns}
        pdfGen={pdfGen}
        isLoaded={isLoaded}
      />
    </>
  );
}
