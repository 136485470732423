/* eslint-disable */
import styled from 'styled-components';

export const Container = styled.div`

  .divGerarRel {
    padding-top: 5px;
    padding-bottom: 10px;
  }

  .btnGerarRel {
    padding: 5px;
    background: white;
    border: 1px solid rgb(26, 120, 74);
    color: green;
  }

  .btnForm {
    color: #fff !important;
    width: 200px;
    height: 40px;
    border: none;
    border-radius: 50px;
    font-weight: 500;
    font-size: 1em;
  }

  .btnSearch {
    background: #1a784a !important;
  }

  .btnClear {
    background: #fc6836 !important;
  }

  @media (max-width: 600px) {
    .ant-row-center {
      flex-direction: column !important;
    }

    .ant-col-8 {
      max-width: 100% !important;
    }
  }
`;

