import React from 'react';
import { Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';
import { TableCompisition } from 'components/composicao';
import { FiltersMapProvider } from 'context/mapa/filters_map';
import { TrackingHistoryProvider } from 'context/tracking_history';
import { MonitoringProvider } from 'context/monitoring';

export function PdfComposicao() {
  return (
    <>
      <FiltersMapProvider>
        <MonitoringProvider>
          <TrackingHistoryProvider>
            <>
              <Breadcrumb style={{ padding: '10px' }}>
                <Breadcrumb.Item>
                  {/* @ts-expect-error Server Component */}
                  <Link to="/">Página Inicial</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>Composição</Breadcrumb.Item>
              </Breadcrumb>
              <TableCompisition />
            </>
          </TrackingHistoryProvider>
        </MonitoringProvider>
      </FiltersMapProvider>
    </>
  );
}

