import { combineReducers } from 'redux';

import tipoVeiculo from './tipoVeiculo/reducer';
import veiculo from './veiculo/reducer';
import equipamento from './equipamento/reducer';
import vinculada from './vinculada/reducer';
import lotacao from './lotacao/reducer';
import prefixo from './prefixo/reducer';
import ais from './ais/reducer';
import monitoring from './monitoring/reducer';
import gruposAtivo from './gruposAtivos/reducer';
import ocorrencia from './ocorrencia/reducer';

export default combineReducers({
  tipoVeiculo,
  veiculo,
  equipamento,
  vinculada,
  lotacao,
  prefixo,
  ais,
  monitoring,
  gruposAtivo,
  ocorrencia,
});
