import styled from 'styled-components';

const Container = styled.div`
  margin: 20px;

  .row {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  .card-01 {
    /* background: #03d29f; */
    position: relative;
    flex: 1;
    max-width: 600px;
    height: 150px;
    margin: 10px;
    border-radius: 5px;
    cursor: pointer;
    overflow: hidden;
    /* box-shadow: 0 0 5px rgba(0, 0, 0, 0.2); */
  }

  .card-02 {
    background: #50a7ff;
    position: relative;
    flex: 1;
    max-width: 460px;
    height: 200px;
    margin: 10px;
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  }

  .card-04 {
    background: transparent;
    position: relative;
    flex: 1;
    max-width: 460px;
    height: 100px;
    margin: 10px;
  }

  .card-03 {
    background: #fff;
    position: relative;
    flex: 1;
    max-width: 580px;
    height: 120px;
    margin-left: 15px;
    margin-top: 15px;
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    overflow: auto;
  }

  .card-icon-orange {
    background: #fc6836;
    position: relative;
    flex: 1;
    max-width: 90px;
    height: 90px;
    margin: 0px;
    margin-top: -135px;
    border-radius: 10px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  }

  .card-icon-green {
    background: #03d29f;
    position: relative;
    flex: 1;
    max-width: 90px;
    height: 90px;
    margin: 0px;
    margin-top: -135px;
    border-radius: 10px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  }

  .card-icon-blue {
    background: #368cfc;
    position: relative;
    flex: 1;
    max-width: 90px;
    height: 90px;
    margin: 0px;
    margin-top: -135px;
    border-radius: 10px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  }

  .card-icon-yello {
    background: #d6d463;
    position: relative;
    flex: 1;
    max-width: 90px;
    height: 90px;
    margin: 0px;
    margin-top: -135px;
    border-radius: 10px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  }

  .card-icon-red {
    background: #fc3667;
    position: relative;
    flex: 1;
    max-width: 90px;
    height: 90px;
    margin: 0px;
    margin-top: -135px;
    border-radius: 10px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  }

  .card-icon-grey {
    background: grey;
    position: relative;
    flex: 1;
    max-width: 90px;
    height: 90px;
    margin: 0px;
    margin-top: -135px;
    border-radius: 10px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  }

  .iconComponent {
    height: 40px;
    width: 40px;
    margin: 25px;
    fill: #fff;
    transition: 500ms;
  }

  .iconVehicle {
    filter: brightness(100) invert(1);
    scale: 1.2;
  }

  .icon-Phosphor {
    filter: brightness(100) invert(1);
    scale: 1.5;
  }

  .card-01:hover .iconComponent {
    height: 50px;
    width: 50px;
    margin: 20px;
  }

  .img-icon-phospohor {
    padding: 27px;
  }

  .card-01:hover .icon-Phosphor {
    animation-name: animation;
    animation-duration: 1s;
    scale: 1.5;
  }

  @keyframes animation {
    from {
      scale: 1.5;
    }
    to {
      scale: 1.8;
    }
  }

  .img-icon img {
    height: 40px;
    width: 40px;
    margin: 25px;
    transition: 650ms;
  }

  .card-01:hover .img-icon img {
    height: 50px;
    width: 50px;
    margin: 20px;
  }

  .title-card {
    width: 100vw;
    font-size: 25px;
    font-weight: 600;
    color: #008442;
    flex: 1;
    margin-left: 97px;
    margin-top: -60px;
  }

  .subtitle-card {
    font-size: 16px;
    flex: 1;
    padding-top: 50px;
    padding-right: 10px;
    margin-left: 80px;
  }

  @media (max-width: 800px) {
    .card-01 {
      flex: 100%;
      max-width: 600px;
    }
  }

  @media (max-width: 800px) {
    .subtitle-card {
      flex: 100%;
      max-width: 600px;
    }
  }

  @media screen and (min-width: 500px) {
    .title-card {
      font-size: calc(16px + (8 / 1200) * 100 * 1vw);
    }
  }
`;

export default Container;
