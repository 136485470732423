import { ActionTypesReutils } from '~/utils/reutils';

export default {
  // Types Tipo Veiculo

  ...ActionTypesReutils('TIPO_VEICULO'),

  // Types Veiculo

  ...ActionTypesReutils('VEICULO'),

  // Types Vinculada

  ...ActionTypesReutils('VINCULADA'),

  // Types EQUIPAMENTO

  ...ActionTypesReutils('EQUIPAMENTO'),

  // Types LOTAÇÃO

  ...ActionTypesReutils('LOTACAO'),

  // Types Prefixo

  ...ActionTypesReutils('PREFIXO'),

  // Types Prefixo

  ...ActionTypesReutils('AIS'),

  MONITORING_PLACAS_ALL_SUC: '@Monitoring/GET_ALL_PLACA_SUCC',
  MONITORING_ALERT_ALL_REQUEST: '@Monitoring/GET_ALL_ALERT_REQ',
  MONITORING_ALERT_ALL_SUCCESS: '@Monitoring/GET_ALL_ALERT_SUC',
  MONITORING_AIS_ALL_REQUEST: '@Monitoring/GET_ALL_AIS_REQ',
  MONITORING_AIS_ALL_SUCCESS: '@Monitoring/GET_ALL_AIS_SUC',
  MONITORING_VIN_ALL_REQUEST: '@Monitoring/GET_ALL_VIN_REQ',
  MONITORING_VIN_ALL_SUCCESS: '@Monitoring/GET_ALL_VIN_SUC',
  MONITORING_VEI_ALL_REQUEST: '@Monitoring/GET_ALL_RESUME_VEICHLES_REQ',
  MONITORING_VEI_ALL_SUCCESS: '@Monitoring/GET_ALL_RESUME_VEICHLES_SUC',
  MONITORING_ERROR: '@Monitoring/ERROR',
  MONITORING_GRUPOS_ATIVO_SUCCESS: '@Monitoring/GET_ALL_ATIVO_SUC',
  MONITORING_ERROR_ATIVO: '@Monitoring_ATIVO/ERROR',

  // Types Ocorrencia
  OCORRENCIA_LIST_REQUEST: '@ocorrencia/LIST_REQUEST',
  OCORRENCIA_LIST_SUCCESS: '@ocorrencia/LIST_SUCCESS',
  OCORRENCIA_GET_ID_REQUEST: '@ocorrencia/GET_BY_ID_REQUEST',
  OCORRENCIA_GET_ID_SUCCESS: '@ocorrencia/GET_BY_ID_SUCCESS',
  OCORRENCIA_ERROR: '@ocorrencia/ERROR',
};
