/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { useHistory, Link } from 'react-router-dom';
import { Breadcrumb } from 'antd';

import IconConectados from '../../imgs/conectado.svg';
// import IconDesatualizada from '../../imgs/desatualizado.svg';
import IconDesconectado from '../../imgs/desconectado.svg';
import IconPersonalizado from '../../imgs/personalizado.svg';
// import { useAuth } from '../../context/auth';
// import alertaLogo from '../../imgs/alerta.svg';

import './styles.css';
import { ContainerReportsList } from './styles';

function Relatorios() {
  const history = useHistory();

  // const { data } = useAuth();
  // const { roles } = data;

  // const isPermitted = useCallback(
  //   role => {
  //     return roles.filter(item => item === role).length > 0;
  //   },
  //   [roles]
  // );

  return (
    <div>
      <Breadcrumb style={{ padding: '10px' }}>
        <Breadcrumb.Item>
          {/* @ts-expect-error Server Component */}
          <Link to="/">Página Inicial</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Relatorios</Breadcrumb.Item>
      </Breadcrumb>
      <ContainerReportsList>
        <div
          className="card-02"
          onClick={() => history.push('/pdfDesconectados')}
        >
          <div className="titleContainer">
            <div className="title-card">Viaturas desconectadas</div>

            <div className="subtitle-card">
              Relatório de veículos desconectados sem mandar posição - (48
              horas).
            </div>
          </div>
          <div className="card-icon card-icon-blue">
            <div className="img-icon">
              <img
                src={IconDesconectado}
                alt="desconectado"
                className="iconComponent"
              />
              {/* <IconCerca className="iconComponent" /> */}
            </div>
          </div>
        </div>

        <div
          className="card-02 "
          onClick={() => history.push('/pdfConectados')}
        >
          <div className="titleContainer">
            <div className="title-card">Viaturas conectadas</div>
            <div className="subtitle-card">
              Veículos conectados que não enviam posição a pelo menos 30
              minutos.
            </div>
          </div>

          <div className="card-icon card-icon-green">
            <div className="img-icon">
              <img
                src={IconConectados}
                alt="conectados"
                className="iconComponent"
              />
            </div>
          </div>
        </div>

        <div
          className="card-02"
          onClick={() => history.push('/pdfPersonalizado')}
        >
          <div className="titleContainer">
            <div className="title-card">Personalizado</div>
            <div className="subtitle-card">
              Crie um documento personalizado.
            </div>
          </div>

          <div className="card-icon card-icon-orange">
            <div className="img-icon">
              <img
                src={IconPersonalizado}
                alt="personalizado"
                className="iconComponent"
              />
              {/* <IconCerca className="iconComponent" /> */}
            </div>
          </div>
        </div>
      </ContainerReportsList>
    </div>
  );
}

export default Relatorios;
