import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { ConfigProvider } from 'antd';
import BR from 'antd/es/locale/pt_BR';

import moment from 'moment';
import Root from './pages';
import GlobalStyle from './styles/global';
import AppProvider from './context';
import store from './store/index.js';

import 'moment/locale/pt-br';

function App() {
  moment.locale('pt-br');
  return (
    <>
      {/* @ts-expect-error Server Component */}
      <Provider store={store}>
        <ConfigProvider locale={BR}>
          {/* @ts-expect-error Server Component */}
          <Router basename="web/rotas">
            <AppProvider>
              <Root />
            </AppProvider>
            {/* @ts-expect-error Server Component */}
            <GlobalStyle />
            <ToastContainer theme="light" />
          </Router>
        </ConfigProvider>
      </Provider>
    </>
  );
}

export default App;

