import { TrackingHistoryContext } from 'context/tracking_history';
import { useContextSelector } from 'use-context-selector';

export function useHistoryResetFunctions() {
  const setLoadingHistorico = useContextSelector(
    TrackingHistoryContext,
    context => context.setLoadingHistorico
  );

  const resetAllData = useContextSelector(
    TrackingHistoryContext,
    context => context.resetAllData
  );

  const cleanSelectedAis = useContextSelector(
    TrackingHistoryContext,
    context => context.cleanSelectedAis
  );

  const setChoosingOrigin = useContextSelector(
    TrackingHistoryContext,
    context => context.setChoosingOrigin
  );

  const choosingOrigin = useContextSelector(
    TrackingHistoryContext,
    context => context.choosingOrigin
  );

  return {
    choosingOrigin,
    setLoadingHistorico,
    resetAllData,
    cleanSelectedAis,
    setChoosingOrigin,
  };
}

