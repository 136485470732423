import ACTION from "../actionTypes";

export function getAll(params) {
  return {
    type: ACTION.TIPO_VEICULO_GET_ALL_REQUEST,
    params
  };
}

export function getAllSuccess(data) {
  return {
    type: ACTION.TIPO_VEICULO_GET_ALL_SUCCESS,
    data
  };
}

export function getAllPage(params) {
  return {
    type: ACTION.TIPO_VEICULO_GET_SEARCH_PAGE,
    params
  };
}

export function getAllPageSuccess(data) {
  return {
    type: ACTION.TIPO_VEICULO_GET_SEARCH_PAGE_SUCCESS,
    data
  };
}

export function create(data) {
  return {
    type: ACTION.TIPO_VEICULO_POST_REQUEST,
    data
  };
}

export function createSuccess() {
  return {
    type: ACTION.TIPO_VEICULO_POST_SUCCESS
  };
}

export function getById(id) {
  return {
    type: ACTION.TIPO_VEICULO_GET_ID_REQUEST,
    id
  };
}

export function getByIdSuccess(data) {
  return {
    type: ACTION.TIPO_VEICULO_GET_ID_SUCCESS,
    data
  };
}

export function update(data) {
  return {
    type: ACTION.TIPO_VEICULO_UPDATE_REQUEST,
    data
  };
}

export function updateSuccess(data) {
  return {
    type: ACTION.TIPO_VEICULO_UPDATE_SUCCESS,
    data
  };
}

export function deleteRequest({ id, params }) {
  return {
    type: ACTION.TIPO_VEICULO_DELETE_REQUEST,
    id,
    params
  };
}

export function deleteSuccess() {
  return {
    type: ACTION.TIPO_VEICULO_DELETE_SUCCESS
  };
}

export function error(message) {
  return {
    type: ACTION.TIPO_VEICULO_ERROR,
    message
  };
}
