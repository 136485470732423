import React from 'react';

import { Link } from 'react-router-dom';

const labelByPath = {
  tipoVeiculo: {
    breadcrumbName: 'Tipo de veículo',

    path: '/tipoVeiculo',
  },

  veiculo: {
    breadcrumbName: 'Cadastro veículo',

    path: '/veiculo',
  },

  veiculo: {
    breadcrumbName: 'composicao',

    path: '/composicao',
  },

  equipamentos: {
    breadcrumbName: 'Cadastro equipamento',

    path: '/equipamentos',
  },

  vinculada: {
    breadcrumbName: 'Vinculada',

    path: '/vinculada',
  },

  lotacao: {
    breadcrumbName: 'Lotação',

    path: '/lotacao',
  },

  prefixo: {
    breadcrumbName: 'Prefixo',

    path: '/prefixo',
  },

  ais: {
    breadcrumbName: 'AIS',

    path: '/ais',
  },

  cerca: {
    breadcrumbName: 'Cerca Eletrônica',

    path: '/cerca',
  },

  tipoCerca: {
    breadcrumbName: 'Tipo de Cerca',

    path: '/tipoCerca',
  },
};

export function formatRoutesByPath(pathCompleto) {
  const arrayParams = pathCompleto.split('/');

  const paths = arrayParams.slice(1, arrayParams.length);

  const routes = [];

  routes.push({
    breadcrumbName: 'Página inicial',

    path: '/',
  });

  if (paths.length) {
    routes.push(labelByPath[paths[0]]);

    if (paths.length > 2) {
      routes.push({
        breadcrumbName: 'Formulário',

        path: pathCompleto,
      });
    } else if (paths.length === 2) {
      routes.push({
        breadcrumbName: 'Formulário - Cadastro',

        path: pathCompleto,
      });
    }
  }

  const itemRender = route => {
    const last = routes.indexOf(route) === routes.length - 1;

    const { path } = route;

    return last ? (
      <span>{route.breadcrumbName}</span>
    ) : (
      <Link to={path}>{route.breadcrumbName}</Link>
    );
  };

  return { routes, itemRender };
}
