import React, { useCallback, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Card, Input, Table, Breadcrumb } from 'antd';
import debounce from 'lodash.debounce';

import { ServiceVeiculo } from 'services/modulos/service_veiculo';
import { toast } from 'react-toastify';
import { PageHeader } from '../../../components/PageHeader';
import { templateColumns } from './columns';

export const TITLE = 'Veículo';
const SUBTITLE = 'Gerenciamento de veículo';
export const BASE_ROLE = 'TIPO_VEICULO';
export const MODULE_ROUTE = 'veiculo';

const { Search } = Input;

export function CadastroVeiculoList() {
  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalElements, setTotalElements] = useState();
  // const history = useHistory();

  const initFetch = useCallback(async () => {
    try {
      setLoading(true);
      const allVehicles = await ServiceVeiculo.getAllPageable({
        page,
        size: 6,
      });
      setTotalElements(allVehicles.totalElements);
      setData(allVehicles.content);
    } catch (err) {
      toast.error('Erro ao carregar veiculos');
      console.error('erro no get all:', err);
    } finally {
      setLoading(false);
    }
  }, [page]);

  useEffect(() => {
    initFetch();
  }, [initFetch]);

  // const handleNewRegister = useCallback(() => {
  //   // "modal callbak!!!");
  //   // console.log(`${MODULE_ROUTE}/form`);
  //   history.push(`${MODULE_ROUTE}/form`);
  // }, [history]);

  const handleSearch = useCallback(
    async value => {
      try {
        setLoading(true);
        setPage(1);
        if (value) {
          const getSearchedVehicle = await ServiceVeiculo.getByPlaca(value);
          setData([getSearchedVehicle]);
          setTotalElements(1);
        } else {
          initFetch();
        }
      } catch (err) {
        console.error('erro no get by placa:', err);
        initFetch();
      } finally {
        setLoading(false);
      }
    },
    [initFetch]
  );

  // const handleDelete = useCallback(async value => {
  //   try {
  //     setLoading(true);
  //     setPage(1);
  //     await ServiceVeiculo.delete(value.id);
  //     toast.success('Veiculo deletado');
  //   } catch (err) {
  //     toast.error('Erro ao deletar');
  //     console.error('erro no delete:', err);
  //   } finally {
  //     setLoading(false);
  //   }
  // }, []);

  const onSearch = useCallback(
    value => {
      if (page === 1) {
        handleSearch(value);
      } else {
        setPage(1);
      }
    },
    [handleSearch, page]
  );

  const debouncedSave = useCallback(
    debounce(searchValue => onSearch(searchValue), 1000),
    []
  );

  return (
    <div>
      <Breadcrumb style={{ padding: '10px' }}>
        <Breadcrumb.Item>
          <Link to="/">Página Inicial</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to="/cadastros">Cadastros</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Veículos</Breadcrumb.Item>
      </Breadcrumb>
      <Card>
        <PageHeader
          title={TITLE}
          subTitle={SUBTITLE}
          baseRole={BASE_ROLE}
          // onNewRegister={handleNewRegister}
          activeBackHistory
        />

        <div
          style={{
            marginBottom: 8,
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Search
            placeholder="Pesquisa por placa"
            enterButton="Pesquisar"
            size="large"
            onChange={e => debouncedSave(e.target.value)}
          />
          <div />
        </div>

        <Table
          tableLayout="fixed"
          rowKey="key"
          loading={loading}
          columns={templateColumns}
          dataSource={data}
          pagination={{
            onChange: pageChange => {
              setPage(pageChange);
            },
            current: page,
            defaultCurrent: page,
            total: totalElements,
            showSizeChanger: false,
          }}
        />
      </Card>
    </div>
  );
}
