import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import logo64 from '../../../imgs/encoded/brasaosspds.base64';

let contents = [];
let printeredImage;

export function printData(results, printUri) {
  contents = results;
  printeredImage = printUri;
}

export const pdfGen = async () => {
  const win = window.open('', '_blank');
  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  const reportTitle = [
    {
      image: logo64,
      width: 179,
      height: 63,
      alignment: 'center',
      margin: [0, 10, 0, 10],
    },
    {
      text: 'Relatório histórico de Auditoria\n',
      bold: true,
      fontSize: 13,
      alignment: 'center',
      margin: [0, 4, 0, 0],
    },
    {
      text: `Segue abaixo a lista com informações de veículos (${contents.length}).\n`,
      bold: false,
      fontSize: 9,
      alignment: 'center',
      margin: [0, 4, 0, 0],
    },
    {
      text: 'Dados de posicionamento fornecidos pelas empresas Oi/CSBrasil\n',
      bold: false,
      fontSize: 9,
      alignment: 'center',
      margin: [0, 4, 0, 0],
    },
  ];

  const results = contents.map(content => {
    return [
      {
        text: `${content.placa}\n${content.prefixo}`,
        fontSize: 9,
        margin: [0, 2, 0, 2],
      },
      { text: content.position.x, fontSize: 9, margin: [0, 2, 0, 2] },
      { text: content.position.y, fontSize: 9, margin: [0, 2, 0, 2] },
      {
        text: content.engineOn === '1' ? 'Ligado' : 'Desligado',
        fontSize: 9,
        margin: [0, 2, 0, 2],
      },
      { text: `${content.speed} Km/h`, fontSize: 9, margin: [0, 2, 0, 2] },
      {
        text: `${new Date(
          content.datePosition
        ).toLocaleDateString()} \n ${new Date(
          content.datePosition
        ).toLocaleTimeString()}`,
        fontSize: 9,
        margin: [0, 2, 0, 2],
      },
      {
        text: `${(content.address.amenity === undefined
          ? ''
          : `${content.address.amenity}, `) +
          (content.address.road === undefined
            ? content.address.state_district
            : content.address.road)}, ${
          content.address.postcode === undefined
            ? content.address.municipality
            : content.address.postcode
        }, ${
          content.address.town === undefined
            ? content.address.suburb
            : content.address.town
        }`,
        fontSize: 9,
        margin: [0, 2, 0, 2],
      },
    ];
  });

  const details = [
    {
      image: printeredImage,
      width: 540, // 580
      height: 880, // 1180
      alignment: 'center',
      margin: [10, 10, 10, 30],
    },
    {
      table: {
        headerRows: 1,
        widths: ['auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
        body: [
          [
            {
              text: 'Placa',
              style: 'tableHeader',
              fontSize: 8,
            },
            { text: 'Longitude', style: 'tableHeader', fontSize: 8 },
            { text: 'Latitude', style: 'tableHeader', fontSize: 8 },
            { text: 'Estado Motor', style: 'tableHeader', fontSize: 8 },
            { text: 'Velocidade', style: 'tableHeader', fontSize: 8 },
            { text: 'Data posição', style: 'tableHeader', fontSize: 8 },
            { text: 'Logradouro', style: 'tableHeader', fontSize: 8 },
          ],
          ...results,
        ],
      },
      alignment: 'center',
      margin: [2, 2, 0, 0],
      layout: {
        fillColor(rowIndex, node, columnIndex) {
          return rowIndex % 2 === 0 ? '#CCCCCC' : null;
        },
      },
    },
  ];
  const footer = [
    {
      text: [
        {
          text:
            'Secretaria de Segurança Pública e Defesa Social. Avenida Bezerra de Menezes, 581 - São Gerardo\n CEP 60325-003 . Fortaleza Ceará . Fone: (85) 3101-6501',
        },
      ],
      fontSize: 8,
      margin: [0, 10, 0, 45],
      alignment: 'center',
    },
  ];

  const docDefinitions = {
    pageMargins: [40, 140, 40, 40],
    pageSize: 'A4',
    alignment: 'center',
    header: [reportTitle],
    content: [details],
    footer: [footer],
  };
  pdfMake.createPdf(docDefinitions).open({}, win);
};

export default [printData(), pdfGen];
