import { MonitoringContext } from 'context/monitoring';
import { useContextSelector } from 'use-context-selector';

export function useMonitoringMapInfo() {
  const map = useContextSelector(MonitoringContext, context => context.map);

  const setMap = useContextSelector(
    MonitoringContext,
    context => context.setMap
  );

  const data = useContextSelector(MonitoringContext, context => context.data);

  const statusSocketTVP = useContextSelector(
    MonitoringContext,
    context => context.statusSocketTVP
  );

  const markersConectados = useContextSelector(
    MonitoringContext,
    context => context.markersConectados
  );

  const markersDesconectados = useContextSelector(
    MonitoringContext,
    context => context.markersDesconectados
  );

  const markersRadio = useContextSelector(
    MonitoringContext,
    context => context.markersRadio
  );

  const markersSemCadastro = useContextSelector(
    MonitoringContext,
    context => context.markersSemCadastro
  );

  const showAlerts = useContextSelector(
    MonitoringContext,
    context => context.showAlerts
  );

  const showConectadas = useContextSelector(
    MonitoringContext,
    context => context.showConectadas
  );

  const showDesconectadas = useContextSelector(
    MonitoringContext,
    context => context.showDesconectadas
  );

  const showRadio = useContextSelector(
    MonitoringContext,
    context => context.showRadio
  );

  const showSemCadastro = useContextSelector(
    MonitoringContext,
    context => context.showSemCadastro
  );

  const showOccurrences = useContextSelector(
    MonitoringContext,
    context => context.showOccurrences
  );

  const showTooltipConectadas = useContextSelector(
    MonitoringContext,
    context => context.showTooltipConectadas
  );

  const showTooltipDesconectadas = useContextSelector(
    MonitoringContext,
    context => context.showTooltipDesconectadas
  );

  const showTooltipRadio = useContextSelector(
    MonitoringContext,
    context => context.showTooltipRadio
  );

  const showTooltipSemCadastro = useContextSelector(
    MonitoringContext,
    context => context.showTooltipSemCadastro
  );

  const showTooltipOccurrences = useContextSelector(
    MonitoringContext,
    context => context.showTooltipOccurrences
  );

  const fencesSelected = useContextSelector(
    MonitoringContext,
    context => context.fencesSelected
  );

  const setLoadingFences = useContextSelector(
    MonitoringContext,
    context => context.setLoadingFences
  );

  const dataOccurrences = useContextSelector(
    MonitoringContext,
    context => context.dataOccurrences
  );

  const activeAlert = useContextSelector(
    MonitoringContext,
    context => context.activeAlert
  );

  const firstLoadOfActiveAlert = useContextSelector(
    MonitoringContext,
    context => context.firstLoadOfActiveAlert
  );

  const mapOccurrences = useContextSelector(
    MonitoringContext,
    context => context.mapOccurrences
  );

  const showOnlyVehiclesFences = useContextSelector(
    MonitoringContext,
    context => context.showOnlyVehiclesFences
  );

  const vehiclesOfSelectedFences = useContextSelector(
    MonitoringContext,
    context => context.vehiclesOfSelectedFences
  );

  const onActivateAlertSocket = useContextSelector(
    MonitoringContext,
    context => context.onActivateAlertSocket
  );

  const onDeactiveAlertSocket = useContextSelector(
    MonitoringContext,
    context => context.onDeactiveAlertSocket
  );

  return {
    map,
    setMap,
    data,
    statusSocketTVP,
    markersConectados,
    markersDesconectados,
    markersRadio,
    markersSemCadastro,
    showAlerts,
    showConectadas,
    showDesconectadas,
    showRadio,
    showSemCadastro,
    showOccurrences,
    showTooltipConectadas,
    showTooltipDesconectadas,
    showTooltipRadio,
    showTooltipSemCadastro,
    showTooltipOccurrences,
    fencesSelected,
    setLoadingFences,
    dataOccurrences,
    activeAlert,
    firstLoadOfActiveAlert,
    mapOccurrences,
    showOnlyVehiclesFences,
    vehiclesOfSelectedFences,
    onActivateAlertSocket,
    onDeactiveAlertSocket,
  };
}

