import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;

  .gmnoprint {
    left: 60px !important;
    top: 5px !important;
  }

  .gm-bundled-control,
  .gm-bundled-control-on-bottom {
    display: none;
  }

  .gm-style-mtc {
    display: flex !important;
    flex-direction: column !important;

    button {
      height: 30px !important;
    }
  }

  button[title='Stop drawing'],
  button[title='Draw a shape'] {
    font-size: 40px;
    width: 40px;
    height: 40px;
    display: flex;
    padding: 30% !important;
    right: 400px;
    top: 15px;
  }

  .iconesriginDestionation {
    display: flex;
    margin: 0px 10px;
    position: relative;
    transform: translateY(25%);
    text-align: center;
    vertical-align: 50px;
    font-size: 1rem;
    color: #959595;
  }

  .iconeRastreamento {
    display: flex;
    flex-direction: column;
    align-items: center;
    top: 2%;
    right: 22%;
    position: absolute;
    z-index: 99999;
    color: red;
    font-size: 14px;
  }

  .iconeRastreamentoCollapsed {
    display: flex;
    flex-direction: column;
    align-items: center;
    top: 2%;
    right: 7%;
    position: absolute;
    z-index: 99999;
    color: red;
    font-size: 14px;
  }

  .buttonSection {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: column;
    align-items: space-around;
    flex-direction: row;
    top: 60px;
    left: 30%;
    position: absolute;
    z-index: 998;
    color: red;
    font-size: 14px;
    width: 30%;
    min-width: 400px;

    .button {
      color: rgb(150, 150, 150) !important;
      border-radius: 3px !important;
      height: 32px !important;
      width: 200px !important;
      box-shadow: 0 2px 2px rgb(0 0 0 / 11%);
      font-weight: 500;
      border: none !important;
    }

    .alertButton {
      background-color: white !important;
    }

    .alertIcon {
      width: 18px;
      height: 18px;
      margin-right: 8px;
      filter: grayscale(1);
    }

    .button:hover {
      .alertIcon {
        filter: grayscale(0);
      }
      color: #1a784a !important;
    }

    .gerarRelatorio {
      margin-right: 10px;
    }
  }

  .iconeHistorico {
    display: flex;
    flex-direction: column;
    align-items: center;
    top: 12%;
    right: 20.5%;
    position: absolute;
    z-index: 999;

    #icone {
      font-size: 30px;
    }
  }

  .iconeNotificacao {
    display: flex;
    flex-direction: column;
    align-items: center;
    top: 22%;
    right: 22%;
    position: absolute;
    z-index: 999;
    color: red;

    #icone {
      font-size: 30px;
      -webkit-animation: waves 2.5s linear;
      animation: waves 2.5s linear;
      -webkit-animation-delay: 0.1s;
      animation-delay: 0.1s;
      animation-iteration-count: infinite;
    }
  }

  .iconeNotificacaoCollapsed {
    display: flex;
    flex-direction: column;
    align-items: center;
    top: 22%;
    right: 7%;
    position: absolute;
    z-index: 999;
    color: red;

    #icone {
      font-size: 30px;
      -webkit-animation: waves 2.5s linear;
      animation: waves 2.5s linear;
      -webkit-animation-delay: 0.1s;
      animation-delay: 0.1s;
      animation-iteration-count: infinite;
    }
  }

  .iconeCarregando {
    display: flex;
    flex-direction: column;
    align-items: center;
    top: 12%;
    right: 22.5%;
    position: absolute;
    z-index: 999;

    #icone {
      font-size: 30px;
      color: rgb(75, 74, 76) !important; // color: rgb(255, 196, 12) !important;
    }

    #iconeCarro {
      top: 8%;
      color: white;
      right: 6px;
      position: absolute;
      width: 20px;
    }
  }

  .iconeCarregandoCollapsed {
    display: flex;
    flex-direction: column;
    align-items: center;
    top: 12%;
    right: 7%;
    position: absolute;
    z-index: 999;

    #icone {
      font-size: 30px;
      color: rgb(75, 74, 76) !important; // color: rgb(255, 196, 12) !important;
    }

    #iconeCarro {
      top: 8%;
      color: white;
      right: 6px;
      position: absolute;
      width: 20px;
    }
  }

  #map div div div:last-child div:last-child div div div:nth-child(3) {
    left: 0 !important;
    top: 30px !important;
    border-top-width: 24px;
    position: absolute;
    transform: rotate(90deg);
  }

  .satelite {
    width: 30px;
    height: 40px;
    filter: invert(0.9);
  }

  .conectado {
    color: green !important;
  }

  .iconesriginDestionation h3 {
    color: #cecece;
  }

  .btn {
    margin-left: 5px;
  }

  .tbnTitle {
    display: none;
  }

  @media only screen and (max-width: 1400px) {
    .buttonSection {
      .button {
        width: 50px !important;
      }

      #text {
        display: none !important;
      }

      .gerarRelatorio {
        margin-right: 10px;
      }
    }
  }

  /* media  */
  @media only screen and (max-width: 768px) {
    .buttonSection {
      left: 12%;

      .button {
        width: 50px !important;
      }

      #text {
        display: none !important;
      }

      .gerarRelatorio {
        margin-right: 10px;
      }
    }
    .iconeRastreamento {
      right: 3%;
      top: 8%;
    }
    .iconeHistorico {
      right: 3%;
    }
    .iconeRastreamentoCollapsed {
      right: 3%;
      top: 8%;
    }
    .iconeCarregando {
      top: 19%;
      right: 3%;
    }
    .iconeCarregandoCollapsed {
      top: 19%;
      right: 3%;
    }
    .iconeNotificacao {
      right: 3%;
    }
    .iconeNotificacaoCollapsed {
      right: 3%;
    }
  }
  @media only screen and (max-width: 1000px) {
    .buttonSection {
      left: 12% !important;
      min-width: 300px;
    }

    .gmnoprint {
      left: 3px !important;
      top: 700px !important;
    }
  }

  @media only screen and (max-width: 769px) {
    .buttonSection {
      left: 12% !important;
      min-width: 300px;
    }

    .gmnoprint {
      left: 10px !important;
      top: 50px !important;
      display: flex;
      flex-direction: column;
    }
  }

  @media only screen and (min-width: 769px) and (max-width: 1300px) {
    .iconeRastreamento {
      right: 260px;
    }
    .iconeHistorico {
      right: 260px;
    }
    .iconeCarregando {
      right: 260px;
    }
    .address {
      flex-direction: column;
      left: 50%;
      width: 40%;
    }

    .addressField {
      margin-bottom: 10px;
      width: 90%;
      margin-left: 30px;
    }

    .iconesriginDestionation {
      position: absolute;
      margin: 0px;
      flex-direction: column;
      /* left: -150px; */
      transform: translateY(0%);
    }
    .btn {
      margin-left: auto;
      margin-right: auto;
      text-align: center;
      left: 5%;
      width: 150px;
      display: flex;
      padding-top: 8px;
      padding-left: 30px;
      justify-content: row;
      text-align: center;
    }
    .tbnTitle {
      display: block;
    }
  }

  @media only screen and (max-width: 600px) {
    .buttonSection {
      min-width: 300px;
    }
  }
`;

export default Container;

