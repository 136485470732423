import { AuthContext } from 'context/auth';
import { globalContext } from 'context/global';
import { useContextSelector } from 'use-context-selector';

export function useGlobalLoadingRequest() {
  const loadingRequest = useContextSelector(
    globalContext,
    context => context.loadingRequest
  );

  return {
    loadingRequest,
  };
}

