import { Select } from 'antd';

import styled from 'styled-components';

export const SelectCustom = styled(Select)`
  /* .ant-select-selector {

    border-radius: 30px !important;

  } */
`;

