/* eslint-disable */
import {
  Breadcrumb,
  Form,
  Col,
  Row,
  DatePicker,
  Button,
  Space,
  Input,
  Table,
  Select,
  Spin,
} from 'antd';
import { Link } from 'react-router-dom';
import React, { useCallback, useState, useEffect, useRef } from 'react';
import moment from 'moment';
import { toast } from 'react-toastify';
import { Container } from './style';
import columns from '~/columns/PDF/Alertas/columns';
import { pdfGen } from './report';
import { ServiceReportAlert } from '~/services/modulos/serviceReportAlert';
import { useGlobalLoadingRequest } from 'hooks/global/useLoadingRequest';
import { useGlobalFences } from 'hooks/global/useGlobalFences';
import { useGlobalVehicles } from 'hooks/global/useGlobalVehicles';

const PAGE_SIZE = 6;

export default function Alertas() {
  const [form] = Form.useForm();
  const [loadingSpin, setLoadingSpin] = useState(false);
  const [loadingReport, setLoadingReport] = useState(false);
  const [alerts, setAlerts] = useState({
    alertsFiltered: [],
    allAlerts: [],
    wasFiltered: false,
    totalElements: 0,
    initialTotalElements: 0,
  });
  const [optionsSelect, setOptionsSelect] = useState({
    placas: [],
    id: [],
    alertas: ['SAIU_DA_CERCA', 'ENTROU_NA_CERCA', 'PARADA_SUPERIOR_15_MIN'],
    cercas: [],
  });
  const valuesSearched = useRef({});
  const [page, setPage] = useState(1);
  const { RangePicker } = DatePicker;
  const now = new Date();

  const { vehicles } = useGlobalVehicles();

  const { fences } = useGlobalFences();

  const { loadingRequest: loadingSelect } = useGlobalLoadingRequest();

  const dataInicial = `${now.toISOString().split('T')[0]}T${'00:00:00'}.000Z`;
  const dataFinal = `${now.toISOString().split('T')[0]}T${'23:59:59'}.000Z`;

  const updateAlert = useCallback(newProperties => {
    setAlerts(prev => ({ ...prev, ...newProperties }));
  });

  const updateSelect = useCallback(newProperties => {
    setOptionsSelect(prev => ({ ...prev, ...newProperties }));
  });

  const loadSelectsOptions = useCallback(() => {
    const allPlacas = Object.keys(vehicles).map(p => p.placa);
    const allPlacasOrdely = allPlacas.filter(
      (item, index) => allPlacas.indexOf(item) === index
    );

    const allEquipments = Object.keys(vehicles).map(e => e.equipamento);
    const allEquipmentsOrdely = allEquipments.filter(
      (item, index) => allEquipments.indexOf(item) === index
    );

    const allFences = fences.current.data.map(c => c.nome);

    updateSelect({
      placas: allPlacasOrdely || [],
      id: allEquipmentsOrdely || [],
      cercas: allFences || [],
    });
  }, [vehicles, fences.currrent]);

  useEffect(() => {
    loadSelectsOptions();
  }, [loadSelectsOptions]);

  const initFetch = useCallback(async () => {
    try {
      const getAllReportAlertsFormated = [];
      const body = {
        dataInicial,
        dataFinal,
      };
      const getAllReportAlerts = await ServiceReportAlert.getAllPageable({
        page: page - 1,
        size: PAGE_SIZE,
        body,
      });

      getAllReportAlerts.content.map(alert => {
        const isOutFenceAlert = alert.tipoAlerta === 'SAIU_DA_CERCA';
        const isInFenceAlert = alert.tipoAlerta === 'ENTROU_NA_CERCA';
        let foraAIS = false;
        let foraCerca = false;
        let entrouAIS = false;
        let entrouCerca = false;
        if (isOutFenceAlert) {
          if (
            alert.nomeCerca &&
            alert.nomeCerca.indexOf('AIS') === 0 &&
            alert.nomeCerca.length === 5
          ) {
            foraAIS = isOutFenceAlert;
          } else {
            foraCerca = isOutFenceAlert;
          }
        } else if (isInFenceAlert) {
          if (
            alert.nomeCerca &&
            alert.nomeCerca.indexOf('AIS') === 0 &&
            alert.nomeCerca.length === 5
          ) {
            entrouAIS = isInFenceAlert;
          } else {
            entrouCerca = isInFenceAlert;
          }
        }

        const newAlert = {
          ...alert,
          tipoAlerta: foraAIS
            ? 'FORA DA AIS'
            : foraCerca
            ? 'FORA DA CERCA'
            : entrouAIS
            ? 'ENTROU NA AIS'
            : entrouCerca
            ? 'ENTROU NA CERCA'
            : alert.tipoAlerta,
          datePosition: moment(alert.datePosition).format(
            'DD-MM-YYYY HH:mm:ss'
          ),
        };

        getAllReportAlertsFormated.push(newAlert);
      });
      updateAlert({
        allAlerts: getAllReportAlertsFormated,
        alertsFiltered: getAllReportAlertsFormated,
        totalElements: getAllReportAlerts.totalElements,
        initialTotalElements: getAllReportAlerts.totalElements,
      });
    } catch (err) {
      console.error(err);
    }
  }, [page]);

  useEffect(() => {
    let cancelRequest = false;
    onSearch(cancelRequest, valuesSearched.current);

    return () => {
      cancelRequest = true;
    };
  }, [page]);

  const onSearch = useCallback(
    async (isCancelled, values) => {
      try {
        setLoadingSpin(true);
        const getAllReportAlertsFormated = [];
        const hasValue = Object.keys(values).some(v => values[v]);
        if (hasValue) {
          valuesSearched.current = values;
          if (!isCancelled) {
            const body = {
              placas: values.placa || [],
              tipoAlertas: values.tipo || [],
              idDevices: values.id || [],
              idCercas: values.cercas || [],
              dataInicial:
                values.date && values.date.length > 0
                  ? moment(values.date[0])
                  : dataInicial,
              dataFinal:
                values.date && values.date.length > 1
                  ? moment(values.date[1])
                  : dataFinal,
            };
            const getAllReportAlerts = await ServiceReportAlert.getAllPageable({
              page: page - 1,
              size: PAGE_SIZE,
              body,
            });

            getAllReportAlerts.content.map(alert => {
              const isOutFenceAlert = alert.tipoAlerta === 'SAIU_DA_CERCA';
              const isInFenceAlert = alert.tipoAlerta === 'ENTROU_NA_CERCA';
              let foraAIS = false;
              let foraCerca = false;
              let entrouAIS = false;
              let entrouCerca = false;
              if (isOutFenceAlert) {
                if (
                  alert.nomeCerca &&
                  alert.nomeCerca.indexOf('AIS') === 0 &&
                  alert.nomeCerca.length === 5
                ) {
                  foraAIS = isOutFenceAlert;
                } else {
                  foraCerca = isOutFenceAlert;
                }
              } else if (isInFenceAlert) {
                if (
                  alert.nomeCerca &&
                  alert.nomeCerca.indexOf('AIS') === 0 &&
                  alert.nomeCerca.length === 5
                ) {
                  entrouAIS = isInFenceAlert;
                } else {
                  entrouCerca = isInFenceAlert;
                }
              }

              const newAlert = {
                ...alert,
                tipoAlerta: foraAIS
                  ? 'FORA DA AIS'
                  : foraCerca
                  ? 'FORA DA CERCA'
                  : entrouAIS
                  ? 'ENTROU NA AIS'
                  : entrouCerca
                  ? 'ENTROU NA CERCA'
                  : alert.tipoAlerta,
                datePosition: moment(alert.datePosition).format(
                  'DD-MM-YYYY HH:mm:ss'
                ),
              };

              getAllReportAlertsFormated.push(newAlert);
            });
            updateAlert({
              alertsFiltered: getAllReportAlertsFormated,
              totalElements: getAllReportAlerts.totalElements,
            });
          }
        } else {
          initFetch();
        }
      } catch (e) {
        toast.error('Error ao carregar dados!');
        console.error('erro ao filtrar:', e);
      } finally {
        setLoadingSpin(false);
      }
    },
    [page, JSON.stringify(valuesSearched.current)]
  );

  const handleGenPdf = useCallback(async () => {
    try {
      setLoadingReport(true);
      const reportDataFiltered = [];
      const isSearch = Object.keys(valuesSearched.current).length;
      const filters = {
        placas: valuesSearched.current.placa || [],
        tipoAlertas: valuesSearched.current.tipo || [],
        idDevices: valuesSearched.current.id || [],
        idCercas: valuesSearched.current.cercas || [],
        dataInicial:
          valuesSearched.current.date && valuesSearched.current.date.length > 0
            ? moment(valuesSearched.current.date[0])
            : dataInicial,
        dataFinal:
          valuesSearched.current.date && valuesSearched.current.date.length > 1
            ? moment(valuesSearched.current.date[1])
            : dataFinal,
      };
      const body = {
        dataInicial,
        dataFinal,
      };
      const getAllReportAlerts = isSearch
        ? await ServiceReportAlert.getAllNoPageable(filters)
        : await ServiceReportAlert.getAllNoPageable(body);

      getAllReportAlerts.map(alert => {
        const isOutFenceAlert = alert.tipoAlerta === 'SAIU_DA_CERCA';
        const isInFenceAlert = alert.tipoAlerta === 'ENTROU_NA_CERCA';
        let foraAIS = false;
        let foraCerca = false;
        let entrouAIS = false;
        let entrouCerca = false;
        if (isOutFenceAlert) {
          if (
            alert.nomeCerca &&
            alert.nomeCerca.indexOf('AIS') === 0 &&
            alert.nomeCerca.length === 5
          ) {
            foraAIS = isOutFenceAlert;
          } else {
            foraCerca = isOutFenceAlert;
          }
        } else if (isInFenceAlert) {
          if (
            alert.nomeCerca &&
            alert.nomeCerca.indexOf('AIS') === 0 &&
            alert.nomeCerca.length === 5
          ) {
            entrouAIS = isInFenceAlert;
          } else {
            entrouCerca = isInFenceAlert;
          }
        }

        const newAlert = {
          ...alert,
          tipoAlerta: foraAIS
            ? 'FORA DA AIS'
            : foraCerca
            ? 'FORA DA CERCA'
            : entrouAIS
            ? 'ENTROU NA AIS'
            : entrouCerca
            ? 'ENTROU NA CERCA'
            : alert.tipoAlerta,
          datePosition: moment(alert.datePosition).format(
            'DD-MM-YYYY HH:mm:ss'
          ),
        };

        reportDataFiltered.push(newAlert);
      });

      pdfGen(reportDataFiltered);
    } catch (err) {
      toast.error('Erro ao gerar relatório!');
      console.error('erro ao gerar relatorio:', err);
    } finally {
      setLoadingReport(false);
    }
  }, [JSON.stringify(valuesSearched.current)]);

  const onReset = useCallback(async () => {
    try {
      valuesSearched.current = {};
      setPage(1);
      form.resetFields();
      initFetch();
    } catch (err) {
      console.error(err);
    }
  }, [JSON.stringify(valuesSearched.current)]);

  const onFinish = useCallback(
    values => {
      valuesSearched.current = values;
      if (page === 1) {
        onSearch(false, valuesSearched.current);
      } else {
        setPage(1);
      }
    },
    [page]
  );

  return (
    <Container>
      <Breadcrumb style={{ padding: '10px' }}>
        <Breadcrumb.Item>
          <Link to="/">Página Inicial</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to="/relatorios">Relatorios</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Alertas</Breadcrumb.Item>
      </Breadcrumb>

      <div
        style={{
          border: '1px solid #00aa3f',
          borderRadius: '15px',
          padding: '10px',
          marginBottom: '2%',
          fontWeight: 'bold',
        }}
      >
        <Form
          form={form}
          onFinish={values => onFinish(values)}
          layout="horizontal"
          style={{ padding: '20px' }}
        >
          <h1
            style={{
              textAlign: 'center',
            }}
          >
            Filtros
          </h1>
          <Row gutter={4} justify="center">
            <Col span={8}>
              <Form.Item label="Placa" name="placa">
                <Select
                  mode="multiple"
                  loading={loadingSelect.vehicles}
                  allowClear
                  options={
                    optionsSelect.placas.length
                      ? optionsSelect.placas.map(elements => {
                          return {
                            value: elements,
                            label: `${elements}`,
                          };
                        })
                      : [
                          {
                            value: '',
                            label: '',
                          },
                        ]
                  }
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Tipo" name="tipo">
                <Select
                  mode="multiple"
                  allowClear
                  options={optionsSelect.alertas.map(elements => {
                    return {
                      value: elements,
                      label: `${elements}`,
                    };
                  })}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Equipamento" name="id">
                <Select
                  mode="multiple"
                  loading={loadingSelect.vehicles}
                  allowClear
                  options={
                    optionsSelect.id.length
                      ? optionsSelect.id.map(elements => {
                          return {
                            value: elements,
                            label: `${elements}`,
                          };
                        })
                      : [
                          {
                            value: '',
                            label: '',
                          },
                        ]
                  }
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Cercas" name="cercas">
                <Select
                  mode="multiple"
                  loading={loadingSelect.fences}
                  allowClear
                  options={
                    optionsSelect.cercas.length
                      ? optionsSelect.cercas.map(elements => {
                          return {
                            value: elements,
                            label: `${elements}`,
                          };
                        })
                      : [
                          {
                            value: '',
                            label: '',
                          },
                        ]
                  }
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Data" name="date">
                <RangePicker showTime allowEmpty={[true, true]} />
              </Form.Item>
            </Col>
          </Row>
          {/* </Row>
          <Row gutter={3} justify="center"> */}
          <Row justify="center">
            <Space
              size="middle"
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'center',
              }}
            >
              <Button
                buttonStyle="btn--primary"
                buttonSize="btn--small"
                className="btnForm btnSearch"
                htmlType="submit"
              >
                Buscar
              </Button>
              <Button
                buttonStyle="btn--secondary"
                buttonSize="btn--small"
                onClick={onReset}
                className="btnForm btnClear"
              >
                Limpar
              </Button>
            </Space>
          </Row>
        </Form>
      </div>

      <div className="divGerarRel">
        <Button
          className="btnGerarRel"
          disabled={!alerts.alertsFiltered.length}
          loading={loadingReport}
          onClick={() => handleGenPdf()}
        >
          Gerar Relatorio
        </Button>
      </div>
      <Spin spinning={loadingSpin}>
        <Table
          columns={columns()}
          dataSource={alerts.alertsFiltered}
          pagination={{
            onChange: pageChange => {
              setPage(pageChange);
            },
            current: page,
            defaultCurrent: page,
            total: alerts.totalElements,
            pageSize: PAGE_SIZE,
            showSizeChanger: false,
          }}
        />
      </Spin>
    </Container>
  );
}

