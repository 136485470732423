import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import moment from 'moment';
import logo64 from '../../../../imgs/encoded/brasaosspds.base64';

export const pdfReport = (data: any, mapImg: any, logradouro: string) => {
  const win = window.open('', '_blank');
  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  const reportTitle = [
    {
      image: logo64,
      width: 179,
      height: 63,
      alignment: 'center',
      margin: [0, 10, 0, 0],
    },
    {
      text: 'Relatório da Ocorrência',
      bold: true,
      fontSize: 13,
      alignment: 'center',
      margin: [0, 10, 0, 0],
    },
  ];

  const results = data.ocorrenciaCronologiaList.map((c: any) => {
    return [
      {
        text: c.operador,
        fontSize: 9,
        margin: [0, 1, 0, 1],
      },
      {
        text: moment(c.dataHora).format('DD/MM/YYYY HH:mm:ss'),
        fontSize: 9,
        margin: [0, 1, 0, 1],
      },
      { text: c.terminal, fontSize: 9, margin: [0, 1, 0, 1] },
      { text: c.comentario, fontSize: 9, margin: [0, 1, 0, 1] },
    ];
  });

  const details = [
    {
      table: {
        widths: ['30%', '10%', '25%', '35%'],
        heights: [10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10],
        headerRows: 1,
        body: [
          [
            {
              text: 'Dados da ocorrência',
              alignment: 'center',
              colSpan: 4,
              bold: true,
              fillColor() {
                '#CCCCCC';
              },
            },
            {},
            {},
            {},
          ],
          [
            {
              text: [
                'Número: ',
                {
                  text: `${data.ocorrencia}`,
                  bold: false,
                },
              ],
              colSpan: 3,
              bold: true,
              fontSize: 9,
            },
            {},
            {},
            {
              text: [
                'Agência: ',
                {
                  text: `${data.agencia}`,
                  bold: false,
                },
              ],
              fontSize: 9,
              bold: true,
            },
          ],
          [
            {
              text: [
                'Tipo do código: ',
                {
                  text: `${data.tipoCodigo}`,
                  bold: false,
                },
              ],
              fontSize: 9,
              bold: true,
            },
            {
              text: [
                'Descrição: ',
                {
                  text: `${data.tipoDesc}`,
                  bold: false,
                },
              ],
              colSpan: 3,
              fontSize: 9,
              bold: true,
            },
            {},
            {},
          ],
          [
            {
              text: [
                'SubTipo do código: ',
                {
                  text: `${data.subTipoCodigo}`,
                  bold: false,
                },
              ],
              fontSize: 9,
              bold: true,
            },
            {
              text: [
                'Subtipo da descrição: ',
                {
                  text: `${data.subTipoDesc}`,
                  bold: false,
                },
              ],
              colSpan: 3,
              fontSize: 9,
              bold: true,
            },
            {},
            {},
          ],
          [
            {
              text: [
                'Local: ',
                {
                  text: `${data.endereco}`,
                  bold: false,
                },
              ],
              fontSize: 9,
              bold: true,
            },
            {
              text: [
                'Grupo: ',
                {
                  text: `${data.grupo}`,
                  bold: false,
                },
              ],
              fontSize: 9,
              colSpan: 3,
              bold: true,
            },
            {},
            {},
          ],
          [
            {
              text: [
                'Viaturas: ',
                {
                  text: `${data.viaturasAtribuidas}`,
                  bold: false,
                },
              ],
              colSpan: 4,
              fontSize: 9,
              bold: true,
            },
            {},
            {},
            {},
          ],
          [
            {
              text: [
                'Finalizações: ',
                {
                  text: `${data.finalizacoes}`,
                  bold: false,
                },
              ],
              border: [true, true, true, true],
              colSpan: 4,
              fontSize: 9,
              bold: true,
            },
            {},
            {},
            {},
          ],
          [
            {
              text: [
                'Data de Comunicação: ',
                {
                  text: `${moment(data.comunicacao).format(
                    'DD/MM/YYYY HH:mm:ss'
                  )}`,
                  bold: false,
                },
              ],
              margin: [0, 4, 0, 0],
              border: [true, true, false, false],
              colSpan: 2,
              fontSize: 9,
              bold: true,
            },
            {},
            {
              text: [
                'Data de chegada: ',
                {
                  text: `${moment(data.dtChegada).format(
                    'DD/MM/YYYY HH:mm:ss'
                  )}`,
                  bold: false,
                },
              ],
              margin: [0, 4, 0, 0],
              border: [false, false, true, false],
              colSpan: 2,
              fontSize: 9,
              bold: true,
            },
            {},
          ],
          [
            {
              text: [
                'Data de criação: ',
                {
                  text: `${moment(data.dtCriacao).format(
                    'DD/MM/YYYY HH:mm:ss'
                  )}`,
                  bold: false,
                },
              ],
              margin: [0, 4, 0, 0],
              border: [true, false, false, false],
              colSpan: 2,
              fontSize: 9,
              bold: true,
            },
            {},
            {
              text: [
                'Data de chegada do transporte: ',
                {
                  text: `${moment(data.dtChegadaTransporte).format(
                    'DD/MM/YYYY HH:mm:ss'
                  )}`,
                  bold: false,
                },
              ],
              margin: [0, 4, 0, 0],
              border: [false, false, true, false],
              colSpan: 2,
              fontSize: 9,
              bold: true,
            },
            {},
          ],
          [
            {
              text: [
                'Data de despacho: ',
                {
                  text: `${moment(data.dtDespacho).format(
                    'DD/MM/YYYY HH:mm:ss'
                  )}`,
                  bold: false,
                },
              ],
              margin: [0, 4, 0, 0],
              border: [true, false, false, false],
              colSpan: 2,
              fontSize: 9,
              bold: true,
            },
            {},
            {
              text: [
                'Data de encerramento: ',
                {
                  text: `${moment(data.dtEncerramento).format(
                    'DD/MM/YYYY HH:mm:ss'
                  )}`,
                  bold: false,
                },
              ],
              margin: [0, 4, 0, 0],
              border: [false, false, true, false],
              colSpan: 2,
              fontSize: 9,
              bold: true,
            },
            {},
          ],
          [
            {
              text: [
                'Data em rota: ',
                {
                  text: `${moment(data.dtEmRota).format(
                    'DD/MM/YYYY HH:mm:ss'
                  )}`,
                  bold: false,
                },
              ],
              margin: [0, 4, 0, 0],
              border: [true, false, true, true],
              colSpan: 4,
              fontSize: 9,
              bold: true,
            },
            {},
            {},
            {},
          ],
        ],
      },
    },

    {
      margin: [0, 60, 0, 0],
      layout: {
        fillColor(rowIndex: number) {
          return rowIndex % 2 === 0 ? '#CCCCCC' : null;
        },
      },
      table: {
        headerRows: 1,
        widths: ['15%', '20%', '10%', '55%'],
        width: '100%',
        body: [
          [
            {
              text: 'Operador',
              style: 'tableHeader',
              fontSize: 11,
              bold: true,
            },
            { text: 'Data', style: 'tableHeader', fontSize: 11, bold: true },
            {
              text: 'Terminal',
              style: 'tableHeader',
              fontSize: 11,
              bold: true,
            },
            {
              text: 'Comentário',
              style: 'tableHeader',
              fontSize: 11,
              bold: true,
            },
          ],
          ...results,
        ],
      },
    },
  ];

  const mapContainer = [
    {
      image: 'snow',
      margin: [90, 20, 90, 0],
      width: 400,
      height: 400,
    },
    {
      text: [
        'Endereço:',
        {
          text: logradouro,
          bold: false,
        },
      ],
      alignment: 'start',
      fontSize: 10,
      bold: true,
      margin: [90, 10, 0, 0],
    },
  ];

  const footer = [
    {
      text: [
        {
          text:
            'Secretaria de Segurança Pública e Defesa Social. Avenida Bezerra de Menezes, 581 - São Gerardo\n CEP 60325-003 . Fortaleza Ceará . Fone: (85) 3101-6501',
        },
      ],
      fontSize: 8,
      margin: [0, 10, 0, 45],
      alignment: 'center',
    },
  ];

  const docDefinitions: any = {
    // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
    pageMargins: [10, 150, 10, 40],
    pageSize: 'A4',
    alignment: 'center',
    header: [reportTitle],
    content: mapImg ? [details, mapContainer] : [details],
    images: {
      snow: mapImg ? mapImg.src : '',
    },
    footer: [footer],
  };

  pdfMake.createPdf(docDefinitions).open({}, win);
};

