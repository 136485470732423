import React, { useEffect, useCallback } from 'react';
import { Form, Spin, Card, Input, Switch, Button } from 'antd';
import { useParams, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { SaveOutlined } from '@ant-design/icons';

import { PageHeader } from '../../../components/PageHeader';
import { TITLE, BASE_ROLE } from './List';
import * as ACTIONS from '~/store/modules/vinculada/actions';

const SUBTITLE = '';

const { TextArea } = Input;

export function VinculadaForm() {
  const { id } = useParams();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const history = useHistory();

  const { form: entidade, loading } = useSelector(state => state.vinculada);

  useEffect(() => {
    if (id) dispatch(ACTIONS.getById(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (entidade && entidade.id && id) {
      const { nome, ativo, descricao } = entidade;
      // dataAtivacao: dataAtivacao ? moment(dataAtivacao) : null,
      form.setFieldsValue({
        nome,
        ativo,
        descricao,
      });
    }
  }, [form, entidade, id]);

  const handleSubmit = useCallback(
    // values.dataAtivacao = values.dataAtivacao.unix() * 1000;
    values => {
      if (!id) {
        dispatch(ACTIONS.create({ data: values, history }));
      } else {
        values.id = id;
        dispatch(ACTIONS.update(values));
      }
    },
    [dispatch, history, id]
  );

  return (
    <div>
      <Card style={{ margin: 15 }}>
        <PageHeader
          title={`${id ? 'Edição' : 'Cadastro'} - ${TITLE}`}
          subTitle={SUBTITLE}
          baseRole={BASE_ROLE}
          activeBackHistory
        />

        <Spin spinning={loading}>
          <Form
            form={form}
            initialValues={{ ativo: true }}
            labelCol={{
              xs: { span: 24 },
              sm: { span: 4 },
            }}
            wrapperCol={{
              xs: { span: 24 },
              sm: { span: 16 },
            }}
            onFinish={handleSubmit}
          >
            <Form.Item label="Ativo" name="ativo" valuePropName="checked">
              <Switch />
            </Form.Item>

            <Form.Item
              label="Nome"
              name="nome"
              rules={[
                {
                  required: true,
                  message: 'Por favor, informe o nome',
                },
              ]}
            >
              <Input maxLength={50} />
            </Form.Item>

            <Form.Item
              label="Descrição"
              name="descricao"
              rules={[
                {
                  required: true,
                  message: 'Por favor, informe a descrição',
                },
              ]}
            >
              <TextArea rows={4} maxLength={500} />
            </Form.Item>

            <Form.Item
              wrapperCol={{
                xs: { span: 24, offset: 0 },
                sm: { span: 20, offset: 4 },
              }}
            >
              <Button type="primary" htmlType="submit" icon={<SaveOutlined />}>
                Salvar
              </Button>
            </Form.Item>
          </Form>
        </Spin>
      </Card>
    </div>
  );
}
