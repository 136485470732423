import moment from "moment";

export const convertStringToDate = dataStr => {
  return moment(dataStr).format("DD-MM-YYYYTHH:mm:ss.SSS[Z]");
};

export const convertDateToISO = date => {
  const format = moment(date).format("YYYY-MM-DDTHH:mm:ss.SSSZ");
  return format;
};

export const convertDateToISODateTime = (date, time) => {
  const dateTime = `${date}T${time}`;
  const format = convertDateToISO(dateTime);
  return format;
};

export const isSameOfBefore = (date1, date2) => {
  const isSameOrBefore = moment(date1).isSameOrBefore(date2);
  return isSameOrBefore;
};