import React, { useEffect, useMemo, useState } from 'react';
import api from '~/services/api.ts';

export function Item({
  d,
  _numeroPagina,
  plus,
  _separador,
  _contador,
  dadosPdfPai,
  plusPagina,
  veiculos,
}) {
  const [logragouro, setLogradouro] = useState('');

  useEffect(() => {
    api
      .get(
        `https://spca.sspds.ce.gov.br/api/nominatim/reverse.php?format=json&lat=${d.position.x}&lon=${d.position.y}`
      )
      .then(log => {
        if (log.address) {
          const { road, city, postcode, state, suburb } = log.address;
          d.address = log.address;
          setLogradouro(
            `${road || ''}, ${suburb || ''} - ${postcode || ''}, ${city ||
              ''}, ${state || ''}`
          );
        }
      });
  }, [d.latLng, d.position.x, d.position.y]);

  /* const _separadorUsado =
    _numeroPagina > 1 ? _separador.padrao : _separador.inicial; */
  const _separadorUsado = _separador.padrao;
  const _quebra =
    _contador === _separador || _contador % _separador.padrao === 0;
  // _contador == 14 || (_contador > 16 && (_contador - 14) % 16 == 0);
  const _data = `${new Date(d.datePosition).toLocaleDateString()} ${new Date(
    d.datePosition
  ).toLocaleTimeString()}`;

  const _motor = d.engineOn === '1' ? 'Lig.' : 'Desl.';

  if (_contador % _separadorUsado == 0) {
    plusPagina();
  }

  const prefixo = useMemo(() => {
    const filtered = veiculos.find(v => v.identificador === d.placa);

    if (filtered) {
      return filtered.prefixo;
    }
    return '';
  }, [d.placa, veiculos]);

  return (
    <>
      <li
        className={
          _contador == dadosPdfPai.length
            ? 'itemListaImpressao ultimoItemImpressao'
            : 'itemListaImpressao'
        }
        key={d.dataPosicao}
      >
        <p className="celPlacaAud">
          {d.placa}
          <br /> {prefixo}
        </p>
        <p className="celLatitudeAud">{d.position.x}</p>
        <p className="celLongitudeAud">{d.position.y}</p>
        {/*<p className="celMotorAud">{_motor}</p>*/}
        <p className="celVelocidadeAud">{`${d.speed} Km/h`}</p>
        <p className="celPosicaoAud">{_data}</p>
        {/*   <p className="celDtPos">{_data.odometro}</p> */}
        <p className="celLogradouroAud">
          {!logragouro.includes('undefined') ? logragouro : ''}
        </p>
      </li>
      {/*_quebra ? (
        <div className="separadorListaImpressao" style={{ height: '84mm' }} />
      ) : (
        undefined
      )*/}
    </>
  );
}
