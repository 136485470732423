import React from 'react';
import { useHistory, Link } from 'react-router-dom';
import { Breadcrumb } from 'antd';
import { Wrench, FolderPlus } from 'phosphor-react';
import { ReactComponent as IconAddVeiculo } from '../../imgs/add-veiculo.svg';
import Prefixo from '../../imgs/placa-de-carro.png';

import Container from './style';

export default function Cadastros() {
  const history = useHistory();

  return (
    <Container>
      <Breadcrumb style={{ padding: '10px' }}>
        <Breadcrumb.Item>
          {/* @ts-expect-error Server Component */}
          <Link to="/">Página Inicial</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Cadastros</Breadcrumb.Item>
      </Breadcrumb>
      <div className="card-01" onClick={() => history.push('/veiculo')}>
        <div className="card-03">
          <div className="subtitle-card">Adicionar veículo</div>
        </div>
        <div className="card-icon-blue">
          <div className="img-icon iconVehicle">
            <IconAddVeiculo className="iconComponent " />
          </div>
          <div className="title-card">Veículo</div>
        </div>
      </div>
      <div className="row">
        {/* <div className="card-01" onClick={() => history.push('/equipamentos')}>
          <div className="card-03">
            <div className="subtitle-card">Adicionar equipamento</div>
          </div>
          <div className="card-icon-red">
            <div className="img-icon-phospohor">
              <Wrench size={32} className="icon-Phosphor" />
            </div>
            <div className="title-card">Equipamentos</div>
          </div>
        </div> */}

        {/* <div className="card-01" onClick={() => history.push('/veiculo')}>
          <div className="card-03">
            <div className="subtitle-card">Adicionar veículo</div>
          </div>
          <div className="card-icon-blue">
            <div className="img-icon iconVehicle">
              <IconAddVeiculo className="iconComponent " />
            </div>
            <div className="title-card">Veículo</div>
          </div>
        </div> */}
      </div>
      <div className="row">
        {/* <div className="card-01" onClick={() => history.push('/prefixo')}>
          <div className="card-03">
            <div className="subtitle-card">Adicionar prefixo</div>
          </div>
          <div className="card-icon-orange">
            <div className="img-icon">
              <img
                src={Prefixo}
                alt="prefixo icon"
                className="iconcomponent"
                style={{ filter: 'brightness(100) invert(1)', scale: '1.2' }}
              />
            </div>
            <div className="title-card">Prefixo</div>
          </div>
        </div>
        <div className="card-01" onClick={() => history.push('/tipoVeiculo')}>
          <div className="card-03">
            <div className="subtitle-card">Adicionar tipo de veículo</div>
          </div>
          <div className="card-icon-green">
            <div className="img-icon-phospohor">
              <FolderPlus size={32} className="icon-Phosphor" />
            </div>
            <div className="title-card">Tipo de veículo</div>
          </div>
        </div> */}
      </div>
      {/* <div className="row">
        <div className="card-04" />
        <div className="card-04" />
      </div> */}
    </Container>
  );
}

