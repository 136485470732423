/* eslint-disable */
import io from 'socket.io-client';
import ENV from '../config';

const URL = ENV.serverSocketAlert || '';

export const socketAlert = io(URL, {
  autoConnect: false,
  transports: ['websocket'],
  upgrade: false,
  query: {
    token: `${sessionStorage.getItem('rota@tkn')}`,
  },
  path: '/rotas/alertas/socket.io',
  timeout: 5000,
});

