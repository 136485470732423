import React, { useEffect, useRef, useState, useCallback } from 'react';
import { GoogleMap, Polyline, Polygon } from '@react-google-maps/api';

import dadosLatLngAis from '../../../dados/ais/dadosAis.json';

import { toast } from 'react-toastify';

import iconeCarroCarregamento from '../../../imgs/vtr_car.svg';

import OccurrenceIcon from '../../../imgs/ocorrence_icon.svg';

import { LoadingOutlined } from '@ant-design/icons';
import { rgba } from 'polished';
import moment from 'moment';
import {
  compareOccurrenceObjects,
  mapPolylinesColors,
  prefixoIsHT,
} from 'utils/functionsUtils';
import { SliderPerso } from '../slider';
import { HistoryMapProps } from './wrapper_mapa';
import { MarkersOccurrenceProps, dataPropertiesAux } from 'interfaces/vehicle';
import { HistoryService } from 'services/modulos/serviceHistory';
import { useMonitoringMapInfo } from 'hooks/monitoring/useMonitoringMapInfo';
import { useHistorySearchedDateSlider } from 'hooks/history/useHistorySearchedDateSlider';
import { useHistoryRaioPonto } from 'hooks/history/useHistoryRaioPonto';
import { useHistoryOriginPoint } from 'hooks/history/useHistoryOriginPoint';
import { useHistoryDataType } from 'hooks/history/useHistoryDataType';
import { useHistoryData } from 'hooks/history/useHistoryData';
import { useHistoryResetFunctions } from 'hooks/history/useHistoryResetFunctions';
import { useHistoryVehiclesWithFullHistoryLoaded } from 'hooks/history/useHistoryVehiclesWithFullHistoryLoaded';
import { useHistorySearchedDate } from 'hooks/history/useHistorySearchedDate';
import { useHistoryMap } from 'hooks/history/useHistoryMap';
import { useHistoryPolygonsAndPosicaoEfetivo } from 'hooks/history/useHistoryPolygonsAndPosicaoEfetivo';
import { VehiclePositionHistory } from 'interfaces/history';
import { useFiltersMapSelectedAis } from 'hooks/filtersMap/useFiltersMapSelectedAis';
import { updateHistoryMarkersArray } from 'utils/monitoring_functions';
import { useHistoryDataSource } from 'hooks/history/useHistoryDataSource';
import { HistoryTeltronicService } from 'services/modulos/serviceHistoricoTeltronic';
import ENV from 'config';

const position = {
  lat: -3.759201,
  lng: -38.526526,
};

const HISTORY_WITH_RADIUS = ['raio', 'auditoria_ocorrencia'];

function HistoryMap({
  searchAddress,
  collapsed,
  isDrawing,
  setIsDrawing,
  setFiltersVisible,
  isErasing,
  setIsErasing,
}: HistoryMapProps) {
  const {
    markersConectados,
    markersDesconectados,
    markersRadio,
    markersSemCadastro,
    showConectadas,
    showDesconectadas,
    showRadio,
    showSemCadastro,
    showOccurrences,
    dataOccurrences,
    mapOccurrences,
    showTooltipConectadas,
    showTooltipDesconectadas,
    showTooltipRadio,
    showTooltipSemCadastro,
  } = useMonitoringMapInfo();

  const { dataSource } = useHistoryDataSource();

  const { selectedAis } = useFiltersMapSelectedAis();

  const {
    selectedPosicaoEfetivo,
    handleSetPrefixosHistorico,
    polygonsCustomAudit,
  } = useHistoryPolygonsAndPosicaoEfetivo();

  const { map, setMap } = useHistoryMap();

  const {
    vehiclesWithFullHistoryLoaded,
  } = useHistoryVehiclesWithFullHistoryLoaded();

  const { dataHistorico, setDataHistorico } = useHistoryData();
  const { raioPonto } = useHistoryRaioPonto();
  const { choosingOrigin } = useHistoryResetFunctions();
  const { dataType } = useHistoryDataType();
  const { originPoint, setOriginPoint } = useHistoryOriginPoint();

  const { searchedDate } = useHistorySearchedDate();
  const { searchedDateSlider } = useHistorySearchedDateSlider();

  const polygonsAudit = useRef<{ polygon: google.maps.Polygon }[]>([]);
  const drawingManager = useRef<google.maps.drawing.DrawingManager>();

  const documentListeners = useRef<{ event: string; handler: any }[]>([]);

  const [loadingIcon, setLoadingIcon] = useState(false);

  const [
    currentCircleRadius,
    setCurrentCircleRadius,
  ] = useState<google.maps.Circle | null>();

  const currentPolylines = useRef<
    { plate: string; polyline: google.maps.Polyline[] }[]
  >([]);

  const addressMarker = useRef<google.maps.marker.AdvancedMarkerElement | null>(
    null
  );
  const currentEventListeners = useRef<google.maps.MapsEventListener[]>([]);

  const onMapLoad = (map: google.maps.Map) => {
    setMap(map);
  };

  const mapOptions = {
    center: map
      ? map.getCenter()
      : new window.google.maps.LatLng(position.lat, position.lng),
    zoom: map ? map.getZoom() : 12,
    // styles: [
    //   {
    //     elementType: 'labels',
    //     featureType: 'poi',
    //     stylers: [{ visibility: 'off' }],
    //   },
    // ],
    mapId: ENV.mapId,
    mapTypeControlOptions: {
      position: window.google.maps.ControlPosition.LEFT_CENTER,
    },
    fullscreenControl: false,
    zoomControl: false,
    streetViewControl: false,
  };

  const loadFullHistory = useCallback(
    async (radiusPosition: dataPropertiesAux) => {
      try {
        setLoadingIcon(true);

        let positionsHistory: VehiclePositionHistory[] = [];

        if (dataSource === 'COTIC') {
          positionsHistory = await HistoryService.getByPlates({
            placas: [radiusPosition.placa],
            dataInicio: searchedDate.dataInicio,
            dataFim: searchedDate.dataFim,
          });
        } else {
          positionsHistory = await HistoryTeltronicService.getByIdDevices({
            idDevices: [radiusPosition.placa],
            dataInicio: searchedDate.dataInicio,
            dataFim: searchedDate.dataFim,
          });
        }

        if (positionsHistory.length > 0) {
          setDataHistorico((prev: VehiclePositionHistory[]) =>
            prev.map(v => {
              if (v.placa === radiusPosition.placa) {
                v.positionInsideRadius = radiusPosition;
                v.positions = positionsHistory[0].positions.map(p => {
                  let newObj = {
                    ...p,
                    latitude: p.position?.x || 0,
                    longitude: p.position?.y || 0,
                    latLng: {
                      lat: p.position?.x || 0,
                      lng: p.position?.y || 0,
                    },
                    position: p.position
                      ? p.position
                      : {
                          x: p.latitude,
                          y: p.longitude,
                          coordinates: [p.latitude, p.longitude],
                          type: 'Point',
                        },
                  };

                  return newObj;
                });
              }
              return v;
            })
          );
        }
      } catch (e) {
        //
      } finally {
        setLoadingIcon(false);
      }
    },
    [searchedDate, dataSource]
  );

  const handleRenderMarkers = useCallback(
    (controller: AbortController) => {
      try {
        setLoadingIcon(true);

        const auxMarkersConectadas: dataPropertiesAux[] = [];
        const auxMarkersDesconectadas: dataPropertiesAux[] = [];
        const auxMarkersRadios: dataPropertiesAux[] = [];
        const auxMarkersSemCadastro: dataPropertiesAux[] = [];

        markersConectados.current.map(m => {
          m.marker.map = null;
        });
        markersDesconectados.current.map(m => {
          m.marker.map = null;
        });
        markersRadio.current.map(m => {
          m.marker.map = null;
        });
        markersSemCadastro.current.map(m => {
          m.marker.map = null;
        });

        markersConectados.current = [];
        markersDesconectados.current = [];
        markersRadio.current = [];
        markersSemCadastro.current = [];

        if (!choosingOrigin && dataType.current !== 'auditoria_ocorrencia') {
          if (currentCircleRadius) {
            currentCircleRadius.setMap(null);
            setCurrentCircleRadius(null);
          }
        }

        // filtrando posições dentro da timezone com posição valida
        let dataFinal = dataHistorico.map(v => {
          const timezonePositions = v.positions.filter(p => {
            let isInTimestamp = true;

            if (searchedDateSlider.startDate && searchedDateSlider.endDate) {
              isInTimestamp = Boolean(
                moment(p.datePosition).isSameOrAfter(
                  moment(searchedDateSlider.startDate)
                ) &&
                  moment(p.datePosition).isSameOrBefore(
                    moment(searchedDateSlider.endDate)
                  )
              );
            }

            return (
              p.position && p.position.coordinates.length === 2 && isInTimestamp
            );
          });

          return {
            ...v,
            positions: timezonePositions,
          };
        });

        const prefixosConectadas: { placa: string; prefixo: string }[] = [];
        const prefixosDesconectadas: { placa: string; prefixo: string }[] = [];
        const prefixosRadio: { placa: string; prefixo: string }[] = [];
        const prefixosSemCadastro: { placa: string; prefixo: string }[] = [];

        // adicionar posições nos arrays de marcadores
        dataFinal.map(v => {
          const selected = vehiclesWithFullHistoryLoaded.current?.includes(
            v.placa
          );

          const newPositions = [];
          // adicionar todas as posições se o veiculo estiver selecionado
          if (selected) {
            newPositions.push(...v.positions);
          }
          // adicionar apenas última posição
          else {
            if (v.positionInsideRadius) {
              newPositions.push(v.positionInsideRadius);
            } else {
              newPositions.push(v.positions[0]);
            }
          }

          let exist = false;

          newPositions.map(p => {
            if (
              (p.prefixo && prefixoIsHT(p.prefixo)) ||
              (!p.prefixo && prefixoIsHT(p.placa))
            ) {
              if (!exist) {
                exist = true;
                prefixosRadio.push({
                  placa: p.placa,
                  prefixo: p.prefixo,
                });
              }
              auxMarkersRadios.push(p);
              return p;
            }
            if (p.grupo === 'SEM_CADASTRO') {
              prefixosSemCadastro.push({
                placa: p.placa,
                prefixo: p.prefixo,
              });
              auxMarkersSemCadastro.push(p);
              return p;
            }

            if (p.statusConexaoViatura) {
              if (!exist) {
                exist = true;
                prefixosConectadas.push({
                  placa: p.placa,
                  prefixo: p.prefixo,
                });
              }
              auxMarkersConectadas.push(p);
              return p;
            } else {
              if (!exist) {
                exist = true;
                prefixosDesconectadas.push({
                  placa: p.placa,
                  prefixo: p.prefixo,
                });
              }
              auxMarkersDesconectadas.push(p);
              return p;
            }
          });
        });

        let updateConectadas = true;
        let updateDesconectadas = true;
        let updateRadio = true;
        let updateSemCadastro = true;

        let newConectadasList: dataPropertiesAux[] = auxMarkersConectadas;
        let newDesconectadasList: dataPropertiesAux[] = auxMarkersDesconectadas;
        let newRadiosList: dataPropertiesAux[] = auxMarkersRadios;
        let newSemCadastroList: dataPropertiesAux[] = auxMarkersSemCadastro;

        // prefixos e placas usados no painel
        handleSetPrefixosHistorico({
          conectadas: prefixosConectadas,
          desconectadas: prefixosDesconectadas,
          radio: prefixosRadio,
        });

        currentPolylines.current.map(p => {
          p.polyline.map(line => line.setMap(null));
        });

        currentPolylines.current = [];

        const newpolylines: {
          plate: string;
          polyline: google.maps.Polyline[];
        }[] = [];

        // desenhar polylines
        dataFinal.map((v, i) => {
          if (
            !vehiclesWithFullHistoryLoaded.current?.includes(v.placa) ||
            v.positions.length < 2
          ) {
            return;
          }

          const { r, g, b } = mapPolylinesColors[i % 50];

          let latLngArray = [];

          const lineSymbol = {
            path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
            strokeColor: rgba(r, g, b, 1),
          };

          latLngArray = v.positions
            .sort((a, b) =>
              moment(a.datePosition).isAfter(moment(b.datePosition)) ? 1 : -1
            )
            .map(p => p.latLng);

          const auxLineArray = [];

          for (let k = 0; k < latLngArray.length - 1; k += 1) {
            const newLine = new google.maps.Polyline({
              path: [latLngArray[k], latLngArray[k + 1]],
              icons: [
                {
                  icon: lineSymbol,
                  offset: '100%',
                },
              ],
              map,
              strokeColor: rgba(r, g, b, 1),
              strokeWeight: 2,
            });
            auxLineArray.push(newLine);
          }

          const newPoli = {
            plate: v.placa,
            polyline: auxLineArray,
          };

          newpolylines.push(newPoli);
        });

        currentPolylines.current = newpolylines;

        if (updateConectadas) {
          markersConectados.current = updateHistoryMarkersArray(
            map || null,
            markersConectados.current,
            auxMarkersConectadas,
            newConectadasList,
            showConectadas.current,
            showTooltipConectadas.current,
            false,
            false,
            { min: 60000, max: 70000 },
            loadFullHistory,
            controller,
            documentListeners,
            dataType,
            vehiclesWithFullHistoryLoaded,
            handleRenderMarkers
          );
        }

        if (updateDesconectadas) {
          markersDesconectados.current = updateHistoryMarkersArray(
            map || null,
            markersDesconectados.current,
            auxMarkersDesconectadas,
            newDesconectadasList,
            showDesconectadas.current,
            showTooltipDesconectadas.current,
            false,
            false,
            { min: 40000, max: 50000 },
            loadFullHistory,
            controller,
            documentListeners,
            dataType,
            vehiclesWithFullHistoryLoaded,
            handleRenderMarkers
          );
        }

        if (updateRadio) {
          markersRadio.current = updateHistoryMarkersArray(
            map || null,
            markersRadio.current,
            auxMarkersRadios,
            newRadiosList,
            showRadio.current,
            showTooltipRadio.current,
            true,
            false,
            { min: 20000, max: 30000 },
            loadFullHistory,
            controller,
            documentListeners,
            dataType,
            vehiclesWithFullHistoryLoaded,
            handleRenderMarkers
          );
        }

        if (updateSemCadastro) {
          markersSemCadastro.current = updateHistoryMarkersArray(
            map || null,
            markersSemCadastro.current,
            auxMarkersSemCadastro,
            newSemCadastroList,
            showSemCadastro.current,
            showTooltipSemCadastro.current,
            false,
            true,
            { min: 1000, max: 10000 },
            loadFullHistory,
            controller,
            documentListeners,
            dataType,
            vehiclesWithFullHistoryLoaded,
            handleRenderMarkers
          );
        }
      } catch (err) {
        toast.error(
          // @ts-ignore
          err.message ||
            'Erro ao carregar dados, tente novamente. Caso o problema persista, contate o suporte técnico.'
        );
      } finally {
        setTimeout(() => setLoadingIcon(false), 3 * 1000);
      }
    },
    [dataHistorico, selectedPosicaoEfetivo, searchedDateSlider, dataSource]
  );

  useEffect(() => {
    const controller = new AbortController(); // controller dos listenners do document

    handleRenderMarkers(controller);

    return () => {
      documentListeners.current.map(e =>
        document.removeEventListener(e.event, e.handler)
      );
      controller.abort();
    };
  }, [handleRenderMarkers]);

  const generateInfoWindowContentOccurrence = (dataOccurrence: any) => {
    return `<div style="padding: 0px 10px 10px 0px">
              <b>Ocorrência:</b> ${dataOccurrence?.ocorrencia} <br />
              <b>Agência:</b> ${dataOccurrence?.agencia || ''}<br />
              <b>Descrição:</b> ${dataOccurrence?.tipoDesc || ''}<br />
              <b>Grupo:</b> ${dataOccurrence?.grupo} <br />
              ${
                dataOccurrence?.dtDespacho
                  ? `<b>Despacho:</b> ${moment(
                      dataOccurrence?.dtDespacho
                    ).format('DD/MM/YYYY HH:mm:ss')}<br />`
                  : ''
              }
              ${
                dataOccurrence.dtEmRota
                  ? `<b>Em Rota:</b> ${moment(dataOccurrence?.dtEmRota).format(
                      'DD/MM/YYYY HH:mm:ss'
                    )}<br />`
                  : ''
              }
              ${
                dataOccurrence.dtChegada
                  ? `<b>Chegada:</b> ${moment(dataOccurrence?.dtChegada).format(
                      'DD/MM/YYYY HH:mm:ss'
                    )}<br />`
                  : ''
              }
              ${
                dataOccurrence.dtTransporte
                  ? `<b>Transporte:</b> ${moment(
                      dataOccurrence?.dtTransporte
                    ).format('DD/MM/YYYY HH:mm:ss')}<br />`
                  : ''
              }
              ${
                dataOccurrence.dtChegadaTransporte
                  ? `<b>Chegada Transporte:</b> ${moment(
                      dataOccurrence?.dtChegadaTransporte
                    ).format('DD/MM/YYYY HH:mm:ss')}<br />`
                  : ''
              }
              <a href="/web/rotas/ocorrencias/${
                dataOccurrence?.ocorrencia
              }">Carregar Informações</ a>
            </div>`;
  };

  useEffect(() => {
    if (map) {
      mapOccurrences.current.map(occ => (occ.marker.map = null));

      let auxMapOccurrences: MarkersOccurrenceProps[] = [];

      for (let i = 0; i < dataOccurrences.length; i += 1) {
        let exist = false;
        auxMapOccurrences = auxMapOccurrences.map(occ => {
          if (occ.occurrence?.ocorrencia === dataOccurrences[i].ocorrencia) {
            exist = true;
            if (!showOccurrences.current) {
              occ.marker.map = null;
            } else {
              occ.marker.map = map;
            }
            if (!compareOccurrenceObjects(occ.occurrence, dataOccurrences[i])) {
              if (dataOccurrences[i].finalizado) {
                occ.marker.map = null;
              } else {
                const infowindow = new window.google.maps.InfoWindow({
                  content: generateInfoWindowContentOccurrence(
                    dataOccurrences[i]
                  ),
                  maxWidth: 400,
                  zIndex: 99999,
                });

                occ.listeners?.map((e: any) =>
                  window.google.maps.event.removeListener(e)
                );
                occ.occurrence = dataOccurrences[i];
                occ.marker.addListener('click', () => {
                  infowindow.open({
                    map,
                    anchor: occ.marker,
                    shouldFocus: false,
                  });
                });
              }
            }
          }

          return occ;
        });
        if (
          !exist &&
          dataOccurrences[i].latLng &&
          dataOccurrences[i].latLng.lat !== 0 &&
          dataOccurrences[i].latLng.lng !== 0
        ) {
          let position = new window.google.maps.LatLng(
            dataOccurrences[i].latLng.lat,
            dataOccurrences[i].latLng.lng
          );

          const icon = document.createElement('img');
          icon.src = OccurrenceIcon;

          const marker = new window.google.maps.marker.AdvancedMarkerElement({
            position: position,
            // icon: {
            //   url: OccurrenceIcon,
            //   scaledSize: new window.google.maps.Size(25, 30),
            // },
            content: icon,
            map: showOccurrences.current ? map : null,
            zIndex: 99997,
            // tracksViewChanges: false,
          });

          const infowindow = new window.google.maps.InfoWindow({
            content: generateInfoWindowContentOccurrence(dataOccurrences[i]),
            maxWidth: 400,
            zIndex: 90099,
          });

          const tooltip = new window.google.maps.InfoWindow({
            content: `<div style="padding: 0px 10px 10px 0px"><b>${dataOccurrences[i]?.tipoDesc}</b></div>`,
            maxWidth: 150,
            // maxHeight: 100,
            zIndex: 99998,
            disableAutoPan: true,
          });

          const listener = marker.addListener('click', () => {
            infowindow.open({
              map,
              anchor: marker,
              shouldFocus: false,
            });
          });

          auxMapOccurrences.push({
            marker,
            tooltip,
            infowindow,
            occurrence: dataOccurrences[i],
            listeners: [listener],
          });
        }
      }

      mapOccurrences.current = auxMapOccurrences;
    }
  }, [map, dataOccurrences]);

  useEffect(() => {
    if (map) {
      if (choosingOrigin) {
        const newlistener = google.maps.event.addListener(
          map,
          'click',
          (event: any) => {
            setOriginPoint({
              lat: event.latLng.lat(),
              lng: event.latLng.lng(),
            });
          }
        );
        currentEventListeners.current.push(newlistener);
      } else {
        markersConectados.current.map(m => (m.marker.map = null));
        markersDesconectados.current.map(m => (m.marker.map = null));
        markersRadio.current.map(m => (m.marker.map = null));

        markersConectados.current = [];
        markersDesconectados.current = [];
        markersRadio.current = [];

        currentEventListeners.current.map(e =>
          window.google.maps.event.removeListener(e)
        );
        currentEventListeners.current = [];
        setOriginPoint({});
        if (currentCircleRadius) {
          currentCircleRadius.setMap(null);
          setCurrentCircleRadius(null);
        }
      }
    }
  }, [map, choosingOrigin]);

  useEffect(() => {
    if (map) {
      if (
        Object.keys(originPoint).length === 2 &&
        HISTORY_WITH_RADIUS.includes(dataType.current)
      ) {
        currentEventListeners.current.map(e =>
          window.google.maps.event.removeListener(e)
        );
        if (currentCircleRadius) {
          currentCircleRadius.setCenter(originPoint);
          currentCircleRadius.setRadius(raioPonto);
        } else {
          const newCircle = new window.google.maps.Circle({
            strokeColor: '#0000cd',
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: '#8ab9f1',
            fillOpacity: 0.35,
            map,
            center: originPoint,
            radius: raioPonto,
          });

          setCurrentCircleRadius(newCircle);
        }

        map.panTo(originPoint);
      }
    }
  }, [map, originPoint, raioPonto]);

  useEffect(() => {
    if (addressMarker.current) {
      addressMarker.current.map = null;
    }
    if (
      map &&
      searchAddress?.show &&
      searchAddress?.address.latitude &&
      searchAddress?.address.longitude
    ) {
      const auxMarkerAddress = new window.google.maps.marker.AdvancedMarkerElement(
        {
          position: {
            lat: searchAddress.address.latitude,
            lng: searchAddress.address.longitude,
          },
          map,
        }
      );
      map.panTo({
        lat: searchAddress.address.latitude,
        lng: searchAddress.address.longitude,
      });
      map.setZoom(17);

      addressMarker.current = auxMarkerAddress;
    }
  }, [searchAddress]);

  useEffect(() => {
    polygonsAudit.current.map(p => {
      p.polygon.setMap(null);
    });

    polygonsAudit.current = polygonsCustomAudit.map((p: any) => {
      const path = p.paths;

      const polygon = new google.maps.Polygon({
        paths: path,
        strokeColor: 'black',
        strokeOpacity: 1,
        strokeWeight: 1,
        fillColor: rgba(0, 0, 0, 0.8),
        map,
      });

      return {
        polygon,
      };
    });
  }, [polygonsCustomAudit]);

  useEffect(() => {
    if (map) {
      if (!drawingManager.current) {
        let drawingManagerAux = new window.google.maps.drawing.DrawingManager({
          drawingControl: false,
          polygonOptions: {
            strokeColor: 'black',
            strokeOpacity: 1,
            strokeWeight: 1,
            fillColor: rgba(0, 0, 0, 0.8),
          },
          map,
        });

        google.maps.event.addListener(
          drawingManagerAux,
          'polygoncomplete',
          function(polygon: google.maps.Polygon) {
            polygonsAudit.current.push({ polygon: polygon });
            setIsDrawing(false);
            setFiltersVisible(true);
          }
        );

        drawingManager.current = drawingManagerAux;
      } else {
        if (isDrawing) {
          drawingManager.current.setDrawingMode(
            google.maps.drawing.OverlayType.POLYGON
          );
        } else {
          drawingManager.current.setDrawingMode(null);
        }
      }
    }
  }, [map, isDrawing]);

  useEffect(() => {
    if (map && isErasing) {
      polygonsAudit.current.map(p => {
        p.polygon.addListener('click', () => {
          p.polygon.setMap(null);
          polygonsAudit.current = polygonsAudit.current.filter(
            p2 => p2.polygon !== p.polygon
          );
          setIsErasing(false);
          setFiltersVisible(true);
        });
      });
    }
  }, [map, isErasing]);

  return (
    <>
      <div
        hidden={!loadingIcon}
        className={!collapsed ? 'iconeCarregandoCollapsed' : 'iconeCarregando'}
      >
        <LoadingOutlined id="icone" />
        <img id="iconeCarro" src={iconeCarroCarregamento} />
      </div>

      <GoogleMap
        id="history_map"
        onLoad={onMapLoad}
        mapContainerStyle={{
          width: '100%',
          height: '100%',
        }}
        options={mapOptions}
      >
        {Array(25)
          .fill(0)
          .map((_, index) => (
            <Polyline
              key={index}
              path={dadosLatLngAis[index].map(a => ({
                lat: a[1],
                lng: a[0],
              }))}
              options={{
                strokeColor: 'black',
                strokeWeight: 0.5,
              }}
            />
          ))}
        {Object.keys(selectedAis)?.map(
          (ais, index) =>
            !selectedAis[ais] && (
              <Polygon
                key={index}
                paths={dadosLatLngAis[parseInt(ais, 10) - 1].map(a => ({
                  lat: a[1],
                  lng: a[0],
                }))}
                options={{
                  strokeColor: 'purple',
                  strokeOpacity: 1,
                  strokeWeight: 1,
                  fillColor: rgba(138, 43, 226, 0.5),
                }}
              />
            )
        )}

        {/* Desenhar traços das AIS */}
        {Object.keys(selectedAis)?.map((ais, index) => (
          <Polyline
            key={index}
            path={dadosLatLngAis[parseInt(ais, 10) - 1].map(a => ({
              lat: a[1],
              lng: a[0],
            }))}
            options={{
              strokeColor: 'black',
              strokeWeight: 0.5,
            }}
          />
        ))}
      </GoogleMap>

      {dataHistorico.length > 0 &&
        dataType.current === 'historicoIndividual' && <SliderPerso />}
    </>
  );
}

export default HistoryMap;

