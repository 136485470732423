import { MonitoringContext } from 'context/monitoring';
import { useContextSelector } from 'use-context-selector';

export function useDataAndSelectEfetivo() {
  const data = useContextSelector(MonitoringContext, context => context.data);

  const handleSelectEfetivo = useContextSelector(
    MonitoringContext,
    context => context.handleSelectEfetivo
  );

  return {
    data,
    handleSelectEfetivo,
  };
}

