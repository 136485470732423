import React, { useState, useMemo, ReactNode } from 'react';

import { createContext } from 'use-context-selector';
import {
  FiltersMapProps,
  GroupsFiltersProps,
} from 'interfaces/FiltersMapContext';
import { useAuthData } from 'hooks/auth/useAuthData';
import { useLocation } from 'react-router-dom';

export const FiltersMapContext = createContext<FiltersMapProps>({
  selectedAis: '',
  loadingAis: false,
  setLoadingAis: () => {},
  roleAis: [],
  onSelectedAis: () => {},
  groupsFilters: {
    agencies: [],
    groups: [],
  },
  setGroupsFilters: () => {},
  selectedAgencies: [],
  setSelectedAgencies: () => {},
});

export const FiltersMapProvider = ({ children }: { children: ReactNode }) => {
  const { data: client } = useAuthData();

  const [selectedAgencies, setSelectedAgencies] = useState<string[]>(
    client.isSecretario ? ['PM'] : []
  );
  const [loadingAis, setLoadingAis] = useState(false);

  const location = useLocation();

  const isMonitoring = location.pathname === '/mapa/monitoramento';

  const [groupsFilters, setGroupsFilters] = useState<GroupsFiltersProps>({
    agencies: [
      { key: 1, id: 1, nome: 'PC', checked: false },
      { key: 2, id: 2, nome: 'PRF', checked: false },
      { key: 3, id: 3, nome: 'GM', checked: false },
      { key: 4, id: 4, nome: 'COPEC', checked: false },
      { key: 5, id: 5, nome: 'BMGSU', checked: false },
      { key: 6, id: 6, nome: 'PME', checked: false },
      { key: 7, id: 7, nome: 'BMIS', checked: false },
      {
        key: 8,
        id: 8,
        nome: 'PM',
        checked: Boolean(client.isSecretario) && isMonitoring && isMonitoring,
      },
      { key: 9, id: 9, nome: 'COMEL', checked: false },
      { key: 10, id: 10, nome: 'TRANS', checked: false },
      { key: 11, id: 11, nome: 'COPAC', checked: false },
      { key: 12, id: 12, nome: 'CBM', checked: false },
      { key: 13, id: 13, nome: 'SAP', checked: false },
    ],
    groups: [
      { id: 3, nome: 'COTAM', checked: false, num: null },
      { id: 13, nome: 'COTAR', checked: false, num: null },
      { id: 18, nome: 'COD', checked: false, num: null },
      { id: 19, nome: 'GOE', checked: false, num: null },
      { id: 20, nome: 'BOPE', checked: false, num: null },
      { id: 21, nome: 'BPRE', checked: false, num: null },
      // { id: 21, nome: 'BPEsp', checked: false, num: null },
      { id: 26, nome: 'BPGEP', checked: false, num: null },
      { id: 27, nome: 'BPTUR', checked: false, num: null },
      { id: 28, nome: 'CHOQUE', checked: false, num: null },
      { id: 33, nome: 'COLOG', checked: false, num: null },
      { id: 36, nome: 'RAIO', checked: false, num: null },
      { id: 37, nome: 'BPE', checked: false, num: null },
      { id: 38, nome: 'CPC', checked: false, num: null },
      { id: 39, nome: 'CPG', checked: false, num: null },
      { id: 40, nome: 'BPMA', checked: false, num: null },
      { id: 41, nome: 'FORTA', checked: false, num: null },
      { id: 42, nome: 'METRO', checked: false, num: null },
      { id: 43, nome: 'INTER', checked: false, num: null },
      { id: 'sem-grupo', nome: 'SEM', checked: false, num: null },
      {
        id: 6,
        nome: 'AIS 01',
        checked: Boolean(client.isSecretario) && isMonitoring,
        num: 1,
      },
      {
        id: 4,
        nome: 'AIS 02',
        checked: Boolean(client.isSecretario) && isMonitoring,
        num: 2,
      },
      {
        id: 14,
        nome: 'AIS 03',
        checked: Boolean(client.isSecretario) && isMonitoring,
        num: 3,
      },
      {
        id: 12,
        nome: 'AIS 04',
        checked: Boolean(client.isSecretario) && isMonitoring,
        num: 4,
      },
      {
        id: 17,
        nome: 'AIS 05',
        checked: Boolean(client.isSecretario) && isMonitoring,
        num: 5,
      },
      {
        id: 16,
        nome: 'AIS 06',
        checked: Boolean(client.isSecretario) && isMonitoring,
        num: 6,
      },
      {
        id: 10,
        nome: 'AIS 07',
        checked: Boolean(client.isSecretario) && isMonitoring,
        num: 7,
      },
      {
        id: 9,
        nome: 'AIS 08',
        checked: Boolean(client.isSecretario) && isMonitoring,
        num: 8,
      },
      {
        id: 11,
        nome: 'AIS 09',
        checked: Boolean(client.isSecretario) && isMonitoring,
        num: 9,
      },
      {
        id: 23,
        nome: 'AIS 10',
        checked: Boolean(client.isSecretario) && isMonitoring,
        num: 10,
      },
      {
        id: 22,
        nome: 'AIS 11',
        checked: Boolean(client.isSecretario) && isMonitoring,
        num: 11,
      },
      {
        id: 25,
        nome: 'AIS 12',
        checked: Boolean(client.isSecretario) && isMonitoring,
        num: 12,
      },
      {
        id: 24,
        nome: 'AIS 13',
        checked: Boolean(client.isSecretario) && isMonitoring,
        num: 13,
      },
      {
        id: 32,
        nome: 'AIS 14',
        checked: Boolean(client.isSecretario) && isMonitoring,
        num: 14,
      },
      {
        id: 31,
        nome: 'AIS 15',
        checked: Boolean(client.isSecretario) && isMonitoring,
        num: 15,
      },
      {
        id: 35,
        nome: 'AIS 16',
        checked: Boolean(client.isSecretario) && isMonitoring,
        num: 16,
      },
      {
        id: 34,
        nome: 'AIS 17',
        checked: Boolean(client.isSecretario) && isMonitoring,
        num: 17,
      },
      {
        id: 30,
        nome: 'AIS 18',
        checked: Boolean(client.isSecretario) && isMonitoring,
        num: 18,
      },
      {
        id: 29,
        nome: 'AIS 19',
        checked: Boolean(client.isSecretario) && isMonitoring,
        num: 19,
      },
      {
        id: 8,
        nome: 'AIS 20',
        checked: Boolean(client.isSecretario) && isMonitoring,
        num: 20,
      },
      {
        id: 2,
        nome: 'AIS 21',
        checked: Boolean(client.isSecretario) && isMonitoring,
        num: 21,
      },
      {
        id: 1,
        nome: 'AIS 22',
        checked: Boolean(client.isSecretario) && isMonitoring,
        num: 22,
      },
      {
        id: 7,
        nome: 'AIS 23',
        checked: Boolean(client.isSecretario) && isMonitoring,
        num: 23,
      },
      {
        id: 5,
        nome: 'AIS 24',
        checked: Boolean(client.isSecretario) && isMonitoring,
        num: 24,
      },
      {
        id: 15,
        nome: 'AIS 25',
        checked: Boolean(client.isSecretario) && isMonitoring,
        num: 25,
      },
    ],
  });

  const aisCompare = useMemo(
    () =>
      (client?.roles || [])
        .map((r: string) => r.replace(/\D/g, ''))
        .filter((r: string) => r !== ''),

    [client?.roles]
  );

  const objRoleAis = useMemo(() => {
    const _obj: any = {};

    aisCompare.forEach((ais: string) => {
      _obj[ais] = !(
        Boolean(client.isSecretario) &&
        isMonitoring &&
        isMonitoring
      );
    });

    return _obj;
  }, [aisCompare]);

  const [selectedAis, setSelectedAis] = useState(objRoleAis);

  return (
    <FiltersMapContext.Provider
      value={{
        selectedAis,
        loadingAis,
        setLoadingAis,
        roleAis: aisCompare,
        onSelectedAis: setSelectedAis,
        groupsFilters,
        setGroupsFilters,
        selectedAgencies,
        setSelectedAgencies,
      }}
    >
      {children}
    </FiltersMapContext.Provider>
  );
};
