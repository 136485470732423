import ACTION from '../actionTypes';

const initialState = {
  data: [],
  form: {},
  loading: false,
  error: null,
  totalElements: 0,
};

export default function ais(state = initialState, action) {
  switch (action.type) {
    case ACTION.AIS_GET_SEARCH_PAGE:
    case ACTION.AIS_GET_ALL_REQUEST:
    case ACTION.AIS_POST_REQUEST:
    case ACTION.AIS_GET_ID_REQUEST:
    case ACTION.AIS_UPDATE_REQUEST:
    case ACTION.AIS_DELETE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ACTION.AIS_GET_ALL_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case ACTION.AIS_GET_SEARCH_PAGE_SUCCESS: {
      const { content, totalElements } = action.data;
      return {
        ...state,
        loading: false,
        error: null,
        data: (content || []).map(row => ({ ...row, key: row.id })),
        totalElements,
      };
    }
    case ACTION.AIS_POST_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case ACTION.AIS_UPDATE_SUCCESS:
    case ACTION.AIS_GET_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        form: action.data,
      };
    case ACTION.AIS_DELETE_SUCCESS:
      return {
        ...state,
      };
    case ACTION.AIS_ERROR:
      return {
        ...state,
        data: [],
        loading: false,
        error: action.message,
      };
    default:
      return state;
  }
}
