import React, {
  MutableRefObject,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import { createContext } from 'use-context-selector';
import { getVeiculosServices } from 'services/modulos/getVeiculos';
import { PositionVehicle, ResumeVehicle } from 'interfaces/vehicle';
import { FencesData } from 'interfaces/fences';
import { ServiceCerca } from 'services/modulos/serviceCerca';
import { ServiceVeiculo } from 'services/modulos/service_veiculo';
import { useAuthData } from 'hooks/auth/useAuthData';

type TVehicles = {
  [key: string]: ResumeVehicle;
};
export interface GlobalProps {
  vehicles: TVehicles;
  // lastPositions?: ;
  loadLastPositions: () => Promise<PositionVehicle[]>;
  fences: MutableRefObject<FencesData>;
  loadingRequest?: {
    vehicles: boolean;
    // lastPositions: boolean;
    fences: boolean;
  };
  loadFences: () => Promise<void>;
  handleRenderFencesName: (render: boolean, map: google.maps.Map) => void;
  showFencesName: MutableRefObject<boolean>;
}

export const globalContext = createContext({} as GlobalProps);

export const GlobalProvider = ({ children }: { children: ReactNode }) => {
  const [vehicles, setVehicles] = useState<TVehicles>({});
  // const [lastPositions, setLastPositions] = useState<PositionVehicle[]>([]);

  const showFencesName = useRef(false);

  const fences = useRef<FencesData>({
    data: [],
    mapFences: [],
    idList: [],
  });

  const [loadingRequest, setLoadingRequest] = useState({
    vehicles: false,
    // lastPositions: false,
    fences: false,
  });
  const { data: dataAuth } = useAuthData();
  const { isFenceAdmin, roles } = dataAuth || {};
  const hasRoleFence = roles?.includes('ROLE_ROTAS_VISUALIZACAO_CERCA');

  const updateLoadingRequest = useCallback((newProperties: any) => {
    setLoadingRequest(prev => ({ ...prev, ...newProperties }));
  }, []);

  const loadLastPositions = useCallback(async () => {
    try {
      const res = await getVeiculosServices.get();

      return res;
    } catch (e) {
      //
      return [];
    }
  }, []);

  const initFetch: () => Promise<void> = useCallback(async (): Promise<
    void
  > => {
    try {
      updateLoadingRequest({
        vehicles: true,
        // lastPositions: true,
        fences: true,
      });

      let requests: any = [
        ServiceVeiculo.resumoVeiculo,
        // getVeiculosServices.get,
      ];

      if (hasRoleFence) {
        if (isFenceAdmin) {
          requests.push(ServiceCerca.getCercaAdminAll);
        } else {
          requests.push(ServiceCerca.getCercaAll);
        }
      }

      await Promise.all(
        requests.map((p: any) => {
          p().then((response: any) => {
            if (p === ServiceVeiculo.resumoVeiculo) {
              updateLoadingRequest({
                vehicles: false,
              });

              let obj: TVehicles = {};

              response.map((v: ResumeVehicle) => {
                obj[v.equipamento || ''] = v;
              });

              return setVehicles(obj);
            }
            // if (p === getVeiculosServices.get) {
            //   updateLoadingRequest({
            //     lastPositions: false,
            //   });
            //   return setLastPositions(response);
            // }
            if (Array.isArray(response)) {
              fences.current = {
                ...fences.current,
                data: response,
              };
              updateLoadingRequest({
                fences: false,
              });
              return;
            }
          });
        })
      );
    } catch (err) {
      console.error(err);
    }
  }, [vehicles, fences.current]);

  const loadFences: () => Promise<void> = useCallback(async (): Promise<
    void
  > => {
    try {
      updateLoadingRequest({ fences: true });
      let allFences: any[] = [];
      if (hasRoleFence) {
        if (isFenceAdmin) {
          allFences = await ServiceCerca.getCercaAdminAll();
        } else {
          allFences = await ServiceCerca.getCercaAll();
        }
      }

      fences.current = {
        ...fences.current,
        data: allFences,
      };
    } catch (err) {
      console.log(err);
    } finally {
      updateLoadingRequest({ fences: false });
    }
  }, []);

  const handleRenderFencesName = useCallback(
    (render: boolean, map: google.maps.Map) => {
      showFencesName.current = render;

      if (!render) {
        fences.current.mapFences.map(fence => {
          fence.infoWindow.close();
        });
      } else {
        fences.current.mapFences.map(fence => {
          fence.infoWindow.open(map);
        });
      }
    },
    [fences.current]
  );

  useEffect(() => {
    initFetch();
  }, []);

  return (
    <>
      <globalContext.Provider
        value={{
          vehicles,
          loadLastPositions,
          fences,
          loadingRequest,
          loadFences,
          handleRenderFencesName,
          showFencesName,
        }}
      >
        {children}
      </globalContext.Provider>
    </>
  );
};

