import React, { useCallback, useState } from 'react';
import { Modal, Form, Row, Col, Spin, DatePicker } from 'antd';
import PropTypes from 'prop-types';

import { toast } from 'react-toastify';
import { InputAntdCustom } from '~/components/FormAntd/InputAntd';
import { ServicePrefixo } from '~/services/modulos/servicePrefixo';

export function ModalCadastroPrefixo({ validarData, visible, onCancel, onOk }) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const onFinish = useCallback(
    async values => {
      try {
        setLoading(true);
        const { status, mensagem } = validarData(
          values.dataAtivacao,
          values.dataDesativacao
        );
        if (!status) {
          throw new Error(mensagem);
        }
        const response = await ServicePrefixo.create({
          codigo: values.codigo,
          ativo: true,
        });
        values.prefixoId = response.id;
        onOk(values);
        form.resetFields();
        toast.success('Registro salvo com sucesso!');
      } catch (err) {
        toast.error(err.message || 'Falha ao salvar os dados');
      } finally {
        setLoading(false);
      }
    },
    [form, onOk]
  );

  return (
    <Modal
      title="Cadastrar e vincular prefixo ao veículo"
      className="modal-recupera-login"
      open={visible}
      onOk={form.submit}
      onCancel={onCancel}
      destroyOnClose
    >
      <Spin spinning={loading}>
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Row justify="space-around">
            <Col span={22}>
              <Form.Item
                label="Codigo"
                name="codigo"
                rules={[
                  {
                    required: true,
                    message: 'Por favor, informe o codigo',
                  },
                ]}
              >
                <InputAntdCustom size="large" />
              </Form.Item>
            </Col>
          </Row>

          <Row justify="space-around">
            <Col span={10}>
              <Form.Item
                label="Data ativação"
                name="dataAtivacao"
                rules={[
                  {
                    required: true,
                    message: 'Por favor, informe a data de ativação',
                  },
                ]}
              >
                <DatePicker showTime format="DD/MM/YYYY HH:mm:ss" />
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item label="Data desativação" name="dataDesativacao">
                <DatePicker showTime format="DD/MM/YYYY HH:mm:ss" />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Modal>
  );
}

ModalCadastroPrefixo.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onOk: PropTypes.func.isRequired,
  validarData: PropTypes.func.isRequired,
};

