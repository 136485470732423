import React, { useEffect, useRef, useState, useCallback } from 'react';

import { GoogleMap, Polygon, Polyline } from '@react-google-maps/api';

import { toast } from 'react-toastify';

import debounce from 'lodash.debounce';

import iconeCarroCarregamento from '../../../imgs/vtr_car.svg';

import PontoBaseCerca from '../../../imgs/ponto_base_cerca.svg';
import OccurrenceIcon from '../../../imgs/ocorrence_icon.svg';

import {
  ExclamationCircleOutlined,
  ExclamationOutlined,
  LoadingOutlined,
  DownloadOutlined,
  PoweroffOutlined,
  HistoryOutlined,
} from '@ant-design/icons';
import { rgba } from 'polished';
import moment from 'moment';
import {
  compareOccurrenceObjects,
  comparePositionObjects,
  prefixoIsHT,
} from 'utils/functionsUtils';
import { Popover, Button } from 'antd';
import { animateMapComponent } from '../../../utils/functionsUtils';
import { ServiceNotification } from 'services/modulos/serviceNotification';
import { useMonitoringMapInfo } from 'hooks/monitoring/useMonitoringMapInfo';
import { useGlobalFences } from 'hooks/global/useGlobalFences';
import { useFiltersMapSelectedAis } from 'hooks/filtersMap/useFiltersMapSelectedAis';
import {
  icons_ais_alerts,
  icons_fence_alerts,
  updateMarkersArray,
} from 'utils/monitoring_functions';
import { DataPosition, OccurrenceSocket } from 'interfaces/vehicle';

import satelite from '../../../imgs/satelite.svg';
import dadosLatLngAis from '../../../dados/ais/dadosAis.json';
import { MonitoringReportModal } from '../modals/monirotingReportModal';
import ENV from 'config';

interface NotificationError {
  message: any;
}

const position = {
  lat: -3.759201,
  lng: -38.526526,
};

type TMonitoringMap = {
  searchAddress: any;
  setIsOpenModalAlerts: (value: boolean) => void;
  collapsed: boolean;
};

function MonitoringMap({
  searchAddress,
  setIsOpenModalAlerts,
  collapsed,
}: TMonitoringMap) {
  const {
    map,
    setMap,
    data,
    statusSocketTVP,
    markersConectados,
    markersDesconectados,
    markersRadio,
    markersSemCadastro,
    showAlerts,
    showConectadas,
    showDesconectadas,
    showRadio,
    showSemCadastro,
    showOccurrences,
    showTooltipConectadas,
    showTooltipDesconectadas,
    showTooltipRadio,
    showTooltipSemCadastro,
    showTooltipOccurrences,
    fencesSelected,
    dataOccurrences,
    activeAlert,
    firstLoadOfActiveAlert,
    mapOccurrences,
    onActivateAlertSocket,
    onDeactiveAlertSocket,
  } = useMonitoringMapInfo();

  const { fences, showFencesName } = useGlobalFences();

  const fencesInfo = useRef<
    { name: string; map: google.maps.Map | null; listeners: any[] }[]
  >([
    {
      name: '',
      map: null,
      listeners: [],
    },
  ]);

  const { selectedAis } = useFiltersMapSelectedAis();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loadingLogradouro, setLoadingLogradouro] = useState(false);

  const [notificationError, setNotificationError] = useState<
    NotificationError[]
  >([]);

  const [loadingIcon, setLoadingIcon] = useState(false);

  const addressMarker = useRef<google.maps.marker.AdvancedMarkerElement | null>(
    null
  );

  const onMapLoad = (mapa: google.maps.Map) => {
    setMap(mapa);
  };

  const generateInfoWindowContentOccurrence = (
    dataOccurrence: OccurrenceSocket
  ) => {
    return `<div style="padding: 0px 10px 10px 0px">
              <b>Ocorrência:</b> ${dataOccurrence?.ocorrencia} <br />
              <b>Agência:</b> ${dataOccurrence?.agencia || ''}<br />
              <b>Descrição:</b> ${dataOccurrence?.tipoDesc || ''}<br />
              <b>Grupo:</b> ${dataOccurrence?.grupo} <br />
              <b>Despacho:</b> ${moment(dataOccurrence?.dtDespacho).format(
                'DD/MM/YYYY HH:mm:ss'
              )}<br />
              ${
                dataOccurrence.dtEmRota
                  ? `<b>Em Rota:</b> ${moment(dataOccurrence?.dtEmRota).format(
                      'DD/MM/YYYY HH:mm:ss'
                    )}<br />`
                  : ''
              }
              ${
                dataOccurrence.dtChegada
                  ? `<b>Chegada:</b> ${moment(dataOccurrence?.dtChegada).format(
                      'DD/MM/YYYY HH:mm:ss'
                    )}<br />`
                  : ''
              }
              ${
                dataOccurrence.dtTransporte
                  ? `<b>Transporte:</b> ${moment(
                      dataOccurrence?.dtTransporte
                    ).format('DD/MM/YYYY HH:mm:ss')}<br />`
                  : ''
              }
              ${
                dataOccurrence.dtChegadaTransporte
                  ? `<b>Chegada Transporte:</b> ${moment(
                      dataOccurrence?.dtChegadaTransporte
                    ).format('DD/MM/YYYY HH:mm:ss')}<br />`
                  : ''
              }
              <a href="/web/rotas/ocorrencias/${
                dataOccurrence?.ocorrencia
              }">Carregar Informações</ a>
            </div>`;
  };

  const mapOptionsAux: any = {};

  if (!map?.getZoom()) mapOptionsAux.zoom = 12;

  const mapOptions = {
    ...mapOptionsAux,
    center: map
      ? map.getCenter()
      : new window.google.maps.LatLng(position.lat, position.lng),
    // styles: [
    //   {
    //     elementType: 'labels',
    //     featureType: 'poi',
    //     stylers: [{ visibility: 'off' }],
    //   },
    // ],
    // panControl: true,
    // panControlOptions: {
    //   position: window.google.maps.ControlPosition.TOP_LEFT,
    // },
    // mapTypeControl: false,
    mapTypeControlOptions: {
      position: window.google.maps.ControlPosition.TOP_LEFT,
    },
    fullscreenControl: false,
    zoomControl: false,
    streetViewControl: false,
    mapId: ENV.mapId,
  };

  const handleCheckErrors = useCallback(async () => {
    try {
      const newNotifications = await ServiceNotification.getNewest();

      const messagesError: any = {
        'integracao-teltronic-radios':
          'Erro no recebimento de posições das viaturas. Contate 0800 280 1158 para informar o problema.',
        'rotas-monitoramento-falha-posição':
          'Erro no monitoramento. Recarregue a página ou contate 0800 280 1158 para informar o problema.',
      };

      // newNotifications.map((n: any) => {
      //   if (n.name.includes('rotas-monitoramento-falha-posição')) {
      //     monitoringDown.current = true;
      //   } else {
      //     monitoringDown.current = false;
      //   }
      // });

      const notificationsFiltered = newNotifications
        .filter(
          (noti: any) =>
            noti.severity === 'ERROR' &&
            Object.keys(messagesError).includes(noti.name)
        )
        .map((noti: any) => {
          let msg = messagesError[noti.name] || noti.description;

          toast.error(msg);
          return {
            message: msg,
          };
        });

      setNotificationError(notificationsFiltered);
    } catch (e) {
      //
    }
  }, []);

  useEffect(() => {
    try {
      if (map) {
        // @ts-ignore
        // window.google.maps.InfoWindow.prototype.isOpen = false;
        // @ts-ignore
        window.google.maps.marker.AdvancedMarkerElement.prototype.animateTo = animateMapComponent;

        const auxMarkersConectadas: DataPosition[] = [];
        const auxMarkersDesconectadas: DataPosition[] = [];
        const auxMarkersRadios: DataPosition[] = [];
        const auxMarkersSemCadastro: DataPosition[] = [];

        data
          .filter(v => {
            if (
              !v.properties.latLng ||
              !v.properties.latitude ||
              !v.properties.longitude
            ) {
              return false;
            }

            return true;
          })
          .map(v => {
            if (v.properties?.grupo === 'SEM_CADASTRO') {
              auxMarkersSemCadastro.push(v);
              return v;
            }
            if (
              (v.properties?.prefixo && prefixoIsHT(v.properties?.prefixo)) ||
              (!v.properties?.prefixo && prefixoIsHT(v.properties?.placa))
            ) {
              auxMarkersRadios.push(v);
              return v;
            }
            if (v.properties?.conectado) {
              auxMarkersConectadas.push(v);
              return v;
            }
            if (!v.properties?.conectado) {
              auxMarkersDesconectadas.push(v);
              return v;
            }
          });

        let updateConectadas =
          markersConectados.current.length !== auxMarkersConectadas.length;

        let updateDesconectadas =
          markersDesconectados.current.length !==
          auxMarkersDesconectadas.length;

        let updateRadio =
          markersRadio.current.length !== auxMarkersRadios.length;

        let updateSemCadastro =
          markersSemCadastro.current.length !== auxMarkersSemCadastro.length;

        let newConectadasList: any[] = [];
        let newDesconectadasList: any[] = [];
        let newRadiosList: any[] = [];
        let newSemCadastroList: any[] = [];

        //removendo marcadores desatializados / antigos
        // Saber se tem algo diferente nas conectadas
        for (let i = 0; i < auxMarkersConectadas.length; i += 1) {
          // let newId = true;
          auxMarkersConectadas[i].properties.exist = false;
          markersConectados.current.map(m => {
            if (
              auxMarkersConectadas[i].properties.idDevice === m.veiculo.idDevice
            ) {
              auxMarkersConectadas[i].properties.exist = true;

              if (
                !comparePositionObjects(
                  auxMarkersConectadas[i].properties,
                  m.veiculo
                ) ||
                (icons_fence_alerts.includes(m.markerType) &&
                  !showAlerts.outFence) ||
                (!icons_fence_alerts.includes(m.markerType) &&
                  m.veiculo.foraCerca &&
                  showAlerts.outFence) ||
                (icons_ais_alerts.includes(m.markerType) &&
                  !showAlerts.outAis) ||
                (!icons_ais_alerts.includes(m.markerType) &&
                  m.veiculo.foraAIS &&
                  showAlerts.outAis)
              ) {
                updateConectadas = true;

                const newObj = {
                  ...auxMarkersConectadas[i],
                  properties: {
                    ...auxMarkersConectadas[i].properties,
                    oldLatitude: m.veiculo.latitude,
                    oldLongitude: m.veiculo.longitude,
                  },
                  marker: m.marker,
                  infowindow: m.infowindow,
                  markerType: m.markerType,
                };

                newConectadasList.push(newObj);
              }
            }
          });
          if (!auxMarkersConectadas[i].properties.exist) {
            const newObj = {
              ...auxMarkersConectadas[i],
              properties: {
                ...auxMarkersConectadas[i].properties,
                oldLatitude: auxMarkersConectadas[i].properties.latitude,
                oldLongitude: auxMarkersConectadas[i].properties.longitude,
              },
            };

            updateConectadas = true;
            newConectadasList.push(newObj);
          }
        }

        // Saber se tem algo diferente nas desconectadas
        for (let i = 0; i < auxMarkersDesconectadas.length; i += 1) {
          auxMarkersDesconectadas[i].properties.exist = false;
          markersDesconectados.current.map(m => {
            if (
              auxMarkersDesconectadas[i].properties?.idDevice ===
              m.veiculo.idDevice
            ) {
              auxMarkersDesconectadas[i].properties.exist = true;
              if (
                !comparePositionObjects(
                  auxMarkersDesconectadas[i].properties,
                  m.veiculo
                ) ||
                (m.markerType === 'DESCONECTADA_FORA_CERCA' &&
                  !showAlerts.outFence) ||
                (m.markerType !== 'DESCONECTADA_FORA_CERCA' &&
                  m.veiculo.foraCerca &&
                  showAlerts.outFence) ||
                (m.markerType === 'DESCONECTADA_FORA_AIS' &&
                  !showAlerts.outAis) ||
                (m.markerType !== 'DESCONECTADA_FORA_AIS' &&
                  m.veiculo.foraAIS &&
                  showAlerts.outAis)
              ) {
                const newObj = {
                  ...auxMarkersDesconectadas[i],
                  properties: {
                    ...auxMarkersDesconectadas[i].properties,
                    oldLatitude: m.veiculo.latitude,
                    oldLongitude: m.veiculo.longitude,
                  },
                  marker: m.marker,
                  infowindow: m.infowindow,
                  markerType: m.markerType,
                };

                newDesconectadasList.push(newObj);
                updateDesconectadas = true;
              }
            }
          });
          if (!auxMarkersDesconectadas[i].properties.exist) {
            const newObj = {
              ...auxMarkersDesconectadas[i],
              properties: {
                ...auxMarkersDesconectadas[i].properties,
                oldLatitude: auxMarkersDesconectadas[i].properties.latitude,
                oldLongitude: auxMarkersDesconectadas[i].properties.longitude,
              },
            };

            newDesconectadasList.push(newObj);
            updateDesconectadas = true;
          }
          // if (updateDesconectadas) break;
        }
        // Saber se tem algo diferente nos Radios
        for (let i = 0; i < auxMarkersRadios.length; i += 1) {
          auxMarkersRadios[i].properties.exist = false;
          markersRadio.current.map(m => {
            if (
              auxMarkersRadios[i].properties?.idDevice === m.veiculo.idDevice
            ) {
              auxMarkersRadios[i].properties.exist = true;

              if (
                !comparePositionObjects(
                  auxMarkersRadios[i].properties,
                  m.veiculo
                ) ||
                (m.markerType === 'RÁDIO_FORA_CERCA' && !showAlerts.outFence) ||
                (m.markerType !== 'RÁDIO_FORA_CERCA' &&
                  m.veiculo.foraCerca &&
                  showAlerts.outFence) ||
                (m.markerType === 'RÁDIO_FORA_AIS' && !showAlerts.outAis) ||
                (m.markerType !== 'RÁDIO_FORA_AIS' &&
                  m.veiculo.foraAIS &&
                  showAlerts.outAis)
              ) {
                const newObj = {
                  ...auxMarkersRadios[i],
                  properties: {
                    ...auxMarkersRadios[i].properties,
                    oldLatitude: m.veiculo.latitude,
                    oldLongitude: m.veiculo.longitude,
                  },
                  marker: m.marker,
                  infowindow: m.infowindow,
                  markerType: m.markerType,
                };

                updateRadio = true;
                newRadiosList.push(newObj);
              }
            }
          });
          if (!auxMarkersRadios[i].properties.exist) {
            const newObj = {
              ...auxMarkersRadios[i],
              properties: {
                ...auxMarkersRadios[i].properties,
                oldLatitude: auxMarkersRadios[i].properties.latitude,
                oldLongitude: auxMarkersRadios[i].properties.longitude,
              },
            };

            newRadiosList.push(newObj);
            updateRadio = true;
          }
        }

        // Saber se tem algo diferente nos SEM-CADASTRO
        for (let i = 0; i < auxMarkersSemCadastro.length; i += 1) {
          auxMarkersSemCadastro[i].properties.exist = false;
          markersSemCadastro.current.map(m => {
            if (
              auxMarkersSemCadastro[i].properties?.idDevice ===
              m.veiculo.idDevice
            ) {
              auxMarkersSemCadastro[i].properties.exist = true;
              if (
                !comparePositionObjects(
                  auxMarkersSemCadastro[i].properties,
                  m.veiculo
                )
              ) {
                const newObj = {
                  ...auxMarkersSemCadastro[i],
                  properties: {
                    ...auxMarkersSemCadastro[i].properties,
                    oldLatitude: m.veiculo.latitude,
                    oldLongitude: m.veiculo.longitude,
                  },
                  marker: m.marker,
                  infowindow: m.infowindow,
                  markerType: m.markerType,
                };

                updateSemCadastro = true;
                newSemCadastroList.push(newObj);
              }
            }
          });
          if (!auxMarkersSemCadastro[i].properties.exist) {
            const newObj = {
              ...auxMarkersSemCadastro[i],
              properties: {
                ...auxMarkersSemCadastro[i].properties,
                oldLatitude: auxMarkersSemCadastro[i].properties.latitude,
                oldLongitude: auxMarkersSemCadastro[i].properties.longitude,
              },
            };

            newSemCadastroList.push(newObj);
            updateSemCadastro = true;
          }
        }

        if (
          updateConectadas ||
          updateDesconectadas ||
          updateRadio ||
          updateSemCadastro
        ) {
          setLoadingIcon(true);
        }

        // renderizar cercas
        fencesSelected.current.map(fenceId => {
          try {
            if (!fences.current.idList.includes(fenceId)) {
              fences.current.idList.push(fenceId);
              const fence = fences.current.data.find(f => f.id === fenceId);

              const polygonPath = fence?.geom.points.map((p: any) => ({
                lat: p.y,
                lng: p.x,
              }));

              const newMarkers: google.maps.marker.AdvancedMarkerElement[] = [];
              const listeners: any[] = [];

              const bounds = new google.maps.LatLngBounds();
              let i;

              for (i = 0; i < polygonPath.length; i++) {
                bounds.extend(polygonPath[i]);
              }

              const center = {
                lat: bounds.getCenter().lat(),
                lng: bounds.getCenter().lng(),
              };

              const fencePolygon = new window.google.maps.Polygon({
                paths: polygonPath,
                strokeColor: 'black',
                strokeOpacity: 1,
                strokeWeight: 1,
                fillColor: rgba(0, 0, 0, 0.8),
                map,
                zIndex: 401,
              });

              const infoWindow = new window.google.maps.InfoWindow({
                content: `<div style="padding: 0px 10px 10px 0px" ><b>${fence?.nome}</b></div>`,
                maxWidth: 150,
                // maxHeight: 100,
                zIndex: 4999,
                disableAutoPan: true,
                // map,
                position: center,
              });

              if (!showFencesName.current) {
                infoWindow.close();
              } else {
                infoWindow.open(map);
              }

              const openInfoWindow = fencePolygon.addListener('click', () => {
                infoWindow.open(map);
              });

              listeners.push(openInfoWindow);

              fencesInfo.current.push({
                name: `<b style="margin: 0px 10px 10px 0px">${fence?.nome}</b>`,
                map,
                listeners,
              });

              fence?.pontosBase.map(pb => {
                let initialPosition = new window.google.maps.LatLng(pb.y, pb.x);
                const icon = document.createElement('img');
                icon.src = PontoBaseCerca;

                const basePoint = new window.google.maps.marker.AdvancedMarkerElement(
                  {
                    position: initialPosition,
                    content: icon,
                    map,
                    zIndex: 401,
                    // tracksViewChanges: false,
                  }
                );

                newMarkers.push(basePoint);
              });

              fences.current.mapFences.push({
                polygon: fencePolygon,
                markers: newMarkers,
                infoWindow,
                listeners,
                id: fenceId,
                veiculosAssociados: fence?.veiculosAssociados,
                foraCerca: false,
                polygonPath,
                fenceName: fence?.nome,
              });
            }
          } catch (e) {
            //
          }
        });

        fences.current.mapFences = fences.current.mapFences.filter(fence => {
          if (!fencesSelected.current.includes(fence.id)) {
            fencesInfo.current = fencesInfo.current.filter(
              fenceInfo =>
                fenceInfo.name !==
                `<div style="padding: 0px 10px 10px 0px" ><b>${fence.fenceName}</b></div>`
            );
            fences.current.idList = fences.current.idList.filter(
              id => id !== fence.id
            );
            if (fence.infoWindow) {
              fence.infoWindow.close();
            }
            fence.markers.map(
              (m: google.maps.marker.AdvancedMarkerElement) => (m.map = null)
            );
            fence.listeners.map((l: any) => {
              window.google.maps.event.removeListener(l);
            });
            if (fence.polygon) {
              fence.polygon.setMap(null);
            }
            return false;
          }

          return true;
        });

        if (updateConectadas) {
          markersConectados.current = updateMarkersArray(
            map,
            markersConectados.current,
            auxMarkersConectadas,
            newConectadasList,
            showAlerts,
            showConectadas.current,
            showTooltipConectadas.current,
            false,
            false,
            { min: 60000, max: 70000 }
          );
        }

        if (updateDesconectadas) {
          markersDesconectados.current = updateMarkersArray(
            map,
            markersDesconectados.current,
            auxMarkersDesconectadas,
            newDesconectadasList,
            showAlerts,
            showDesconectadas.current,
            showTooltipDesconectadas.current,
            false,
            false,
            { min: 40000, max: 50000 }
          );
        }

        if (updateRadio) {
          markersRadio.current = updateMarkersArray(
            map,
            markersRadio.current,
            auxMarkersRadios,
            newRadiosList,
            showAlerts,
            showRadio.current,
            showTooltipRadio.current,
            true,
            false,
            { min: 20000, max: 30000 }
          );
        }

        if (updateSemCadastro) {
          markersSemCadastro.current = updateMarkersArray(
            map,
            markersSemCadastro.current,
            auxMarkersSemCadastro,
            newSemCadastroList,
            showAlerts,
            showSemCadastro.current,
            showTooltipSemCadastro.current,
            false,
            true,
            { min: 1000, max: 10000 }
          );
        }
      }
    } catch (e) {
      console.log('e', e);
      toast.error(
        'Erro ao carregar dados, tente novamente. Caso o problema persista, contate o suporte técnico.'
      );
    } finally {
      setTimeout(() => setLoadingIcon(false), 3 * 1000);
    }
  }, [map, data, showAlerts.outAis, showAlerts.outFence]);

  useEffect(() => {
    if (map) {
      let auxMapOccurrences = mapOccurrences.current;

      const currentOccIds = dataOccurrences.map(
        (d: OccurrenceSocket) => d.ocorrencia
      );

      // removendo ocorrencias antigas
      auxMapOccurrences = auxMapOccurrences.filter(occ => {
        if (
          !currentOccIds.includes(occ.occurrence.ocorrencia) ||
          dataOccurrences.length === 0
        ) {
          occ.marker.map = null;
          return false;
        }
        return true;
      });

      for (let i = 0; i < dataOccurrences.length; i += 1) {
        let exist = false;
        auxMapOccurrences = auxMapOccurrences.map(occ => {
          if (occ.occurrence.ocorrencia === dataOccurrences[i].ocorrencia) {
            exist = true;
            if (!showOccurrences.current) {
              occ.marker.map = null;
            } else {
              occ.marker.map = map;
            }
            if (!compareOccurrenceObjects(occ.occurrence, dataOccurrences[i])) {
              if (dataOccurrences[i].finalizado) {
                occ.marker.map = null;
              } else {
                const infowindow = new window.google.maps.InfoWindow({
                  content: generateInfoWindowContentOccurrence(
                    dataOccurrences[i]
                  ),
                  disableAutoPan: true,
                  maxWidth: 400,
                  zIndex: 99999,
                });

                occ.listeners.map(e =>
                  window.google.maps.event.removeListener(e)
                );
                occ.occurrence = dataOccurrences[i];
                occ.marker.addListener('click', () => {
                  infowindow.open({
                    map,
                    anchor: occ.marker,
                    shouldFocus: false,
                  });
                });
              }
            }
          }

          return occ;
        });
        if (
          !exist &&
          dataOccurrences[i].latLng &&
          dataOccurrences[i].latLng.lat !== 0 &&
          dataOccurrences[i].latLng.lng !== 0
        ) {
          let position = new window.google.maps.LatLng(
            dataOccurrences[i].latLng.lat,
            dataOccurrences[i].latLng.lng
          );

          const icon = document.createElement('img');
          icon.src = OccurrenceIcon;

          const marker = new window.google.maps.marker.AdvancedMarkerElement({
            position: position,
            // icon: {
            //   url: OccurrenceIcon,
            //   scaledSize: new window.google.maps.Size(25, 30),
            // },
            content: icon,
            map: showOccurrences.current ? map : null,
            zIndex: 99997,
            // tracksViewChanges: false,
          });

          const infowindow = new window.google.maps.InfoWindow({
            content: generateInfoWindowContentOccurrence(dataOccurrences[i]),
            disableAutoPan: true,
            maxWidth: 400,
            zIndex: 99999,
          });

          const tooltip = new window.google.maps.InfoWindow({
            content: `<div style="padding: 0px 10px 10px 0px"><b>${dataOccurrences[i]?.tipoDesc}</b></div>`,
            maxWidth: 150,
            // maxHeight: 100,
            zIndex: 99998,
            disableAutoPan: true,
          });

          if (showTooltipOccurrences.current && (map.getZoom() || 0) > 13) {
            tooltip.open({
              map,
              anchor: marker,
              shouldFocus: false,
            });
          }

          const listener = marker.addListener('click', () => {
            infowindow.open({
              map,
              anchor: marker,
              shouldFocus: false,
            });
          });

          auxMapOccurrences.push({
            marker,
            tooltip,
            occurrence: dataOccurrences[i],
            listeners: [listener],
            infowindow,
          });
        }
      }

      mapOccurrences.current = auxMapOccurrences;
    }
  }, [map, dataOccurrences]);

  useEffect(() => {
    if (addressMarker.current) {
      addressMarker.current.map = null;
    }
    if (
      map &&
      searchAddress.show &&
      searchAddress.address.latitude &&
      searchAddress.address.longitude
    ) {
      const auxMarkerAddress = new window.google.maps.marker.AdvancedMarkerElement(
        {
          position: {
            lat: searchAddress.address.latitude,
            lng: searchAddress.address.longitude,
          },
          map,
        }
      );
      map.panTo({
        lat: searchAddress.address.latitude,
        lng: searchAddress.address.longitude,
      });
      map.setZoom(17);

      addressMarker.current = auxMarkerAddress;
    }
  }, [searchAddress]);

  // Effect para criar interval que faz request de erros nos microserviços a cada 5 minutos
  useEffect(() => {
    const interval = setInterval(() => {
      handleCheckErrors();
    }, 5 * 60 * 1000);
    return () => clearInterval(interval);
  }, [handleCheckErrors]);

  // Effect para adicionar listenner de bounds_changed para renderizar apenas marcadores dentro dos limites da viewport (janela do usuário)
  useEffect(() => {
    if (map) {
      let previus_bounds: google.maps.LatLngBounds | null = null;

      const renderMarkersInsideBounds = debounce(() => {
        const bounds = map.getBounds();

        if (showConectadas.current) {
          markersConectados.current.map(m => {
            if (bounds?.contains(m.marker.position || { lat: 0, lng: 0 })) {
              m.marker.map = map;
            } else {
              m.marker.map = null;
            }
          });
        }

        if (showDesconectadas.current) {
          markersDesconectados.current.map(m => {
            if (bounds?.contains(m.marker.position || { lat: 0, lng: 0 })) {
              m.marker.map = map;
            } else {
              m.marker.map = null;
            }
          });
        }

        if (showRadio.current) {
          markersRadio.current.map(m => {
            if (bounds?.contains(m.marker.position || { lat: 0, lng: 0 })) {
              m.marker.map = map;
            } else {
              m.marker.map = null;
            }
          });
        }

        if (showSemCadastro.current) {
          markersSemCadastro.current.map(m => {
            if (bounds?.contains(m.marker.position || { lat: 0, lng: 0 })) {
              m.marker.map = map;
            } else {
              m.marker.map = null;
            }
          });
        }
      }, 500);

      google.maps.event.addListener(map, 'bounds_changed', function() {
        if (!previus_bounds || !map.getBounds()?.equals(previus_bounds)) {
          renderMarkersInsideBounds();
          previus_bounds = map.getBounds() || null;
        }
      });
    }
  }, [map]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  return (
    <>
      <div className="buttonSection">
        <Button
          onClick={showModal}
          loading={loadingLogradouro}
          icon={<DownloadOutlined />}
          disabled={
            !Boolean(Object.values(data).length > 0) // ||
            // !Object.values(ativos).length > 0
          }
          className="button gerarRelatorio"
        >
          <span id="text">
            {' '}
            {loadingLogradouro ? 'Aguarde...' : 'Gerar Relatório'}
          </span>
        </Button>

        <Button
          id="activateAlertButton"
          className={
            !activeAlert
              ? 'button activeAlertButton'
              : 'button desactiveAlertButton'
          }
          icon={<PoweroffOutlined />}
          onClick={() => {
            firstLoadOfActiveAlert.current = true;
            if (!activeAlert) {
              onActivateAlertSocket();
            } else {
              onDeactiveAlertSocket();
            }
          }}
          disabled={!data.length}
        >
          <span id="text">
            {!activeAlert ? 'Ativar Alertas' : 'Desativar Alertas'}
          </span>
        </Button>

        {activeAlert && (
          <Button
            onClick={() => setIsOpenModalAlerts(true)}
            icon={<HistoryOutlined />}
            // icon={<img src={AlertIconMonitoring} className="alertIcon" />}
            type="primary"
            ghost
            className="button alertButton"
          >
            <span id="text">Histórico de Alertas</span>
          </Button>
        )}
      </div>

      <div
        className={
          !collapsed ? 'iconeRastreamentoCollapsed' : 'iconeRastreamento'
        }
      >
        <img alt="" className="satelite" src={satelite} />
        <p className={statusSocketTVP ? 'conectado' : ''}>
          {statusSocketTVP ? 'On' : 'Off'}
        </p>
      </div>

      <div
        hidden={!loadingIcon}
        className={!collapsed ? 'iconeCarregandoCollapsed' : 'iconeCarregando'}
      >
        <LoadingOutlined id="icone" />
        <img id="iconeCarro" src={iconeCarroCarregamento} />
      </div>

      {notificationError.length > 0 && (
        <div
          className={
            !collapsed ? 'iconeNotificacaoCollapsed' : 'iconeNotificacao'
          }
        >
          <Popover
            placement="left"
            content={notificationError.map(e => (
              <div key={e.message}>
                <p
                  style={{
                    border: '1px solid red',
                    padding: '10px',
                    borderRadius: '10px',
                    margin: '3px',
                  }}
                >
                  <ExclamationCircleOutlined style={{ color: 'red' }} />{' '}
                  {e.message}
                </p>
              </div>
            ))}
          >
            <ExclamationOutlined id="icone" />
          </Popover>
        </div>
      )}

      <MonitoringReportModal
        handleCancel={() => {}}
        open={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        key={1}
      />

      <GoogleMap
        id="monitoring_map"
        onLoad={onMapLoad}
        mapContainerStyle={{
          width: '100%',
          height: '100%',
        }}
        options={mapOptions}
      >
        {Object.keys(selectedAis)?.map(
          (ais, index) =>
            !selectedAis[ais] && (
              <Polygon
                key={index}
                paths={dadosLatLngAis[parseInt(ais, 10) - 1].map(a => ({
                  lat: a[1],
                  lng: a[0],
                }))}
                options={{
                  strokeColor: 'purple',
                  strokeOpacity: 1,
                  strokeWeight: 1,
                  fillColor: rgba(138, 43, 226, 0.5),
                }}
              />
            )
        )}

        {/* Desenhar traços das AIS */}
        {Object.keys(selectedAis)?.map((ais, index) => (
          <Polyline
            key={index}
            path={dadosLatLngAis[parseInt(ais, 10) - 1].map(a => ({
              lat: a[1],
              lng: a[0],
            }))}
            options={{
              strokeColor: 'black',
              strokeWeight: 0.5,
              // fillColor: rgba(255, 255, 255, 0),
            }}
          />
        ))}
      </GoogleMap>
    </>
  );
}

export default MonitoringMap;
