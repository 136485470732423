import { TrackingHistoryContext } from 'context/tracking_history';
import { useContextSelector } from 'use-context-selector';

export function useHistoryRaioPonto() {
  const raioPonto = useContextSelector(
    TrackingHistoryContext,
    context => context.raioPonto
  );

  const setRaioPonto = useContextSelector(
    TrackingHistoryContext,
    context => context.setRaioPonto
  );

  return {
    raioPonto,
    setRaioPonto,
  };
}

