/* eslint-disable */
import React from 'react';
import moment from 'moment';
import iconMap from 'imgs/open-map.svg';
import fenceIcon from 'imgs/fora_cerca.svg';
import speedLimitIcon from 'imgs/speed_limit.svg';
import timeOutIcon from 'imgs/time_out.svg';
import { v4 as uuidv4 } from 'uuid';

import { Container } from './styles';

interface AlertCardProps {
  type: string;
  outAIS: boolean;
  date: string;
  prefixo: string;
  placa: string;
  cerca: string;
  ais: string;
  velocity: number;
  lastIndex: number;
  key: number;
}

export function AlertCard({
  type,
  outAIS,
  date,
  prefixo,
  placa,
  cerca,
  ais,
  velocity,
  lastIndex,
}: AlertCardProps) {
  const LABEL_NAME: any = {
    SAIU_DA_CERCA: outAIS ? 'Fora da AIS' : 'Fora da Cerca',
    LIMITE_VELOCIDADE: 'Limite de Velocidade',
    PARADA_SUPERIOR_15_MIN: 'Parada superior à 15 minutos',
  };
  const formattedDate = moment(date).format('DD/MM/YYYY HH:mm:ss');

  return (
    <Container key={uuidv4()}>
      <div
        className={`card ${outAIS ? 'FORA_AIS' : type}_MODAL_ALERT`}
        style={{ marginRight: `${lastIndex ? '0px' : '10px'}` }}
      >
        <div className="cardTitle">
          <div
            className={outAIS ? 'FORA_AIS' : type}
            style={{ marginRight: '10px' }}
          >
            {outAIS && (
              <img
                src={iconMap}
                alt="map.icon"
                id="mapIcon"
                style={{ width: '40px', height: '40px' }}
              />
            )}
            {type === 'SAIU_DA_CERCA' && !outAIS && (
              <img
                src={fenceIcon}
                alt="map.icon"
                id="fenceIcon"
                style={{ width: '40px', height: '40px' }}
              />
            )}
            {type === 'LIMITE_VELOCIDADE' && (
              <img
                src={speedLimitIcon}
                alt="map.icon"
                id="speedIcon"
                style={{ width: '40px', height: '40px' }}
              />
            )}
            {type === 'PARADA_SUPERIOR_15_MIN' && (
              <img
                src={timeOutIcon}
                alt="map.icon"
                id="timeOutIcon"
                style={{ width: '40px', height: '40px' }}
              />
            )}
          </div>

          <span>{LABEL_NAME[type]}</span>
        </div>

        <div className="cardBody">
          <span id={outAIS ? 'FORA_AIS' : type}>
            Placa: <b id="valueAlert">{placa}</b>
          </span>
          <span id={outAIS ? 'FORA_AIS' : type}>
            Prefixo: <b id="valueAlert">{prefixo}</b>
          </span>
          {type === 'SAIU_DA_CERCA' && !outAIS && (
            <span id={type}>
              Cerca: <b id="valueAlert">{cerca}</b>
            </span>
          )}
          {outAIS && (
            <span>
              AIS: <b id="valueAlert">{ais}</b>
            </span>
          )}
          {type === 'LIMITE_VELOCIDADE' && (
            <span>
              Velocidade (km/h): <b id="valueAlert">{velocity}</b>
            </span>
          )}
        </div>
        <div className="cardFooter">{formattedDate}</div>
      </div>
    </Container>
  );
}
