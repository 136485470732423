import React, { useState, useEffect } from 'react';
import { Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useJsApiLoader } from '@react-google-maps/api';
import { pdfGen } from './reports';
import columns from 'columns/PDF/Conectados/columns';
import PdfImprimir from '../../../components/PdfImprimir';

import ENV from 'config';
import Filter from '../Filter';
import '../styles.css';
import { PositionVehicle } from 'interfaces/vehicle';
import { useLoadLastPositions } from 'hooks/global/useLoadLastPositions';
import { googleLibraries } from 'utils/functionsUtils';

export function PdfConectados() {
  const [dadosLast, setDadosLast] = useState<PositionVehicle[]>([]);
  const [dataFiltered, setDataFiltered] = useState<PositionVehicle[]>([]);
  const { loadLastPositions } = useLoadLastPositions();

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: ENV.googleMapsApiKey,
    libraries: googleLibraries,
    region: 'BR',
    language: 'pt-br',
  });

  const isDateGreaterThan30Minutes = (dateForCompare: string) => {
    const date = Number(new Date(dateForCompare));
    const currentDate = Number(new Date());
    const diff = currentDate - date;

    const diffInMinutes = Math.floor(diff / (1000 * 60)); // Convert milliseconds to minutes

    if (diffInMinutes >= 30) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    const load = async () => {
      try {
        const lastPositions = await loadLastPositions();

        const vehiclesDisconnected = (lastPositions || []).filter(
          v =>
            v.statusConexaoViatura &&
            v.statusConexaoViatura !== 'DESCONECTADA' &&
            isDateGreaterThan30Minutes(v.datePosition)
        );

        setDadosLast(vehiclesDisconnected);
        setDataFiltered(vehiclesDisconnected);
      } catch (err) {
        toast.error('Erro ao carregar dados');
      }
    };
    load();
  }, [loadLastPositions]);

  return (
    <>
      <Breadcrumb style={{ padding: '10px' }}>
        <Breadcrumb.Item>
          {/* @ts-expect-error Server Component */}
          <Link to="/">Página Inicial</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          {/* @ts-expect-error Server Component */}
          <Link to="/relatorios">Relatorios</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Conectados</Breadcrumb.Item>
      </Breadcrumb>
      <Filter data={dadosLast} setDataFiltered={setDataFiltered} />
      <PdfImprimir
        dadosLast={dataFiltered}
        loadingTable={!dadosLast || !dadosLast.length}
        columnWithModal
        columns={columns}
        pdfGen={pdfGen}
        isLoaded={isLoaded}
      />
    </>
  );
}
