/* eslint-disable */
import { Alert, AlertFilter, AlertParams } from "interfaces/alerts";
import api from "../api";

export const ServiceReportAlert = {

  getAllPageable: async({page, size, body} : {page: number, size: number, body: AlertParams}) : Promise<Alert[]> => {
    return await api.post(`rotas/alertas/relatorio/page?page=${page}&size=${size}`, {...body});
  },

  getAllNoPageable: async (body : AlertParams) : Promise<Alert[]> => {
    return await api.post('rotas/alertas/relatorio/all', {...body});
  },

  getLast: async (body : AlertFilter) : Promise<Alert[]> => {
    return await api.post('rotas/alertas/relatorio/last', {...body});
  },

  getLastByPlate: async (plates: string[]) : Promise<Alert[][]> => {
    return await api.post('rotas/alertas/relatorio/last/placas', {
      placas: plates
    }, {
      timeout: 60 * 1000
    })
  }

};
