import ACTION from '../actionTypes';

const initialState = {
  ais: [],
  vinculadas: [],
  inputLst: [],
  resume: [],
  alertas: [],
  placas: [],
  loading: false,
  error: null,
};

export default function prefixo(state = initialState, action) {
  switch (action.type) {
    case ACTION.MONITORING_VIN_ALL_REQUEST:
    case ACTION.MONITORING_AIS_ALL_REQUEST:
    case ACTION.MONITORING_VEI_ALL_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ACTION.MONITORING_VIN_ALL_SUCCESS: {
      return {
        ...state,
        vinculadas: action.data,
        loading: false,
      };
    }
    case ACTION.MONITORING_PLACAS_ALL_SUC: {
      return {
        ...state,
        placas: action.data,
        loading: false,
      };
    }
    case ACTION.MONITORING_AIS_ALL_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: null,
        ais: action.data,
      };
    }
    case ACTION.MONITORING_ALERT_ALL_SUCCESS: {
      const alertas = [];
      action.data.forEach(v => {
        const filtered = state.placas.find(p => p.placa === v.placa);
        if (filtered) {
          alertas.push({ ...v, ...filtered });
        }
      });

      return {
        ...state,
        alertas,
      };
    }
    case ACTION.MONITORING_VEI_ALL_SUCCESS: {
      const placas = action.data?.map(feature => feature.identificador);
      const prefixos = action.data?.map(feature => feature.prefixo);
      return {
        ...state,
        resume: action.data,
        inputLst: [...placas, ...prefixos],
        loading: false,
      };
    }
    case ACTION.MONITORING_ERROR:
      return {
        ...state,
        loading: false,
        error: action.message,
      };
    default:
      return state;
  }
}
