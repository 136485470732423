import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Slider } from 'antd';
import { Container } from './styles';
import type { SliderMarks } from 'antd/es/slider';
import moment from 'moment';
import { useHistorySearchedDateSlider } from 'hooks/history/useHistorySearchedDateSlider';
import { useHistorySearchedDate } from 'hooks/history/useHistorySearchedDate';

export const SliderPerso = () => {

  const {searchedDate} = useHistorySearchedDate();

  const {setSearchedDataSlider} = useHistorySearchedDateSlider();
  
  let marks = useRef<SliderMarks | null>();

  const formatter = (value: number | undefined) => <div>{moment(timestampIni.current + (step.current * (value || 0))).toISOString().substring(11, 19)}</div>;

  const timestampIni = useRef<number>(0);
  const timestampFim = useRef<number>(0);
  const step = useRef<number>(0)

  const handleChangeTime = useCallback((values: any) => {
    const startAux = timestampIni.current + (step.current * values[0]);
    const endAux = timestampFim.current - (step.current * (100 - values[1]));

    setSearchedDataSlider({
      startDate: moment(startAux).utc().format('YYYY-MM-DDTHH:mm:ss'),
      endDate: moment(endAux).utc().format('YYYY-MM-DDTHH:mm:ss')
    })
  }, [])

  useEffect(() => {
    timestampIni.current = Number(moment(searchedDate.dataInicio).subtract(3, 'hours').format('x'));
    timestampFim.current = Number(moment(searchedDate.dataFim).subtract(3, 'hours').format('x'));
    step.current = (timestampFim.current - timestampIni.current) / 100;

    marks.current = {
      0: {
        style: { color: 'black' },
        label: <strong>{moment(searchedDate.dataInicio).subtract(3, 'hours').toISOString().substring(11, 19) || ''}</strong>,
      },
      100: {
        style: { color: 'black' },
        label: <strong>{moment(searchedDate.dataFim).subtract(3, 'hours').toISOString().substring(11, 19) || ''}</strong>,
      },
    }
  }, [searchedDate])

  return (
    <Container>
      <Slider marks={marks.current || {}} tipFormatter={formatter} onAfterChange={(e) => handleChangeTime(e)} className="sliderAntd" range defaultValue={[0, 100]} />
    </Container>
  );
};

