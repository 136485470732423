import { FiltersMapContext } from 'context/mapa/filters_map';
import { useContextSelector } from 'use-context-selector';

export function useFiltersMapSelectedAis() {
  const selectedAis = useContextSelector(
    FiltersMapContext,
    context => context.selectedAis
  );

  const onSelectedAis = useContextSelector(
    FiltersMapContext,
    context => context.onSelectedAis
  );

  return {
    selectedAis,
    onSelectedAis,
  };
}

