import { AuthContext } from 'context/auth';
import { globalContext } from 'context/global';
import { useContextSelector } from 'use-context-selector';

export function useGlobalFences() {
  const fences = useContextSelector(globalContext, context => context.fences);

  const showFencesName = useContextSelector(
    globalContext,
    context => context.showFencesName
  );

  const handleRenderFencesName = useContextSelector(
    globalContext,
    context => context.handleRenderFencesName
  );

  return {
    fences,
    showFencesName,
    handleRenderFencesName,
  };
}

