import { all, call, put, takeLatest } from 'redux-saga/effects';
import { getAllSuccess, getByIdSuccess, error } from './actions';

import { ServiceOcorrencia } from '~/services/modulos/serviceOcorrencia';

import ACTION from '../actionTypes';

function* listPageableSaga({ params }) {
  try {
    const list = yield call(ServiceOcorrencia.getList, params);
    yield put(getAllSuccess(list));
  } catch (e) {
    yield put(error(e));
  }
}

function* getById({ id }) {
  try {
    const list = yield call(ServiceOcorrencia.getById, id);
    yield put(getByIdSuccess(list));
  } catch (e) {
    yield put(error(e));
  }
}

export default all([
  takeLatest(ACTION.OCORRENCIA_LIST_REQUEST, listPageableSaga),
  takeLatest(ACTION.OCORRENCIA_GET_ID_REQUEST, getById),
]);
