// @ts-nocheck
import React, { useState, useCallback } from 'react';
import { Input, Form, Spin, Tooltip } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';

import { toast } from 'react-toastify';
import { InputCPF } from '../FormAntd/InputCPF';
import { ModalRecuperarSenha } from './ModalRecuperarSenha';
import ENV from 'config';
import logo from '~/imgs/logo-rotas.svg';
import desenv from '~/imgs/cms.svg';
import { Container, Button } from './styles';
import NewFooter from '../Footer';

import christmasIcon from '../../imgs/icone_gorro_natal.png';
import { useAuthFunctions } from 'hooks/auth/useAuthFunctions';

export function Login() {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [visibleModal, setVisibleModal] = useState(false);

  const { signIn } = useAuthFunctions();

  const pegaToken = async (cpf, pass) => {
    const patt1 = /[0-9]/g;
    cpf = cpf.toString();
    pass = pass.toString();
    cpf = cpf.match(patt1).join('');
    await signIn({ cpf, password: pass });
  };

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 24 },
  };

  const onFinish = values => {
    const patt1 = /[0-9]/g;
    values.cpf = values.cpf.match(patt1).join('');
    setLoading(true);

    pegaToken(values.cpf, values.password).finally(() => {
      setLoading(false);
    });
  };

  const handleModal = useCallback(e => {
    e.preventDefault();
    setVisibleModal(true);
  }, []);

  const month = new Date().getMonth();

  return (
    <Container>
      <div className="container-sistema">
        <>
          {ENV.ambiente === 'dev' && (
            <h3
              className="nome-sistema"
              style={{
                color: 'black',
                fontWeight: 'bold',
                display: 'flex',
                alignItems: 'center',
                marginLeft: 4,
                fontSize: 14,
              }}
            >
              <img src={desenv} alt="ROTAS" width={30} height={30} />
              DESEN
            </h3>
          )}
          {/* {ENV.ambiente === 'stage' && (
            <h3
              className="nome-sistema"
              style={{
                color: 'orange',
                fontWeight: 'bold',
                display: 'flex',
                alignItems: 'center',
                marginLeft: 4,
                fontSize: 14,
              }}
            >
              HOMO
            </h3>
          )}
          {ENV.ambiente === 'localhost' && (
            <h3
              className="nome-sistema"
              style={{
                color: 'orange',
                fontWeight: 'bold',
                display: 'flex',
                alignItems: 'center',
                marginLeft: 4,
                fontSize: 14,
              }}
            >
              LOCAL
            </h3>
          )} */}
        </>
      </div>

      <div className="limiter">
        <div className="barra-verde-login">
          <div className="barra-laranja-login" />
        </div>
        <div className="container-login100">
          <div className="container-title">
            <div className="text-one">Bem-vindo ao</div>
            <div className="text-tow">R.O.T.A.S.</div>
            <div className="text-three">
              Registro de Operações Táticas e Ações de Segurança
            </div>
          </div>

          <div id="fixed" className="wrap-login100">
            {month === 11 && (
              <div className="christmasIcon">
                <img id="img" src={christmasIcon} alt="Boas festas!" />
              </div>
            )}
            <Spin spinning={loading}>
              <Form
                {...layout}
                name="basic"
                form={form}
                onFinish={onFinish}
                className="login100-form validate-form"
              >
                <span className="login100-form-title">
                  <Tooltip title="ROTAS">
                    <img src={logo} alt="ROTAS" width={160} height={100} />
                  </Tooltip>
                </span>

                <span
                  className="login100-form-title"
                  style={{ justifyContent: 'flex-start' }}
                >
                  <strong>Login</strong>
                </span>
                <Form.Item
                  style={{ marginBottom: 14 }}
                  name="cpf"
                  rules={[
                    { required: true, message: 'Por favor, insira o CPF!' },
                  ]}
                >
                  <InputCPF
                    prefix={<UserOutlined style={{ fontSize: 15 }} />}
                    placeholder="Informe o CPF"
                    className="wrap-input100"
                  />
                </Form.Item>
                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: 'Por favor, insira a senha!',
                    },
                  ]}
                >
                  <Input.Password
                    prefix={<LockOutlined style={{ fontSize: 15 }} />}
                    type="password"
                    className="wrap-input100"
                    placeholder="Informe a senha"
                  />
                </Form.Item>

                <div className="container-login100-form-btn">
                  <div className="wrap-login100-form-btn">
                    <div className="login100-form-bgbtn" />
                    <Button
                      type=""
                      className="login100-form-btn btnLogin"
                      htmlType="submit"
                    >
                      <strong style={{ fontSize: 20, fontWeight: 500 }}>
                        ENTRAR
                      </strong>
                    </Button>
                  </div>
                </div>

                <a href="/" onClick={handleModal} className="linkRecuperaSenha">
                  Esqueci minha senha
                </a>
              </Form>
            </Spin>
          </div>
        </div>
      </div>
      <NewFooter />

      {/* <div className="rodape">
        <div className="rodape-titulo">
          SECRETARIA DE SEGURANÇA PÚBLICA E DEFESA SOCIAL
        </div>
        <div className="rodape-subtitulo">
          Copyright © 2021 - Coordenadoria de Tecnologia da Informação e
          Comunicação - COTIC. Todos os direitos resevados
        </div>
      </div> */}

      <ModalRecuperarSenha
        visible={visibleModal}
        onCancel={() => setVisibleModal(false)}
        onOk={() => {
          setVisibleModal(false);
          toast.success(
            'E-mail de alteração enviado! Por favor, verifique seu e-mail.'
          );
        }}
      />
    </Container>
  );
}

