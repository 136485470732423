import React, { useCallback, useEffect, useState } from 'react';
import { DatePicker, Modal, Form } from 'antd';
import PropTypes from 'prop-types';
import moment from 'moment';
import { toast } from 'react-toastify';
import { ServiceEquipamento } from '~/services/modulos/serviceEquipamento';
import { convertDateToISO } from '~/utils/date/dateUtils';

export const ModalDataAtivDesEquipamento = ({
  visible,
  onCancel,
  onOk,
  equipamentoSelected,
  equipamentoList,
}) => {
  const [form] = Form.useForm();
  const [initialDataAtivacao, setInitialDataAtivacao] = useState(null);
  const [initialDataDesativacao, setInitialDataDesativacao] = useState(null);

  useEffect(() => {
    try {
      const historicoFiltered = equipamentoList?.filter(
        h => h.key === equipamentoSelected
      );

      if (historicoFiltered && historicoFiltered.length > 0) {
        const newAtiv = convertDateToISO(historicoFiltered[0].momentAtivacao);
        const newDesativ = historicoFiltered[0].momentDesativacao
          ? convertDateToISO(historicoFiltered[0].momentDesativacao)
          : null;
        setInitialDataAtivacao(newAtiv);
        setInitialDataDesativacao(newDesativ);
      }
    } catch (err) {
      toast.error('Erro ao carregar Equipamento!');
    }
  }, [equipamentoSelected]);

  useEffect(() => {
    // console.log(initialDataAtivacao, initialDataDesativacao);

    form.setFieldsValue({
      dataAtivacao: initialDataAtivacao ? moment(initialDataAtivacao) : null,
      dataDesativacao: initialDataDesativacao
        ? moment(initialDataDesativacao)
        : null,
    });
  }, [form, initialDataAtivacao, initialDataDesativacao]);

  const handleSubmit = useCallback(
    values => {
      onOk(values);
    },

    [onOk]
  );

  return (
    <Modal
      destroyOnClose
      title="Equipamento"
      className="modal-recupera-login"
      open={visible}
      onCancel={onCancel}
      onOk={form.submit}
      maskClosable={false}
    >
      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        <Form.Item
          label="Data ativação"
          name="dataAtivacao"
          rules={[
            {
              required: true,
              message: 'Por favor, informe a data de ativação',
            },
          ]}
        >
          <DatePicker showTime format="DD/MM/YYYY HH:mm:ss" />
        </Form.Item>

        <Form.Item label="Data desativação" name="dataDesativacao">
          <DatePicker showTime format="DD/MM/YYYY HH:mm:ss" />
        </Form.Item>

        <Form.Item
          wrapperCol={{
            xs: { span: 24, offset: 0 },
            sm: { span: 16, offset: 8 },
          }}
        />
      </Form>
    </Modal>
  );
};

ModalDataAtivDesEquipamento.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onOk: PropTypes.func.isRequired,
  equipamentoSelected: PropTypes.any,
  equipamentoList: PropTypes.array,
};

ModalDataAtivDesEquipamento.defaultProps = {
  equipamentoSelected: -1,
  equipamentoList: [],
};
