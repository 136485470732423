import api from '../api';

import { serviceReutils } from '../../utils/reutils';
import { ResumeVehicle } from 'interfaces/vehicle';

export const ServiceVeiculo = {
  ...serviceReutils({ name: 'rotas/v1/veiculo', api }),

  resumoVeiculo: async (): Promise<ResumeVehicle[]> => {
    return await api.get('rotas/v1/veiculo/resume-auth');
  },

  getByPlaca: async (placa: number): Promise<any> => {
    const retorno = await api.get('rotas/v1/veiculo/placa', {
      params: { placa },
    });

    return retorno;
  },

  saveVeiculo: async (body: any): Promise<any> => {
    const retorno = await api.post('rotas/v1/veiculo/save', {
      ...body,
    });

    return retorno;
  },

  getPropByVeiculo: (prefixo: string): Promise<any> =>
    api.get(`rotas/viaturas/propr?identificador=${prefixo}`),
};
