import React, { MutableRefObject } from 'react';
import Container from './styles';

import MonitoringMap from './index';
import { Search } from '../pesquisa';
import { Alert } from 'interfaces/alerts';
import { AddressProps } from 'interfaces/vehicle';

interface WrapperProps {
  searchAddress: AddressProps;
  setSearchAddress: (value: any) => void;
  collapsed: boolean;
  // ativos: any;
  setIsOpenModalAlerts: (value: boolean) => void;
  // allAlerts: MutableRefObject<Alert[]>;
}

export function WrapperMonitoringMap({
  searchAddress,
  setSearchAddress,
  collapsed,
  // ativos,
  setIsOpenModalAlerts,
}: // allAlerts,
WrapperProps) {
  return (
    <Container>
      <Search handleSetAddress={setSearchAddress} />
      <MonitoringMap
        searchAddress={searchAddress}
        setIsOpenModalAlerts={setIsOpenModalAlerts}
        collapsed={collapsed}
        // ativos={ativos}
        // allAlerts={allAlerts}
      />
    </Container>
  );
}

export default WrapperMonitoringMap;
