import React, { useCallback, useMemo, useState } from 'react';

import { Form, Select, Row, Col, Spin, Checkbox, Tooltip } from 'antd';

import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Container } from './styles';
import Button from '../../../button';
import { useHistoryFormInfo } from 'hooks/monitoring/useHistoryFormInfo';
import { useHistoryResetFunctions } from 'hooks/history/useHistoryResetFunctions';
import { useHistorySeachByAisAndDate } from 'hooks/history/useHistorySearchbyAisAndDate';
import { useFiltersMapSelectedAis } from 'hooks/filtersMap/useFiltersMapSelectedAis';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useHistoryDataSource } from 'hooks/history/useHistoryDataSource';
import debounce from 'lodash.debounce';
import { allAis } from 'utils/functionsUtils';
import './styles.css';

const { Option } = Select;

const INTERVALO_MAXIMO_PESQUISA = 3 * 60 * 60; // 3 min

interface formAuditoriaProps {
  alternaAis: (props: any) => void;
  onVisible: (value: boolean) => void;
}

export function FormAuditoriaAis({
  alternaAis,
  onVisible,
}: formAuditoriaProps) {
  const { selectedAis: objRoleAis } = useFiltersMapSelectedAis();

  const [form] = Form.useForm();
  const [dtInicio, setDtInicio] = useState('');
  const [hrInicio, setHrInicio] = useState('');

  const [apenasAis, setApenasAis] = useState(false);

  const { dataSource } = useHistoryDataSource();

  const [dtFim, setDtFim] = useState('');
  const [hrFim, setHrFim] = useState('');

  const { setDataOccurrences } = useHistoryFormInfo();

  const { searchByAisAndDate } = useHistorySeachByAisAndDate();

  const {
    setLoadingHistorico,
    resetAllData,
    cleanSelectedAis,
    setChoosingOrigin,
  } = useHistoryResetFunctions();

  // @ts-ignore
  const { loading } = useSelector(state => state.monitoring);
  // @ts-ignore
  let { ais } = useSelector(state => state.monitoring);

  if (ais && ais.length > 0) {
    ais = ais.filter((a: any) => a.nome.split(' ').length > 1);
  }

  const handleChangeDtInicio = useCallback(
    (value: string) => {
      setDtInicio(value);

      if (!dtFim) {
        setDtFim(value);
      }
    },
    [dtFim]
  );

  const debounceChangeDate = debounce((e: any) => {
    handleChangeDtInicio(e.target.value);
  }, 500);

  const onFinish = useCallback(
    async (values: any) => {
      try {
        resetAllData();
        cleanSelectedAis();
        setChoosingOrigin(false);
        setDataOccurrences([]);
        const { aisSelected } = values;

        let numberAis = aisSelected.substring(3, aisSelected?.length);

        if (!dtInicio || !hrInicio || !hrFim || !dtFim) {
          toast.error('Por favor, preencha a data.');
          setLoadingHistorico(false);
          return;
        }

        const momentInicio = moment(new Date(`${dtInicio}T${hrInicio}`));
        const momentFim = moment(new Date(`${dtFim}T${hrFim}`));

        if (momentFim.isBefore(momentInicio)) {
          toast.info('Data inválida');
          setLoadingHistorico(false);

          return;
        }

        const secondsDiff = momentFim.diff(momentInicio, 'seconds');

        if (secondsDiff > INTERVALO_MAXIMO_PESQUISA) {
          toast.warn('Intervalo máximo de pesquisa é de 3 horas.');
          setLoadingHistorico(false);
          return;
        }

        const dataInicio = momentInicio.format('YYYY-MM-DDTHH:mm:ss');
        const dataFim = momentFim.format('YYYY-MM-DDTHH:mm:ss');

        // setAisAuditoria(numberAis);

        if (objRoleAis[numberAis] !== undefined && objRoleAis[numberAis]) {
          alternaAis({ id: `ais-${Number(numberAis)}` });
        }

        await searchByAisAndDate({
          dataInicio,
          dataFim,
          nome: aisSelected,
          apenasAis,
        });
        onVisible(false);
      } catch (err) {
        toast.info('Consulta sem resultado');
      } finally {
        setLoadingHistorico(false);
      }
    },
    [
      dtInicio,
      dtFim,
      hrFim,
      hrInicio,
      resetAllData,
      objRoleAis,
      cleanSelectedAis,
      setLoadingHistorico,
      searchByAisAndDate,
      apenasAis,
    ]
  );

  const inputAis = useMemo(
    () => (
      <>
        {allAis.map(a => (
          <Option key={a.value} value={a.value}>
            {a.label}
          </Option>
        ))}
      </>
    ),
    [allAis]
  );

  const resetarCampos = () => {
    form.resetFields();
    resetAllData();
    cleanSelectedAis();
    setDtInicio('');
    setHrInicio('');
    setHrFim('');
  };

  return (
    <Container>
      <Spin spinning={loading}>
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Row>
            <Col span={13} offset={1}>
              <Form.Item
                label="AIS"
                name="aisSelected"
                rules={[
                  {
                    required: true,
                    message: 'Por favor, selecione a AIS!',
                  },
                ]}
                required
              >
                <Select
                  virtual={false}
                  showSearch
                  // style={{ width: 200 }}
                  getPopupContainer={trigger => trigger.parentElement}
                  placeholder="Selecione a AIS"
                  filterOption={(input, option) => {
                    return Boolean(
                      option?.value &&
                        (String(option.value) || '')
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                    );
                  }}
                >
                  {inputAis}
                </Select>
              </Form.Item>
            </Col>
            {dataSource === 'COTIC' && (
              <Col span={9} offset={1}>
                <Form.Item
                  label={
                    <div
                      style={{
                        display: 'flex',
                        marginLeft: '40%',
                        width: '100%',
                        height: '20px',
                      }}
                    >
                      <p>Cadastro</p>
                      <Tooltip title="Selecione para visualizar apenas as viaturas cadastradas na AIS selecionada">
                        <InfoCircleOutlined
                          style={{
                            marginLeft: 10,
                            color: 'blue',
                            height: '20px',
                          }}
                        />
                      </Tooltip>
                    </div>
                  }
                >
                  <Checkbox
                    checked={apenasAis}
                    onChange={e => {
                      setApenasAis(e.target.checked);
                    }}
                    style={{ marginLeft: '40%' }}
                  />
                </Form.Item>
              </Col>
            )}
          </Row>

          <Row>
            <Col offset={1} style={{ marginBottom: 15 }}>
              <div className="ant-col ant-form-item-label">
                <span className="ant-form-item-required">Data Inicial:</span>
              </div>
              <br />
              <span>
                <input
                  style={{
                    height: 30,
                    padding: 5,
                    borderRadius: 10,
                    border: '1px solid #d9d9d9',
                  }}
                  type="date"
                  id="dtInicio"
                  name="dtInicio"
                  step="1"
                  // value={dtInicio}
                  onChange={debounceChangeDate}
                />
                <input
                  style={{
                    height: 30,
                    padding: 5,
                    borderRadius: 10,
                    border: '1px solid #d9d9d9',
                  }}
                  type="time"
                  id="hrInicio"
                  name="hrInicio"
                  step="1"
                  value={hrInicio}
                  onChange={e => {
                    setHrInicio(e.target.value);
                  }}
                />
              </span>
            </Col>
          </Row>

          <Row>
            <Col offset={1} style={{ marginBottom: 15 }}>
              <div className="ant-col ant-form-item-label">
                <span className="ant-form-item-required">Data Final:</span>
              </div>
              <br />
              <span>
                <input
                  style={{
                    height: 30,
                    padding: 5,
                    borderRadius: 10,
                    border: '1px solid #d9d9d9',
                  }}
                  type="date"
                  id="dtFim"
                  name="dtFim"
                  step="1"
                  value={dtFim}
                  onChange={e => {
                    setDtFim(e.target.value);
                  }}
                />
                <input
                  style={{
                    height: 30,
                    padding: 5,
                    borderRadius: 10,
                    border: '1px solid #d9d9d9',
                  }}
                  type="time"
                  id="hrFim"
                  name="hrFim"
                  step="1"
                  value={hrFim}
                  onChange={e => {
                    setHrFim(e.target.value);
                  }}
                />
              </span>
            </Col>
          </Row>

          <Row justify="center" className="slotBotoes">
            <div className="grid-button">
              <Col>
                <Form.Item>
                  <Button type="submit">Buscar</Button>
                </Form.Item>
              </Col>

              <Col>
                <Form.Item>
                  <Button
                    type="reset"
                    buttonStyle="btn--secondary"
                    onClick={resetarCampos}
                  >
                    Limpar
                  </Button>
                </Form.Item>
              </Col>
            </div>
          </Row>
        </Form>
      </Spin>
    </Container>
  );
}
