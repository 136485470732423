/* eslint-disable react/prop-types */
import React, { useCallback, useMemo, useState } from 'react';
import Modal from 'antd/lib/modal/Modal';
import { useHistory } from 'react-router-dom';
import { Popconfirm, Row, Table, Tag, Tooltip } from 'antd';
import {
  GoogleMap,
  useJsApiLoader,
  Polygon,
  Marker,
} from '@react-google-maps/api';
import { MapTrifold, PencilSimpleLine, Trash } from 'phosphor-react';

import ENV from 'config';
import style from '../style.module.css';
import { ReactComponent as IconCerca } from '../../../../imgs/cerca-eletronica.svg';
import PontoBaseCerca from '../../../../imgs/ponto_base_cerca.svg';
import { templateColumns } from './columns';
import { googleLibraries } from 'utils/functionsUtils';

const containerStyle = {
  width: '100%',
  height: '100%',
  borderRadius: 12,
};

type TCardCerca = {
  id: string;
  nome: string;
  deleteCerca: any;
  feature: any;
  ativo: boolean;
  isFenceAdmin: boolean;
  isAutor: boolean;
  veiculos: any[];
  system: boolean;
  isUpload?: boolean;
  canEditVehicles?: boolean;
};

export default function CardCercaEletronica({
  id,
  nome,
  deleteCerca,
  feature,
  ativo,
  isFenceAdmin,
  isAutor,
  veiculos,
  system,
  isUpload,
  canEditVehicles,
}: TCardCerca) {
  const [isModalMapVisible, setIsModalMapVisible] = useState(false);

  const history = useHistory();

  const mapOptions = {
    // styles: [
    //   {
    //     elementType: 'labels',
    //     featureType: 'poi',
    //     stylers: [{ visibility: 'on' }],
    //   },
    // ],
    mapId: ENV.mapId,
    panControl: true,
    panControlOptions: {
      position: 4,
    },
    mapTypeControl: true,
    fullscreenControl: false,
    zoomControl: false,
    streetViewControl: true,
  };

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: ENV.googleMapsApiKey,
    libraries: googleLibraries,
    region: 'BR',
    language: 'pt-br',
  });

  const editCerca = () => {
    history.push('/cerca/form/' + id);
  };

  const getCentroid = useCallback((arr: any) => {
    return arr.reduce(
      (x: any, y: any) => {
        return [x[0] + y[0] / arr.length, x[1] + y[1] / arr.length];
      },
      [0, 0]
    );
  }, []);

  const pathsFormatted = useMemo(() => {
    const targets = feature.coordinates;

    let locations: any = [];
    targets.map((target: any) => {
      let obj = {
        lat: target[0],
        lng: target[1],
      };
      locations.push(obj);
    });
    return locations;
  }, []);

  return (
    <div key={id} className={style.collabsible}>
      <input id={id} className={style.toggle} type="checkbox" />
      <label htmlFor={id} className={style.lblToggle}>
        <div className={style.rowCollum}>
          <Row>
            <IconCerca className={style.rowTitleIcon} />
            <div className={style.rowTitle}>{nome}</div>
            <Tag
              className={style.hide}
              style={{ marginLeft: '10px', padding: '3px', fontSize: '14px' }}
              color={ativo ? 'green' : 'volcano'}
              key="tag"
            >
              {ativo ? 'ativa' : 'desativada'}
            </Tag>
          </Row>
        </div>
        <div className={style.rowCollum}>
          <Row>
            <Tooltip key="mapButton" title="Mapa">
              <button
                type="button"
                className={style.buttonG}
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  setIsModalMapVisible(true);
                }}
              >
                <MapTrifold
                  color="#18A779"
                  size={32}
                  className={style.cercasIcon}
                />
              </button>
            </Tooltip>
            {!system && (
              <div className={style.adminButtons}>
                {!isUpload && (isFenceAdmin || canEditVehicles || isAutor) && (
                  <Tooltip key="editButton" title="Editar">
                    <button
                      type="button"
                      className={style.buttonG}
                      onClick={editCerca}
                    >
                      <PencilSimpleLine
                        color="#f98d51"
                        size={32}
                        className={style.cercasIcon}
                      />
                    </button>
                  </Tooltip>
                )}
                {(isFenceAdmin || isAutor) && (
                  <Tooltip title="Excluir" color="red">
                    <Popconfirm
                      placement="top"
                      title={'Deseja realmente excluir?'}
                      onConfirm={() => deleteCerca(id, nome)}
                      okText="Ok"
                      cancelText="Cancelar"
                    >
                      <button type="button" className={style.buttonG}></button>
                      <Trash
                        color="#E93F32"
                        size={32}
                        className={style.cercasIcon}
                      />
                    </Popconfirm>
                  </Tooltip>
                )}
              </div>
            )}
          </Row>
        </div>
      </label>
      <Modal
        destroyOnClose
        onCancel={() => {
          setIsModalMapVisible(false);
        }}
        open={isModalMapVisible}
        footer={null}
      >
        <div style={{ height: 500 }}>
          <h1>Visualização da cerca</h1>
          <div style={{ width: 470, height: 450 }}>
            {isLoaded && (
              <GoogleMap
                id={ENV.mapId}
                mapContainerStyle={containerStyle}
                center={{
                  lat: getCentroid(feature.coordinates)[0],
                  lng: getCentroid(feature.coordinates)[1],
                }}
                zoom={10}
                options={mapOptions}
              >
                <Polygon paths={pathsFormatted} />
                {feature.pontosBase.map((p: any) => (
                  <Marker
                    key={p.x}
                    position={{
                      lat: p.y,
                      lng: p.x,
                    }}
                    icon={{
                      url: PontoBaseCerca,
                      scaledSize: new window.google.maps.Size(22, 25),
                    }}
                  />
                ))}
              </GoogleMap>
            )}
          </div>
        </div>
      </Modal>
      {!isUpload && (
        <div className={style.collapsibleContent}>
          <div className={style.contentInner}>
            <Table
              rowKey="placa"
              // @ts-ignore
              columns={templateColumns()}
              dataSource={veiculos}
            />
            <div className={style.divider} />
          </div>
        </div>
      )}
    </div>
  );
}

