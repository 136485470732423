import React from 'react';
import { Input } from 'antd';
import PropTypes from 'prop-types';

import { cpfMask } from '../../../utils/format';

export function InputCPF({
  className,
  value,
  onChange,
  disabled,
  placeholder,
  prefix,
}) {
  function handleChange(e) {
    const { value: cpf } = e.target;
    onChange(cpfMask(cpf));
  }
  return (
    <Input
      value={value}
      onChange={handleChange}
      disabled={disabled}
      placeholder={placeholder}
      prefix={prefix}
      className={className}
    />
  );
}

InputCPF.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  prefix: PropTypes.element,
  className: PropTypes.string,
};

InputCPF.defaultProps = {
  value: '',
  onChange: () => {},
  disabled: false,
  placeholder: '',
  prefix: null,
  className: '',
};
