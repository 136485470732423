import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;

  .inputButton {
    margin-top: 40px;
  }

  .ant-upload-list {
    display: none;
  }

  .ant-upload-list-item-info {
    max-width: 300px !important;
    .ant-upload-list-item-name {
      color: green !important;
    }

    .anticon {
      color: green !important;
    }
  }

  .buttonsDiv {
    position: absolute;

    right: 30px;
    top: 20px;

    @media (max-width: 840px) {
      position: relative;
      right: 0px;
      top: 0px;
      margin-top: 10px;
    }
  }

  .buttonsDivUpload {
    position: relative;
    margin-top: 10px;
  }

  .uploadButton {
    width: 150px;

    border-radius: 5px;
  }

  .body {
    padding: 15px;
    min-height: 400px;
    border-radius: 10px;
    box-shadow: 0 2px 2px rgb(0 0 0 / 11%);

    .title {
      margin-top: 20px;
      h1 {
        font-size: 30px !important;
      }

      @media (max-width: 1024px) {
        h1 {
          font-size: 22px !important;
        }
      }

      @media (max-width: 600px) {
        h1 {
          font-size: 18px !important;
        }
      }
    }
  }
`;

