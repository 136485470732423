import ACTION from '../actionTypes';

export function getAll(params) {
  return {
    type: ACTION.OCORRENCIA_LIST_REQUEST,
    params,
  };
}

export function getAllSuccess(data) {
  return {
    type: ACTION.OCORRENCIA_LIST_SUCCESS,
    data,
  };
}

export function getById(id) {
  return {
    type: ACTION.OCORRENCIA_GET_ID_REQUEST,
    id,
  };
}

export function getByIdSuccess(data) {
  return {
    type: ACTION.OCORRENCIA_GET_ID_SUCCESS,
    data,
  };
}

export function error(message) {
  return {
    type: ACTION.OCORRENCIA_ERROR,
    message,
  };
}
