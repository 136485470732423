import styled from 'styled-components';

const Container = styled.div`
  overflow: hidden !important;

  .confirmVehicleAssociationModal {
    background-color: red;
  }
  .cardBody {
    position: relative;
  }
  .greenButton {
    background-color: var(--corPrincipalEscura);
    color: white;
  }

  .greenButton:hover {
    background-color: var(--corPrincipalEscura);
    color: white;
  }

  .gm-style-mtc {
    left: 1px !important;
    display: flex !important;
    flex-direction: column !important;
    top: -5px !important;

    button {
      width: 100px !important;
      height: 37px !important;
    }
  }

  .ant-select-selector {
    border-radius: 10px !important;
  }

  button[title='Stop drawing'],
  button[title='Add a marker'],
  button[title='Draw a shape'] {
    font-size: 40px;
    width: 40px;
    height: 40px;
    display: flex;
    padding: 30% !important;
  }
  @media (max-width: 1150px) {
    .buttonc {
      display: flex;
      justify-content: center;
      text-align: center;
      align-items: center;
      width: 60px !important;

      span {
        display: none;
      }
    }
    .anticon-link,
    .anticon-cloud-download,
    .anticon-share-alt {
      display: inline-block !important;
    }
  }
`;

const ShareContainer = styled.div`
  position: absolute;
  display: flex;
  gap: 5px;
  top: 5%;
  right: 3%;

  .buttonc {
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    width: 150px;
    background-color: transparent;
  }

  .modelsButton {
    color: rgb(245, 106, 0);
  }

  .modelsButton:hover {
    border: 1px solid rgb(245, 106, 0);
  }

  .linkButton {
    color: blue;
  }

  .linkButton:hover {
    border: 1px solid blue;
  }

  .shareButton {
    color: green;
  }

  .shareButton:hover {
    border: 1px solid green;
  }

  @media only screen and (max-width: 800px) {
    top: 1%;
  }
`;

const ModalTitle = styled.div`
  .title {
    font-size: 18px;
  }

  .subtitle {
    color: grey;
    font-size: 12px;
  }
`;

const ModalBody = styled.div`
  display: flex;
  width: 100%;

  .alertIconContainer {
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    align-content: center;

    .alertIconBody {
      font-size: 50px;
      color: red;
    }
  }
`;

const ButtonsContainer = styled.div`
  .ant-radio-wrapper {
    padding: 0px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black !important;
    /* font-weight: 500; */
    background-color: rgba(255, 255, 255, 1) !important;
    box-shadow: rgb(0 0 0 / 30%) 0px 1px 4px -1px;
    height: 37px;
    width: 100px;
    position: absolute;
    font-size: 18px;
    top: 5px;
  }

  .ant-radio {
    margin-bottom: 5px !important;
  }

  .showFencesButton {
    left: 115px !important;
  }

  .editFencesButton {
    left: 220px !important;
  }
`;

export const ModalBodyT = styled.div`
  .selectWithoutDropdown {
    .ant-select-item-option-selected {
      display: none;
    }
  }
`;

export { Container, ShareContainer, ModalTitle, ModalBody, ButtonsContainer };

