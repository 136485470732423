import api from '../api';
import ENV from '../../config';
import { OccurrenceSocket } from 'interfaces/vehicle';
import { FilterProps } from 'interfaces/services';

export const ServiceOcorrenciaGEO = {
  // getFullList: () => {
  //   return api.get(`${ENV.ocorrengiasGEOService}/rotas/ocorrencia/all`);
  // },

  getListFiltered: ({
    agencies,
    groups,
  }: FilterProps): Promise<OccurrenceSocket[]> => {
    return api.post(`${ENV.ocorrengiasGEOService}/ocorrencia/filter`, {
      agencias: agencies,
      grupos: groups,
    });
  },

  // setFilters: ({ agencies, groups, userId }) => {
  //   return api.post(
  //     `${ENV.ocorrengiasGEOService}/rotas/ocorrencia/filter/socket`,
  //     {
  //       agencias: agencies,
  //       grupos: groups,
  //       userId,
  //     }
  //   );
  // },
};
