import React, { useCallback, useState } from 'react';
import { Breadcrumb, Input, Spin, Upload, UploadProps } from 'antd';
import { Link } from 'react-router-dom';

import Button from 'components/button';
import { Fence, TUploadedFence } from 'interfaces/fences';
import CardCercaEletronica from '../Components/card';
import { Container } from '../styles';
import { FormUploadModal } from '../Components/formUploadModal';
import { ServiceCerca } from 'services/modulos/serviceCerca';
import { toast } from 'react-toastify';

export const UploadFences = () => {
  const [loading, setLoading] = useState(false);

  const [uploadedFences, setUploadedFences] = useState<TUploadedFence[]>([]);

  const [openUploadModal, setOpenUploadModal] = useState(false);

  const handleDeleteUploadedFence = useCallback((index: string | number) => {
    setUploadedFences(prev => prev.filter((_, i) => i !== Number(index)));
  }, []);

  const handleSaveAllUploadedFences = useCallback(
    async (ais: string[], period: string[]) => {
      try {
        setLoading(true);
        await Promise.all(
          uploadedFences.map(f => {
            const newFence: Fence = {
              nome: f.nome,
              descricao: '',
              dataInicial: period[0],
              dataFinal: period[1],
              points: f.formattedCoords,
              pontosBase: [],
              alloweds: [],
              ativo: true,
              veiculosAssociados: [],
              grupos: ais,
            };

            ServiceCerca.createAdminCerca(newFence);
          })
        );

        setUploadedFences([]);
        toast.success('Cercas adicionadas');
      } catch (e) {
        console.log('error', e);
      } finally {
        setLoading(false);
      }
    },
    [uploadedFences]
  );

  const parseXml = (xmlContent: string): any[] => {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(xmlContent, 'text/xml');

    const fenceName =
      xmlDoc.getElementsByTagName('name')[0]?.textContent || '*';

    const placemarksElements: HTMLCollectionOf<Element> = xmlDoc.getElementsByTagName(
      'Placemark'
    );

    const finalCoords: any[] = [];

    for (let i = 0; i < placemarksElements.length; ++i) {
      const placemarkName =
        placemarksElements[i].getElementsByTagName('name')[0]?.textContent ||
        '*';

      const polygonElements = placemarksElements[i].getElementsByTagName(
        'Polygon'
      );

      // só entende como cerca se tiver o nome AREAOP ou AOP
      if (
        (placemarkName.includes('AREAOP') || placemarkName.includes('AOP')) &&
        polygonElements.length
      ) {
        for (let j = 0; j < polygonElements.length; j++) {
          const coordinatesHTML = polygonElements[j].getElementsByTagName(
            'coordinates'
          );

          let coords: any = [];
          let formattedCoords: any = [];
          let arr = [].slice.call(coordinatesHTML);

          arr.map((c: any) => {
            let lines: string[] = c.textContent?.split('\n');

            coords = lines
              ?.filter(l => l.trim())
              .map(l => {
                const [x, y] = l.trim().split(',');
                formattedCoords.push([parseFloat(x), parseFloat(y)]);
                return [parseFloat(y), parseFloat(x)];
              });
          });

          finalCoords.push({
            coordinates: coords,
            formattedCoords,
            nome: fenceName,
            ativo: true,
            veiculos: [],
            pontosBase: [],
          });
        }
      }
    }

    setUploadedFences(prev => [...prev, ...finalCoords]);

    return finalCoords;
  };

  const uploadProps: UploadProps = {
    name: 'file',
    headers: {
      authorization: 'authorization-text',
    },
    async onChange(info) {
      if (info.file.status !== 'uploading') {
      }
      if (info.file.status === 'done') {
      } else if (info.file.status === 'error') {
        var fileReader = new FileReader();
        fileReader.onload = function(fileLoadedEvent): string {
          const textFromFileLoaded = fileLoadedEvent?.target?.result;
          parseXml(textFromFileLoaded + '');
          return String(textFromFileLoaded);
        };

        fileReader.readAsText(info.file.originFileObj || new Blob(), 'UTF-8');
      } else if (info.file.status === 'removed') {
        // handleRemoveUploadedFile(info.file.name || '');
      }
    },
  };

  return (
    <>
      <Container>
        <Breadcrumb style={{ padding: '10px' }}>
          <Breadcrumb.Item>
            {/* @ts-expect-error Server Component */}
            <Link to="/">Página Inicial</Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item>
            {/* @ts-expect-error Server Component */}
            <Link to="/cerca">Cercas</Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item>Upload</Breadcrumb.Item>
        </Breadcrumb>
        <Spin spinning={loading}>
          <div className="body" style={{ textAlign: 'center' }}>
            <div className="title">
              <h1>Upload de Cercas</h1>
            </div>
            <div className="buttonsDivUpload">
              <Button
                disabled={!uploadedFences.length}
                type="button"
                buttonStyle="btn--primary"
                onClick={() => setOpenUploadModal(true)}
              >
                Salvar
              </Button>
              <Upload {...uploadProps}>
                <Button type="button" buttonStyle="btn--link">
                  Upload
                </Button>
              </Upload>
            </div>
            {/* <Search className="inputButton" /> */}
            <div className="cardList">
              {uploadedFences.map((f, i) => {
                return (
                  <CardCercaEletronica
                    id={i + ''}
                    key={i}
                    nome={`${f.nome}`}
                    feature={f}
                    veiculos={f.veiculos}
                    deleteCerca={handleDeleteUploadedFence}
                    ativo={f.ativo}
                    isFenceAdmin={false}
                    isAutor={true}
                    system={false}
                    isUpload={true}
                  />
                );
              })}
            </div>
          </div>
        </Spin>
      </Container>
      <FormUploadModal
        key={1}
        open={openUploadModal}
        setModalVisible={setOpenUploadModal}
        handleConfirmModal={handleSaveAllUploadedFences}
      />
    </>
  );
};

