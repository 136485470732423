import styled from 'styled-components';

export const Container = styled.div`
  z-index: 999;
  position: absolute;

  bottom: 1px;
  left: 5px;
  background-color: rgba(0, 0, 0, 0.9);
  width: 210px;
  min-height: 30px;
  /* height: 250px; */
  margin: 0 auto;
  /* border-top: 1px solid #121924; */
  padding: 2px 10px 0px 10px;

  .hideBar {
    display: none;
  }

  .rodape {
    margin-top: 10px;
    color: orange;
    font-size: 12px;
    line-height: 0.2rem;
  }

  ul {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: 100%;
  }

  li {
    font-size: 14px;
    width: 200px;
    display: flex;
    flex-direction: row;
    color: white;
    font-weight: 600;
    margin-top: 3px;
    align-items: center;

    -webkit-text-stroke-width: 0.5px; /* largura da borda */
    -webkit-text-stroke-color: #000;
  }

  @media only screen and (max-width: 992px) {
    display: none;
  }
`;

export default Container;

