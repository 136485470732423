import React from 'react';

import { Select } from 'antd';

import PropTypes from 'prop-types';

import { SelectCustom } from './styles';

const { Option } = Select;

export function SelectAntd(props) {
  const { data } = props;

  return (
    <SelectCustom
      showSearch
      allowClear
      getPopupContainer={trigger => trigger.parentElement}
      filterOption={(input, option) => {
        const v = option.children;

        return v.toLowerCase().indexOf(input.trim().toLowerCase()) >= 0;
      }}
      {...props}
    >
      {(data || []).map(row => (
        <Option key={row.key} value={row.key}>
          {row.value}
        </Option>
      ))}
    </SelectCustom>
  );
}

SelectAntd.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.number,

      value: PropTypes.string,
    })
  ).isRequired,
};
