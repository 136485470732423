import { MonitoringContext } from 'context/monitoring';
import { useContextSelector } from 'use-context-selector';

export function useHistoryFormInfo() {
  const setDataOccurrences = useContextSelector(
    MonitoringContext,
    context => context.setDataOccurrences
  );

  const fencesSelected = useContextSelector(
    MonitoringContext,
    context => context.fencesSelected
  );

  return {
    setDataOccurrences,
    fencesSelected,
  };
}

