import { TrackingHistoryContext } from 'context/tracking_history';
import { useContextSelector } from 'use-context-selector';

export function useHistoryDataSource() {
  const dataSource = useContextSelector(
    TrackingHistoryContext,
    context => context.dataSource
  );

  const setDataSource = useContextSelector(
    TrackingHistoryContext,
    context => context.setDataSource
  );

  return {
    dataSource,
    setDataSource,
  };
}

