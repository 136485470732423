import api from '../api';
import ENV from '../../config';

interface Notification {
  createdAt: string,
  description: string,
  emailSend: boolean
  id: string
  message: string,
  name: string,
  origin: string,
  project: string,
  send: boolean,
  severity: string,
  source: string,
  updatedAt: string
}

export const ServiceNotification = {
  getNewest: (): Promise<Notification[]> => {
    return api.post(`${ENV.notificationService}/new`, {
      project: 'rotas',
    });
  },
};
