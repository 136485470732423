import { TrackingHistoryContext } from 'context/tracking_history';
import { useContextSelector } from 'use-context-selector';

export function useHistorySeachByRadiusAll() {
  const searchByRadiusAll = useContextSelector(
    TrackingHistoryContext,
    context => context.searchByRadiusAll
  );

  return {
    searchByRadiusAll,
  };
}

