import io from 'socket.io-client';
import ENV from '../config';

const URL = ENV.serverSocketTO || '';

export const socketTO = io(URL, {
  autoConnect: false,
  // extraHeaders: {
  //   Authorization: `${sessionStorage.getItem('rota@tkn')}`,
  // },
  transports: ['websocket'],
  upgrade: false,
  query: {
    token: `${sessionStorage.getItem('rota@tkn')}`,
  },
  path: '/rotas/ocorrencias/socket.io',
  // reconnectionDelay: 2 * 1000,
  timeout: 5000,
});

