/* eslint-disable */
import { createGlobalStyle } from 'styled-components';

import 'react-toastify/dist/ReactToastify.css';

export default createGlobalStyle`

  /* @import url(${() => require('antd/dist/antd.css')} ); */
  * {
	margin: 0;
	padding: 0;
	outline: 0;
	box-sizing: border-box;
}

html,
body {
	line-height: 1.15 !important;
}

#root {
	margin: 0 auto;
	overflow: auto !important;
}

button {
	cursor: pointer;
}

html {
	height: 100%;
	#app {
		height: 100%;
	}

	a {
		color: #4e4a46;
		text-decoration: none;

		&:hover,
		&:focus {
		color: #c94e50;
	}

}

.ant-spin-dot-item {

	background-color: green !important;

}

.ant-spin-text {

    color: green;

}

.ant-spin-sm .ant-spin-dot i {

	background-color: green;
}

.ant-radio-checked, .ant-radio-inner, .ant-checkbox-checked, .ant-checkbox-inner {
    border-color: green !important;
	
}

.ant-checkbox-checked .ant-checkbox-inner {
	background-color: green !important;
}

.ant-slider-handle {
	border: 2px solid green !important;
}

.ant-slider-track {
	background-color: green !important;
}

.ant-slider-rail {
	background-color: #cecece !important
}






























































































































































































































































.ant-breadcrumb li:last-child {































































































































    color: green !important;































































































































}































































































































































































































































.ant-breadcrumb {































































































































    font-size: 16px !important;































































































































}































































































































































































































































.ant-switch-checked {



    background: var(--corPrincipal);

}































































































































































































































































.ant-pagination-item-active {































































































































    border-color: green !important;































































































































}































































































































































































































































.ant-pagination-item-active a {































































































































    color: green !important;































































































































}































































































































































































































































.ant-table-tbody > tr > td a {

    color: green;































































































































}































































































































































































































































.ant-table-thead > tr > th {































































































































	background-color: rgba(26, 120, 74, 0.2) !important;































































































































}































































































































































































































































.ant-input:hover {































































































































    border-color: #2fac6d !important;































































































































}































































































































































































































































.ant-table-column-sorter-up.active, .ant-table-column-sorter-down.active {







































    color: #08a555 !important;































































































































}































































































































































































































































main {































































































































	height: 100%;































































































































	overflow: auto;































































































































}































































































































































































































































.description {































































































































	max-width: 20em;































































































































	margin: 1.2em auto 1em;































































































































	line-height: 1.3em;































































































































	font-weight: 400;































































































































}































































































































































































































































































































































































/* .ant-menu-item {































































































































  color: black !important;































































































































} */































































































































































































































































.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {































































































































	background-color: #126538 !important;































































































































	opacity: 0.6 !important;































































































































}































































































































































































































































.ant-menu-dark .ant-menu-inline.ant-menu-sub {































































































































	/* background: #204d37 !important; */































































































































}































































































































































































































































.ant-menu-submenu-title {































































































































	color: rgba(255, 255, 255, 0.65);































































































































}































































































































































































































































#menuAntd::-webkit-scrollbar {































































































































	width: 10px;































































































































}































































































































































































































































#menuAntd::-webkit-scrollbar-track {































































































































	background: #F5F5F5;































































































































	border-radius: 20px;































































































































}































































































































































































































































#menuAntd::-webkit-scrollbar-thumb {































































































































	background-color: #008442;































































































































	border-radius: 20px;































































































































	border: 2px solid white;































































































































}































































































































































































































































#menuAntd {































































































































	position: relative !important;































































































































	animation: animatetop 0.4s !important;































































































































	height: 74vh !important;































































































































	background: #008442 !important;































































































































	color: black !important;































































































































}































































































































































































































































@keyframes animatetop {































































































































	from {































































































































		top: -300px;































































































































		opacity: 0































































































































	}































































































































	to {































































































































		top: 0;































































































































		opacity: 1































































































































	}































































































































}































































































































































































































































.demo-buttons {































































































































	font-size: 1em;































































































































	max-width: 1200px;































































































































	margin: 2em auto 3em;































































































































	padding: 0 10em;































































































































	a {































































































































		display: inline-block;































































































































		margin: 0.75em;































































































































		padding: 1.35em 1.1em;































































































































		width: 15em;































































































































		background: #fffce1;































































































































		text-transform: uppercase;































































































































		letter-spacing: 1px;































































































































		font-weight: 800;































































































































		border-top-left-radius: 20px 50px;































































































































		border-top-right-radius: 20px 50px;































































































































		border-bottom-right-radius: 20px 50px;































































































































		border-bottom-left-radius: 20px 50px;































































































































		cursor: pointer;































































































































		&.current-demo {































































































































			background: #c94e50;































































































































			color: #fffce1;































































































































		}































































































































	}































































































































}































































































































































































































































.ant-btn-primary {























    background-color: var(--corPrincipal) !important;















	border-color: var(--corPrincipal) !important;















    &:hover {







      opacity: 0.8;







    }















}















ant-switch-checked {







    background: var(--corPrincipal) !important;







}































































































































}































































































































































































































































.side-button {































































































































	display: inline-block;































































































































	width: 5em;































































































































	height: 2.5em;































































































































	line-height: 2.5em;































































































































	cursor: pointer;































































































































	background: #fffce1;































































































































	text-transform: uppercase;































































































































	letter-spacing: 1px;































































































































	font-size: 0.8em;































































































































	font-weight: 800;































































































































	&.left {































































































































		border-top-left-radius: 10px;































































































































		border-bottom-left-radius: 10px;































































































































	}































































































































	&.right {































































































































		border-top-right-radius: 10px;































































































































		border-bottom-right-radius: 10px;































































































































	}































































































































	&.active {































































































































		background: #c94e50;































































































































		color: #fffce1;































































































































	}































































































































}































































































































































































































































@media screen and (max-width: 40em) {































































































































	main {































































































































		font-size: 80%;































































































































	}































































































































	h1 {































































































































		padding-top: 1em;































































































































		font-size: 2.5em;































































































































	}































































































































	.demo-buttons {































































































































		max-width: 900px;































































































































		padding: 0 2em;































































































































	}































































































































}































































































































































































































































































































































































/* Color/shape of close button cross */































































































































































































































































.bm-cross {































































































































	background: white;































































































































}































































































































































































































































.bm-burger-button {































































































































	position: relative;































































































































	width: 25px;































































































































	height: 25px;































































































































	left: 10px !important;































































































































}































































































































































































































































.right .bm-burger-button {































































































































	left: initial;































































































































	right: 36px;































































































































}































































































































































































































































.bm-burger-bars {































































































































	background: #001529;































































































































	height: 14% !important;































































































































}































































































































































































































































.bm-morph-shape {































































































































	fill: #008442;































































































































}































































































































































































































































.bm-morph-shape {































































































































	position: absolute !important;































































































































	left: 11em !important;































































































































}































































































































































































































































#elastic {































































































































	position: fixed !important;































































































































	left: 0 !important;































































































































	z-index: 1001 !important;































































































































	top: 0px !important;































































































































}































































































































































































































































.bm-overlay {































































































































	position: fixed !important;































































































































	top: 0px !important;































































































































	left: 0px !important;































































































































}































































































































































































































































.ant-layout-header {































































































































	height: 50px !important;































































































































}































































































































































































































































.bm-menu {































































































































	background: #008442;































































































































	a {































































































































		/* color: #b8b7ad; */































































































































		&:hover,































































































































		&:focus {































































































































			color: #c94e50;































































































































		}































































































































	}































































































































}































































































































































































































































.bm-menu-wrap {































































































































	position: fixed !important;































































































































	left: 0 !important;































































































































	top: 0 !important;































































































































	width: 230px !important;































































































































}































































































































































































































































.ant-menu-dark .ant-menu-inline.ant-menu-sub {































































































































	// background: #204d37 !important;































































































































}































































































































































































































































.bm-item-list a {































































































































	padding: 0.8em;































































































































	span {































































































































		margin-left: 10px;































































































































		font-weight: 700;































































































































	}































































































































}































































































































































































































































.bm-item:focus {































































































































	outline: none;































































































































}































































































































































































































































#page-wrap {































































































































	padding: 0px !important;































































































































}































































































































































































































































.modal-recupera-login {































































































































	.ant-modal-content {































































































































		border-radius: 10px !important;































































































































		padding: 0px !important;































































































































	}































































































































	.ant-modal {































































































































		padding-bottom: 0px !important;































































































































		width: 520px !important;































































































































	}































































































































	.ant-modal-header {































































































































		background-color: transparent !important;































































































































		border-bottom: 0px solid transparent !important;































































































































	}































































































































	.ant-modal-body {































































































































		padding: 13px 53px !important;































































































































	}































































































































	.ant-modal-footer {































































































































		padding: 10px 50px !important;































































































































		border-top: 0px solid transparent !important;































































































































		text-align: center !important;































































































































	}































































































































	.ant-btn {































































		position: relative !important;































































		width: 200px !important;































































		margin-bottom: 20px !important;































































	}































































































































	.ant-btn-primary {































































































































		background-color: #008442 !important;































































































































		border-color: #008442 !important;































































































































	}































































































































	#nest-messages_email {































































































































		height: 40px !important;































































































































		border-radius: 50px !important;































































































































		width: 100% !important;































































































































	}































































































































	.ant-form-item-label {































































































































		padding-top: 5px !important;































































































































	}































































































































































































































































}































































































































































































































































.ant-popover-inner {































































































































	border-radius: 10px !important;































































































































}































































































































































































































































@-webkit-keyframes waves {































































































































  0% {































































































































    transform: scale(1);































































































































    opacity: 1;































































































































  }































































































































  100% {































































































































    transform: scale(2);































































































































    opacity: .5;































































































































  }































































































































}































































































































@-webkit-keyframes bloop {































































































































  0% {































































































































    transform: scale3d(1, 1, 1);































































































































  }































































































































  30% {































































































































    transform: scale3d(1.25, 0.75, 1);































































































































  }































































































































  40% {































































































































    transform: scale3d(0.75, 1.25, 1);































































































































  }































































































































  50% {































































































































    transform: scale3d(1.15, 0.85, 1);































































































































  }































































































































  65% {































































































































    transform: scale3d(0.95, 1.05, 1);































































































































  }































































































































  75% {































































































































    transform: scale3d(1.05, 0.95, 1);































































































































  }































































































































  100% {































































































































    transform: scale3d(1, 1, 1);































































































































  }































































































































}































































































































@keyframes bloop {































































































































  0% {































































































































    transform: scale3d(1, 1, 1);































































































































  }































































































































  /* 30% {































































































































    transform: scale3d(1.25, 0.75, 1);































































































































  } */































































































































  40% {































































































































    transform: scale3d(0.75, 1.25, 1);































































































































  }































































































































  /* 50% {































































































































    transform: scale3d(1.15, 0.85, 1);































































































































  } */































































































































  65% {































































































































    transform: scale3d(0.95, 1.05, 1);































































































































  }































































































































  75% {































































































































    transform: scale3d(1.05, 0.95, 1);































































































































  }































































































































  100% {































































































































    transform: scale3d(1, 1, 1);































































































































  }

}

`;

