import { Input } from 'antd';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import debounce from 'lodash.debounce';
import { useLocation } from 'react-router-dom';

import { Fence } from 'interfaces/fences';
import { useFenceFilterInfo } from 'hooks/monitoring/useFenceFilterInfo';
import { useGlobalFences } from 'hooks/global/useGlobalFences';
import { useFiltersMapGroups } from 'hooks/filtersMap/useFiltersMapGroups';
import { useHistoryRenderFunctions } from 'hooks/history/useHistoryRenderFunctions';
import { useHistoryLoadingFences } from 'hooks/history/useHistoryLoadingFences';

interface switchPanelProps {
  isSearch: boolean;
  data: (Fence & { checked: boolean })[];
  dataFiltered: (Fence & { checked: boolean })[];
}

export default function FenceFilter() {
  const {
    fencesSelected,
    setLoadingFences,
    vehiclesOfSelectedFences,
    handleLoadData,
  } = useFenceFilterInfo();
  const { fences } = useGlobalFences();

  const {
    setLoadingFences: setHistoryLoadingFences,
  } = useHistoryLoadingFences();

  const { groupsFilters } = useFiltersMapGroups();
  const { renderFencesOnHistoryMap } = useHistoryRenderFunctions();

  const location = useLocation();

  const isMonitoramento = location.pathname === '/mapa/monitoramento';

  const [switchPanel, setSwitchPanel] = useState<switchPanelProps>({
    isSearch: false,
    data: [],
    dataFiltered: [],
  });

  const handleChangeVehiclesOfFences = (currentSelected: string[]) => {
    const auxSelected: string[] = [];

    fences.current.data.map(f => {
      if (f.id && currentSelected.includes(f.id)) {
        f.veiculosAssociados.map(v => auxSelected.push(v.placa));
      }
    });

    vehiclesOfSelectedFences.current = auxSelected;
  };

  const updateSwitchPanel = useCallback((newProperties: any) => {
    setSwitchPanel(prev => ({ ...prev, ...newProperties }));
  }, []);

  const loadFences = useCallback(() => {
    const fencesSwitch = fences.current.data
      .filter(f => f.veiculosAssociados?.length && f.ativo)
      .sort((f1, f2) => (f1.nome > f2.nome ? 1 : -1))
      .map(f => {
        return {
          ...f,
          checked: Boolean(fencesSelected.current.includes(f.id || '')),
        };
      });

    const searchText:
      | (HTMLElement & { value?: string })
      | null = document.getElementById('searchInput');

    let fencesSwitchFiltered = fencesSwitch;

    if (searchText?.value) {
      fencesSwitchFiltered = fencesSwitch.filter(fence => {
        fence.nome = fence.nome.toLowerCase();
        if (fence.nome.includes(searchText.value || '')) {
          return true;
        }
      });
    }
    updateSwitchPanel({
      data: fencesSwitch,
      dataFiltered: fencesSwitchFiltered,
    });
  }, [fencesSelected.current]);

  useEffect(() => {
    loadFences();
  }, [loadFences, JSON.stringify(fencesSelected.current)]);

  const debouncedSave = useCallback(
    debounce(() => {
      handleChangeVehiclesOfFences(fencesSelected.current);
      if (!isMonitoramento) {
        renderFencesOnHistoryMap();
        setHistoryLoadingFences(false);
      } else {
        handleLoadData();
        setLoadingFences(false);
      }
    }, 2000),
    [handleLoadData]
  );

  const selectAll = useCallback(
    (value: boolean) => {
      let auxSelectAllFences: string[] = [];
      const auxSwitchPanel = switchPanel.isSearch
        ? switchPanel.dataFiltered
        : switchPanel.data;

      const fencesSwitchSelected = auxSwitchPanel.map(f => {
        if (f.checked !== value && f.id) {
          auxSelectAllFences.push(f.id);
          return { ...f, checked: value };
        }
        return f;
      });
      switchPanel.isSearch
        ? updateSwitchPanel({ dataFiltered: fencesSwitchSelected })
        : updateSwitchPanel({ data: fencesSwitchSelected });

      if (value) {
        const arrayConcat = auxSelectAllFences.concat(fencesSelected.current); // contatena os valores ja selecionados com os restantes
        const arrayWithoutDuplicate = [...new Set(arrayConcat)]; // remove os duplicados
        fencesSelected.current = arrayWithoutDuplicate;
      } else {
        fencesSelected.current = [];
      }

      if (isMonitoramento) {
        setLoadingFences(true);
      } else {
        setHistoryLoadingFences(true);
      }

      debouncedSave();
    },
    [switchPanel, debouncedSave]
  );

  const handleChangeFencesSelected = useCallback(
    ({ id, check }: { id: string; check: boolean }) => {
      try {
        if (fencesSelected.current.includes(id)) {
          fencesSelected.current = fencesSelected.current.filter(f => f !== id);
        } else {
          fencesSelected.current = [...fencesSelected.current, id];
        }
        const auxSwitchPanel = switchPanel.isSearch
          ? switchPanel.dataFiltered
          : switchPanel.data;
        const fencesSwitchSelected = auxSwitchPanel.map(f => {
          if (f.id === id && f.checked !== check) {
            return { ...f, checked: check };
          }
          return f;
        });
        switchPanel.isSearch
          ? updateSwitchPanel({ dataFiltered: fencesSwitchSelected })
          : updateSwitchPanel({ data: fencesSwitchSelected });
        if (isMonitoramento) {
          setLoadingFences(true);
        } else {
          setHistoryLoadingFences(true);
        }

        debouncedSave();
      } catch (err) {
        console.error(err);
      }
    },
    [switchPanel, debouncedSave]
  );

  const concatenarEremoverDuplicatasPorPropriedade = (
    array1: Fence[],
    array2: Fence[],
    key: string
  ) => {
    const arrayConcat = array2.concat(array1);

    const mapObject: any = new Map();

    for (const obj of arrayConcat) {
      // @ts-ignore
      if (!mapObject.has(obj[key])) {
        // @ts-ignore
        mapObject.set(obj[key], obj);
      }
    }

    return Array.from(mapObject.values());
  };

  const onFilter = useCallback(
    (value: string) => {
      if (value) {
        value = value.toLowerCase();
        updateSwitchPanel({ isSearch: true });
        const auxFiltered = switchPanel.data.filter(fence => {
          fence.nome = fence.nome.toLowerCase();
          if (fence.nome.includes(value)) {
            return true;
          }
        });
        updateSwitchPanel({ dataFiltered: auxFiltered });
      } else {
        updateSwitchPanel({ isSearch: false });
        const resultado = concatenarEremoverDuplicatasPorPropriedade(
          switchPanel.data,
          switchPanel.dataFiltered,
          'id'
        );
        updateSwitchPanel({ data: resultado });
      }
    },
    [fences.current.data, switchPanel]
  );

  return (
    <div
      className="grid grid-template-columns-1"
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        width: '400px',
      }}
    >
      {(!isMonitoramento || groupsFilters.groups.some(g => g.checked)) && (
        <>
          <Input
            id={`searchInput`}
            style={{ width: 200, borderRadius: '10px' }}
            placeholder="Pesquisar por nome"
            onChange={e => onFilter(e.target.value)}
          />

          {switchPanel?.isSearch ? (
            switchPanel?.dataFiltered?.map(f => (
              <div key={f.id} style={{ width: '400px' }}>
                <label htmlFor={f.id} className="switchToggle">
                  <input
                    type="checkbox"
                    id={f.id}
                    className="display-switch"
                    // defaultChecked={fencesSelected.includes(f.id)}
                    checked={f.checked}
                    onChange={value =>
                      handleChangeFencesSelected({
                        id: f.id || '',
                        check: value.target.checked,
                      })
                    }
                  />
                  <span className="slider" />
                </label>
                &nbsp;
                <span>{f.nome?.toLowerCase()}</span>
              </div>
            ))
          ) : (
            <>
              <div style={{ width: '400px' }}>
                <label className="switchToggle">
                  <input
                    type="checkbox"
                    className="display-switch"
                    // defaultChecked={false}
                    value=""
                    checked={
                      switchPanel.isSearch
                        ? !Boolean(
                            switchPanel.dataFiltered.some(f => !f.checked)
                          )
                        : !Boolean(switchPanel.data.some(f => !f.checked))
                    }
                    onChange={value => selectAll(value.target.checked)}
                  />

                  <span className="slider" />
                </label>
                &nbsp;
                <span>Marcar todos</span>
              </div>
              {switchPanel?.data?.map(f => (
                <div key={f.id} style={{ width: '400px' }}>
                  <label htmlFor={f.id} className="switchToggle">
                    <input
                      type="checkbox"
                      id={f.id}
                      className="display-switch"
                      // defaultChecked={fencesSelected.includes(f.id)}
                      checked={f.checked}
                      onChange={value =>
                        handleChangeFencesSelected({
                          id: f.id || '',
                          check: value.target.checked,
                        })
                      }
                    />
                    <span className="slider" />
                  </label>
                  &nbsp;
                  <span>{f.nome?.toLowerCase()}</span>
                </div>
              ))}
            </>
          )}
        </>
      )}
    </div>
  );
}
