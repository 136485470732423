import api from '../api';
import { serviceReutils } from '../../utils/reutils';

export type ReturnProps = {
  id: string;
  nome: string;
};

export const ServiceAIS = {
  ...serviceReutils({ name: 'rotas/v1/ais', api }),

  getByVinculadas: (agencias: string[]): Promise<ReturnProps[]> =>
    api.post('/rotas/v1/ais/ais_agencia', { agencias }),
};
