import ACTION from '../actionTypes';

const initialState = {
  data: [],
  desconectados: [],
  loading: false,
  error: null,
};

export default function grupoAtivos(state = initialState, action) {
  switch (action.type) {
    case ACTION.MONITORING_GRUPOS_ATIVO_SUCCESS: {
      const data = action.data
        .map(v => ({ ...v.properties }))
        .filter(f => f.conectado);

      const desconectados = action.data
        .map(v => ({ ...v.properties }))
        .filter(f => !f.conectado);

      return {
        ...state,
        data,
        desconectados,
        loading: false,
      };
    }

    case ACTION.MONITORING_ERROR_ATIVO:
      return {
        ...state,
        loading: false,
        error: action.message,
      };
    default:
      return state;
  }
}
