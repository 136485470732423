import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import {
  Content,
  DynamicContent,
  TDocumentDefinitions,
} from 'pdfmake/interfaces';
import logo64 from '../../imgs/encoded/brasaosspds.base64';

export const pdfReport = (data: any) => {
  const win = window.open('', '_blank');
  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  const reportTitle: any = [
    {
      image: logo64,
      width: 179,
      height: 63,
      alignment: 'center',
      margin: [0, 10, 0, 0],
    },
    {
      text: 'Relatório personalizado.\n',
      bold: true,
      fontSize: 13,
      alignment: 'center',
      margin: [0, 4, 0, 0],
    },
    {
      text: `Segue abaixo a lista com informações das últimas posições das viaturas (${data.length}).\n`,
      bold: false,
      fontSize: 9,
      alignment: 'center',
      margin: [0, 4, 0, 0],
    },
    {
      text: 'Dados de posicionamento fornecidos pela empresa TELTRONIC\n',
      bold: false,
      fontSize: 9,
      alignment: 'center',
      margin: [0, 4, 0, 0],
    },
  ];

  const results: any = data.map((c: any) => {
    return [
      { text: c[0], fontSize: 9, margin: [0, 1, 0, 1] },
      { text: c[1], fontSize: 9, margin: [0, 1, 0, 1] },
      { text: c[2], fontSize: 9, margin: [0, 1, 0, 1] },
      { text: c[3], fontSize: 9, margin: [0, 1, 0, 1] },
      // { text: c[4], fontSize: 9, margin: [0, 1, 0, 1] },
      // { text: c[5], fontSize: 9, margin: [0, 1, 0, 1] },
      { text: c[6], fontSize: 9, margin: [0, 1, 0, 1] },
      { text: c[7], fontSize: 9, margin: [0, 1, 0, 1] },
    ];
  });

  const details: any = [
    {
      alignment: 'center',
      margin: [0, 0, 0, 0],
      layout: {
        fillColor(rowIndex: number) {
          return rowIndex % 2 === 0 ? '#CCCCCC' : null;
        },
      },

      table: {
        headerRows: 1,
        widths: [
          // 'auto',
          // 'auto',
          'auto',
          100,
          'auto',
          'auto',
          'auto',
          'auto',
        ],
        body: [
          [
            { text: 'Placa', style: 'tableHeader', fontSize: 9 },
            { text: 'Prefixo', style: 'tableHeader', fontSize: 9 },
            { text: 'Grupo', style: 'tableHeader', fontSize: 9 },
            { text: 'Última posição', style: 'tableHeader', fontSize: 9 },
            // { text: 'Latitude', style: 'tableHeader', fontSize: 9 },
            // { text: 'Longitude', style: 'tableHeader', fontSize: 9 },
            { text: 'Status', style: 'tableHeader', fontSize: 9 },
            { text: 'Logradouro', style: 'tableHeader', fontSize: 9 },
          ],
          ...results,
        ],
      },
    },
  ];

  const footer: DynamicContent | Content = [
    {
      text: [
        {
          text:
            'Secretaria de Segurança Pública e Defesa Social. Avenida Bezerra de Menezes, 581 - São Gerardo\n CEP 60325-003 . Fortaleza Ceará . Fone: (85) 3101-6501',
        },
      ],
      fontSize: 8,
      margin: [0, 10, 0, 45],
      alignment: 'center',
    },
  ];

  const docDefinitions: TDocumentDefinitions = {
    // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
    pageMargins: [10, 140, 10, 40],
    pageSize: 'A4',
    // @ts-ignore
    alignment: 'center',
    header: [reportTitle],
    content: [details],
    footer: [footer],
  };

  pdfMake.createPdf(docDefinitions).open({}, win);
};

