/* eslint-disable */
import React, { useState, useMemo } from 'react';
import { RightOutlined } from '@ant-design/icons';
import style from './styles.module.css';

import TipoAlerta from './componet/tipoAlerta';
import InfoAlerta from './componet/infoAlerta';
import { Alert } from 'interfaces/alerts';
import { useSelectEfetivo } from 'hooks/monitoring/useSelectEfetivo';

export interface AlertCardProps {
  alert: Alert;
  onClick: (value: string) => void;
  isOpen: boolean;
}

export default function Alerta({ alert, onClick, isOpen }: AlertCardProps) {
  const [rotateChevron, setRotateCheveron] = useState(isOpen);
  const handleRotate = () => {
    setRotateCheveron(!rotateChevron);
  };
  const rotate = rotateChevron ? 'rotate(90deg)' : 'rotate(0)';

  const { handleSelectEfetivo } = useSelectEfetivo();

  const title = useMemo(() => {
    if (alert.tipoAlerta === 'LIMITE_VELOCIDADE') {
      return 'Limite Velocidade';
    }
    if (alert.tipoAlerta === 'SAIU_DA_CERCA') {
      if (alert.foraAIS) {
        return 'Fora da AIS';
      }
      return 'Fora da Cerca';
    }
    if (alert.tipoAlerta === 'PARADA_SUPERIOR_15_MIN') {
      return 'Parada Superior 15 min';
    }
    return '';
  }, [alert]);

  const handleClickAlert = (efetivo: string) => {
    handleSelectEfetivo(efetivo);
  };

  return (
    <div
      className={style.container}
      onClick={() => {
        handleClickAlert(alert.idDevice);
        onClick(alert.idDevice);
        handleRotate();
      }}
    >
      <input id={alert.id} className={style.toggle} type="checkbox" />
      <label
        // onClick={handleRotate}
        // htmlFor={alert.id}
        className={style.lblToggle}
      >
        {/*  <div className={style.divider} /> */}
        <TipoAlerta
          isAIS={alert.foraAIS}
          styleType={alert.tipoAlerta}
          prefixo={alert.prefixo}
        >
          {title}
        </TipoAlerta>

        <div className={style.botao}>
          <RightOutlined
            style={{ transform: rotate, transition: 'all 0.2s linear' }}
            // onClick={handleRotate}
          />
        </div>
      </label>

      <div
        className={
          rotateChevron ? style.notCollapsedContent : style.collapsibleContent
        }
      >
        <div className={style.contentInner}>
          <InfoAlerta
            placa={alert.placa}
            data={alert.datePosition}
            speed={alert.speed}
            nomeCerca={alert.nomeCerca}
          />
          <br />
        </div>
      </div>
    </div>
  );
}

