// opcoes: localhost, dev, prod, stage

function getVariablesByEnv() {
  switch (process.env.REACT_APP_AMB) {
    case 'local':
      return {
        applicationName: 'ROTAS',
        serverSIAA: 'https://spi-stage.sspds.ce.gov.br/dev/api/siaa',
        // serverSocket: 'https://spca-dev.sspds.ce.gov.br/socket.io/api/ws',
        serverSocketTVP: 'https://spca-dev.sspds.ce.gov.br', // 'https://spca-dev.sspds.ce.gov.br/socket.io/api/ws',
        serverSocketTO: 'https://spca-dev.sspds.ce.gov.br', // 'https://spca-dev.sspds.ce.gov.br/socket.io/api/ws',
        ocorrengiasGEOService: 'https://spca-dev.sspds.ce.gov.br/api',
        serverSSPDS: 'http://172.25.10.230:8090',
        server: 'http://172.25.10.230:8090',
        appkey: '8a6a4c3fccd0718b68c8f89a4bacb12c',
        notificationService:
          'https://spca-dev.sspds.ce.gov.br/notification/notification',
        googleMapsApiKey: 'AIzaSyDVrj9xt0YqO-_5deYI1H4B0x5Q-RSS5ak',
        ambiente: 'localhost',
        prefixRoute: '/web/rotas',
        version: '1.0.0',
        mapId: '8d409071b9d9e1cc',
      };

    case 'dev':
      return {
        applicationName: 'ROTAS',
        serverSIAA: 'https://spi-stage.sspds.ce.gov.br/dev/api/siaa',
        serverSocketTVP: 'https://spca-dev.sspds.ce.gov.br', // 'http://172.25.100.243:30054',
        serverSocketTO: 'https://spca-dev.sspds.ce.gov.br', // 'http://172.25.100.243:30064',
        serverSocketAlert: 'https://spca-dev.sspds.ce.gov.br', // 'http://172.25.100.243:30013',
        ocorrengiasGEOService: 'https://spca-dev.sspds.ce.gov.br/api', //http://172.25.100.243:30063',
        serverSSPDS: 'https://spca-dev.sspds.ce.gov.br/api', // 'http://172.25.100.243:30080',
        server: 'https://spca-dev.sspds.ce.gov.br/api', // 'http://172.25.100.243:30081',
        appkey: '8a6a4c3fccd0718b68c8f89a4bacb12c',
        notificationService:
          'https://spca-dev.sspds.ce.gov.br/notification/notification',
        googleMapsApiKey: 'AIzaSyDVrj9xt0YqO-_5deYI1H4B0x5Q-RSS5ak',
        ambiente: 'dev',
        version: '1.0.0',
        prefixRoute: '/web/rotas',
        mapId: '8d409071b9d9e1cc',
      };

    case 'stage':
      return {
        applicationName: 'ROTAS',
        serverSIAA: 'https://spi.sspds.ce.gov.br/api/siaa', // PROD
        serverSocketTVP: 'https://spca-stage.sspds.ce.gov.br',
        serverSocketTO: 'https://spca-stage.sspds.ce.gov.br',
        serverSocketAlert: 'https://spca-stage.sspds.ce.gov.br',
        ocorrengiasGEOService: 'https://spca-stage.sspds.ce.gov.br/stage/api',
        serverSSPDS: 'https://spca-stage.sspds.ce.gov.br/stage/api',
        appkey: '208d7ae7ad66d46eeefb1da3335544a3',
        notificationService:
          'https://spca-stage.sspds.ce.gov.br/notification/notification',
        googleMapsApiKey: 'AIzaSyDVrj9xt0YqO-_5deYI1H4B0x5Q-RSS5ak',
        ambiente: 'stage',
        version: '1.0.0',
        prefixRoute: '/web/rotas',
        mapId: '8d409071b9d9e1cc',
      };

    case 'prod':
      return {
        applicationName: 'ROTAS',
        serverSIAA: 'https://spi.sspds.ce.gov.br/api/siaa', // PROD
        // serverSocket: 'http://172.25.10.49:8090/socket.io/api/ws',
        serverSocketTVP: 'https://spca.sspds.ce.gov.br/socket.io/api/ws', // PROD
        serverSocketTO: 'https://spca.sspds.ce.gov.br/rotas/socket/ocorrencias', // PROD
        ocorrengiasGEOService:
          'https://spca.sspds.ce.gov.br/rotas/socket/ocorrencias',
        // serverSocket: 'http://172.25.100.57:30034',
        server: 'https://spca.sspds.ce.gov.br/prod/api', // PRODß
        serverSSPDS: 'https://spca.sspds.ce.gov.br/prod/api', // PROD
        appkey: '208d7ae7ad66d46eeefb1da3335544a3',
        notificationService:
          'https://spca.sspds.ce.gov.br/notification/notification',
        googleMapsApiKey: 'AIzaSyDVrj9xt0YqO-_5deYI1H4B0x5Q-RSS5ak',
        ambiente: 'prod',
        version: '1.0.0',
        prefixRoute: '/web/rotas',
        mapId: '8d409071b9d9e1cc',
      };

    default:
      return {
        applicationName: 'ROTAS',
        server: '',
        serverSIAA: 'https://spi-stage.sspds.ce.gov.br/dev/api/siaa',
        serverSocketTVP: 'https://spca-dev.sspds.ce.gov.br/socket.io/api/ws',
        serverSocketTO:
          'https://spca-dev.sspds.ce.gov.br/socket.io/api/ocorrencias',
        appkey: '208d7ae7ad66d46eeefb1da3335544a3',
        googleMapsApiKey: 'AIzaSyDVrj9xt0YqO-_5deYI1H4B0x5Q-RSS5ak',
        serverSSPDS: 'https://spca-dev.sspds.ce.gov.br/api',
        ambiente: 'prod',
        version: '1.0.0',
        prefixRoute: '/web/rotas',
        mapId: '8d409071b9d9e1cc',
      };
  }
}

export default {
  ...getVariablesByEnv(),
};
