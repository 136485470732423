import { TrackingHistoryContext } from 'context/tracking_history';
import { useContextSelector } from 'use-context-selector';

export function useHistorySeachByAisAndDate() {
  const searchByAisAndDate = useContextSelector(
    TrackingHistoryContext,
    context => context.searchByAisAndDate
  );

  return {
    searchByAisAndDate,
  };
}

