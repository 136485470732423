import React, { useEffect, useState } from 'react';
import { ClockCircleOutlined } from '@ant-design/icons';
import { useLoadingVeiculo } from 'hooks/monitoring/useLoadingVeiculo';
import { useLoadingHistorico } from 'hooks/history/useLoadingHistorico';

// import "./styles.css";

export default function Spinner() {
  const [temporizador, setTemporizador] = useState<number>(0);

  const { loadingHistorico } = useLoadingHistorico();
  const { loadingVeiculo } = useLoadingVeiculo();

  useEffect(() => {
    if (loadingHistorico || loadingVeiculo) {
      let ismounted = true;
      setTemporizador(0);
      const interval = setInterval(() => {
        if (ismounted) {
          setTemporizador(prev => prev + 1);
        }
      }, 1 * 1000);

      return () => {
        ismounted = false;
        clearInterval(interval);
      };
    }
  }, [loadingVeiculo, loadingHistorico]);

  return (
    <>
      {(loadingVeiculo || loadingHistorico) && (
        <div
          id="slotOverlay"
          className={loadingHistorico ? 'slotOverlay' : 'slotOverlay oculto'}
        >
          <div className="overlay ">
            <h6
              style={{
                color: '#ff4500',
                fontSize: `12px`,
                marginBottom: '20px',
                marginLeft: '10px',
                letterSpacing: '2px',
                textTransform: 'uppercase',
                textAlign: 'center',
              }}
            >
              Aguarde, buscando informações <br />
              Tempo de espera estimado: 4 minutos
            </h6>
            <div id="slotSpinner">
              <div className="spinner fundo" />
              <div className="spinner frente" />
            </div>
            <h2>Carregando...</h2>
            <h2>
              <ClockCircleOutlined style={{ color: '#1a784a' }} />{' '}
              {temporizador / 60 > 9
                ? Math.floor(temporizador / 60)
                : `0${Math.floor(temporizador / 60)}`}
              :
              {temporizador % 60 > 9
                ? temporizador % 60
                : `0${temporizador % 60}`}
            </h2>
          </div>
        </div>
      )}
    </>
  );
}

