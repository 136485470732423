import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Home from './Home';
import Mapa from './Principal';

/* import { ValidRole } from '../components/ValidRole'; */

import Cadastros from './Cadastros';

import { TipoVeiculoList } from './Veiculo/TipoVeiculo/List';
import { TipoVeiculoForm } from './Veiculo/TipoVeiculo/Form';

import { CadastroVeiculoList } from './Veiculo/Cadastro/List';
import { CadastroVeiculoForm } from './Veiculo/Cadastro/Form';

import { CadastroEquipamentos } from './Equipamentos/Cadastro';
import { CadastroEquipamentoForm } from './Equipamentos/Cadastro/Form';

import { VinculadaList } from './Veiculo/Vinculada/List';
import { VinculadaForm } from './Veiculo/Vinculada/Form';

import { LotacaoList } from './Veiculo/Lotacao/List';
import { LotacaoForm } from './Veiculo/Lotacao/Form';

import { PrefixoList } from './Veiculo/Prefixo/List';
import { PrefixoForm } from './Veiculo/Prefixo/Form';

import { AisList } from './Veiculo/Ais/List';
import { AisForm } from './Veiculo/Ais/Form';

import { Ocorrencias } from './Ocorrencias/view/listagemOcorrencia';
import { OcorrenciaPorProtocolo } from './Ocorrencias/view/ocorrenciaPorProtocolo';

import { CercaEletronica } from './Cercas/List';
import { CriarCercaEletronica } from './Cercas/form-criar-cerca-eletronica';
import { FormAtribuirCercas } from './Cercas/form-atribuir-cercas';
import { UploadFences } from './Cercas/Upload';

// import { PdfVeiculosNaoCadastrados } from './PDF/VeiculosNaoCadastrados';
import { PdfConectados } from './PDF/Conectados';
import { PdfDesconectados } from './PDF/Desconectados';
import { PdfHistoricoRaio } from './PDF/HistóricoRaio';
import Relatorios from './PDF';
import { PdfVeiculosNaoCadastrados } from './PDF/VeiculosNaoCadastrados';
import { PdfHistoricoAuditoria } from './PDF/HistóricoAuditoria';
import { PdfPersonalizado } from './PDF/Personalizado';
import Alertas from './PDF/Alertas';
import { PdfComposicao } from './composicao';
import ProtectedRoute from './protectedRoute';
// import { ScriptsList } from './roteiros/List';
// import { UploadScripts } from './roteiros/Upload';

export default function Routes() {
  return (
    /* @ts-expect-error Server Component */
    <Switch>
      {/* @ts-expect-error Server Component */}
      <Route path="/" exact component={Home} />

      <ProtectedRoute
        requiredRoles={['ROLE_ROTAS_MONITORAMENTO']}
        path="/mapa/:id"
        exact
        component={Mapa}
      />
      <ProtectedRoute
        requiredRoles={['ROLE_ROTAS_RELATORIOS']}
        path="/relatorios"
        exact
        component={Relatorios}
      />
      <ProtectedRoute
        requiredRoles={['ROLE_ROTAS_RELATORIOS']}
        path="/pdfVeiculosNaoCadastrados"
        exact
        component={PdfVeiculosNaoCadastrados}
      />
      <ProtectedRoute
        requiredRoles={['ROLE_ROTAS_RELATORIOS']}
        path="/pdfConectados"
        exact
        component={PdfConectados}
      />
      <ProtectedRoute
        requiredRoles={['ROLE_ROTAS_RELATORIOS']}
        path="/pdfDesconectados"
        exact
        component={PdfDesconectados}
      />
      <ProtectedRoute
        requiredRoles={['ROLE_ROTAS_RELATORIOS']}
        path="/alertas"
        exact
        component={Alertas}
      />
      <ProtectedRoute
        requiredRoles={['ROLE_ROTAS_RELATORIOS']}
        path="/pdfPersonalizado"
        exact
        component={PdfPersonalizado}
      />
      <ProtectedRoute
        requiredRoles={['ROLE_ROTAS_RELATORIOS']}
        path="/pdfHistoricoRaio"
        exact
        component={PdfHistoricoRaio}
      />
      <ProtectedRoute
        requiredRoles={['ROLE_ROTAS_RELATORIOS']}
        path="/pdfHistoricoAuditoria"
        exact
        component={PdfHistoricoAuditoria}
      />

      <ProtectedRoute
        requiredRoles={['ROLE_ROTAS_CADASTROS']}
        path="/cadastros"
        exact
        component={Cadastros}
      />

      <ProtectedRoute
        requiredRoles={['ROLE_ROTAS_CADASTROS']}
        exact
        path="/tipoVeiculo"
        component={TipoVeiculoList}
      />
      <ProtectedRoute
        requiredRoles={['ROLE_ROTAS_CADASTROS']}
        exact
        path="/tipoVeiculo/form"
        component={TipoVeiculoForm}
      />
      <ProtectedRoute
        requiredRoles={['ROLE_ROTAS_CADASTROS']}
        exact
        path="/tipoVeiculo/form/:id"
        component={TipoVeiculoForm}
      />

      <ProtectedRoute
        requiredRoles={['ROLE_ROTAS_CADASTROS']}
        exact
        path="/veiculo"
        component={CadastroVeiculoList}
      />

      <ProtectedRoute
        requiredRoles={['ROLE_ROTAS_CADASTROS']}
        exact
        path="/veiculo/form"
        component={CadastroVeiculoForm}
      />
      <ProtectedRoute
        requiredRoles={['ROLE_ROTAS_CADASTROS']}
        exact
        path="/veiculo/form/:id"
        component={CadastroVeiculoForm}
      />

      <ProtectedRoute
        requiredRoles={['ROLE_ROTAS_CADASTROS']}
        exact
        path="/equipamentos"
        component={CadastroEquipamentos}
      />
      <ProtectedRoute
        requiredRoles={['ROLE_ROTAS_CADASTROS']}
        path="/equipamentos/form"
        exact
        component={CadastroEquipamentoForm}
      />
      <ProtectedRoute
        requiredRoles={['ROLE_ROTAS_CADASTROS']}
        path="/equipamentos/form/:id"
        exact
        component={CadastroEquipamentoForm}
      />

      <ProtectedRoute
        requiredRoles={['ROLE_ROTAS_CADASTROS']}
        path="/vinculada"
        exact
        component={VinculadaList}
      />
      <ProtectedRoute
        requiredRoles={['ROLE_ROTAS_CADASTROS']}
        path="/vinculada/form"
        exact
        component={VinculadaForm}
      />
      <ProtectedRoute
        requiredRoles={['ROLE_ROTAS_CADASTROS']}
        path="/vinculada/form/:id"
        exact
        component={VinculadaForm}
      />

      <ProtectedRoute
        requiredRoles={['ROLE_ROTAS_CADASTROS']}
        path="/lotacao"
        exact
        component={LotacaoList}
      />
      <ProtectedRoute
        requiredRoles={['ROLE_ROTAS_CADASTROS']}
        path="/lotacao/form"
        exact
        component={LotacaoForm}
      />
      <ProtectedRoute
        requiredRoles={['ROLE_ROTAS_CADASTROS']}
        path="/lotacao/form/:id"
        exact
        component={LotacaoForm}
      />

      <ProtectedRoute
        requiredRoles={['ROLE_ROTAS_CADASTROS']}
        path="/prefixo"
        exact
        component={PrefixoList}
      />
      <ProtectedRoute
        requiredRoles={['ROLE_ROTAS_CADASTROS']}
        path="/prefixo/form"
        exact
        component={PrefixoForm}
      />
      <ProtectedRoute
        requiredRoles={['ROLE_ROTAS_CADASTROS']}
        path="/prefixo/form/:id"
        exact
        component={PrefixoForm}
      />

      <ProtectedRoute
        requiredRoles={['ROLE_ROTAS_CADASTROS']}
        path="/ais"
        exact
        component={AisList}
      />
      <ProtectedRoute
        requiredRoles={['ROLE_ROTAS_CADASTROS']}
        path="/ais/form"
        exact
        component={AisForm}
      />
      <ProtectedRoute
        requiredRoles={['ROLE_ROTAS_CADASTROS']}
        path="/ais/form/:id"
        exact
        component={AisForm}
      />

      <ProtectedRoute
        requiredRoles={['ROLE_ROTAS_OCORRENCIA']}
        path="/ocorrencias"
        exact
        component={Ocorrencias}
      />
      <ProtectedRoute
        requiredRoles={['ROLE_ROTAS_OCORRENCIA']}
        path="/ocorrencias/:id"
        exact
        component={OcorrenciaPorProtocolo}
      />

      <ProtectedRoute
        requiredRoles={[
          'ROLE_ROTAS_VISUALIZACAO_CERCA',
          'ROLE_ROTAS_CRIACAO_CERCA',
          'ROLE_ROTAS_CERCA_CIOPS',
        ]}
        path="/cerca"
        exact
        component={CercaEletronica}
      />
      <ProtectedRoute
        requiredRoles={['ROLE_ROTAS_CERCA_CIOPS']}
        path="/cerca/atribuir"
        exact
        component={FormAtribuirCercas}
      />

      <ProtectedRoute
        requiredRoles={['ROLE_ROTAS_CRIACAO_CERCA', 'ROLE_ROTAS_CERCA_CIOPS']}
        path="/cerca/form"
        exact
        component={CriarCercaEletronica}
      />
      <ProtectedRoute
        requiredRoles={['ROLE_ROTAS_CRIACAO_CERCA', 'ROLE_ROTAS_CERCA_CIOPS']}
        path="/cerca/form/:id"
        exact
        component={CriarCercaEletronica}
      />

      <ProtectedRoute
        requiredRoles={['ROLE_ROTAS_CERCA_UPLOAD']}
        path="/cerca/upload"
        exact
        component={UploadFences}
      />

      <ProtectedRoute
        requiredRoles={['ROLE_ROTAS_HISTORICO_CONEXAO']}
        path="/composicao"
        exact
        component={PdfComposicao}
      />

      {/* <ProtectedRoute
        requiredRoles={['ROLE_ROTAS_ROTEIROS']}
        path="/roteiros"
        exact
        component={ScriptsList}
      /> */}
      {/* <ProtectedRoute
        requiredRoles={['ROLE_ROTAS_ROTEIROS']}
        path="/roteiros/upload"
        exact
        component={UploadScripts}
      /> */}
      {/* @ts-expect-error Server Component */}
      <Redirect to="/" />
    </Switch>
  );
}
