import { FiltersMapContext } from 'context/mapa/filters_map';
import { useContextSelector } from 'use-context-selector';

export function useFiltersMapGroups() {
  const groupsFilters = useContextSelector(
    FiltersMapContext,
    context => context.groupsFilters
  );

  const setGroupsFilters = useContextSelector(
    FiltersMapContext,
    context => context.setGroupsFilters
  );

  return {
    groupsFilters,
    setGroupsFilters,
  };
}

