/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import IconMonitoramento from '../../imgs/marker-map.svg';
import IconCadastros from '../../imgs/ocorrencia-1.svg';
import IconRelatorio from '../../imgs/relatorio.svg';
import IconOcorrencia from '../../imgs/ocorrence.svg';
import IconHistorico from '../../imgs/Car_Historico.svg';
import IconCerca from '../../imgs/cerca-eletronica.svg';
import IconComposicao from '../../imgs/composição.svg';

import './styles.css';
import { useAuthData } from 'hooks/auth/useAuthData';

function Home() {
  const history = useHistory();
  const { data } = useAuthData();
  const { roles } = data || {};

  const isPermitted = useCallback(
    (role: string) => {
      return (roles || []).filter((item: string) => item === role).length > 0;
    },
    [roles]
  );

  return (
    <div className="container">
      <div className="row">
        {isPermitted('ROLE_ROTAS_MONITORAMENTO') && (
          <div
            className="card-01"
            onClick={() => history.push('/mapa/monitoramento')}
          >
            <div className="titleContainer">
              <div className="title-card">Monitoramento</div>
              <div className="subtitle-card">
                Monitoramento em tempo real de viaturas
              </div>
            </div>
            <div className="card-icon card-icon-red">
              <div className="img-icon">
                <img
                  src={IconMonitoramento}
                  alt="monitoramento"
                  className="iconComponent"
                />
                {/* <IconMonitoramento className="iconComponent" /> */}
              </div>
            </div>
          </div>
        )}

        {isPermitted('ROLE_ROTAS_HISTORICO') && (
          <div
            className="card-01"
            onClick={() => history.push('/mapa/historico')}
          >
            <div className="titleContainer">
              <div className="title-card">Histórico</div>
              <div className="subtitle-card">Histórico de posições</div>
            </div>
            <div className="card-icon card-icon-green">
              <div className="img-icon">
                <img
                  src={IconHistorico}
                  alt="historico"
                  className="iconComponent"
                />
                {/* <IconMonitoramento className="iconComponent" /> */}
              </div>
            </div>
          </div>
        )}

        {isPermitted('ROLE_ROTAS_CADASTROS') && (
          <div className="card-01" onClick={() => history.push('/cadastros')}>
            <div className="titleContainer">
              <div className="title-card">Cadastros</div>
              <div className="subtitle-card">Menu de Cadastros</div>
            </div>
            <div className="card-icon card-icon-orange">
              <div className="img-icon">
                <img
                  src={IconCadastros}
                  alt="cadastros"
                  className="iconComponent"
                />
                {/* <IconOcorrencia className="iconComponent" /> */}
              </div>
            </div>
          </div>
        )}

        {isPermitted('ROLE_ROTAS_OCORRENCIA') && (
          <div className="card-01" onClick={() => history.push('/ocorrencias')}>
            <div className="titleContainer">
              <div className="title-card">Ocorrências</div>
              <div className="subtitle-card">Monitoramento de ocorrências</div>
            </div>
            <div className="card-icon card-icon-yello">
              <div className="img-icon">
                <img
                  src={IconOcorrencia}
                  alt="ocorrencias"
                  className="iconComponent"
                />
                {/* <IconOcorrencia className="iconComponent" /> */}
              </div>
            </div>
          </div>
        )}

        {(isPermitted('ROLE_ROTAS_VISUALIZACAO_CERCA') ||
          isPermitted('ROLE_ROTAS_CERCA_CIOPS')) && (
          <div className="card-01" onClick={() => history.push('/cerca')}>
            <div className="titleContainer">
              <div className="title-card">Cercas</div>
              <div className="subtitle-card">
                Gerenciamento de Cercas Eletrônicas
              </div>
            </div>

            <div className="card-icon card-icon-blue">
              <div className="img-icon">
                <img src={IconCerca} alt="cercas" className="iconComponent" />
              </div>
            </div>
          </div>
        )}

        {isPermitted('ROLE_ROTAS_RELATORIOS') && (
          <div className="card-01" onClick={() => history.push('/relatorios')}>
            <div className="titleContainer">
              <div className="title-card">Relatórios</div>
              <div className="subtitle-card">Relatórios disponíveis</div>
            </div>
            <div className="card-icon card-icon-grey">
              <div className="img-icon">
                <img
                  src={IconRelatorio}
                  alt="ocorrencia"
                  className="iconComponent"
                />
              </div>
            </div>
          </div>
        )}

        {isPermitted('ROLE_ROTAS_HISTORICO_CONEXAO') && (
          <div className="card-01" onClick={() => history.push('/composicao')}>
            <div className="titleContainer">
              <div className="title-card">Composição</div>
              <div className="subtitle-card">
                Historico de composição das viaturas
              </div>
            </div>
            <div className="card-icon card-icon-purple">
              <div className="img-icon">
                <img
                  src={IconComposicao}
                  alt="composição"
                  className="iconComponent"
                />
                {/* <IconOcorrencia className="iconComponent" /> */}
              </div>
            </div>
          </div>
        )}

        {/* {isPermitted('ROLE_ROTAS_ROTEIROS') && (
          <div className="card-01" onClick={() => history.push('/roteiros')}>
            <div className="titleContainer">
              <div className="title-card">Roteiros</div>
              <div className="subtitle-card">
                Upload e gerenciamento de rotas personalizadas
              </div>
            </div>
            <div className="card-icon card-icon-purple">
              <div className="img-icon">
                <img
                  src={IconComposicao}
                  alt="composição"
                  className="iconComponent"
                />
          
              </div>
            </div>
          </div>
        )} */}
      </div>
    </div>
  );
}

export default Home;
