import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .rowButtons {
    display: flex;
    width: 100%;

    button {
      flex: 1 !important;
    }
  }

  .buttonSquare {
    height: 70px;
  }

  .pdfButton {
    :hover {
      color: black;
      font-weight: bold;
      background: rgba(143, 188, 143, 0.5);
      border: 1px solid green;
    }
  }

  .csvButton {
    :hover {
      color: black;
      font-weight: bold;
      background: rgba(176, 196, 222, 0.5);
    }
  }

  .disabled {
    cursor: not-allowed;
    background-color: rgba(211, 211, 211, 0.5);
    pointer-events: none;
  }
`;

