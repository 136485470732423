import styled from 'styled-components';

export const Container = styled.div`
  .ant-select-selector {
    border-radius: 10px !important;
  }

  .ant-picker-range {
    border-radius: 10px;
  }

  .ant-btn-primary {
    border-radius: 10px;
    background-color: var(--corPrincipal);

    &:hover {
      opacity: 0.8;
    }
  }
  .targetPointButton {
    background-color: #1a784a !important;
    border-radius: 25px !important;
    width: 200px !important;
    height: 40px !important;
    color: #fff !important;
    margin: 0px !important;
    transition: 1s !important;
  }

  .targetPointButton:hover {
    border: 1px solid #115e39 !important;
    background-color: #115e39 !important;
  }

  .resetButton {
    background-color: #fc6836 !important;
    border-radius: 25px !important;
    width: 200px !important;
    height: 40px !important;
    color: #fff !important;
    margin: 0px !important;
    border: 1px solid #fc6836 !important;
    transition: 1s !important;
  }

  .resetButton:hover {
    background-color: #d1481a !important;
  }

  .formes {
    width: 100%;
    height: 50px;
    background: #115e39;
  }

  .grid-button {
    display: flex;
  }

  /* Extra small devices (phones, 600px and down) */

  @media only screen and (max-width: 600px) {
    .grid-button {
      display: block;
    }
  }

  /* Small devices (portrait tablets and large phones, 600px and up) */

  @media only screen and (min-width: 600px) {
    .grid-button {
      display: block;
    }
  }

  /* Medium devices (landscape tablets, 768px and up) */

  @media only screen and (min-width: 768px) {
    .grid-button {
      display: flex;
    }
  }

  /* Large devices (laptops/desktops, 992px and up) */

  @media only screen and (min-width: 992px) {
  }

  /* Extra large devices (large laptops and desktops, 1200px and up) */

  @media only screen and (min-width: 1200px) {
  }
`;

