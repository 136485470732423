/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import style from './styles.module.css';

export default function InfoAlerta({
  placa,
  speed,
  data,
  nomeCerca,
}: {
  placa: string;
  speed: number;
  data: string;
  nomeCerca: string;
}) {
  return (
    <div className={style.infoAlertaContainer}>
      <div className={style.rowInfo}>
        <div className={style.title}>Placa:</div>
        <div className={style.subTitle}>{placa}</div>
      </div>
      {data && (
        <div className={style.rowInfo}>
          <div className={style.title}>Data:</div>
          <div className={style.subTitle}>
            {`${new Date(data).toLocaleDateString()} ${new Date(
              data
            ).toLocaleTimeString()}`}
          </div>
        </div>
      )}
      <div className={style.rowInfo}>
        <div className={style.title}>Velocidade:</div>
        <div className={style.subTitle}>{speed}</div>
      </div>

      <div className={style.dividerHorizontal} />

      {nomeCerca && (
        <div className={style.rowInfo}>
          <div className={style.title}>Cerca:</div>
          <div className={style.nomeCerca}>{nomeCerca}</div>
        </div>
      )}
    </div>
  );
}
InfoAlerta.propTypes = {
  placa: PropTypes.string.isRequired,
  nomeCerca: PropTypes.string,
};

