/* eslint-disable */
import React from 'react';

export default function columns() {
  return [
    {
      title: <b> Placa</b>,
      dataIndex: 'placa',
      sorter: (a, b) => (a.placa < b.placa ? -1 : a.placa > b.placa ? 1 : 0),
    },
    {
      title: <b> Tipo</b>,
      dataIndex: 'tipoAlerta',
      sorter: (a, b) => (a.tipoAlerta < b.tipoAlerta ? -1 : a.tipoAlerta > b.tipoAlerta ? 1 : 0),
    },
    {
      title: <b>Equipamento</b>,
      dataIndex: 'idDevice',
      sorter: (a,b) => (a.ideDevice < b.ideDevice ? -1 : a.ideDevice > b.ideDevice ? 1 : 0),
    },
    {
      title: <b>Cercas</b>,
      dataIndex: 'nomeCerca',
      sorter: (a,b) => (a.idCercas < b.idCercas ? -1 : a.idCercas > b.idCercas ? 1 : 0),
    },
    {
      title: <b> Data</b>,
      dataIndex: 'datePosition',
      sorter: (a, b) => (a.date < b.date ? -1 : a.date > b.date ? 1 : 0),
    },
  ];
}

