import api from '../api';
import { serviceReutils } from '../../utils/reutils';

export const ServiceHistoricoVeiculoPrefixo = {
  ...serviceReutils({ name: 'rotas/v1/veiculoPrefixo', api }),

  getAllByVeiculo: async placa => {
    const retorno = await api.get(`/rotas/v1/veiculoPrefixo/page`, {
      params: {
        placa,
        size: 10,
        page: 1,
      },
    });

    return retorno;
  },
};
