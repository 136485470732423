import { toast } from 'react-toastify';

const alertaError = (messages: any) => {
  messages.forEach((message: any) => {
    toast.error(message.message);
  });
};

const httpErrorHandlers: any = {
  '422': (error: any) => {
    if (error.response.data.messages) {
      alertaError(error.response.data.messages || []);
    }
  },
  '400': (error: any) => {
    if (error.response.data.messages) {
      alertaError(error.response.data.messages || []);
    }
  },
  '404': (error: any) => {
    if (
      error.response.data.message &&
      error.response.data.message !== 'No message available'
    ) {
      toast.error(error.response.data.message);
    }
  },
  '401': (error: any) => {
    if (!toast.isActive('errorAuth')) {
      toast.error('Erro de autorização. Por favor, efetue o login novamente.', {
        toastId: 'errorAuth',
      });
    }

    setTimeout(() => {
      sessionStorage.removeItem('rota@tkn');
      sessionStorage.removeItem('rota@roles');
      sessionStorage.removeItem('rota@client');
      document.location.reload();
    }, 2 * 1000);
  },
  '403': (error: any) => {
    if (!toast.isActive('errorAuth')) {
      toast.error('Erro de autorização. Por favor, efetue o login novamente.', {
        toastId: 'errorAuth',
      });
    }

    setTimeout(() => {
      sessionStorage.removeItem('rota@tkn');
      sessionStorage.removeItem('rota@roles');
      sessionStorage.removeItem('rota@client');
      document.location.reload();
    }, 2 * 1000);
  },
};

export const httpErrorHandler = (error: any, logout: any) => {
  if (error.response) {
    const { status } = error.response;
    const handler = httpErrorHandlers[`${status}`];

    if (handler) handler(error, logout);
  }
};

