import React, { useRef, useCallback, useState, useEffect } from 'react';
import { Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';

import { toast } from 'react-toastify';
import { useJsApiLoader } from '@react-google-maps/api';
import { getVeiculosServices } from '~/services/modulos/getVeiculos';
import { ServiceVeiculo } from '~/services/modulos/service_veiculo';
import { pdfGen } from './reports';
import columns from '~/columns/PDF/VeiculosNaoCadastrados/columns';
import PdfImprimir from '../../../components/PdfImprimir';
import Filter from '../Filter';

import ENV from '~/config';
import '../styles.css';
import { googleLibraries } from 'utils/functionsUtils';

let dadosPdfPai;

export function PdfVeiculosNaoCadastrados() {
  const componentRef = useRef();
  const [dadosLast, setDadosLast] = useState([]);
  const [loadingTable, setLoadingTable] = useState(false);
  const [dataFiltred, setDataFiltred] = useState([]);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: ENV.googleMapsApiKey,
    libraries: googleLibraries,
    region: 'BR',
    language: 'pt-br',
  });

  const diffHours = useCallback((dt2, dt1) => {
    let diff = (dt2.getTime() - dt1.getTime()) / 1000;
    diff /= 60 * 60;
    return Math.abs(Math.round(diff));
  }, []);

  useEffect(() => {
    const load = async () => {
      try {
        setLoadingTable(true);
        const today = new Date();
        let [data, veiculos, conectadas] = await Promise.all([
          getVeiculosServices.get(),
          ServiceVeiculo.resumoVeiculo(),
          getVeiculosServices.getConectadas(),
        ]);

        data = data.filter(
          d => conectadas.filter(con => con.viatura === d.prefixo).length > 0
        );

        const reducer = (arrayAccumlator, currentValue) => {
          const dataLast = new Date(currentValue.datePosition);
          const dif = diffHours(today, dataLast);
          if (dif <= 48) {
            const veiculoFiltered = veiculos.find(
              v => v.identificador === currentValue.placa
            );
            if (veiculoFiltered) {
              arrayAccumlator.push({ ...currentValue, ...veiculoFiltered });
            } else {
              arrayAccumlator.push({ ...currentValue, prefixo: '' });
            }
          }
          return arrayAccumlator;
        };
        setDadosLast(data.reduce(reducer, []));
        setDataFiltred(data.reduce(reducer, []));
      } catch (err) {
        toast.error('Erro ao carregar dados');
      } finally {
        setLoadingTable(false);
      }
    };
    load();
  }, [diffHours]);

  const sessaoDados = dados => {
    const _chave = 'dadosVeiculosNaoCadastrados';

    if (dados) {
      sessionStorage.setItem(`rotas/${_chave}`, JSON.stringify(dados));
    } else {
      return JSON.parse(sessionStorage.getItem(`rotas/${_chave}`));
    }
  };

  if (window.navigator && window.navigator.dadosPdf) {
    dadosPdfPai = window.navigator.dadosPdf;

    sessaoDados(dadosPdfPai);
  } else {
    dadosPdfPai = sessaoDados();
  }

  return (
    <>
      <Breadcrumb style={{ padding: '10px' }}>
        <Breadcrumb.Item>
          <Link to="/">Página Inicial</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to="/relatorios">Relatorios</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Desatualizados</Breadcrumb.Item>
      </Breadcrumb>
      <Filter data={dadosLast} setDataFiltred={setDataFiltred} />
      <PdfImprimir
        dadosPdfPai={dadosPdfPai}
        dadosLast={dataFiltred}
        ref={componentRef}
        loadingTable={loadingTable}
        columnWithModal
        columns={columns}
        pdfGen={pdfGen}
        isLoaded={isLoaded}
      />
    </>
  );
}
