// @ts-nocheck
import styled from 'styled-components';
import { Menu, Avatar } from 'antd';

export const MenuContainer = styled(Menu)`
  color: red !important;
  background-color: #fff !important;
  /* margin-top: 20px !important; */
  border-radius: 10px !important;
  height: 217px !important;
  width: 270px !important;
  margin-left: 57px !important;
`;

export const MenuItem = styled(Menu.Item)`
  color: grey !important;
`;

export const AvatarImg = styled(Avatar)`
  margin: 20px !important;
  width: 50% auto !important;
  background-color: black !important;
`;

export const UserDetails = styled.div`
  strong {
    color: grey;
    margin: 20%;
    width: auto;
    padding-top: 20px;
  }
  small {
    display: table;
    max-width: 100%;
    padding: 0px 42px;
    word-wrap: break-word;
    color: grey;
    font-size: 8px;
  }
  button {
    display: inline;
    color: grey;
    margin-left: 10px;
    margin-top: 24px;
    height: 40px;
    width: 110px;
    border-radius: 5px;
    background-color: transparent;

    border: 1px solid gray;
  }
  .item1 {
    grid-row: 1 / span 2;
    margin-left: 10px;
  }
`;

export const MenuItemUser = styled.div`
  background: #4400ff;
`;

export const Container = styled.div`
  height: 100%;

  #dadosPosicao {
    --espessuraBorda: 0.6rem;
    background: #fff;
    position: relative;
    height: 100%;
    top: 0;
    left: 0;
    color: var(--corBranco);
    display: flex;
    flex-direction: column;
    justify-content: left;
  }

  #dadosPosicao button {
    padding: 0.2rem;
    font-size: 14pt;
    cursor: pointer;
  }

  .aparecePainel {
    background: #e4e4e4;
    position: absolute;
    width: 20%;
    height: 100%;
    overflow-y: auto;
  }
  .hidePainel {
    position: relative;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    height: 101vh;
    width: 60px;
    float: right;
    background: #097547;
    animation: aparecePainelMobile 0.3 linear forwards;
    transition: all 0.2s ease-out;
  }

  .cabecalhoPainel {
    background: #097547;
    padding: 10px !important;
    color: var(--corBranco);
    border-bottom: 6px solid var(--corBranco);
    padding: 0.2rem;
    padding-top: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .titleSistema {
    display: flex !important;
    align-items: center !important;
    font-size: 1.5rem;
  }

  .cabecalhoPainel * {
    color: var(--corBranco);
    text-align: left;
  }

  .cabecalhoPainel > h2 {
    font-size: 18pt;
    margin-bottom: 0px;
  }

  .cabecalhoPainel > h4 {
    font-size: 10pt;
  }

  .circularAvatar {
    width: 44px;
    height: 44px;
    border: 2px solid #fff;
    border-radius: 50px;
    background: #ececec;
  }
  .circularAvatarMobile {
    display: none;
    position: absolute;
    right: 15px;
    top: 9px;
    z-index: 999;
  }

  .line-orange {
    height: 6px;
    left: 998px;
    top: 68px;
    background: #fc6836;
  }

  .componentesPainel {
    width: 100%;
    padding: 5px;
    // overflow: auto;
  }

  .titleMobile {
    display: none;
  }

  .ant-select {
    width: 100%;
  }

  .contentAlertas {
    display: flex;
    flex-direction: column;
    overflow: auto;
    background: white;
    max-height: 500px;
  }

  .styles_grupos__1foam {
    padding: 0px;
    display: flex;
    align-items: baseline;
  }

  .ant-select-selector {
    border-radius: 10px !important;
  }

  .ant-dropdown-menu-item {
    display: block !important;
    margin: -5px -12px !important;
    padding: 5px 40% !important;
    color: red !important;
    -webkit-transition: all 0.3s !important;
    transition: all 0.3s !important;
  }

  #btnLogout {
    background: var(--corComplementar) !important;
    padding: 0 !important;
    border-color: var(--corComplementar) !important;
  }

  #btnLogout:hover,
  #btnLogout:focus {
    background: var(--corComplementarClara) !important;
  }

  #btnLogout * {
    color: var(--corBranco);
  }

  #lista {
    border-top: var(--espessuraBorda) solid var(--corComplementarClara);
    max-height: 100%;
    overflow: auto;
    text-align: center;
  }

  #lista > select {
    border: 1px solid var(--corPrincipalMaisClara);
    background: inherit;
    color: var(--corPrincipalMaisClara);
    font-size: 12pt;
    padding: 0.1rem;
    margin: 0.3rem auto;
  }

  #lista > p {
    text-align: center;
    width: 100%;
    justify-content: center;
    box-shadow: 0 2px 5px var(--corFundo3);
    color: var(--corAnaloga1);
  }

  .listaPins {
    margin-top: 0;
  }

  .ant-dropdown-placement-bottomRight {
    margin-left: 10px;
  }

  .slotRodape {
    position: absolute !important;
    width: 100%;
    bottom: 0;
    ${'' /* border: 1px solid #f0f !important; */}
    z-index: 3000 !important;
  }

  .rodape {
    ${'' /* border: 1px solid #f00; */}
    z-index: 3500 !important;
    color: gray;
  }
  .filtroAlerta {
    padding: 7px 10px 0px 12px;
    justify-content: space-between;
  }

  .rowFiltroAlerta {
    width: 100%;
    text-align: -webkit-left;
    display: flex;
  }

  .areaDeAlertaScroll {
    overflow-y: scroll;
    height: 80%;
  }

  .filtroAlerta h3 {
    padding-top: 5px;
    padding-left: 5px;
    font-size: 12px;
    width: 45%;
  }
  /* Extra small devices (phones, 600px and down) */

  @media only screen and (max-width: 768px) {
    .aparecePainel {
      bottom: 0px;
      height: 50vh !important;
      width: 100vw !important;
      border-radius: 15px;
      background: #fff;
      transition: all 0.2s ease-out;
      animation: hidePainelMobile 0.3s linear forwards !important;
    }
    .hidePainel {
      bottom: 0px;
      position: absolute;
      height: 6vh !important;
      width: 100vw !important;
    }
    .titleMobile {
      display: flex;
      padding: 20px 18px;
      font-size: 20px;
      font-weight: 500;
      height: 0vh;
    }
    .circularAvatar {
      display: none;
    }
    .circularAvatarMobile {
      display: flex !important;
    }
    .contentAlertas {
      flex-direction: row;
    }
  }

  @media only screen and (max-width: 600px) {
    .titleSistema {
      display: none !important;
    }

    #dadosPosicao {
      display: none;
    }

    .filtroAlerta {
      display: none;
    }

    .circularAvatar {
      width: 44px;
      height: 44px;
      border: 2px solid #fff;
      border-radius: 50px;
      background: #ececec;
    }
  }

  /* Small devices (portrait tablets and large phones, 600px and up) */

  @media only screen and (max-width: 600px) {
    .infoAlertaContainer {
      line-height: 1.2rem;
    }
    .rowInfo {
      display: block;
      line-height: 1.2rem;
    }
    .subTitle {
      font-size: 1rem;
    }
    .nomeCerca {
      padding-right: 3px;
      font-size: 0.8rem;
      line-height: 0.9rem;
    }

    .filtroAlerta {
      display: none;
    }

    .aparecePainel {
      width: 20%;
    }

    .cabecalhoPainel {
      display: grid;
    }

    .circularAvatar {
      width: 44px;
      height: 44px;
      border: 2px solid #fff;
      border-radius: 50px;
      background: #ececec;
    }
  }

  /* Medium devices (landscape tablets, 768px and up) */

  @media only screen and (max-width: 768px) {
    .titleSistema {
      font-size: 16px !important;
    }

    .infoAlertaContainer {
      line-height: 1.2rem;
    }
    .rowInfo {
      display: block;
      line-height: 1.3rem;
    }
    .subTitle {
      font-size: 1rem;
    }
    .nomeCerca {
      padding-right: 3px;
      font-size: 1rem;
      line-height: 1.7rem;
    }

    .filtroAlerta {
      display: none;
    }

    .aparecePainel {
      width: 20%;
    }

    .cabecalhoPainel {
      display: flex;
    }

    .circularAvatar {
      width: 44px;
      height: 44px;
      border: 2px solid #fff;
      border-radius: 50px;
      background: #ececec;
    }
  }

  /* Large devices (laptops/desktops, 992px and up) */

  @media only screen and (max-width: 768px) {
    .cabecalhoPainel {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .circularAvatar {
      margin-top: 10px;
    }
  }

  @media only screen and (max-width: 992px) {
    .titleSistema {
      font-size: 16px;
    }
    .infoAlertaContainer {
      line-height: 1.2rem;
    }
    .rowInfo {
      display: flex;
      line-height: 1.3rem;
    }
    .subTitle {
      font-size: 1rem;
    }
    .nomeCerca {
      padding-right: 3px;
      font-size: 1.1rem;
      line-height: 1.7rem;
    }

    .filtroAlerta {
      display: block;
    }

    .aparecePainel {
      width: 20%;
    }

    #dadosPosicao {
      display: block;
    }

    .cabecalhoPainel {
      display: flex;
    }

    .circularAvatar {
      width: 44px;
      height: 44px;
      border: 2px solid #fff;
      border-radius: 50px;
      background: #ececec;
    }
  }

  @media only screen and (max-width: 1200px) {
    .titleSistema {
      font-size: 18px;
    }
  }

  /* Extra large devices (large laptops and desktops, 1200px and up) */

  @media only screen and (min-width: 1200px) {
    .infoAlertaContainer {
      line-height: 1.2rem;
    }
    .rowInfo {
      display: flex;
      line-height: 1.3rem;
    }
    .subTitle {
      font-size: 1rem;
    }
    .nomeCerca {
      padding-right: 3px;
      font-size: 1.2rem;
      line-height: 1.7rem;
    }

    .filtroAlerta {
      display: block;
    }

    .aparecePainel {
      width: 20%;
    }

    #dadosPosicao {
      width: 100%;
    }

    .cabecalhoPainel {
      display: flex;
    }

    .circularAvatar {
      width: 44px;
      height: 44px;
      border: 2px solid #fff;
      border-radius: 50px;
      background: #ececec;
    }
  }
  @media only screen and (max-width: 1200px) {
    .hide {
      display: none !important;
    }
  }
`;

