import { MonitoringContext } from 'context/monitoring';
import { useContextSelector } from 'use-context-selector';

export function useLoadingVeiculo() {
  const loadingVeiculo = useContextSelector(
    MonitoringContext,
    context => context.loadingVeiculo
  );

  return {
    loadingVeiculo,
  };
}

