import { TrackingHistoryContext } from 'context/tracking_history';
import { useContextSelector } from 'use-context-selector';

export function useHistoryVehiclesWithFullHistoryLoaded() {
  const vehiclesWithFullHistoryLoaded = useContextSelector(
    TrackingHistoryContext,
    context => context.vehiclesWithFullHistoryLoaded
  );

  return {
    vehiclesWithFullHistoryLoaded,
  };
}

