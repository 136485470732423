import { EyeOutlined } from '@ant-design/icons';
import Tooltip from 'antd/es/tooltip';
import React from 'react';
import { Link } from 'react-router-dom';

export const templateColumns = () => {
  return [
    {
      title: 'Número',
      dataIndex: 'ocorrencia',
      key: 'ocorrencia',
      align: 'center',
      sorter: (a, b) =>
        a.ocorrencia < b.ocorrencia ? -1 : a.ocorrencia > b.ocorrencia ? 1 : 0,
    },
    {
      title: 'Agencia',
      dataIndex: 'agencia',
      key: 'agencia',
      align: 'center',
      responsive: ['sm'],
      sorter: (a, b) =>
        a.agencia < b.agencia ? -1 : a.agencia > b.agencia ? 1 : 0,
    },
    {
      title: 'Grupo',
      dataIndex: 'grupo',
      key: 'grupo',
      align: 'center',
      responsive: ['sm'],
      sorter: (a, b) => (a.grupo < b.grupo ? -1 : a.grupo > b.grupo ? 1 : 0),
    },
    {
      title: 'Data Criação',
      dataIndex: 'dtCriacao',
      key: 'dtCriacao',
      align: 'center',
      responsive: ['md'],
      sorter: (a, b) =>
        a.dtCriacao < b.dtCriacao ? -1 : a.dtCriacao > b.dtCriacao ? 1 : 0,
      render: dtCriacao => {
        return new Date(dtCriacao).toLocaleString();
      },
    },
    {
      title: 'Tipo',
      dataIndex: 'tipoCodigo',
      key: 'tipoCodigo',
      align: 'center',
      responsive: ['md'],
      sorter: (a, b) =>
        a.tipoCodigo < b.tipoCodigo ? -1 : a.tipoCodigo > b.tipoCodigo ? 1 : 0,
    },
    {
      title: 'Sub Tipo',
      dataIndex: 'subTipoCodigo',
      key: 'subTipoCodigo',
      align: 'center',
      responsive: ['lg'],
      sorter: (a, b) =>
        a.subTipoCodigo < b.subTipoCodigo
          ? -1
          : a.subTipoCodigo > b.subTipoCodigo
          ? 1
          : 0,
    },
    {
      title: 'Ações',
      key: 'action',
      align: 'center',
      render: (__, record) => (
        <>
          <Link to={`ocorrencias/${record.ocorrencia}`}>
            <Tooltip title="Visualizar Ocorrência">
              <EyeOutlined size={20} />
            </Tooltip>
          </Link>
        </>
      ),
    },
  ];
};
