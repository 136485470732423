import React from 'react';
import { toast } from 'react-toastify';
import { call, put, takeLatest } from 'redux-saga/effects';
import { Tag, Tooltip, Divider, Popconfirm } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';

import history from '../services/history';
import { ContainerFlex, Button } from './styles';

export const serviceReutils = ({ name, api }) => ({
  getAll: params => api.get(`/${name}`, { params }),

  getAllPageable: params => api.get(`/${name}/page`, { params }),

  create: entidade => api.post(`/${name}`, entidade),

  getById: id =>
    api.get(`/${name}`, { params: { id } }).then(response => {
      if ((response || []).length > 0) return response[0];
      return {};
    }),

  update: entidade =>
    api.put(`/${name}`, entidade, { params: { id: entidade.id } }),

  delete: id => api.delete(`/${name}`, { params: { id } }),
});

export const ActionTypesReutils = modulo => {
  const name = modulo.toUpperCase();
  return {
    [`${name}_GET_ALL_REQUEST`]: `@${name}/GET_ALL_REQUEST`,
    [`${name}_GET_ALL_SUCCESS`]: `@${name}/GET_ALL_SUCCESS`,
    [`${name}_POST_REQUEST`]: `@${name}/POST_CREATE_REQUEST`,
    [`${name}_POST_SUCCESS`]: `@${name}/POST_CREATE_SUCCESS`,
    [`${name}_GET_SEARCH_PAGE`]: `@${name}/GET_SEARCH_REQUEST`,
    [`${name}_GET_SEARCH_PAGE_SUCCESS`]: `@${name}/GET_SEARCH_SUCCESS`,
    [`${name}_GET_ID_REQUEST`]: `@${name}/GET_ID_REQUEST`,
    [`${name}_GET_ID_SUCCESS`]: `@${name}/GET_ID_SUCCESS`,
    [`${name}_UPDATE_REQUEST`]: `@${name}/GET_UPDATE_REQUEST`,
    [`${name}_UPDATE_SUCCESS`]: `@${name}/GET_UPDATE_SUCCESS`,
    [`${name}_DELETE_REQUEST`]: `@${name}/GET_DELETE_REQUEST`,
    [`${name}_DELETE_SUCCESS`]: `@${name}/GET_DELETE_SUCCESS`,
    [`${name}_ERROR`]: `@${name}/ERROR`,
  };
};

export const createSagaReutils = (
  path,
  Service,
  listRequest,
  listSuccess,
  salvarSucess,
  editarSuccess,
  deleteSuccess,
  getByIdSuccess,
  error
) => ({
  listar: function* listSaga({ params }) {
    try {
      // console.log("list");
      const list = yield call(Service.getAll, params);
      yield put(listSuccess(list));
    } catch (e) {
      yield put(error(e));
    }
  },
  salvar: function* salvarSaga({ data, history: redirect }) {
    try {
      const retorno = yield call(Service.create, data);
      yield put(salvarSucess(retorno));
      toast.success('Registro salvo com sucesso');
      if (redirect) {
        redirect.push(path);
      } else {
        history.push(path);
      }
    } catch (e) {
      toast.error('Falha ao salvar, tente novamente!');
      yield put(error(e));
    }
  },
  editar: function* editarSaga({ data }) {
    try {
      // CHAMADA API
      const retorno = yield call(Service.update, data);
      // CHAMDA SUCCESS
      yield put(editarSuccess(retorno));
      toast.success('Registro atualizado com sucesso!');
    } catch (e) {
      toast.error(e.message);
      // ERROR
      yield put(error(e));
    }
  },
  deletar: function* deletarSaga({ id, params }) {
    try {
      // CHAMADA API
      const retorno = yield call(Service.delete, id);
      // CHAMDA SUCESS
      yield put(deleteSuccess(retorno));
      // REFRESH
      toast.success('Registro removido!');
      yield put(listRequest(params));
    } catch (e) {
      // ERROR
      yield put(error(e));
    }
  },
  getById: function* getSaga({ id }) {
    try {
      const data = yield call(Service.getById, id);
      yield put(getByIdSuccess(data));
    } catch (e) {
      yield put(error(e.message));
    }
  },
});

export const createAllTakeLastDefault = ({ modulo, ACOES }) => {
  const name = modulo.toUpperCase();
  return [
    takeLatest(`@${name}/GET_DELETE_REQUEST`, ACOES.deletar),
    takeLatest(`@${name}/GET_UPDATE_REQUEST`, ACOES.editar),
    takeLatest(`@${name}/GET_ID_REQUEST`, ACOES.getById),
    takeLatest(`@${name}/GET_ALL_REQUEST`, ACOES.listar),
    takeLatest(`@${name}/POST_CREATE_REQUEST`, ACOES.salvar),
  ];
};

export const columnsTableTipos = ({ handleDelete, modulo }) => {
  return [
    {
      title: 'Nome',
      dataIndex: 'nome',
      key: 'nome',
      align: 'center',
    },
    {
      title: 'Status',
      key: 'ativo',
      dataIndex: 'ativo',
      align: 'center',
      render: isAtivo => (
        <span>
          <Tag color={isAtivo ? 'green' : 'red'}>
            {isAtivo ? 'Ativo' : 'Inativo'}
          </Tag>
        </span>
      ),
    },
    {
      title: 'Ações',
      key: 'action',
      align: 'center',
      render: (text, record) => (
        <ContainerFlex>
          <Tooltip title="Editar">
            <Button
              type="primary"
              icon={<EditOutlined />}
              onClick={() => history.push(`/${modulo}/form/${record.key}`)}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            />
          </Tooltip>
          <Divider type="vertical" />

          <Tooltip title="Remover">
            <Popconfirm
              title="Deseja excluir o registro?"
              onConfirm={() => handleDelete(record.key)}
            >
              <Button
                type="danger"
                icon={<DeleteOutlined />}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              />
            </Popconfirm>
          </Tooltip>
        </ContainerFlex>
      ),
    },
  ];
};

