import {
  Form,
  Input,
  Col,
  Row,
  DatePicker,
  Select,
  Button as AntBtn,
} from 'antd';
import { PositionVehicle } from 'interfaces/vehicle';
import moment from 'moment';
import React from 'react';

interface filterProps {
  data: PositionVehicle[];
  setDataFiltered: (value: PositionVehicle[]) => void;
}

export default function Filter({ data, setDataFiltered }: filterProps) {
  const [form] = Form.useForm();
  const { RangePicker } = DatePicker;

  const dataSearch = (valuesSearch: any) => {
    if (
      (valuesSearch.placa ||
        valuesSearch.prefixo ||
        valuesSearch.agencia ||
        valuesSearch.date) == null
    ) {
      setDataFiltered(data);
    } else {
      const filters = {
        placa: valuesSearch.placa ? valuesSearch.placa.toUpperCase() : '',
        dataInicial:
          valuesSearch.date && valuesSearch.date.length !== 0
            ? valuesSearch.date[0]
            : null,
        dataFinal:
          valuesSearch.date && valuesSearch.date.length !== 0
            ? valuesSearch.date[1]
            : null,
        prefixo: valuesSearch.prefixo ? valuesSearch.prefixo.toUpperCase() : '',
        agencia: valuesSearch.agencia ? valuesSearch.agencia.toUpperCase() : '',
      };

      const dadosFiltred = data.filter(value => {
        let exist = true;
        if (
          !value.placa.includes(filters.placa) ||
          !value.prefixo.includes(filters.prefixo) ||
          !value.agencia.includes(filters.agencia)
        ) {
          exist = false;
        }
        if (
          filters.dataFinal &&
          filters.dataInicial &&
          !moment(value.datePosition).isBetween(
            filters.dataInicial,
            filters.dataFinal
          )
        ) {
          exist = false;
        }
        return exist;
      });

      dadosFiltred.sort((a, b) => {
        return a.placa < b.placa ? -1 : a.placa > b.placa ? 1 : 0;
      });

      setDataFiltered(dadosFiltred);
    }
  };

  const onReset = () => {
    form.resetFields();
    setDataFiltered(data);
  };

  return (
    <div
      style={{
        border: '1px solid #00aa3f',
        borderRadius: '15px',
        padding: '10px',
        marginBottom: '2%',
        fontWeight: 'bold',
      }}
    >
      <h1 style={{ justifySelf: 'center', width: '100%', textAlign: 'center' }}>
        Filtros
      </h1>
      <Form
        form={form}
        onFinish={dataSearch}
        layout="horizontal"
        style={{ padding: '20px' }}
      >
        <Row gutter={24} justify="center">
          <Col span={12}>
            <Form.Item label="Placa:" name="placa">
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Prefixo:" name="prefixo">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24} justify="center">
          <Col span={12}>
            <Form.Item label="Agencia:" name="agencia">
              <Select
                virtual={false}
                getPopupContainer={trigger => trigger.parentElement}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? '')
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={[
                  {
                    value: '',
                    label: '',
                  },
                  {
                    value: 'COPEC',
                    label: 'COPEC',
                  },
                  {
                    value: 'BMIS',
                    label: 'BMIS',
                  },
                  {
                    value: 'PC',
                    label: 'PC',
                  },
                  {
                    value: 'GM',
                    label: 'GM',
                  },
                  {
                    value: 'BMGSU',
                    label: 'BMGSU',
                  },
                  {
                    value: 'COMEL',
                    label: 'COMEL',
                  },
                  {
                    value: 'PM',
                    label: 'PM',
                  },
                  {
                    value: 'TRANS',
                    label: 'TRANS',
                  },
                  {
                    value: 'PME',
                    label: 'PME',
                  },
                  {
                    value: 'COPAC',
                    label: 'COPAC',
                  },
                  {
                    value: 'PRF',
                    label: 'PRF',
                  },
                  {
                    value: 'SAP',
                    label: 'SAP',
                  },
                ]}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Data" name="date">
              <RangePicker style={{ width: '100%' }} />
            </Form.Item>
          </Col>
        </Row>

        <Row style={{ justifyContent: 'center', marginBottom: '-3%' }}>
          <Form.Item style={{ padding: '10px' }}>
            <AntBtn
              // buttonStyle="btn--primary"
              // buttonSize="btn--small"
              htmlType="submit"
              style={{
                background: '#1a784a',
                color: '#fff',
                width: '200px',
                height: '40px',
                border: 'none',
                borderRadius: '50px',
                fontWeight: 500,
                fontSize: '1em',
              }}
            >
              Buscar
            </AntBtn>
          </Form.Item>

          <Form.Item style={{ padding: '10px' }}>
            <AntBtn
              // buttonStyle="btn--secondary"
              // buttonSize="btn--small"
              onClick={onReset}
              style={{
                background: '#fc6836',
                color: '#fff',
                width: '200px',
                height: '40px',
                border: 'none',
                borderRadius: '50px',
                fontWeight: 500,
                fontSize: '1em',
              }}
            >
              Limpar
            </AntBtn>
          </Form.Item>
        </Row>
      </Form>
    </div>
  );
}

