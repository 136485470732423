import { MonitoringContext } from 'context/monitoring';
import { useContextSelector } from 'use-context-selector';

export function useFilterInfo() {
  const loadingFences = useContextSelector(
    MonitoringContext,
    context => context.loadingFences
  );

  const activeAlert = useContextSelector(
    MonitoringContext,
    context => context.activeAlert
  );

  const filterAlertArray = useContextSelector(
    MonitoringContext,
    context => context.filterAlertArray
  );

  return {
    loadingFences,
    activeAlert,
    filterAlertArray,
  };
}

