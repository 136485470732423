import styled from 'styled-components';
import logoSecretaria from '../../imgs/rotas.png';
import logoMobile from '../../imgs/brasao_sspds.png';

export const Profile = styled.div`
  display: flex;
  align-items: center;
  border-left: 2px solid #fff;
  height: 30px;
  text-align: center;

  #userName {
    display: block;
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    padding-left: 20px;
  }

  div {
    text-align: right;
    margin-right: 10px;

    a {
      display: block;
      margin-top: 2px;
      font-size: 12px;
      color: #999;
    }
  }
`;

export const Container = styled.div`
  .labelEco {
    @media (max-width: 50em) {
      display: none !important;
    }
  }

  .logosEco svg {
    min-width: 26px;
    min-height: 27px;
  }

  .labelEcoMobile {
    @media (min-width: 50em) {
      display: none !important;
    }
  }

  .logosEco {
    @media (max-width: 50em) {
      margin: 0 auto !important;
    }
  }

  .governo {
    display: flex !important;
  }

  .profile {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-end;
    @media (max-width: 50em) {
      // display: none;
    }
  }

  .menu_browser {
    @media (max-width: 50em) {
      display: none;
    }
  }

  .menu_mobile {
    /* @media (min-width: 50em) {
      display: none;
    } */
  }

  .searchable {
    @media (max-width: 72em) {
      display: none;
    }
  }

  body {
    background: #e9e9e9;
    font-family: 'Raleway', sans-serif;
    margin: 0px;
    padding: 0px;
  }

  .standard_layout .content_contain {
    overflow: hidden;
    background: #e9e9e9;
  }

  .mark {
    margin-right: 1.25em;
  }

  @media (min-width: 50em) {
    .mark {
      display: none !important;
    }
  }

  .nav_contain {
    display: block;
    padding: 0.75em 0;
    text-align: center;
    ul {
      @media (min-width: 72em) {
        display: -webkit-box;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        padding: 0;
        margin: 0;
      }

      li {
        display: block;
        padding: 0.75em 0;
        text-align: center;
        a {
          position: relative;
          display: block;
          color: #090910;
          text-decoration: none;
          -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        }
        @media (min-width: 72em) {
          position: relative;
          margin-right: 8.5%;
          border-bottom: none;
        }
      }
    }

    @media (min-width: 72em) {
      max-height: 100vh;
      overflow: visible;
      opacity: 1;
      padding: 0;
      -webkit-transform: none;
      transform: none;
      margin-right: 300px;
    }
  }

  /* HEADER */

  header {
    position: relative;
    z-index: 1000;
    -webkit-transition: padding 0.3s ease;
    transition: padding 0.3s;

    height: 110px;
    width: 100%;

    display: flex;
    align-items: center;
    background-color: grey;

    .greenBar {
      background: linear-gradient(to right, #204d37, #00853b 40%);
      width: 100%;
      height: 80px;
      margin: 0;
      display: flex;
      justify-content: center;
      padding: 15px;
    }

    .contain {
      /* padding: 0 1em;
      max-width: 80em; */
      overflow-x: hidden;
      width: 100%;
    }

    &::before {
      content: '';
      position: absolute;
      z-index: -1;
      display: block;
      top: 0;
      left: 0;
      width: 100%;
      height: 85px;
      box-shadow: 0 10px 15px -8px rgba(9, 9, 16, 0);
      -webkit-transform: translateZ(0);
      transform: translateZ(0);
      -webkit-transition: box-shadow 0.6s ease;
      transition: box-shadow 0.6s ease;
    }

    .logoImg {
      content: url(${logoSecretaria});
      max-width: 348px;
      height: 64px;
    }

    @media (max-width: 620px) {
      .logoImg {
        content: url(${logoMobile});
        max-width: 45px;
        max-height: 61px;
      }
    }

    @media (max-width: 375px) {
      .ant-avatar-lg {
        width: 4em;
        height: 3em;
      }
    }
    .header_content_menu {
      display: -webkit-box;
      display: flex;
      -webkit-box-align: none;
      align-items: center;
      background-color: #fff;
      border-bottom: 3px solid #00bd19;
      padding: 15px;
      justify-content: space-between;
      height: 30px;
    }

    @media (max-width: 424px) {
      .baseOndas {
        display: none;
      }
      .header_content_menu {
        justify-content: center;
      }
    }

    .page_contain {
      max-width: 100em;
      margin: 0 auto;
      padding: 0 5.5%;
      margin-top: -150;
    }

    .search_box .input_group {
      padding: 0;
      width: 100%;
      -webkit-transition: width 0.5s ease;
      transition: width 0.5s ease;
      margin-right: 30;
    }
  }
  .baseOndas {
    position: absolute;
    padding-top: 15px;
  }

  .baseOndas img {
    height: 14px;
  }
`;

