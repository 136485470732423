import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Breadcrumb, Button, Form, Select, Spin } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { isValidCPF } from 'utils/functionsUtils';
import { ServiceCerca } from 'services/modulos/serviceCerca';
import { useGlobalFences } from 'hooks/global/useGlobalFences';
import { WarningOutlined } from '@ant-design/icons';
import { Container, FormContainer } from './styles';
import { Fence } from 'interfaces/fences';

const { Option } = Select;

type TForm = {
  cpfList: string[];
  cercasList: string[];
};

export const FormAtribuirCercas = () => {
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const cpfsList = useRef<string[]>([]);

  const { fences } = useGlobalFences();

  const onFinish = useCallback(async (values: TForm) => {
    try {
      setLoading(true);
      if (
        !values.cercasList ||
        !values.cercasList.length ||
        !values.cpfList ||
        !values.cpfList.length
      ) {
        throw new Error();
      }
      values.cpfList = values.cpfList.map(cpf =>
        cpf.replaceAll('.', '').replaceAll('-', '')
      );
      await ServiceCerca.shareAdminCercaToCPFList(values);
      form.resetFields();
      cpfsList.current = [];
      toast.success('Cercas compartilhadas');
    } catch (e) {
      toast.error('Erro ao compartilhar cercas, tente novamente');
    } finally {
      setLoading(false);
    }
  }, []);

  const formatTags = useCallback((newValues?: any) => {
    let formattedTags = [];
    if (newValues) {
      formattedTags = newValues.map((tag: any) =>
        tag.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
      );
    }

    return formattedTags;
  }, []);

  const handleSelectAlloweds: (cpf: string) => Promise<void> = useCallback(
    async (cpf: string) => {
      try {
        if (cpf && cpf.includes('list')) {
          let newList = cpf.split(' ');

          newList = newList.slice(1, newList.length);

          for (let i = 0; i < newList.length; ++i) {
            let currentCpf = newList[i].replace(/[^0-9 ]/g, '');

            if (!isValidCPF(currentCpf)) {
              throw new Error();
            }
            // const newAllowed = await ServiceCerca.searchByCPF(cpf);

            form.setFieldsValue({
              cpfList: formatTags([...cpfsList.current, currentCpf]),
            });

            cpfsList.current = [...cpfsList.current, currentCpf];
          }
        } else if (cpf) {
          cpf = cpf.replace(/[^0-9 ]/g, '');
          if (!isValidCPF(cpf)) {
            throw new Error();
          }
          // const newAllowed = await ServiceCerca.searchByCPF(cpf);

          form.setFieldsValue({
            cpfList: formatTags([...cpfsList.current, cpf]),
          });

          cpfsList.current = [...cpfsList.current, cpf];
        }
      } catch (e) {
        toast.error('CPF inválido!');
        form.setFieldsValue({
          cpfList: formatTags(cpfsList.current),
        });
      }
    },
    [cpfsList]
  );

  const handleDeselectAlloweds = (value: string) => {
    try {
      if (value) {
        value = value.replace(/[^0-9 ]/g, '');
        const newlist = cpfsList.current.filter(cpf => cpf !== value);

        form.setFieldsValue({
          allowedCpfs: formatTags(newlist),
        });

        cpfsList.current = newlist;
      }
    } catch (e) {
      //
    }
  };

  const sortFencesByName = (a: Fence, b: Fence) => {
    return a.nome > b.nome ? 1 : -1;
  };

  const fencesOptions = useMemo(() => {
    return fences.current.data?.sort(sortFencesByName).map(f => {
      return (
        <Option key={f.id} value={f.id}>
          {f.nome}
        </Option>
      );
    });
  }, [fences.current.data]);

  return (
    <Container>
      <Breadcrumb style={{ padding: '10px' }}>
        <Breadcrumb.Item>
          {/* @ts-expect-error Server Component */}
          <Link to="/">Página Inicial</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          {/* @ts-expect-error Server Component */}
          <Link to="/cerca">Cercas</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Compartilhar</Breadcrumb.Item>
      </Breadcrumb>
      <Spin spinning={loading}>
        <FormContainer>
          <div className="backButton" onClick={() => history.goBack()}>
            {'< Voltar'}
          </div>
          <div className="title">Compartilhar cercas</div>
          <div className="infoCard">
            <WarningOutlined className="icon" />
            <p>
              {' '}
              As cercas selecionadas serão atribuídas a todos os usuários (CPFs)
              que constarem na lista abaixo. Estes usuários devem possuir
              permissão de acesso as cercas eletrônicas.
            </p>
          </div>
          <Form
            layout="vertical"
            form={form}
            onFinish={onFinish}
            className="formClass"
          >
            <Form.Item name="cpfList" label="CPFs" className="itemsClass">
              <Select
                virtual={false}
                getPopupContainer={trigger => trigger.parentElement}
                mode="tags"
                // onKeyUp={e => handleChangeSelectInput(e)}
                onSelect={(value: any) => handleSelectAlloweds(value)}
                onDeselect={(value: any) => handleDeselectAlloweds(value)}
                style={{
                  width: '100%',
                }}
                // filterOption={(value, option) => {
                //   return cpfsList.current.includes(String(value || ''));
                // }}
                notFoundContent={null}
                popupClassName={'selectWithoutDropdown'}
              />
            </Form.Item>
            <Form.Item name="cercasList" label="Cercas" className="itemsClass">
              <Select
                virtual={false}
                getPopupContainer={trigger => trigger.parentElement}
                mode="multiple"
                showArrow
                filterOption={(input, option) => {
                  return Boolean(
                    option?.children &&
                      String(option.children || '')
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                  );
                }}
              >
                {fencesOptions}
              </Select>
            </Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className={loading ? 'submitButton loading' : 'submitButton'}
              disabled={loading}
            >
              {loading ? <Spin /> : 'Confirmar'}
            </Button>
          </Form>
        </FormContainer>
      </Spin>
    </Container>
  );
};

