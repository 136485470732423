/* eslint-disable */
import React, { useMemo } from 'react';
import { Form, Select, Row, Col } from 'antd';

import { Container } from './styles';

import Button from '../../../button';
import { v4 as uuidv4 } from 'uuid';
import { DataPosition } from 'interfaces/vehicle';
import { useDataAndSelectEfetivo } from 'hooks/monitoring/useDataAndSelectEfetivo';

const { Option } = Select;

export function FormBuscaViatura({
  onVisible,
}: {
  onVisible: (value: boolean) => void;
}) {
  const [form] = Form.useForm();

  const { data, handleSelectEfetivo } = useDataAndSelectEfetivo();

  const onFinish = async (values: { idDevice: string }) => {
    const { idDevice } = values;
    handleSelectEfetivo(idDevice);
    onVisible(false);
  };

  const inputPlacas = useMemo(
    () => (
      <>
        {data.map((veiculo: DataPosition) => {
          let value = veiculo.properties.placa;

          value +=
            veiculo.properties.prefixo &&
            veiculo.properties.prefixo !== veiculo.properties.placa
              ? ` - ${veiculo.properties.prefixo}`
              : '';

          return (
            <Option key={uuidv4()} value={veiculo.properties.idDevice}>
              {value}
            </Option>
          );
        })}
      </>
    ),
    [data]
  );

  const resetarCampos = () => {
    form.resetFields();
  };

  return (
    <Container>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        // onFinishFailed={onFinishFailed}
      >
        <Row>
          <Col offset={1}>
            <Form.Item
              label="Placa ou prefixo"
              name="idDevice"
              rules={[
                {
                  required: true,
                  message: 'Por favor, informe a placa ou prefixo!',
                },
              ]}
              required
            >
              <Select
                showSearch
                style={{ width: 200 }}
                virtual={false}
                mode="tags"
                notFoundContent={null}
                getPopupContainer={trigger => trigger.parentElement}
                placeholder="Informe a placa ou prefixo."
                filterOption={(input, option) => {
                  return Boolean(
                    option?.children &&
                      String(option.children || '')
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                  );
                }}
              >
                {inputPlacas}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row justify="space-around">
          <div className="grid-button">
            <Col>
              <Form.Item>
                <Button type="submit">Buscar</Button>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item>
                <Button
                  type="button"
                  buttonStyle="btn--secondary"
                  onClick={resetarCampos}
                >
                  Limpar
                </Button>
              </Form.Item>
            </Col>
          </div>
        </Row>
      </Form>
    </Container>
  );
}

