import React, { useCallback, useEffect, useState } from 'react';
import {
  Form,
  Input,
  Row,
  Select,
  Spin,
  Table,
  Modal,
  Typography,
  Col,
  Progress,
  Divider,
  DatePicker,
  Button as AntBtn,
  Breadcrumb,
} from 'antd';

import { v4 as uuidv4 } from 'uuid';
import { toast } from 'react-toastify';
import { Link, useLocation } from 'react-router-dom';
import { Printer } from 'phosphor-react';
import debounce from 'lodash.debounce';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import { GoogleMap, Marker } from '@react-google-maps/api';
// @ts-ignore
import Container from '../styles.ts';
import api from 'services/api';
import { templateColumns } from '../../columns/PDF/Personalizado/columns';
import Button from '../button';
import { LoadFormattedAddress, prefixoIsHT } from 'utils/functionsUtils';
import { pdfReport } from './pdfReport';
import { DataPosition, dataPropertiesAux } from 'interfaces/vehicle';
import { AgenciesProps } from 'interfaces/FiltersMapContext';
import { useAuthData } from 'hooks/auth/useAuthData';
import { useLoadLastPositions } from 'hooks/global/useLoadLastPositions';
import { useFiltersMapGroups } from 'hooks/filtersMap/useFiltersMapGroups';
import { ServiceExterno } from 'services/modulos/serviceExterno';
import ENV from 'config';

const { Option } = Select;

const { Text, Title } = Typography;

const csvHeader = [
  'Placa',
  'Prefixo',
  'AIS',
  'Última posição',
  'Latitude',
  'Longitude',
  'Status',
  'Logradouro',
];

const containerStyle = {
  width: '100%',
  height: '100%',
  borderRadius: 12,
};

interface TableProps {
  isLoaded: boolean;
}

export const TablePersonalizada = ({ isLoaded }: TableProps) => {
  const { data: dataAuth } = useAuthData();

  const { roles, client } = dataAuth || {};

  const location = useLocation();

  const { loadLastPositions } = useLoadLastPositions();
  const { groupsFilters } = useFiltersMapGroups();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [isModalRelOpen, setIsModalRelOpen] = useState(false);
  const [mapPosition, setMapPosition] = useState([0, 0]);
  const [address, setAddress] = useState('');
  const [loadingTable, setLoadingTable] = useState(false);

  const { RangePicker } = DatePicker;

  const [data, setData] = useState({
    originalData: [],
    currentData: [],
    totalElements: 0,
  });

  const [entidade, setEntidade] = useState({
    placa: '',
    prefixo: '',
    status: '',
    ais: '',
    dias: 10,
    dtInicio: '',
    dtFim: '',
    logradouro: '',
  });

  const { originalData, currentData } = data;

  const isRelatorio = Boolean(
    window.location.pathname === '/web/rotas/pdfPersonalizado'
  );

  const [form] = Form.useForm();

  const [csvData, setCsvData] = useState<string[][]>([]);
  const [reset, setReset] = useState(false);
  const [localDays, setLocalDays] = useState(10);
  const [daysWithouPosition, setDaysWithoutPosition] = useState(10);
  const [percent, setPercent] = useState(0);

  const rolesFiltered = (roles || []).map((r: string) =>
    r.replaceAll('ROLE_ROTAS_', '').replaceAll('_', ' ')
  );

  const groupsFiltered = groupsFilters.groups.filter((op: any) =>
    rolesFiltered.includes(op.nome)
  );

  const updateData = useCallback((newProperties: any) => {
    setData(prev => ({ ...prev, ...newProperties }));
  }, []);

  const handleVerifyConnected = useCallback(
    async (resumo: any) => {
      try {
        const auxData = resumo.map((p: DataPosition) => {
          const isConnected = Boolean(
            p.properties.statusConexaoViatura &&
              p.properties.statusConexaoViatura !== 'DESCONECTADA'
          );

          return {
            ...p.properties,
            conectado: isConnected,
          };
        });

        updateData({
          originalData: auxData,
          currentData: auxData,
          totalElements: auxData.length,
        });

        setCsvData(
          auxData.map((v: any) => {
            let statusFormated = '';

            if (
              (v.prefixo && prefixoIsHT(v.prefixo)) ||
              (!v.prefixo && prefixoIsHT(v.placa))
            ) {
              statusFormated = 'RÁDIO';
            } else if (!v.conectado) {
              statusFormated = 'DESCONECTADA';
            } else {
              statusFormated = v.statusConexaoViatura;
            }

            return [
              v.placa,
              v.prefixo,
              v.grupo,
              v.datePosition,
              v.latitude,
              v.longitude,
              statusFormated,
              v.logradouro,
            ];
          })
        );
      } catch (err) {
        toast.error('Erro ao carregar veículos, tente novamente.');
      }
    },

    [updateData]
  );

  const loadVeiculos = useCallback(async () => {
    try {
      setLoadingTable(true);

      const lastPositions = await loadLastPositions();

      const veiculosFilter = (lastPositions || []).filter(v => {
        if (v.datePosition) {
          const now = new Date();

          const dateAtual = new Date(
            now.getFullYear(),
            now.getMonth(),
            now.getDate()
          );

          dateAtual.setDate(dateAtual.getDate() - daysWithouPosition);

          if (v.prefixo && prefixoIsHT(v.prefixo)) {
            const dateAtualMinutos = new Date(
              now.getFullYear(),
              now.getMonth(),
              now.getDate(),
              now.getHours(),
              now.getMinutes()
            );

            dateAtualMinutos.setMinutes(dateAtualMinutos.getMinutes() - 30);

            return (
              v.latitude &&
              v.longitude &&
              new Date(v.datePosition) > dateAtualMinutos
            );
          }

          return (
            v.latitude && v.longitude && new Date(v.datePosition) > dateAtual
          );
        }
      });

      const resumo = [];

      const times = veiculosFilter.length / 1000 + 1;

      for (let i = 0; i < times; i += 1) {
        const slicedArray = veiculosFilter.slice(
          i * 1000,
          Math.min((i + 1) * 1000, veiculosFilter.length)
        );

        resumo.push(
          ...(await Promise.all(
            slicedArray.map(async v => {
              const latLng = {
                lat: v.latitude,
                lng: v.longitude,
              };

              return {
                type: 'Feature',
                properties: {
                  id: uuidv4(),
                  ...v,
                  latLng,
                },
                geometry: {
                  type: 'Point',
                  coordinates: [latLng.lng, latLng.lat],
                },
              };
            })
          ))
        );
      }

      try {
        await handleVerifyConnected(resumo);
      } catch (e) {
        loadVeiculos();
        console.error(e);
      }
    } catch (err) {
      // loadVeiculos();

      console.error(err);
    } finally {
      setLoadingTable(false);
    }
  }, [handleVerifyConnected, daysWithouPosition, loadLastPositions]);

  const resetFields = () => {
    setReset(prev => !prev);

    updateData({ currentData: originalData });
  };

  const debouncedSave = useCallback(
    debounce((value: number) => setDaysWithoutPosition(value), 2000),
    []
  );

  const handleChangeDays = (event: any) => {
    const { value } = event.target;

    if (value < 1) {
      toast.error('O valor mínimo para pesquisa é de 1 dia');
    } else if (value > 30) {
      toast.error('O valor máximo para pesquisa é de 30 dias');
    } else {
      setLocalDays(value);

      debouncedSave(value);
    }
  };

  const checkAllBlank = (values: any) => {
    let dataInicial = null;

    let dataFinal = null;

    if (values.date) {
      dataInicial = values.date[0];

      dataFinal = values.date[1];
    }

    if (
      (!values.placa || values.placa === '') &&
      (!values.prefixo || values.prefixo === '') &&
      (!values.status || values.status === '') &&
      (!values.ais || values.ais === '') &&
      (!values.logradouro || values.logradouro === '') &&
      (!dataInicial || dataInicial === '') &&
      (!dataFinal || dataFinal === '')
    ) {
      return true;
    }

    return false;
  };

  const transformData = (values: any) => {
    if (values.placa) {
      values.placa = values.placa.toLowerCase();
    }

    if (values.prefixo) {
      values.prefixo = values.prefixo.toLowerCase();
    }

    return {
      ...values,
      placa: values.placa || '',
      prefixo: values.prefixo || '',
    };
  };

  const onFinish = useCallback(
    (values: any) => {
      let auxArray: (dataPropertiesAux & {
        logradouro: string;
      })[] = originalData;

      let dataInicial: string = '';
      let dataFinal: string = '';

      if (values.date) {
        dataInicial = moment(values.date[0])
          .format()
          .substring(0, 10);

        dataFinal = moment(values.date[1])
          .format()
          .substring(0, 10);
      }

      if (checkAllBlank(values)) {
        updateData({ currentData: originalData });
      } else {
        values = transformData(values);

        if (values.placa)
          auxArray = auxArray.filter(
            v => v.placa && v.placa.toLowerCase().includes(values.placa)
          );

        if (values.prefixo)
          auxArray = auxArray.filter(
            v => v.prefixo && v.prefixo.toLowerCase().includes(values.prefixo)
          );

        if (values.status) {
          if (values.status === 'RÁDIO') {
            auxArray = auxArray.filter(
              v =>
                (v.prefixo && prefixoIsHT(v.prefixo)) ||
                (!v.prefixo && prefixoIsHT(v.placa))
            );
          } else {
            auxArray = auxArray.filter(
              v =>
                (v.prefixo && !prefixoIsHT(v.prefixo)) ||
                (!v.prefixo && !prefixoIsHT(v.placa))
            );
          }

          if (values.status === 'DESCONECTADA') {
            auxArray = auxArray.filter(v => !v.statusConexaoViatura);
          } else {
            auxArray = auxArray.filter(
              v =>
                v.statusConexaoViatura &&
                v.statusConexaoViatura === values.status
            );
          }
        }

        if (values.ais)
          auxArray = auxArray.filter(v => {
            return v.grupo && v.grupo === values.ais.replace(/\s/g, '');
          });

        if (dataInicial) {
          if (dataInicial === dataFinal) {
            auxArray = auxArray.filter(v => {
              if (
                moment(v.datePosition)
                  .format()

                  .substring(0, 10) === dataInicial
              ) {
                return true;
              }
            });
          } else {
            auxArray = auxArray.filter(v =>
              moment(v.datePosition).isSameOrAfter(dataInicial)
            );
          }
        }

        if (dataFinal) {
          if (dataInicial === dataFinal) {
            auxArray = auxArray.filter(v => {
              if (
                moment(v.datePosition)
                  .format()

                  .substring(0, 10) === dataInicial
              ) {
                return true;
              }
            });
          } else {
            auxArray = auxArray.filter(v =>
              moment(v.datePosition).isSameOrBefore(dataFinal)
            );
          }
        }

        auxArray.sort((a, b) => {
          return a.placa < b.placa ? -1 : a.placa > b.placa ? 1 : 0;
        });

        updateData({ currentData: auxArray });
      }

      setCsvData(
        auxArray.map(v => {
          let statusFormated = '';

          if (
            (v.prefixo && prefixoIsHT(v.prefixo)) ||
            (!v.prefixo && prefixoIsHT(v.placa))
          ) {
            statusFormated = 'RÁDIO';
          } else if (!v.conectado) {
            statusFormated = 'DESCONECTADA';
          } else {
            statusFormated = v.statusConexaoViatura || '';
          }

          return [
            v.placa || '',
            v.prefixo || '',
            v.grupo || '',
            v.datePosition || '',
            String(v.latitude),
            String(v.longitude),
            statusFormated,
            v.logradouro || '',
          ];
        })
      );
    },

    [originalData, updateData]
  );

  const returnAddress = async (lat: number, lng: number) => {
    const address = await LoadFormattedAddress(lat, lng);

    setAddress(address);
  };

  useEffect(() => {
    if (isRelatorio) {
      loadVeiculos();
    }
  }, [isRelatorio, loadVeiculos]);

  useEffect(() => {
    form.setFieldsValue(entidade);
  }, [entidade, form, reset]);

  useEffect(() => {
    if (mapPosition[0] != 0 || mapPosition[1] != 0) {
      if (isModalOpen) {
        returnAddress(mapPosition[0], mapPosition[1]);
      }
    }
  }, [isModalOpen, mapPosition, returnAddress]);

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const loadCsvData = async () => {
    setIsModalRelOpen(true);

    setIsLoadingData(true);

    const reportData = [];

    const range = csvData.length;

    let per = 0;

    let counter = 0;

    try {
      // Registrando usuário que está gerando relatório
      ServiceExterno.generateReport({
        name: client.name,
        cpf: client.cpf,
        externPath: 'Google Geocoding API',
        pathName: location.pathname,
      });

      const times = range / 1000 + 1;

      for (let i = 0; i < times; i += 1) {
        const slicedArray = csvData.slice(
          i * 1000,

          Math.min((i + 1) * 1000, range)
        );

        reportData.push(
          ...(await Promise.all(
            slicedArray.map(async x => {
              const currentLogradouro = await LoadFormattedAddress(
                Number(x[4]),
                Number(x[5])
              );

              x[7] = currentLogradouro;

              counter += 1;

              per = (counter * 100) / range;

              // @ts-ignore
              setPercent(parseInt(per));

              if (counter >= range) {
                setIsLoadingData(false);
              }

              return x;
            })
          ))
        );
      }
    } catch (err) {
      console.log(err);

      setIsLoadingData(false);
    }
  };

  const gerarPdf = () => {
    pdfReport(csvData);

    setIsModalRelOpen(false);
  };

  return (
    <Container>
      <Breadcrumb style={{ padding: '10px' }}>
        <Breadcrumb.Item>
          {/* @ts-ignore */}
          <Link to="/" title="">
            Página Inicial
          </Link>
        </Breadcrumb.Item>

        <Breadcrumb.Item>
          {/* @ts-ignore */}
          <Link to="/relatorios">Relatorios</Link>
        </Breadcrumb.Item>

        <Breadcrumb.Item>Personalizado</Breadcrumb.Item>
      </Breadcrumb>

      <div className="filtrosContainer">
        <Form
          form={form}
          // className="filtrosForm"

          onFinish={onFinish}
          layout="horizontal"
          style={{ padding: '20px' }}

          // id="filters"
        >
          <h1 className="titleForm">Filtros</h1>

          <Row className="filtrosForm" justify="center" gutter={24}>
            <Col span={10}>
              <Form.Item label="Placa" name="placa">
                <Input />
              </Form.Item>
            </Col>

            <Col span={10}>
              <Form.Item label="Prefixo" name="prefixo">
                <Input />
              </Form.Item>
            </Col>

            <Col span={4}>
              <Form.Item label="AIS" name="ais">
                <Select
                  getPopupContainer={trigger => trigger.parentElement}
                  showSearch
                  virtual={false}
                >
                  <Option key="0" value="">
                    {''}
                  </Option>

                  {groupsFiltered.map((g: AgenciesProps) => (
                    <Option key={g.id} value={g.nome}>
                      {g.nome}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            {/* <Form.Item

              className="formItemClass"

              label="Posição nos últimos dias"

              name="dias"
            >
              <InputNumber />

            </Form.Item> */}
          </Row>

          <Row justify="center" gutter={24}>
            <Col span={12}>
              <Form.Item
                label={
                  <label>
                    <strong> Status</strong>
                  </label>
                }
                name="status"
              >
                <Select
                  getPopupContainer={trigger => trigger.parentElement}
                  showSearch
                  virtual={false}
                >
                  <Option key="0" value="">
                    {''}
                  </Option>

                  <Option key="1" value="CHEGOU NO LOCAL">
                    CHEGOU NO LOCAL
                  </Option>

                  <Option key="2" value="DESCONECTADA">
                    DESCONECTADA
                  </Option>

                  <Option key="3" value="DESPACHADO">
                    DESPACHADO
                  </Option>

                  <Option key="4" value="DISPONÍVEL">
                    DISPONÍVEL
                  </Option>

                  <Option key="5" value="DISPONÍVEL COM TERMINAL DE BORDO">
                    DISPONÍVEL COM TERMINAL DE BORDO
                  </Option>

                  <Option key="6" value="EM ROTA">
                    EM ROTA
                  </Option>

                  <Option key="7" value="EM TRANSPORTE">
                    EM TRANSPORTE
                  </Option>

                  <Option key="8" value="MANUTENÇÃO">
                    MANUTENÇÃO
                  </Option>

                  <Option key="9" value="RÁDIO">
                    RÁDIO
                  </Option>
                </Select>
              </Form.Item>
            </Col>

            <Col span={12} style={{ paddingRight: '34px' }}>
              <Form.Item
                label={
                  <label>
                    <strong>Data</strong>
                  </label>
                }
                name="date"
              >
                <RangePicker style={{ width: '100%' }} />
              </Form.Item>
            </Col>
          </Row>

          <Row className="slotBotoes2">
            <Form.Item className="slotBotoesButton">
              <Button type="submit">Buscar</Button>
            </Form.Item>

            <Form.Item className="slotBotoesButton">
              <Button
                type="reset"
                buttonStyle="btn--secondary"
                onClick={resetFields}
              >
                Limpar
              </Button>
            </Form.Item>
          </Row>
        </Form>
      </div>

      <Modal
        open={isModalRelOpen}
        destroyOnClose
        onCancel={() => {
          setIsModalRelOpen(false);
        }}
        footer={null}
      >
        <Row>
          <Printer size={32} weight="light" />

          <Title
            level={4}
            style={{
              verticalAlign: 'middle',
            }}
          >
            {' '}
            Gerar Relatório
          </Title>
        </Row>

        <Divider />

        {isLoadingData ? (
          <div>
            <Row
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
              }}
            >
              <Progress type="circle" strokeColor="#006B35" percent={percent} />
            </Row>

            <Row
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
              }}
            >
              {' '}
              Carregando dados...
            </Row>
          </div>
        ) : (
          <Row>
            <Col span={12}>
              <AntBtn onClick={gerarPdf}>Gerar PDF</AntBtn>
            </Col>

            <Col span={12}>
              <AntBtn>
                {/* @ts-ignore */}
                <CSVLink
                  headers={csvHeader}
                  data={csvData || []}
                  filename="Planilha Personalizada"
                  target="_blank"
                >
                  Gerar CSV
                </CSVLink>
              </AntBtn>
            </Col>
          </Row>
        )}
      </Modal>

      <div className="daysWithoutLocationContainer">
        {loadingTable ? (
          <Spin size="small" className="slotBtnDocCsvPersonalizado" />
        ) : (
          <AntBtn
            className="slotBtnDocCsvPersonalizado"
            onClick={loadCsvData}
            style={{
              padding: '5px',
              background: 'white',
              border: '1px solid rgb(26, 120, 74)',
            }}
          >
            Gerar Relatório
          </AntBtn>
        )}

        <div className="ant-col ant-form-item-label">
          <span className="ant-form-item-required">Posição nos últimos</span>
        </div>

        <span>
          {loadingTable && (
            <Spin
              style={{
                position: 'absolute',
                top: '10px',
                right: '30px',
                width: '55px',
                height: '30px',
                background: 'rgba(240, 240, 240, .5)',
              }}
            />
          )}
          <input
            disabled={loadingTable}
            style={{
              height: 30,
              width: '50px',
              padding: 5,
              margin: '5px',
              border: '0px solid #d9d9d9',
              borderBottom: '3px solid black',
            }}
            value={localDays}
            onChange={e => handleChangeDays(e)}
            type="number"
            min={0}
            id="dias"
          />
        </span>

        <div className="ant-col ant-form-item-label">
          <span className="ant-form-item-required">dias</span>
        </div>
      </div>

      <div>
        <Modal
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={null}
          width={1000}
        >
          <Row
            style={{
              marginTop: '1rem',

              marginBottom: '1rem',
            }}
          >
            <Text strong>Endereço: </Text>

            <Text italic>{address}</Text>
          </Row>

          <Row>
            <div
              style={{
                width: 1000,

                height: 450,
              }}
            >
              {isLoaded && (
                <GoogleMap
                  id={ENV.mapId}
                  mapContainerStyle={containerStyle}
                  center={{
                    lat: mapPosition[0],

                    lng: mapPosition[1],
                  }}
                  zoom={15}
                >
                  <Marker
                    position={{
                      lat: mapPosition[0],

                      lng: mapPosition[1],
                    }}
                  />
                </GoogleMap>
              )}
            </div>
          </Row>
        </Modal>
      </div>

      <Table
        className="tablePersonalizada"
        loading={loadingTable}
        dataSource={currentData}
        // @ts-ignore
        columns={templateColumns({ setIsModalOpen, setMapPosition })}
        rowKey="id"
      />
    </Container>
  );
};

