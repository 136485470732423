import { MonitoringContext } from 'context/monitoring';
import { useContextSelector } from 'use-context-selector';

export function usePanelInfo() {
  const panelAlertsFiltered = useContextSelector(
    MonitoringContext,
    context => context.panelAlertsFiltered
  );

  return {
    panelAlertsFiltered,
  };
}

