import { AuthContext } from 'context/auth';
import { globalContext } from 'context/global';
import { useContextSelector } from 'use-context-selector';

export function useGlobalLoadFences() {
  const loadFences = useContextSelector(
    globalContext,
    context => context.loadFences
  );

  return {
    loadFences,
  };
}

