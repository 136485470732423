import React, { MutableRefObject } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { Avatar, Dropdown } from 'antd';
import {
  HomeOutlined,
  LogoutOutlined,
  RightOutlined,
  LeftOutlined,
  DownOutlined,
  UpOutlined,
} from '@ant-design/icons';
import { v4 as uuidv4 } from 'uuid';
import { Container, MenuContainer, UserDetails } from './styles';

import Alerta from './alerta/alerta';
import { GruposVeiculos } from './gruposVeiculos';
import PosicoesEfetivo from './posicoesEfetivo/index';
import PrefixosHistorico from './selectPrefixosHistorico';
import { Alert } from 'interfaces/alerts';
import moment from 'moment';
import { usePanelInfo } from 'hooks/monitoring/usePanelInfo';
import { useAuthDataFunctions } from 'hooks/auth/useAuthDataFunctions';
import { useHistoryDataType } from 'hooks/history/useHistoryDataType';
import { useHistoryData } from 'hooks/history/useHistoryData';

export interface PanelProps {
  clickedAlerts: MutableRefObject<string[]>;
  collapsed: boolean;
  setCollapsed: (value: boolean) => void;
}

export default function Painel({
  clickedAlerts,
  collapsed,
  setCollapsed,
}: PanelProps) {
  const history = useHistory();
  const { data: dataAuth, signOut } = useAuthDataFunctions();

  const { panelAlertsFiltered } = usePanelInfo();

  const { name, institution, image } = dataAuth?.client || {};

  const { dataHistorico } = useHistoryData();
  const { dataType } = useHistoryDataType();

  let nome = name;
  let nomeUser = nome;

  // eslint-disable-next-line no-restricted-globals, prefer-destructuring
  const width = screen.width;

  const arrayNome = (name || '').split(' ');

  if (arrayNome.length > 1) {
    nome = `${arrayNome[0]} ${arrayNome[arrayNome.length - 1]}`;

    nomeUser = `${arrayNome[0].substr(0, 1)}${arrayNome[
      arrayNome.length - 1
    ].substr(0, 1)}`;
  }

  const menu = (
    <MenuContainer>
      <div className="MenuItemUser">
        <div
          style={{
            display: 'flex',
            width: '25%',
            alignItems: 'center',
            margin: '11px 40% auto',
          }}
        >
          <Avatar
            src={image}
            size={60}
            style={{
              backgroundColor: '#f56a00',

              verticalAlign: 'middle',
            }}
          >
            {nomeUser}
          </Avatar>
        </div>

        <UserDetails>
          <div>
            <strong>{nome}</strong>
          </div>

          <div>
            <small>{institution ? institution.title : ''}</small>
          </div>

          <div className="item1">
            <button
              id="btnLogout"
              title="Home"
              type="button"
              onClick={() => history.push('/')}
            >
              <HomeOutlined style={{ fontSize: '15px', paddingRight: '5px' }} />
              INÍCIO
            </button>

            <button
              id="btnLogout"
              title="Sair"
              type="button"
              onClick={() => signOut()}
            >
              <LogoutOutlined
                style={{ fontSize: '15px', paddingRight: '5px' }}
              />
              SAIR
            </button>
          </div>
        </UserDetails>
      </div>
    </MenuContainer>
  );

  const handleClickAlert = (alert: string) => {
    if (clickedAlerts.current.includes(alert)) {
      clickedAlerts.current = clickedAlerts.current.filter(a => a !== alert);
    } else {
      clickedAlerts.current.push(alert);
    }
  };

  const sortAlerts = (a: Alert, b: Alert) => {
    if (moment(a.datePosition).isBefore(moment(b.datePosition))) {
      return 1;
    }
    return -1;
  };

  return (
    <Container>
      <div className="circularAvatarMobile">
        <Dropdown overlay={menu} placement="bottomRight" trigger={['click']}>
          <a onClick={e => e.preventDefault()}>
            <Avatar
              src={image}
              size={40}
              style={{
                backgroundColor: '#f56a00',
              }}
            >
              {nomeUser}
            </Avatar>
          </a>
        </Dropdown>
      </div>
      {collapsed ? (
        <div
          className="aparecePainel"
          style={{
            display: 'flex',
            flexDirection: 'column',
            height: '100vh',
            width: '20vw',
            minWidth: '250px',
            float: 'right',
          }}
        >
          {width > 768 && (
            <div>
              <div className="cabecalhoPainel">
                {/* @ts-expect-error Server Component */}
                <Link to="/">
                  <div className="titleSistema">
                    {window.location.pathname ===
                    '/web/rotas/mapa/monitoramento'
                      ? 'Monitoramento'
                      : 'Histórico'}
                  </div>
                </Link>

                <div className="circularAvatar">
                  <Dropdown
                    overlay={menu}
                    placement="bottomRight"
                    trigger={['click']}
                  >
                    <a onClick={e => e.preventDefault()}>
                      <Avatar
                        src={image}
                        size={40}
                        style={{
                          backgroundColor: '#f56a00',
                        }}
                      >
                        {nomeUser}
                      </Avatar>
                    </a>
                  </Dropdown>
                </div>
              </div>
              <div className="line-orange" />
            </div>
          )}
          <div className="titleMobile">
            <p>
              {window.location.pathname === '/web/rotas/mapa/monitoramento'
                ? 'Monitoramento'
                : 'Histórico'}
            </p>
          </div>

          {window &&
            window.location.pathname === '/web/rotas/mapa/monitoramento' && (
              <div className="componentesPainel">
                <GruposVeiculos />
                {/* <CardVeiculo /> */}
                {width <= 768 && (
                  <div className="contentAlertas">
                    {panelAlertsFiltered.sort(sortAlerts).map(alert => (
                      <Alerta
                        key={uuidv4()}
                        alert={alert}
                        onClick={handleClickAlert}
                        isOpen={clickedAlerts.current.includes(alert.idDevice)}
                      />
                    ))}
                  </div>
                )}
              </div>
            )}

          {window &&
            window.location.pathname === '/web/rotas/mapa/historico' &&
            dataType.current === 'historicoIndividual' &&
            dataHistorico &&
            dataHistorico.length > 0 && (
              <div className="componentesPainel">
                <PosicoesEfetivo />
              </div>
            )}

          {window &&
            window.location.pathname === '/web/rotas/mapa/historico' &&
            (dataType.current === 'auditoria' || dataType.current === 'raio') &&
            dataHistorico &&
            dataHistorico.length > 0 && (
              <div className="componentesPainel">
                <PrefixosHistorico />
              </div>
            )}
          {width > 768 && (
            <div className="contentAlertas">
              {window.location.pathname === '/web/rotas/mapa/monitoramento' &&
                panelAlertsFiltered
                  .sort(sortAlerts)
                  .map(alert => (
                    <Alerta
                      key={uuidv4()}
                      alert={alert}
                      onClick={handleClickAlert}
                      isOpen={clickedAlerts.current.includes(alert.idDevice)}
                    />
                  ))}
            </div>
          )}
          <div>
            {width <= 768 ? (
              <DownOutlined
                onClick={_ => setCollapsed(false)}
                style={{
                  position: 'absolute',
                  top: '8px',
                  width: '100%',
                  backgroundColor: 'white',
                }}
              />
            ) : (
              <RightOutlined
                onClick={_ => setCollapsed(false)}
                style={{
                  position: 'absolute',
                  bottom: '1px',
                  width: '100%',
                  backgroundColor: 'white',
                }}
              />
            )}
          </div>
        </div>
      ) : (
        <div className="hidePainel">
          <div
            className="cabecalhoPainel"
            style={{
              height: '100%',
              justifyContent: 'space-between',
              flexDirection: 'column',
            }}
          >
            <div className="circularAvatar">
              <Dropdown
                overlay={menu}
                placement="bottomRight"
                trigger={['click']}
              >
                <a onClick={e => e.preventDefault()}>
                  <Avatar
                    src={image}
                    size={40}
                    style={{
                      backgroundColor: '#f56a00',
                    }}
                  >
                    {nomeUser}
                  </Avatar>
                </a>
              </Dropdown>
            </div>
            <div>
              {width <= 768 ? (
                <UpOutlined onClick={_ => setCollapsed(true)} />
              ) : (
                <LeftOutlined onClick={_ => setCollapsed(true)} />
              )}
            </div>
          </div>
        </div>
      )}
    </Container>
  );
}
