import React, { PureComponent, useEffect, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import { Item } from './Item';
import Resizer from 'react-image-file-resizer';
import { printData, pdfGen } from './reports';
import '../styles.css';
import { ServiceVeiculo } from '~/services/modulos/service_veiculo';
import './styles.css';
import { CSVLink } from 'react-csv';

let dadosPdfPai;
let info;
let printUri;
let dados;
let csvHeader;
var hasData;
var csvData;
const _separador = {
  inicial: 15,
  padrao: 16,
};
const _pagina = {
  total: 1,
  atual: 1,
};
// console.log(window);

export const PdfHistoricoAuditoria = () => {
  const componentRef = useRef();
  const [resizedImage, setResizedImage] = useState();
  const [loadingTable, setLoadingTable] = useState(false);

  const chamaImpressao = useReactToPrint({
    content: () => componentRef.current,
  });

  const preparaImpressao = () => {
    const slotImpressao = document.getElementById('slotImpressao');
    slotImpressao.classList.add('elementoImpressao');
    chamaImpressao();
  };

  const sessaoDados = dados => {
    const _chave = 'dadosHistoricoRaio';

    if (dados) {
      sessionStorage.setItem(`rotas/${_chave}`, JSON.stringify(dados));
    } else {
      return JSON.parse(sessionStorage.getItem(`rotas/${_chave}`));
    }
  };

  const sessaoDadosInfo = dados => {
    const _chave = 'dadosHistoricoRaioInfo';

    if (dados) {
      sessionStorage.setItem(`rotasa/${_chave}`, JSON.stringify(dados));
    } else {
      return JSON.parse(sessionStorage.getItem(`rotasa/${_chave}`));
    }
  };

  const sessaoDadosImage = dados => {
    const _chave = 'dadosHistoricoRaioImage';

    if (dados) {
      sessionStorage.setItem(`rotasa/${_chave}`, JSON.stringify(dados));
    } else {
      return JSON.parse(sessionStorage.getItem(`rotasa/${_chave}`));
    }
  };

  useEffect(() => {
    if (
      window.navigator &&
      window.navigator.dadosPdf &&
      window.navigator.image
    ) {
      dadosPdfPai = window.navigator.dadosPdf;
      info = window.navigator.info;
      setResizedImage(window.navigator.image);

      Resizer.imageFileResizer(
        window.navigator.image,
        1180,
        580,
        'PNG',
        100,
        90,
        uri => {
          setResizedImage(uri);
          printUri = uri;
        },
        'base64'
      );

      sessaoDados(dadosPdfPai);
      sessaoDadosInfo(info);
      sessaoDadosImage(window.navigator.image);
    } else {
      dadosPdfPai = sessaoDados();
      info = sessaoDadosInfo();
      setResizedImage(sessaoDadosImage());
    }
    printData(dadosPdfPai, printUri);
    hasData = dadosPdfPai.length > 0 ? true : false;

    csvData = dadosPdfPai.map(index => {
      return [
        index.placa,
        index.prefixo,
        index.position.x.toString(),
        index.position.y.toString(),
        index.speed.toString(),
        index.datePosition,
      ];
    });
  }, []);

  csvHeader = [
    'Placa',
    'Prefixo',
    'Latitude',
    'Longitude',
    'Velocidade',
    'Posição',
    //'Logradouro'
  ];

  //console.log(csvData)

  return (
    <>
      <div className="slotBtnDoc">
        <button type="button" disabled={!hasData} onClick={pdfGen}>
          Gerar PDF
        </button>
      </div>

      <CSVLink
        headers={csvHeader}
        data={csvData === undefined ? '' : csvData}
        filename={'Planilha Historico de Auditoria'}
        className="slotBtnDocCsv"
        target="_blank"
      >
        <button type="button" disabled={!hasData}>
          Gerar CSV
        </button>
      </CSVLink>

      {resizedImage && (
        <PdfImprimir
          dadosPdfPai={dadosPdfPai}
          info={info}
          image={resizedImage}
          ref={componentRef}
        />
      )}
    </>
  );
};

export class PdfImprimir extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      txtData: {
        inicio: undefined,
        fim: undefined,
      },
      veiculos: [],
    };
  }

  componentDidMount() {
    const { state, props } = this;
    let { txtData } = { ...state };
    const { dadosPai } = props;

    ServiceVeiculo.resumoVeiculo().then(veiculos => {
      this.setState({ veiculos });
    });

    this.setState({ dadosPdfPai: { ...dadosPdfPai }, txtData: { ...txtData } });
  }

  sessaoDados = dados => {
    const _chave = 'dadosHistoricoIdentificador';

    if (dados) {
      sessionStorage.setItem(`rotas/${_chave}`, JSON.stringify(dados));
    } else {
      return JSON.parse(sessionStorage.getItem(`rotas/${_chave}`));
    }
  };
  render() {
    const { state, props } = this;
    const { dadosPdfPai, info, image } = props;
    printUri = props.image;

    printData(dadosPdfPai, image);

    const { txtData } = state;
    /* const _txtData = {
      inicio: `${new Date(
        dadosPdfPai.periodoPesquisado.dataInicio
      ).toLocaleDateString()} ${new Date(
        dadosPdfPai.periodoPesquisado.dataInicio
      ).toLocaleTimeString()}`,
      fim: `${new Date(
        dadosPdfPai.periodoPesquisado.dataFim
      ).toLocaleDateString()} ${new Date(
        dadosPdfPai.periodoPesquisado.dataFim
      ).toLocaleTimeString()}`,
    }; */

    let _contador = 2;
    let _numeroPagina = 1;
    _pagina.atual = 1;

    let _numeroItens = dadosPdfPai.length - _separador.inicial;

    if (_numeroItens > 0) {
      let _totalPaginas = 1;

      _totalPaginas += Math.ceil(_numeroItens / _separador.padrao);
      _pagina.total = _totalPaginas;
    }

    const plusContador = () => {
      return _contador++;
    };

    const plusPagina = () => {
      return (_numeroPagina += 1);
    };

    const Image = () => (
      <img
        src={image}
        alt="Image histórico"
        className="pdfAuditoriaImageRotate"
      />
    );

    return (
      <div className="corpoAbaImpressao">
        <div id="slotImpressao" className="slotsImpressao secaoImpressao">
          <div className="espacoUtilImpressao">
            <div className="cabecalhoImpressao">
              <span className="slotBrasaoImpressao">
                <img
                  alt="brasaoCentral"
                  src={require('~/imgs/Brasao_sspds_2022_lateral.png')}
                  className="logoPdfAuditoria"
                />
              </span>
            </div>

            <div className="corpoImpressao">
              {/* <div
                id="separator"
                style={{
                  height: `${29 * (16 - (_numeroItens % _separador.padrao))}px`,
                }}
              ></div> */}
              <div className="imageContainer">
                <Image />
              </div>
              <br />
              <h2>Relatório histórico de Auditoria</h2>
              {/* <p>
                Segue abaixo a lista com informações de referências geográficas
                entre os períodos {txtData.inicio} e {txtData.fim}.
              </p> */}

              <span className="veiculoImpressao">
                <p>
                  Dados de posicionamento fornecidos pelas empresas{' '}
                  <i>Oi/CSBrasil</i>
                </p>
              </span>
              <br />
              <div className="slotListaImpressao">
                <ul className="listaImpressao">
                  <li className="itemListaImpressaoAux cabecalhoListaImpressao">
                    <p className="cabPlacaAud">Placa/Pref.</p>
                    <p className="cabLatitudeAud">Latitude</p>
                    <p className="cabLongitudeAud">Longitude</p>
                    {/* <p className="cabMotorAud">Mot.</p> */}
                    <p className="cabVelocidadeAud">Vel.</p>
                    <p className="cabPosicaoAud">Posição</p>
                    {/*  <p className="celDtPos">Odometro</p> */}
                    <p className="cabLogradouroAud nomeItemLista">Logradouro</p>
                  </li>
                  {dadosPdfPai.map(d => (
                    <Item
                      veiculos={this.state.veiculos}
                      d={d}
                      key={d.dataPosicao}
                      plus={plusContador}
                      plusPagina={plusPagina}
                      _numeroPagina={_numeroPagina}
                      _separador={_separador}
                      _contador={_contador++}
                      dadosPdfPai={dadosPdfPai}
                    />
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
