import { AuthContext } from 'context/auth';
import { useContextSelector } from 'use-context-selector';

export function useAuthFunctions() {
  const signIn = useContextSelector(AuthContext, context => context.signIn);
  const signOut = useContextSelector(AuthContext, context => context.signOut);

  return {
    signIn,
    signOut,
  };
}

