import React from 'react';

import usePlacesAutoComplete, {
  getGeocode,
  getLatLng,
} from 'use-places-autocomplete';
import { toast } from 'react-toastify';
import styles from './styles.module.css';
import { AddressProps } from 'interfaces/vehicle';
import { Input, Select } from 'antd';

const position = {
  lat: -3.759201,
  lng: -38.526526,
};

interface SearchProps {
  handleSetAddress: (value: AddressProps) => void;
  alignLeft?: boolean;
  handleDrawnPolygon?: (value: any) => void;
  handleSearchInsideFilter?: any;
}

export function Search({
  handleSetAddress,
  alignLeft,
  handleDrawnPolygon,
  handleSearchInsideFilter,
}: SearchProps) {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutoComplete({
    requestOptions: {
      // @ts-ignore
      location: {
        lat: () => position.lat,
        lng: () => position.lng,
      },
      radius: 350 * 1000,
      debounce: 5000,
    },
  });

  const handleClickAddress = async (address: string) => {
    setValue(address, false);
    clearSuggestions();
    try {
      const geocode = await getGeocode({ address });
      if (!geocode.length) {
        throw new Error();
      }
      const { lat, lng } = getLatLng(geocode[0]);

      let numero = '';
      let logradouro = '';
      let bairro = '';
      let municipio = '';

      geocode[0].address_components.map(add => {
        if (add.types.includes('street_number')) numero = add.long_name;
        if (add.types.includes('route')) logradouro = add.long_name;
        if (add.types.includes('sublocality')) bairro = add.long_name;
        if (add.types.includes('administrative_area_level_2'))
          municipio = add.long_name;
        return add;
      });

      if (handleSearchInsideFilter) {
        handleSearchInsideFilter({
          lat,
          lng,
        });
      } else if (alignLeft && handleDrawnPolygon) {
        handleDrawnPolygon(geocode[0]);
      } else {
        handleSetAddress({
          address: {
            numero,
            logradouro,
            bairro,
            municipio,
            latitude: lat,
            longitude: lng,
          },
        });
      }
    } catch (err) {
      toast.error('Erro ao selecionar endereço!');
    }
    return;
  };

  return (
    <div
      className={
        handleSearchInsideFilter
          ? styles.searchBoxInsideFilter
          : alignLeft
          ? styles.searchBoxLeft
          : styles.searchBox
      }
    >
      <Input
        className={styles.searchInput}
        value={value}
        onChange={e => {
          setValue(e.target.value);
        }}
        disabled={!ready}
        placeholder={handleSearchInsideFilter ? '' : 'Pesquise no mapa'}
      />
      {/* <ComboboxPopover style={{ zIndex: 9999 }}> */}
      <ul className={styles.addressList} style={{ zIndex: 99999 }}>
        {status === 'OK' &&
          data.map(d => (
            <li
              className={styles.addressItem}
              key={d.place_id}
              value={d.description}
              style={{ zIndex: 99999 }}
              onClick={() => handleClickAddress(d.description)}
            >
              {d.description}
            </li>
          ))}
      </ul>
      {/* </ComboboxPopover> */}
      {/* </Select> */}
    </div>
  );
}

