import React, { ReactNode } from 'react';

import style from '../view/listagemOcorrencia/styles.module.css';

type TCardOcorrencia = {
  icon: ReactNode;
  tituloCard: string;
  dados: {
    title: string;
    value: string;
  }[];
};

export function CardOcorrencia(props: TCardOcorrencia) {
  return (
    <div className={style.card}>
      <div className={style.iconTitulo}>
        <div className={style.icon}>{props.icon}</div>

        <div className={style.titulo}>{props.tituloCard}</div>
      </div>

      <div className={style.conteudo}>
        {(props.dados || []).map((info, i) => {
          return (
            <div key={info.title}>
              <div className={style.subTitle}>{info.title}</div>

              <div className={style.title}>{info.value}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
