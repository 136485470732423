import { TrackingHistoryContext } from 'context/tracking_history';
import { useContextSelector } from 'use-context-selector';

export function useHistoryPolygonsAndPosicaoEfetivo() {
  const handleSetPrefixosHistorico = useContextSelector(
    TrackingHistoryContext,
    context => context.handleSetPrefixosHistorico
  );

  const selectedPosicaoEfetivo = useContextSelector(
    TrackingHistoryContext,
    context => context.selectedPosicaoEfetivo
  );

  const polygonsCustomAudit = useContextSelector(
    TrackingHistoryContext,
    context => context.polygonsCustomAudit
  );

  return {
    selectedPosicaoEfetivo,
    handleSetPrefixosHistorico,
    polygonsCustomAudit,
  };
}

