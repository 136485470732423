import { TrackingHistoryContext } from 'context/tracking_history';
import { useContextSelector } from 'use-context-selector';

export function useHistoryLoadingFences() {
  const loadingFences = useContextSelector(
    TrackingHistoryContext,
    context => context.loadingFences
  );

  const setLoadingFences = useContextSelector(
    TrackingHistoryContext,
    context => context.setLoadingFences
  );

  return {
    loadingFences,
    setLoadingFences,
  };
}

