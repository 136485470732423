import React, { useState } from 'react';
import { Layout } from 'antd';
import { useHistory } from 'react-router-dom';

import Routes from './routes';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Login from './Login';
import { GlobalProvider } from 'context/global';
import { useAuthData } from 'hooks/auth/useAuthData';

export default function Main() {
  const { Content } = Layout;
  const { data } = useAuthData();
  const [path, setPath] = useState<string>();

  const history = useHistory();
  const { pathname } = history.location || {};

  const isNotViewHeaderAndFooter = !path
    ? pathname !== '/mapa/historico' &&
      pathname !== '/mapa/monitoramento' &&
      // pathname !== '/pdfDesconectados' &&
      pathname !== '/pdfHistoricoRaio'
    : path !== '/mapa/historico' &&
      path !== '/mapa/monitoramento' &&
      // path !== '/pdfDesconectados' &&
      path !== 'pdfHistoricoRaio';

  history.listen((location: any) => {
    setPath(location.pathname);
  });

  const isLogado = !!(data || {}).token; // !!data.token;
  return (
    <>
      {!isLogado && <Login />}
      {isLogado && (
        <Layout style={{ minHeight: '100vh' }}>
          {isLogado && isNotViewHeaderAndFooter && <Header />}

          {/* <main  */}

          <GlobalProvider>
            <Content
              id="page-wrap"
              style={{ padding: 0, margin: '30px auto', width: '85%' }}
            >
              {isLogado && <Routes />}
            </Content>
          </GlobalProvider>

          {/* </main> */}
          {isLogado && isNotViewHeaderAndFooter && <Footer />}
        </Layout>
      )}
    </>
  );
}
