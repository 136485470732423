import { Spin } from 'antd';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { useSelector } from 'react-redux';
import debounce from 'lodash.debounce';
import { ReturnProps, ServiceAIS } from 'services/modulos/serviceAIS';

import { KEY_VALUE_ROLE_MAP } from '../../../../utils/mapGroupsMoniByRole';
import {
  FiltersMapProps,
  GroupsFiltersProps,
} from 'interfaces/FiltersMapContext';
import { useAuthData } from 'hooks/auth/useAuthData';
import { useHistoryResetFunctions } from 'hooks/history/useHistoryResetFunctions';
import { useFiltersMapLoadingAis } from 'hooks/filtersMap/useFiltersMapLoadingAis';
import { useFiltersMapAgencies } from 'hooks/filtersMap/useFiltersMapSelectedAgencies';
import { useFiltersMapGroups } from 'hooks/filtersMap/useFiltersMapGroups';
import { useFiltersMapSelectedAis } from 'hooks/filtersMap/useFiltersMapSelectedAis';
import { useFencesSelectedInfo } from 'hooks/monitoring/useFencesSelectedInfo';
import { useGlobalFences } from 'hooks/global/useGlobalFences';

interface OptionsProps {
  id: string;
  nome: string;
  checked: boolean;
  num: number | null;
}

export default function FiltroAis({
  alternaAis,
}: {
  alternaAis: (value: any) => void;
}) {
  const { setGroupsFilters } = useFiltersMapGroups();
  const { data } = useAuthData();

  const { hasMonitoramentoCIOPS, isSecretario } = data;

  const { selectedAgencies } = useFiltersMapAgencies();
  const { setLoadingAis } = useFiltersMapLoadingAis();
  const { selectedAis } = useFiltersMapSelectedAis();
  const { fencesSelected } = useFencesSelectedInfo();

  const { fences } = useGlobalFences();

  const [opcoes, setOpcoes] = useState<OptionsProps[]>([]);
  const auxOpcoes = useRef<OptionsProps[]>([]);
  const auxFencesSelected = useRef<string[]>([]);
  const { data: dataAuth } = useAuthData();
  const { roles } = dataAuth || {};

  // const { resetAllData } = useHistoryResetFunctions();

  const [loading, setloading] = useState(false);

  useEffect(() => {
    setGroupsFilters((prev: GroupsFiltersProps) => ({
      ...prev,
      groups: opcoes,
    }));
  }, [opcoes]);

  // @ts-ignore
  const { ais } = useSelector(state => state.monitoring);

  const checkGroup = (opArray: OptionsProps[], res: ReturnProps) => {
    if (opArray.length > 0) {
      return Boolean(opArray.filter(o => o.nome === res.nome)[0]?.checked);
    }
    return Boolean(isSecretario && res.nome.includes('AIS'));
  };

  const loadAis = useCallback(async () => {
    try {
      setloading(true);
      const response = await ServiceAIS.getByVinculadas(selectedAgencies);

      let newOpcoes: OptionsProps[] = [];

      if (selectedAgencies.length > 0) {
        newOpcoes = response?.map(a => ({
          id: a.id,
          nome: a.nome,
          checked: checkGroup(opcoes, a),
          num: a.nome.split(' ')[1] ? Number(a.nome.split(' ')[1]) : null,
        }));
      } else {
        newOpcoes = response?.map(a => ({
          id: a.id,
          nome: a.nome,
          checked: false,
          num: a.nome.split(' ')[1] ? Number(a.nome.split(' ')[1]) : null,
        }));
      }

      newOpcoes.push({
        id: 'sem-grupo',
        nome: 'SEM',
        checked: false,
        num: null,
      });

      let newIds = newOpcoes.map(i => i.id);

      if (opcoes && opcoes.length > 0) {
        opcoes.map(op => {
          if (!newIds.includes(op.id) && op.num && op.checked) {
            alternaAis({ id: `ais-${op.num}` });
          }
        });
      }

      const sortedArray = newOpcoes
        .sort((a, b) => ((a.num || 0) < (b.num || 0) ? -1 : 1))
        .filter(v =>
          roles?.includes(
            KEY_VALUE_ROLE_MAP[
              v.nome === 'BPEsp' ? 'BPRE' : v.nome.replace(' ', '_')
            ]
          )
        );

      auxOpcoes.current = sortedArray;
      setOpcoes(sortedArray);
      setloading(false);
    } catch (e) {
      //
    }
  }, [selectedAgencies, roles, isSecretario]);

  useEffect(() => {
    loadAis();
  }, [loadAis, ais]);

  const debouncedSave = useCallback(
    debounce(() => {
      setOpcoes(auxOpcoes.current);
      if (hasMonitoramentoCIOPS) {
        fencesSelected.current = auxFencesSelected.current;
      }
    }, 2000),
    []
  );

  const selectAll = useCallback(
    (value: boolean) => {
      setLoadingAis(true);

      auxFencesSelected.current = fencesSelected.current;

      auxOpcoes.current = auxOpcoes.current.map(op => {
        if (op.nome.includes('AIS') && op.checked !== value) {
          const id = op.num ? `ais-${op.num}` : op.nome;
          const radio = document.getElementById(id);
          // @ts-ignore
          if (radio) radio.checked = value;

          const nameAis = {
            aisNumber: op.nome
              .split(/\s+/)
              .join('')
              .replace('AIS', ''),
          };
          alternaAis(nameAis);

          if (hasMonitoramentoCIOPS) {
            if (value) {
              fences.current.data.map(f => {
                if (
                  f.veiculosAssociados.length > 0 &&
                  f.ativo &&
                  f.grupos?.includes(op.nome.replaceAll(' ', '')) &&
                  !auxFencesSelected.current.includes(f.id || '')
                ) {
                  auxFencesSelected.current.push(f.id || '');
                }
              });
            } else {
              auxFencesSelected.current = [];
            }
          }

          return { ...op, checked: value };
        }
        return op;
      });
      if (!value) {
        for (const chave in selectedAis) {
          selectedAis[chave] = true;
        }
      }

      debouncedSave();
      // setOpcoes(auxOpcoes.current);
    },
    [selectedAis, fences.current, hasMonitoramentoCIOPS]
  );

  const handleChange = useCallback(
    ({ id, check, nome }: { id: string; check: boolean; nome: string }) => {
      try {
        setLoadingAis(true);

        auxFencesSelected.current = fencesSelected.current;

        auxOpcoes.current = auxOpcoes.current.map(op => {
          if (hasMonitoramentoCIOPS) {
            fences.current.data.map(f => {
              if ((op.id === id && check) || (op.id !== id && op.checked)) {
                if (
                  f.veiculosAssociados.length > 0 &&
                  f.ativo &&
                  f.grupos?.includes(op.nome.replaceAll(' ', '')) &&
                  !auxFencesSelected.current.includes(f.id || '')
                ) {
                  auxFencesSelected.current.push(f.id || '');
                }
              }

              return f;
            });
          }

          if (op.id === id && op.checked !== check) {
            return { ...op, checked: check };
          }

          return op;
        });
        debouncedSave();
      } catch (error) {
        //
      }
    },
    [fences.current, hasMonitoramentoCIOPS]
  );

  return (
    <Spin spinning={loading}>
      <div className="grid grid-template-columns-1">
        {auxOpcoes.current.length ? (
          <div>
            <label className="switchToggle">
              <input
                type="checkbox"
                className="display-switch"
                value={''}
                checked={
                  !Boolean(
                    auxOpcoes.current.filter(o => o.num).some(o => !o.checked)
                  )
                }
                onChange={value => selectAll(value.target.checked)}
              />

              <span className="slider" />
            </label>
            &nbsp;
            <span>Todas AIS</span>
          </div>
        ) : null}
        {auxOpcoes.current?.map(a => (
          <div key={a.id}>
            <label
              htmlFor={a.num ? `ais-${a.num}` : a.nome}
              className="switchToggle"
            >
              <input
                type="checkbox"
                id={a.num ? `ais-${a.num}` : a.nome}
                className="display-switch"
                value={''}
                defaultChecked={a.checked}
                // checked={a.checked}
                onChange={evento => {
                  // resetAllData();
                  handleChange({
                    id: a.id,
                    check: evento.target.checked,
                    nome: a.nome,
                  });
                  if (a.num) {
                    alternaAis(evento.target);
                  }
                }}
              />

              <span className="slider" />
            </label>
            &nbsp;
            <span>{a.nome === 'SEM' ? 'SEM GRUPO' : a.nome}</span>
          </div>
        ))}
      </div>
    </Spin>
  );
}
