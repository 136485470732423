import axios, { AxiosInstance } from 'axios';

import { path } from 'ramda';

import { httpErrorHandler } from './handleError';

import ENV from '../config';

interface CustomAxiosInstance extends AxiosInstance {
  setAuth?: any;
  unsetAuth?: any;
}

const MAX_RETRIES = 5;

const mapResponseToUsefulData = (response: any) => {
  const { data } = response;

  return data;
};

const mapToUsefulError = (error: any) => {
  // try get from body

  const messageFromBody = path(['response', 'data', 'message'], error);

  // const messageFromBody = path(['response', 'data'], error);

  if (messageFromBody) return { message: messageFromBody, error };

  // try get exception

  const messageFromError = error && error.message;

  if (messageFromError) return { message: messageFromError, error };

  // otherwise, return error

  return { message: error, error };
};

const api: CustomAxiosInstance = axios.create({
  baseURL: `${ENV.serverSSPDS}`,

  headers: {
    Accept: 'application/json',

    'Content-Type': 'application/json',
  },
  timeout: 1000 * 3,
});

api.setAuth = ({ token, client, roles }: any) => {
  localStorage.setItem(`@${ENV.applicationName}:token`, token);

  localStorage.setItem(
    `@${ENV.applicationName}:client`,

    JSON.stringify(client)
  );

  localStorage.setItem(`@${ENV.applicationName}:roles`, JSON.stringify(roles));

  api.defaults.headers.common.Authorization = `Bearer ${token}`;
};

api.unsetAuth = () => {
  localStorage.removeItem(`@${ENV.applicationName}:token`);

  localStorage.removeItem(`@${ENV.applicationName}:client`);

  localStorage.removeItem(`@${ENV.applicationName}:roles`);

  api.defaults.headers.common.Authorization = undefined;
};

const handleError = (error: any) => {
  // log

  // eslint-disable-next-line no-console

  // handle http erros

  httpErrorHandler(error, api.unsetAuth);

  // extract in useful error format

  const usefulError = mapToUsefulError(error);

  //  publish('error', usefulError);

  return Promise.reject(usefulError);
};

api.interceptors.response.use(
  // data

  response => mapResponseToUsefulData(response),

  // error response

  // eslint-disable-next-line comma-dangle

  error => handleError(error)
);

// interceptors

api.interceptors.request.use(
  // update config before request

  config => config,

  // handle request error

  error => handleError(error)
);

api.interceptors.response.use(undefined, function axiosRetryInterceptor(err) {
  const { error } = err;
  const originalRequest = error.config;

  if (
    (error.code === 'ECONNABORTED' && error.message.includes('timeout')) ||
    (error.code === 'ERR_BAD_RESPONSE' && error.message.includes('500'))
  ) {
    originalRequest._timeoutCanceled = true;

    if (!originalRequest.retry) {
      originalRequest.retry = 0;
    }

    if (originalRequest.retry < MAX_RETRIES) {
      originalRequest.retry += 1;

      const backoff = new Promise<void>(resolve => {
        setTimeout(() => {
          resolve();
        }, 2 * 1000);
      });

      return backoff.then(() => {
        return api(originalRequest);
      });
    }
  }

  return Promise.reject(error);
});

export default api;
