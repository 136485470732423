import React, { useCallback, useState } from 'react';
import { Modal, Input, Form, Spin } from 'antd';
import PropTypes from 'prop-types';

/* import { ServiceSIAA } from '~/services/siaaModulo/siaaService'; */
import { ContainerModal } from './styles';
import api from '~/services/api.ts';
import ENV from '~/config';

export function ModalRecuperarSenha({ visible, onOk, onCancel }) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const onFinish = useCallback(
    async values => {
      try {
        setLoading(true);
        await api.get(`${ENV.serverSIAA}/users/recovery/${values.email}`);
        onOk();
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    },
    [onOk]
  );

  return (
    <ContainerModal>
      <Modal
        title="Recuperar senha"
        className="modal-recupera-login"
        open={visible}
        onOk={form.submit}
        onCancel={onCancel}
        destroyOnClose
      >
        <Spin spinning={loading}>
          <Form name="nest-messages" onFinish={onFinish} form={form}>
            <Form.Item
              name="email"
              label="Email"
              rules={[
                {
                  type: 'email',
                  message: 'Por favor, informe um e-mail válido!',
                },
                { required: true, message: 'Por favor, informe o e-mail!' },
              ]}
            >
              <Input type="email" placeholder="Digite seu e-mail" />
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    </ContainerModal>
  );
}

ModalRecuperarSenha.propTypes = {
  visible: PropTypes.bool.isRequired,
  onOk: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

