import { TrackingHistoryContext } from 'context/tracking_history';
import { useContextSelector } from 'use-context-selector';

export function useHistorySeachByPlatesAndDate() {
  const searchByPlatesAndDate = useContextSelector(
    TrackingHistoryContext,
    context => context.searchByPlatesAndDate
  );

  return {
    searchByPlatesAndDate,
  };
}

