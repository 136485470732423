import React, { useCallback, useState, useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { Breadcrumb, Card, Input, Table } from 'antd';

import { debounce } from 'throttle-debounce';
import { useSelector, useDispatch } from 'react-redux';
import { PageHeader } from '../../../components/PageHeader';

import * as ACOES from '~/store/modules/equipamento/actions';

import { templateColumns } from './columns';

const PAGE_SIZE = 10;
export const TITLE = 'Equipamento';
const SUBTITLE = 'Gerenciamento de Equipamento';
export const BASE_ROLE = 'TIPO_VEICULO';
export const MODULE_ROUTE = 'equipamentos';

const { Search } = Input;
const mvp = true;

export function CadastroEquipamentos() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [search, setSearch] = useState();
  const [result, setResult] = useState([]);

  const { loading, totalElements, data } = useSelector(
    state => state.equipamento
  );

  // console.log("R" ,data);

  const handleNewRegister = useCallback(() => {
    // console.log("modal callbak!!!");
    // console.log(`${MODULE_ROUTE}/form`);
    history.push(`${MODULE_ROUTE}/form`);
  }, [history]);

  // const handleSearch = useCallback(
  //   value => {
  //     console.log("o callbak!!!");
  //     console.log(value);
  //     // setPage(1);
  //     // setSearch(value);
  //     // dispatch(ACOES.getAllPage({ page: 1, size: pageSize, nome: value }));
  //   },
  //   [dispatch, pageSize]
  // );

  const initFetch = useCallback(() => {
    dispatch(ACOES.getAll());
  }, [dispatch]);

  useEffect(() => {
    initFetch();
  }, [initFetch]);

  useEffect(() => {
    let _result;

    if (search) {
      _result = data.filter(
        d => d && d.identificacao && d.identificacao.includes(search)
      );
    } else {
      _result = [...data];
    }

    setResult([..._result]);
  }, [data, search]);

  /* const handleSearch = useCallback(
    value => {
      setPage(1);
      setSearch(value);
      dispatch(ACOES.getAllPage({ page: 1, size: PAGE_SIZE, id: value }));
    },
    [dispatch]
  ); */

  const handleSearch = value => {
    setSearch(value);
  };

  const handleDelete = useCallback(
    ({ id }) => {
      dispatch(ACOES.deleteRequest({ id }));
    },
    [dispatch]
  );

  // console.log(data);

  return (
    <div className="container">
      <Breadcrumb style={{ padding: '10px' }}>
        <Breadcrumb.Item>
          <Link to="/">Página Inicial</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to="/cadastros">Cadastros</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Equipamentos</Breadcrumb.Item>
      </Breadcrumb>
      <Card>
        <PageHeader
          title={TITLE}
          subTitle={SUBTITLE}
          baseRole={BASE_ROLE}
          onNewRegister={handleNewRegister}
        />

        <div
          style={{
            marginBottom: 8,
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Search
            value={search}
            placeholder="Pesquisa por Identificador (IMEI/Nº de Série)"
            onChange={e => debounce(800, handleSearch(e.target.value))}
          />
          <div />
        </div>

        <Table
          tableLayout="fixed"
          loading={loading}
          columns={templateColumns({ onDelete: handleDelete })}
          dataSource={(result || []).map(d => ({ ...d, key: d.id }))}
        />
      </Card>
    </div>
  );
}

