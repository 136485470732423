import React, { useCallback, useState, useContext, ReactNode } from 'react';
import { createContext } from 'use-context-selector';
import { toast } from 'react-toastify';
import axios from 'axios';
import ENV from '../config';
import api from 'services/api';
import { AuthDataProps, AuthProps } from 'interfaces/auth';

export const AuthContext = createContext({} as AuthProps);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [data, setData] = useState<AuthDataProps>(() => {
    const token = sessionStorage.getItem('rota@tkn');
    const roles = sessionStorage.getItem('rota@roles');
    const client = sessionStorage.getItem('rota@client');

    const isFenceAdmin = Boolean(
      sessionStorage.getItem('rota@fenceAdmin') === 'true'
    );

    const hasMonitoramentoCIOPS = Boolean(
      sessionStorage.getItem('rota@monitoramentoCIOPS') === 'true'
    );

    const isSecretario = Boolean(
      sessionStorage.getItem('rota@scrtr') === 'true'
    );

    if (token && roles && client) {
      api.setAuth({ token, client, roles });

      return {
        token,
        roles: JSON.parse(roles),
        client: JSON.parse(client),
        isFenceAdmin,
        hasMonitoramentoCIOPS,
        isSecretario,
      };
    }

    return {} as AuthDataProps;
  });

  const [loading, setLoading] = useState(false);

  const signIn = useCallback(
    async ({ cpf, password }: { cpf: string; password: string }) => {
      const body = {
        cpf,
        password,
        appServerKey: ENV.appkey,
      };

      try {
        await axios
          .post(`${ENV.serverSIAA}/auth`, body, {
            headers: { 'Content-Type': 'application/json' },
          })
          .then(
            resposta => {
              const { client, roles, token } = resposta.data;

              let isFenceAdmin = false;
              let hasMonitoramentoCIOPS = false;
              let isSecretario = false;

              if (roles.includes('ROLE_ROTAS_CERCA_CIOPS')) {
                isFenceAdmin = true;
              }

              if (roles.includes('ROLE_ROTAS_MONITORAMENTO_CIOPS')) {
                hasMonitoramentoCIOPS = true;
              }

              if (roles.includes('ROLE_ROTAS_SECRETARIO')) {
                isSecretario = true;
              }

              sessionStorage.setItem('rota@tkn', token);
              sessionStorage.setItem('rota@roles', JSON.stringify(roles));
              sessionStorage.setItem('rota@client', JSON.stringify(client));
              sessionStorage.setItem('rota@fenceAdmin', String(isFenceAdmin));
              sessionStorage.setItem(
                'rota@monitoramentoCIOPS',
                String(hasMonitoramentoCIOPS)
              );
              sessionStorage.setItem('rota@scrtr', String(isSecretario));

              api.setAuth({ token, client, roles });
              setData({
                client,
                roles,
                token,
                isFenceAdmin,
                hasMonitoramentoCIOPS,
                isSecretario,
              });
            },

            e => {
              const retorno = Object.values(e)[2];

              let msgUsuario =
                'Erro ao fazer login. Tente novamente.\nCaso ele persista, entre em contato com o suporte';

              if (retorno !== null && retorno !== undefined) {
                // @ts-ignore
                if (retorno.data !== null && retorno.data !== undefined) {
                  // @ts-ignore
                  msgUsuario = retorno.data.message;
                }
              }

              toast.error(msgUsuario);
            }
          );
      } catch (err) {
        // err
      } finally {
        setLoading(false);
      }
    },
    []
  );

  const signOut = useCallback(() => {
    sessionStorage.removeItem('rota@tkn');
    sessionStorage.removeItem('rota@roles');
    sessionStorage.removeItem('rota@client');
    sessionStorage.removeItem('rota@fenceAdmin');
    sessionStorage.removeItem('rota@monitoramentoCIOPS');
    sessionStorage.removeItem('rota@scrtr');
    // @ts-ignore
    setData();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        data,
        signIn,
        signOut,
        loading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
