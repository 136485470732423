import { AuthContext } from 'context/auth';
import { useContextSelector } from 'use-context-selector';

export function useAuthDataFunctions() {
  const data = useContextSelector(AuthContext, context => context.data);
  const signIn = useContextSelector(AuthContext, context => context.signIn);
  const signOut = useContextSelector(AuthContext, context => context.signOut);

  return {
    data,
    signIn,
    signOut,
  };
}

