import { Popover, Collapse, Switch, Spin, Checkbox } from 'antd';
import React, { useCallback } from 'react';
import Tooltip from 'antd/es/tooltip';

import { useLocation } from 'react-router-dom';
import { InfoCircleOutlined } from '@ant-design/icons';
import { FormHistoryEfetivo } from './historico/form_historico_efetivo';
// import { FormHistoryComposicao } from './historico/form_historico_composicao';
import { FormAuditoriaAis } from './historico/form_auditoria_ais';
import { FormBuscaViatura } from './busca_geral/form_busca_viatura';
import { FormCustomAudit } from './historico/form_custom_audit';
import { IconAis, IconEfetivo } from 'imgs/icons';
import { Container } from './styles';

import { FormHistoryRaioAll } from './historico/form_historico_raio';

import FiltroAgencias from './agencies';
import FiltroAis from './ais';
import FiltroOutros from './outros';
import FenceFilter from './fences';
import { FormAuditoriaOcorrencia } from './historico/form_auditoria_ocorrencia';
import { AuthDataProps, AuthProps } from 'interfaces/auth';
import { useFilterInfo } from 'hooks/monitoring/useFilterInfo';
import { useAuthData } from 'hooks/auth/useAuthData';
import { useFiltersMapLoadingAis } from 'hooks/filtersMap/useFiltersMapLoadingAis';
import { useHistoryLoadingFences } from 'hooks/history/useHistoryLoadingFences';
import { useHistoryDataSource } from 'hooks/history/useHistoryDataSource';

const { Panel } = Collapse;

interface ContainerWrapperProps {
  alternaAis: (props: any) => void;
  onVisible: (value: boolean) => void;
  setIsDrawing: (value: boolean) => void;
  setIsErasing: (value: boolean) => void;
}

interface FiltroProps {
  alternaAis: (props: any) => void;
  setIsDrawing: (value: boolean) => void;
  filtersVisible: boolean;
  setFiltersVisible: (value: boolean) => void;
  setIsErasing: (value: boolean) => void;
}

export function ContainerWrapper({
  alternaAis,
  onVisible,
  setIsDrawing,
  setIsErasing,
}: ContainerWrapperProps) {
  const { data: dataAuth } = useAuthData();
  const { loadingFences, activeAlert, filterAlertArray } = useFilterInfo();
  const { loadingAis } = useFiltersMapLoadingAis();
  const { loadingFences: historyLoadingFences } = useHistoryLoadingFences();
  const { dataSource, setDataSource } = useHistoryDataSource();
  const { roles }: AuthDataProps = dataAuth || {};

  const location = useLocation();

  const isMonitoramento = location.pathname === '/mapa/monitoramento';

  // const [allVehicles, setAllVehicles] = useState([]);

  const isPermitted = useCallback(
    (role: string) => {
      return ((roles || []).filter(r => r === role) || []).length > 0;
    },
    [roles]
  );

  const genExtra = (title: string) => (
    <span className="panel">
      {/* <IconAis size="30" /> */}
      <h3 style={{ margin: 0, marginLeft: 10 }}>{title}</h3>
      <Spin
        spinning={loadingAis}
        style={{ marginLeft: '5%', padding: '2px' }}
      />
    </span>
  );

  return (
    <Container>
      {!isMonitoramento && isPermitted('ROLE_ROTAS_HISTORICO_TELTRONIC') && (
        <div className="dataSourceSelect">
          <Checkbox
            checked={dataSource === 'COTIC'}
            onChange={() => setDataSource('COTIC')}
          >
            Dados COTIC
          </Checkbox>
          <Checkbox
            checked={dataSource === 'TELTRONIC'}
            onChange={() => setDataSource('TELTRONIC')}
          >
            Dados TELTRONIC
          </Checkbox>
        </div>
      )}
      <div style={{ overflow: 'scroll', maxHeight: '80vh' }}>
        <Collapse expandIconPosition="end" ghost>
          {isMonitoramento && (
            <>
              <Panel
                header=""
                key="0"
                extra={
                  <span className="panel">
                    {/* <IconEfetivo size={30} /> */}
                    <h3 style={{ margin: 0, marginLeft: 10 }}>Agências</h3>
                  </span>
                }
              >
                <FiltroAgencias />
              </Panel>
              <div className="separador" />
            </>
          )}

          {isMonitoramento && (
            <>
              <Panel
                header=""
                key="1"
                extra={genExtra('Controle de AIS e Especializadas')}
              >
                {/* configAis={configAis} */}
                <FiltroAis alternaAis={alternaAis} />
              </Panel>

              <div className="separador" />
            </>
          )}

          {isMonitoramento && (
            <>
              <Panel
                header=""
                key="3"
                extra={
                  <span className="panel">
                    {/* <IconEfetivo size={30} /> */}
                    <h3 style={{ margin: 0, marginLeft: 10 }}>
                      Buscar Viatura
                    </h3>
                  </span>
                }
              >
                <FormBuscaViatura onVisible={onVisible} />
              </Panel>

              <div className="separador" />
            </>
          )}

          {isMonitoramento && activeAlert && (
            <>
              <Panel
                header=""
                key="4"
                extra={
                  <span className="panel">
                    {/* <IconEfetivo size={30} /> */}
                    <h3 style={{ margin: 0, marginLeft: 10 }}>Alertas</h3>
                  </span>
                }
              >
                <div className="areaDeAlertaScroll">
                  <div className="filtroAlerta">
                    <div className="rowFiltroAlerta">
                      <Switch
                        defaultChecked
                        style={{ backgroundColor: '#c4c4c4' }}
                        onChange={e => filterAlertArray(e, 'foraCerca')}
                      />
                    </div>
                    <span>Fora da Cerca</span>
                    <div className="rowFiltroAlerta">
                      <Switch
                        defaultChecked={
                          !isPermitted('ROLE_ROTAS_MONITORAMENTO_CIOPS')
                        }
                        onChange={e => filterAlertArray(e, 'foraAIS')}
                      />
                    </div>
                    <span>Fora Da AIS</span>
                    {/* <div className="rowFiltroAlerta">
                      <Switch
                        defaultChecked
                        onChange={e => filterAlertArray(e, 'LIMITE_VELOCIDADE')}
                      />
                    </div>
                    <span>Limite Velocidade</span> */}
                  </div>

                  <div className="filtroAlerta" style={{ padding: '0px 20px' }}>
                    <div className="rowFiltroAlerta">
                      <Switch
                        defaultChecked
                        onChange={e =>
                          filterAlertArray(e, 'PARADA_SUPERIOR_15_MIN')
                        }
                      />
                    </div>
                    <span>Parada Superior 15min</span>
                  </div>
                </div>
              </Panel>

              <div className="separador" />
            </>
          )}

          {!isMonitoramento && isPermitted('ROLE_ROTAS_HISTORICO') && (
            <>
              <Panel
                header=""
                key="5"
                extra={
                  <span className="panel">
                    {/* <IconEfetivo size={30} /> */}
                    <h3 style={{ margin: 0, marginLeft: 10 }}>
                      Rastreamento completo
                      <Tooltip title="Tempo máximo para pesquisa : 24 Horas">
                        <InfoCircleOutlined
                          style={{
                            margin: 0,
                            marginLeft: 10,
                            color: 'orange',
                          }}
                        />
                      </Tooltip>
                    </h3>
                  </span>
                }
              >
                <FormHistoryEfetivo onVisible={onVisible} />
              </Panel>
              <div className="separador" />
            </>
          )}

          {!isMonitoramento && isPermitted('ROLE_ROTAS_HISTORICO_RAIO') && (
            <>
              <Panel
                header=""
                key="7"
                extra={
                  <span className="panel">
                    {/* <IconEfetivo size={30} /> */}
                    <h3 style={{ margin: 0, marginLeft: 10 }}>
                      Busca por raio
                      <Tooltip title="Tempo máximo para pesquisa : 5 Horas">
                        <InfoCircleOutlined
                          style={{
                            margin: 0,
                            marginLeft: 10,
                            color: 'orange',
                          }}
                        />
                      </Tooltip>
                    </h3>
                  </span>
                }
              >
                <FormHistoryRaioAll onVisible={onVisible} />
              </Panel>
              <div className="separador" />
            </>
          )}

          {!isMonitoramento && isPermitted('ROLE_ROTAS_HISTORICO_AUDITORIA') && (
            <>
              <Panel
                header=""
                key="8"
                extra={
                  <span className="panel">
                    {/* <IconEfetivo size={30} /> */}
                    <h3 style={{ margin: 0, marginLeft: 10 }}>Auditoria</h3>
                  </span>
                }
              >
                <Collapse
                  accordion
                  expandIconPosition="end"
                  style={{ justifyContent: 'center', alignItems: 'center' }}
                >
                  <Panel
                    header=""
                    key="81"
                    extra={
                      <span className="subpanel">
                        <h3>
                          Consulta por AIS{' '}
                          <Tooltip title="Tempo maximo para pesquisa: 3 Horas">
                            <InfoCircleOutlined
                              style={{
                                margin: 0,
                                marginLeft: 10,
                                color: 'orange',
                              }}
                            />
                          </Tooltip>
                        </h3>
                      </span>
                    }
                  >
                    <FormAuditoriaAis
                      onVisible={onVisible}
                      alternaAis={alternaAis}
                    />
                  </Panel>
                  {/* {isPermitted('ROLE_ROTAS_VISUALIZACAO_CERCA') && (
                    <Panel
                      key="82"
                      extra={
                        <span className="subpanel">
                          <h3>
                            Cerca{' '}
                            <Tooltip title="Tempo Maximo para pesquisa : 3 Horas.">
                              <InfoCircleOutlined
                                style={{
                                  margin: 0,
                                  marginLeft: 10,
                                  color: 'orange',
                                }}
                              />
                            </Tooltip>
                          </h3>
                        </span>
                      }
                    >
                      <FormAuditoriaCerca />
                    </Panel>
                  )} */}

                  {isPermitted('ROLE_ROTAS_OCORRENCIA') && (
                    <Panel
                      header=""
                      key="83"
                      extra={
                        <span className="subpanel">
                          <h3>Ocorrência</h3>
                        </span>
                      }
                    >
                      <FormAuditoriaOcorrencia onVisible={onVisible} />
                    </Panel>
                  )}

                  {isPermitted(
                    'ROLE_ROTAS_HISTORICO_AUDITORIA_PERSONALIZADA'
                  ) && (
                    <Panel
                      header=""
                      key="84"
                      extra={
                        <span className="subpanel">
                          <h3>Personalizado</h3>
                        </span>
                      }
                    >
                      <FormCustomAudit
                        setIsDrawing={setIsDrawing}
                        // allVehicles={allVehicles}
                        onVisible={onVisible}
                        setIsErasing={setIsErasing}
                      />
                    </Panel>
                  )}
                  {/* <Panel
                    key="82"
                    disabled
                    extra={
                      <span className="subpanel">
                        <h3>Consulta por Ocorrência</h3>
                      </span>
                    }
                  >
                    <FormAuditoriaOcorrencia
                      alternaAis={alternaAis}
                      onVisible={false}
                    />
                  </Panel> */}
                </Collapse>
              </Panel>
              <div className="separador" />
            </>
          )}

          {isPermitted('ROLE_ROTAS_VISUALIZACAO_CERCA') && (
            <>
              <Panel
                header=""
                key="22"
                extra={
                  <span className="panel">
                    {/* <IconAis size="30" /> */}
                    <h3 style={{ margin: 0, marginLeft: 10 }}>
                      Controle de Cercas
                    </h3>
                    {isMonitoramento && (
                      <Tooltip title="Selecione uma AIS para vizualizar as cercas">
                        <InfoCircleOutlined
                          style={{
                            margin: 0,
                            marginLeft: 10,
                            color: 'orange',
                          }}
                        />
                      </Tooltip>
                    )}

                    <Spin
                      spinning={loadingFences || historyLoadingFences}
                      style={{ marginLeft: '5%', padding: '2px' }}
                    />
                  </span>
                }
              >
                <div style={{ maxHeight: '300px', overflow: 'auto' }}>
                  <FenceFilter />
                </div>
              </Panel>

              <div className="separador" />
            </>
          )}

          <>
            <Panel
              header=""
              key="2"
              extra={
                <span className="panel">
                  {/* <IconEfetivo size={30} /> */}
                  <h3 style={{ margin: 0, marginLeft: 10 }}>Outros filtros</h3>
                </span>
              }
            >
              <FiltroOutros showDaysSelect={isMonitoramento} />
            </Panel>

            <div className="separador" />
          </>
        </Collapse>
      </div>
    </Container>
  );
}

export default function Filtro({
  alternaAis,
  // filterAlertArray,
  setIsDrawing,
  filtersVisible,
  setFiltersVisible,
  setIsErasing,
}: FiltroProps) {
  return (
    <Container>
      <div id="slotFiltro" className="blocoFechado">
        <Popover
          placement="bottomLeft"
          content={
            <ContainerWrapper
              alternaAis={alternaAis}
              onVisible={setFiltersVisible}
              // filterAlertArray={filterAlertArray}
              setIsDrawing={setIsDrawing}
              setIsErasing={setIsErasing}
            />
          }
          title="Filtros"
          trigger="click"
          open={filtersVisible}
          onOpenChange={isVisible => setFiltersVisible(isVisible)}
        >
          <button name="slotFiltro" type="button" onClick={() => {}}>
            &#9776;
          </button>
        </Popover>
      </div>
    </Container>
  );
}
