import { TrackingHistoryContext } from 'context/tracking_history';
import { useContextSelector } from 'use-context-selector';

export function useHistoryData() {
  const dataHistorico = useContextSelector(
    TrackingHistoryContext,
    context => context.dataHistorico
  );

  const setDataHistorico = useContextSelector(
    TrackingHistoryContext,
    context => context.setDataHistorico
  );

  return {
    dataHistorico,
    setDataHistorico,
  };
}

