export const KEY_VALUE_ROLE_MAP: any = {
  COTAM: 'ROLE_ROTAS_COTAM',
  COTAR: 'ROLE_ROTAS_COTAR',
  COD: 'ROLE_ROTAS_COTAR',
  GOE: 'ROLE_ROTAS_GOE',
  BOPE: 'ROLE_ROTAS_BOPE',
  BPRE: 'ROLE_ROTAS_BPRE',
  BPGEP: 'ROLE_ROTAS_BPGEP',
  BPTUR: 'ROLE_ROTAS_BPTUR',
  CHOQUE: 'ROLE_ROTAS_CHOQUE',
  COLOG: 'ROLE_ROTAS_COLOG',
  RAIO: 'ROLE_ROTAS_RAIO',
  BPE: 'ROLE_ROTAS_BPE',
  CPC: 'ROLE_ROTAS_CPC',
  CPG: 'ROLE_ROTAS_CPG',
  BPMA: 'ROLE_ROTAS_BPMA',
  FORTA: 'ROLE_ROTAS_FORTA',
  METRO: 'ROLE_ROTAS_METRO',
  INTER: 'ROLE_ROTAS_INTER',
  AIS_01: 'ROLE_ROTAS_AIS_01',
  AIS_02: 'ROLE_ROTAS_AIS_02',
  AIS_03: 'ROLE_ROTAS_AIS_03',
  AIS_04: 'ROLE_ROTAS_AIS_04',
  AIS_05: 'ROLE_ROTAS_AIS_05',
  AIS_06: 'ROLE_ROTAS_AIS_06',
  AIS_07: 'ROLE_ROTAS_AIS_07',
  AIS_08: 'ROLE_ROTAS_AIS_08',
  AIS_09: 'ROLE_ROTAS_AIS_09',
  AIS_10: 'ROLE_ROTAS_AIS_10',
  AIS_11: 'ROLE_ROTAS_AIS_11',
  AIS_12: 'ROLE_ROTAS_AIS_12',
  AIS_13: 'ROLE_ROTAS_AIS_13',
  AIS_14: 'ROLE_ROTAS_AIS_14',
  AIS_15: 'ROLE_ROTAS_AIS_15',
  AIS_16: 'ROLE_ROTAS_AIS_16',
  AIS_17: 'ROLE_ROTAS_AIS_17',
  AIS_18: 'ROLE_ROTAS_AIS_18',
  AIS_19: 'ROLE_ROTAS_AIS_19',
  AIS_20: 'ROLE_ROTAS_AIS_20',
  AIS_21: 'ROLE_ROTAS_AIS_21',
  AIS_22: 'ROLE_ROTAS_AIS_22',
  AIS_23: 'ROLE_ROTAS_AIS_23',
  AIS_24: 'ROLE_ROTAS_AIS_24',
  AIS_25: 'ROLE_ROTAS_AIS_25',
};

export const KEY_VIN_ROLE_MAP: any = {
  PC: 'ROLE_ROTAS_PC',
  PRF: 'ROLE_ROTAS_PRF',
  GM: 'ROLE_ROTAS_GM',
  COPEC: 'ROLE_ROTAS_COPEC',
  BMGSU: 'ROLE_ROTAS_BMGSU',
  PME: 'ROLE_ROTAS_PME',
  BMIS: 'ROLE_ROTAS_BMIS',
  PM: 'ROLE_ROTAS_PM',
  COMEL: 'ROLE_ROTAS_COMEL',
  TRANS: 'ROLE_ROTAS_TRANS',
  COPAC: 'ROLE_ROTAS_COPAC',
  CBM: 'ROLE_ROTAS_CBM',
  SAP: 'ROLE_ROTAS_SAP',
};
