import styled from "styled-components";
import { Button as ButtonAntd } from "antd";

export const ContainerFlex = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Button = styled(ButtonAntd)`
  display: flex;
  align-items: center;
  justify-content: center;
`;
