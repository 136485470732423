import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import logo64 from '../../../imgs/encoded/brasaosspds.base64';

export const pdfGen = async contents => {
  const win = window.open('', '_blank');
  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  const reportTitle = [
    {
      image: logo64,
      width: 179,
      height: 63,
      alignment: 'center',
      margin: [0, 10, 0, 0],
    },
    {
      text:
        'Relatório de veículos que enviaram posições nas últimas 48 horas \nmas não estão cadastrados no sistema da Hexagon\n',
      bold: true,
      fontSize: 13,
      alignment: 'center',
      margin: [0, 4, 0, 0],
    },
    {
      text: `Segue abaixo a lista com informações de veículos (${contents.length}).\n`,
      bold: false,
      fontSize: 9,
      alignment: 'center',
      margin: [0, 4, 0, 0],
    },
    {
      text:
        'Dados de posicionamento fornecidos pelas empresas Oi/CSBrasil/TELTRONIC\n',
      bold: false,
      fontSize: 9,
      alignment: 'center',
      margin: [0, 4, 0, 0],
    },
  ];

  // console.log('results', contents);

  const results = contents.map(content => {
    return [
      { text: content[0], fontSize: 9, margin: [0, 2, 0, 2] },
      { text: content[1], fontSize: 9, margin: [0, 2, 0, 2] },
      { text: content[2], fontSize: 9, margin: [0, 2, 0, 2] },
      { text: content[3], fontSize: 9, margin: [0, 2, 0, 2] },
      { text: content[4], fontSize: 9, margin: [0, 2, 0, 2] },
      {
        text: content[5],
        fontSize: 9,
        margin: [0, 2, 0, 2],
      },
      { text: content[6], fontSize: 9, margin: [0, 2, 0, 2] },
    ];
  });

  const details = [
    {
      alignment: 'center',
      margin: [0, 0, 0, 0],
      layout: {
        fillColor(rowIndex) {
          return rowIndex % 2 === 0 ? '#CCCCCC' : null;
        },
      },

      table: {
        headerRows: 1,
        widths: ['auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
        body: [
          [
            { text: 'Placa', style: 'tableHeader', fontSize: 10 },
            { text: 'Prefixo', style: 'tableHeader', fontSize: 10 },
            { text: 'Vinculada', style: 'tableHeader', fontSize: 10 },
            { text: 'Latitude', style: 'tableHeader', fontSize: 10 },
            { text: 'Longitude', style: 'tableHeader', fontSize: 10 },
            { text: 'Ultima Posição', style: 'tableHeader', fontSize: 10 },
            { text: 'Logradouro', style: 'tableHeader', fontSize: 10 },
          ],
          ...results,
        ],
      },
    },
  ];
  const footer = [
    {
      text: [
        {
          text:
            'Secretaria de Segurança Pública e Defesa Social. Avenida Bezerra de Menezes, 581 - São Gerardo\n CEP 60325-003 . Fortaleza Ceará . Fone: (85) 3101-6501',
        },
      ],
      fontSize: 8,
      margin: [0, 10, 0, 45],
      alignment: 'center',
    },
  ];

  const docDefinitions = {
    pageMargins: [40, 140, 40, 40],
    pageSize: 'A4',
    alignment: 'center',
    header: [reportTitle],
    content: [details],
    footer: [footer],
  };
  pdfMake.createPdf(docDefinitions).open({}, win);
};

// eslint-disable-next-line
export default  pdfGen;
