/* eslint-disable */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Modal,
  Form,
  Input,
  Row,
  Col,
  Select,
  DatePicker,
  Spin,
  Empty,
  Button,
  Pagination,
} from 'antd';
import { PropTypes } from 'prop-types';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import moment from 'moment';
import { AlertCard } from './components/card';
import { GenPdfButton, ModalBodyContainer } from './styles';
import { pdfGen } from './report';
import { CSVLink } from 'react-csv';
import { v4 as uuidv4 } from 'uuid';
import { useAlertModalInfo } from 'hooks/monitoring/useAlertModalInfo';

const { Option } = Select;
const { RangePicker } = DatePicker;

export const AlertModal = ({
  isOpenModalAlerts,
  setIsOpenModalAlerts,
  // alertsHistory,
}) => {
  const { alertsHistory } = useAlertModalInfo();

  const [form] = Form.useForm();
  const [state, setState] = useState({
    alertsFiltered: [],
    allAlerts: [],
    reportAlerts: [],
    filtered: false,
    valueSearched: null,
    filterSearched: '',
  });
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const updateState = useCallback(newProperties => {
    setState(prev => ({ ...prev, ...newProperties }));
  }, []);

  const CsvHeader = [
    { label: 'Placa', key: 'placa' },
    { label: 'Prefixo', key: 'prefixo' },
    { label: 'Tipo', key: 'tipoAlerta' },
    { label: 'Cerca', key: 'nomeCerca' },
    { label: 'Data', key: 'datePosition' },
    { label: 'Agencia', key: 'agencia' },
    { label: 'Grupo', key: 'grupo' },
    { label: 'Latitude', key: 'latitude' },
    { label: 'Longitude', key: 'longitude' },
  ];

  const width = screen.width;
  const isMobile = width <= 767;
  const isTablet = width >= 768 && width <= 991;

  let PAGE_SIZE = 3;
  if (isMobile) {
    PAGE_SIZE = 1;
  } else if (isTablet) {
    PAGE_SIZE = 2;
  } else {
    PAGE_SIZE = 3;
  }

  const [page, setPage] = useState({
    first: 0,
    last: 3,
  });

  const filterAlert = useRef({
    tipoAlerta: [],
    prefixo: '',
    data: [],
  });

  const loadAlerts = useCallback(alerts => {
    const limitedArray = [];
    const reportLimitedArray = [];
    alerts.map(a => {
      if (limitedArray.length >= 100) {
        limitedArray.pop();
      }
      limitedArray.unshift(a);
    });
    limitedArray.sort((a, b) =>
      a.datePosition < b.datePosition
        ? 1
        : a.datePosition > b.datePosition
        ? -1
        : 0
    );

    limitedArray.map(alert => {
      const newAlert = {
        ...alert,
        tipoAlerta: alert.foraAIS
          ? 'Fora Da Ais'
          : alert.foraCerca
          ? 'Fora Da Cerca'
          : alert.tipoAlerta,
      };

      reportLimitedArray.push(newAlert);
    });

    updateState({
      filtered: false,
      alertsFiltered: limitedArray,
      allAlerts: limitedArray,
      reportAlerts: reportLimitedArray,
    });
  }, []);

  useEffect(() => {
    if (state.filtered) {
      filterAlerts(state.valueSearched, state.filterSearched);
    } else {
      loadAlerts(alertsHistory?.current || []);
    }
  }, [alertsHistory?.current]);

  const filterAlerts = useCallback(
    (value, filter) => {
      try {
        setLoading(true);
        let dataFiltred = state.allAlerts;
        let reportDataFiltered = [];
        if (value) {
          updateState({
            filtered: true,
            valueSearched: value,
            filterSearched: filter,
          });

          if (filter) {
            filterAlert.current[filter] = value;
          }

          const search = {
            tipoAlerta: filterAlert.current.tipoAlerta,
            prefixo: filterAlert.current.prefixo,
            dataInicial: filterAlert.current.data[0]
              ? moment(filterAlert.current.data[0])
                  .format('YYYY-MM-DDTHH:mm:ss')
                  .substring(0, 19)
              : null,
            dataFinal: filterAlert.current.data[1]
              ? moment(filterAlert.current.data[1])
                  .format('YYYY-MM-DDTHH:mm:ss')
                  .substring(0, 19)
              : null,
          };

          if (search.prefixo) {
            dataFiltred = dataFiltred.filter(a =>
              a.prefixo.includes(search.prefixo)
            );
          }

          if (search.tipoAlerta.length > 0) {
            dataFiltred = dataFiltred.filter(a => {
              if (a.foraAIS) {
                return search.tipoAlerta.includes('FORA_AIS');
              }
              if (a.foraCerca) {
                return search.tipoAlerta.includes('SAIU_DA_CERCA');
              }
              return search.tipoAlerta.includes(a.tipoAlerta);
            });
          }

          if (search.dataInicial) {
            dataFiltred = dataFiltred.filter(a =>
              moment(a.datePosition).isSameOrAfter(moment(search.dataInicial))
            );
          }

          if (search.dataFinal) {
            dataFiltred = dataFiltred.filter(a =>
              moment(a.datePosition).isSameOrBefore(moment(search.dataFinal))
            );
          }

          setCurrentPage(1);
          setPage({
            first: 0,
            last: PAGE_SIZE,
          });
        } else {
          updateState({
            filtered: false,
            valueSearched: null,
            filterSearched: null,
          });
          filterAlert.current = {
            tipoAlerta: [],
            prefixo: '',
            data: [],
          };
        }

        dataFiltred.map(alert => {
          const newAlert = {
            ...alert,
            tipoAlerta: alert.foraAIS
              ? 'Fora Da Ais'
              : alert.foraCerca
              ? 'Fora Da Cerca'
              : alert.tipoAlerta,
          };

          reportDataFiltered.push(newAlert);
        });

        updateState({
          alertsFiltered: dataFiltred,
          reportAlerts: reportDataFiltered,
        });
      } catch (e) {
        console.error('Erro ao filtrar historico de alertas:', e);
      } finally {
        setLoading(false);
      }
    },
    [state, alertsHistory?.current]
  );

  return (
    <Modal
      open={isOpenModalAlerts}
      destroyOnClose
      onCancel={() => {
        setIsOpenModalAlerts(false);
      }}
      footer={null}
      width={800}
      title={
        <div style={{ display: 'flex' }}>
          <h2 style={{ flexGrow: 15 }}>Histórico de Alertas</h2>
          <GenPdfButton>
            <Button
              className="btnGerarRel"
              disabled={!state.alertsFiltered.length}
              loading={loading}
              onClick={() => pdfGen(state.reportAlerts)}
            >
              Gerar PDF
            </Button>
            <Button
              className="btnGerarCSV"
              disabled={!state.alertsFiltered.length}
              loading={loading}
            >
              <CSVLink
                data={state.reportAlerts}
                headers={CsvHeader}
                filename="R.O.T.A.S. Planilha de Monitoramento.csv"
                target="_blank"
              >
                Gerar CSV
              </CSVLink>
            </Button>
          </GenPdfButton>
        </div>
      }
    >
      <ModalBodyContainer>
        <Form
          form={form}
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Row key={1}>
            <Col key={2} span={5} offset={1} style={{ minWidth: '150px' }}>
              <Form.Item
                labelCol={{ span: 24 }}
                name="type"
                label="Tipo de Alerta"
              >
                <Select
                  onChange={e => filterAlerts(e, 'tipoAlerta')}
                  mode="multiple"
                >
                  <Option value="FORA_AIS" key={3}>
                    Fora da AIS
                  </Option>
                  <Option value="SAIU_DA_CERCA" key={4}>
                    Fora da Cerca
                  </Option>
                  <Option value="PARADA_SUPERIOR_15_MIN" key={5}>
                    Parada Superior 15 minutos
                  </Option>
                </Select>
              </Form.Item>
            </Col>

            <Col key={6} span={5} offset={1} style={{ minWidth: '150px' }}>
              <Form.Item
                onChange={e => filterAlerts(e.target.value, 'prefixo')}
                label="Prefixo"
                name="prefixo"
                labelCol={{ span: 24 }}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col key={7} span={10} offset={1} style={{ minWidth: '250px' }}>
              <Form.Item label="Data" name="date" labelCol={{ span: 24 }}>
                <RangePicker
                  onChange={e => filterAlerts(e, 'data')}
                  allowEmpty={[true, true]}
                  showTime
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <Spin spinning={loading} tip="Carregando...">
          <div
            style={{
              display: `flex`,
              justifyContent: 'center',
              alignItems: 'center',
              padding: '15px',
              background: 'rgba(245, 245, 245, .5)',
              borderRadius: '3px',
              height: '300px',
              overflowX: 'scroll',
              overflowY: 'hidden',
            }}
          >
            <Row>
              {state.alertsFiltered.length > 0 ? (
                state.alertsFiltered
                  .slice(page.first, page.first + PAGE_SIZE)
                  .map((alert, i) => (
                    <AlertCard
                      key={uuidv4()}
                      lastIndex={
                        state.alertsFiltered.indexOf(alert) === page.last - 1
                      }
                      type={alert.tipoAlerta}
                      outAIS={alert.foraAIS}
                      date={alert.datePosition}
                      prefixo={alert.prefixo}
                      placa={alert.placa}
                      cerca={alert.nomeCerca}
                      ais={alert.grupo}
                      velocity={alert.speed}
                    />
                  ))
              ) : (
                <Empty description="Não há alertas recentes" />
              )}
            </Row>
            <Button
              disabled={page.first === 0}
              onClick={() => {
                setCurrentPage(currentPage - 1);
                setPage(prev => ({
                  first: prev.first - PAGE_SIZE,
                  last: prev.last - PAGE_SIZE,
                }));
              }}
              className="previusPageIcon"
              icon={<LeftOutlined />}
            />

            <Button
              disabled={page.last >= state.alertsFiltered.length}
              onClick={() => {
                setCurrentPage(currentPage + 1);
                setPage(prev => ({
                  first: prev.first + PAGE_SIZE,
                  last: prev.last + PAGE_SIZE,
                }));
              }}
              className="nextPageIcon"
              icon={<RightOutlined />}
            />
          </div>
          <Pagination
            style={{ textAlign: 'center', paddingTop: '15px' }}
            defaultCurrent={1}
            total={state.alertsFiltered.length}
            current={currentPage}
            pageSize={3}
            showSizeChanger={false}
            onChange={pageChange => {
              setCurrentPage(pageChange);
              setPage(prev => ({
                first: (prev.first = (pageChange - 1) * PAGE_SIZE),
                last: (prev.last = pageChange * PAGE_SIZE),
              }));
            }}
          />
        </Spin>
      </ModalBodyContainer>
    </Modal>
  );
};

AlertModal.propTypes = {
  isOpenModalAlerts: PropTypes.bool.isRequired,
  setIsOpenModalAlerts: PropTypes.func.isRequired,
};

