import { globalContext } from 'context/global';
import { useContextSelector } from 'use-context-selector';

export function useLoadLastPositions() {
  const loadLastPositions = useContextSelector(
    globalContext,
    context => context.loadLastPositions
  );

  return {
    loadLastPositions,
  };
}

