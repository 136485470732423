/* eslint-disable */
// import bairrosCEJson from '../dados/CE_Bairros_2021.json';
// import microregioesCE from '../dados/CE_Microrregioes_2021.json';
// import municipiosCE from '../dados/CE_Municipios_2021.json';
import { getGeocode } from 'use-places-autocomplete';
import dadosLatLngAis from '../dados/ais/dadosAis.json';
import {
  BairrosCE,
  BairrosCEFeature,
  BairrosCEFormatted,
  MicrorregioesCE,
  MicrorregioesCEFeature,
  MicrorregioesCEFormatted,
  MunicipiosCE,
  MunicipiosCEFeature,
  MunicipiosCEFormatted,
} from 'interfaces/functionsUtils';
import { dataPropertiesAux } from 'interfaces/vehicle';

const bairrosCEJson = require('../dados/CE_Bairros_2021.json') as BairrosCE;
const microregioesCEJson = require('../dados/CE_Microrregioes_2021.json') as MicrorregioesCE;
const municipiosCEJson = require('../dados/CE_Municipios_2021.json') as MunicipiosCE;

interface DefaultOptionsProps {
  duration: any;
  easing: string;
  complete: any;
}

type TLagLng = {
  lat: number;
  lng: number;
};

declare var jQuery: any;

const MAJOR_RADIUS = 6378137.0;
const MINOR_RADIUS = 6356752.314;
const SCALE_FACTOR = 0.9996;

export const allAis = [
  {
    value: 'AIS01',
    label: 'AIS 01',
  },
  {
    value: 'AIS02',
    label: 'AIS 02',
  },
  {
    value: 'AIS03',
    label: 'AIS 03',
  },
  {
    value: 'AIS04',
    label: 'AIS 04',
  },
  {
    value: 'AIS05',
    label: 'AIS 05',
  },
  {
    value: 'AIS06',
    label: 'AIS 06',
  },
  {
    value: 'AIS07',
    label: 'AIS 07',
  },
  {
    value: 'AIS08',
    label: 'AIS 08',
  },
  {
    value: 'AIS09',
    label: 'AIS 09',
  },
  {
    value: 'AIS10',
    label: 'AIS 10',
  },
  {
    value: 'AIS11',
    label: 'AIS 11',
  },
  {
    value: 'AIS12',
    label: 'AIS 12',
  },
  {
    value: 'AIS13',
    label: 'AIS 13',
  },
  {
    value: 'AIS14',
    label: 'AIS 14',
  },
  {
    value: 'AIS15',
    label: 'AIS 15',
  },
  {
    value: 'AIS16',
    label: 'AIS 16',
  },
  {
    value: 'AIS17',
    label: 'AIS 17',
  },
  {
    value: 'AIS18',
    label: 'AIS 18',
  },
  {
    value: 'AIS19',
    label: 'AIS 19',
  },
  {
    value: 'AIS20',
    label: 'AIS 20',
  },
  {
    value: 'AIS21',
    label: 'AIS 21',
  },
  {
    value: 'AIS22',
    label: 'AIS 22',
  },
  {
    value: 'AIS23',
    label: 'AIS 23',
  },
  {
    value: 'AIS24',
    label: 'AIS 24',
  },
  {
    value: 'AIS25',
    label: 'AIS 25',
  },
];

export const allEspecializadas = [
  'COTAM',
  'COTAR',
  'COD',
  'GOE',
  'BOPE',
  'BPRE',
  //  'BPEsp',
  'BPGEP',
  'BPTUR',
  'CHOQUE',
  'COLOG',
  'RAIO',
  'BPE',
  'CPC',
  'CPG',
  'BPMA',
  'FORTA',
  'METRO',
  'INTER',
];

export const prefixoIsHT = (prefixo: string) =>
  prefixo.substring(0, 2) === 'HT' ||
  prefixo.substring(0, 4) === '2030' ||
  prefixo.substring(0, 4) === '2031' ||
  prefixo.substring(0, 4) === '2032' ||
  prefixo.substring(0, 4) === '2033' ||
  prefixo.substring(0, 4) === '2034' ||
  prefixo.includes('RAIO');

export const validaDentroOuForaArea = ({
  pontosArea,
  lat,
  lng,
}: {
  pontosArea: any;
  lat: any;
  lng: any;
}) => {
  let _dentro = false;
  for (let i = 0, j = pontosArea.length - 1; i < pontosArea.length; j = i++) {
    const _latI = pontosArea[i][0];
    const _lngI = pontosArea[i][1];
    const _latJ = pontosArea[j][0];
    const _lngJ = pontosArea[j][1];
    const _intercessao =
      _lngI > lng !== _lngJ > lng &&
      lat < ((_latJ - _latI) * (lng - _lngI)) / (_lngJ - _lngI) + _latI;

    if (_intercessao) {
      _dentro = !_dentro;
    }
  }
  return _dentro;
};

export const isValidCPF = (cpf: string) => {
  let Soma;
  let Resto;
  Soma = 0;
  if (cpf === '00000000000' || cpf.length !== 11) return false;

  for (let i = 1; i <= 9; i += 1)
    Soma += parseInt(cpf.substring(i - 1, i), 10) * (11 - i);
  Resto = (Soma * 10) % 11;

  if (Resto === 10 || Resto === 11) Resto = 0;
  if (Resto != parseInt(cpf.substring(9, 10), 10)) return false;

  Soma = 0;
  for (let i = 1; i <= 10; i += 1)
    Soma += parseInt(cpf.substring(i - 1, i), 10) * (12 - i);
  Resto = (Soma * 10) % 11;

  if (Resto === 10 || Resto === 11) Resto = 0;
  if (Resto !== parseInt(cpf.substring(10, 11), 10)) return false;
  return true;
};

export const comparePositionObjects = (
  obj1: dataPropertiesAux,
  obj2: dataPropertiesAux
) => {
  return (
    obj1.conectado === obj2.conectado &&
    obj1.datePosition === obj2.datePosition &&
    obj1.ais === obj2.ais &&
    obj1.grupo === obj2.grupo &&
    obj1.latitude === obj2.latitude &&
    obj1.longitude === obj2.longitude &&
    obj1.numAis === obj2.numAis &&
    obj1.prefixo === obj2.prefixo &&
    obj1.statusConexaoViatura === obj2.statusConexaoViatura &&
    obj1.foraAIS === obj2.foraAIS &&
    obj1.foraCerca === obj2.foraCerca
  );
};

export const compareOccurrenceObjects = (obj1: any, obj2: any) => {
  return (
    obj1.agencia === obj2.agencia &&
    obj1.tipoCodigo === obj2.tipoCodigo &&
    obj1.tipoDesc === obj2.tipoDesc &&
    obj1.grupo === obj2.grupo &&
    obj1.dtCriacao === obj2.dtCriacao &&
    obj1.dtDespacho === obj2.dtDespacho &&
    obj1.dpEmRota === obj2.dpEmRota &&
    obj1.dtChegada === obj2.dtChegada &&
    obj1.dtTransporte === obj2.dtTransporte &&
    obj1.dtChegadaTransporte === obj2.dtChegadaTransporte &&
    obj1.finalizado === obj2.finalizado
  );
};

export const compareAlertObjects = (obj1: any, obj2: any) => {
  return (
    obj1.agencia === obj2.agencia &&
    obj1.grupo === obj2.grupo &&
    obj1.datePosition === obj2.datePosition &&
    obj1.idDevice === obj2.idDevice &&
    obj1.placa === obj2.placa &&
    obj1.latitude === obj2.latitude &&
    obj1.longitude === obj2.longitude &&
    obj1.speed === obj2.speed
  );
};

export const compareNewAlertObjects = (obj1: any, obj2: any) => {
  return (
    obj1.agencia === obj2.agencia &&
    obj1.grupo === obj2.grupo &&
    obj1.datePosition === obj2.datePosition &&
    obj1.idDevice === obj2.idDevice &&
    obj1.placa === obj2.placa &&
    obj1.latitude === obj2.latitude &&
    obj1.longitude === obj2.longitude &&
    obj1.speed === obj2.speed &&
    obj1.tipoAlerta === obj2.tipoAlerta
  );
};

export const UTMConverter = {
  /* Ellipsoid model constants (actual values here are for WGS84) */

  getFootpointLatitude: (y: number) => {
    /* Precalculate n (Eq. 10.18) */
    const n = (MAJOR_RADIUS - MINOR_RADIUS) / (MAJOR_RADIUS + MINOR_RADIUS);

    /* Precalculate alpha_ (Eq. 10.22) */
    /* (Same as alpha in Eq. 10.17) */
    const alpha_ =
      ((MAJOR_RADIUS + MINOR_RADIUS) / 2.0) *
      (1 + Math.pow(n, 2.0) / 4 + Math.pow(n, 4.0) / 64);

    /* Precalculate y_ (Eq. 10.23) */
    const y_ = y / alpha_;

    /* Precalculate beta_ (Eq. 10.22) */
    const beta_ =
      (3.0 * n) / 2.0 +
      (-27.0 * Math.pow(n, 3.0)) / 32.0 +
      (269.0 * Math.pow(n, 5.0)) / 512.0;

    /* Precalculate gamma_ (Eq. 10.22) */
    const gamma_ =
      (21.0 * Math.pow(n, 2.0)) / 16.0 + (-55.0 * Math.pow(n, 4.0)) / 32.0;

    /* Precalculate delta_ (Eq. 10.22) */
    const delta_ =
      (151.0 * Math.pow(n, 3.0)) / 96.0 + (-417.0 * Math.pow(n, 5.0)) / 128.0;

    /* Precalculate epsilon_ (Eq. 10.22) */
    const epsilon_ = (1097.0 * Math.pow(n, 4.0)) / 512.0;

    /* Now calculate the sum of the series (Eq. 10.21) */
    const result =
      y_ +
      beta_ * Math.sin(2.0 * y_) +
      gamma_ * Math.sin(4.0 * y_) +
      delta_ * Math.sin(6.0 * y_) +
      epsilon_ * Math.sin(8.0 * y_);

    return result;
  },

  mapPointToLatLng: (x: any, y: any, lambda0: number) => {
    const phif = UTMConverter.getFootpointLatitude(y);

    const ep2 =
      (Math.pow(MAJOR_RADIUS, 2.0) - Math.pow(MINOR_RADIUS, 2.0)) /
      Math.pow(MINOR_RADIUS, 2.0);

    const cf = Math.cos(phif);

    const nuf2 = ep2 * Math.pow(cf, 2.0);

    const Nf =
      Math.pow(MAJOR_RADIUS, 2.0) / (MINOR_RADIUS * Math.sqrt(1 + nuf2));
    let Nfpow = Nf;

    const tf = Math.tan(phif);
    const tf2 = tf * tf;
    const tf4 = tf2 * tf2;

    const x1frac = 1.0 / (Nfpow * cf);

    Nfpow *= Nf; /* now equals Nf**2) */
    const x2frac = tf / (2.0 * Nfpow);

    Nfpow *= Nf; /* now equals Nf**3) */
    const x3frac = 1.0 / (6.0 * Nfpow * cf);

    Nfpow *= Nf; /* now equals Nf**4) */
    const x4frac = tf / (24.0 * Nfpow);

    Nfpow *= Nf; /* now equals Nf**5) */
    const x5frac = 1.0 / (120.0 * Nfpow * cf);

    Nfpow *= Nf; /* now equals Nf**6) */
    const x6frac = tf / (720.0 * Nfpow);

    Nfpow *= Nf; /* now equals Nf**7) */
    const x7frac = 1.0 / (5040.0 * Nfpow * cf);

    Nfpow *= Nf; /* now equals Nf**8) */
    const x8frac = tf / (40320.0 * Nfpow);

    const x2poly = -1.0 - nuf2;

    const x3poly = -1.0 - 2 * tf2 - nuf2;

    const x4poly =
      5.0 +
      3.0 * tf2 +
      6.0 * nuf2 -
      6.0 * tf2 * nuf2 -
      3.0 * (nuf2 * nuf2) -
      9.0 * tf2 * (nuf2 * nuf2);

    const x5poly =
      5.0 + 28.0 * tf2 + 24.0 * tf4 + 6.0 * nuf2 + 8.0 * tf2 * nuf2;

    const x6poly =
      -61.0 - 90.0 * tf2 - 45.0 * tf4 - 107.0 * nuf2 + 162.0 * tf2 * nuf2;

    const x7poly = -61.0 - 662.0 * tf2 - 1320.0 * tf4 - 720.0 * (tf4 * tf2);

    const x8poly = 1385.0 + 3633.0 * tf2 + 4095.0 * tf4 + 1575 * (tf4 * tf2);

    const latRad =
      phif +
      x2frac * x2poly * (x * x) +
      x4frac * x4poly * Math.pow(x, 4.0) +
      x6frac * x6poly * Math.pow(x, 6.0) +
      x8frac * x8poly * Math.pow(x, 8.0);

    const lngRad =
      lambda0 +
      x1frac * x +
      x3frac * x3poly * Math.pow(x, 3.0) +
      x5frac * x5poly * Math.pow(x, 5.0) +
      x7frac * x7poly * Math.pow(x, 7.0);

    const radiansToDegress = (rad: number) => {
      return rad * (180 / Math.PI);
    };

    return {
      lat: radiansToDegress(latRad),
      lng: radiansToDegress(lngRad),
    };
  },

  getCentralMeridian: (zone: any) => {
    const degreesToRadians = (deg: number) => (deg * Math.PI) / 180.0;

    return degreesToRadians(-183.0 + zone * 6.0);
  },

  convertToLatLng: (x: number, y: number, zone: any, southhemi: any) => {
    x -= 500000.0;
    x /= SCALE_FACTOR;

    if (southhemi) y -= 10000000.0;

    y /= SCALE_FACTOR;

    const cmeridian = UTMConverter.getCentralMeridian(zone);
    return UTMConverter.mapPointToLatLng(x, y, cmeridian);
  },
};

export const haversineDistance = (mk1: any, mk2: any) => {
  const R = 6371.071;
  const rlat1 = Number(mk1.lat) * (Math.PI / 180);
  const rlat2 = Number(mk2.lat) * (Math.PI / 180);
  const difflat = rlat2 - rlat1;
  const difflon = (Number(mk2.lng) - Number(mk1.lng)) * (Math.PI / 180);

  const d =
    2 *
    R *
    Math.asin(
      Math.sqrt(
        Math.sin(difflat / 2) * Math.sin(difflat / 2) +
          Math.cos(rlat1) *
            Math.cos(rlat2) *
            Math.sin(difflon / 2) *
            Math.sin(difflon / 2)
      )
    );

  return d * 1000;
};

const bairrosCE: BairrosCEFeature[] = bairrosCEJson.features;

export const bairrosCEFormatted: BairrosCEFormatted[] = bairrosCE.map(
  (m: any) => ({
    ...m,
    geometry: {
      ...m.geometry,
      coordinates: m.geometry.coordinates[0][0].map((c: any) => {
        return UTMConverter.convertToLatLng(c[0], c[1], 24, true);
      }),
    },
    name: m.properties.nome_bairro,
    type: 'bairro',
    id: m.properties.id,
  })
);

const microregioesCE: MicrorregioesCEFeature[] = microregioesCEJson.features;

export const microregioesCEFormatted: MicrorregioesCEFormatted[] = microregioesCE.map(
  (m: any) => ({
    ...m,
    geometry: {
      ...m.geometry,
      coordinates: m.geometry.coordinates[0].map((c: any) => ({
        lat: c[1],
        lng: c[0],
      })),
    },
    name: m.properties.NM_MICRO,
    type: 'micro-região',
    id: m.properties.CD_MICRO,
  })
);

const municipiosCE: MunicipiosCEFeature[] = municipiosCEJson.features;

export const municipiosCEFormatted: MunicipiosCEFormatted[] = municipiosCE.map(
  (m: any) => ({
    ...m,
    geometry: {
      ...m.geometry,
      coordinates: m.geometry.coordinates[0].map((c: any) => ({
        lat: c[1],
        lng: c[0],
      })),
    },
    name: m.properties.NM_MUN,
    type: 'município',
    id: m.properties.CD_MUN,
  })
);

export function download(filename: any, text: any) {
  const element = document.createElement('a');
  element.setAttribute(
    'href',
    `data:text/plain;charset=utf-8, ${encodeURIComponent(text)}`
  );
  element.setAttribute('download', filename);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}

export const createKMLFile = () => {
  const colors = [
    '50FF5733',
    '50C70039',
    '50900C3F',
    '50581845',
    '50FFC300',
    '50DAF7A6',
    '50FF5733',
    '50FF00FF',
    '5000FF00',
    '500000FF',
    '50008B00',
    '50FFA07A',
    '507FFFD4',
    '5040E0D0',
    '50FF0000',
    '507FFF00',
    '50FFD700',
    '50FF1493',
    '5000FF7F',
    '5000BFFF',
    '50FF4500',
    '509400D3',
    '5000FA9A',
    '509932CC',
    '50FF8C00',
  ];

  const centers = Array(25)
    .fill(0)
    .map((_, index) => {
      return dadosLatLngAis[index].map((coord: any) => ({
        lat: coord[0],
        lng: coord[1],
      }));
    })
    .map(path => {
      let bounds = new window.google.maps.LatLngBounds();
      path.map((point: any) => {
        bounds.extend(point);
      });
      return bounds;
    });

  let kml = '<?xml version="1.0" encoding="UTF-8"?>\n';
  kml += '<kml xmlns="http://www.opengis.net/kml/2.2">\n';
  kml += '  <Document>\n';

  kml += '    <name>My Map</name>\n';
  kml += '    <description>A map with a polygon</description>\n';

  Array(25)
    .fill(0)
    .map((_, index) => {
      kml += `    <Style id="${index}">\n`;
      kml += `      <LineStyle>\n`;
      kml += `        <color>${colors[index]}</color>\n`;
      kml += `        <width>0.5</width>\n`;
      kml += `      </LineStyle>\n`;
      kml += `      <PolyStyle>\n`;
      kml += `        <fill>1</fill>\n`;
      kml += `        <color>${colors[index]}</color>\n`;
      kml += `      </PolyStyle>\n`;
      kml += `    </Style>\n`;

      // kml += `    <Style id="${index + 25}">\n`;
      // kml += `      <IconStyle>\n`;
      // kml += `        <Icon>\n`;
      // kml += `          <href>${ViaturaCinza}</href>\n`; // <href>https://maps.google.com/mapfiles/transparent.png</href>\n`;
      // // kml += `          <scale>1.0</scale>\n`;
      // kml += `        </Icon>\n`;
      // // kml += `        <hotSpot x="32" y="1" xunits="pixels" yunits="pixels"/>\n`;
      // // kml += `        <color>ff0000ff</color>\n`;
      // kml += `        <scale>1.5</scale>\n`;
      // kml += `      </IconStyle>\n`;

      // kml += `      <LabelStyle>\n`;
      // kml += `        <color>ff0000ff</color>\n`;
      // kml += `        <scale>1.0</scale>\n`;
      // kml += `      </LabelStyle>\n`;

      // kml += `      <ExtendedData>\n`;
      // kml += `        <Data name="Description">\n`;
      // kml += `          <value>My Marker Description</value>\n`;
      // kml += `        </Data>\n`;
      // kml += `      </ExtendedData>\n`;

      // kml += `      <BalloonStyle>\n`;
      // kml += `        <text><![CDATA[<h3>$[name]</h3>]]></text>\n`;
      // kml += `        <displayMode>default</displayMode>\n`;
      // // kml += `        <open>1</open>`;
      // kml += `      </BalloonStyle>\n`;
      // kml += `    </Style>\n`;
    });

  Array(25)
    .fill(0)
    .map((_, index) => {
      // kml += `    <Placemark>\n`;
      // kml += `      <name>AIS ${index + 1}</name>\n`;
      // kml += `      <styleUrl>#${index + 25}</styleUrl>\n`;

      // kml += `      <Point>\n`;
      // kml += `         <coordinates>${centers[index]
      //   .getCenter()
      //   .lng()},${centers[index].getCenter().lat()},0</coordinates>\n`;
      // kml += `      </Point>\n`;

      // kml += `      <ScreenOverlay>\n`;
      // kml += `        <name>TESTAAANDO</name>\n`;
      // kml += `        <Icon>\n`;
      // kml += `          <href>https://maps.google.com/mapfiles/transparent.png</href>\n`;
      // kml += `        </Icon>\n`;

      // kml += `        <overlayXY x="0.5" y="0.5" xunits="fraction" yunits="fraction"/>\n`;
      // kml += `        <screenXY x="0.5" y="0.5" xunits="fraction" yunits="fraction"/>\n`;
      // kml += `        <size x="0" y="0" xunits="pixels" yunits="pixels"/>\n`;

      // kml += `        <BalloonStyle>`;
      // kml += `          <text><![CDATA[<b>$[name]</b>]]></text>`;
      // // kml += `        <displayMode>default</displayMode>`;
      // kml += `          <open>1</open>`;
      // kml += `        </BalloonStyle>`;

      // kml += `      </ScreenOverlay>\n`;

      // kml += `    </Placemark>\n`;
      kml += `    <Placemark>\n`;
      kml += `      <name>AIS ${index + 1}</name>\n`;
      kml += `      <styleUrl>#${index}</styleUrl>\n`;
      kml += `      <Polygon>\n`;
      kml += `        <outerBoundaryIs>\n`;
      kml += `          <LinearRing>\n`;
      kml += `            <coordinates>\n`;

      dadosLatLngAis[index].map((coord: any) => {
        kml += `${coord[1]},${coord[0]},0\n`;
      });

      kml += `            </coordinates>\n`;
      kml += `          </LinearRing>\n`;
      kml += `        </outerBoundaryIs>\n`;
      kml += `      </Polygon>\n`;

      kml += `    </Placemark>\n`;
    });

  kml += '  </Document>\n';
  kml += '</kml>';

  download('teste_kml.kml', kml);
};

export function animateMapComponent(
  type: string,
  newPosition: any,
  duration: any,
  optionsSelected: any
) {
  const defaultOptions: DefaultOptionsProps = {
    duration,
    easing: 'linear',
    complete: null,
  };
  const options = optionsSelected || {};

  // complete missing options
  for (let key in defaultOptions) {
    options[key] =
      options[key] || defaultOptions[key as keyof DefaultOptionsProps];
  }

  // throw exception if easing function doesn't exist
  if (options.easing != 'linear') {
    if (typeof jQuery == 'undefined' || !jQuery.easing[options.easing]) {
      throw '"' +
        options.easing +
        '" easing function doesn\'t exist. Include jQuery and/or the jQuery easing plugin and use the right function name.';
      return;
    }
  }

  window.requestAnimationFrame =
    window.requestAnimationFrame ||
    //@ts-ignore
    window.mozRequestAnimationFrame ||
    //@ts-ignore
    window.webkitRequestAnimationFrame ||
    //@ts-ignore
    window.msRequestAnimationFrame;
  window.cancelAnimationFrame =
    //@ts-ignore
    window.cancelAnimationFrame || window.mozCancelAnimationFrame;

  // save current position. prefixed to avoid name collisions. separate for lat/lng to avoid calling lat()/lng() in every frame
  if (type === 'circle') {
    //@ts-ignore
    this.AT_startPosition_lat = this.getCenter().lat();
    //@ts-ignore
    this.AT_startPosition_lng = this.getCenter().lng();
  } else if (type === 'marker') {
    //@ts-ignore
    this.AT_startPosition_lat = this.position.lat;
    //@ts-ignore
    this.AT_startPosition_lng = this.position.lng;
  }
  let newPosition_lat = newPosition.lat();
  let newPosition_lng = newPosition.lng();

  // crossing the 180° meridian and going the long way around the earth?
  //@ts-ignore
  if (Math.abs(newPosition_lng - this.AT_startPosition_lng) > 180) {
    //@ts-ignore
    if (newPosition_lng > this.AT_startPosition_lng) {
      newPosition_lng -= 360;
    } else {
      newPosition_lng += 360;
    }
  }

  let animateStep = function(component: any, startTime: any) {
    let ellapsedTime = new Date().getTime() - startTime;
    let durationRatio = ellapsedTime / options.duration; // 0 - 1
    let easingDurationRatio = durationRatio;

    // use jQuery easing if it's not linear
    if (options.easing !== 'linear') {
      easingDurationRatio = jQuery.easing[options.easing](
        durationRatio,
        ellapsedTime,
        0,
        1,
        options.duration
      );
    }

    if (durationRatio < 1) {
      let deltaPosition = new window.google.maps.LatLng(
        component.AT_startPosition_lat +
          (newPosition_lat - component.AT_startPosition_lat) *
            easingDurationRatio,
        component.AT_startPosition_lng +
          (newPosition_lng - component.AT_startPosition_lng) *
            easingDurationRatio
      );
      if (type === 'circle') {
        component.setCenter(deltaPosition);
      } else if (type === 'marker') {
        component.position = deltaPosition;
      }

      // use requestAnimationFrame if it exists on this browser. If not, use setTimeout with ~60 fps
      if (window.requestAnimationFrame) {
        component.AT_animationHandler = window.requestAnimationFrame(
          function() {
            animateStep(component, startTime);
          }
        );
      } else {
        component.AT_animationHandler = setTimeout(function() {
          animateStep(component, startTime);
        }, 17);
      }
    } else {
      if (type === 'circle') {
        component.setCenter(newPosition);
      } else if (type === 'marker') {
        component.position = newPosition;
      }

      if (typeof options.complete === 'function') {
        options.complete();
      }
    }
  };

  // stop possibly running animation
  if (window.cancelAnimationFrame) {
    //@ts-ignore
    window.cancelAnimationFrame(this.AT_animationHandler);
  } else {
    //@ts-ignore
    clearTimeout(this.AT_animationHandler);
  }
  //@ts-ignore
  animateStep(this, new Date().getTime());
}

export const marcadorDentroArea = (posMarcador: any, area: any) => {
  try {
    const { latitude: lat, longitude: lng } = posMarcador;
    if (area) {
      let _pontosArea;
      let _dentro = false;

      if (!lat || !lng) {
        return false;
      }

      if (area) {
        // _pontosArea = dadosAis[area - 1];

        // console.log(_pontosArea);
        _dentro = validaDentroOuForaArea({
          pontosArea: _pontosArea,
          lat,
          lng,
        });
      } else {
        _dentro = true;
      }

      return _dentro;
    }
  } catch (e) {
    console.error(e);

    return true;
  }
};

// function(
//   newPosition,
//   duration,
//   optionsSelected
// ) {
//   const defaultOptions = {
//     duration,
//     easing: 'linear',
//     complete: null,
//   };
//   const options = optionsSelected || {};

//   // complete missing options
//   for (let key in defaultOptions) {
//     options[key] = options[key] || defaultOptions[key];
//   }

//   // throw exception if easing function doesn't exist
//   if (options.easing != 'linear') {
//     if (
//       typeof jQuery == 'undefined' ||
//       !jQuery.easing[options.easing]
//     ) {
//       throw '"' +
//         options.easing +
//         '" easing function doesn\'t exist. Include jQuery and/or the jQuery easing plugin and use the right function name.';
//       return;
//     }
//   }

//   window.requestAnimationFrame =
//     window.requestAnimationFrame ||
//     window.mozRequestAnimationFrame ||
//     window.webkitRequestAnimationFrame ||
//     window.msRequestAnimationFrame;
//   window.cancelAnimationFrame =
//     window.cancelAnimationFrame || window.mozCancelAnimationFrame;

//   // save current position. prefixed to avoid name collisions. separate for lat/lng to avoid calling lat()/lng() in every frame
//   this.AT_startPosition_lat = this.getPosition().lat();
//   this.AT_startPosition_lng = this.getPosition().lng();
//   let newPosition_lat = newPosition.lat();
//   let newPosition_lng = newPosition.lng();

//   // crossing the 180° meridian and going the long way around the earth?
//   if (Math.abs(newPosition_lng - this.AT_startPosition_lng) > 180) {
//     if (newPosition_lng > this.AT_startPosition_lng) {
//       newPosition_lng -= 360;
//     } else {
//       newPosition_lng += 360;
//     }
//   }

//   let animateStep = function(marker, startTime) {
//     let ellapsedTime = new Date().getTime() - startTime;
//     let durationRatio = ellapsedTime / options.duration; // 0 - 1
//     let easingDurationRatio = durationRatio;

//     // use jQuery easing if it's not linear
//     if (options.easing !== 'linear') {
//       easingDurationRatio = jQuery.easing[options.easing](
//         durationRatio,
//         ellapsedTime,
//         0,
//         1,
//         options.duration
//       );
//     }

//     if (durationRatio < 1) {
//       let deltaPosition = new google.maps.LatLng(
//         marker.AT_startPosition_lat +
//           (newPosition_lat - marker.AT_startPosition_lat) *
//             easingDurationRatio,
//         marker.AT_startPosition_lng +
//           (newPosition_lng - marker.AT_startPosition_lng) *
//             easingDurationRatio
//       );
//       marker.setPosition(deltaPosition);

//       // use requestAnimationFrame if it exists on this browser. If not, use setTimeout with ~60 fps
//       if (window.requestAnimationFrame) {
//         marker.AT_animationHandler = window.requestAnimationFrame(
//           function() {
//             animateStep(marker, startTime);
//           }
//         );
//       } else {
//         marker.AT_animationHandler = setTimeout(function() {
//           animateStep(marker, startTime);
//         }, 17);
//       }
//     } else {
//       marker.setPosition(newPosition);

//       if (typeof options.complete === 'function') {
//         options.complete();
//       }
//     }
//   };

//   // stop possibly running animation
//   if (window.cancelAnimationFrame) {
//     window.cancelAnimationFrame(this.AT_animationHandler);
//   } else {
//     clearTimeout(this.AT_animationHandler);
//   }

//   animateStep(this, new Date().getTime());
// };

export function hslToRgb(hslColor: any) {
  const div = (val: number) => {
    return val / 255;
  };

  const match = hslColor.match(/^hsl\((\d+),\s*([\d.]+)%,\s*([\d.]+)%\)$/);

  if (!match) {
    throw new Error(`Invalid HSL color format: ${hslColor}`);
  }

  const h = Number(match[1]);
  const s = Number(match[2]) / 100;
  const l = Number(match[3]) / 100;

  const hue2rgb = (p: any, q: any, t: any) => {
    if (t < 0) t += 1;
    if (t > 1) t -= 1;
    if (t < 1 / 6) return p + (q - p) * 6 * t;
    if (t < 1 / 2) return q;
    if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
    return p;
  };

  let r, g, b;

  if (s === 0) {
    r = g = b = l; // achromatic
  } else {
    const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    const p = 2 * l - q;

    r = Math.round(div(hue2rgb(p, q, h + 1 / 3)) * 255);
    g = Math.round(div(hue2rgb(p, q, h)) * 255);
    b = Math.round(div(hue2rgb(p, q, h - 1 / 3)) * 255);
  }

  return { r, g, b };
}

export const mapPolylinesColors = [
  {
    r: 255,
    g: 0,
    b: 0,
  },
  {
    r: 0,
    g: 255,
    b: 0,
  },
  {
    r: 0,
    g: 0,
    b: 255,
  },
  {
    // rosa
    r: 255,
    g: 0,
    b: 255,
  },
  {
    // roxo
    r: 128,
    g: 0,
    b: 128,
  },
  {
    // marrom dourado
    r: 128,
    g: 128,
    b: 0,
  },
  {
    r: 0,
    g: 128,
    b: 128,
  },
  {
    r: 255,
    g: 0,
    b: 128,
  },
  {
    r: 0,
    g: 128,
    b: 0,
  },
  {
    r: 0,
    g: 0,
    b: 128,
  },
  {
    r: 128,
    g: 0,
    b: 0,
  },
  {
    r: 128,
    g: 128,
    b: 128,
  },
  {
    r: 64,
    g: 64,
    b: 64,
  },
  {
    r: 0,
    g: 0,
    b: 0,
  },
  {
    r: 255,
    g: 99,
    b: 71,
  },
  {
    r: 255,
    g: 140,
    b: 0,
  },
  {
    r: 0,
    g: 128,
    b: 0,
  },
  {
    r: 0,
    g: 255,
    b: 0,
  },
  {
    r: 0,
    g: 100,
    b: 0,
  },
  {
    r: 0,
    g: 0,
    b: 128,
  },
  {
    r: 70,
    g: 130,
    b: 180,
  },
  {
    r: 255,
    g: 228,
    b: 196,
  },
  {
    r: 0,
    g: 0,
    b: 205,
  },
  { r: 26, g: 13, b: 171 },
  { r: 44, g: 62, b: 80 },
  { r: 40, g: 55, b: 71 },
  { r: 34, g: 49, b: 63 },
  { r: 23, g: 32, b: 42 },
  { r: 18, g: 25, b: 31 },
  { r: 15, g: 15, b: 15 },
  { r: 17, g: 17, b: 19 },
  { r: 41, g: 128, b: 185 },
  { r: 52, g: 73, b: 94 },
  { r: 44, g: 62, b: 80 },
  { r: 35, g: 47, b: 62 },
  { r: 47, g: 54, b: 64 },
  { r: 70, g: 70, b: 70 },
  { r: 55, g: 59, b: 68 },
  { r: 80, g: 80, b: 80 },
  { r: 75, g: 101, b: 132 },
  { r: 87, g: 101, b: 116 },
  { r: 77, g: 86, b: 86 },
  { r: 75, g: 75, b: 75 },
  { r: 103, g: 128, b: 159 },
  { r: 99, g: 110, b: 114 },
  { r: 108, g: 122, b: 137 },
  { r: 124, g: 123, b: 122 },
  { r: 108, g: 122, b: 137 },
  { r: 123, g: 125, b: 125 },
  { r: 142, g: 142, b: 142 },
  { r: 149, g: 165, b: 166 },
  { r: 169, g: 169, b: 169 },
  { r: 152, g: 160, b: 160 },
  { r: 161, g: 161, b: 161 },
  { r: 193, g: 193, b: 193 },
  { r: 211, g: 211, b: 211 },
  { r: 192, g: 192, b: 192 },
  { r: 105, g: 105, b: 105 },
  { r: 112, g: 128, b: 144 },
  { r: 128, g: 138, b: 135 },
  { r: 94, g: 94, b: 94 },
  { r: 102, g: 102, b: 102 },
];

export const compareLatLng = (x: TLagLng, y: TLagLng) => {
  return Boolean(x.lat === y.lat && x.lng === y.lng);
};

export async function LoadFormattedAddress(lat: number, lng: number) {
  const geocode = await getGeocode({
    location: {
      lat,
      lng,
    },
  });

  const finalAddress = geocode.length ? geocode[0].formatted_address : '*';

  return finalAddress;
}

export const googleLibraries: (
  | 'places'
  | 'drawing'
  | 'geometry'
  | 'visualization'
  | 'marker'
)[] = ['places', 'drawing', 'marker'];

