import styled from 'styled-components';

import { Select } from 'antd';

export const SelectCustom = styled(Select)`
  /* .ant-select-selector {

    border-radius: 30px !important;

  } */

  .code-box-demo .ant-select {
    margin: 0 8px 10px 0;
  }

  .ant-row-rtl .code-box-demo .ant-select {
    margin: 0 0 10px 8px;
  }

  #components-select-demo-search-box .code-box-demo .ant-select {
    margin: 0;
  }
`;

