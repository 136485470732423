import { TrackingHistoryContext } from 'context/tracking_history';
import { useContextSelector } from 'use-context-selector';

export function useHistoryOriginPoint() {
  const originPoint = useContextSelector(
    TrackingHistoryContext,
    context => context.originPoint
  );

  const setOriginPoint = useContextSelector(
    TrackingHistoryContext,
    context => context.setOriginPoint
  );

  return {
    originPoint,
    setOriginPoint,
  };
}

