import {
  Form,
  Input,
  Row,
  Spin,
  Table,
  Modal,
  Typography,
  Col,
  Progress,
  Divider,
  Button as AntBtn,
  DatePicker,
} from 'antd';
import React, { useCallback, useState } from 'react';

import { toast } from 'react-toastify';

import { Printer } from 'phosphor-react';

import moment from 'moment';
import { CSVLink } from 'react-csv';
//@ts-ignore
import Container from '../styles.ts';
import { getVeiculosServices } from '../../services/modulos/getVeiculos';
import { templateColumns } from './columns';
import Button from '../button';
import { pdfReport } from './pdfReport';
import { VehicleComposition } from 'interfaces/vehicle';
import { ServiceExterno } from 'services/modulos/serviceExterno';
import { useAuthData } from 'hooks/auth/useAuthData';
import { useLocation } from 'react-router-dom';

const { Title } = Typography;

const csvHeader = [
  { label: 'Equipamento', key: 'mdt' },
  { label: 'Prefixo', key: 'viatura' },
  { label: 'AIS', key: 'ais' },
  { label: 'Data/Hora Conexão', key: 'dataHoraConexao' },
  { label: 'Comandante', key: 'comandante' },
  { label: 'Motorista', key: 'motorista' },
  { label: 'Patrulheiro', key: 'patrulheiro' },
];

export function TableCompisition() {
  const [percent, setPercent] = useState(0);

  const { data } = useAuthData();
  const { client } = data;

  const location = useLocation();

  const [isLoadingData, setIsLoadingData] = useState(false);
  const [isModalRelOpen, setIsModalRelOpen] = useState(false);
  const [composicao, setComposicao] = useState<VehicleComposition[]>([]);
  const [loadingTable, setLoadingTable] = useState(false);

  const loadCsvData = useCallback(async () => {
    setIsModalRelOpen(true);
    setIsLoadingData(true);
    const reportData = [];

    // Registrando usuário que está gerando relatório
    ServiceExterno.generateReport({
      name: client.name,
      cpf: client.cpf,
      externPath: 'Google Geocoding API',
      pathName: location.pathname,
    });

    let per = 0;
    let counter = 0;
    try {
      if (composicao) {
        const range = composicao.length;

        const times = range / 1000 + 1;
        for (let i = 0; i < times; i += 1) {
          const slicedArray = composicao.slice(
            i * 1000,
            Math.min((i + 1) * 1000, range)
          );

          reportData.push(
            ...(await Promise.all(
              slicedArray.map(async x => {
                counter += 1;
                per = (counter * 100) / range;
                setPercent(parseInt(per.toString()));
                if (counter >= range) {
                  setIsLoadingData(false);
                }

                return x;
              })
            ))
          );
        }
      }
    } catch (err) {
      console.log(err);
      setIsLoadingData(false);
    }
  }, [location]);

  const onFinish = useCallback(
    async (values: any) => {
      try {
        const dFim = new Date(values.dtFim);
        const dayFim = dFim.getDate() + 1;
        const dIni = new Date(values.dtInicio);

        dFim.setDate(dayFim - 90);
        if (dIni >= dFim) {
          const filters = {
            viatura: values.prefixo ? values.prefixo.toUpperCase() : null,
            dtIni: moment(values.dtInicio)
              .format()
              .substring(0, 10)
              .concat('T00:00:00'),
            dtFim: moment(values.dtFim)
              .format()
              .substring(0, 10)
              .concat('T23:59:59'),
          };

          setLoadingTable(true);

          const response = await getVeiculosServices.getComposition(filters);

          setComposicao(response);
          setLoadingTable(false);
        } else {
          toast.error('Período máximo para pesquisa : 90 dias');
        }
      } catch (e) {
        toast.error('Erro ao carregar dados');
        console.error(e);
      }
    },

    []
  );

  const gerarPdf = () => {
    pdfReport(composicao);
    setIsModalRelOpen(false);
  };
  // const csvDados = [];

  return (
    <Container>
      <div className="composicao">
        <Form className="filtrosForm" onFinish={onFinish}>
          <h1 style={{ margin: '30px' }} className="titleForm">
            Filtros
          </h1>
          <Row gutter={24} style={{ width: '100%', padding: '20px' }}>
            <Col span={8}>
              <Form.Item
                label="Prefixo"
                rules={[
                  {
                    required: true,
                    message: 'Por favor, informe o prefixo.',
                  },
                ]}
                name="prefixo"
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="dtInicio"
                label="Data Inicial"
                rules={[
                  {
                    required: true,
                    message: 'Por favor, informe a data inicial.',
                  },
                ]}
              >
                <DatePicker style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="dtFim"
                label="Data Final"
                rules={[
                  {
                    required: true,
                    message: 'Por favor, informe a data final.',
                  },
                ]}
              >
                <DatePicker style={{ width: '100%' }} />
              </Form.Item>
            </Col>
          </Row>

          <Row className="filtrosForm" />
          <Row className="slotBotoes2">
            <Form.Item className="slotBotoesButton">
              <Button type="submit">Buscar</Button>
            </Form.Item>

            <Form.Item className="slotBotoesButton">
              <Button
                type="reset"
                buttonStyle="btn--secondary"
                onClick={() => setComposicao([])}
              >
                Limpar
              </Button>
            </Form.Item>
          </Row>
        </Form>
      </div>

      <Modal
        open={isModalRelOpen}
        destroyOnClose
        onCancel={() => {
          setIsModalRelOpen(false);
        }}
        footer={null}
      >
        {/* @ts-ignore */}
        <Row height="24">
          <Printer size={32} weight="light" />
          <Title
            level={4}
            style={{
              verticalAlign: 'middle',
            }}
          >
            {' '}
            Gerar Relatório
          </Title>
        </Row>
        <Divider />

        {isLoadingData ? (
          <div>
            <Row
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
              }}
            >
              <Progress type="circle" strokeColor="#006B35" percent={percent} />
            </Row>
            <Row
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
              }}
            >
              {' '}
              Carregando dados...
            </Row>
          </div>
        ) : (
          <Row>
            <Col span={12}>
              <AntBtn onClick={gerarPdf}>Gerar PDF</AntBtn>
            </Col>
            <Col span={12}>
              <AntBtn>
                {/* @ts-expect-error Server Component */}
                <CSVLink
                  headers={csvHeader}
                  data={
                    composicao.map(c => ({
                      ...c,
                      dataHoraConexao: moment(c.dataHoraConexao).format(
                        'DD/MM/YYYY HH:mm:ss'
                      ),
                    })) || []
                  }
                  filename="Planilha Composição"
                  target="_blank"
                >
                  Gerar CSV
                </CSVLink>
              </AntBtn>
            </Col>
          </Row>
        )}
      </Modal>
      <div className="daysWithoutLocationContainer">
        {loadingTable ? (
          <Spin size="small" className="slotBtnDocCsvPersonalizado" />
        ) : (
          <AntBtn
            className="slotBtnDocCsvPersonalizado"
            id="gerarRelBtn"
            onClick={loadCsvData}
            disabled={!composicao}
            style={{
              background: 'white',
              border: '1px solid rgb(26, 120, 74)',
              margin: '20px 0px 20px',
              marginBottom: '10px',
            }}
          >
            Gerar Relatório
          </AntBtn>
        )}
      </div>
      <div>
        <Table
          style={{ marginTop: '30px' }}
          className="tablePersonalizada"
          dataSource={composicao}
          loading={loadingTable}
          //@ts-ignore
          columns={templateColumns()}
          dadosLast={composicao}
          pdfGen={pdfReport}
          dadosPdfPai={csvHeader}
          columnWithModal
          rowKey={'dataHoraConexao'}
        />
      </div>
    </Container>
  );
}

