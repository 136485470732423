import { OccurrenceSocket } from 'interfaces/vehicle';
import api from '../api';

interface getProps {
  page: number;
  size: number;
  dataInicial: string;
  dataFinal: string;
  updateObj: any;
}

export const ServiceOcorrencia = {
  getList: ({ page, size, dataInicial, dataFinal, updateObj }: getProps) => {
    return api.post(`/rotas/ocorrencias?page=${page}&size=${size}`, {
      ...updateObj,
      dataInicial,
      dataFinal,
    });
  },

  getById: ({ id }: { id: string }): Promise<OccurrenceSocket> => {
    return api.get(`/rotas/ocorrencias?ocorrencia=${id}`);
  },
};
