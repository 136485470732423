import React, { ReactNode } from 'react';

import './styles.css';

const STYLES = ['btn--primary', 'btn--secondary', 'btn--link'];

const SIZE = ['btn--medium', 'btn--small'];

interface ButtonProps {
  icon?: ReactNode;
  children?: ReactNode;
  type: 'button' | 'submit' | 'reset' | undefined;
  onClick?: () => void;
  buttonStyle?: string;
  buttonSize?: string;
  disabled?: boolean;
}

export default function Button({
  icon,
  children,
  type,
  onClick,
  buttonStyle,
  buttonSize,
  disabled,
}: ButtonProps) {
  const checkButtonStyle = STYLES.includes(buttonStyle || '')
    ? buttonStyle
    : STYLES[0];

  const checkButtonSize = SIZE.includes(buttonSize || '')
    ? buttonSize
    : SIZE[0];

  return (
    <button
      className={`btn ${checkButtonStyle} ${checkButtonSize} ${
        disabled ? 'btn--disabled' : ''
      }`}
      onClick={onClick}
      type={type}
      disabled={disabled}
    >
      {icon} {children}
    </button>
  );
}
