import { VehiclePositionHistory } from 'interfaces/history';
import api from '../api';
import ENV from 'config';
import { getVeiculosServices } from './getVeiculos';
import { VehicleComposition } from 'interfaces/vehicle';

interface BodyProps {
  prefixo?: string;
  nome?: string;
  placas?: string[];
  distanciaKM?: number;
  latitude?: number;
  longitude?: number;
  dataInicio: string;
  dataFim: string;
}

// o objeto de retorno coloca a ultima posição dentro do "positions", precisa adicionar a um array antes de tudo

export const HistoryService = {
  getByPlates: async (body: BodyProps): Promise<VehiclePositionHistory[]> => {
    return await api.post(`${ENV.serverSSPDS}/rotas/history`, body, {
      headers: { 'Content-Type': 'application/json' },
      timeout: 1000 * 180,
    });
  },

  loadComposition: async (body: BodyProps): Promise<VehicleComposition[]> => {
    try {
      if (body.prefixo) {
        const response = await getVeiculosServices.getComposition({
          viatura: body.prefixo,
          dtIni: body.dataInicio,
          dtFim: body.dataFim,
        });

        return response;
      }

      return [];
    } catch (e) {
      return [];
    }
  },

  loadSphericalDistance: async (
    body: BodyProps
  ): Promise<VehiclePositionHistory[]> => {
    try {
      return await api.post(
        `${ENV.serverSSPDS}/rotas/history/sphericalDistance`,
        body,
        {
          timeout: 1000 * 180,
        }
      );
    } catch (e) {
      return [];
    }
  },

  loadAuditPolygonHistory: async (
    body: BodyProps
  ): Promise<VehiclePositionHistory[]> => {
    try {
      return await api.post(`${ENV.serverSSPDS}/rotas/auditPolygon`, body, {
        headers: { 'Content-Type': 'application/json' },
        timeout: 1000 * 180,
      });
    } catch (e) {
      return [];
    }
  },
};

