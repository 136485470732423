import React, { useEffect, useMemo, useState } from 'react';
import './raioTableStyle.css';

export function Item({
  d,
  _numeroPagina,
  plus,
  _separador,
  _contador,
  dadosPdfPai,
  plusPagina,
  veiculos,
}) {
  const _separadorUsado = _separador.padrao;
  const _quebra =
   _contador === _separador || _contador % _separador.padrao === 0;
  const _data = `${new Date(d.datePosition).toLocaleDateString()} ${new Date(
    d.datePosition
  ).toLocaleTimeString()}`;

  const _motor = d.engineOn === '1' ? 'Ligado' : 'Desligado';

  if (_contador % _separadorUsado == 0) {
    plusPagina();
  }
  const prefixo = useMemo(() => {
    const filtered = veiculos.find(v => v.identificador === d.placa);

    if (filtered) {
      return filtered.prefixo;
    }
    return '';
  }, [d.placa, veiculos]);

  return (
    <>
      <li
        className={
          _contador == dadosPdfPai.length
            ? 'itemListaImpressao ultimoItemImpressao'
            : 'itemListaImpressao'
        }
        key={d.dataPosicao}
      >
        <p className="celPlacaRaio">
          {d.placa}
          <br /> {prefixo}
        </p>
        <p className="celLatRaio">{d.position.x}</p>
        <p className="celLngRaio">{d.position.y}</p>
        <p className="celMotorRaio">{_motor}</p>
        <p className="celVelRaio">{`${d.speed} Km/h`}</p>
        <p className="celDtPosRaio">{_data}</p>
      </li>
    </>
  );
}
