import React from 'react';
import { Tag, Button } from 'antd';
import { MapTrifold } from 'phosphor-react';
import { prefixoIsHT } from 'utils/functionsUtils';
import { dataPropertiesAux } from 'interfaces/vehicle';
import moment from 'moment';

interface templateProps {
  setIsModalOpen: (value: boolean) => void;
  setMapPosition: (value: any) => void;
}

export const templateColumns = ({
  setIsModalOpen,
  setMapPosition,
}: templateProps) => {
  return [
    {
      title: 'Placa',
      dataIndex: 'placa',
      key: 'placa',
      align: 'center',
      width: 80,
      responsive: ['sm'],
      sorter: (a: dataPropertiesAux, b: dataPropertiesAux) =>
        a.placa < b.placa ? -1 : a.placa > b.placa ? 1 : 0,
      render: (text: string) => <p>{text}</p>,
    },
    {
      title: 'Prefixo',
      dataIndex: 'prefixo',
      key: 'prefixo',
      align: 'center',
      width: 80,
      sorter: (a: dataPropertiesAux, b: dataPropertiesAux) =>
        a.prefixo < b.prefixo ? -1 : a.prefixo > b.prefixo ? 1 : 0,
      render: (text: string) => <p>{text}</p>,
    },
    {
      title: 'Grupo',
      dataIndex: 'grupo',
      key: 'grupo',
      align: 'center',
      width: 150,
      responsive: ['md'],
      sorter: (a: dataPropertiesAux, b: dataPropertiesAux) =>
        (a.grupo || '') < (b.grupo || '')
          ? -1
          : (a.grupo || '') > (b.grupo || '')
          ? 1
          : 0,
      render: (text: string) => <p>{text}</p>,
    },
    {
      title: 'Última posição',
      dataIndex: 'datePosition',
      key: 'datePosition',
      align: 'center',
      width: 150,
      responsive: ['sm'],
      sorter: (a: dataPropertiesAux, b: dataPropertiesAux) =>
        a.datePosition < b.datePosition
          ? -1
          : a.datePosition > b.datePosition
          ? 1
          : 0,
      render: (text: string) => moment(text).format('DD/MM/YYYY HH:mm:ss'),
    },
    // {
    //   title: 'Latitude',
    //   dataIndex: 'latitude',
    //   key: 'latitude',
    //   align: 'center',
    //   width: 200,
    //   responsive: ['lg'],
    //   sorter: (a: dataPropertiesAux, b: dataPropertiesAux) =>
    //     a.latitude < b.latitude ? -1 : a.latitude > b.latitude ? 1 : 0,
    //   render: (text: string) => <p style={{ maxWidth: '80px' }}>{text}</p>,
    // },
    // {
    //   title: 'Longitude',
    //   dataIndex: 'longitude',
    //   key: 'longitude',
    //   align: 'center',
    //   width: 200,
    //   responsive: ['lg'],
    //   sorter: (a: dataPropertiesAux, b: dataPropertiesAux) =>
    //     a.longitude < b.longitude ? -1 : a.longitude > b.longitude ? 1 : 0,
    //   render: (text: string) => <p style={{ maxWidth: '80px' }}>{text}</p>,
    // },
    {
      title: 'Status',
      key: 'statusConexaoViatura',
      dataIndex: 'statusConexaoViatura',
      align: 'center',
      width: 150,
      responsive: ['md'],
      sorter: (a: dataPropertiesAux, b: dataPropertiesAux) =>
        (a.statusConexaoViatura || '') < (b.statusConexaoViatura || '')
          ? -1
          : (a.statusConexaoViatura || '') > (b.statusConexaoViatura || '')
          ? 1
          : 0,
      render: (text: string, record: dataPropertiesAux) => {
        let finalColor = 'green';

        if (
          (record.prefixo && prefixoIsHT(record.prefixo)) ||
          (!record.prefixo && prefixoIsHT(record.placa))
        ) {
          finalColor = 'pink';
          text = 'RÁDIO';
        } else if (!record.conectado) {
          finalColor = 'purple';
          text = 'DESCONECTADA';
        } else {
          switch (text) {
            case 'DISPONÍVEL COM TERMINAL DE BORDO':
              finalColor = 'blue';
              break;
            case 'DISPONÍVEL':
              finalColor = 'blue';
              break;
            case 'CHEGOU NO LOCAL':
              finalColor = 'green';
              break;
            case 'EM TRANSPORTE':
              finalColor = 'white';
              break;
            case 'DESPACHADO':
              finalColor = 'orange';
              break;
            case 'EM ROTA':
              finalColor = 'yellow';
              break;
            case 'MANUTENÇÃO':
              finalColor = 'black';
              break;
            default:
              finalColor = 'grey';
              break;
          }
        }

        return (
          <span>
            <Tag
              style={{
                display: 'inline-block',
                textAlign: 'center',
                justifyContent: 'center',
                wordWrap: 'break-word',
                maxWidth: '150px',
                whiteSpace: 'pre-line',
              }}
              color={finalColor}
            >
              {text || '-'}
            </Tag>
          </span>
        );
      },
    },
    {
      title: 'Lodragouro',
      dataIndex: 'logradouro',
      key: 'logradouro',
      align: 'center',
      width: 80,
      render: (text: string, record: dataPropertiesAux) => {
        const lat = record.latitude;
        const lng = record.longitude;
        return (
          <Button
            style={{
              border: 'none',
              backgroundColor: 'none',
            }}
            onClick={() => {
              setIsModalOpen(true);
              setMapPosition([lat, lng]);
            }}
          >
            <MapTrifold color="#18A779" size={36} />
          </Button>
        );
      },
    },
  ];
};

