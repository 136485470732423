import HelicopteroAzul from 'imgs/helicoptero_azul.svg';
import moment from 'moment';
import { haversineDistance, prefixoIsHT } from './functionsUtils';
import {
  DataPosition,
  MarkersProps,
  dataPropertiesAux,
} from 'interfaces/vehicle';
import { MutableRefObject } from 'react';

const ReturnErrorMarker = (
  prefixo: string,
  outFence: boolean,
  outAis: boolean,
  showTooltip: boolean
) => {
  let svg = '';

  let widthAux = 70;
  let heightAux = 70;

  let xOffsetAux = 0;
  let yOffsetAux = 40;

  let fillColorCircle = '#3959A6';

  if (outFence) {
    fillColorCircle = '#C83538';
  }

  if (showTooltip && prefixo) {
    svg = `
    <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" width="21.0106mm" height="21.0106mm" version="1.1" shape-rendering="geometricPrecision" text-rendering="geometricPrecision" image-rendering="optimizeQuality" fill-rule="evenodd" clip-rule="evenodd"
    viewBox="0 0 206.19 204.22"
     xmlns:xlink="http://www.w3.org/1999/xlink"
     xmlns:xodm="http://www.corel.com/coreldraw/odm/2003">
     <defs>
      <font id="FontID0" horiz-adv-x="722" font-variant="normal" fill-rule="nonzero" font-style="normal" font-weight="700">
      <font-face 
        font-family="Arial">
        <font-face-src>
          <font-face-name name="Arial Negrito"/>
        </font-face-src>
      </font-face>
       <missing-glyph><path d="M0 0z"/></missing-glyph>
       <glyph unicode="$" horiz-adv-x="556" d="M238.002 107.007l0 204.16c-63.5087,18.1735 -109.998,45.6692 -139.497,82.5021 -29.6667,36.9999 -44.5001,81.667 -44.5001,134.335 0,53.3364 16.8223,98.1553 50.3302,134.335 33.4928,36.3319 77.9929,57.1624 133.667,62.6585l0 48.0073 70.9937 0 0 -48.0073c52.0003,-6.16412 93.3424,-23.8366 124.178,-53.0024 30.6536,-29.1657 50.3302,-68.1697 58.8325,-116.997l-124.011 -16.0024c-7.6672,38.336 -27.3286,64.3437 -58.9995,78.008l0 -190.511c78.5091,-20.9975 131.997,-48.4931 160.343,-82.0011 28.4977,-33.6598 42.663,-76.8238 42.663,-129.492 0,-58.8325 -17.8395,-108.328 -53.6704,-148.668 -35.8309,-40.3401 -85.4931,-65.3306 -149.336,-75.3359l0 -90.9891 -70.9937 0 0 89.9871c-56.0085,6.83216 -101.495,27.5108 -136.673,62.3396 -35.1628,34.8288 -57.4964,84.1722 -67.3347,147.666l127.002 15.0004c5.17726,-26.0077 15.0004,-48.3413 29.3327,-67.1677 14.3323,-18.8264 30.1678,-32.3389 47.6733,-40.8259zm0 511.987c-19.1756,-6.83216 -34.176,-17.9913 -45.3352,-33.6598 -11.1744,-15.6684 -16.6705,-32.8399 -16.6705,-51.6663 0,-17.1715 5.01025,-33.1739 15.1674,-48.0073 10.1723,-14.6664 25.8407,-26.6606 46.8382,-35.6639l0 168.997zm70.9937 -519.988c24.5047,5.32908 44.5001,16.9893 59.8345,34.9958 15.5014,17.9913 23.1686,39.171 23.1686,63.6605 0,21.6655 -6.49814,40.5071 -19.4944,56.1755 -12.8445,15.8354 -34.009,27.8297 -63.5087,36.3319l0 -191.164z"/>
       <glyph unicode="e" horiz-adv-x="556" d="M372.003 164.002l136.992 -23.0016c-17.4903,-50.1632 -45.3352,-88.3322 -83.1549,-114.674 -38.002,-26.1596 -85.5082,-39.3229 -142.337,-39.3229 -90.1693,0 -157.003,29.4997 -200.167,88.4992 -34.176,47.3241 -51.3323,106.992 -51.3323,179.17 0,85.9941 22.5006,153.496 67.3347,202.156 44.8341,48.8423 101.662,73.1648 170.333,73.1648 76.9908,0 137.827,-25.4915 182.494,-76.4898 44.5001,-50.9983 65.8316,-129.173 63.8275,-234.51l-342.989 0c1.00205,-40.6589 11.9942,-72.3298 33.1587,-94.9973 20.9975,-22.6676 47.3393,-33.9938 78.6761,-33.9938 21.4985,0 39.4899,5.83011 53.9892,17.4903 14.6664,11.6754 25.6737,30.5018 33.1739,56.5095zm8.00121 138.996c-1.00205,39.8391 -11.1744,70.1738 -30.6688,90.8373 -19.5096,20.8305 -43.164,31.1698 -71.1759,31.1698 -29.8338,0 -54.4902,-11.0074 -73.9998,-32.8399 -19.4944,-21.8325 -28.9987,-51.6663 -28.6647,-89.1672l204.509 0z"/>
       <glyph unicode="f" horiz-adv-x="332" d="M11.9942 519.001l76.0039 0 0 39.171c0,43.6651 4.66105,76.3228 13.9983,97.8213 9.33728,21.6807 26.5088,39.171 51.4993,52.6683 25.1727,13.5125 56.8435,20.3446 95.1644,20.3446 39.171,0 77.674,-5.84529 115.342,-17.5055l-17.5055 -95.3314c-22.3336,5.16207 -43.8321,7.83421 -64.4956,7.83421 -20.3295,0 -34.8288,-4.67623 -43.6651,-13.9983 -8.83626,-9.33728 -13.3303,-27.3438 -13.3303,-54.0044l0 -36.9999 102.999 0 0 -107.994 -102.999 0 0 -411.007 -137.008 0 0 411.007 -76.0039 0 0 107.994z"/>
       <glyph unicode="i" horiz-adv-x="277" d="M71.9957 588.993l0 127.002 137.008 0 0 -127.002 -137.008 0zm0 -588.993l0 519.001 137.008 0 0 -519.001 -137.008 0z"/>
       <glyph unicode="o" horiz-adv-x="610" d="M40.0061 265.832c0,45.6692 11.1592,89.8353 33.6598,132.498 22.5006,42.83 54.3384,75.3359 95.4984,97.8365 41.1751,22.5006 86.9961,33.8268 137.843,33.8268 78.4939,0 142.655,-25.4915 192.834,-76.4898 50.1632,-51.1653 75.1537,-115.509 75.1537,-193.502 0,-78.6609 -25.3245,-143.84 -75.8218,-195.506 -50.6794,-51.6663 -114.34,-77.4918 -191.179,-77.4918 -47.4911,0 -92.8262,10.8252 -135.99,32.3237 -42.997,21.4985 -75.8369,53.0024 -98.3375,94.6785 -22.5006,41.494 -33.6598,92.1582 -33.6598,151.826zm141.001 -7.33318c0,-51.4993 12.1612,-91.0043 36.4989,-118.333 24.4895,-27.4956 54.4902,-41.1599 90.3211,-41.1599 35.679,0 65.6798,13.6643 89.8353,41.1599 24.1707,27.3286 36.3319,67.1677 36.3319,119.335 0,50.8312 -12.1612,90.0023 -36.3319,117.331 -24.1555,27.4956 -54.1562,41.1751 -89.8353,41.1751 -35.8309,0 -65.8316,-13.6795 -90.3211,-41.1751 -24.3377,-27.3286 -36.4989,-66.8337 -36.4989,-118.333z"/>
       <glyph unicode="p" horiz-adv-x="610" d="M68.0027 519.001l128.004 0 0 -76.171c16.6553,26.0077 38.9888,47.0052 67.3195,63.1747 28.3459,16.0024 59.8345,23.9885 94.3445,23.9885 60.1685,0 111.334,-23.6544 153.329,-70.9937 41.995,-47.1722 63.0077,-113.171 63.0077,-197.495 0,-86.8443 -21.1797,-154.164 -63.5087,-202.338 -42.329,-48.1591 -93.6613,-72.1628 -153.997,-72.1628 -28.6647,0 -54.6724,5.6631 -78.008,17.1563 -23.1534,11.3414 -47.8251,30.8358 -73.4988,58.5136l0 -259.668 -136.992 0 0 715.995zm135.003 -251.499c0,-58.4984 11.4932,-101.662 34.6618,-129.674 23.0016,-27.8297 51.1653,-41.828 84.1722,-41.828 31.8227,0 58.3314,12.8293 79.4959,38.503 21.1645,25.6585 31.6708,67.6687 31.6708,126.167 0,54.6573 -10.8404,95.1644 -32.6729,121.658 -21.6655,26.5088 -48.6601,39.6721 -80.832,39.6721 -33.5079,0 -61.3376,-12.9963 -83.3371,-39.004 -22.1666,-25.9926 -33.1587,-64.4956 -33.1587,-115.494z"/>
       <glyph unicode="r" horiz-adv-x="389" d="M203.006 0l-137.008 0 0 519.001 127.002 0 0 -73.6658c21.8325,34.8288 41.494,57.6634 58.9995,68.5038 17.5055,10.8252 37.3339,16.1543 59.5005,16.1543 31.3368,0 61.5046,-8.65407 90.5033,-25.8256l-42.5112 -119.669c-23.1534,15.0004 -44.6671,22.5006 -64.4956,22.5006 -19.3274,0 -35.6639,-5.32908 -48.9942,-15.8354 -13.3303,-10.6582 -23.8366,-29.6667 -31.5038,-57.3294 -7.6672,-27.6626 -11.4932,-85.6752 -11.4932,-173.84l0 -159.994z"/>
       <glyph unicode="x" horiz-adv-x="556" d="M5.49609 0l187.004 267.335 -179.003 251.666 167.342 0 91.6572 -142.671 97.3355 142.671 160.662 0 -175.829 -245.836 191.832 -273.165 -167.16 0 -106.84 160.829 -106.324 -160.829 -160.677 0z"/>
       <glyph unicode="{" horiz-adv-x="389" d="M28.9987 201.002l0 116.997c23.8366,1.33607 41.661,4.82806 53.8374,10.8404 11.9942,5.83011 22.3336,15.6532 31.1698,29.4997 8.82107,13.8313 14.8334,31.1546 18.1584,52.0003 2.50512,15.6532 3.84119,42.83 3.84119,81.667 0,63.1595 2.99097,107.159 8.82107,132.164 5.84529,24.9905 16.3364,45.0011 31.6708,60.1685 15.3344,15.1674 37.668,26.9946 66.8337,35.8309 19.8284,5.83011 51.1653,8.83626 93.8283,8.83626l25.8407 0 0 -117.012c-36.3319,0 -59.5005,-1.98892 -69.8398,-6.16412 -10.3241,-3.99302 -17.8243,-10.1571 -22.8194,-18.4924 -4.84324,-8.33523 -7.34836,-22.5006 -7.34836,-42.6782 0,-20.4965 -1.32088,-59.4853 -3.99302,-116.663 -1.67008,-32.3237 -5.83011,-58.3314 -12.6623,-78.4939 -6.83216,-19.9954 -15.5014,-36.4989 -26.1748,-49.5104 -10.4912,-12.9963 -26.6606,-26.4936 -48.4931,-40.4919 19.3274,-11.0074 34.9958,-24.0036 47.3241,-38.837 12.3434,-14.8334 21.6807,-32.8247 28.1789,-54.0044 6.66515,-21.1645 10.8252,-49.6622 12.8293,-85.159 2.0041,-54.0044 2.99097,-88.6662 2.99097,-103.667 0,-21.4985 2.67213,-36.4989 7.83421,-45.0011 5.17726,-8.33523 13.0115,-14.8334 23.6696,-19.1604 10.5063,-4.50922 33.3409,-6.66515 68.5038,-6.66515l0 -117.012 -25.8407 0c-43.9991,0 -77.6588,3.50717 -101.161,10.5063 -23.3356,6.99916 -43.164,18.6594 -59.1665,34.9958 -16.1694,16.1694 -26.9946,36.3319 -32.4907,60.3355 -5.51127,23.8366 -8.33523,61.6716 -8.33523,113.171 0,59.8345 -2.67213,98.8234 -7.83421,116.663 -7.16617,26.1748 -18.0065,44.8341 -32.5059,55.9933 -14.4994,11.3414 -36.6659,17.6725 -66.6667,19.3426z"/>
       <glyph unicode="}" horiz-adv-x="389" d="M356.001 201.002c-23.8366,-1.33607 -41.661,-4.82806 -53.6704,-10.6733 -12.1612,-5.99712 -22.5006,-15.8354 -31.1698,-29.6667 -8.48706,-13.8313 -14.6664,-31.1546 -18.3254,-52.0003 -2.50512,-15.6684 -3.84119,-42.663 -3.84119,-81.166 0,-63.1595 -2.82396,-107.326 -8.48706,-132.498 -5.67828,-24.9905 -16.1694,-45.1681 -31.5038,-60.3355 -15.3344,-15.1674 -37.835,-26.9946 -67.3347,-35.8309 -19.8284,-5.83011 -51.1653,-8.83626 -93.8283,-8.83626l-25.8407 0 0 117.012c34.9958,0 57.8304,2.15592 68.6708,6.66515 10.6582,4.32703 18.6594,10.6582 23.6696,18.9934 5.16207,8.33523 7.6672,22.3336 7.83421,42.329 0.151826,19.8436 1.48789,57.8456 3.82601,113.839 1.67008,33.9938 5.99712,61.0036 13.1633,81.5 7.16617,20.3295 16.6705,37.668 28.4977,51.8333 12.0094,14.1653 27.1768,26.4936 45.6692,37.3339 -24.0036,15.6684 -41.661,31.0028 -52.6683,45.8362 -15.3344,21.4985 -25.8256,48.8271 -31.3216,82.1681 -3.50717,22.6676 -6.0123,72.8308 -7.33318,150.323 -0.334017,24.3377 -2.50512,40.6741 -6.68033,48.8423 -3.99302,8.00121 -11.3262,14.3323 -21.9995,18.6594 -10.6582,4.50922 -34.3278,6.66515 -71.3277,6.66515l0 117.012 25.8407 0c43.9991,0 77.6588,-3.50717 101.161,-10.3393 23.3356,-6.83216 42.997,-18.5076 58.9995,-34.8288 16.0024,-16.5035 26.8276,-36.6659 32.5059,-60.6696 5.6631,-23.8366 8.48706,-61.6716 8.48706,-113.171 0,-59.5005 2.50512,-98.5045 7.33318,-116.663 7.16617,-26.1748 18.0065,-44.8341 32.6729,-56.0085 14.6664,-11.3262 36.9999,-17.6573 67.0007,-19.3274l0 -116.997z"/>
      </font>
     </defs>
     <g id="Camada_x0020_1">
      <metadata id="CorelCorpID_0Corel-Layer"/>
      <rect fill="none" x="0.98" width="204.22" height="204.22"/>
      <g id="_2069084618000">
      ${(outAis || outFence) &&
        `<path id="circle" fill="${fillColorCircle}" d="M106.85 198.8c-9.07,0 -14.79,-0.34 -23.3,-4.41 -2.17,-1.03 -5.16,-2.79 -7.01,-4.16 -6.37,-4.72 -11.86,-10.92 -15.32,-18.18 -6.31,-13.23 -5.7,-27.84 0.15,-41.09 2.05,-4.65 5.86,-9.37 9.41,-12.93 7.56,-7.57 19.56,-13.55 31.13,-13.55 8.78,0 14.72,0.71 22.34,4.55 14.92,7.53 26.89,22.43 26.89,40.95 0,13.74 -4.19,25.54 -13.55,34.85 -1.96,1.95 -3.66,3.63 -5.96,5.2 -6.85,4.63 -15.59,8.76 -24.75,8.76l0 0 -0.01 -0.01zm-55.43 -50.05c0,10.12 0.34,16.42 5.1,25.93 1.43,2.86 2.8,5.31 4.65,7.76 9.41,12.43 23.86,21.75 39.89,21.75 14.28,0 22.07,-1.91 33.43,-9.59 12.4,-8.38 21.99,-25.33 21.99,-40.46 0,-11.63 -0.62,-14.59 -4.89,-24.89 -0.9,-2.18 -3.2,-6.05 -4.55,-7.86 -2.72,-3.66 -5.64,-6.92 -9.07,-9.96 -7.73,-6.87 -21.16,-12.31 -31.53,-12.31 -11.01,0 -15.02,0.61 -24.94,4.84 -4.03,1.72 -13.2,8.01 -16.01,11.7 -0.64,0.84 -0.79,0.95 -1.55,1.76 -6.85,7.36 -12.51,20.77 -12.51,31.33l0 0 0.01 0.01z"/>`}
       <path id="marker" fill="#ED3237" d="M103.11 108.76c18.56,0 33.62,15.05 33.62,33.62 0,18.56 -29.36,52.09 -33.62,52.09 -4.26,0 -33.62,-33.51 -33.62,-52.09 0,-18.56 15.05,-33.62 33.62,-33.62z"/>
       <circle id="smallCircle" fill="#FEFEFE" cx="103.11" cy="141.94" r="26.35"/>
       <path id="X" fill="#ED3237" fill-rule="nonzero" d="M109.29 155.64l-6.14 -9 -6.28 9c-0.91,1.26 -1.68,2.16 -2.31,2.7 -0.63,0.54 -1.43,0.8 -2.4,0.8 -1.07,0 -1.99,-0.34 -2.74,-1 -0.75,-0.68 -1.13,-1.44 -1.13,-2.31 0,-0.89 0.58,-2.16 1.74,-3.81l7.65 -10.97 -6.78 -9.15c-1.17,-1.5 -1.77,-2.81 -1.77,-3.94 0,-0.87 0.35,-1.63 1.06,-2.26 0.7,-0.65 1.62,-0.96 2.75,-0.96 0.99,0 1.81,0.28 2.47,0.84 0.65,0.56 1.41,1.44 2.27,2.63l5.48 7.51 5.33 -7.51c0.89,-1.24 1.66,-2.13 2.31,-2.67 0.66,-0.54 1.46,-0.8 2.43,-0.8 1.11,0 2.04,0.32 2.77,0.94 0.73,0.63 1.1,1.4 1.1,2.29 0,0.65 -0.15,1.26 -0.44,1.84 -0.31,0.59 -0.76,1.29 -1.39,2.09l-6.78 9.15 7.65 10.97c1.2,1.71 1.8,3 1.8,3.87 0,0.89 -0.37,1.65 -1.11,2.29 -0.75,0.65 -1.67,0.96 -2.76,0.96 -0.97,0 -1.79,-0.27 -2.47,-0.84 -0.67,-0.56 -1.44,-1.45 -2.31,-2.67z"/>
       <path id="comment" fill="#FEFEFE" stroke="#A2A2A3" stroke-width="2.02" stroke-miterlimit="22.9256" d="M107.88 116.08c6.78,-6.07 18.19,-17.05 21.61,-20.3 2.1,-2 2.27,-3.2 6.48,-3.28 6.9,-0.11 14.01,0.03 20.93,0.03 15.91,0 39.03,3.3 46.19,-15.15 2.81,-7.24 2.18,-47.84 1.58,-55.5 -0.82,-10.54 -9.15,-18.42 -18.95,-20.79 0,0 -138.5,-0.13 -165.7,0.09 -9.8,2.52 -17.81,10.31 -18.6,21.11 -0.86,11.81 -0.08,26.19 -0.08,38.29 0,10.09 -0.58,20.83 11.19,27.92 13.3,7.99 50.34,2.1 58.09,4.24 2.31,0.63 18.62,15.93 27.57,23.48 5.99,5.06 3.89,5.05 9.69,-0.14z"/>
       <g>
        <g transform="matrix(1 0 0 1 1806.83 -271.343)">
         <text x="-1789.59" y="327.82"  fill="black" font-weight="bold" font-size="37.94px" font-family="Arial">${prefixo}</text>
        </g>
       </g>
      </g>
     </g>
    </svg>
    `;
  } else {
    widthAux = 35;
    heightAux = 35;
    yOffsetAux = 0;

    svg = `
    <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" width="9.2406mm" height="9.2374mm" version="1.1" shape-rendering="geometricPrecision" text-rendering="geometricPrecision" image-rendering="optimizeQuality" fill-rule="evenodd" clip-rule="evenodd"
    viewBox="0 0 47.53 47.53"
     xmlns:xlink="http://www.w3.org/1999/xlink"
     xmlns:xodm="http://www.corel.com/coreldraw/odm/2003">
     <g id="Camada_x0020_1">
      <metadata id="CorelCorpID_0Corel-Layer"/>
      ${(outAis || outFence) &&
        `
      <path id="circle" fill="${fillColorCircle}" d="M25.08 45.09c-4.1,0 -6.69,-0.16 -10.54,-2 -0.98,-0.46 -2.34,-1.26 -3.17,-1.88 -2.88,-2.14 -5.37,-4.94 -6.93,-8.22 -2.85,-5.99 -2.58,-12.59 0.07,-18.59 0.93,-2.1 2.65,-4.24 4.25,-5.85 3.42,-3.42 8.85,-6.13 14.08,-6.13 3.97,0 6.66,0.32 10.1,2.06 6.75,3.4 12.17,10.14 12.17,18.52 0,6.21 -1.89,11.55 -6.13,15.76 -0.88,0.88 -1.65,1.65 -2.7,2.35 -3.1,2.09 -7.05,3.96 -11.19,3.96l0 0 0 0 -0.02 -0zm-25.08 -22.64c0,4.58 0.16,7.43 2.3,11.73 0.65,1.3 1.27,2.4 2.1,3.51 4.25,5.63 10.79,9.84 18.05,9.84 6.46,0 9.99,-0.87 15.12,-4.34 5.61,-3.79 9.95,-11.46 9.95,-18.3 0,-5.26 -0.28,-6.6 -2.21,-11.26 -0.41,-0.99 -1.45,-2.74 -2.06,-3.55 -1.23,-1.65 -2.56,-3.13 -4.1,-4.51 -3.49,-3.11 -9.57,-5.57 -14.27,-5.57 -4.98,0 -6.79,0.28 -11.28,2.19 -1.83,0.78 -5.97,3.62 -7.25,5.29 -0.29,0.39 -0.36,0.43 -0.7,0.8 -3.1,3.33 -5.66,9.39 -5.66,14.17l0 0 0 0 0.01 0.01z"/>
      `}
      <path id="marker" fill="#ED3237" d="M23.4 4.37c8.39,0 15.2,6.81 15.2,15.2 0,8.39 -13.29,23.56 -15.2,23.56 -1.93,0 -15.2,-15.16 -15.2,-23.56 0,-8.39 6.81,-15.2 15.2,-15.2z"/>
      <circle id="smallCircle" fill="#FEFEFE" cx="23.39" cy="19.34" r="11.92"/>
      <path id="X" fill="#ED3237" fill-rule="nonzero" d="M26.19 25.54l-2.78 -4.07 -2.84 4.07c-0.41,0.57 -0.76,0.98 -1.04,1.22 -0.29,0.24 -0.65,0.36 -1.09,0.36 -0.48,0 -0.9,-0.15 -1.24,-0.45 -0.34,-0.31 -0.51,-0.65 -0.51,-1.04 0,-0.4 0.26,-0.98 0.79,-1.72l3.46 -4.96 -3.07 -4.14c-0.53,-0.68 -0.8,-1.27 -0.8,-1.78 0,-0.39 0.16,-0.74 0.48,-1.02 0.32,-0.29 0.73,-0.44 1.24,-0.44 0.45,0 0.82,0.12 1.12,0.38 0.29,0.25 0.64,0.65 1.03,1.19l2.48 3.4 2.41 -3.4c0.4,-0.56 0.75,-0.96 1.04,-1.21 0.3,-0.24 0.66,-0.36 1.1,-0.36 0.5,0 0.92,0.14 1.25,0.43 0.33,0.29 0.5,0.63 0.5,1.03 0,0.29 -0.07,0.57 -0.2,0.83 -0.14,0.27 -0.34,0.58 -0.63,0.95l-3.07 4.14 3.46 4.96c0.54,0.78 0.81,1.36 0.81,1.75 0,0.4 -0.17,0.75 -0.5,1.03 -0.34,0.29 -0.76,0.44 -1.25,0.44 -0.44,0 -0.81,-0.12 -1.12,-0.38 -0.3,-0.25 -0.65,-0.66 -1.04,-1.21z"/>
     </g>
    </svg>
    `;
  }

  const markerType = 'MARKER_ERROR';

  const defaultIcon =
    'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(svg);

  return {
    defaultIcon,
    widthAux,
    heightAux,
    markerType,
    xOffsetAux,
    yOffsetAux,
  };
};

const MAX_DISTANCE_ANIMATION = 300;
const ANIMATION_TIME = 10 * 1000;

export const AIRPLANES_LIST = [
  '2030556',
  '2033975',
  '2033970',
  'HT2033958',
  '2033950',
];

export const icons_fence_alerts = [
  'DESPACHADO_FORA_CERCA',
  'EM TRANSPORTE_FORA_CERCA',
  'EM ROTA_FORA_CERCA',
  'CHEGOU NO LOCAL_FORA_CERCA',
  'MANUTENÇÃO_FORA_CERCA',
  'ALIMENTAÇÃO_FORA_CERCA',
  'RENDIÇÃO_FORA_CERCA',
  'ABASTECIMENTO_FORA_CERCA',
  'DESCONECTADA_FORA_CERCA',
  'RÁDIO_FORA_CERCA',
  'DISPONÍVEL_FORA_CERCA',
];

export const icons_ais_alerts = [
  'DESPACHADO_FORA_AIS',
  'EM TRANSPORTE_FORA_AIS',
  'EM ROTA_FORA_AIS',
  'CHEGOU NO LOCAL_FORA_AIS',
  'MANUTENÇÃO_FORA_AIS',
  'ALIMENTAÇÃO_FORA_AIS',
  'RENDIÇÃO_FORA_AIS',
  'ABASTECIMENTO_FORA_AIS',
  'DESCONECTADA_FORA_AIS',
  'RÁDIO_FORA_AIS',
  'DISPONÍVEL_FORA_AIS',
];

export function loadVehicleIconsMap(
  prefixo: string,
  status: string | null,
  outFence: boolean,
  outAis: boolean,
  showTooltip: boolean,
  isLegend: boolean,
  error: boolean
) {
  let defaultIcon = '';
  let widthAux = 70;
  let heightAux = 70;
  let markerType = 'DISPONÍVEL';
  let xOffsetAux = 0;
  let yOffsetAux = 40;

  const vehicleStatus: any = {
    DESPACHADO: '#F56B06',
    'EM TRANSPORTE': '#FAA416',
    'EM ROTA': '#A59B06',
    'CHEGOU NO LOCAL': '#3880E2',
    MANUTENÇÃO: '#646464',
    ALIMENTAÇÃO: '#646464',
    RENDIÇÃO: '#646464',
    ABASTECIMENTO: '#646464',
  };

  let fillColorCircle = '#3959A6';
  let fillColorMarker = '#00A859';

  if (outFence) {
    fillColorCircle = '#C83538';
  }

  if (error) {
    return ReturnErrorMarker(prefixo, outFence, outAis, showTooltip);
  } else if (status && vehicleStatus[status]) {
    fillColorMarker = vehicleStatus[status];
    markerType = status;
  } else if (!status) {
    fillColorMarker = '#8F5BD9';
    markerType = 'DESCONECTADA';
  } else if (status === 'RÁDIO') {
    fillColorMarker = '#C7479A';
    markerType = 'RÁDIO';
    widthAux = 75;
    heightAux = 75;
  } else if (status === 'SEM_CADASTRO') {
    fillColorMarker = '#8B4513';
    markerType = 'SEM_CADASTRO';
  }

  let svg = '';

  if (status === 'RÁDIO' && !isLegend) {
    if (showTooltip && prefixo) {
      svg = `
      <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" width="21.0106mm" height="21.0106mm" version="1.1" shape-rendering="geometricPrecision" text-rendering="geometricPrecision" image-rendering="optimizeQuality" fill-rule="evenodd" clip-rule="evenodd"
      viewBox="0 0 286.71 286.71"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      xmlns:xodm="http://www.corel.com/coreldraw/odm/2003">
      
      <g id="Camada_x0020_1">
        <metadata id="CorelCorpID_0Corel-Layer"/>
        <rect fill="none" width="286.71" height="286.71"/>
        <g id="_2677133552032">
        <g>
        ${(outAis || outFence) &&
          `
          <path id="circle" fill="${fillColorCircle}" d="M147.65 262.61c-11.01,0 -17.95,-0.42 -28.28,-5.35 -2.63,-1.25 -6.27,-3.39 -8.51,-5.05 -7.73,-5.73 -14.4,-13.25 -18.6,-22.06 -7.65,-16.05 -6.92,-33.79 0.18,-49.87 2.49,-5.64 7.11,-11.37 11.42,-15.69 9.17,-9.18 23.74,-16.44 37.77,-16.44 10.65,0 17.86,0.86 27.11,5.52 18.11,9.14 32.63,27.22 32.63,49.7 0,16.67 -5.08,31 -16.44,42.29 -2.38,2.37 -4.44,4.41 -7.24,6.31 -8.32,5.62 -18.92,10.63 -30.03,10.63l0 0 -0.01 -0.01zm-67.27 -60.74c0,12.28 0.42,19.93 6.19,31.46 1.73,3.46 3.39,6.44 5.64,9.41 11.42,15.09 28.95,26.39 48.41,26.39 17.33,0 26.79,-2.32 40.57,-11.64 15.04,-10.17 26.69,-30.74 26.69,-49.11 0,-14.11 -0.75,-17.71 -5.94,-30.2 -1.1,-2.64 -3.89,-7.34 -5.52,-9.54 -3.3,-4.44 -6.85,-8.4 -11,-12.09 -9.38,-8.33 -25.68,-14.94 -38.27,-14.94 -13.36,0 -18.23,0.74 -30.26,5.88 -4.9,2.09 -16.02,9.72 -19.44,14.19 -0.78,1.02 -0.96,1.16 -1.88,2.14 -8.32,8.93 -15.18,25.21 -15.18,38.02l0 0 0.01 0.01z"/>
        `}
          <path id="marker" fill="#C7479A" stroke="#FEFEFE" stroke-width="6.99" stroke-miterlimit="22.9256" d="M143.12 153.34c22.53,0 40.8,18.27 40.8,40.8 0,22.53 -35.64,63.22 -40.8,63.22 -5.17,0 -40.8,-40.67 -40.8,-63.22 0,-22.53 18.27,-40.8 40.8,-40.8z"/>
          <path id="phone" fill="#FEFEFE" d="M148.95 223.27l3.48 0 0 3.75 -3.48 0 0 -3.75zm-22.51 -37.93l0.16 1.1c0.58,-0.13 1.21,-0.28 1.79,-0.41 -0.13,-1.66 -0.02,-2.26 1.1,-3.75 0.92,-1.22 2.06,-1.66 3.62,-1.66 0.13,-1.63 1.37,-1.8 -1.12,-1.8 -1.82,0 -4.26,2.24 -5,4.03 -0.17,0.41 -0.67,1.89 -0.58,2.49l0 0 0 0.02 0.02 0 0.01 0 0 -0.01zm-4.03 -0.84l0.13 1.8c0.55,-0.11 1.22,-0.38 1.8,-0.41 -0.04,-0.52 -0.13,-0.39 -0.13,-1.12 0,-4.03 3.13,-7.65 7.08,-8.22 2.4,-0.34 2.21,0.57 2.44,-1.05 0.13,-1 0.07,-0.9 -1.19,-0.9 -2.88,0 -5.18,1.07 -7.06,2.97 -1.69,1.69 -3.08,4.26 -3.08,6.91l0 0 0 0.02 0 0 0 -0.01 0.01 0.01zm-4.44 0l0 1.4 1.8 -0.13c0,-3.88 1.44,-7.47 4,-10.05 2.49,-2.49 4.87,-3.43 9.76,-3.43 0.21,-0.92 0.13,-0.7 0.13,-1.8 -4.04,0 -6.36,0.3 -9.64,2.58 -0.41,0.28 -0.55,0.46 -0.9,0.76 -2.58,2.01 -5.15,7.29 -5.15,10.7l0 0.02 -0.02 -0.02 0 0 0 0 0.02 -0.02zm24.86 38.77l3.62 0 0 3.75 -3.62 0 0 -3.75zm-6.12 0l3.62 0 0 3.75 -3.62 0 0 -3.75zm12.08 -6.12l3.62 0 0 3.75 -3.62 0 0 -3.75zm-6.12 0l3.62 0 0 3.75 -3.62 0 0 -3.75zm-6.11 0.41c0,-0.32 0.09,-0.41 0.41,-0.41l3.19 0 0 3.75 -3.62 0 0 -3.34 0 0 0.02 0 -0.01 0 0 0zm12.08 -6.4l3.62 0 0 3.62 -3.62 0 0 -3.62zm-5.96 0l3.48 0 0 3.62 -3.48 0 0 -3.62zm-6.11 0l3.62 0 0 3.62 -3.62 0 0 -3.62zm-0.57 -4.71l0 -13.62c0,-0.66 0.32,-0.98 0.98,-0.98l15 0c0.45,0 0.98,0.25 0.98,0.7 0,2.4 0.2,13.39 -0.13,14.85 -1.48,0.35 -13.47,0.13 -15.97,0.13 -0.38,0 -0.38,-0.07 -0.7,-0.13 -0.04,-0.43 -0.13,-0.49 -0.13,-0.98l0 0 0 0.02 0 0 0 0 -0.02 0.01zm10.01 -41.95l0 21.12 -10.84 0c-1.77,0 -3.34,0.84 -3.34,2.65l0 41.82c0,1.79 1.55,2.65 3.34,2.65l18.9 0c0.93,0 1.73,-0.2 2.3,-0.61 0.51,-0.35 1.03,-1.22 1.03,-2.04l0 -41.82c0,-3.04 -3.71,-2.65 -5.56,-2.65l0 -21.38c0,-2.43 -2.26,-2.21 -3.48,-2.21 -1.34,0 -2.37,1.17 -2.37,2.51l0 0 0 -0.02 0 -0.02 0 0.01 0.01 -0z"/>
          <path id="comment" fill="#FEFEFE" stroke="#A2A2A3" stroke-width="2.46" stroke-miterlimit="22.9256" d="M143.12 164.42c3.69,-2.46 26.47,-26.4 32,-32.14 2.55,-2.65 2.76,-4.24 7.87,-4.34 8.37,-0.14 17,0.04 25.4,0.04 19.3,0 47.36,4.38 56.05,-20.06 3.41,-9.59 2.64,-63.35 1.92,-73.5 -1,-13.96 -11.1,-24.39 -23,-27.54 -8.89,-2.34 -38.39,-1.04 -49.54,-1.04 -19.95,0 -142.59,-1.34 -151.55,1.17 -11.89,3.33 -21.62,13.66 -22.57,27.96 -1.04,15.64 -0.1,34.69 -0.1,50.7 0,13.36 -0.7,27.58 13.58,36.98 16.14,10.59 61.09,2.78 70.49,5.61 3.49,1.04 33.4,32.26 39.46,36.18l-0.03 0 0 -0.01 0.01 -0.01 -0.01 -0z"/>
          
        </g>
        </g>
        <g id="_2262552347136">
        <g transform="matrix(1 0 0 1 2092.4 877.04)">
          <text x="-2050.53" y="-791.22"  fill="black" font-weight="bold" font-size="46.05px" font-family="Arial">${prefixo}</text>
        </g>
        </g>
      </g>
      </svg>
    `;
    } else {
      widthAux = 35;
      heightAux = 35;
      yOffsetAux = 0;

      svg = `
      <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" width="9.2406mm" height="9.2374mm" version="1.1" shape-rendering="geometricPrecision" text-rendering="geometricPrecision" image-rendering="optimizeQuality" fill-rule="evenodd" clip-rule="evenodd"
      viewBox="0 0 42.52 42.52"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      xmlns:xodm="http://www.corel.com/coreldraw/odm/2003">
        <g id="Camada_x0020_1">
          <metadata id="CorelCorpID_0Corel-Layer"/>
          ${(outAis || outFence) &&
            `
          <path id="circle" fill="#C83538" d="M22.44 40.34c-3.67,0 -5.98,-0.14 -9.43,-1.79 -0.88,-0.41 -2.09,-1.13 -2.83,-1.68 -2.58,-1.91 -4.8,-4.42 -6.2,-7.35 -2.55,-5.36 -2.31,-11.27 0.06,-16.63 0.83,-1.88 2.37,-3.79 3.81,-5.23 3.06,-3.06 7.92,-5.48 12.6,-5.48 3.55,0 5.96,0.29 9.04,1.84 6.04,3.05 10.88,9.07 10.88,16.57 0,5.56 -1.69,10.34 -5.48,14.1 -0.79,0.79 -1.48,1.47 -2.41,2.11 -2.77,1.87 -6.31,3.55 -10.01,3.55l0 0 0 0 -0.02 -0zm-22.43 -20.26c0,4.1 0.14,6.65 2.06,10.49 0.58,1.16 1.14,2.15 1.88,3.14 3.81,5.03 9.65,8.8 16.15,8.8 5.78,0 8.93,-0.78 13.53,-3.88 5.02,-3.39 8.9,-10.25 8.9,-16.37 0,-4.71 -0.25,-5.9 -1.98,-10.07 -0.37,-0.88 -1.3,-2.45 -1.84,-3.18 -1.1,-1.48 -2.29,-2.8 -3.67,-4.03 -3.12,-2.78 -8.56,-4.98 -12.76,-4.98 -4.46,0 -6.08,0.25 -10.09,1.96 -1.64,0.7 -5.34,3.24 -6.48,4.73 -0.26,0.34 -0.32,0.38 -0.63,0.71 -2.77,2.98 -5.07,8.4 -5.07,12.68l0 0 0 0 0.01 0.01z"/>
          `}
          <path id="marker" fill="${fillColorMarker}" stroke="#FEFEFE" stroke-width="2.33" stroke-miterlimit="22.9256" d="M20.93 3.91c7.51,0 13.6,6.09 13.6,13.6 0,7.51 -11.89,21.08 -13.6,21.08 -1.72,0 -13.6,-13.56 -13.6,-21.08 0,-7.51 6.09,-13.6 13.6,-13.6z"/>
          <path id="radio" fill="#FEFEFE" d="M14.79 14.97l0.06 0.37c0.19,-0.05 0.41,-0.09 0.61,-0.14 -0.05,-0.56 -0,-0.76 0.37,-1.27 0.31,-0.41 0.7,-0.56 1.23,-0.56 0.05,-0.55 0.46,-0.61 -0.38,-0.61 -0.62,0 -1.45,0.76 -1.69,1.36 -0.06,0.14 -0.23,0.64 -0.19,0.84l0 0 0 0zm7.64 12.77l1.18 0 0 1.27 -1.18 0 0 -1.27zm-2.08 0l1.23 0 0 1.27 -1.23 0 0 -1.27zm-2.08 0l1.23 0 0 1.27 -1.23 0 0 -1.27zm4.1 -2.06l1.23 0 0 1.27 -1.23 0 0 -1.27zm-2.08 0l1.23 0 0 1.27 -1.23 0 0 -1.27zm-2.07 0.14c0,-0.11 0.03,-0.14 0.14,-0.14l1.09 0 0 1.27 -1.23 0 0 -1.12 0 0zm4.1 -2.16l1.23 0 0 1.22 -1.23 0 0 -1.22zm-2.03 0l1.18 0 0 1.22 -1.18 0 0 -1.22zm-2.07 0l1.23 0 0 1.22 -1.23 0 0 -1.22zm-0.19 -1.59l0 -4.59c0,-0.22 0.11,-0.33 0.33,-0.33l5.09 0c0.16,0 0.33,0.08 0.33,0.24 0,0.81 0.07,4.51 -0.05,5.01 -0.5,0.12 -4.57,0.05 -5.42,0.05 -0.13,0 -0.13,-0.02 -0.24,-0.05 -0.01,-0.15 -0.05,-0.16 -0.05,-0.33l0 0 0 0zm3.39 -14.13l0 7.11 -3.68 0c-0.6,0 -1.13,0.28 -1.13,0.89l0 14.09c0,0.6 0.52,0.89 1.13,0.89l6.41 0c0.32,0 0.58,-0.07 0.78,-0.21 0.17,-0.12 0.35,-0.41 0.35,-0.68l0 -14.09c0,-1.03 -1.26,-0.89 -1.89,-0.89l0 -7.2c0,-0.82 -0.77,-0.75 -1.18,-0.75 -0.46,0 -0.8,0.4 -0.8,0.84l0 0 0 -0zm-9.52 6.74l0 0.47 0.61 -0.05c0,-1.31 0.49,-2.52 1.36,-3.38 0.84,-0.84 1.65,-1.16 3.31,-1.16 0.07,-0.31 0.05,-0.24 0.05,-0.61 -1.37,0 -2.16,0.1 -3.27,0.87 -0.14,0.09 -0.18,0.16 -0.31,0.25 -0.88,0.67 -1.74,2.45 -1.74,3.6l0 0 -0 -0zm1.51 0l0.05 0.61c0.18,-0.04 0.42,-0.13 0.61,-0.14 -0.01,-0.17 -0.05,-0.13 -0.05,-0.38 0,-1.36 1.06,-2.58 2.4,-2.77 0.82,-0.11 0.75,0.19 0.83,-0.35 0.05,-0.34 0.03,-0.3 -0.4,-0.3 -0.98,0 -1.76,0.36 -2.39,1 -0.57,0.57 -1.05,1.43 -1.05,2.33l0 0 -0 0z"/>
        </g>
      </svg>
      `;
    }
  } else if (isLegend) {
    svg = `
    <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" width="12.7474mm" height="15.974mm" version="1.0" shape-rendering="geometricPrecision" text-rendering="geometricPrecision" image-rendering="optimizeQuality" fill-rule="evenodd" clip-rule="evenodd"
    viewBox="0 0 163.32 204.65"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns:xodm="http://www.corel.com/coreldraw/odm/2003">
      <g id="Camada_x0020_1">
        <metadata id="CorelCorpID_0Corel-Layer"/>
        <g id="_2586741335104">
        <path id="marker" fill="${fillColorMarker}" stroke="#FEFEFE" stroke-width="12.81" stroke-miterlimit="22.9256" d="M81.66 6.41c41.56,0 75.25,33.69 75.25,75.25 0,41.56 -65.71,116.59 -75.25,116.59 -9.54,0 -75.25,-75.03 -75.25,-116.59 0,-41.56 33.69,-75.25 75.25,-75.25z"/>
        </g>
      </g>
      <g id="Layer_x0020_1">
        <metadata id="CorelCorpID_1Corel-Layer"/>
        <g id="_2586741336544">
        <path fill="#FEFEFE" d="M110.35 75.03c8.11,-2.92 11.23,9.34 4.4,11.73 -8.32,2.92 -12.3,-8.89 -4.4,-11.73zm-61.8 0.17c7.94,-3.38 11.62,8.54 5.02,11.39 -3.76,1.62 -7.35,-0.23 -8.48,-2.9 -0.74,-1.77 -0.8,-3.54 0.01,-5.51 0.79,-1.94 1.54,-2.15 3.45,-2.97zm4.82 -12.73c0.87,-3.8 4.59,-15.23 6.69,-17.1 2.99,-2.65 16.72,-1.33 21.69,-1.33 5.28,0 19.02,-1.35 21.82,1.71 1.42,1.55 5.54,13.66 6.26,16.76l-56.46 -0.05zm-2.45 39.99l61.38 -0.05c0.31,6.72 -1.63,14.77 5.89,15.35 8.3,0.64 12.61,0.31 12.6,-8.08 -0.01,-8.34 1.36,-35.38 -1.57,-40.81 -0.63,-1.17 -1.38,-2.05 -2.25,-2.89 -4.48,-4.34 -2.53,1.9 -6.37,-9.56 -1.47,-4.38 -3.6,-11.7 -5.63,-15.38 -6.15,-11.1 -18.18,-9.41 -33.21,-9.41 -31.11,0 -30.55,0.03 -38.98,24.19 -0.78,2.24 -1.57,6.24 -3.07,7.69 -2.68,2.6 -3.15,0.68 -5.61,5.16 -2.76,5.03 -1.67,13.12 -1.67,19.44 0,27.37 -2.78,30.62 12.38,29.65 7.84,-0.5 5.74,-8.3 6.11,-15.3z"/>
        </g>
      </g>
    </svg>
    `;
  } else if (showTooltip && prefixo) {
    svg = `
    <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" width="21.0106mm" height="21.0106mm" version="1.1" shape-rendering="geometricPrecision" text-rendering="geometricPrecision" image-rendering="optimizeQuality" fill-rule="evenodd" clip-rule="evenodd"
    viewBox="0 0 3578.1 3543.93"
     xmlns:xlink="http://www.w3.org/1999/xlink"
     xmlns:xodm="http://www.corel.com/coreldraw/odm/2003">
     <defs>
      <font id="FontID0" horiz-adv-x="722" font-variant="normal" fill-rule="nonzero" font-weight="700">
      <font-face 
        font-family="Arial">
        <font-face-src>
          <font-face-name name="Arial Negrito"/>
        </font-face-src>
      </font-face>
       <missing-glyph><path d="M0 0z"/></missing-glyph>
       <glyph unicode="$" horiz-adv-x="556" d="M238.002 107.007l0 204.16c-63.5087,18.1735 -109.998,45.6692 -139.497,82.5021 -29.6667,36.9999 -44.5001,81.667 -44.5001,134.335 0,53.3364 16.8223,98.1553 50.3302,134.335 33.4928,36.3319 77.9929,57.1624 133.667,62.6585l0 48.0073 70.9937 0 0 -48.0073c52.0003,-6.16412 93.3424,-23.8366 124.178,-53.0024 30.6536,-29.1657 50.3302,-68.1697 58.8325,-116.997l-124.011 -16.0024c-7.6672,38.336 -27.3286,64.3437 -58.9995,78.008l0 -190.511c78.5091,-20.9975 131.997,-48.4931 160.343,-82.0011 28.4977,-33.6598 42.663,-76.8238 42.663,-129.492 0,-58.8325 -17.8395,-108.328 -53.6704,-148.668 -35.8309,-40.3401 -85.4931,-65.3306 -149.336,-75.3359l0 -90.9891 -70.9937 0 0 89.9871c-56.0085,6.83216 -101.495,27.5108 -136.673,62.3396 -35.1628,34.8288 -57.4964,84.1722 -67.3347,147.666l127.002 15.0004c5.17726,-26.0077 15.0004,-48.3413 29.3327,-67.1677 14.3323,-18.8264 30.1678,-32.3389 47.6733,-40.8259zm0 511.987c-19.1756,-6.83216 -34.176,-17.9913 -45.3352,-33.6598 -11.1744,-15.6684 -16.6705,-32.8399 -16.6705,-51.6663 0,-17.1715 5.01025,-33.1739 15.1674,-48.0073 10.1723,-14.6664 25.8407,-26.6606 46.8382,-35.6639l0 168.997zm70.9937 -519.988c24.5047,5.32908 44.5001,16.9893 59.8345,34.9958 15.5014,17.9913 23.1686,39.171 23.1686,63.6605 0,21.6655 -6.49814,40.5071 -19.4944,56.1755 -12.8445,15.8354 -34.009,27.8297 -63.5087,36.3319l0 -191.164z"/>
       <glyph unicode="e" horiz-adv-x="556" d="M372.003 164.002l136.992 -23.0016c-17.4903,-50.1632 -45.3352,-88.3322 -83.1549,-114.674 -38.002,-26.1596 -85.5082,-39.3229 -142.337,-39.3229 -90.1693,0 -157.003,29.4997 -200.167,88.4992 -34.176,47.3241 -51.3323,106.992 -51.3323,179.17 0,85.9941 22.5006,153.496 67.3347,202.156 44.8341,48.8423 101.662,73.1648 170.333,73.1648 76.9908,0 137.827,-25.4915 182.494,-76.4898 44.5001,-50.9983 65.8316,-129.173 63.8275,-234.51l-342.989 0c1.00205,-40.6589 11.9942,-72.3298 33.1587,-94.9973 20.9975,-22.6676 47.3393,-33.9938 78.6761,-33.9938 21.4985,0 39.4899,5.83011 53.9892,17.4903 14.6664,11.6754 25.6737,30.5018 33.1739,56.5095zm8.00121 138.996c-1.00205,39.8391 -11.1744,70.1738 -30.6688,90.8373 -19.5096,20.8305 -43.164,31.1698 -71.1759,31.1698 -29.8338,0 -54.4902,-11.0074 -73.9998,-32.8399 -19.4944,-21.8325 -28.9987,-51.6663 -28.6647,-89.1672l204.509 0z"/>
       <glyph unicode="f" horiz-adv-x="332" d="M11.9942 519.001l76.0039 0 0 39.171c0,43.6651 4.66105,76.3228 13.9983,97.8213 9.33728,21.6807 26.5088,39.171 51.4993,52.6683 25.1727,13.5125 56.8435,20.3446 95.1644,20.3446 39.171,0 77.674,-5.84529 115.342,-17.5055l-17.5055 -95.3314c-22.3336,5.16207 -43.8321,7.83421 -64.4956,7.83421 -20.3295,0 -34.8288,-4.67623 -43.6651,-13.9983 -8.83626,-9.33728 -13.3303,-27.3438 -13.3303,-54.0044l0 -36.9999 102.999 0 0 -107.994 -102.999 0 0 -411.007 -137.008 0 0 411.007 -76.0039 0 0 107.994z"/>
       <glyph unicode="i" horiz-adv-x="277" d="M71.9957 588.993l0 127.002 137.008 0 0 -127.002 -137.008 0zm0 -588.993l0 519.001 137.008 0 0 -519.001 -137.008 0z"/>
       <glyph unicode="o" horiz-adv-x="610" d="M40.0061 265.832c0,45.6692 11.1592,89.8353 33.6598,132.498 22.5006,42.83 54.3384,75.3359 95.4984,97.8365 41.1751,22.5006 86.9961,33.8268 137.843,33.8268 78.4939,0 142.655,-25.4915 192.834,-76.4898 50.1632,-51.1653 75.1537,-115.509 75.1537,-193.502 0,-78.6609 -25.3245,-143.84 -75.8218,-195.506 -50.6794,-51.6663 -114.34,-77.4918 -191.179,-77.4918 -47.4911,0 -92.8262,10.8252 -135.99,32.3237 -42.997,21.4985 -75.8369,53.0024 -98.3375,94.6785 -22.5006,41.494 -33.6598,92.1582 -33.6598,151.826zm141.001 -7.33318c0,-51.4993 12.1612,-91.0043 36.4989,-118.333 24.4895,-27.4956 54.4902,-41.1599 90.3211,-41.1599 35.679,0 65.6798,13.6643 89.8353,41.1599 24.1707,27.3286 36.3319,67.1677 36.3319,119.335 0,50.8312 -12.1612,90.0023 -36.3319,117.331 -24.1555,27.4956 -54.1562,41.1751 -89.8353,41.1751 -35.8309,0 -65.8316,-13.6795 -90.3211,-41.1751 -24.3377,-27.3286 -36.4989,-66.8337 -36.4989,-118.333z"/>
       <glyph unicode="p" horiz-adv-x="610" d="M68.0027 519.001l128.004 0 0 -76.171c16.6553,26.0077 38.9888,47.0052 67.3195,63.1747 28.3459,16.0024 59.8345,23.9885 94.3445,23.9885 60.1685,0 111.334,-23.6544 153.329,-70.9937 41.995,-47.1722 63.0077,-113.171 63.0077,-197.495 0,-86.8443 -21.1797,-154.164 -63.5087,-202.338 -42.329,-48.1591 -93.6613,-72.1628 -153.997,-72.1628 -28.6647,0 -54.6724,5.6631 -78.008,17.1563 -23.1534,11.3414 -47.8251,30.8358 -73.4988,58.5136l0 -259.668 -136.992 0 0 715.995zm135.003 -251.499c0,-58.4984 11.4932,-101.662 34.6618,-129.674 23.0016,-27.8297 51.1653,-41.828 84.1722,-41.828 31.8227,0 58.3314,12.8293 79.4959,38.503 21.1645,25.6585 31.6708,67.6687 31.6708,126.167 0,54.6573 -10.8404,95.1644 -32.6729,121.658 -21.6655,26.5088 -48.6601,39.6721 -80.832,39.6721 -33.5079,0 -61.3376,-12.9963 -83.3371,-39.004 -22.1666,-25.9926 -33.1587,-64.4956 -33.1587,-115.494z"/>
       <glyph unicode="r" horiz-adv-x="389" d="M203.006 0l-137.008 0 0 519.001 127.002 0 0 -73.6658c21.8325,34.8288 41.494,57.6634 58.9995,68.5038 17.5055,10.8252 37.3339,16.1543 59.5005,16.1543 31.3368,0 61.5046,-8.65407 90.5033,-25.8256l-42.5112 -119.669c-23.1534,15.0004 -44.6671,22.5006 -64.4956,22.5006 -19.3274,0 -35.6639,-5.32908 -48.9942,-15.8354 -13.3303,-10.6582 -23.8366,-29.6667 -31.5038,-57.3294 -7.6672,-27.6626 -11.4932,-85.6752 -11.4932,-173.84l0 -159.994z"/>
       <glyph unicode="x" horiz-adv-x="556" d="M5.49609 0l187.004 267.335 -179.003 251.666 167.342 0 91.6572 -142.671 97.3355 142.671 160.662 0 -175.829 -245.836 191.832 -273.165 -167.16 0 -106.84 160.829 -106.324 -160.829 -160.677 0z"/>
       <glyph unicode="{" horiz-adv-x="389" d="M28.9987 201.002l0 116.997c23.8366,1.33607 41.661,4.82806 53.8374,10.8404 11.9942,5.83011 22.3336,15.6532 31.1698,29.4997 8.82107,13.8313 14.8334,31.1546 18.1584,52.0003 2.50512,15.6532 3.84119,42.83 3.84119,81.667 0,63.1595 2.99097,107.159 8.82107,132.164 5.84529,24.9905 16.3364,45.0011 31.6708,60.1685 15.3344,15.1674 37.668,26.9946 66.8337,35.8309 19.8284,5.83011 51.1653,8.83626 93.8283,8.83626l25.8407 0 0 -117.012c-36.3319,0 -59.5005,-1.98892 -69.8398,-6.16412 -10.3241,-3.99302 -17.8243,-10.1571 -22.8194,-18.4924 -4.84324,-8.33523 -7.34836,-22.5006 -7.34836,-42.6782 0,-20.4965 -1.32088,-59.4853 -3.99302,-116.663 -1.67008,-32.3237 -5.83011,-58.3314 -12.6623,-78.4939 -6.83216,-19.9954 -15.5014,-36.4989 -26.1748,-49.5104 -10.4912,-12.9963 -26.6606,-26.4936 -48.4931,-40.4919 19.3274,-11.0074 34.9958,-24.0036 47.3241,-38.837 12.3434,-14.8334 21.6807,-32.8247 28.1789,-54.0044 6.66515,-21.1645 10.8252,-49.6622 12.8293,-85.159 2.0041,-54.0044 2.99097,-88.6662 2.99097,-103.667 0,-21.4985 2.67213,-36.4989 7.83421,-45.0011 5.17726,-8.33523 13.0115,-14.8334 23.6696,-19.1604 10.5063,-4.50922 33.3409,-6.66515 68.5038,-6.66515l0 -117.012 -25.8407 0c-43.9991,0 -77.6588,3.50717 -101.161,10.5063 -23.3356,6.99916 -43.164,18.6594 -59.1665,34.9958 -16.1694,16.1694 -26.9946,36.3319 -32.4907,60.3355 -5.51127,23.8366 -8.33523,61.6716 -8.33523,113.171 0,59.8345 -2.67213,98.8234 -7.83421,116.663 -7.16617,26.1748 -18.0065,44.8341 -32.5059,55.9933 -14.4994,11.3414 -36.6659,17.6725 -66.6667,19.3426z"/>
       <glyph unicode="}" horiz-adv-x="389" d="M356.001 201.002c-23.8366,-1.33607 -41.661,-4.82806 -53.6704,-10.6733 -12.1612,-5.99712 -22.5006,-15.8354 -31.1698,-29.6667 -8.48706,-13.8313 -14.6664,-31.1546 -18.3254,-52.0003 -2.50512,-15.6684 -3.84119,-42.663 -3.84119,-81.166 0,-63.1595 -2.82396,-107.326 -8.48706,-132.498 -5.67828,-24.9905 -16.1694,-45.1681 -31.5038,-60.3355 -15.3344,-15.1674 -37.835,-26.9946 -67.3347,-35.8309 -19.8284,-5.83011 -51.1653,-8.83626 -93.8283,-8.83626l-25.8407 0 0 117.012c34.9958,0 57.8304,2.15592 68.6708,6.66515 10.6582,4.32703 18.6594,10.6582 23.6696,18.9934 5.16207,8.33523 7.6672,22.3336 7.83421,42.329 0.151826,19.8436 1.48789,57.8456 3.82601,113.839 1.67008,33.9938 5.99712,61.0036 13.1633,81.5 7.16617,20.3295 16.6705,37.668 28.4977,51.8333 12.0094,14.1653 27.1768,26.4936 45.6692,37.3339 -24.0036,15.6684 -41.661,31.0028 -52.6683,45.8362 -15.3344,21.4985 -25.8256,48.8271 -31.3216,82.1681 -3.50717,22.6676 -6.0123,72.8308 -7.33318,150.323 -0.334017,24.3377 -2.50512,40.6741 -6.68033,48.8423 -3.99302,8.00121 -11.3262,14.3323 -21.9995,18.6594 -10.6582,4.50922 -34.3278,6.66515 -71.3277,6.66515l0 117.012 25.8407 0c43.9991,0 77.6588,-3.50717 101.161,-10.3393 23.3356,-6.83216 42.997,-18.5076 58.9995,-34.8288 16.0024,-16.5035 26.8276,-36.6659 32.5059,-60.6696 5.6631,-23.8366 8.48706,-61.6716 8.48706,-113.171 0,-59.5005 2.50512,-98.5045 7.33318,-116.663 7.16617,-26.1748 18.0065,-44.8341 32.6729,-56.0085 14.6664,-11.3262 36.9999,-17.6573 67.0007,-19.3274l0 -116.997z"/>
      </font>
     </defs>
     <g id="Camada_x0020_1">
      <metadata id="CorelCorpID_0Corel-Layer"/>
      <rect fill="none" x="17.09" y="0" width="3543.93" height="3543.93"/>
      <g id="_2069084618000">
      ${(outAis || outFence) &&
        `
       <path id="circle" fill="${fillColorCircle}" d="M1854.2 3449.91c-157.47,0 -256.68,-5.94 -404.42,-76.46 -37.66,-17.94 -89.6,-48.41 -121.7,-72.17 -110.59,-81.9 -205.88,-189.44 -265.91,-315.44 -109.45,-229.51 -98.96,-483.15 2.53,-713.04 35.64,-80.64 101.61,-162.65 163.28,-224.33 131.18,-131.31 339.46,-235.07 540.16,-235.07 152.29,0 255.41,12.26 387.61,78.99 258.95,130.68 466.6,389.25 466.6,710.65 0,238.36 -72.67,443.22 -235.07,604.74 -34,33.87 -63.44,63.06 -103.51,90.23 -118.92,80.38 -270.58,152.03 -429.44,152.03l0 0 -0.13 -0.12zm-961.89 -868.49c0,175.67 5.94,284.99 88.46,449.91 24.77,49.55 48.54,92.13 80.64,134.6 163.28,215.73 414.03,377.38 692.19,377.38 247.84,0 383.06,-33.11 580.09,-166.44 215.11,-145.47 381.67,-439.56 381.67,-702.18 0,-201.83 -10.75,-253.27 -84.92,-431.85 -15.68,-37.79 -55.6,-105.02 -78.99,-136.37 -47.14,-63.44 -97.95,-120.06 -157.34,-172.89 -134.09,-119.17 -367.14,-213.59 -547.23,-213.59 -191.09,0 -260.72,10.62 -432.73,84.04 -70.02,29.83 -229.13,139.02 -277.92,202.97 -11.12,14.66 -13.78,16.56 -26.92,30.58 -118.92,127.64 -217.12,360.44 -217.12,543.7l0 0 0.12 0.13z"/>
       `}
       <path id="marker" fill="${fillColorMarker}" stroke="#FEFEFE" stroke-width="99.97" stroke-miterlimit="22.9256" d="M1789.37 1887.46c322.14,0 583.37,261.23 583.37,583.37 0,322.14 -509.57,904.01 -583.37,904.01 -73.94,0 -583.37,-581.6 -583.37,-904.01 0,-322.14 261.23,-583.37 583.37,-583.37z"/>
       <path id="car" fill="#FEFEFE" d="M2011.8 2419.4c62.94,-22.75 86.95,72.42 34.12,90.99 -64.33,22.75 -95.42,-68.88 -34.12,-90.99zm-479 1.13c61.42,-26.29 90.11,66.1 38.8,88.08 -29.19,12.52 -57.12,-1.77 -65.72,-22.62 -5.69,-13.65 -6.32,-27.42 0.13,-42.85 6.32,-14.92 12.01,-16.68 26.66,-22.87l-0.13 0 0.13 0.13 0 0 0.13 0.13zm37.54 -98.7c6.7,-29.45 35.77,-118.16 52.06,-132.7 23.12,-20.85 129.92,-10.24 167.96,-10.24 40.95,0 147.62,-10.37 169.35,13.15 11,12.01 42.97,105.78 48.66,130.05l-437.54 -0.63 -0.13 0 0 0 -0.13 0.13 0 0 -0.23 0.24zm-19.08 310.02l475.83 -0.63c2.15,52.06 -12.77,114.63 45.75,119.17 64.33,4.93 97.57,2.15 97.57,-62.56 -0.13,-64.58 10.37,-274.38 -12.14,-316.46 -4.68,-9.1 -10.75,-15.93 -17.44,-22.62 -34.76,-33.74 -19.59,14.79 -49.42,-74.19 -11.5,-34 -27.68,-90.61 -43.73,-119.3 -47.65,-86.19 -141.04,-72.92 -257.44,-72.92 -241.14,0 -236.84,0.13 -302.05,187.42 -6.32,17.44 -12.14,48.54 -23.76,59.65 -20.72,20.22 -24.52,5.31 -43.35,40.19 -21.35,38.8 -12.89,101.86 -12.89,150.64 0,212.07 -21.35,237.47 96.18,230.01 60.79,-3.92 44.36,-64.33 47.52,-118.92l-0.13 0.13 0 0 -0.13 0.38 0 0 -0.37 0.01z"/>
      
       <path id="comment" fill="#FEFEFE" stroke="#A2A2A3" stroke-width="35.13" stroke-miterlimit="22.9256" d="M1872.07 2014.33c117.58,-105.35 315.6,-295.89 374.94,-352.24 36.53,-34.75 39.43,-55.58 112.48,-56.86 119.68,-1.85 243.16,0.58 363.22,0.58 276.02,0 677.28,57.33 801.51,-262.88 48.79,-125.65 37.79,-830.12 27.42,-963.06 -14.29,-182.86 -158.73,-319.63 -328.84,-360.86 0,0 -2403.38,-2.22 -2875.43,1.62 -169.98,43.67 -309.13,178.93 -322.78,366.31 -14.92,204.98 -1.39,454.55 -1.39,664.39 0,175.11 -9.99,361.44 194.25,484.54 230.77,138.74 873.55,36.48 1008.02,73.54 40.05,10.97 323.18,276.36 478.39,407.4 104.03,87.85 67.57,87.7 168.23,-2.47z"/>
       <g transform="matrix(1 0 0 1 -1490.37 -784.733)">
        <text x="1789.59" y="1764.84"  fill="black" font-weight="bold" font-size="658.45px" font-family="Arial">${prefixo}</text>
       </g>
      </g>
     </g>
    </svg>
    `;
  } else {
    widthAux = 35;
    heightAux = 35;
    yOffsetAux = 0;

    svg = `
      <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" width="9.2373mm" height="9.2386mm" version="1.1" shape-rendering="geometricPrecision" text-rendering="geometricPrecision" image-rendering="optimizeQuality" fill-rule="evenodd" clip-rule="evenodd"
      viewBox="0 0 54.3 54.3"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      xmlns:xodm="http://www.corel.com/coreldraw/odm/2003">
        <g id="Camada_x0020_1">
          <metadata id="CorelCorpID_0Corel-Layer"/>
          ${(outAis || outFence) &&
            `
              <path id="circle" fill="${fillColorCircle}" d="M28.65 51.52c-4.69,0 -7.64,-0.18 -12.04,-2.28 -1.12,-0.53 -2.67,-1.44 -3.62,-2.15 -3.29,-2.44 -6.13,-5.64 -7.92,-9.39 -3.26,-6.84 -2.95,-14.39 0.08,-21.24 1.06,-2.4 3.03,-4.84 4.86,-6.68 3.91,-3.91 10.11,-7 16.09,-7 4.54,0 7.61,0.37 11.54,2.35 7.71,3.89 13.9,11.59 13.9,21.16 0,7.1 -2.16,13.2 -7,18.01 -1.01,1.01 -1.89,1.88 -3.08,2.69 -3.54,2.39 -8.06,4.53 -12.79,4.53l0 0 -0 -0zm-28.65 -25.87c0,5.23 0.18,8.49 2.63,13.4 0.74,1.48 1.45,2.74 2.4,4.01 4.86,6.43 12.33,11.24 20.62,11.24 7.38,0 11.41,-0.99 17.28,-4.96 6.41,-4.33 11.37,-13.09 11.37,-20.91 0,-6.01 -0.32,-7.54 -2.53,-12.86 -0.47,-1.13 -1.66,-3.13 -2.35,-4.06 -1.4,-1.89 -2.92,-3.58 -4.69,-5.15 -3.99,-3.55 -10.93,-6.36 -16.3,-6.36 -5.69,0 -7.76,0.32 -12.89,2.5 -2.09,0.89 -6.82,4.14 -8.28,6.04 -0.33,0.44 -0.41,0.49 -0.8,0.91 -3.54,3.8 -6.47,10.73 -6.47,16.19l0 0 0 0z"/>  
          `}
          <path id="marker" fill="${fillColorMarker}" stroke="#FEFEFE" stroke-width="2.98" stroke-miterlimit="22.9256" d="M26.72 4.99c9.59,0 17.37,7.78 17.37,17.37 0,9.59 -15.18,26.92 -17.37,26.92 -2.2,0 -17.37,-17.32 -17.37,-26.92 0,-9.59 7.78,-17.37 17.37,-17.37z"/>
    
          <path id="car" fill="#FEFEFE" d="M33.34 20.83c1.87,-0.68 2.59,2.16 1.02,2.71 -1.92,0.68 -2.84,-2.05 -1.02,-2.71zm-14.27 0.03c1.83,-0.78 2.68,1.97 1.16,2.62 -0.87,0.37 -1.7,-0.05 -1.96,-0.67 -0.17,-0.41 -0.19,-0.82 0,-1.28 0.19,-0.44 0.36,-0.5 0.79,-0.68l-0 0 0 0 0 0 0 0zm1.12 -2.94c0.2,-0.88 1.07,-3.52 1.55,-3.95 0.69,-0.62 3.87,-0.3 5,-0.3 1.22,0 4.4,-0.31 5.04,0.39 0.33,0.36 1.28,3.15 1.45,3.87l-13.03 -0.02 -0 0 0 0 -0 0 0 0 -0.01 0.01zm-0.57 9.23l14.17 -0.02c0.06,1.55 -0.38,3.41 1.36,3.55 1.92,0.15 2.91,0.06 2.91,-1.86 -0,-1.92 0.31,-8.17 -0.36,-9.43 -0.14,-0.27 -0.32,-0.47 -0.52,-0.67 -1.04,-1 -0.58,0.44 -1.47,-2.21 -0.34,-1.01 -0.82,-2.7 -1.3,-3.55 -1.42,-2.57 -4.2,-2.17 -7.67,-2.17 -7.18,0 -7.05,0 -9,5.58 -0.19,0.52 -0.36,1.45 -0.71,1.78 -0.62,0.6 -0.73,0.16 -1.29,1.2 -0.64,1.16 -0.38,3.03 -0.38,4.49 0,6.32 -0.64,7.07 2.86,6.85 1.81,-0.12 1.32,-1.92 1.42,-3.54l-0 0 0 0 -0 0.01 0 0 -0.01 0z"/>
        </g>
      </svg>
    `;
  }
  defaultIcon = 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(svg);

  if (outFence) markerType += '_FORA_CERCA';
  if (outAis) markerType += '_FORA_AIS';

  return {
    defaultIcon,
    widthAux,
    heightAux,
    markerType,
    xOffsetAux,
    yOffsetAux,
  };
}

const handleMoveToLastPositions = (
  lastPLength: number,
  properties: dataPropertiesAux,
  marker: google.maps.marker.AdvancedMarkerElement & { animateTo?: any },
  newPosi: google.maps.LatLng
) => {
  const animationDuration = ANIMATION_TIME / (lastPLength + 1);
  const firstMove = new window.google.maps.LatLng(
    // @ts-ignore
    properties?.lastPositions[0].lat || 0,
    // @ts-ignore
    properties?.lastPositions[0].lng || 0
  );

  if (
    haversineDistance(
      {
        lat: marker?.position?.lat,
        lng: marker?.position?.lng,
      },
      {
        lat: firstMove.lat(),
        lng: firstMove.lng(),
      }
    ) < MAX_DISTANCE_ANIMATION
  ) {
    marker.animateTo('marker', firstMove, animationDuration);
  } else {
    marker.position = firstMove;
  }

  function executeTimeoutsInOrder(index: number) {
    setTimeout(() => {
      if (index < lastPLength) {
        const runner = new window.google.maps.LatLng(
          //@ts-ignore
          properties?.lastPositions[index].lat,
          //@ts-ignore
          properties?.lastPositions[index].lng
        );

        if (
          haversineDistance(
            {
              lat: marker.position?.lat,
              lng: marker.position?.lng,
            },
            {
              lat: runner.lat(),
              lng: runner.lng(),
            }
          ) < MAX_DISTANCE_ANIMATION
        ) {
          marker.animateTo('marker', runner, animationDuration);
        } else {
          marker.position = runner;
        }
        executeTimeoutsInOrder(index + 1);
      } else {
        if (
          haversineDistance(
            {
              lat: marker.position?.lat,
              lng: marker.position?.lng,
            },
            {
              lat: newPosi.lat(),
              lng: newPosi.lng(),
            }
          ) < MAX_DISTANCE_ANIMATION
        ) {
          marker.animateTo('marker', newPosi, animationDuration);
        } else {
          marker.position = newPosi;
        }
      }
    }, animationDuration);
  }

  executeTimeoutsInOrder(1);
};

export function updateMarkersArray(
  map: google.maps.Map | null,
  currentList: MarkersProps[],
  auxCurrentList: DataPosition[],
  newList: (MarkersProps & DataPosition)[],
  showAlerts: {
    outFence: boolean;
    outAis: boolean;
  },
  showMarker: boolean,
  showTooltip: boolean,
  isRadio: boolean,
  isSemCadastro: boolean,
  zIndexRate: {
    min: number;
    max: number;
  }
) {
  const temporaryArray: MarkersProps[] = [];

  let updatedDevices = newList.map(v => v.properties?.idDevice);

  let currentDevices = auxCurrentList.map(v => v.properties.idDevice);

  // remover markers antigos primeiro
  currentList.map(m => {
    if (!currentDevices.includes(m.veiculo.idDevice)) {
      m.marker.map = null;
    } else if (!updatedDevices.includes(m.veiculo.idDevice)) {
      temporaryArray.push(m);
    }
  });

  for (let i = 0; i < newList.length; i += 1) {
    const outFenceAux = Boolean(
      newList[i].properties.foraCerca && showAlerts.outFence
    );

    const outAisAux = Boolean(
      newList[i].properties.foraAIS && showAlerts.outAis
    );

    let isReallyAnHT = isRadio;

    if (
      (isSemCadastro &&
        newList[i].properties?.prefixo &&
        prefixoIsHT(newList[i].properties?.prefixo)) ||
      (!newList[i].properties?.prefixo &&
        prefixoIsHT(newList[i].properties?.placa))
    ) {
      isReallyAnHT = true;
    }

    let {
      defaultIcon,
      widthAux,
      heightAux,
      markerType,
      xOffsetAux,
      yOffsetAux,
    } = loadVehicleIconsMap(
      newList[i].properties.prefixo || newList[i].properties.placa,
      isReallyAnHT
        ? 'RÁDIO'
        : isSemCadastro
        ? 'SEM_CADASTRO'
        : newList[i].properties.statusConexaoViatura &&
          newList[i].properties.statusConexaoViatura !== 'DESCONECTADA'
        ? newList[i].properties.statusConexaoViatura
        : '',
      outFenceAux,
      outAisAux,
      showTooltip,
      false,
      Boolean(newList[i].properties.error)
    );

    if (AIRPLANES_LIST.includes(newList[i].properties.idDevice)) {
      defaultIcon = HelicopteroAzul;
      widthAux = 22;
      heightAux = 25;
    }

    const zIndexAux = Math.min(zIndexRate.min + i, zIndexRate.max);

    let initialPosition = new window.google.maps.LatLng(
      newList[i].properties?.oldLatitude || 0,
      newList[i].properties?.oldLongitude || 0
    );
    let marker: google.maps.marker.AdvancedMarkerElement & {
      animateTo?: any;
    } = {} as google.maps.marker.AdvancedMarkerElement & { animateTo?: any };
    let infowindow: any = null;

    const infoContent = `
        <div style="padding: 0px 10px 10px 0px">
        ${
          isSemCadastro || isReallyAnHT
            ? ''
            : ` <b>Placa:</b> ${newList[i].properties?.placa} <br />
                <b>Prefixo:</b> ${newList[i].properties?.prefixo || ''}<br />`
        }
          <b>Data:</b> ${moment(
            newList[i].properties?.datePosition || ''
          ).format('DD/MM/YYYY HH:mm:ss')} <br />
          <b>Grupo:</b> ${newList[i].properties?.ais} <br />
          <b>Equipamento:</b> ${newList[i].properties?.idDevice}<br />
         <b>${
           isReallyAnHT
             ? '<b>Rádio móvel</b>'
             : newList[i].properties?.conectado
             ? `Conectado CIOPS - ${newList[i].properties.statusConexaoViatura}`
             : 'Desconectado CIOPS'
         }
          </b><br />
          ${newList[i].properties.error ? `<b>Erro no GPS</b>` : ``}
        </div>`;

    if (newList[i].properties.exist) {
      marker = newList[i].marker;
      infowindow = newList[i].infowindow;

      infowindow.setContent(infoContent);

      if (markerType !== newList[i].markerType) {
        const icon = document.createElement('img');
        icon.src = defaultIcon;

        marker.content = icon;
      }
    } else {
      const icon = document.createElement('img');
      icon.src = defaultIcon;

      marker = new window.google.maps.marker.AdvancedMarkerElement({
        position: initialPosition,
        content: icon,
        map: showMarker ? map : null,
        zIndex: zIndexAux,
      });

      infowindow = new window.google.maps.InfoWindow({
        content: infoContent,
        maxWidth: 999,
        disableAutoPan: true,
        zIndex: 99999,
        pixelOffset: new google.maps.Size(xOffsetAux, yOffsetAux),
      });

      marker.addListener('click', () => {
        infowindow.open({
          map,
          anchor: marker,
          shouldFocus: false,
        });
      });
    }

    // animação de mover marcador
    const newPosi = new window.google.maps.LatLng(
      newList[i].properties?.latitude,
      newList[i].properties?.longitude
    );

    if (
      newPosi.lat() !== initialPosition.lat() ||
      newPosi.lng() !== initialPosition.lng()
    ) {
      if (
        (map?.getZoom() || 0) > 14 &&
        haversineDistance(
          {
            lat: newPosi.lat(),
            lng: newPosi.lng(),
          },
          {
            lat: initialPosition.lat(),
            lng: initialPosition.lng(),
          }
        ) < MAX_DISTANCE_ANIMATION
      ) {
        const lastPLength = newList[i].properties.lastPositions?.length || 0;

        if (lastPLength > 0) {
          handleMoveToLastPositions(
            lastPLength,
            newList[i].properties,
            marker,
            newPosi
          );
        } else {
          marker.animateTo('marker', newPosi, ANIMATION_TIME);
        }
      } else {
        marker.position = newPosi;
      }
    }

    const newObj = {
      veiculo: newList[i].properties,
      marker: marker,
      infowindow,
      markerType,
    };

    temporaryArray.push(newObj);
  }

  return temporaryArray;
}

export function updateHistoryMarkersArray(
  map: google.maps.Map | null,
  currentList: MarkersProps[],
  auxCurrentList: dataPropertiesAux[],
  newList: dataPropertiesAux[],
  showMarker: boolean,
  showTooltip: boolean,
  isRadio: boolean,
  isSemCadastro: boolean,
  zIndexRate: {
    min: number;
    max: number;
  },
  loadFullHistory: any,
  controller: AbortController,
  documentListeners: MutableRefObject<{ event: string; handler: any }[]>,
  dataType: MutableRefObject<string>,
  vehiclesWithFullHistoryLoaded: MutableRefObject<string[]>,
  handleRenderMarkers: any
) {
  const temporaryArray: MarkersProps[] = [];

  let updatedDevices = newList.map(v => v?.idDevice);

  let currentDevices = auxCurrentList.map(v => v.idDevice);

  // remover markers antigos primeiro
  currentList.map(m => {
    if (!currentDevices.includes(m.veiculo.idDevice)) {
      m.marker.map = null;
    } else if (!updatedDevices.includes(m.veiculo.idDevice)) {
      temporaryArray.push(m);
    }
  });

  for (let i = 0; i < newList.length; i += 1) {
    const zIndexAux = Math.min(zIndexRate.min + i, zIndexRate.max);

    let isReallyAnHT = isRadio;

    if (
      (isSemCadastro &&
        newList[i].prefixo &&
        prefixoIsHT(newList[i].prefixo)) ||
      (!newList[i].prefixo && prefixoIsHT(newList[i].placa))
    ) {
      isReallyAnHT = true;
    }

    let {
      defaultIcon,
      widthAux,
      heightAux,
      markerType,
      xOffsetAux,
      yOffsetAux,
    } = loadVehicleIconsMap(
      newList[i].prefixo || newList[i].placa,
      isReallyAnHT
        ? 'RÁDIO'
        : isSemCadastro
        ? 'SEM_CADASTRO'
        : newList[i].statusConexaoViatura &&
          newList[i].statusConexaoViatura !== 'DESCONECTADA'
        ? newList[i].statusConexaoViatura
        : '',
      false,
      false,
      showTooltip,
      false,
      Boolean(newList[i].error)
    );

    let initialPosition = new google.maps.LatLng(
      newList[i].latitude,
      newList[i].longitude
    );

    let marker: google.maps.marker.AdvancedMarkerElement | null = null;
    let infowindow: google.maps.InfoWindow | null = null;

    let hType = isReallyAnHT
      ? 'radio'
      : isSemCadastro
      ? 'sem_cadastro'
      : newList[i].statusConexaoViatura
      ? 'conectada'
      : 'desconectada';

    const eventListennerFunction = (event: Event) => {
      if (
        event.target &&
        // @ts-ignore
        event.target.matches(`#${hType}${i}`)
      ) {
        if (
          !vehiclesWithFullHistoryLoaded.current?.includes(newList[i].placa)
        ) {
          vehiclesWithFullHistoryLoaded.current?.push(newList[i].placa);

          loadFullHistory(newList[i]);
        } else {
          vehiclesWithFullHistoryLoaded.current = vehiclesWithFullHistoryLoaded.current?.filter(
            p => p !== newList[i].placa
          );
          controller.abort();

          documentListeners.current.map(e =>
            document.removeEventListener(e.event, e.handler)
          );

          controller = new AbortController();

          handleRenderMarkers(controller);
        }
      }
    };

    if (dataType.current !== 'historicoIndividual') {
      document.addEventListener('click', eventListennerFunction, {
        signal: controller.signal,
      });

      documentListeners.current.push({
        event: 'click',
        handler: eventListennerFunction,
      });
    }

    const infoContent = `
    <div style="padding: 0px 10px 10px 0px">
      ${
        isSemCadastro || isReallyAnHT
          ? ''
          : `<b>Placa:</b> ${newList[i]?.placa} <br />
          <b>Prefixo:</b> ${newList[i]?.prefixo || ''}<br />`
      }
      <b>Data:</b> ${moment(newList[i]?.datePosition || '').format(
        'DD/MM/YYYY HH:mm:ss'
      )} <br />
      <b>Grupo:</b> ${newList[i].grupo} <br />
      <b>Equipamento:</b> ${newList[i]?.idDevice}<br />
      <b>${newList[i]?.statusConexaoViatura}</b>
      ${
        dataType.current !== 'historicoIndividual'
          ? `<br/>
          <a id="${hType}${i}" href="#">
            Ver caminho completo
          </a>`
          : ''
      }
    </div>`;

    const icon = document.createElement('img');
    icon.src = defaultIcon;

    marker = new window.google.maps.marker.AdvancedMarkerElement({
      position: initialPosition,
      content: icon,
      map: showMarker ? map : null,
      zIndex: zIndexAux,
    });

    infowindow = new google.maps.InfoWindow({
      content: infoContent,
      maxWidth: 400,
      zIndex: 99999,
      pixelOffset: new google.maps.Size(xOffsetAux, yOffsetAux),
    });

    marker.addListener('click', () => {
      infowindow?.open({
        map,
        anchor: marker,
        shouldFocus: false,
      });
    });

    const newObj = {
      veiculo: newList[i],
      marker: marker,
      infowindow,
      markerType,
    };

    temporaryArray.push(newObj);
  }

  return temporaryArray;
}

