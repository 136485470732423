import { MonitoringContext } from 'context/monitoring';
import { useContextSelector } from 'use-context-selector';

export function useFiltroOutrosInfo() {
  const showConectadas = useContextSelector(
    MonitoringContext,
    context => context.showConectadas
  );

  const showDesconectadas = useContextSelector(
    MonitoringContext,
    context => context.showDesconectadas
  );

  const showRadio = useContextSelector(
    MonitoringContext,
    context => context.showRadio
  );

  const showSemCadastro = useContextSelector(
    MonitoringContext,
    context => context.showSemCadastro
  );

  const showOccurrences = useContextSelector(
    MonitoringContext,
    context => context.showOccurrences
  );

  const setShowOnlyVehiclesFences = useContextSelector(
    MonitoringContext,
    context => context.setShowOnlyVehiclesFences
  );

  const handleRenderTooltipsOccurrence = useContextSelector(
    MonitoringContext,
    context => context.handleRenderTooltipsOccurrence
  );

  const handleRenderTooltipsSemCadastro = useContextSelector(
    MonitoringContext,
    context => context.handleRenderTooltipsSemCadastro
  );

  const handleRenderTooltipsRadio = useContextSelector(
    MonitoringContext,
    context => context.handleRenderTooltipsRadio
  );

  const handleRenderTooltipsDesconectadas = useContextSelector(
    MonitoringContext,
    context => context.handleRenderTooltipsDesconectadas
  );

  const handleRenderTooltipsConectadas = useContextSelector(
    MonitoringContext,
    context => context.handleRenderTooltipsConectadas
  );

  const handleRenderMarkersOccurrence = useContextSelector(
    MonitoringContext,
    context => context.handleRenderMarkersOccurrence
  );

  const handleRenderMarkersRadio = useContextSelector(
    MonitoringContext,
    context => context.handleRenderMarkersRadio
  );

  const handleRenderMarkersSemCadastro = useContextSelector(
    MonitoringContext,
    context => context.handleRenderMarkersSemCadastro
  );

  const handleRenderMarkersDesconectadas = useContextSelector(
    MonitoringContext,
    context => context.handleRenderMarkersDesconectadas
  );

  const handleRenderMarkersConectadas = useContextSelector(
    MonitoringContext,
    context => context.handleRenderMarkersConectadas
  );

  const setDaysOfLastPosition = useContextSelector(
    MonitoringContext,
    context => context.setDaysOfLastPosition
  );

  return {
    showConectadas,
    showDesconectadas,
    showRadio,
    showSemCadastro,
    showOccurrences,
    setDaysOfLastPosition,
    handleRenderMarkersConectadas,
    handleRenderMarkersDesconectadas,
    handleRenderMarkersRadio,
    handleRenderMarkersSemCadastro,
    handleRenderMarkersOccurrence,
    handleRenderTooltipsConectadas,
    handleRenderTooltipsDesconectadas,
    handleRenderTooltipsRadio,
    handleRenderTooltipsSemCadastro,
    handleRenderTooltipsOccurrence,
    setShowOnlyVehiclesFences,
  };
}

