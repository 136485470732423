import { AuthContext } from 'context/auth';
import { globalContext } from 'context/global';
import { useContextSelector } from 'use-context-selector';

export function useGlobalVehicles() {
  const vehicles = useContextSelector(
    globalContext,
    context => context.vehicles
  );

  return {
    vehicles,
  };
}

