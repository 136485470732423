import React from 'react';
import { Button, Popconfirm, Tag } from 'antd';

import { DeleteOutlined } from '@ant-design/icons';
import { GroupAcoes } from '../../../components/TableList';

import { MODULE_ROUTE } from './List';

export const templateColumns = [
  {
    title: 'Placa da Vtr',
    dataIndex: 'placa',
    key: 'placa',
    align: 'center',
    responsive: ['sm'],
  },
  {
    title: 'Prefixo',
    dataIndex: 'prefixo',
    key: 'prefixos',
    align: 'center',
    // render: prefixo => {
    //   console.log('prefixos', prefixo);
    //   const pref = prefixo.filter(p => !p.dataDesativacao);

    //   if (!pref) return <span />;
    //   if (pref.length === 0 || !pref[0].prefixo) return <span />;

    //   return pref[0].prefixo.codigo;
    // },
  },
  {
    title: 'Tipo Veiculo',
    dataIndex: 'tipo',
    key: 'tipo',
    align: 'center',
    responsive: ['sm'],
    render: tipoVeiculo => {
      if (!tipoVeiculo) return '';
      return tipoVeiculo;
    },
  },
  {
    title: 'Agencia',
    dataIndex: 'agencia',
    key: 'agencia',
    align: 'center',
    responsive: ['sm'],
    render: agencia => {
      if (!agencia) return '';
      return agencia.nome;
    },
  },
  {
    title: 'AIS',
    dataIndex: 'ais',
    key: 'ais',
    align: 'center',
    responsive: ['sm'],
    render: ais => {
      if (!ais) return '';
      return ais.nome;
    },
  },
  // {
  //   title: 'Descrição',
  //   dataIndex: 'description',
  //   key: 'description',
  //   align: 'center',
  //   responsive: ['lg'],
  // },
  // {
  //   title: 'Status',
  //   key: 'isAtivo',
  //   dataIndex: 'ativo',
  //   align: 'center',
  //   responsive: ['sm'],
  //   render: isAtivo => (
  //     <span>
  //       <Tag color={isAtivo ? 'green' : 'red'}>
  //         {isAtivo ? 'Ativo' : 'Inativo'}
  //       </Tag>
  //     </span>
  //   ),
  // },
  // {
  //   title: 'Ação',
  //   key: 'action',
  //   align: 'center',
  //   render: (text, record) => (
  //     <Popconfirm
  //       title="Deseja excluir o registro?"
  //       onConfirm={() => onDelete(record)}
  //     >
  //       <Button type="danger" icon={<DeleteOutlined />} />
  //     </Popconfirm>
  //     // <GroupAcoes
  //     //   moduloSistema={MODULE_ROUTE}
  //     //   record={record}
  //     //   onDelete={onDelete}
  //     // />
  //   ),
  // },
];
