import styled from 'styled-components';

export const Container = styled.div`
  .sliderAntd {
    left: 30%;
    bottom: 12.5%;
    position: absolute;
    z-index: 999;
    width: 30%;

    color: green;
  }

  .ant-slider-rail {
    height: 10px !important;
  }

  .ant-slider-track {
    height: 10px !important;
    background-color: #fc6836 !important;
  }

  .ant-slider-handle {
    height: 20px !important;
    width: 20px !important;
    color: green !important;
    border: solid 3px green;
  }

  .ant-slider-handle.ant-tooltip-open,
  .ant-slider-handle:focus,
  .ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
    border: solid 3px green;
  }
`;

