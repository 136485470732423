// eslint-disable-next-line no-unused-vars
import { Tag } from 'antd';

import moment from 'moment';

import React from 'react';

import { ActionTag } from '~/components/ActionTag';
import { GroupAcoes } from '~/components/TableList';
import { MODULE_ROUTE } from './List';

export const parseJsonByEquipamentos = (equipamentosVinc, listEquipamento) => {
  const retorno = [];

  equipamentosVinc.forEach(eq => {
    const equipamentoFiltered = listEquipamento.filter(
      e => e.id === eq.equipamento.id
    );

    if (equipamentoFiltered && equipamentoFiltered.length > 0) {
      const localDateAtiv = moment(eq.dataAtivacao).local();

      const localDateDes = eq.dataDesativacao
        ? moment(eq.dataDesativacao).local()
        : null;

      retorno.push({
        key: eq.equipamento.id,

        id: eq.id,

        ativacao: localDateAtiv.format('DD/MM/YYYY HH:mm:ss'),

        desativacao: localDateDes
          ? localDateDes.format('DD/MM/YYYY HH:mm:ss')
          : '',

        momentAtivacao: moment(eq.dataAtivacao),

        momentDesativacao: eq.dataDesativacao
          ? moment(eq.dataDesativacao)
          : null,

        tipoEquipamento: equipamentoFiltered[0].tipoEquipamento.nome,

        serie: equipamentoFiltered[0].identificacao,

        empresa: equipamentoFiltered[0].tipoTecnologia
          ? equipamentoFiltered[0].tipoTecnologia.empresa
          : null,
      });
    }
  });

  return retorno;
};

export const formatDatasourceEquipamento = datasource => {
  if (datasource && datasource.length > 0) {
    return datasource.map(d => {
      let dataDesativacao;

      if (d.momentDesativacao) {
        dataDesativacao = d.momentDesativacao.unix() * 1000;
      }

      return {
        id: d.id,

        equipamento: {
          id: d.key,
        },

        dataAtivacao: d.momentAtivacao.unix() * 1000,

        dataDesativacao,
      };
    });
  }

  return [];
};

export const formatDatasourceHistorico = datasource => {
  if (datasource && datasource.length > 0) {
    return datasource.map(d => {
      let dataDesativacao = null;

      if (d.dataDesativacao) {
        dataDesativacao = d.dataDesativacao.unix() * 1000;
      }

      return {
        id: d.id,
        prefixo: d.prefixo,
        dataAtivacao: d.dataAtivacao.unix() * 1000,
        dataDesativacao,
        codigo: d.codigo,
      };
    });
  }

  return [];
};

export const rulePrefixoEdit = ({ prefixo, prefixos }) => {
  if (prefixos && prefixos.length > 0) {
    const i = prefixos.findIndex(p => !p.dataDesativacao);

    const isEqual = prefixos[i].prefixo.id === prefixo;

    if (!isEqual) {
      prefixos[i].dataDesativacao = new Date().getTime();

      prefixos.push({
        prefixo: { id: prefixo },

        dataAtivacao: new Date().getTime(),
      });
    }

    return prefixos;
  }

  return [{ prefixo: { id: prefixo }, dataAtivacao: new Date().getTime() }];
};

export const genericInsertAtivacao = ({ name, value }) => {
  return [{ [`${name}`]: { id: value }, dataAtivacao: new Date().getTime() }];
};

export const ruleAisEdit = ({ ais, aisUpdated }) => {
  if (ais) {
    if (aisUpdated && aisUpdated.length > 0) {
      const i = aisUpdated.findIndex(p => !p.dataDesativacao);

      const isEqual = aisUpdated[i].ais.id === ais;

      if (!isEqual) {
        aisUpdated[i].dataDesativacao = new Date().getTime();

        aisUpdated.push({
          ais: { id: ais },

          dataAtivacao: new Date().getTime(),
        });
      }

      return aisUpdated;
    }

    return [{ ais: { id: ais }, dataAtivacao: new Date().getTime() }];
  }

  return [];
};

export const ruleLotacaoEdit = ({ lotacao, lotacaoes }) => {
  if (lotacao) {
    if (lotacaoes && lotacaoes.length > 0) {
      const i = lotacaoes.findIndex(p => !p.dataDesativacao);

      const isEqual = lotacaoes[i].lotacao.id === lotacao;

      if (!isEqual) {
        lotacaoes[i].dataDesativacao = new Date().getTime();

        lotacaoes.push({
          lotacao: { id: lotacao },

          dataAtivacao: new Date().getTime(),
        });
      }

      return lotacaoes;
    }

    return [{ lotacao: { id: lotacao }, dataAtivacao: new Date().getTime() }];
  }

  return [];
};

export const columnsEquipamento = ({
  onDelete,
  setRegistroSelected,
  setVisibleModal,
}) => {
  return [
    {
      title: 'Equip.',
      dataIndex: 'tipoEquipamento',
      key: 'tipoEquipamento',
    },
    {
      title: 'S',
      dataIndex: 'serie',
      key: 'serie',
    },
    {
      title: 'Empresa',
      dataIndex: 'empresa',
      key: 'empresa',
    },

    {
      title: 'Ativ.',

      dataIndex: 'ativacao',

      key: 'ativacao',
    },

    {
      title: 'Des.',

      dataIndex: 'desativacao',

      key: 'desativacao',
    },

    {
      title: 'Ações',
      key: 'action',
      align: 'center',
      render: (text, record) => (
        <GroupAcoes
          moduloSistema={MODULE_ROUTE}
          record={record}
          onDelete={onDelete}
          showModal
          setRegistroSelected={setRegistroSelected}
          setVisibleModal={setVisibleModal}
        />
      ),
    },
  ];
};

export const columnsPrefixo = ({
  // eslint-disable-next-line no-unused-vars
  onDelete,
  setRegistroSelected,
  setVisibleModal,
}) => {
  return [
    {
      title: 'Codigo',
      dataIndex: 'prefixo',
      key: 'prefixo',
      render: text =>
        // eslint-disable-next-line react/destructuring-assignment, jsx-a11y/anchor-is-valid
        text.codigo ? <a style={{ color: 'black' }}>{text.codigo}</a> : <></>,
    },
    {
      title: 'Ativ.',
      dataIndex: 'ativacao',
      key: 'ativacao',
      defaultSortOrder: 'descend',
      sorter: (a, b) =>
        moment(a.dataAtivacao)
          .local()
          .isAfter(moment(b.dataAtivacao).local()),
    },
    {
      title: 'Des.',
      dataIndex: 'desativacao',
      key: 'desativacao',
    },
    {
      title: 'Status',
      dataIndex: 'statusPrefixo',
      key: 'statusPrefixo',
      width: 110,
      render: text => (
        <span
          style={{
            display: 'flex',

            width: '100%',
          }}
        >
          <ActionTag text={text} />
        </span>
      ),
    },
    // {
    //   title: 'Ações',
    //   key: 'action',
    //   align: 'center',
    //   render: (text, record) => (
    //     <GroupAcoes
    //       moduloSistema={MODULE_ROUTE}
    //       record={record}
    //       onDelete={onDelete}
    //       showModal
    //       setRegistroSelected={setRegistroSelected}
    //       setVisibleModal={setVisibleModal}
    //     />
    //   ),
    // },
  ];
};
