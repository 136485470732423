import React from 'react';
import { Tag } from 'antd';

import { GroupAcoes } from '../../../components/TableList';

import { MODULE_ROUTE } from './List';

export const templateColumns = ({ onDelete }) => {
  return [
    {
      title: 'Codigo',
      dataIndex: 'codigo',
      key: 'codigo',
      align: 'center',
    },
    {
      title: 'Status',
      key: 'isAtivo',
      dataIndex: 'ativo',
      align: 'center',
      render: isAtivo => (
        <span>
          <Tag color={isAtivo ? 'green' : 'red'}>
            {isAtivo ? 'Ativo' : 'Inativo'}
          </Tag>
        </span>
      ),
    },
    {
      title: 'Ações',
      key: 'action',
      align: 'center',
      render: (text, record) => (
        <GroupAcoes
          moduloSistema={MODULE_ROUTE}
          record={record}
          onDelete={onDelete}
        />
      ),
    },
  ];
};
