import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  ReactNode,
} from 'react';

import { createContext } from 'use-context-selector';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import { getVeiculosServices } from 'services/modulos/getVeiculos';
import * as ACTIONS from 'store/modules/monitoring/actions';
import 'dotenv/config';
import moment from 'moment';
import { compareAlertObjects, prefixoIsHT } from 'utils/functionsUtils';
import { ServiceOcorrenciaGEO } from 'services/modulos/serviceOcorrenciaGEO';
import { socketTVP } from 'sockets/socketTVP';
import { socketTO } from 'sockets/socketTO';
import { socketAlert } from 'sockets/socketAlert';
import {
  DataPosition,
  dataPropertiesAux,
  MarkersOccurrenceProps,
  MarkersProps,
  OccurrenceSocket,
  PositionVehicle,
  VehicleContextProps,
} from 'interfaces/vehicle';
import { GroupsProps, AgenciesProps } from 'interfaces/FiltersMapContext';
import { ServiceReportAlert } from 'services/modulos/serviceReportAlert';
import { Alert } from 'interfaces/alerts';
import { useAuthData } from 'hooks/auth/useAuthData';
import { useFiltersMapLoadingAis } from 'hooks/filtersMap/useFiltersMapLoadingAis';
import { useFiltersMapGroups } from 'hooks/filtersMap/useFiltersMapGroups';
import { loadVehicleIconsMap } from 'utils/monitoring_functions';
import { useGlobalFences } from 'hooks/global/useGlobalFences';
import { useLocation } from 'react-router-dom';

export const MonitoringContext = createContext({} as VehicleContextProps);

export const MonitoringProvider = ({ children }: { children: ReactNode }) => {
  const dispatch = useDispatch();
  const [map, setMap] = useState<google.maps.Map>();
  const [statusSocketTVP, setStatusSocketTVP] = useState(false);

  const { data: dataAuth } = useAuthData();
  const [showAlerts, setShowAlerts] = useState({
    outAis: !Boolean(dataAuth.hasMonitoramentoCIOPS),
    outFence: true,
  });
  const updateShowAlerts = useCallback((newProperties: any) => {
    setShowAlerts(prev => ({ ...prev, ...newProperties }));
  }, []);

  const { groupsFilters } = useFiltersMapGroups();
  const { setLoadingAis } = useFiltersMapLoadingAis();
  const { agencies, groups } = groupsFilters;
  const [daysOfLastPosition, setDaysOfLastPosition] = useState(
    Boolean(dataAuth.hasMonitoramentoCIOPS) ? 1 : 10
  );
  const [loadingVeiculo, setLoadingVeiculo] = useState(false);

  const [data, setData] = useState<DataPosition[]>([]);
  const [dataOccurrences, setDataOccurrences] = useState<OccurrenceSocket[]>(
    []
  );

  const { fences } = useGlobalFences();

  const markersConectados = useRef<MarkersProps[]>([]);
  const markersDesconectados = useRef<MarkersProps[]>([]);
  const markersRadio = useRef<MarkersProps[]>([]);
  const markersSemCadastro = useRef<MarkersProps[]>([]);
  const mapOccurrences = useRef<MarkersOccurrenceProps[]>([]);

  const [listenersConectados, setListenersConectados] = useState([]);

  const showConectadas = useRef(true);
  const showDesconectadas = useRef(
    !(Boolean(dataAuth.hasMonitoramentoCIOPS) || Boolean(dataAuth.isSecretario))
  );
  const showRadio = useRef(true);
  const showOccurrences = useRef(!Boolean(dataAuth.hasMonitoramentoCIOPS));
  const showSemCadastro = useRef(true);

  const showTooltipConectadas = useRef(Boolean(dataAuth.hasMonitoramentoCIOPS));
  const showTooltipDesconectadas = useRef(false);
  const showTooltipRadio = useRef(Boolean(dataAuth.hasMonitoramentoCIOPS));
  const showTooltipOccurrences = useRef(false);
  const showTooltipSemCadastro = useRef(false);

  // const hasSemCadastro = useRef(
  //   Boolean(dataAuth.roles?.includes('ROLE_ROTAS_SEM_CADASTRO'))
  // );

  const [showOnlyVehiclesFences, setShowOnlyVehiclesFences] = useState(
    Boolean(dataAuth.hasMonitoramentoCIOPS)
  );

  const vehiclesOfSelectedFences = useRef<string[]>([]);
  const monitoringVehiclesWithoutRegister = useRef<string[]>([]);

  const fencesSelected = useRef<string[]>([]);
  const [loadingFences, setLoadingFences] = useState(false);

  const [panelAlertsFiltered, setPanelAlertsFiltered] = useState<Alert[]>([]);

  const monitoringDown = useRef(false);
  const [activeAlert, setActiveAlert] = useState(false);
  const firstLoadOfActiveAlert = useRef(false);
  const firstLoadAlertsForSecretary = useRef(false);
  const lastSocketAlerts = useRef<Alert[]>([]);
  const allAlerts = useRef<Alert[]>([]);
  const alertsHistory = useRef<Alert[]>([]);

  const socketTVPRegistered = useRef(false);
  // const socketTARegistered = useRef(false);
  const socketTORegistered = useRef(false);

  const location = useLocation();

  const isMonitoring = location.pathname === '/mapa/monitoramento';

  const count_minimized = useRef(0);

  const filterAlert = useRef({
    foraCerca: true,
    foraAIS: !Boolean(dataAuth.hasMonitoramentoCIOPS),
    PARADA_SUPERIOR_15_MIN: true,
  });

  const aisCompare = useMemo(
    () =>
      (dataAuth?.roles || [])
        .map((r: string) => r.replace(/\D/g, ''))
        .filter((r: string) => r !== ''),
    [dataAuth?.roles]
  );

  const hasOccurrenceRole = () => {
    const role = (dataAuth?.roles || []).find(
      r => r === 'ROLE_ROTAS_OCORRENCIA'
    );
    return role;
  };

  const addPositionToList = useCallback((dataSocket: dataPropertiesAux[]) => {
    try {
      const dataObj: any = {};

      dataSocket.map(d => {
        dataObj[d.idDevice] = {
          ...d,
        };
      });

      setData((prev: DataPosition[]) => {
        prev = prev.map(p => {
          if (dataObj[p.properties.idDevice]) {
            dataObj[p.properties.idDevice].auxExist = true;
            const lat = parseFloat(dataObj[p.properties.idDevice].latitude);
            const lng = parseFloat(dataObj[p.properties.idDevice].longitude);

            const isConnected = Boolean(
              dataObj[p.properties.idDevice].statusConexaoViatura &&
                dataObj[p.properties.idDevice].statusConexaoViatura !==
                  'DESCONECTADA'
            );

            const newPosi = {
              ...p,
              properties: {
                ...p.properties,
                latLng: {
                  lat,
                  lng,
                },
                ais: dataObj[p.properties.idDevice].grupo,
                latitude: dataObj[p.properties.idDevice].latitude,
                longitude: dataObj[p.properties.idDevice].longitude,
                speed: dataObj[p.properties.idDevice].speed,
                datePosition: dataObj[p.properties.idDevice].datePosition,
                ultimaAtualizacao:
                  dataObj[p.properties.idDevice].dateInputServer,
                lastPositions: dataObj[p.properties.idDevice].lastPositions,
                conectado: isConnected,
                statusConexaoViatura:
                  dataObj[p.properties.idDevice].statusConexaoViatura,
                error: false,
              },
              geometry: {
                type: 'Point',
                coordinates: [lng, lat],
              },
            };

            return newPosi;
          }
          return p;
        });

        Object.keys(dataObj).map(k => {
          if (!dataObj[k].auxExist) {
            const lat = parseFloat(dataObj[k].latitude);
            const lng = parseFloat(dataObj[k].longitude);

            const isConnected = Boolean(
              dataObj[k].statusConexaoViatura &&
                dataObj[k].statusConexaoViatura !== 'DESCONECTADA'
            );

            const aisInformada = dataObj[k].grupo || '';
            let numAis = null;
            let grupo = null;
            let ais = dataObj[k].grupo;

            if (aisInformada) {
              if (aisInformada.includes('AIS')) {
                numAis = parseInt(aisInformada.replace(/\D/g, ''), 10);
              } else {
                grupo = aisInformada;
              }
            }

            const newPosi: DataPosition = {
              properties: {
                grupo,
                numAis,
                ais,
                accountNumber: dataObj[k].accountNumber,
                agencia: dataObj[k].agencia,
                dateInputServer: dataObj[k].dateInputServer,
                idDevice: dataObj[k].idDevice,
                placa: dataObj[k].placa,
                prefixo: dataObj[k].prefixo,
                latLng: {
                  lat,
                  lng,
                },
                latitude: dataObj[k].latitude,
                longitude: dataObj[k].longitude,
                speed: dataObj[k].speed,
                datePosition: dataObj[k].datePosition,
                ultimaAtualizacao: dataObj[k].dateInputServer,
                lastPositions: dataObj[k].lastPositions,
                conectado: isConnected,
                statusConexaoViatura: dataObj[k].statusConexaoViatura,
              },
              geometry: {
                type: 'Point',
                coordinates: [lng, lat],
              },
            };

            prev.push(newPosi);
          }
        });

        return prev;
      });
    } catch (err) {
      // continue
    }
  }, []);

  const onNewRegisterOcc = useCallback(
    (dataSocket: OccurrenceSocket, type: string) => {
      try {
        setDataOccurrences((prev: OccurrenceSocket[]) => {
          let exist = false;
          prev = prev.map(occ => {
            if (occ.ocorrencia === dataSocket.ocorrencia) {
              exist = true;
              occ = dataSocket;
            }

            return occ;
          });

          if (!exist) prev.push(dataSocket);

          return prev;
        });
      } catch (err) {
        // continue
      }
    },
    []
  );

  const filterAlertArray = useCallback(
    (
      value?: any,
      type?: 'foraCerca' | 'foraAIS' | 'PARADA_SUPERIOR_15_MIN'
    ) => {
      if (type === 'foraCerca') {
        updateShowAlerts({ outFence: Boolean(value) });
      } else if (type === 'foraAIS') {
        updateShowAlerts({ outAis: Boolean(value) });
      }

      if (type) {
        filterAlert.current[type] = value;
      }

      const falseTypes = ['ENTROU_NA_CERCA'];
      let auxPanelAlert = [];

      Object.keys(filterAlert.current).map(t => {
        // @ts-ignore
        if (!filterAlert.current[t]) {
          falseTypes.push(t);
        }
        return t;
      });

      lastSocketAlerts.current.map((lastSocketAlert: Alert) => {
        if (lastSocketAlert.tipoAlerta === 'ENTROU_NA_CERCA') {
          allAlerts.current = allAlerts.current.filter(
            a =>
              !(
                a.idDevice === lastSocketAlert.idDevice &&
                a.tipoAlerta === 'SAIU_DA_CERCA'
              )
          );
        } else {
          // condições para antigo alerta (a) ficar:
          // 1 - se ele for de outro dispositivo,;
          // 2 - se ele for do mesmo, mas alertas diferentes;
          // 3 - se for do mesmo dispositivo, mas o antigo alerta é de saiu_cerca e o novo é saiu_ais, nesse caso, prevalece o alerta de saiu_cerca
          allAlerts.current = allAlerts.current.filter(
            a =>
              a.idDevice !== lastSocketAlert.idDevice ||
              (a.idDevice === lastSocketAlert.idDevice &&
                a.tipoAlerta !== lastSocketAlert.tipoAlerta)
          );

          alertsHistory.current = alertsHistory.current.filter(
            a =>
              a.idDevice !== lastSocketAlert.idDevice ||
              (a.idDevice === lastSocketAlert.idDevice &&
                a.tipoAlerta !== lastSocketAlert.tipoAlerta)
          );

          allAlerts.current.unshift(lastSocketAlert);
          alertsHistory.current.push(lastSocketAlert);
        }

        return lastSocketAlert;
      });

      if (showOnlyVehiclesFences) {
        allAlerts.current = allAlerts.current.filter(a =>
          vehiclesOfSelectedFences.current.includes(a.placa)
        );
      }

      const aisSelecteds: string[] = [];
      groupsFilters.groups.map((ais: any) => {
        if (ais.checked) {
          const aisFormated = ais.nome.split(/\s+/).join('');
          aisSelecteds.push(aisFormated);
        }
        return ais;
      });

      auxPanelAlert = allAlerts.current.filter(a => {
        const isOutAIS = a.foraAIS ? 'foraAIS' : '';
        const isOutFence = a.foraCerca ? 'foraCerca' : '';
        const aisOfAlert: string | null =
          a.nomeCerca &&
          a.nomeCerca.indexOf('AIS') === 0 &&
          a.nomeCerca.length === 5
            ? a.nomeCerca
            : null;
        if (
          falseTypes.includes(a.tipoAlerta) ||
          falseTypes.includes(isOutAIS) ||
          falseTypes.includes(isOutFence) ||
          (!fencesSelected.current.includes(a.idCerca) && a.foraCerca) ||
          (aisOfAlert && !aisSelecteds.includes(aisOfAlert))
        ) {
          return false;
        }
        return true;
      });

      setPanelAlertsFiltered(auxPanelAlert);
    },
    [
      JSON.stringify(lastSocketAlerts.current),
      fencesSelected.current,
      groupsFilters,
      showOnlyVehiclesFences,
    ]
  );

  const addNewAlertToList = useCallback(
    (arraySocket: Alert[], positionVehicles: DataPosition[]) => {
      try {
        let auxData = positionVehicles;
        let auxNewAlert: Alert[] = [];
        arraySocket.map(dataSocket => {
          let isOutAis: boolean = false;
          let isOutFence: boolean = false;
          const isOutFenceAlert: boolean =
            dataSocket.tipoAlerta === 'SAIU_DA_CERCA';
          const isInFenceAlert: boolean =
            dataSocket.tipoAlerta === 'ENTROU_NA_CERCA';
          const isParadaSup15min =
            dataSocket.tipoAlerta === 'PARADA_SUPERIOR_15_MIN';
          const isBaseFixa = dataSocket.prefixo.includes('BASEFIXA');

          if (isOutFenceAlert || isInFenceAlert || isParadaSup15min) {
            if (
              dataSocket.nomeCerca?.indexOf('AIS') === 0 &&
              dataSocket.nomeCerca?.length === 5
            ) {
              isOutAis = isOutFenceAlert;
            } else {
              isOutFence = isOutFenceAlert;
            }

            // caso não esteja monitorando a cerca
            if (isOutFenceAlert && !isOutAis) {
              if (!fencesSelected.current.includes(dataSocket.idCerca)) return;
            }

            if (auxData.length > 0) {
              auxData = auxData.map(d => {
                if (
                  d.properties.idDevice === dataSocket.idDevice &&
                  !monitoringDown.current
                ) {
                  const newPosition = {
                    ...d,
                    properties: {
                      ...d.properties,
                      foraAIS: isOutAis,
                      foraCerca: isOutFence,
                    },
                  };
                  return newPosition;
                }
                return d;
              });
            } else {
              setData(prev => {
                prev = prev.map(d => {
                  if (
                    d.properties.idDevice === dataSocket.idDevice &&
                    !monitoringDown.current
                  ) {
                    const newPosition = {
                      ...d,
                      properties: {
                        ...d.properties,
                        foraAIS: isOutAis,
                        foraCerca: isOutFence,
                      },
                    };
                    return newPosition;
                  }
                  return d;
                });
                return prev;
              });
            }
            if (!monitoringDown.current && !isBaseFixa) {
              const newAlert: Alert = {
                ...dataSocket,
                foraAIS: isOutAis,
                foraCerca: isOutFence,
              };
              auxNewAlert.push(newAlert);
            }
          }
        });

        if (auxData && auxData.length > 0) {
          setData(auxData);
        }

        lastSocketAlerts.current = auxNewAlert;
        filterAlertArray();
      } catch (err) {
        console.error(err);
      } finally {
        setLoadingVeiculo(false);
      }
    },
    [monitoringDown.current, fencesSelected.current, filterAlertArray]
  );

  const onNewAlert = useCallback(
    (
      arraySocket: Alert[],
      positionVehicles: DataPosition[],
      source: string
    ) => {
      try {
        let exist = false;
        if (lastSocketAlerts.current.length && source === 'socket') {
          exist = lastSocketAlerts.current.some(lastSocketAlert => {
            return compareAlertObjects(lastSocketAlert, arraySocket[0]);
          });
          if (!exist) {
            addNewAlertToList(arraySocket, positionVehicles);
          } else {
            setLoadingVeiculo(false);
          }
        } else if (arraySocket.length > 0) {
          addNewAlertToList(arraySocket, positionVehicles);
        } else {
          if (positionVehicles.length > 0) {
            setData(prev => {
              let auxBeforePosition = {};
              return positionVehicles.map(newPosition => {
                let exist = false;
                prev.map(oldPosition => {
                  if (
                    newPosition.properties.idDevice ===
                    oldPosition.properties.idDevice
                  ) {
                    exist = true;
                    auxBeforePosition = oldPosition;
                  }
                });
                if (exist) {
                  return { ...auxBeforePosition, ...newPosition };
                }
                return newPosition;
              });
            });
          }
          setLoadingVeiculo(false);
        }
      } catch (err) {
        console.error('error on new alert:', err);
        setLoadingVeiculo(false);
      }
    },
    [fencesSelected.current, addNewAlertToList]
  );

  const loadLastAlerts = useCallback(
    async (plates: string[], positionVehicles: DataPosition[]) => {
      try {
        const lastAlertsResponse = await ServiceReportAlert.getLastByPlate(
          plates
        );

        const lastAlerts: any = lastAlertsResponse
          .map(l => {
            if (!l[0]) {
              return null;
            }

            if (
              l[0].tipoAlerta === 'ENTROU_NA_CERCA' ||
              l[0].tipoAlerta === 'PARADA_SUPERIOR_15_MIN' ||
              (l[0].tipoAlerta === 'SAIU_DA_CERCA' &&
                l[0].nomeCerca.indexOf('AIS') === 0 &&
                l[0].nomeCerca.length === 5)
            ) {
              return l[0];
            } else if (fencesSelected.current.includes(l[0].idCerca)) {
              return l[0];
            }

            return l[1] || null;
          })
          .filter(p => p);

        onNewAlert(lastAlerts, positionVehicles, 'function');
      } catch (err) {
        setData(prev => {
          let auxBeforePosition = {};
          return positionVehicles.map(newPosition => {
            let exist = false;
            prev.map(beforePosition => {
              if (
                newPosition.properties.idDevice ===
                beforePosition.properties.idDevice
              ) {
                exist = true;
                auxBeforePosition = beforePosition;
              }
            });
            if (exist) {
              return { ...auxBeforePosition, ...newPosition };
            }
            return newPosition;
          });
        });
        setLoadingVeiculo(false);
        console.error('erro ao carregar ultimas alertas:', err);
      }
    },
    [fencesSelected.current, onNewAlert]
  );

  const onDeactiveAlertSocket = useCallback(() => {
    try {
      setActiveAlert(false);
      setLoadingVeiculo(true);
      socketAlert.off('alertas');
      lastSocketAlerts.current = [];
      setPanelAlertsFiltered([]);
      allAlerts.current = [];
      alertsHistory.current = [];
      setData(prev =>
        prev.map(p => {
          p.properties.foraAIS = false;
          p.properties.foraCerca = false;
          return p;
        })
      );
    } catch (err) {
      toast.error('Erro ao desativar alertas');
      // console.error('erro ao desativar alertas:', err);
    } finally {
      setLoadingVeiculo(false);
    }
  }, [lastSocketAlerts.current]);

  const handleDuplicatedPositions = useCallback(
    (dataSocket: PositionVehicle[]) => {
      const devices = new Set();

      const oldPositions: any = {};

      const dataSocketFiltered: dataPropertiesAux[] = dataSocket
        .sort((a, b) =>
          moment(a.datePosition).isBefore(moment(b.datePosition)) ? 1 : -1
        )
        .filter(d => {
          if (!devices.has(d.idDevice)) {
            devices.add(d.idDevice);
            return true;
          }

          if (!oldPositions[d.idDevice]) {
            oldPositions[d.idDevice] = [];
          }

          oldPositions[d.idDevice].unshift({
            lat: d.latitude,
            lng: d.longitude,
            date: d.datePosition,
          });

          return false;
        })
        .map(d => {
          d.lastPositions = [];
          if (oldPositions[d.idDevice]?.length > 0) {
            d.lastPositions = oldPositions[d.idDevice];
          }

          return {
            ...d,
            latLng: {
              lat: d.latitude,
              lng: d.longitude,
            },
          };
        });

      addPositionToList(dataSocketFiltered);

      return dataSocketFiltered;
    },
    [addPositionToList]
  );

  const loadOccurrences = useCallback(
    async (selectedVin: string[], selectedGroups: string[]) => {
      try {
        socketTO.emit('addFilters', {
          agencias: selectedVin,
          grupos: selectedGroups,
        });

        const occurrences = await ServiceOcorrenciaGEO.getListFiltered({
          agencies: selectedVin,
          groups: selectedGroups,
        });

        if (occurrences) {
          setDataOccurrences(occurrences);
        }

        if (!socketTORegistered.current) {
          onRegisterTOSocket();
        }
      } catch (e) {
        // toast.error(`Erro ao carregar ocorrências`);
      }
    },
    []
  );

  const loadVeiculos = useCallback(
    async (selectedVin: string[], selectedGroups: string[]) => {
      try {
        setLoadingVeiculo(true);

        monitoringVehiclesWithoutRegister.current = [];
        // if (hasSemCadastro.current) {
        //   selectedVin.push('SEM_CADASTRO');
        //   selectedGroups.push('SEM_CADASTRO');
        // }

        const veiculos = await getVeiculosServices.get({
          agencias: selectedVin,
          grupos: selectedGroups,
        });

        const idDevicesList = new Set();

        const veiculosFilter = veiculos
          .sort((a, b) =>
            moment(a.datePosition || '').isBefore(moment(b.datePosition || ''))
              ? 1
              : -1
          )
          .filter((v: any) => {
            if (
              showOnlyVehiclesFences &&
              !vehiclesOfSelectedFences.current.includes(v.placa)
            ) {
              return false;
            }

            if (idDevicesList.has(v.idDevice)) {
              return false;
            }

            idDevicesList.add(v.idDevice);

            const now = new Date();
            const dateAtual = new Date(
              now.getFullYear(),
              now.getMonth(),
              now.getDate()
            );

            dateAtual.setDate(dateAtual.getDate() - daysOfLastPosition);

            if (v.grupo === 'SEM_CADASTRO') {
              monitoringVehiclesWithoutRegister.current.push(v.placa);
            }

            if (v.prefixo && prefixoIsHT(v.prefixo)) {
              const dateAtualMinutos = new Date(
                now.getFullYear(),
                now.getMonth(),
                now.getDate(),
                now.getHours(),
                now.getMinutes()
              );

              dateAtualMinutos.setMinutes(dateAtualMinutos.getMinutes() - 30);

              return (
                v.latitude &&
                v.longitude &&
                new Date(v.datePosition) > dateAtualMinutos
              );
            } else {
              return (
                v.latitude &&
                v.longitude &&
                new Date(v.datePosition) > dateAtual
              );
            }
          });

        const resumo: DataPosition[] = veiculosFilter.map(v => {
          const latLng = {
            lat: v.latitude,
            lng: v.longitude,
          };

          const aisInformada = v.grupo || '';
          let numAis = null;
          let grupo = null;
          let ais = v.grupo;

          if (aisInformada) {
            if (aisInformada.includes('AIS')) {
              numAis = parseInt(aisInformada.replace(/\D/g, ''), 10);
            } else {
              grupo = aisInformada;
            }
          }

          const isConnected = Boolean(
            v.statusConexaoViatura && v.statusConexaoViatura !== 'DESCONECTADA'
          );

          let hasError =
            v.error &&
            moment(v.datePosition).isBefore(moment().subtract(5, 'minutes'));

          return {
            type: 'Feature',
            properties: {
              ...v,
              latLng,
              numAis,
              grupo,
              ais,
              conectado: isConnected,
              ultimaAtualizacao: v.dateInputServer,
              error: hasError,
            },
            geometry: {
              type: 'Point',
              coordinates: [latLng.lng, latLng.lat],
            },
          };
        });

        if (activeAlert) {
          // onActiveAlert(resumo);
          const plates: string[] = resumo.map(r => r.properties.placa);
          await loadLastAlerts(plates, resumo);
        } else {
          setData(resumo);
          setLoadingVeiculo(false);
        }

        dispatch(ACTIONS.getAllAlertRequest());

        if (showOnlyVehiclesFences) {
          socketTVP.emit('addFilters', {
            placas: vehiclesOfSelectedFences.current,
          });

          socketAlert.emit('addFilters', {
            placas: vehiclesOfSelectedFences.current,
          });
        } else {
          socketTVP.emit('addFilters', {
            agencias: selectedVin,
            grupos: selectedGroups,
            placas: monitoringVehiclesWithoutRegister.current,
          });
          socketAlert.emit('addFilters', {
            agencias: selectedVin,
            grupos: selectedGroups,
            idCercas: fencesSelected.current,
          });
        }

        if (!socketTVPRegistered.current) {
          setStatusSocketTVP(true);
          onRegisterTVPSocket();
        }
      } catch (err) {
        const auxData = data.filter(position => {
          if (
            showOnlyVehiclesFences &&
            !vehiclesOfSelectedFences.current.includes(
              position.properties.placa
            )
          ) {
            return false;
          }
          if (
            position.properties.ais &&
            selectedGroups.includes(position.properties.ais) &&
            selectedVin.includes(position.properties.agencia)
          ) {
            return true;
          }
        });

        setData(auxData);

        if (showOnlyVehiclesFences) {
          socketTVP.emit('addFilters', {
            placas: vehiclesOfSelectedFences.current,
          });

          socketAlert.emit('addFilters', {
            placas: vehiclesOfSelectedFences.current,
          });
        } else {
          socketTVP.emit('addFilters', {
            agencias: selectedVin,
            grupos: selectedGroups,
            placas: monitoringVehiclesWithoutRegister.current,
          });
          socketAlert.emit('addFilters', {
            agencias: selectedVin,
            grupos: selectedGroups,
            idCercas: fencesSelected.current,
          });
        }

        if (!socketTVPRegistered.current) {
          setStatusSocketTVP(true);
          onRegisterTVPSocket();
        }
        setLoadingVeiculo(false);
      } finally {
        setLoadingAis(false);
      }
    },
    [
      dataAuth?.roles,
      activeAlert,
      agencies,
      groups,
      dispatch,
      daysOfLastPosition,
      showOnlyVehiclesFences,
      vehiclesOfSelectedFences.current,
      loadLastAlerts,
    ]
  );

  const handleSelectEfetivo = useCallback(
    (efetivo: string) => {
      try {
        if (map) {
          markersConectados.current.map(m => {
            if (efetivo.includes(m.veiculo.idDevice)) {
              if (m.marker.position) {
                map.panTo(m.marker.position);
              }
              new (window.google.maps.event.trigger as any)(m.marker, 'click');
            }
          });
          markersDesconectados.current.map(m => {
            if (efetivo.includes(m.veiculo.idDevice)) {
              if (m.marker.position) {
                map.panTo(m.marker.position);
              }
              new (window.google.maps.event.trigger as any)(m.marker, 'click');
            }
          });
          markersRadio.current.map(m => {
            if (efetivo.includes(m.veiculo.idDevice)) {
              if (m.marker.position) {
                map.panTo(m.marker.position);
              }
              new (window.google.maps.event.trigger as any)(m.marker, 'click');
            }
          });
          markersSemCadastro.current.map(m => {
            if (efetivo.includes(m.veiculo.idDevice)) {
              if (m.marker.position) {
                // @ts-ignore
                map.panTo(m.marker.position);
              }
              new (window.google.maps.event.trigger as any)(m.marker, 'click');
            }

            return;
          });
        }
      } catch (e) {
        //
      }
    },
    [
      map,
      markersConectados.current,
      markersDesconectados.current,
      markersRadio.current,
      markersSemCadastro.current,
    ]
  );

  const onRegisterTVPSocket = useCallback(() => {
    socketTVPRegistered.current = true;

    function onConnectErrorEvent(value: any) {
      setStatusSocketTVP(false);
    }

    socketTVP.on('connect_error', onConnectErrorEvent);
    socketTVP.on('positions', (value: string) => {
      console.log('ds', JSON.parse(value).length);
      handleDuplicatedPositions(JSON.parse(value));
    });
  }, []);

  const onRegisterTOSocket = useCallback(() => {
    socketTORegistered.current = true;
    socketTO.on('createOcorrencia', (value: string) => {
      onNewRegisterOcc(JSON.parse(value), 'create');
    });
    socketTO.on('updateOcorrencia', (value: string) => {
      onNewRegisterOcc(JSON.parse(value), 'update');
    });
  }, []);

  const onUnregisterSockets = useCallback(() => {
    socketTO.off('createOcorrencia');
    socketTO.off('updateOcorrencia');

    socketTVP.off('positions');
    socketTVP.off('connect_error');
  }, []);

  const onSocketReconnect = useCallback(() => {
    try {
      socketAlert.disconnect();
      socketTO.disconnect();
      socketTVP.disconnect();

      const selectedVin: string[] = agencies
        .filter((vin: AgenciesProps) => vin.checked)
        .map((v: AgenciesProps) => v.nome?.replaceAll(' ', ''));

      const selectedGroups: string[] = groups
        .filter((gr: GroupsProps) => gr.checked)
        .map((g: GroupsProps) => g.nome?.replaceAll(' ', ''));

      // if (hasSemCadastro.current) {
      // selectedVin.push('SEM_CADASTRO');
      // selectedGroups.push('SEM_CADASTRO');
      // }

      if (selectedVin.length > 0 && selectedGroups.length > 0) {
        socketTO.connect();
        socketTVP.connect();

        if (activeAlert) {
          socketAlert.connect();
        }

        if (showOnlyVehiclesFences) {
          socketTVP.emit('addFilters', {
            placas: vehiclesOfSelectedFences.current,
          });

          socketAlert.emit('addFilters', {
            placas: vehiclesOfSelectedFences.current,
          });
        } else {
          socketTVP.emit('addFilters', {
            agencias: selectedVin,
            grupos: selectedGroups,
            placas: monitoringVehiclesWithoutRegister.current,
          });
          socketAlert.emit('addFilters', {
            agencias: selectedVin,
            grupos: selectedGroups,
            idCercas: fencesSelected.current,
          });
        }

        socketTO.emit('addFilters', {
          agencias: selectedVin,
          grupos: selectedGroups,
        });

        onRegisterTVPSocket();
        onRegisterTOSocket();
      }
    } catch (e) {
      //
    }
  }, [
    agencies,
    groups,
    fencesSelected.current,
    showOnlyVehiclesFences,
    activeAlert,
    monitoringVehiclesWithoutRegister.current,
    vehiclesOfSelectedFences.current,
  ]);

  const onActivateAlertSocket = useCallback(() => {
    setActiveAlert(true);
    if (firstLoadOfActiveAlert.current) {
      socketAlert.on('alertas', (value: string) => {
        const dataSocket: Alert[] = [JSON.parse(value)];
        onNewAlert(dataSocket, [], 'socket');
      });

      firstLoadOfActiveAlert.current = false;
    }

    const plates: string[] = data.map(r => r.properties.placa);
    loadLastAlerts(plates, data);
  }, [data, onNewAlert, fencesSelected.current]);

  const handleRenderMarkersConectadas = useCallback(
    (render: boolean, currentMap: google.maps.Map | undefined) => {
      showConectadas.current = render;
      if (render) {
        markersConectados.current.map(m => {
          m.marker.map = currentMap || null;
          return m;
        });
      } else {
        markersConectados.current.map(m => {
          m.marker.map = null;
        });
      }
    },
    [markersConectados.current]
  );

  const handleRenderMarkersDesconectadas = useCallback(
    (render: boolean, currentMap: google.maps.Map | undefined) => {
      showDesconectadas.current = render;
      if (render) {
        markersDesconectados.current.map(m => {
          m.marker.map = currentMap || null;
          return m;
        });
      } else {
        markersDesconectados.current.map(m => {
          m.marker.map = null;
        });
      }
    },
    [markersDesconectados.current, map]
  );

  const handleRenderMarkersRadio = useCallback(
    (render: boolean, currentMap: google.maps.Map | undefined) => {
      showRadio.current = render;
      if (render) {
        markersRadio.current.map(m => {
          m.marker.map = currentMap || null;
          return m;
        });

        markersSemCadastro.current.map(m => {
          if (m.markerType !== 'RÁDIO') return m;
          m.marker.map = currentMap || null;
          return m;
        });
      } else {
        markersRadio.current.map(m => {
          m.marker.map = null;
        });
        markersSemCadastro.current.map(m => {
          if (m.markerType !== 'RÁDIO') return m;
          m.marker.map = null;
        });
      }
    },
    [markersRadio.current, markersSemCadastro.current, map]
  );

  const handleRenderMarkersSemCadastro = useCallback(
    (render: boolean, currentMap: google.maps.Map | undefined) => {
      showSemCadastro.current = render;

      if (render) {
        markersSemCadastro.current.map(m => {
          m.marker.map = currentMap || null;
          return m;
        });
      } else {
        markersSemCadastro.current.map(m => {
          m.marker.map = null;
        });
      }
    },
    [markersSemCadastro.current, map]
  );

  const handleRenderMarkersOccurrence = useCallback(
    (render: boolean, currentMap: google.maps.Map | undefined) => {
      showOccurrences.current = render;
      if (render) {
        mapOccurrences.current.map(m => {
          m.marker.map = currentMap || null;
          return m;
        });
      } else {
        mapOccurrences.current.map(m => {
          m.marker.map = null;
        });
      }
    },
    [mapOccurrences.current, map]
  );

  const handleRenderTooltipsConectadas = useCallback(
    (render: boolean, currentMap: google.maps.Map | undefined) => {
      showTooltipConectadas.current = render;

      markersConectados.current.map(m => {
        const outFenceAux = Boolean(m.veiculo.foraCerca && showAlerts.outFence);

        const outAisAux = Boolean(m.veiculo.foraAIS && showAlerts.outAis);

        let auxStatus =
          m.veiculo.statusConexaoViatura &&
          m.veiculo.statusConexaoViatura !== 'DESCONECTADA'
            ? m.veiculo.statusConexaoViatura
            : '';

        const {
          defaultIcon,
          widthAux,
          heightAux,
          xOffsetAux,
          yOffsetAux,
        } = loadVehicleIconsMap(
          m.veiculo.prefixo,
          auxStatus,
          outFenceAux,
          outAisAux,
          render,
          false,
          Boolean(m.veiculo.error)
        );

        m.infowindow.setOptions({
          pixelOffset: new google.maps.Size(xOffsetAux, yOffsetAux),
        });

        const icon = document.createElement('img');
        icon.src = defaultIcon;

        m.marker.content = icon;
        // setIcon({
        //   url: defaultIcon,
        //   scaledSize: new window.google.maps.Size(widthAux, heightAux),
        //   anchor: new google.maps.Point(widthAux / 2, heightAux - 5),
        // });

        return m;
      });
    },
    [showConectadas, markersConectados.current]
  );

  const handleRenderTooltipsDesconectadas = useCallback(
    (render: boolean, currentMap: google.maps.Map | undefined) => {
      showTooltipDesconectadas.current = render;

      markersDesconectados.current.map(m => {
        const outFenceAux = Boolean(m.veiculo.foraCerca && showAlerts.outFence);

        const outAisAux = Boolean(m.veiculo.foraAIS && showAlerts.outAis);

        const {
          defaultIcon,
          widthAux,
          heightAux,
          xOffsetAux,
          yOffsetAux,
        } = loadVehicleIconsMap(
          m.veiculo.prefixo,
          null,
          outFenceAux,
          outAisAux,
          render,
          false,
          Boolean(m.veiculo.error)
        );

        m.infowindow.setOptions({
          pixelOffset: new google.maps.Size(xOffsetAux, yOffsetAux),
        });

        const icon = document.createElement('img');
        icon.src = defaultIcon;

        m.marker.content = icon;

        // m.marker.setIcon({
        //   url: defaultIcon,
        //   scaledSize: new window.google.maps.Size(widthAux, heightAux),
        //   anchor: new google.maps.Point(widthAux / 2, heightAux - 5),
        // });

        return m;
      });
    },
    [showDesconectadas, markersDesconectados.current]
  );

  const handleRenderTooltipsRadio = useCallback(
    (render: boolean, currentMap: google.maps.Map | undefined) => {
      showTooltipRadio.current = render;

      markersRadio.current.map(m => {
        const outFenceAux = Boolean(m.veiculo.foraCerca && showAlerts.outFence);

        const outAisAux = Boolean(m.veiculo.foraAIS && showAlerts.outAis);

        const {
          defaultIcon,
          widthAux,
          heightAux,
          xOffsetAux,
          yOffsetAux,
        } = loadVehicleIconsMap(
          m.veiculo.prefixo,
          'RÁDIO',
          outFenceAux,
          outAisAux,
          render,
          false,
          Boolean(m.veiculo.error)
        );

        m.infowindow.setOptions({
          pixelOffset: new google.maps.Size(xOffsetAux, yOffsetAux),
        });

        const icon = document.createElement('img');
        icon.src = defaultIcon;

        m.marker.content = icon;

        // m.marker.setIcon({
        //   url: defaultIcon,
        //   scaledSize: new window.google.maps.Size(widthAux, heightAux),
        //   anchor: new google.maps.Point(widthAux / 2, heightAux - 5),
        // });

        return m;
      });

      markersSemCadastro.current.map(m => {
        if (m.markerType !== 'RÁDIO') return;

        const outFenceAux = Boolean(m.veiculo.foraCerca && showAlerts.outFence);

        const outAisAux = Boolean(m.veiculo.foraAIS && showAlerts.outAis);

        const {
          defaultIcon,
          widthAux,
          heightAux,
          xOffsetAux,
          yOffsetAux,
        } = loadVehicleIconsMap(
          m.veiculo.prefixo,
          'RÁDIO',
          outFenceAux,
          outAisAux,
          render,
          false,
          Boolean(m.veiculo.error)
        );

        m.infowindow.setOptions({
          pixelOffset: new google.maps.Size(xOffsetAux, yOffsetAux),
        });

        const icon = document.createElement('img');
        icon.src = defaultIcon;

        m.marker.content = icon;

        // m.marker.setIcon({
        //   url: defaultIcon,
        //   scaledSize: new window.google.maps.Size(widthAux, heightAux),
        //   anchor: new google.maps.Point(widthAux / 2, heightAux - 5),
        // });

        return m;
      });
    },
    [showRadio, markersRadio.current, markersSemCadastro.current]
  );

  const handleRenderTooltipsSemCadastro = useCallback(
    (render: boolean, currentMap: google.maps.Map | undefined) => {
      showTooltipSemCadastro.current = render;

      markersSemCadastro.current.map(m => {
        let isReallyAnHT = false;

        if (
          (m.veiculo?.prefixo && prefixoIsHT(m.veiculo?.prefixo)) ||
          (!m.veiculo?.prefixo && prefixoIsHT(m.veiculo?.placa))
        ) {
          isReallyAnHT = true;
        }

        const {
          defaultIcon,
          widthAux,
          heightAux,
          xOffsetAux,
          yOffsetAux,
        } = loadVehicleIconsMap(
          m.veiculo.prefixo,
          isReallyAnHT ? 'RÁDIO' : 'SEM_CADASTRO',
          false,
          false,
          render,
          false,
          Boolean(m.veiculo.error)
        );

        m.infowindow.setOptions({
          pixelOffset: new google.maps.Size(xOffsetAux, yOffsetAux),
        });

        const icon = document.createElement('img');
        icon.src = defaultIcon;

        m.marker.content = icon;

        // m.marker.setIcon({
        //   url: defaultIcon,
        //   scaledSize: new window.google.maps.Size(widthAux, heightAux),
        //   anchor: new google.maps.Point(widthAux / 2, heightAux - 5),
        // });

        return m;
      });
    },
    [showRadio, markersRadio.current]
  );

  const handleRenderTooltipsOccurrence = useCallback(
    (render: boolean, currentMap: google.maps.Map | undefined) => {
      showTooltipOccurrences.current = render;
      if (showOccurrences && render) {
        mapOccurrences.current.map(m => {
          m.tooltip?.open({
            map: currentMap || null,
            anchor: m.marker,
            shouldFocus: false,
          });
        });
      } else {
        mapOccurrences.current.map(m => {
          m.tooltip?.close();
        });
      }
    },
    [showOccurrences, mapOccurrences.current]
  );

  useEffect(() => {
    socketTVP.io.opts.query = {
      token: `${sessionStorage.getItem('rota@tkn')}`,
    };

    socketTO.io.opts.query = {
      token: `${sessionStorage.getItem('rota@tkn')}`,
    };

    socketAlert.io.opts.query = {
      token: `${sessionStorage.getItem('rota@tkn')}`,
    };

    socketTVP.connect();
    socketTO.connect();
    socketAlert.connect();

    return () => {
      socketTO.disconnect();
      socketTVP.disconnect();
      socketAlert.disconnect();
    };
  }, []);

  // effect para carregar as cercas no perfil SECRETARIO
  useEffect(() => {
    if (dataAuth.isSecretario && isMonitoring) {
      fencesSelected.current = fences.current.data
        ?.filter(f => f.veiculosAssociados?.length && f.ativo)
        .map(f => f.id || '');

      if (!firstLoadAlertsForSecretary.current) {
        firstLoadAlertsForSecretary.current = true;
        firstLoadOfActiveAlert.current = true;
        onActivateAlertSocket();
      }
    }
  }, [dataAuth, fences.current, onActivateAlertSocket, isMonitoring]);

  const handleLoadData = useCallback(() => {
    try {
      const selectedVin: string[] = agencies
        .filter((vin: AgenciesProps) => vin.checked)
        .map((v: AgenciesProps) => v.nome?.replaceAll(' ', ''));

      const selectedGroups: string[] = groups
        .filter((gr: GroupsProps) => gr.checked)
        .map((g: GroupsProps) => g.nome?.replaceAll(' ', ''));

      if (selectedGroups.length > 0 && selectedVin.length > 0) {
        loadVeiculos(selectedVin, selectedGroups);
        if (hasOccurrenceRole()) {
          loadOccurrences(selectedVin, selectedGroups);
        }
      } else {
        setData([]);
        setDataOccurrences([]);
        setLoadingAis(false);
        setLoadingVeiculo(false);
        setActiveAlert(false);
        onDeactiveAlertSocket();
        onUnregisterSockets();
      }
    } catch {
      //
    }
  }, [agencies, groups, daysOfLastPosition, showOnlyVehiclesFences]);

  useEffect(() => {
    handleLoadData();
  }, [handleLoadData]);

  // se a aba ficar minimizada por 30m ou mais, a conexão do socket é reiniciada
  useEffect(() => {
    let isMounted = true;
    const interval = setInterval(() => {
      if (isMounted) {
        let hidden;
        if (typeof document.hidden !== 'undefined') {
          hidden = 'hidden';
        }

        // @ts-ignore
        if (document[hidden]) {
          count_minimized.current += 1;
        } else if (count_minimized.current > 29) {
          count_minimized.current = 1;
          onSocketReconnect();
        } else {
          count_minimized.current = 1;
        }
      }
    }, 60 * 1000);

    return () => {
      isMounted = false;
      clearInterval(interval);
    };
  }, [onSocketReconnect]);

  return (
    <>
      <MonitoringContext.Provider
        value={{
          map,
          setMap,
          loadingVeiculo,
          data,
          setData,
          lastSocketAlerts,
          statusSocketTVP,
          roleAis: aisCompare,
          daysOfLastPosition,
          setDaysOfLastPosition,
          markersConectados,
          markersDesconectados,
          markersRadio,
          markersSemCadastro,
          listenersConectados,
          setListenersConectados,
          showConectadas,
          showDesconectadas,
          showRadio,
          showOccurrences,
          showSemCadastro,
          showTooltipConectadas,
          showTooltipDesconectadas,
          showTooltipRadio,
          showTooltipSemCadastro,
          showTooltipOccurrences,
          fencesSelected,
          loadingFences,
          setLoadingFences,
          dataOccurrences,
          setDataOccurrences,
          activeAlert,
          setActiveAlert,
          firstLoadOfActiveAlert,
          showAlerts,
          updateShowAlerts,
          mapOccurrences,
          handleSelectEfetivo,
          filterAlertArray,
          allAlerts,
          panelAlertsFiltered,
          alertsHistory,
          vehiclesOfSelectedFences,
          showOnlyVehiclesFences,
          setShowOnlyVehiclesFences,
          onActivateAlertSocket,
          onDeactiveAlertSocket,
          handleRenderMarkersConectadas,
          handleRenderMarkersDesconectadas,
          handleRenderMarkersRadio,
          handleRenderMarkersSemCadastro,
          handleRenderMarkersOccurrence,
          handleRenderTooltipsConectadas,
          handleRenderTooltipsDesconectadas,
          handleRenderTooltipsRadio,
          handleRenderTooltipsSemCadastro,
          handleRenderTooltipsOccurrence,
          handleLoadData,
        }}
      >
        {children}
      </MonitoringContext.Provider>
    </>
  );
};
