import React, { useCallback, useState } from 'react';

import { toast } from 'react-toastify';
import debounce from 'lodash.debounce';
import { StatusContainer } from './styles';
import { useFiltroOutrosInfo } from 'hooks/monitoring/useFiltroOutrosInfo';
import { useHistoryResetFunctions } from 'hooks/history/useHistoryResetFunctions';
import { useAuthData } from 'hooks/auth/useAuthData';
import { useGlobalFences } from 'hooks/global/useGlobalFences';
import { useMonitoringGoogleMap } from 'hooks/monitoring/useMonitoringGoogleMap';
import { useHistoryMap } from 'hooks/history/useHistoryMap';
import { useLocation } from 'react-router-dom';

export default function FiltroOutros({
  showDaysSelect,
}: {
  showDaysSelect: boolean;
}) {
  const { data } = useAuthData();

  const location = useLocation();

  const isMonitoramento = location.pathname === '/mapa/monitoramento';

  const { hasMonitoramentoCIOPS, isSecretario } = data;

  const { handleRenderFencesName } = useGlobalFences();

  const [opcoes, setOpcoes] = useState([
    {
      id: 1,
      status: 'Conectadas',
      key: 'conectadas',
      opcoesCheckbox: [
        {
          id: 2,
          checked: true,
          key: '2',
          name: 'Marcador',
        },
        {
          id: 3,
          checked: Boolean(hasMonitoramentoCIOPS),
          key: '3',
          name: 'Prefixo',
        },
      ],
    },
    {
      id: 4,
      status: 'Desconectadas',
      key: 'desconectadas',
      opcoesCheckbox: [
        {
          id: 5,
          checked: !(Boolean(hasMonitoramentoCIOPS) || Boolean(isSecretario)),
          key: '5',
          name: 'Marcador',
        },
        {
          id: 6,
          checked: false,
          key: '6',
          name: 'Prefixo',
        },
      ],
    },
    {
      id: 7,
      status: 'Rádio',
      key: 'radio',
      opcoesCheckbox: [
        {
          id: 8,
          checked: true,
          key: '8',
          name: 'Marcador',
        },
        {
          id: 9,
          checked: Boolean(hasMonitoramentoCIOPS),
          key: '9',
          name: 'Prefixo',
        },
      ],
    },
    {
      id: 17,
      status: 'Sem cadastro',
      key: 'semCadastro',
      opcoesCheckbox: [
        {
          id: 18,
          checked: true,
          key: '18',
          name: 'Marcador',
        },
        {
          id: 19,
          checked: false,
          key: '19',
          name: 'Equipamento',
        },
      ],
    },
    {
      id: 13,
      status: 'Cercas',
      key: 'fences',
      opcoesCheckbox: [
        {
          id: 14,
          checked: false,
          key: '14',
          name: 'Nome',
        },
      ],
    },
    {
      id: 15,
      status: 'Viaturas das Cercas',
      key: 'fenceVehicles',
      opcoesCheckbox: [
        {
          id: 16,
          checked: Boolean(hasMonitoramentoCIOPS),
          key: '16',
          name: 'Marcadores',
        },
      ],
    },
    {
      id: 10,
      status: 'Ocorrência',
      key: 'occurrences',
      opcoesCheckbox: [
        {
          id: 11,
          checked: !Boolean(hasMonitoramentoCIOPS),
          key: '11',
          name: 'Marcador',
        },
        {
          id: 12,
          checked: false,
          key: '12',
          name: 'Descrição',
        },
      ],
    },
  ]);
  const {
    showConectadas,
    showDesconectadas,
    showRadio,
    showSemCadastro,
    showOccurrences,
    setDaysOfLastPosition,
    handleRenderMarkersConectadas,
    handleRenderMarkersDesconectadas,
    handleRenderMarkersRadio,
    handleRenderMarkersSemCadastro,
    handleRenderMarkersOccurrence,
    handleRenderTooltipsConectadas,
    handleRenderTooltipsDesconectadas,
    handleRenderTooltipsRadio,
    handleRenderTooltipsSemCadastro,
    handleRenderTooltipsOccurrence,
    setShowOnlyVehiclesFences,
  } = useFiltroOutrosInfo();

  const [localDays, setLocalDays] = useState(
    Boolean(data.hasMonitoramentoCIOPS) || Boolean(data.isSecretario) ? 1 : 10
  );

  const { map } = useMonitoringGoogleMap();
  const { map: mapHistory } = useHistoryMap();

  const { resetAllData } = useHistoryResetFunctions();

  const functionsObj: any = {
    conectadas: {
      2: handleRenderMarkersConectadas,
      3: handleRenderTooltipsConectadas,
    },
    desconectadas: {
      5: handleRenderMarkersDesconectadas,
      6: handleRenderTooltipsDesconectadas,
    },
    radio: {
      8: handleRenderMarkersRadio,
      9: handleRenderTooltipsRadio,
    },
    semCadastro: {
      18: handleRenderMarkersSemCadastro,
      19: handleRenderTooltipsSemCadastro,
    },
    occurrences: {
      11: handleRenderMarkersOccurrence,
      12: handleRenderTooltipsOccurrence,
    },
    fences: {
      14: handleRenderFencesName,
    },
    fenceVehicles: {
      16: setShowOnlyVehiclesFences,
    },
  };

  const handleChange = useCallback(
    ({
      statusId,
      id,
      check,
      key,
    }: {
      statusId: number;
      id: number;
      check: boolean;
      key: string;
    }) => {
      try {
        const currentMap = isMonitoramento ? map : mapHistory;

        if (id === 2 || id === 5 || id === 8 || id === 11 || id === 18) {
          setOpcoes(prev =>
            prev.map(vin =>
              vin.id === statusId
                ? {
                    ...vin,
                    opcoesCheckbox: vin.opcoesCheckbox.map(op =>
                      op.id === id
                        ? { ...op, checked: !op.checked }
                        : op.id === 3 ||
                          op.id === 6 ||
                          op.id === 9 ||
                          op.id === 12 ||
                          op.id === 19
                        ? { ...op, checked: false }
                        : op
                    ),
                  }
                : {
                    ...vin,
                    opcoesCheckbox: vin.opcoesCheckbox.map(op =>
                      op.id === 3 ||
                      op.id === 6 ||
                      op.id === 9 ||
                      op.id === 12 ||
                      op.id === 19
                        ? { ...op, checked: false }
                        : op
                    ),
                  }
            )
          );
          handleRenderTooltipsConectadas(false, currentMap);
          handleRenderTooltipsDesconectadas(false, currentMap);
          handleRenderTooltipsRadio(false, currentMap);
          handleRenderTooltipsSemCadastro(false, currentMap);
          handleRenderTooltipsOccurrence(false, currentMap);
        } else {
          setOpcoes(prev =>
            prev.map(vin =>
              vin.id === statusId
                ? {
                    ...vin,
                    opcoesCheckbox: vin.opcoesCheckbox.map(op =>
                      op.id === id ? { ...op, checked: !op.checked } : op
                    ),
                  }
                : vin
            )
          );
        }

        functionsObj[key][id](!check, currentMap);
      } catch (err) {
        // continue
      }
    },
    [
      map,
      handleRenderTooltipsConectadas,
      handleRenderTooltipsDesconectadas,
      handleRenderTooltipsRadio,
      handleRenderTooltipsSemCadastro,
      handleRenderTooltipsOccurrence,
      handleRenderFencesName,
      handleRenderMarkersConectadas,
      handleRenderMarkersDesconectadas,
      handleRenderMarkersRadio,
      handleRenderMarkersOccurrence,
    ]
  );

  const debouncedSave = useCallback(
    debounce(nextValue => setDaysOfLastPosition(nextValue), 1000),
    []
  );

  const handleChangeDays = (event: any) => {
    const { value } = event.target;
    if (value < 1) {
      toast.error('O valor mínimo para pesquisa é de 1 dia');
    } else if (value > 30) {
      toast.error('O valor máximo para pesquisa é de 30 dias');
    } else {
      resetAllData();
      setLocalDays(value);
      debouncedSave(value);
    }
  };

  return (
    <>
      <div className="grid  grid-template-columns-2">
        {opcoes.map(o => {
          if (isMonitoramento || o.id !== 15) {
            return (
              <StatusContainer key={o.id}>
                <h3>{o.status}</h3>
                {o.opcoesCheckbox.map(op => (
                  <div key={op.id}>
                    <label htmlFor={op.key} className="switchToggle">
                      <input
                        type="checkbox"
                        id={op.key}
                        disabled={
                          (op.id === 3 && !showConectadas) ||
                          (op.id === 6 && !showDesconectadas) ||
                          (op.id === 9 && !showRadio) ||
                          (op.id === 12 && !showOccurrences) ||
                          (op.id === 19 && !showSemCadastro)
                        }
                        className="display-switch"
                        checked={op.checked}
                        onChange={() => {
                          handleChange({
                            statusId: o.id,
                            id: op.id,
                            check: op.checked,
                            key: o.key,
                          });
                        }}
                      />
                      <span className="slider" />
                    </label>
                    &nbsp;
                    <span>{op.name}</span>
                  </div>
                ))}
              </StatusContainer>
            );
          }
        })}
      </div>
      {showDaysSelect && (
        <div key="lastPositionInput">
          <span>Posição nos últimos</span>
          &nbsp;
          <input
            type="number"
            max={30}
            min={1}
            value={localDays}
            id="lastPositionInputId"
            style={{
              width: '40px',
              borderStyle: 'ridge',
              border: 'none',
              borderBottom: 'solid 1px',
              color: '#1a784a',
              textAlign: 'center',
              borderColor: 'black',
            }}
            onChange={handleChangeDays}
          />
          &nbsp;
          <span>dias</span>
        </div>
      )}
    </>
  );
}

FiltroOutros.propTypes = {};
