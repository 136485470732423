import { VehiclePositionHistory } from 'interfaces/history';
import api from '../api';
import ENV from 'config';

interface BodyProps {
  idDevices?: string[];
  distanciaKM?: number;
  latitude?: number;
  longitude?: number;
  dataInicio: string;
  dataFim: string;
}

// o objeto de retorno coloca a ultima posição dentro do "positions", precisa adicionar a um array antes de tudo

export const HistoryTeltronicService = {
  getByIdDevices: async (
    body: BodyProps
  ): Promise<VehiclePositionHistory[]> => {
    return await api.post(`${ENV.serverSSPDS}/rotas/teltronic/history`, body, {
      headers: { 'Content-Type': 'application/json' },
      timeout: 1000 * 180,
    });
  },

  loadSphericalDistance: async (
    body: BodyProps
  ): Promise<VehiclePositionHistory[]> => {
    try {
      return await api.post(
        `${ENV.serverSSPDS}/rotas/teltronic/history/sphericalDistance`,
        body,
        {
          timeout: 1000 * 180,
        }
      );
    } catch (e) {
      return [];
    }
  },

  loadAuditPolygonHistory: async (
    body: BodyProps
  ): Promise<VehiclePositionHistory[]> => {
    try {
      return await api.post(
        `${ENV.serverSSPDS}/rotas/teltronic/auditPolygon`,
        body,
        {
          headers: { 'Content-Type': 'application/json' },
          timeout: 1000 * 180,
        }
      );
    } catch (e) {
      return [];
    }
  },
};

