import { Button, Modal, Progress, Spin } from 'antd';
import { useAuthData } from 'hooks/auth/useAuthData';
import { useLoadLastPositions } from 'hooks/global/useLoadLastPositions';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ServiceExterno } from 'services/modulos/serviceExterno';
import { Container } from './styles';
import { LoadFormattedAddress, prefixoIsHT } from 'utils/functionsUtils';
import { PositionVehicle, dataPropertiesAux } from 'interfaces/vehicle';
import { CSVDownload } from 'react-csv';
import pdfGen from 'components/container_mapa/reports/genereate_monitoring_report/report';
import moment, { Moment } from 'moment';

type TMonitoringReportModal = {
  open: boolean;
  handleCancel: any;
  setIsModalVisible: (value: boolean) => void;
};

export const MonitoringReportModal = (props: TMonitoringReportModal) => {
  const { open, handleCancel, setIsModalVisible } = props;
  let csvData = useRef<any[]>([]);

  const { loadLastPositions } = useLoadLastPositions();
  const { data: dataAuth } = useAuthData();
  const location = useLocation();

  const { client } = dataAuth;

  const [pdfLoaded, setPdfLoaded] = useState(false);
  const [percent, setPercent] = useState(0);
  const [genCSV, setGenCSV] = useState(false);

  const [selectedFile, setSelectedFile] = useState('');

  const lastPositions = useRef<PositionVehicle[]>([]);
  const dateOfLastReport = useRef<Moment>();

  const uniqueCoords = useRef<{
    [key: string]: string;
  }>({});

  const csvHeader = [
    'Agência',
    'Grupo',
    'Placa',
    'Prefixo',
    'Status',
    'Ultima posição',
    'Logradouro',
    'Latitude',
    'Longitude',
  ];

  const handleCancelModal = () => {
    csvData.current = [];
    setIsModalVisible(false);
    setPercent(0);
    setGenCSV(false);
    setPdfLoaded(false);
  };

  const handleFormatToCSV = (
    values: (dataPropertiesAux & { logradouro?: string })[]
  ) => {
    const dados = values.map((v, i) => {
      // let currentLogradouro = logradouros.find(l => l.id === i);
      return [
        v.agencia || '',
        v.grupo || '',
        v.placa || '',
        v.prefixo || '',
        v.statusConexaoViatura || 'DESCONECTADO',
        moment(v.datePosition).format('DD/MM/YYYY HH:mm:ss'),
        v.logradouro || '',
        v.latitude || '',
        v.longitude || '',
      ];
    });
    csvData.current = dados;
    setGenCSV(true);
  };

  const handleFormatToPdf = (
    values: (dataPropertiesAux & { logradouro?: string })[]
  ) => {
    const formattedData: { [key: string]: (string | number)[][] } = {};

    values.map(p => {
      if (!formattedData[p.grupo || '']) formattedData[p.grupo || ''] = [];

      formattedData[p.grupo || ''].push([
        p.placa,
        p.prefixo,
        p.idDevice,
        p.statusConexaoViatura || '',
        moment(p.datePosition).format('DD/MM/YYYY HH:mm:ss'),
        p.logradouro || 'N/A',
        p.latitude,
        p.longitude,
      ]);
    });

    return formattedData;
  };

  const handleAddLogradouro = useCallback(
    async (
      values: (dataPropertiesAux & {
        logradouro?: string;
      })[]
    ) => {
      values.map(coord => {
        const concCoord =
          String(coord.latitude) + '|' + String(coord.longitude);

        uniqueCoords.current[concCoord] = '';
      });

      const uniqueCoordsArr: string[] = Object.keys(uniqueCoords.current);

      const range = uniqueCoordsArr.length;

      const times = range / 1000 + 1;

      for (let i = 0; i < times; i += 1) {
        const slicedArray = uniqueCoordsArr.slice(
          i * 1000,
          Math.min((i + 1) * 1000, range)
        );

        await Promise.all(
          slicedArray.map(async x => {
            let currentLogradouro = '';

            let [lat, lng]: string[] | number[] = x.split('|');

            lat = Number(lat);
            lng = Number(lng);

            currentLogradouro = await LoadFormattedAddress(lat, lng);

            uniqueCoords.current[x] = currentLogradouro;

            return x;
          })
        );
      }

      values.map(d => {
        const concCoord = String(d.latitude) + '|' + String(d.longitude);

        d.logradouro = uniqueCoords.current[concCoord]
          ? uniqueCoords.current[concCoord]
          : '*';

        return d;
      });

      return values;
    },
    []
  );

  const filterPositions = useCallback(
    async (target: string) => {
      try {
        // colocar limitação de tempo (5m)
        if (
          !dateOfLastReport.current ||
          moment().diff(moment(dateOfLastReport.current)) > 5 * 60 * 1000
        ) {
          lastPositions.current = await loadLastPositions();
          dateOfLastReport.current = moment();
        }

        switch (target) {
          case 'connecteds': {
            return (lastPositions.current || []).filter(
              p =>
                p.agencia === 'PM' &&
                p.statusConexaoViatura &&
                !(prefixoIsHT(p.prefixo) || prefixoIsHT(p.placa))
            );
          }
          case 'desconnecteds': {
            return (lastPositions.current || [])
              .filter(p => p.agencia === 'PM' && !p.statusConexaoViatura)
              .map(p => ({
                ...p,
                statusConexaoViatura: 'DESCONECTADA',
              }));
          }
          case 'radios': {
            return (lastPositions.current || [])
              .filter(
                p =>
                  p.agencia === 'PM' &&
                  (prefixoIsHT(p.prefixo) || prefixoIsHT(p.placa))
              )
              .map(p => ({
                ...p,
                statusConexaoViatura: 'Rádio',
              }));
          }
        }
      } catch (e) {
        return [];
      }
    },
    [loadLastPositions]
  );

  const handleDownloadFile = useCallback(
    async (type: string, target: string) => {
      setSelectedFile(`${type}_${target}`);

      // Registrando usuário que está gerando relatório
      ServiceExterno.generateReport({
        name: client.name,
        cpf: client.cpf,
        externPath: 'Google Geocoding API',
        pathName: location.pathname,
      });

      let positions = (await filterPositions(target)) as (dataPropertiesAux & {
        logradouro?: string;
      })[];

      positions = await handleAddLogradouro(positions || []);

      setPdfLoaded(true);
      if (type === 'pdf') {
        const pdfData = handleFormatToPdf(positions);

        const translatedType: { [key: string]: string } = {
          connecteds: 'Viaturas conectadas',
          desconnecteds: 'Viaturas desconectadas',
          radios: 'HTs conectados',
        };

        pdfGen(`${translatedType[target]} - PM`, pdfData);
      } else if (type === 'csv') {
        handleFormatToCSV(positions);
      }
      setSelectedFile('');
    },
    [filterPositions, handleAddLogradouro]
  );

  return (
    <Modal
      key="modalRelatorioMonitoramento"
      destroyOnClose
      title="Selecione o relatório que deseja"
      // visible={isModalVisible}
      open={open}
      onCancel={handleCancelModal}
      footer={[
        <Button key="1" onClick={handleCancelModal}>
          Cancelar
        </Button>,
      ]}
    >
      <Container>
        <p style={{ fontWeight: '500' }}>PDF</p>
        <div className="rowButtons">
          <Button
            className={
              selectedFile
                ? 'buttonSquare pdfButton disabled'
                : 'buttonSquare pdfButton'
            }
            onClick={() => handleDownloadFile('pdf', 'connecteds')}
          >
            {selectedFile === 'pdf_connecteds' ? (
              <Spin spinning />
            ) : (
              'Conectadas'
            )}
          </Button>
          <Button
            className={
              selectedFile
                ? 'buttonSquare pdfButton disabled'
                : 'buttonSquare pdfButton'
            }
            onClick={() => handleDownloadFile('pdf', 'desconnecteds')}
          >
            {selectedFile === 'pdf_desconnecteds' ? (
              <Spin spinning />
            ) : (
              'Desconectadas'
            )}
          </Button>
          <Button
            className={
              selectedFile
                ? 'buttonSquare pdfButton disabled'
                : 'buttonSquare pdfButton'
            }
            onClick={() => handleDownloadFile('pdf', 'radios')}
          >
            {selectedFile === 'pdf_radios' ? <Spin spinning /> : 'Rádio'}
          </Button>
        </div>
        <p style={{ marginTop: '10px', fontWeight: '500' }}>CSV</p>
        <div className="rowButtons">
          <Button
            className={
              selectedFile
                ? 'buttonSquare csvButton disabled'
                : 'buttonSquare csvButton'
            }
            onClick={() => handleDownloadFile('csv', 'connecteds')}
          >
            {genCSV && (
              // @ts-ignore
              <CSVDownload
                headers={csvHeader}
                data={csvData.current}
                filename="R.O.T.A.S. Planilha de Monitoramento.csv"
                target="_blank"
              />
            )}
            {selectedFile === 'csv_connecteds' ? (
              <Spin spinning />
            ) : (
              'Conectadas'
            )}
          </Button>
          <Button
            className={
              selectedFile
                ? 'buttonSquare csvButton disabled'
                : 'buttonSquare csvButton'
            }
            onClick={() => handleDownloadFile('csv', 'desconnecteds')}
          >
            {genCSV && (
              // @ts-ignore
              <CSVDownload
                headers={csvHeader}
                data={csvData.current}
                filename="R.O.T.A.S. Planilha de Monitoramento.csv"
                target="_blank"
              />
            )}
            {selectedFile === 'csv_desconnecteds' ? (
              <Spin spinning />
            ) : (
              'Desconectadas'
            )}
          </Button>
          <Button
            className={
              selectedFile
                ? 'buttonSquare csvButton disabled'
                : 'buttonSquare csvButton'
            }
            onClick={() => handleDownloadFile('csv', 'radios')}
          >
            {genCSV && (
              // @ts-ignore
              <CSVDownload
                headers={csvHeader}
                data={csvData.current}
                filename="R.O.T.A.S. Planilha de Monitoramento.csv"
                target="_blank"
              />
            )}
            {selectedFile === 'csv_radios' ? <Spin spinning /> : 'Rádio'}
          </Button>
        </div>
      </Container>
      {/* {percent > 0 && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            padding: '20px',
            flexDirection: 'column',
            textAlign: 'center',
          }}
        >
          <Progress type="circle" percent={percent} />
        </div>
      )} */}
    </Modal>
  );
};

