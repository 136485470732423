// @ts-nocheck
import React, { useCallback, useMemo, useState } from 'react';
import { Form, Select, Row, Col, Spin, Button } from 'antd';
import { useGlobalVehicles } from 'hooks/global/useGlobalVehicles';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { ClearOutlined, EditOutlined } from '@ant-design/icons';
import { v4 as uuidv4 } from 'uuid';
import { Container } from './styles';

import './styles.css';

import {
  microregioesCEFormatted,
  bairrosCEFormatted,
  municipiosCEFormatted,
} from 'utils/functionsUtils';
import { useHistoryResetFunctions } from 'hooks/history/useHistoryResetFunctions';
import { useHistoryDataSource } from 'hooks/history/useHistoryDataSource';

const { Option } = Select;
export function FormCustomAudit({ setIsDrawing, onVisible, setIsErasing }) {
  const [form] = Form.useForm();
  const [dtInicio, setDtInicio] = useState('');
  const [hrInicio, setHrInicio] = useState('');

  const [hrFim, setHrFim] = useState('');

  const { dataSource } = useHistoryDataSource();

  const { setLoadingHistorico } = useHistoryResetFunctions();

  const { vehicles } = useGlobalVehicles();

  const { loading } = useSelector(state => state.monitoring);
  let { ais } = useSelector(state => state.monitoring);

  if (ais && ais.length > 0) {
    ais = ais.filter(a => a.nome.split(' ').length > 1);
  }

  const inputAis = useMemo(
    () => (
      <>
        {ais.map(a => (
          <Option key={a.id} value={`${a.nome.split(' ')[1]}_ais`}>
            {a.nome}
          </Option>
        ))}
        {bairrosCEFormatted.map(a => (
          <Option key={a.id + a.name} value={`${a.name}_bairro`}>
            {a.name} ({a.type})
          </Option>
        ))}
        {microregioesCEFormatted.map(a => (
          <Option key={a.id + a.name} value={`${a.name}_microregiao`}>
            {a.name} ({a.type})
          </Option>
        ))}
        {municipiosCEFormatted.map(a => (
          <Option key={a.id + a.name} value={`${a.name}_municipio`}>
            {a.name} ({a.type})
          </Option>
        ))}
      </>
    ),
    [ais]
  );

  const inputVehicles = useMemo(
    () => (
      <>
        {(Object.keys(vehicles) || []).map(k => {
          let value = vehicles[k].placa;

          value += vehicles[k].prefixo ? ` - ${vehicles[k].prefixo}` : '';

          return (
            <Option key={uuidv4()} value={value}>
              {value}
            </Option>
          );
        })}
      </>
    ),
    [vehicles]
  );

  const inputEquipamentos = useMemo(() => {
    return (
      <>
        {(Object.keys(vehicles) || []).map(k => {
          return (
            <Option key={uuidv4()} value={vehicles[k].equipamento}>
              {vehicles[k].equipamento}
            </Option>
          );
        })}
      </>
    );
  }, [vehicles]);

  const handleStartDraw = () => {
    setIsDrawing(true);
    onVisible(false);
  };

  const handleStartErase = () => {
    setIsErasing(true);
    onVisible(false);
  };

  const resetarCampos = () => {
    form.resetFields();
    setDtInicio('');
    setHrInicio('');
    setHrFim('');
  };

  const onFinish = useCallback(
    async values => {
      try {
        // const templatePolygons = {
        //   municipio: municipiosCEFormatted,
        //   bairro: bairrosCEFormatted,
        //   microregiao: microregioesCEFormatted,
        // };
        // const auxPolygons = [];
        // values.areasSelected.map(a => {
        //   const [name, type] = a.split('_');
        //   if (type === 'ais') {
        //     const newPol = {
        //       name: `AIS ${name}`,
        //       paths: dadosLatLngAis[parseInt(name, 10) - 1].map(coord => ({
        //         lat: coord[0],
        //         lng: coord[1],
        //       })),
        //     };
        //     auxPolygons.push(newPol);
        //     return newPol;
        //   }
        //   templatePolygons[type].map(p => {
        //     if (p.name === name) {
        //       const newPol = {
        //         name,
        //         paths: p.geometry.coordinates,
        //       };
        //       auxPolygons.push(newPol);
        //       return newPol;
        //     }
        //   });
        // });
        // setPolygonsCustomAudit(auxPolygons);
        // unmountComponentAtNode(openFiltersRef.current);
        // const type = e.split('_')[1];
        // resetAllData();
        // setFencesSelected([]);
        // setDataOccurrences([]);
        // const { aisSelected } = values;
        // const numberAis = aisSelected.substring(3, aisSelected?.length);
        // if (!dtInicio || !hrInicio || !hrFim) {
        //   toast.error('Por favor, preencha a data.');
        //   setLoadingHistorico(false);
        //   return;
        // }
        // const momentInicio = moment(new Date(`${dtInicio}T${hrInicio}`));
        // const momentFim = moment(new Date(`${dtInicio}T${hrFim}`));
        // if (momentFim.diff(momentInicio, 'hours') > 1) {
        //   toast.warn('Intervalo máximo de pesquisa é de 1 hora.');
        //   setLoadingHistorico(false);
        //   return;
        // }
        // const dataInicio = momentInicio.format('YYYY-MM-DDTHH:mm:ss');
        // const dataFim = momentFim.format('YYYY-MM-DDTHH:mm:ss');
        // await searchByAisAndDate({
        //   ais: numberAis,
        //   dataInicio,
        //   dataFim,
        // });
      } catch (err) {
        console.log('err', err);
        toast.info('Consulta sem resultado');
      } finally {
        setLoadingHistorico(false);
      }
    },
    [setLoadingHistorico]
  );

  return (
    <Container>
      <Spin spinning={loading}>
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Row>
            <Col offset={1} span={20}>
              <Form.Item
                label="Áreas (AIS, cercas, micro-regiöes ou municípios)"
                name="areasSelected"
              >
                <Select
                  getPopupContainer={trigger => trigger.parentElement}
                  mode="multiple"
                  virtual={false}
                  showSearch
                  style={{ maxWidth: '400px' }}
                  placeholder="Selecione as áreas"
                  filterOption={(input, option) => {
                    return (
                      option.value &&
                      option.value.toLowerCase().indexOf(input.toLowerCase()) >=
                        0
                    );
                  }}
                >
                  {inputAis}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row justify="center">
            <Col
              span={8}
              style={{
                marginTop: '7px',
              }}
            >
              <span
              // className="ant-form-item-required"
              >
                Desenhar área:
              </span>
            </Col>
            <Col span={5}>
              <Form.Item>
                <Button
                  className="filterButton drawButton"
                  // size="large"
                  style={{ width: '80px' }}
                  onClick={handleStartDraw}
                  icon={<EditOutlined />}
                  title="Desenhar"
                  ghost
                />
              </Form.Item>
            </Col>

            <Col span={6}>
              <Form.Item>
                <Button
                  className="filterButton cleanDrawButton"
                  // size="large"
                  style={{ width: '80px' }}
                  onClick={handleStartErase}
                  icon={<ClearOutlined />}
                  title="Apagar"
                  danger
                />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col offset={1} span={20}>
              <Form.Item label="Ocorrências" name="occurrencesSelected">
                <Select
                  getPopupContainer={trigger => trigger.parentElement}
                  virtual={false}
                  allowClear
                  placeholder="Informe as ocorrências"
                  mode="tags"
                  style={{
                    width: '100%',
                    height: '90%',
                    maxWidth: '400px',
                  }}
                  filterOption={(input, option) => {
                    return (
                      option.value &&
                      option.value.toLowerCase().indexOf(input.toLowerCase()) >=
                        0
                    );
                  }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col offset={1} span={20}>
              <Form.Item label="Viaturas" name="viaturasSelected">
                <Select
                  getPopupContainer={trigger => trigger.parentElement}
                  mode="multiple"
                  virtual={false}
                  showSearch
                  placeholder="Selecione as viaturas"
                  style={{ borderRadius: 5, maxWidth: '400px' }}
                  filterOption={(input, option) => {
                    return option.value;
                  }}
                >
                  {dataSource === 'COTIC' ? inputVehicles : inputEquipamentos}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col offset={1} style={{ marginBottom: 15 }}>
              <div
                className="ant-col ant-form-item-label"
                style={{
                  marginRight: '10px',
                  marginTop: '5px',
                }}
              >
                <span className="ant-form-item-required">Data Inicial:</span>
              </div>
              <span>
                <input
                  style={{
                    height: 30,
                    padding: 5,
                    borderRadius: 5,
                    border: '1px solid #d9d9d9',
                    marginRight: '3px',
                  }}
                  type="date"
                  id="dtInicio"
                  name="dtInicio"
                  step="1"
                  value={dtInicio}
                  onChange={e => {
                    setDtInicio(e.target.value);
                  }}
                />
                <input
                  style={{
                    height: 30,
                    padding: 5,
                    borderRadius: 5,
                    border: '1px solid #d9d9d9',
                  }}
                  type="time"
                  id="hrInicio"
                  name="hrInicio"
                  step="1"
                  value={hrInicio}
                  onChange={e => {
                    setHrInicio(e.target.value);
                  }}
                />
              </span>
            </Col>
          </Row>

          <Row>
            <Col centered offset={1} style={{ marginBottom: 15 }}>
              <div
                className="ant-col ant-form-item-label"
                style={{
                  marginRight: '15px',
                  marginTop: '5px',
                }}
              >
                <span
                  className="ant-form-item-required"
                  style={{
                    textAlign: 'center',
                  }}
                >
                  Data Final:
                </span>
              </div>
              <span>
                <input
                  style={{
                    height: 30,
                    padding: 5,
                    borderRadius: 5,
                    border: '1px solid #d9d9d9',
                    marginRight: '3px',
                  }}
                  type="date"
                  id="dtFim"
                  name="dtFim"
                  step="1"
                  value={dtInicio}
                  disabled
                />
                <input
                  style={{
                    height: 30,
                    padding: 5,
                    borderRadius: 5,
                    border: '1px solid #d9d9d9',
                  }}
                  type="time"
                  id="hrFim"
                  name="hrFim"
                  step="1"
                  value={hrFim}
                  onChange={e => {
                    setHrFim(e.target.value);
                  }}
                />
              </span>
            </Col>
          </Row>

          <Row justify="center" className="slotBotoes">
            <div className="grid-button">
              <Col>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="filterButton"
                  >
                    Buscar
                  </Button>
                </Form.Item>
              </Col>

              <Col>
                <Form.Item>
                  <Button
                    // type="reset"
                    // buttonStyle="btn--secondary"
                    className="filterButton cleanButton"
                    onClick={resetarCampos}
                  >
                    Limpar pesquisa
                  </Button>
                </Form.Item>
              </Col>
            </div>
          </Row>
        </Form>
      </Spin>
    </Container>
  );
}
