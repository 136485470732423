import { TrackingHistoryContext } from 'context/tracking_history';
import { useContextSelector } from 'use-context-selector';

export function useHistoryRenderFunctions() {
  const renderFencesOnHistoryMap = useContextSelector(
    TrackingHistoryContext,
    context => context.renderFencesOnHistoryMap
  );

  return {
    renderFencesOnHistoryMap,
  };
}

