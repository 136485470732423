import { Fence, LinkedDevices } from 'interfaces/fences';
import api from '../api';
import { ClientProps } from 'interfaces/auth';
import { string } from 'prop-types';

// nível de tolerancia para remasterizar polígonos
const tolerance = 0.0002;

export const ServiceCerca = {
  getCercaById: ({ id }: { id: string }): Promise<Fence> => {
    return api.get('/rotas/cerca/geometria', {
      params: { id },
    });
  },

  getCercaByUserId: ({
    page,
    size,
  }: {
    page: number;
    size: number;
  }): Promise<Fence[]> => {
    // rotas/cerca/geometria/page/all?page=0&size=10&email=ed@gmail.com
    return api.get(`/rotas/cerca/geometria/page/all?page=${page}&size=${size}`);
  },

  getCercaByPlate: (plate: string): Promise<string[]> => {
    return api.get(`/rotas/cerca/geometria/placa?placa=${plate}`);
  },

  createCerca: (newFence: Fence): Promise<void> => {
    return api.post('/rotas/cerca/geometria', newFence);
  },

  updateCerca: (id: string, newFence: Fence): Promise<void> => {
    return api.put(`/rotas/cerca/geometria?id=${id}`, {
      // id,
      ...newFence,
    });
  },

  deleteCerca: ({ id, nome }: { id: string; nome: string }): Promise<void> => {
    return api.delete(`/rotas/cerca/geometria?id=${id}&nome=${nome}`);
  },

  getCercaAll: (): Promise<Fence[]> => {
    return api.get('/rotas/cerca/geometria/all', {
      params: {
        tolerance,
      },
    });
  },

  getCercaAdminById: ({ id }: { id: string }): Promise<Fence> => {
    return api.get('/rotas/cerca/geometria/admin', {
      params: { id },
    });
  },

  getCercaAdminByUserId: ({
    page,
    size,
  }: {
    page: number;
    size: number;
  }): Promise<Fence[]> => {
    // rotas/cerca/geometria/page/all?page=0&size=10&email=ed@gmail.com
    return api.get(
      `/rotas/cerca/geometria/admin/page/all?page=${page}&size=${size}`
    );
  },

  createAdminCerca: (newFence: Fence): Promise<void> => {
    return api.post('/rotas/cerca/geometria/admin', newFence);
  },

  updateAdminCerca: (id: string, newFence: Fence): Promise<void> => {
    return api.put(`/rotas/cerca/geometria/admin?id=${id}`, {
      // id,
      ...newFence,
    });
  },

  shareAdminCercaToCPFList: ({
    cpfList,
    cercasList,
  }: {
    cpfList: string[];
    cercasList: string[];
  }): Promise<void> => {
    return api.post(
      `/rotas/cerca/geometria/admin/add-users-to-some-cercas`,
      {
        cpfList,
        cercasList,
      },
      {
        timeout: 60 * 1000,
      }
    );
  },

  deleteAdminCerca: ({
    id,
    nome,
  }: {
    id: string;
    nome: string;
  }): Promise<void> => {
    return api.delete(`/rotas/cerca/geometria/admin?id=${id}&nome=${nome}`);
  },

  getCercaAdminAll: (): Promise<Fence[]> => {
    return api.get('/rotas/cerca/geometria/admin/all', {
      params: {
        tolerance,
      },
    });
  },

  searchByCPF: (cpf: string): Promise<ClientProps> => {
    return api.get(`/rotas/cerca/user?cpf=${cpf}`);
  },

  searchCerca: ({
    page,
    size,
    nome,
  }: {
    page: number;
    size: number;
    nome: string;
  }): Promise<Fence[]> => {
    return api.get('/rotas/cerca/geometria/search', {
      params: { page, size, nome },
    });
  },

  searchAdminCerca: ({
    page,
    size,
    nome,
  }: {
    page: number;
    size: number;
    nome: string;
  }): Promise<Fence[]> => {
    return api.get('/rotas/cerca/geometria/admin/search', {
      params: { page, size, nome },
    });
  },

  updateVeiculosCerca: (id: string, veiculosAssociados: LinkedDevices[]) => {
    return api.put(
      `/rotas/cerca/geometria/viaturas-associadas`,
      {
        veiculosAssociados,
      },
      {
        params: {
          id,
        },
      }
    );
  },
};
