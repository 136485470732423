import { DatePicker, Form, Modal, Select } from 'antd';
import style from '../style.module.css';
import React, { useCallback } from 'react';
import { allAis } from 'utils/functionsUtils';
import Button from 'components/button';
import { toast } from 'react-toastify';
import moment from 'moment';

export type TModal = {
  open: boolean;
  setModalVisible: (value: boolean) => void;
  handleConfirmModal: (ais: string[], period: string[]) => void;
};

const { RangePicker } = DatePicker;

export const FormUploadModal = (props: TModal) => {
  const { open, setModalVisible, handleConfirmModal } = props;

  const [form] = Form.useForm();

  const handleFinishForm = useCallback(
    (values: any) => {
      const { ais, period } = values;

      if (!period || period.length !== 2 || !period[0]) {
        toast.error('Por favor informe a data inicial');
        return;
      }

      const dataInicial = moment(new Date(period[0])).format('YYYY-MM-DD');
      let dataFinal = '';

      if (period[1]) {
        dataFinal = moment(new Date(period[1])).format('YYYY-MM-DD');
      }

      setModalVisible(false);
      handleConfirmModal(ais, [dataInicial, dataFinal]);
    },
    [handleConfirmModal]
  );

  return (
    <Modal
      destroyOnClose
      onCancel={() => {
        setModalVisible(false);
      }}
      open={open}
      footer={null}
    >
      <div style={{ height: 500 }}>
        <h1>Informações Complementares</h1>
        <h4 style={{ color: 'orange' }}>
          As informações adicionadas neste formulário serão aplicadas a todas as
          cercas não-salvas
        </h4>
        <div
          style={{
            width: 470,
            height: 450,
            display: 'flex',
            flexDirection: 'column',
            // justifyContent: 'center',
            marginTop: '40px',
          }}
        >
          <Form
            layout="vertical"
            form={form}
            onFinish={handleFinishForm}
            style={{
              width: '100%',

              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Form.Item
              style={{ width: '100%' }}
              label="AIS Associadas"
              name="ais"
            >
              <Select
                virtual={false}
                getPopupContainer={trigger => trigger.parentElement}
                mode="multiple"
                allowClear
                style={{ width: '100%' }}
                options={allAis}
                showArrow
              />
            </Form.Item>
            <Form.Item
              style={{ width: '100%' }}
              label="Periodo"
              name="period"
              //   rules={[
              //     {
              //       required: false,
              //       message: 'Informe um periodo de vigência',
              //     },
              //   ]}
              required
            >
              <RangePicker
                style={{ width: '100%' }}
                className={style.rangePicker}
                showTime={false}
                size="large"
                format={'DD/MM/YYYY'}
                allowEmpty={[false, true]}
              />
            </Form.Item>
            <Form.Item style={{ position: 'absolute', bottom: 0 }}>
              <Button type="submit" buttonStyle="btn--primary">
                Confirmar
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </Modal>
  );
};

