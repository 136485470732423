import React from 'react';

import { MonitoringProvider } from 'context/monitoring';
import { TrackingHistoryProvider } from 'context/tracking_history';
import { FiltersMapProvider } from 'context/mapa/filters_map';

import ContainerMapa from '../../components/container_mapa';

import './styles.css';

export default function ContainerPrincipal() {
  return (
    <FiltersMapProvider>
      <MonitoringProvider>
        <TrackingHistoryProvider>
          <div id="corpoPagina">
            <ContainerMapa />
          </div>
        </TrackingHistoryProvider>
      </MonitoringProvider>
    </FiltersMapProvider>
  );
}

