/* eslint-disable */
import React from 'react';
import { Button } from 'antd';
import { MapTrifold } from 'phosphor-react';

export default function columns({ setIsModalOpen, setMapPosition }) {
  return [
    {
      title: <b>Placa</b>,
      dataIndex: 'placa',
      sorter: (a, b) => (a.placa < b.placa ? -1 : a.placa > b.placa ? 1 : 0),
    },
    {
      title: <b> Prefixo</b>,
      dataIndex: 'prefixo',
      sorter: (a, b) =>
        a.prefixo < b.prefixo ? -1 : a.prefixo > b.prefixo ? 1 : 0,
    },
    {
      title: <b> Agencia</b>,
      dataIndex: 'agencia',
      sorter: (a, b) =>
        a.agencia < b.agencia ? -1 : a.agencia > b.agencia ? 1 : 0,
      responsive: ['sm'],
    },
    {
      title: <b> Latitude</b>,
      dataIndex: 'latitude',
      sorter: (a, b) =>
        a.latitude < b.latitude ? -1 : a.latitude > b.latitude ? 1 : 0,
      responsive: ['xl'],
    },
    {
      title: <b> Longitude</b>,
      dataIndex: 'longitude',
      sorter: (a, b) =>
        a.longitude < b.longitude ? -1 : a.longitude > b.longitude ? 1 : 0,
      responsive: ['xl'],
    },

    {
      title: <b> Ultima Posição</b>,
      dataIndex: 'datePosition',
      sorter: (a, b) =>
        a.datePosition < b.datePosition
          ? -1
          : a.datePosition > b.datePosition
          ? 1
          : 0,
      responsive: ['sm'],
      render: text =>
        `${new Date(text).toLocaleDateString()} ${new Date(
          text
        ).toLocaleTimeString()}`,
    },
    {
      title: <b> Logradouro </b>,
      dataIndex: 'logradouro',
      align: 'center',
      render: (text, record) => {
        const lat = record.latitude;
        const lng = record.longitude;
        return (
          <Button
            style={{
              border: 'none',
              backgroundColor: 'none',
            }}
            onClick={() => {
              setIsModalOpen(true);
              setMapPosition([lat, lng]);
            }}
          >
            <MapTrifold color="#18A779" size={36} />
          </Button>
        );
      },
    },
  ];
}

