import React, { useState, useEffect, useCallback } from 'react';
import { GoogleMap, Marker } from '@react-google-maps/api';
import {
  Table,
  Spin,
  Modal,
  Row,
  Typography,
  Col,
  Progress,
  Divider,
  Button as AntBtn,
} from 'antd';
import { Envelope, Printer } from 'phosphor-react';
import { CSVLink } from 'react-csv';
import { Container } from '../../pages/PDF/styles';
import { PositionVehicle } from 'interfaces/vehicle';
import { LoadFormattedAddress } from 'utils/functionsUtils';
import { ServiceExterno } from 'services/modulos/serviceExterno';
import { useAuthData } from 'hooks/auth/useAuthData';
import { useLocation } from 'react-router-dom';
import ENV from 'config';
interface pdfImprimirProps {
  dadosLast: PositionVehicle[];
  loadingTable: boolean;
  columnWithModal: any;
  columns: any;
  pdfGen: any;
  isLoaded: any;
}

export default function PdfImprimir({
  dadosLast,
  loadingTable,
  columnWithModal,
  columns,
  pdfGen,
  isLoaded,
}: pdfImprimirProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [mapPosition, setMapPosition] = useState([0, 0]);
  const [csvData, setCsvData] = useState<string[][]>([]);
  // const [addressPDF, setAddressPDF] = useState();
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [percent, setPercent] = useState(0);
  const [isModalRelOpen, setIsModalRelOpen] = useState(false);
  const [address, setAddress] = useState('');
  const { Text, Title } = Typography;

  const { data: dataAuth } = useAuthData();
  const { client } = dataAuth;

  const location = useLocation();

  const containerStyle = {
    width: '100%',
    height: '100%',
    borderRadius: 12,
  };

  const returnAddress = useCallback(async (lat: number, lng: number) => {
    const address = await LoadFormattedAddress(lat, lng);
    setAddress(address);
  }, []);

  useEffect(() => {
    if (mapPosition[0] != 0 || mapPosition[1] != 0) {
      if (isModalOpen) {
        returnAddress(mapPosition[0], mapPosition[1]);
      }
    }
  }, [isModalOpen, mapPosition, returnAddress]);

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const loadCsvData = useCallback(async () => {
    try {
      setIsModalRelOpen(true);
      setIsLoadingData(true);
      const range = (dadosLast || []).length;
      let per = 0;
      let counter = 0;

      // Registrando usuário que está gerando relatório
      ServiceExterno.generateReport({
        name: client.name,
        cpf: client.cpf,
        externPath: 'Google Geocoding API',
        pathName: location.pathname,
      });

      // @ts-ignore
      const auxData: string[][] = await Promise.all(
        dadosLast.map(async x => {
          const currentLogradouro = await LoadFormattedAddress(
            x.latitude,
            x.longitude
          );
          // console.log(x);
          counter += 1;
          per = (counter * 100) / range;
          setPercent(parseInt(per + '', 10));
          if (counter >= range) {
            setIsLoadingData(false);
          }

          return [
            x.placa,
            x.prefixo,
            x.agencia,
            x.latitude,
            x.longitude,
            `${new Date(x.datePosition).toLocaleDateString()} ${new Date(
              x.datePosition
            ).toLocaleTimeString()}`,
            currentLogradouro,
          ];
        })
      );
      setCsvData(auxData);
    } catch (err) {
      setIsLoadingData(false);
    }
  }, [dadosLast]);

  const csvHeader = [
    'Placa',
    'Prefixo',
    'Agencia',
    'Latitude',
    'Longitude',
    'Ultima Posição',
    'Logradouro',
  ];

  return (
    <>
      {loadingTable ? (
        <Spin size="small" className="slotBtnDocCsvPersonalizado" />
      ) : (
        <AntBtn
          className="slotBtnDocCsvPersonalizado"
          // headers={csvHeader}
          // data={csvData || []}
          // filename={'Planilha Personalizada'}
          // target="_blank"
          // asyncOnClick={true}
          onClick={loadCsvData}
          style={{
            padding: '5px',
            background: 'white',
            border: '1px solid rgb(26, 120, 74)',
            color: 'green',
          }}
        >
          Gerar Relatório
        </AntBtn>
      )}
      <Modal
        open={isModalRelOpen}
        destroyOnClose
        onCancel={() => {
          setIsModalRelOpen(false);
        }}
        footer={null}
      >
        <Row>
          <Printer size={32} weight="light" />
          <Title
            level={4}
            style={{
              verticalAlign: 'middle',
            }}
          >
            {' '}
            Gerar Relatório
          </Title>
        </Row>
        <Divider />

        {isLoadingData ? (
          <div>
            <Row
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
              }}
            >
              <Progress strokeColor="green" type="circle" percent={percent} />
            </Row>
            <Row
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
              }}
            >
              {' '}
              Carregando dados...
            </Row>
          </div>
        ) : (
          <Row>
            <Col span={12}>
              <AntBtn onClick={() => pdfGen(csvData)}>Gerar PDF</AntBtn>
            </Col>
            <Col span={12}>
              <AntBtn>
                {/* @ts-expect-error Server Component */}
                <CSVLink
                  headers={csvHeader}
                  data={csvData}
                  filename="Relatórios Rotas"
                  target="_blank"
                >
                  Gerar CSV
                </CSVLink>
              </AntBtn>
            </Col>
          </Row>
        )}
      </Modal>
      <Container>
        <Spin tip="Carregando..." spinning={loadingTable}>
          <Table
            className="tablePersonalizada"
            columns={
              columnWithModal
                ? columns({ setIsModalOpen, setMapPosition })
                : columns
            }
            dataSource={dadosLast}
            style={{ marginTop: 18 }}
            rowKey="prefixo"
          />
        </Spin>
      </Container>
      <Modal
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        width={1000}
      >
        <Row
          style={{
            marginTop: '1rem',
            marginBottom: '1rem',
          }}
        >
          <Text strong>Endereço: </Text>
          <Text italic>{address}</Text>
        </Row>
        <Row>
          <div
            style={{
              width: 1000,
              height: 450,
            }}
          >
            {isLoaded && (
              <GoogleMap
                id={ENV.mapId}
                mapContainerStyle={containerStyle}
                center={{
                  lat: mapPosition[0],
                  lng: mapPosition[1],
                }}
                zoom={15}
              >
                <Marker
                  position={{
                    lat: mapPosition[0],
                    lng: mapPosition[1],
                  }}
                />
              </GoogleMap>
            )}
          </div>
        </Row>
      </Modal>
    </>
  );
}

