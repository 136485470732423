import React, { ComponentType, ReactNode } from 'react';
import { useHistory, Route, RouteComponentProps } from 'react-router-dom';
import { StaticContext } from 'react-router';
import { AuthProps } from 'interfaces/auth';
import { useAuthData } from 'hooks/auth/useAuthData';

interface ProtectedRoutesProps {
  requiredRoles: string[];
  path: string;
  exact: boolean;
  component:
    | ComponentType<any>
    | ComponentType<RouteComponentProps<any, StaticContext, unknown>>
    | undefined;
}

const ProtectedRoute = ({
  requiredRoles,
  path,
  exact,
  component,
}: ProtectedRoutesProps) => {
  const { data } = useAuthData();
  const { roles } = data || {};

  const history = useHistory();

  if (requiredRoles.some(role => roles?.includes(role))) {
    /* @ts-expect-error Server Component */
    return <Route path={path} exact={exact} component={component} />;
  }

  history.push('/');

  return null;
};

export default ProtectedRoute;

