import { TrackingHistoryContext } from 'context/tracking_history';
import { useContextSelector } from 'use-context-selector';

export function useSelectedPosicaoEfetivo() {
  const selectedPosicaoEfetivo = useContextSelector(
    TrackingHistoryContext,
    context => context.selectedPosicaoEfetivo
  );

  const setSelectedPosicaoEfetivo = useContextSelector(
    TrackingHistoryContext,
    context => context.setSelectedPosicaoEfetivo
  );

  return {
    selectedPosicaoEfetivo,
    setSelectedPosicaoEfetivo,
  };
}

