import React, { useCallback, useMemo, useState } from 'react';
import { Form, Select, Row, Col, Spin } from 'antd';
import { toast } from 'react-toastify';
import moment from 'moment';
import Button from '../../../button';
import { v4 as uuidv4 } from 'uuid';

import { Container } from './styles';
import { useHistoryFormInfo } from 'hooks/monitoring/useHistoryFormInfo';
import { useGlobalVehicles } from 'hooks/global/useGlobalVehicles';
import { useHistoryResetFunctions } from 'hooks/history/useHistoryResetFunctions';
import { useHistorySeachByPlatesAndDate } from 'hooks/history/useHistorySeachByPlatesAndDate';
import { useSelectedPosicaoEfetivo } from 'hooks/history/useSelectedPosicaoEfetivo';
import { useHistoryDataSource } from 'hooks/history/useHistoryDataSource';
import debounce from 'lodash.debounce';

const { Option } = Select;

const INTERVALO_MAXIMO_PESQUISA = 24 * 60 * 60;
interface FormHistoryProps {
  onVisible: (value: boolean) => void;
}

export function FormHistoryEfetivo({ onVisible }: FormHistoryProps) {
  const [form] = Form.useForm();
  const [dtInicio, setDtInicio] = useState('');
  const [hrInicio, sethrInicio] = useState('');
  const [dtFim, setDtFim] = useState('');
  const [hrFim, sethrFim] = useState('');

  const { dataSource } = useHistoryDataSource();
  const { setDataOccurrences } = useHistoryFormInfo();
  const { searchByPlatesAndDate } = useHistorySeachByPlatesAndDate();

  const { setSelectedPosicaoEfetivo } = useSelectedPosicaoEfetivo();

  const { setLoadingHistorico } = useHistoryResetFunctions();

  const {
    cleanSelectedAis,
    resetAllData,
    setChoosingOrigin,
  } = useHistoryResetFunctions();

  const { vehicles } = useGlobalVehicles();

  const handleChangeDtInicio = useCallback(
    (value: string) => {
      setDtInicio(value);

      if (!dtFim) {
        setDtFim(value);
      }
    },
    [dtFim]
  );

  const debounceChangeDate = debounce((e: any) => {
    handleChangeDtInicio(e.target.value);
  }, 500);

  const onFinish = useCallback(
    async (values: { placas: string[] }) => {
      try {
        resetAllData();
        cleanSelectedAis();
        setDataOccurrences([]);
        setSelectedPosicaoEfetivo('');
        setChoosingOrigin(false);
        const { placas } = values;

        if (!dtInicio || !hrFim || !hrInicio || !dtFim) {
          toast.info('Por favor, preencha a data inicio e fim.');
          setLoadingHistorico(false);
          return;
        }

        const momentInicio = moment(new Date(`${dtInicio}T${hrInicio}`));
        const momentFim = moment(new Date(`${dtFim}T${hrFim}`));

        if (momentFim.isBefore(momentInicio)) {
          toast.info('Data inválida');
          setLoadingHistorico(false);

          return;
        }

        if (
          momentFim.diff(momentInicio, 'seconds') > INTERVALO_MAXIMO_PESQUISA
        ) {
          toast.info('Intervalo máximo de pesquisa são 24 horas');
          setLoadingHistorico(false);

          return;
        }

        const dataInicio = momentInicio.format('YYYY-MM-DDTHH:mm:ss');

        const dataFim = momentFim.format('YYYY-MM-DDTHH:mm:ss');

        await searchByPlatesAndDate({
          placas: placas.map(p => p.toUpperCase()),
          dataInicio,
          dataFim,
        });

        onVisible(false);
      } catch (err) {
        toast.info('Consulta sem resultado');

        setLoadingHistorico(false);
      }
    },
    [
      resetAllData,
      cleanSelectedAis,
      setDataOccurrences,
      setSelectedPosicaoEfetivo,
      setChoosingOrigin,
      dtInicio,
      dtFim,
      hrFim,
      hrInicio,
      searchByPlatesAndDate,
      onVisible,
      setLoadingHistorico,
    ]
  );

  const inputPlacas = useMemo(() => {
    return (
      <>
        {(Object.keys(vehicles) || []).map(k => {
          let value = vehicles[k].placa;

          value +=
            vehicles[k].prefixo && vehicles[k].prefixo !== vehicles[k].placa
              ? ` - ${vehicles[k].prefixo}`
              : '';

          return (
            <Option key={uuidv4()} value={vehicles[k].placa}>
              {value}
            </Option>
          );
        })}
      </>
    );
  }, [vehicles]);

  const inputEquipamentos = useMemo(() => {
    return (
      <>
        {(Object.keys(vehicles) || []).map(k => {
          return (
            <Option key={uuidv4()} value={vehicles[k].equipamento}>
              {vehicles[k].equipamento}
            </Option>
          );
        })}
      </>
    );
  }, [vehicles]);

  const resetFields = () => {
    form.resetFields();
    resetAllData();
    cleanSelectedAis();
    setDtInicio('');
    sethrInicio('');
    sethrFim('');
  };

  return (
    <Container>
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Row>
          <Col offset={1}>
            <Form.Item
              label={
                dataSource === 'COTIC'
                  ? 'Placas / Prefixos / Equipamentos'
                  : 'Equipamentos'
              }
              name="placas"
              rules={[
                {
                  required: true,
                  message: 'Obrigatório',
                },
              ]}
              required
            >
              <Select
                getPopupContainer={trigger => trigger.parentElement}
                virtual={false} // Lista muito grande, o virtual não funciona
                mode="tags"
                showSearch
                style={{ width: 200 }}
                placeholder={
                  dataSource === 'COTIC'
                    ? 'Informe a(s) placa(s)'
                    : 'Informe o(s) equipamento(s)'
                }
                notFoundContent={null}
                filterOption={(input, option) =>
                  Boolean(
                    option?.children &&
                      (option.children || '')
                        .toString()
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                  )
                }
              >
                {dataSource === 'COTIC' ? inputPlacas : inputEquipamentos}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Col offset={1} style={{ marginBottom: 15 }}>
            <div className="ant-col ant-form-item-label">
              <span className="ant-form-item-required">Data inicial:</span>
            </div>
            <br />
            <span>
              <input
                style={{
                  height: 30,
                  padding: 5,
                  borderRadius: 10,
                  border: '1px solid #d9d9d9',
                }}
                type="date"
                id="dtInicio"
                name="dtInicio"
                step="1"
                // value={dtInicio}
                onChange={debounceChangeDate}
              />
              <input
                style={{
                  height: 30,
                  padding: 5,
                  borderRadius: 10,
                  border: '1px solid #d9d9d9',
                }}
                type="time"
                id="hrInicio"
                name="hrInicio"
                step="1"
                value={hrInicio}
                onChange={e => {
                  sethrInicio(e.target.value);
                }}
              />
            </span>
          </Col>
          <Col offset={1} style={{ marginBottom: 15 }}>
            <div className="ant-col ant-form-item-label">
              <span className="ant-form-item-required">Data final:</span>
            </div>
            <br />
            <span>
              <input
                style={{
                  height: 30,
                  padding: 5,
                  borderRadius: 10,
                  border: '1px solid #d9d9d9',
                }}
                type="date"
                id="dtFim"
                name="dtFim"
                step="1"
                value={dtFim}
                onChange={e => {
                  setDtFim(e.target.value);
                }}
              />
              <input
                style={{
                  height: 30,
                  padding: 5,
                  borderRadius: 10,
                  border: '1px solid #d9d9d9',
                }}
                type="time"
                id="meeting-time2"
                name="meeting-time"
                step="1"
                value={hrFim}
                onChange={e => {
                  sethrFim(e.target.value);
                }}
              />
            </span>
          </Col>
        </Row>

        <Row justify="center" className="slotBotoes">
          <div className="grid-button">
            <Col>
              <Form.Item>
                <Button type="submit">Buscar</Button>
              </Form.Item>
            </Col>

            <Col>
              <Form.Item>
                <Button
                  type="button"
                  buttonStyle="btn--secondary"
                  onClick={resetFields}
                >
                  Limpar
                </Button>
              </Form.Item>
            </Col>
          </div>
        </Row>
      </Form>
    </Container>
  );
}
