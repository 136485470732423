import React from 'react';



import { SelectCustom } from './styles';



export const SelectAddItemAntd = ({ children, ...props }) => {

  return (

    <SelectCustom

      getPopupContainer={trigger => trigger.parentElement}

      {...props}

    >

      {children}

    </SelectCustom>

  );

};

