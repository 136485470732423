/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { PageHeader as Header } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';

import { useHistory } from 'react-router-dom';
import Button from '../button';
import { formatRoutesByPath } from '../../utils/mapRoutesByPath';

import { Container } from '../../pages/Veiculo/Cadastro/styles';

export function PageHeader({
  onNewRegister,
  title,
  subTitle,
  activeBackHistory,
  baseRole,
}) {
  const history = useHistory();
  const { pathname } = history.location || {};

  const { routes, itemRender } = pathname ? formatRoutesByPath(pathname) : {};
  const extra = [];

  if (onNewRegister && baseRole) {
    extra.push(
      <Container key={Date.now()}>
        {/* <Button
          className="botao-salvar"
          key="novoBotao"
          type="primary"
          icon={<PlusOutlined />}
          onClick={onNewRegister}
        >
          Novo
        </Button> */}
        <Button
          onClick={onNewRegister}
          type="button"
          buttonStyle="btn--primary"
          icon={<PlusOutlined />}
          children="Novo"
        />
      </Container>
    );
  }
  const props = {};

  if (activeBackHistory) {
    props.onBack = () => history.goBack();
  }

  return (
    <Header
      // breadcrumb={{ routes, itemRender }}
      title={title}
      subTitle={subTitle}
      extra={extra}
      {...props}
    />
  );
}

PageHeader.propTypes = {
  onNewRegister: PropTypes.func,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  activeBackHistory: PropTypes.bool,
  baseRole: PropTypes.string,
};

PageHeader.defaultProps = {
  onNewRegister: null,
  baseRole: null,
  activeBackHistory: false,
};
