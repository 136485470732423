import { FiltersMapContext } from 'context/mapa/filters_map';
import { useContextSelector } from 'use-context-selector';

export function useFiltersMapLoadingAis() {
  const loadingAis = useContextSelector(
    FiltersMapContext,
    context => context.loadingAis
  );

  const setLoadingAis = useContextSelector(
    FiltersMapContext,
    context => context.setLoadingAis
  );

  return {
    loadingAis,
    setLoadingAis,
  };
}

