import { FiltersMapContext } from 'context/mapa/filters_map';
import { useContextSelector } from 'use-context-selector';

export function useFiltersMapAgencies() {
  const selectedAgencies = useContextSelector(
    FiltersMapContext,
    context => context.selectedAgencies
  );

  const setSelectedAgencies = useContextSelector(
    FiltersMapContext,
    context => context.setSelectedAgencies
  );

  return {
    selectedAgencies,
    setSelectedAgencies,
  };
}

