import { MonitoringContext } from 'context/monitoring';
import { useContextSelector } from 'use-context-selector';

export function useAlertModalInfo() {
  const alertsHistory = useContextSelector(
    MonitoringContext,
    context => context.alertsHistory
  );

  return {
    alertsHistory,
  };
}

