import { useJsApiLoader } from '@react-google-maps/api';
import React from 'react';
import { TablePersonalizada } from 'components/personalizedTable';
import { FiltersMapProvider } from 'context/mapa/filters_map';
import { TrackingHistoryProvider } from 'context/tracking_history';
import { MonitoringProvider } from 'context/monitoring';
import ENV from 'config';
import { googleLibraries } from 'utils/functionsUtils';

export function PdfPersonalizado() {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: ENV.googleMapsApiKey,
    libraries: googleLibraries,
    region: 'BR',
    language: 'pt-br',
  });

  return (
    <FiltersMapProvider>
      <MonitoringProvider>
        <TrackingHistoryProvider>
          <TablePersonalizada isLoaded={isLoaded} />
        </TrackingHistoryProvider>
      </MonitoringProvider>
    </FiltersMapProvider>
  );
}

