import { MonitoringContext } from 'context/monitoring';
import { useContextSelector } from 'use-context-selector';

export function useFenceFilterInfo() {
  const fencesSelected = useContextSelector(
    MonitoringContext,
    context => context.fencesSelected
  );

  const setLoadingFences = useContextSelector(
    MonitoringContext,
    context => context.setLoadingFences
  );

  const vehiclesOfSelectedFences = useContextSelector(
    MonitoringContext,
    context => context.vehiclesOfSelectedFences
  );

  const handleLoadData = useContextSelector(
    MonitoringContext,
    context => context.handleLoadData
  );

  return {
    fencesSelected,
    setLoadingFences,
    vehiclesOfSelectedFences,
    handleLoadData,
  };
}

