/* eslint-disable jsx-a11y/click-events-have-key-events */

/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useCallback, useEffect, useState } from 'react';

import {
  Card,
  Col,
  Input,
  Row,
  Table,
  Breadcrumb,
  Form,
  DatePicker,
  Select,
  Space,
} from 'antd';
import moment from 'moment';
import { Link } from 'react-router-dom';

import { useRef } from 'react';
import { toast } from 'react-toastify/dist';
import { templateColumns } from '~/columns/ocorrencia/columns';

import Button from '../../../../components/button';

import { ServiceOcorrencia } from '~/services/modulos/serviceOcorrencia';
import { useAuthData } from 'hooks/auth/useAuthData';

const PAGE_SIZE = 10;
const now = new Date();

export function Ocorrencias() {
  const [form] = Form.useForm();
  const { RangePicker } = DatePicker;

  const { data } = useAuthData();
  const { roles } = data;

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const [dados, setDados] = useState();
  const [loadingSpin, setLoadingSpin] = useState(false);
  const [valuesForm, setValuesForm] = useState();

  const totalElements = useRef();

  let groupsOptions = [
    {
      value: '',
      label: '',
    },
    {
      value: 'COTAM',
      label: 'COTAM',
    },
    {
      value: 'FORTA',
      label: 'FORTA',
    },
    {
      value: 'COTAR',
      label: 'COTAR',
    },
    {
      value: 'COD',
      label: 'COD',
    },
    {
      value: 'GOE',
      label: 'GOE',
    },
    {
      value: 'BOPE',
      label: 'BOPE',
    },
    {
      value: 'BPRE',
      label: 'BPRE',
    },
    {
      value: 'BPGEP',
      label: 'BPGEP',
    },
    {
      value: 'BPTUR',
      label: 'BPTUR',
    },
    {
      value: 'CHOQUE',
      label: 'CHOQUE',
    },
    {
      value: 'COLOG',
      label: 'COLOG',
    },
    {
      value: 'RAIO',
      label: 'RAIO',
    },
    {
      value: 'BPE',
      label: 'BPE',
    },
    {
      value: 'CPC',
      label: 'CPC',
    },
    {
      value: 'CPG',
      label: 'CPG',
    },
    {
      value: 'BPMA',
      label: 'BPMA',
    },
    {
      value: 'METRO',
      label: 'METRO',
    },
    {
      value: 'INTER',
      label: 'INTER',
    },
    {
      value: 'AIS01',
      label: 'AIS01',
    },
    {
      value: 'AIS02',
      label: 'AIS02',
    },
    {
      value: 'AIS03',
      label: 'AIS03',
    },
    {
      value: 'AIS04',
      label: 'AIS04',
    },
    {
      value: 'AIS05',
      label: 'AIS05',
    },
    {
      value: 'AIS06',
      label: 'AIS06',
    },
    {
      value: 'AIS07',
      label: 'AIS07',
    },
    {
      value: 'AIS08',
      label: 'AIS08',
    },
    {
      value: 'AIS09',
      label: 'AIS09',
    },
    {
      value: 'AIS10',
      label: 'AIS10',
    },
    {
      value: 'AIS11',
      label: 'AIS11',
    },
    {
      value: 'AIS12',
      label: 'AIS12',
    },
    {
      value: 'AIS13',
      label: 'AIS13',
    },
    {
      value: 'AIS14',
      label: 'AIS14',
    },
    {
      value: 'AIS15',
      label: 'AIS15',
    },
    {
      value: 'AIS16',
      label: 'AIS16',
    },
    {
      value: 'AIS17',
      label: 'AIS17',
    },
    {
      value: 'AIS18',
      label: 'AIS18',
    },
    {
      value: 'AIS19',
      label: 'AIS19',
    },
    {
      value: 'AIS20',
      label: 'AIS20',
    },
    {
      value: 'AIS21',
      label: 'AIS21',
    },
    {
      value: 'AIS22',
      label: 'AIS22',
    },
    {
      value: 'AIS23',
      label: 'AIS23',
    },
    {
      value: 'AIS24',
      label: 'AIS24',
    },
    {
      value: 'AIS25',
      label: 'AIS25',
    },
  ];

  const rolesFiltered = roles.map(r =>
    r.replaceAll('ROLE_ROTAS_', '').replaceAll('_', '')
  );

  groupsOptions = groupsOptions.filter(op => {
    if (op.value === '') return true;

    return rolesFiltered.includes(op.value);
  });

  const handleTableChange = useCallback(pagination => {
    const { pageSize: pageSizeTable } = pagination;
    setPageSize(pageSizeTable);
  }, []);

  const initFetch = useCallback(async page => {
    try {
      setLoadingSpin(true);
      const auxDados = await ServiceOcorrencia.getList({
        page: page - 1,
        size: pageSize,
        dataInicial: `${now.toISOString().split('T')[0]}T${'00:00:00'}.000Z`,
        dataFinal: `${now.toISOString().split('T')[0]}T${'23:59:59'}.000Z`,
      });
      totalElements.current = auxDados.totalElements;
      setDados(auxDados.content);
    } catch (e) {
      toast.error('Error ao carregar página');
    } finally {
      setLoadingSpin(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   initFetch(page);
  // }, [initFetch, page]);

  const getFormData = useCallback(
    async (dateInitial, dateFinal, page, codigo, grupo, tipo) => {
      try {
        setLoadingSpin(true);
        let updateObj = {};
        if (codigo) {
          updateObj.codigo = codigo;
        }
        if (grupo) {
          updateObj.grupo = grupo;
        }
        if (tipo) {
          updateObj.tipo = tipo;
        }
        const allData = await ServiceOcorrencia.getList({
          page: page - 1,
          size: pageSize,
          dataInicial: dateInitial,
          dataFinal: dateFinal,
          updateObj,
        });
        totalElements.current = allData.totalElements;
        return allData.content;
      } catch (e) {
        toast.error('Error ao carregar página');
      } finally {
        setLoadingSpin(false);
      }
    },
    [pageSize]
  );

  const dataSearch = useCallback(
    async valuesForm => {
      try {
        // setLoadingSpin(true);
        setValuesForm(valuesForm);
        let valuesFiltered;
        if (valuesForm) {
          valuesFiltered = Object.keys(valuesForm).filter(r => valuesForm[r]);
        }
        if (valuesFiltered?.length > 0) {
          let formData = [];
          const filters = {
            codigo: valuesForm.codigo ? valuesForm.codigo.toUpperCase() : null,
            grupo: valuesForm.grupo ? valuesForm.grupo.toUpperCase() : null,
            tipo: valuesForm.tipo ? valuesForm.tipo.toUpperCase() : null,
            dataInicial:
              valuesForm.date && valuesForm.date.length !== 0
                ? moment(valuesForm.date[0])
                : null,
            dataFinal:
              valuesForm.date && valuesForm.date.length !== 0
                ? moment(valuesForm.date[1])
                : null,
          };

          const dataInicialAux = filters.dataInicial
            ? filters.dataInicial.toISOString()
            : `${now.toISOString().split('T')[0]}T${'00:00:00'}`;
          const dataFinalAux = filters.dataFinal
            ? filters.dataFinal.toISOString()
            : `${now.toISOString().split('T')[0]}T${'23:59:59'}`;

          formData = await getFormData(
            dataInicialAux,
            dataFinalAux,
            page,
            filters.codigo,
            filters.grupo,
            filters.tipo
          );

          setDados(formData);
        } else {
          initFetch(page);
        }
      } catch (e) {
        toast.error('Erro ao carregar os dados');
      } finally {
        // setLoadingSpin(false);
      }
    },
    [getFormData, initFetch, page]
  );

  const onReset = () => {
    form.resetFields();
    setPage(1);
    initFetch(page);
  };

  useEffect(() => {
    dataSearch(valuesForm);
  }, [dataSearch, page, valuesForm]);

  const onSearch = useCallback(value => {
    if (page === 1) {
      dataSearch(value);
    } else {
      setPage(1);
    }
  }, []);

  return (
    <div>
      <Breadcrumb style={{ padding: '10px' }}>
        <Breadcrumb.Item>
          <Link to="/">Página Inicial</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Ocorrências</Breadcrumb.Item>
      </Breadcrumb>
      <Card>
        <div
          style={{
            border: '1px solid #00aa3f',
            borderRadius: '15px',
            padding: '10px',
            marginBottom: '2%',
            fontWeight: 'bold',
          }}
        >
          <Form
            form={form}
            onFinish={onSearch}
            layout="horizontal"
            style={{ padding: '20px' }}
          >
            <h1
              style={{
                textAlign: 'center',
              }}
            >
              Filtros
            </h1>
            <Row gutter={4} justify="center">
              <Col span={16}>
                <Form.Item label="Código" name="codigo">
                  <Input />
                </Form.Item>
              </Col>
              <Col span={8} id="colGrupo">
                <Form.Item label="Grupo" name="grupo">
                  <Select
                    getPopupContainer={() =>
                      document.getElementById('colGrupo')
                    }
                    virtual={false}
                    showSearch
                    optionFilterProp="children"
                    onDeselect={''}
                    filterOption={(input, option) =>
                      (option?.label ?? '')
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={groupsOptions}
                  />
                </Form.Item>
              </Col>
              <Col span={16}>
                <Form.Item label="Tipo" name="tipo">
                  <Input />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Data" name="date">
                  <RangePicker
                    showTime
                    defaultValue={[
                      moment(now.toISOString().split('T')[0]),
                      moment(
                        `${now.toISOString().split('T')[0]}T${'23:59:59'}.000Z`
                      ),
                    ]}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={3} justify="center">
              <Space
                size="middle"
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  justifyContent: 'center',
                }}
              >
                <Button
                  buttonStyle="btn--primary"
                  buttonSize="btn--small"
                  onClick={e => setPage(1)}
                  htmlType="submit"
                  style={{
                    background: '#1a784a',
                    color: '#fff',
                    width: '200px',
                    height: '40px',
                    border: 'none',
                    borderRadius: '50px',
                    fontWeight: '500',
                    fontSize: '1em',
                  }}
                >
                  Buscar
                </Button>
                <Button
                  buttonStyle="btn--secondary"
                  buttonSize="btn--small"
                  onClick={onReset}
                  style={{
                    background: '#fc6836',
                    color: '#fff',
                    width: '200px',
                    height: '40px',
                    border: 'none',
                    borderRadius: '50px',
                    fontWeight: '500',
                    fontSize: '1em',
                  }}
                >
                  Limpar
                </Button>
              </Space>
            </Row>
          </Form>
        </div>
        <br />
        <br />
        <Table
          rowKey={'ocorrencia'}
          tableLayout="fixed"
          loading={loadingSpin}
          columns={templateColumns()}
          dataSource={dados}
          onChange={handleTableChange}
          pagination={{
            onChange: pageChange => {
              setPage(pageChange);
            },
            pageSize,
            current: page,
            defaultCurrent: page,
            total: totalElements.current,
          }}
        />
      </Card>
    </div>
  );
}
