import React, { useCallback, useMemo, useRef, useState } from 'react';

import {
  Input,
  Form,
  Select,
  Row,
  Col,
  Spin,
  Slider,
  InputNumber,
  Radio,
} from 'antd';

import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Container } from './styles';
import Button from '../../../button';
import './styles.css';

import { ServiceOcorrencia } from 'services/modulos/serviceOcorrencia';
import { useHistoryFormInfo } from 'hooks/monitoring/useHistoryFormInfo';
import { useHistoryResetFunctions } from 'hooks/history/useHistoryResetFunctions';
import { Search } from 'components/container_mapa/pesquisa';
import { useFiltersMapGroups } from 'hooks/filtersMap/useFiltersMapGroups';
import { useHistorySeachByRadiusAll } from 'hooks/history/useHistorySeachByRadiusAll';
import { FormHistoricoRaioProps } from './form_historico_raio';
import { useHistoryDataType } from 'hooks/history/useHistoryDataType';
import { useHistoryRaioPonto } from 'hooks/history/useHistoryRaioPonto';
import { useHistoryOriginPoint } from 'hooks/history/useHistoryOriginPoint';
import { getGeocode, getLatLng } from 'use-places-autocomplete';
import { useHistoryDataSource } from 'hooks/history/useHistoryDataSource';

const { Option } = Select;

const MAX_HOURS_CONFIG = 3;

const config = {
  corFill: {
    padrao: '#369',
    inicio: '#004427',
    fim: '#963',
  },
  corBorda: '#eee',
  corPing: '#aaa',
  corLinha: '#993',
  raioPadrao: 5,
  difRadius: 2,
  intervaloMaximoHoras: 3,
  raioBusca: {
    raioMinimo: 10,
    raioMaximo: 5000,
  },
};

export function FormAuditoriaOcorrencia({ onVisible }: FormHistoricoRaioProps) {
  const [form] = Form.useForm();

  const { setDataOccurrences } = useHistoryFormInfo();

  const [searchByNumber, setSearchByNumber] = useState(true);
  const centerRadius = useRef<{ lat: number; lng: number }>();

  const { dataSource } = useHistoryDataSource();
  const { groupsFilters } = useFiltersMapGroups();
  const { searchByRadiusAll } = useHistorySeachByRadiusAll();

  const { raioPonto, setRaioPonto } = useHistoryRaioPonto();
  const { dataType } = useHistoryDataType();
  const { setOriginPoint } = useHistoryOriginPoint();

  const {
    setLoadingHistorico,
    resetAllData,
    cleanSelectedAis,
    setChoosingOrigin,
  } = useHistoryResetFunctions();

  // @ts-ignore
  const { loading } = useSelector(state => state.monitoring);
  // @ts-ignore
  let { ais } = useSelector(state => state.monitoring);

  if (ais && ais.length > 0) {
    ais = ais.filter((a: any) => a.nome.split(' ').length > 1);
  }

  const resetarCampos = () => {
    form.resetFields();
    resetAllData();
  };

  const formItemLayout = {
    labelCol: {
      xs: { span: 9, offset: 1 },
      sm: { span: 9, offset: 1 },
      lg: { span: 9, offset: 1 },
    },
    wrapperCol: {
      xs: { span: 14 },
      sm: { span: 14 },
      lg: { span: 14 },
    },
  };

  const handleLoadAddressLatLng = useCallback(
    (position: { lat: number; lng: number }) => {
      centerRadius.current = position;
    },
    []
  );

  const inputGroups = useMemo(
    () => (
      <>
        <Option key={0} value={'all'}>
          Todos
        </Option>
        {groupsFilters.groups.map(g => (
          <Option key={g.nome} value={g.nome}>
            {g.nome}
          </Option>
        ))}
      </>
    ),
    [groupsFilters]
  );

  const loadLatLngOccurrence = useCallback(async (address: string) => {
    try {
      const geocode = await getGeocode({ address });
      if (!geocode.length) {
        throw new Error();
      }
      return getLatLng(geocode[0]);
    } catch (e) {
      console.log('e', e);
    }
  }, []);

  const onFinish = useCallback(
    async (values: any) => {
      try {
        resetAllData();
        cleanSelectedAis();
        setChoosingOrigin(false);

        dataType.current = 'auditoria_ocorrencia';

        const {
          numero,
          dtInicio,
          dtFim,
          hrInicio,
          hrFim,
          selectedGroups,
        } = values;

        if (dataSource === 'COTIC' && !selectedGroups?.length) {
          toast.info('Selecione os grupos');
          return;
        }

        const momentInicio = moment(new Date(`${dtInicio}T${hrInicio}`));
        const momentFim = moment(new Date(`${dtFim}T${hrFim}`));

        if (momentFim.isBefore(momentInicio)) {
          toast.info('Data inválida');
          setLoadingHistorico(false);

          return;
        }

        const dataInicio = momentInicio.format('YYYY-MM-DDTHH:mm:ss');
        const dataFim = momentFim.format('YYYY-MM-DDTHH:mm:ss');

        let origem = null;

        if (searchByNumber) {
          const occurrence = await ServiceOcorrencia.getById({ id: numero });

          if (
            !occurrence.latLng ||
            (occurrence.x === 0.0 && occurrence.y === 0.0)
          ) {
            if (!occurrence.endereco) {
              toast.info('Ocorrência não possui localização válida');
              setSearchByNumber(false);
              return;
            }

            const { lat, lng } = (await loadLatLngOccurrence(
              occurrence.endereco
            )) || {
              lat: 0,
              lng: 0,
            };

            if (lat && lng) {
              occurrence.latLng = {
                lat,
                lng,
              };
            } else {
              toast.info('Ocorrência não possui localização válida');
              setSearchByNumber(false);
              return;
            }
          }

          if (
            !moment(occurrence.dtCriacao).isBetween(momentInicio, momentFim)
          ) {
            toast.warn('Período inválido');
            return;
          }
          setDataOccurrences([occurrence]);
          origem = occurrence.latLng;
        } else {
          origem = centerRadius.current;
          setDataOccurrences([]);
        }

        const secondsDiff = momentFim.diff(momentInicio, 'seconds');

        if (secondsDiff > 5 * 60 * 60) {
          toast.warn('Intervalo máximo de pesquisa é de 5 horas.');
          return;
        }

        if (secondsDiff < 0) {
          toast.warn('Período inválido');
          return;
        }

        setOriginPoint(origem);

        setLoadingHistorico(true);

        const searchObj = {
          dataInicio,
          dataFim,
          origem,
          raio: raioPonto, // radiusSize,
          selectedGroups,
        };

        await searchByRadiusAll(searchObj);
        onVisible(false);
      } catch (err) {
        // @ts-ignore
        let finalMessage = err.message || 'Ocorrência não encontrada!';
        // @ts-ignore
        if (err?.response?.status === 404) {
          finalMessage = 'Ocorrência não encontrada!';
        }
        toast.info(finalMessage);
      } finally {
        setLoadingHistorico(false);
      }
    },
    [searchByNumber, resetAllData, setLoadingHistorico, raioPonto, dataSource]
  );

  return (
    <Container>
      <Spin spinning={loading}>
        <Form
          form={form}
          onFinish={onFinish}
          {...formItemLayout}
          initialValues={{ selectedGroups: ['all'] }}
        >
          <Row justify={'center'} style={{ marginBottom: '20px' }}>
            <Radio
              checked={searchByNumber}
              onClick={() => setSearchByNumber(true)}
            >
              Número da Ocorrência
            </Radio>
            <Radio
              checked={!searchByNumber}
              onClick={() => setSearchByNumber(false)}
            >
              Logradouro
            </Radio>
          </Row>

          {searchByNumber ? (
            <Row justify="center">
              <Col span={22}>
                <Form.Item
                  labelCol={{
                    xs: { span: 5, offset: 1 },
                    sm: { span: 5, offset: 1 },
                    lg: { span: 5, offset: 1 },
                  }}
                  wrapperCol={{
                    xs: { span: 16 },
                    sm: { span: 16 },
                    lg: { span: 16 },
                  }}
                  label="Número"
                  name="numero"
                  rules={[
                    {
                      required: true,
                      message: 'Informe a ocorrência!',
                    },
                  ]}
                  required
                >
                  <Input
                    placeholder="Número da ocorrência"
                    style={{ width: `100%`, borderRadius: '10px' }}
                  />
                </Form.Item>
              </Col>
            </Row>
          ) : (
            <Row justify="center">
              <Col span={22}>
                <Form.Item
                  labelCol={{
                    xs: { span: 5, offset: 1 },
                    sm: { span: 5, offset: 1 },
                    lg: { span: 5, offset: 1 },
                  }}
                  wrapperCol={{
                    xs: { span: 16 },
                    sm: { span: 16 },
                    lg: { span: 16 },
                  }}
                  label="Logradouro"
                  name="logradouro"
                  style={{
                    zIndex: 99999,
                  }}
                >
                  <Search
                    handleSearchInsideFilter={handleLoadAddressLatLng}
                    handleSetAddress={() => {}}
                  />
                </Form.Item>
              </Col>
            </Row>
          )}

          <div className="slider">
            <Col span={14}>
              <Slider
                min={config.raioBusca.raioMinimo}
                max={config.raioBusca.raioMaximo}
                value={raioPonto} // {radiusSize}
                // tooltipVisible={false}
                // onChange={e => {
                //   setRadiusSize(e);
                // }}

                onChange={e => {
                  setRaioPonto(e);
                }}
              />
            </Col>

            <Col span={4}>
              <Form.Item>
                <InputNumber
                  name="raio"
                  style={{ color: 'red' }}
                  value={raioPonto} // {radiusSize}
                  formatter={value => `${value} m`}
                  disabled
                  min={20}
                  max={5000}
                />
              </Form.Item>
            </Col>
          </div>

          <Row justify="center">
            <Col span={14}>
              <Form.Item
                label="Data inicial"
                name="dtInicio"
                rules={[
                  {
                    required: true,
                    message: 'Obrigatório',
                  },
                ]}
                required
              >
                <Input
                  type="date"
                  onChange={e => {
                    form.setFieldValue('dtFim', e.target.value);
                  }}
                  style={{ width: `100%`, borderRadius: '10px' }}
                />
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item
                name="hrInicio"
                rules={[
                  {
                    required: true,
                    message: 'Obrigatório',
                  },
                ]}
                required
              >
                <Input
                  type="time"
                  style={{ width: `100%`, borderRadius: '10px' }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row justify="center">
            <Col span={14}>
              <Form.Item
                label="Data final"
                name="dtFim"
                rules={[
                  {
                    required: true,
                    message: 'Obrigatório',
                  },
                ]}
                required
              >
                <Input
                  type="date"
                  style={{ width: `100%`, borderRadius: '10px' }}
                />
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item
                name="hrFim"
                rules={[
                  {
                    required: true,
                    message: 'Obrigatório',
                  },
                ]}
                required
              >
                <Input
                  type="time"
                  style={{ width: `100%`, borderRadius: '10px' }}
                />
              </Form.Item>
            </Col>
          </Row>

          {dataSource === 'COTIC' && (
            <Row>
              <Col span={22}>
                <Form.Item
                  labelCol={{
                    xs: { span: 5, offset: 1 },
                    sm: { span: 5, offset: 1 },
                    lg: { span: 5, offset: 1 },
                  }}
                  wrapperCol={{
                    xs: { span: 19 },
                    sm: { span: 19 },
                    lg: { span: 19 },
                  }}
                  label="Grupos"
                  name="selectedGroups"
                >
                  <Select
                    getPopupContainer={trigger => trigger.parentElement}
                    mode="multiple"
                    virtual={false}
                  >
                    {inputGroups}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          )}

          <Row justify="center" className="slotBotoes">
            <div className="grid-button">
              <Col>
                <Form.Item>
                  <Button type="submit">Buscar</Button>
                </Form.Item>
              </Col>

              <Col>
                <Form.Item>
                  <Button
                    type="reset"
                    buttonStyle="btn--secondary"
                    onClick={resetarCampos}
                  >
                    Limpar
                  </Button>
                </Form.Item>
              </Col>
            </div>
          </Row>
        </Form>
      </Spin>
    </Container>
  );
}

