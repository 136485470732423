import React from 'react';
import Container from './styles';

import HistoryMap from './index';
import { Search } from '../pesquisa';
import { AddressProps } from 'interfaces/vehicle';

export interface HistoryMapProps {
  searchAddress?: AddressProps;
  collapsed?: boolean;
  isDrawing?: boolean;
  setIsDrawing: (value: boolean) => void;
  setIsOpenModalAlerts?: (value: boolean) => void;
  isErasing?: boolean;
  setIsErasing: (value: boolean) => void;
  setFiltersVisible: (value: any) => void;
}

export function WrapperHistoryMap({
  searchAddress,
  setSearchAddress,
  collapsed,
  isDrawing,
  setIsDrawing,
  setFiltersVisible,
  isErasing,
  setIsErasing,
}: HistoryMapProps & { setSearchAddress: (value: any) => void }) {
  return (
    <Container>
      <Search handleSetAddress={setSearchAddress} />
      <HistoryMap
        searchAddress={searchAddress}
        collapsed={collapsed}
        isDrawing={isDrawing}
        setIsDrawing={setIsDrawing}
        setFiltersVisible={setFiltersVisible}
        isErasing={isErasing}
        setIsErasing={setIsErasing}
      />
    </Container>
  );
}
