import { TrackingHistoryContext } from 'context/tracking_history';
import { useContextSelector } from 'use-context-selector';

export function useHistoryPrefixosHistoricoRef() {
  const prefixosHistoricoRef = useContextSelector(
    TrackingHistoryContext,
    context => context.prefixosHistoricoRef
  );

  return {
    prefixosHistoricoRef,
  };
}

