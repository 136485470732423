import React, { useCallback, useEffect, useState } from 'react';
import { Modal, Form, Row, Col, Spin, DatePicker } from 'antd';
import PropTypes from 'prop-types';

import { toast } from 'react-toastify';
import { InputAntdCustom } from '~/components/FormAntd/InputAntd';
import { ServicePrefixo } from '~/services/modulos/servicePrefixo';
import moment from 'moment';
import { convertDateToISO } from '~/utils/date/dateUtils';

export function ModalDataAtivDesPrefixo({
  prefixoSelected,
  visible,
  onCancel,
  onOk,
  validarData,
  historicoList,
}) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [codigo, setCodigo] = useState('');
  const [initialDataAtivacao, setInitialDataAtivacao] = useState(null);
  const [initialDataDesativacao, setInitialDataDesativacao] = useState(null);

  const onFinish = useCallback(
    async values => {
      try {
        const { status, mensagem } = validarData(
          values.dataAtivacao,
          values.dataDesativacao
        );
        if (!status) {
          throw new Error(mensagem);
        }
        values.prefixoId = prefixoSelected;
        values.codigo = codigo;
        onOk(values);
        form.resetFields();
      } catch (err) {
        toast.error(err.message || 'Falha ao salvar os dados');
      } finally {
        setLoading(false);
      }
    },
    [form, onOk, codigo, prefixoSelected, validarData]
  );

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await ServicePrefixo.getById(prefixoSelected);
        setCodigo(response.codigo);

        const historicoFiltered = historicoList?.filter(
          h => h.prefixo.id === prefixoSelected
        );

        if (historicoFiltered.length > 0) {
          // console.log(historicoFiltered[0].dataDesativacao);

          const newAtiv = convertDateToISO(historicoFiltered[0].dataAtivacao);
          const newDesativ = historicoFiltered[0].dataDesativacao
            ? convertDateToISO(historicoFiltered[0].dataDesativacao)
            : null;
          setInitialDataAtivacao(newAtiv);
          setInitialDataDesativacao(newDesativ);
        }
      } catch (err) {
        toast.error('Erro ao carregar Prefixo!');
      }
    }

    fetchData();
  }, [prefixoSelected]);

  useEffect(() => {
    form.setFieldsValue({
      codigo,
      dataAtivacao: initialDataAtivacao ? moment(initialDataAtivacao) : null,
      dataDesativacao: initialDataDesativacao
        ? moment(initialDataDesativacao)
        : null,
    });
  }, [form, codigo, initialDataAtivacao, initialDataDesativacao]);

  return (
    <Modal
      destroyOnClose
      title="Vincular prefixo ao veículo"
      className="modal-recupera-login"
      open={visible}
      onOk={form.submit}
      onCancel={onCancel}
      maskClosable={false}
    >
      <Spin spinning={loading}>
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Row justify="space-around">
            <Col span={22}>
              <Form.Item label="Codigo" name="codigo">
                <InputAntdCustom size="large" disabled />
              </Form.Item>
            </Col>
          </Row>

          <Row justify="space-around">
            <Col span={10}>
              <Form.Item
                label="Data ativação"
                name="dataAtivacao"
                rules={[
                  {
                    required: true,
                    message: 'Por favor, informe a data de ativação',
                  },
                ]}
              >
                <DatePicker showTime format="DD/MM/YYYY HH:mm:ss" />
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item label="Data desativação" name="dataDesativacao">
                <DatePicker showTime format="DD/MM/YYYY HH:mm:ss" />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Modal>
  );
}

ModalDataAtivDesPrefixo.propTypes = {
  prefixoSelected: PropTypes.number,
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onOk: PropTypes.func.isRequired,
  validarData: PropTypes.func.isRequired,
  historicoList: PropTypes.array,
};

ModalDataAtivDesPrefixo.defaultProps = {
  historicoList: [-1],
  prefixoSelected: -1,
};

