import { AuthContext } from 'context/auth';
import { useContextSelector } from 'use-context-selector';

export function useAuthData() {
  const data = useContextSelector(AuthContext, context => context.data);

  return {
    data,
  };
}

