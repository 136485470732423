import styled from 'styled-components';

export const Container = styled.div`
  .tablePersonalizada {
    border: 1px solid rgb(26, 120, 74);
    border-radius: 3px !important;
    th {
      background-color: rgba(26, 120, 74, 0.2);
    }

    td {
      border-right: 1px dashed rgba(26, 120, 74, 0.3);
      border-top: 1px solid rgb(26, 120, 74);
    }
  }
  .containerForm {
    border: 1px solid #00aa3f;
    border-radius: 15px;
    padding: 10px;
    margin-bottom: 2%;
  }
`;

export const ContainerReportsList = styled.div`
  display: flex;
  gap: 20px;
  width: 100%;

  justify-content: center;
  align-items: center;

  flex-wrap: wrap;

  @media (max-width: 1000px) {
    margin-top: 10px;
    flex-direction: column;
  }
`;

