/* eslint-disable */
import React, { useCallback, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Card, Spin, Pagination, Empty, Input, Breadcrumb } from 'antd';
import { EditOutlined, ShareAltOutlined } from '@ant-design/icons';
import { toast } from 'react-toastify';
import style from './styles.module.css';
import Button from '../../../components/button';
import { ServiceCerca } from 'services/modulos/serviceCerca';

import CardCercaEletronica from '../Components/card';

import { Fence } from 'interfaces/fences';
import { useAuthData } from 'hooks/auth/useAuthData';
import { useGlobalFences } from 'hooks/global/useGlobalFences';
import { useGlobalLoadingRequest } from 'hooks/global/useLoadingRequest';
import { useGlobalLoadFences } from 'hooks/global/useGlobalLoadFences';

const PAGE_SIZE = 5;

const { Search } = Input;

export interface stateProps {
  cercas: Fence[];
  valueSearched: string;
  currentPage: number;
  loading: boolean;
  total: number;
  allFences: Fence[];
  actives: number;
  desactives: number;
}

export function CercaEletronica() {
  const history = useHistory();

  const { data: dataAuth } = useAuthData();

  const { isFenceAdmin, roles } = dataAuth || {};

  const { loadFences: loadAllFences } = useGlobalLoadFences();

  const { loadingRequest } = useGlobalLoadingRequest();

  const { fences } = useGlobalFences();

  const [page, setPage] = useState({
    first: 0,
    last: PAGE_SIZE,
  });

  const [state, setState] = useState<stateProps>({
    cercas: [],
    valueSearched: '',
    currentPage: 1,
    loading: false,
    total: 0,
    allFences: [],
    actives: 0,
    desactives: 0,
  });

  const updateState = useCallback((newProperties: any) => {
    setState(prev => ({ ...prev, ...newProperties }));
  }, []);

  const {
    cercas,
    valueSearched,
    currentPage,
    loading,
    total,
    actives,
    desactives,
  } = state;

  const loadFences = useCallback(() => {
    try {
      const cercas = fences?.current.data || [];

      const fencesOrderly = [...cercas].sort((a, b) =>
        a.nome.localeCompare(b.nome)
      );
      const actives = fences?.current.data.filter(f => f.ativo).length;
      const desactives = fences?.current.data.filter(f => !f.ativo).length;
      const totalElements = fences?.current.data.length;

      updateState({
        cercas: fencesOrderly,
        total: totalElements,
        actives,
        desactives,
      });
    } catch (err) {
      console.error(err);
    } finally {
      // updateState({ loading: false });
    }
  }, [fences.current]);

  const deleteCerca = useCallback(
    async (id: string, nome: string) => {
      try {
        updateState({ loading: true });

        if (isFenceAdmin) {
          await ServiceCerca.deleteAdminCerca({ id, nome });
        } else {
          await ServiceCerca.deleteCerca({ id, nome });
        }

        toast.success('Cerca excluída com sucesso.');

        loadAllFences();
      } catch (error) {
        toast.error(`Erro ao excluir cerca. Tente novamente.`);
      } finally {
        updateState({ loading: false });
      }
    },

    [updateState, isFenceAdmin]
  );

  const onSearch = useCallback(
    async (value: any) => {
      try {
        if (value.length) {
          updateState({ loading: true, valueSearched: value });
          let fencesFiltered: any = {};

          const searchObj = {
            page: currentPage - 1,
            size: PAGE_SIZE,
            nome: value,
          };

          if (isFenceAdmin) {
            fencesFiltered = await ServiceCerca.searchAdminCerca(searchObj);
          } else {
            fencesFiltered = await ServiceCerca.searchCerca(searchObj);
          }

          setPage({
            first: 0,
            last: PAGE_SIZE,
          });

          updateState({
            cercas: fencesFiltered.content || [],
            total: fencesFiltered.totalElements || 0,
            loading: false,
          });
        } else {
          setPage(prev => ({
            first: prev.first = (currentPage - 1) * PAGE_SIZE,
            last: prev.last = currentPage * PAGE_SIZE,
          }));
          loadFences();
        }
      } catch (error) {
        toast.error('Erro ao carregar cercas. Tente novamente.');
        console.error(error);
      }
    },
    [updateState, currentPage, valueSearched]
  );

  useEffect(() => {
    onSearch(valueSearched);
  }, [currentPage, fences.current]);

  const handleSearch = useCallback(
    (value: any) => {
      updateState({ valueSearched: value });
      if (currentPage === 1) {
        onSearch(value);
      } else {
        updateState({ currentPage: 1 });
      }
    },
    [currentPage]
  );

  return (
    <div>
      <Breadcrumb style={{ padding: '10px' }}>
        <Breadcrumb.Item>
          {/* @ts-expect-error Server Component */}
          <Link to="/">Página Inicial</Link>
        </Breadcrumb.Item>

        <Breadcrumb.Item>Cercas</Breadcrumb.Item>
      </Breadcrumb>

      <Card>
        <div className={style.header}>
          <div>
            <h2>Cerca Eletrônica</h2>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                gap: '2px',
              }}
            >
              <div>
                Ativas:{' '}
                <span
                  style={{
                    fontWeight: 'bold',
                    fontSize: '15px',
                    color: 'green',
                  }}
                >
                  {actives}{' '}
                </span>
              </div>
              <div>
                Desativas:{' '}
                <span
                  style={{ fontWeight: 'bold', fontSize: '15px', color: 'red' }}
                >
                  {desactives}
                </span>
              </div>
            </div>
          </div>
          <div className={style.buttons}>
            {roles?.includes('ROLE_ROTAS_CERCA_CIOPS') && (
              <Button
                onClick={() => history.push('/cerca/atribuir/')}
                type="button"
                buttonStyle="btn--link"
                icon={<ShareAltOutlined />}
              >
                Compartilhar
              </Button>
            )}

            {roles?.includes('ROLE_ROTAS_CERCA_UPLOAD') && (
              <Button
                onClick={() => history.push('/cerca/upload/')}
                type="button"
                buttonStyle="btn--secondary"
                icon={<ShareAltOutlined />}
              >
                Upload
              </Button>
            )}

            {(roles?.includes('ROLE_ROTAS_CERCA_CIOPS') ||
              roles?.includes('ROLE_ROTAS_CRIACAO_CERCA')) && (
              <Button
                onClick={() => history.push('/cerca/form/')}
                type="button"
                buttonStyle="btn--primary"
                icon={<EditOutlined />}
              >
                Criar Cerca
              </Button>
            )}
          </div>
        </div>
        <div>
          <Search
            placeholder="Pesquise por nome"
            size="large"
            onSearch={handleSearch}
          />
        </div>
        <Spin spinning={loadingRequest?.fences || loading} tip="Carregando...">
          {cercas.length > 0 ? (
            !loading &&
            cercas.slice(page.first, page.last).map(cerca => {
              const { geom } = cerca;

              const feature = {
                pontosBase: cerca.pontosBase,
                type: geom?.type,
                coordinates: (geom?.points || []).map((g: any) => [g.y, g.x]),
              };

              return (
                <CardCercaEletronica
                  id={cerca.id || ''}
                  key={cerca.id}
                  nome={`${cerca.nome}`}
                  feature={feature}
                  veiculos={cerca.veiculosAssociados}
                  deleteCerca={deleteCerca}
                  ativo={cerca.ativo}
                  isFenceAdmin={isFenceAdmin}
                  isAutor={
                    parseInt(cerca.autor || '', 10) ===
                      dataAuth?.client?.clientId &&
                    (roles?.includes('ROLE_ROTAS_CERCA_CIOPS') ||
                      roles?.includes('ROLE_ROTAS_CRIACAO_CERCA'))
                  }
                  canEditVehicles={roles?.includes(
                    'ROLE_ROTAS_EDIT_VEICULOS_CERCA'
                  )}
                  system={cerca.autor === 'SYSTEM'}
                />
              );
            })
          ) : (
            <Empty description="Não foram encontradas cercas para este usuário" />
          )}
          <Pagination
            style={{ textAlign: 'end', paddingTop: '15px' }}
            defaultCurrent={1}
            total={total}
            current={currentPage}
            pageSize={PAGE_SIZE}
            onChange={pageChange => {
              updateState({ currentPage: pageChange });
            }}
          />
        </Spin>
        <br />
      </Card>
    </div>
  );
}

