import styled from 'styled-components';

const Container = styled.div`
  .filtrosContainer {
    margin-bottom: 10px;
    border: 1px solid rgb(26, 120, 74);
    border-radius: 3px !important;
    padding: 15px;
  }

  .Time {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 10px;
    justify-content: center;
    align-items: center;
  }

  .titleForm {
    justify-self: center;
    width: 100%;
    text-align: center;
    font-size: 1.2rem;
    @media (min-width: 620px) {
      font-size: 1.5rem;
    }
  }

  .slotBotoes2 {
    display: flex;
    width: 100%;
    justify-content: center;
    justify-items: center;
    align-items: center;
    align-content: center;
  }

  .slotBotoesButton {
    dayfimmargin: 10px 5px 5px 5px !important;
  }

  .filtrosForm {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    font-weight: bold;
    justify-content: center;
  }

  .formItemClass {
    width: 30%;
    margin: 1px 5px 5px 5px;
  }

  .formItemClassLogradouro {
    width: 60%;
    margin: 1px 5px 5px 5px;
  }

  .slotBtnDocCsvPersonalizado {
    position: absolute;
    color: green;
    left: 5px;
  }
  .ant-form-item {
    flex-basis: content;
  }

  .formItemClassDate {
    display: flex;
    width: 40%;
    margin: 1px 5px 5px 5px;
  }

  .composicao {
    border: 1px solid rgb(0, 170, 63);
    border-radius: 15px;
    padding: 10px;
    margin-bottom: 2%;
    font-weight: bold;
  }

  .tablePersonalizada {
    border: 1px solid rgb(26, 120, 74);
    border-radius: 3px !important;
    th {
      background-color: rgba(26, 120, 74, 0.2);
    }

    td {
      border-right: 1px dashed rgba(26, 120, 74, 0.3);
      border-top: 1px solid rgb(26, 120, 74);
    }
  }

  .daysWithoutLocationContainer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    padding: 10px;
  }

  #gerarRelBtn {
    margin-top: 2%;
  }
`;

export default Container;

