import React, { useState } from 'react';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import logo64 from '../../../../imgs/encoded/brasaosspds.base64';
import { TDocumentDefinitions } from 'pdfmake/interfaces';
import { CsvProps } from '.';
import html2canvas from 'html2canvas';

export const pdfReport = async (data: CsvProps[], mapImg: any) => {
  const win = window.open('', '_blank');
  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  let canvasImg = '';

  if (mapImg) {
    await html2canvas(mapImg, { useCORS: true }).then((res: any) => {
      const originalCanvas = res;

      const croppedCanvas = document.createElement('canvas');
      const ctx = croppedCanvas.getContext('2d');

      const cropWidth = 700;
      const cropHeight = 600;

      const scaleFactor = originalCanvas.width / mapImg.offsetWidth;

      croppedCanvas.width = cropWidth;
      croppedCanvas.height = cropHeight;
      ctx?.drawImage(
        originalCanvas,
        originalCanvas.width * 0.25, // X p/ início do retangulo
        originalCanvas.height * 0.15, // Y p/ início do retangulo
        cropWidth * scaleFactor, // X p/ fim do retangulo
        cropHeight * scaleFactor, // Y p/ fim do retangulo
        0,
        0,
        cropWidth,
        cropHeight
      );

      canvasImg = croppedCanvas.toDataURL();
    });
  }

  const reportTitle: any = [
    {
      image: logo64,
      width: 179,
      height: 63,
      alignment: 'center',
      margin: [0, 10, 0, 0],
    },
    {
      text: 'Histórico de posições\n',
      bold: true,
      fontSize: 13,
      alignment: 'center',
      margin: [0, 4, 0, 0],
    },
    {
      text: `Segue abaixo a lista com informações das posições de veículos (${data.length}).\n`,
      bold: false,
      fontSize: 9,
      alignment: 'center',
      margin: [0, 4, 0, 0],
    },
    {
      text: 'Dados de posicionamento fornecidos pela empresa TELTRONIC\n',
      bold: false,
      fontSize: 9,
      alignment: 'center',
      margin: [0, 4, 0, 0],
    },
  ];

  const results: any = data.map(c => {
    return [
      { text: c.placa, fontSize: 9, margin: [0, 1, 0, 1] },
      { text: c.prefixo, fontSize: 9, margin: [0, 1, 0, 1] },
      { text: c.equipamento, fontSize: 9, margin: [0, 1, 0, 1] },
      { text: c.velocidade, fontSize: 9, margin: [0, 1, 0, 1] },
      { text: c.dataPosicao, fontSize: 9, margin: [0, 1, 0, 1] },
      { text: c.logradouro, fontSize: 9, margin: [0, 1, 0, 1] },
      { text: c.latitude, fontSize: 9, margin: [0, 1, 0, 1] },
      { text: c.longitude, fontSize: 9, margin: [0, 1, 0, 1] },
    ];
  });

  const details: any = [
    {
      alignment: 'center',
      margin: [0, 0, 0, 0],
      layout: {
        fillColor(rowIndex: number) {
          return rowIndex % 2 === 0 ? '#CCCCCC' : null;
        },
      },

      table: {
        headerRows: 1,
        widths: ['10%', '10%', '10%', '10%', '10%', '30%', '10%', '10%'],
        body: [
          [
            { text: 'Placa', style: 'tableHeader', fontSize: 9 },
            { text: 'Prefixo', style: 'tableHeader', fontSize: 9 },
            { text: 'Equipamento', style: 'tableHeader', fontSize: 9 },
            { text: 'Velocidade', style: 'tableHeader', fontSize: 9 },
            { text: 'Data Posição', style: 'tableHeader', fontSize: 9 },
            { text: 'Logradouro', style: 'tableHeader', fontSize: 9 },
            { text: 'Latitude', style: 'tableHeader', fontSize: 9 },
            { text: 'Longitude', style: 'tableHeader', fontSize: 9 },
          ],
          ...results,
        ],
      },
    },
  ];

  const canvasContainer: any = [
    {
      image: 'canvas',
      margin: [10, 20, 10, 0],
      width: 500,
      height: 400,
    },
  ];

  const footer: any = [
    {
      text: [
        {
          text:
            'Secretaria de Segurança Pública e Defesa Social. Avenida Bezerra de Menezes, 581 - São Gerardo\n CEP 60325-003 . Fortaleza Ceará . Fone: (85) 3101-6501',
        },
      ],
      fontSize: 8,
      margin: [0, 10, 0, 45],
      alignment: 'center',
    },
  ];

  const docDefinitions: TDocumentDefinitions = {
    // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
    pageMargins: [30, 140, 10, 40],
    pageSize: 'A4',
    // @ts-ignore
    alignment: 'center',
    header: [reportTitle],
    content: mapImg ? [details, canvasContainer] : [details],
    images: {
      canvas: canvasImg,
    },
    footer: [footer],
  };
  pdfMake.createPdf(docDefinitions).open({}, win);
};

