import React, { useState, useEffect } from 'react';

import { Input, Select } from 'antd';
import style from './styles.module.css';
import { useHistoryPrefixosHistoricoRef } from 'hooks/history/useHistoryPrefixosHistoricoRef';
import { dataPropertiesAux } from 'interfaces/vehicle';

const { Option } = Select;

export default function PrefixosHistorico() {
  const [statusSelected, setStatusSelected] = useState('');
  const [currentArray, setCurrentArray] = useState([]);
  const [searchText, setSearchText] = useState('');

  const { prefixosHistoricoRef } = useHistoryPrefixosHistoricoRef();

  function handleChange(value: string) {
    setStatusSelected(value);
  }

  const grupos = ['Conectadas', 'Desconectadas', 'Rádio', 'Todos'];

  useEffect(() => {
    const status: any = {
      Conectadas: prefixosHistoricoRef.current.conectadas,
      Desconectadas: prefixosHistoricoRef.current.desconectadas,
      Rádio: prefixosHistoricoRef.current.radio,
      Todos: [
        ...prefixosHistoricoRef.current.conectadas,
        ...prefixosHistoricoRef.current.desconectadas,
        ...prefixosHistoricoRef.current.radio,
      ],
    };

    setCurrentArray(status[statusSelected]);
  }, [statusSelected]);

  return (
    <div className={style.grupos}>
      <Select
        virtual={false}
        getPopupContainer={trigger => trigger.parentElement}
        showSearch
        value={statusSelected}
        className={style.options}
        onChange={handleChange}
        defaultValue=""
      >
        <Option value="">Selecione</Option>
        {grupos?.map((v, index) => {
          return <Option key={v + index} value={v}>{`${v}`}</Option>;
        })}
      </Select>
      {statusSelected && (
        <div className={style.grupoContainer}>
          <Input
            placeholder="Pesquisar"
            onChange={e => setSearchText(e.target.value)}
            style={{ marginBottom: '10px' }}
          />
          <span>{`${statusSelected}: ${
            (currentArray || []).length
          } veículos`}</span>
          {currentArray
            ?.filter(
              (v: dataPropertiesAux) =>
                !searchText ||
                v.placa.toLowerCase().indexOf(searchText.toLowerCase()) >= 0 ||
                (v.prefixo &&
                  v.prefixo.toLowerCase().indexOf(searchText.toLowerCase()) >=
                    0)
            )
            .map((value: dataPropertiesAux, index) => (
              <div key={value.placa} className={style.grupoButton}>
                <div className={style.grupoDescrition}>
                  <div className={style.grupoChave}>{index + 1}:</div>
                  <div className={style.grupoValor}>{`${value.placa} ${
                    value.prefixo && value.prefixo !== value.placa
                      ? `- ${value.prefixo}`
                      : ''
                  }`}</div>
                </div>
              </div>
            ))}
        </div>
      )}
    </div>
  );
}

