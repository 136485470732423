import styled from 'styled-components';
import { Button as ButtonAnt } from 'antd';
import { shade } from 'polished';

export const Container = styled.div`
  background-color: #fff;
  height: 100vh;

  .ant-spin-nested-loading {
    background: transparent;
  }

  .christmasIcon {
    position: absolute;
    z-index: 999;
    margin-top: -83px;
    margin-left: 260px;
    overflow: hidden !important;

    #img {
      width: 100px;
      height: 100px;
    }
  }

  .container-sistema {
    @media (max-width: 50em) {
      display: none;
    }
    position: relative;
    height: 0px;
  }

  .title-container {
    display: flex;
    margin-left: 20%;
    padding-top: 140px;
    line-height: 4.2em;
  }

  .container-title {
    margin-bottom: 45vh;
  }

  .login {
    width: 320px;
    height: 500px;
    border-radius: 10px;
    border: 1px solid #bdbdbd;
    padding: 20px;
    box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.25);
    top: 200px;
    left: 70%;
    position: absolute;
    background-color: #fff;
    z-index: 1020;
  }

  h3 {
    padding: 5px;
    margin: 0 auto;
    background-color: orange !important;
    width: 100px;
  }

  .logo {
    background-color: orange;
    height: 160px;
    width: 100%;
    top: 180px;
    position: fixed;
  }

  .limiter {
    background-color: transparent;
    overflow: hidden;
  }

  .limiter h1 {
    color: #008442 !important;
  }

  .text-one {
    position: 'absolute';
    top: 160px;
    margin-left: '18%';
    font-size: 47px;
    color: #008442;
    font-weight: 500;
  }

  .text-tow {
    position: 'absolute';
    top: 160px;
    margin-left: '20%';
    font-size: 80px;
    color: #008442;
    font-weight: 500;
  }

  .text-three {
    position: 'absolute';
    top: 250;
    margin-left: '20%';
    font-size: 25px;
    color: #858585;
  }

  @media only screen and (min-height: 824px) and (max-height: 1280px) {
    .container-title {
      margin-bottom: 32vh;
    }
  }

  @media only screen and (min-height: 1281px) and (max-height: 1874px) {
    .container-title {
      margin-bottom: 25vh;
    }
  }

  @media only screen and (min-height: 1875px) {
    .container-title {
      margin-bottom: 20vh;
    }
  }

  @media only screen and (max-width: 1280px) {
    .text-one {
      font-size: 2.7em;
    }

    .text-tow {
      font-size: 4.8em;
    }
    .text-three {
      // width: 45%;
      font-size: 1.5em;
    }
  }

  @media only screen and (max-width: 1024px) {
    .text-one {
      font-size: 2.7em;
    }

    .text-tow {
      font-size: 4.8em;
    }
    .text-three {
      font-size: 1.5em;
    }
  }

  @media only screen and (max-width: 873px) {
    .container-title {
      display: none;
    }
  }

  .row {
    margin-left: 300px !important;
  }

  .barra-green {
    background-color: #008442;
    height: 200px;
    width: 100%;
    top: 340px;
    position: fixed;
    color: transparent;
  }
  .barra-orange {
    background-color: #fc6836;
    height: 20px;
    width: 100%;
    top: 550px;
    position: fixed;
  }

  .container-logo {
    background-color: red;
    display: flex;
    position: relative;
    padding: 230px;
  }

  .titulo-sistema {
    color: #858585;
    font-size: 50px;
    line-height: 1;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .nome-sistema {
    color: #008442;
    font-size: 80px;
    line-height: 1;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    font-weight: 500;
  }

  .subtitulo-sistema {
    color: #858585;
    font-size: 20px;
    line-height: 1;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .rodape {
    position: relative;
    top: 3%;
  }

  .rodape-titulo {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    color: #1d1d1d;
    margin-bottom: 10px;
    font-weight: 600;
  }

  .rodape-subtitulo {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    font-size: 10px;
    color: #1d1d1d;
  }

  .container-login100 {
    width: 100%;
    min-height: 90vh;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    flex-direction: row;
    align-items: center;
    padding: 15px;
    position: relative;
  }

  .barra-verde-login {
    position: absolute;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    top: 40%;
    height: 150px;
    background-color: #008442;
  }

  .barra-laranja-login {
    position: relative;
    top: 90px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 14px;
    background-color: #fc6836;
  }

  .wrap-login100 {
    height: 500px;
    width: 350px;
    // position: fixed;
    /* width: 390px; */
    background: #fff;
    border-radius: 10px;
    overflow: hidden;
    padding: 50px 33px 33px 33px;
    box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.5);
    -o-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.5);
    -ms-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.5);
  }

  .login100-form-bgbtn {
    position: absolute;
    z-index: -1;
    width: 300%;
    height: 100%;

    top: 0;
    left: -100%;
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;
  }

  .login100-form-title {
    display: flex;
    justify-content: center;
    font-size: 30px;
    color: #008442;
    line-height: 0.2;
    text-align: left;
    margin-bottom: 28px;
    margin-right: 25px;
    font-weight: 100;
  }

  .login100-form-title img {
    display: block;
    margin-left: 24px;
    font-weight: 100;
    margin-bottom: 50px;
  }

  .focus-input100::before {
    content: '';
    display: block;
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 0;
    height: 2px;
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;
  }

  .input100 {
    font-size: 15px;
    color: #555555;
    line-height: 1.2;
    display: block;
    width: 100%;
    height: 45px;
    background: transparent;
    padding: 0 5px;
    margin-left: 26px;
  }

  .wrap-input100 {
    width: 100%;
    position: relative;
    border: 1px solid #adadad;
    border-radius: 35px;

    .ant-input {
      height: 37px !important;
    }

    .ant-input-prefix {
      display: flex !important;
      align-items: center !important;
    }

    .ant-input-suffix {
      align-items: center;
      display: flex;
    }

    .ant-input-affix-wrapper {
      width: 95% !important;
      border: none;
      outline: none;
      margin-left: 10px;
    }

    .ant-input-affix-wrapper {
      background-color: transparent !important;
      box-shadow: none !important;
      height: 45px !important;
    }

    input {
      border: none;
      outline: none;
    }
  }

  .focus-input100 {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    margin-left: 26px;
  }

  a {
    display: flex !important;
    justify-content: center;
    color: #1da57a;
    display: block;
    margin-top: 24px;
    text-decoration: none;
    transition: color 0.2s;

    &:hover {
      color: ${shade(0.2, '#1DA57A')};
    }
  }

  .btnLogin {
    padding-bottom: 2.5rem !important;
    background: var(--corPrincipal) !important;
  }

  .linkRecuperaSenha {
    color: var(--corComplementarClara) !important;
  }

  .overlay {
    z-index: 2000 !important;
    background: chartreuse !important;
  }
`;

export const ContainerModal = styled.div`
  background-color: red;
`;

export const Button = styled(ButtonAnt)`
  text-align: center !important;
  display: inline-block !important;
  width: 100% !important;
  /* margin: 20px 0 !important; */
  padding: 7px 15px 8px !important;
  opacity: 0.9 !important;

  &:hover {
    opacity: 1 !important;
  }

  font-size: 14px !important;
  color: #fff !important;
  /* font-family: Open Sans; */
  /* font-weight: lighter; */
  text-rendering: geometricPrecision !important;
  cursor: pointer !important;
  outline: 0 !important;
  background: #fc6836 no-repeat 50% !important;
  border: 0 solid rgba(0, 0, 0, 0.1) !important;
  border-radius: 50px !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  -webkit-transition: 0.1s !important;
  transition: 0.1s !important;
  height: 40px !important;
`;
