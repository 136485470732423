import React, { useCallback, useState, useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { Card, Input, Table, Breadcrumb } from 'antd';
import { debounce } from 'throttle-debounce';
import { useSelector, useDispatch } from 'react-redux';

import * as ACOES from '~/store/modules/lotacao/actions';
import { PageHeader } from '../../../components/PageHeader';
import { templateColumns } from './columns';

const PAGE_SIZE = 5;
export const TITLE = 'Lotação';
const SUBTITLE = 'Gerenciamento de lotação';
export const BASE_ROLE = 'ROLE_ROTAS_CADASTROS';
export const MODULE_ROUTE = 'lotacao';

const { Search } = Input;

export function LotacaoList() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [nomeSearch, setNomeSearch] = useState();
  const [page, setPage] = useState(1);

  const { loading, totalElements, data } = useSelector(state => state.lotacao);

  const initFetch = useCallback(() => {
    dispatch(ACOES.getAllPage({ page, size: PAGE_SIZE }));
  }, [dispatch, page]);

  useEffect(() => {
    initFetch();
  }, [initFetch]);

  const handleNewRegister = useCallback(() => {
    history.push(`${MODULE_ROUTE}/form`);
  }, [history]);

  const handleSearch = useCallback(
    value => {
      setPage(1);
      setNomeSearch(value);
      dispatch(ACOES.getAllPage({ page: 1, size: PAGE_SIZE, nome: value }));
    },
    [dispatch]
  );

  const handleDelete = useCallback(
    value => {
      // Exemplo de como implementar o deletar
      setNomeSearch('');
      setPage(1);
      dispatch(
        ACOES.deleteRequest({
          id: value.id,
          params: { page: 1, size: PAGE_SIZE },
        })
      );
    },
    [dispatch]
  );

  return (
    <div>
      <Breadcrumb style={{ padding: '10px' }}>
        <Breadcrumb.Item>
          <Link to="/">Página Inicial</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to="/cadastros">Cadastros</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Lotacao</Breadcrumb.Item>
      </Breadcrumb>
      <Card>
        <PageHeader
          title={TITLE}
          subTitle={SUBTITLE}
          baseRole={BASE_ROLE}
          onNewRegister={handleNewRegister}
        />
        <div
          style={{
            marginBottom: 8,
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Search
            value={nomeSearch}
            placeholder="Pesquisa por nome"
            onChange={e => debounce(800, handleSearch(e.target.value))}
          />
          <div />
        </div>
        <Table
          tableLayout="fixed"
          loading={loading}
          columns={templateColumns({ onDelete: handleDelete })}
          dataSource={data}
          pagination={{
            onChange: pageChange => {
              setPage(pageChange);
            },
            pageSize: PAGE_SIZE,
            current: page,
            defaultCurrent: page,
            total: totalElements,
          }}
        />
      </Card>
    </div>
  );
}

