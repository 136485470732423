import { FilterProps } from 'interfaces/services';
import {
  DataPosition,
  PositionVehicle,
  VehicleComposition,
} from 'interfaces/vehicle';
import api from '../api';

interface CompositionProps {
  viatura: string;
  dtIni: string;
  dtFim: string;
}

export const getVeiculosServices = {
  // get: async () => {
  //   const retorno = await api.get('/v1/tracking/lastposition/vehcile/all');
  //   return retorno;
  // },

  get: async (filters?: FilterProps): Promise<PositionVehicle[]> => {
    return await api.post('/rotas/tile38', filters);
  },

  getAll: async (): Promise<any> => {
    const retorno = await api.get('/v1/tracking/lastposition');
    return retorno;
  },

  getConectadas: (): Promise<any> => {
    return api.get('/rotas/viaturas/conectadas');
  },

  getStatusViautras: (): Promise<any> => {
    return api.get('/rotas/conexao-viaturas');
  },

  getList: (): Promise<any> => {
    return api.get('/rotas/viaturas/listCorrectResgistration');
  },

  getComposition: ({
    viatura,
    dtIni,
    dtFim,
  }: CompositionProps): Promise<VehicleComposition[]> => {
    return api.get(
      `/rotas/viaturas/historico-conexao-viaturas?viatura=${viatura}&dtIni=${dtIni}&dtFim=${dtFim}`
    );
  },
};
