import { TrackingHistoryContext } from 'context/tracking_history';
import { useContextSelector } from 'use-context-selector';

export function useHistorySearchedDate() {
  const searchedDate = useContextSelector(
    TrackingHistoryContext,
    context => context.searchedDate
  );

  const setSearchedDate = useContextSelector(
    TrackingHistoryContext,
    context => context.setSearchedDate
  );

  return {
    searchedDate,
    setSearchedDate,
  };
}

