import { MonitoringContext } from 'context/monitoring';
import { useContextSelector } from 'use-context-selector';

export function useFiltroAgencias() {
  const setData = useContextSelector(
    MonitoringContext,
    context => context.setData
  );

  return {
    setData,
  };
}

