import React from 'react';

import moment from 'moment';

export const templateColumns = () => {
  return [
    {
      title: 'Equipamento',
      dataIndex: 'mdt',
      key: 'mdt',
      align: 'center',
      width: 80,
      render: (text: string) => <p style={{ maxWidth: '80px' }}>{text}</p>,
      responsive: ['md'],
    },
    {
      title: 'Prefixo',
      dataIndex: 'viatura',
      key: 'viatura',
      align: 'center',
      width: 80,
      render: (text: string) => <p style={{ maxWidth: '80px' }}>{text}</p>,
      responsive: ['lg'],
    },
    {
      title: 'AIS',
      dataIndex: 'ais',
      key: 'ais',
      align: 'center',
      width: 80,
      responsive: ['lg'],
      render: (text: string) => <p style={{ maxWidth: '80px' }}>{text}</p>,
    },
    {
      title: 'Data/Hora Conexão',
      dataIndex: 'dataHoraConexao',
      key: 'dataHoraConexao',
      align: 'center',
      width: 200,
      responsive: ['md'],
      render: (text: string) => moment(text).format('DD-MM-YYYY  HH:mm:ss'),
    },
    {
      title: 'Comandante',
      dataIndex: 'comandante',
      key: 'latitude',
      align: 'center',
      width: 200,
    },
    {
      title: 'Motorista',
      dataIndex: 'motorista',
      key: 'motorista',
      align: 'center',
      width: 200,
    },
    {
      title: 'Patrulheiro',
      key: 'patrulheiro',
      dataIndex: 'patrulheiro',
      align: 'center',
      width: 200,
    },
  ];
};
