import ACTION from '../actionTypes';

export function getAllVinRequest() {
  return {
    type: ACTION.MONITORING_VIN_ALL_REQUEST,
  };
}

export function getAllVinSuccess(data) {
  return {
    type: ACTION.MONITORING_VIN_ALL_SUCCESS,
    data,
  };
}

export function getAllAisRequest() {
  return {
    type: ACTION.MONITORING_AIS_ALL_REQUEST,
  };
}

export function getAllAisSuccess(data) {
  return {
    type: ACTION.MONITORING_AIS_ALL_SUCCESS,
    data,
  };
}

export function getAllVeichlesResumeRequest() {
  return {
    type: ACTION.MONITORING_VEI_ALL_REQUEST,
  };
}

export function getAllVeichlesResumeSuccess(data) {
  return {
    type: ACTION.MONITORING_VEI_ALL_SUCCESS,
    data,
  };
}

export function getAllAlertRequest() {
  return {
    type: ACTION.MONITORING_ALERT_ALL_REQUEST,
  };
}

export function getAllAlertSuccess(data) {
  return {
    type: ACTION.MONITORING_ALERT_ALL_SUCCESS,
    data,
  };
}

export function setPlacasMonitoring(data) {
  return {
    type: ACTION.MONITORING_PLACAS_ALL_SUC,
    data,
  };
}

export function error(e) {
  return {
    type: ACTION.MONITORING_ERROR,
    error: e,
  };
}
