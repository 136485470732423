/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';

import style from './styles.module.css';
import { PositionVehicle, dataPropertiesAux } from 'interfaces/vehicle';
import moment from 'moment';
import { VehiclePositionHistory } from 'interfaces/history';
import { useHistorySearchedDateSlider } from 'hooks/history/useHistorySearchedDateSlider';
import { useHistoryData } from 'hooks/history/useHistoryData';

export default function PosicoesEfetivo() {
  const { dataHistorico } = useHistoryData();
  const { searchedDateSlider } = useHistorySearchedDateSlider();

  const [positions, setPositions] = useState<dataPropertiesAux[]>();

  useEffect(() => {
    const newPositions: dataPropertiesAux[] = [];

    dataHistorico.map((v: VehiclePositionHistory) => {
      newPositions.push(
        ...v.positions.filter(p => {
          let isInTimestamp = true;

          if (searchedDateSlider.startDate && searchedDateSlider.endDate) {
            isInTimestamp = Boolean(
              moment(p.datePosition).isSameOrAfter(
                moment(searchedDateSlider.startDate)
              ) &&
                moment(p.datePosition).isSameOrBefore(
                  moment(searchedDateSlider.endDate)
                )
            );
          }

          return (
            p.position && p.position.coordinates.length === 2 && isInTimestamp
          );
        })
      );
    });

    setPositions(newPositions);
  }, [dataHistorico, searchedDateSlider]);

  return (
    <div className={style.grupos}>
      <div className={style.grupoContainer}>
        {positions?.map((value: PositionVehicle, index: number) => (
          <div
            // onClick={() => handleClick(value)}
            key={index}
            className={style.grupoButton}
          >
            <div className={style.grupoDescrition}>
              <div className={style.grupoChave}>{index + 1}:</div>
              <div className={style.grupoValor}>
                <b>Placa:</b> {value.placa} <br />
                {value.prefixo && (
                  <div>
                    <b>Prefixo:</b> {value.prefixo} <br />
                  </div>
                )}
                {value.motorista && (
                  <div>
                    <b>Motorista:</b> {value.motorista} <br />
                  </div>
                )}{' '}
                {value.comandante && (
                  <div>
                    <b>Comandante:</b> {value.comandante} <br />
                  </div>
                )}{' '}
                {value.patrulheiro && (
                  <div>
                    <b>Patrulheiro:</b> {value.patrulheiro} <br />
                  </div>
                )}{' '}
                <b>Data:</b> {new Date(value.datePosition).toLocaleDateString()}{' '}
                {new Date(value.datePosition).toLocaleTimeString()} <br />
                <div>
                  <b>Velocidade: </b> {value.speed} Km/h{' '}
                </div>
              </div>
            </div>

            <div className={style.grupoDescrition}>
              <div className={style.grupoValor}>{value.agencia}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

