import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import {
  Content,
  DynamicContent,
  TDocumentDefinitions,
} from 'pdfmake/interfaces';
import logo64 from '../../../../imgs/encoded/brasaosspds.base64';
import moment from 'moment';
import { allAis, allEspecializadas } from 'utils/functionsUtils';

export const pdfGen = async (docTitle: string, contents: any) => {
  const win = window.open('', '_blank');
  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  let totalAis = 0;
  let totalEspecializadas = 0;

  const reportTitle: any = [
    {
      image: logo64,
      width: 179,
      height: 63,
      alignment: 'center',
      margin: [0, 10, 0, 0],
    },
    {
      text: docTitle,
      style: 'title',
    },
  ];

  const results: {
    [key: string]: {
      text: string | number;
      fontSize: number;
      margin: number[];
    }[][];
  } = {};

  Object.keys(contents).map((k: string) => {
    if (!results[k]) results[k] = [];

    contents[k].map((_: any, i: number) => {
      results[k].push([
        { text: contents[k][i][0], fontSize: 9, margin: [0, 1, 0, 1] },
        { text: contents[k][i][1], fontSize: 9, margin: [0, 1, 0, 1] },
        { text: contents[k][i][2], fontSize: 9, margin: [0, 1, 0, 1] },
        { text: contents[k][i][3], fontSize: 9, margin: [0, 1, 0, 1] },
        { text: contents[k][i][4], fontSize: 9, margin: [0, 1, 0, 1] },
        { text: contents[k][i][5], fontSize: 9, margin: [0, 1, 0, 1] },
        {
          text: contents[k][i][6],
          fontSize: 9,
          margin: [0, 1, 0, 1],
        },
        {
          text: contents[k][i][7],
          fontSize: 9,
          margin: [0, 1, 0, 1],
        },
      ]);
    });

    return k;
  });

  const details: any = [];

  allAis.map(ais => {
    if (contents[ais.value] && contents[ais.value].length > 0) {
      totalAis += contents[ais.value].length;
      details.push(
        {
          stack: [
            {
              columns: [
                {
                  text: `Grupo: ${ais.value}`,
                },
                {
                  text: `Total: ${contents[ais.value].length}`,
                },
              ],
            },
          ],
          style: 'stack',
        },
        {
          alignment: 'center',
          margin: [0, 0, 0, 20],
          width: 'auto',
          layout: {
            fillColor(rowIndex: number) {
              return rowIndex === 0 ? '#000' : null;
            },
          },
          style: 'table',
          table: {
            headerRows: 1,
            widths: ['10%', '10%', '10%', '20%', '10%', '20%', '10%', '10%'],
            body: [
              [
                {
                  text: 'Placa',
                  style: 'tableHeader',
                  fontSize: 10,
                  color: '#FFF',
                },
                {
                  text: 'Prefixo',
                  style: 'tableHeader',
                  fontSize: 10,
                  color: '#FFF',
                },
                {
                  text: 'Equip.',
                  style: 'tableHeader',
                  fontSize: 10,
                  color: '#FFF',
                },
                {
                  text: 'Status',
                  style: 'tableHeader',
                  fontSize: 10,
                  color: '#FFF',
                },
                {
                  text: 'Ultima Posição',
                  style: 'tableHeader',
                  fontSize: 10,
                  color: '#FFF',
                },
                {
                  text: 'Logradouro',
                  style: 'tableHeader',
                  fontSize: 10,
                  color: '#FFF',
                },
                {
                  text: 'Latitude',
                  style: 'tableHeader',
                  fontSize: 10,
                  color: '#FFF',
                },
                {
                  text: 'Longitude',
                  style: 'tableHeader',
                  fontSize: 10,
                  color: '#FFF',
                },
              ],
              ...results[ais.value],
            ],
          },
        }
      );
    }
  });

  allEspecializadas.map(esp => {
    if (contents[esp] && contents[esp].length > 0) {
      totalEspecializadas += contents[esp].length;
      details.push(
        {
          stack: [
            {
              columns: [
                {
                  text: `Grupo: ${esp}`,
                },
                {
                  text: `Total: ${contents[esp].length}`,
                },
              ],
            },
          ],
          style: 'stack',
        },
        {
          alignment: 'center',
          margin: [0, 0, 0, 20],
          width: 'auto',
          layout: {
            fillColor(rowIndex: number) {
              return rowIndex === 0 ? '#000' : null;
            },
          },
          style: 'table',
          table: {
            headerRows: 1,
            widths: ['10%', '10%', '10%', '20%', '10%', '20%', '10%', '10%'],
            body: [
              [
                {
                  text: 'Placa',
                  style: 'tableHeader',
                  fontSize: 10,
                  color: '#FFF',
                },
                {
                  text: 'Prefixo',
                  style: 'tableHeader',
                  fontSize: 10,
                  color: '#FFF',
                },
                {
                  text: 'Equip.',
                  style: 'tableHeader',
                  fontSize: 10,
                  color: '#FFF',
                },
                {
                  text: 'Status',
                  style: 'tableHeader',
                  fontSize: 10,
                  color: '#FFF',
                },
                {
                  text: 'Ultima Posição',
                  style: 'tableHeader',
                  fontSize: 10,
                  color: '#FFF',
                },
                {
                  text: 'Logradouro',
                  style: 'tableHeader',
                  fontSize: 10,
                  color: '#FFF',
                },
                {
                  text: 'Latitude',
                  style: 'tableHeader',
                  fontSize: 10,
                  color: '#FFF',
                },
                {
                  text: 'Longitude',
                  style: 'tableHeader',
                  fontSize: 10,
                  color: '#FFF',
                },
              ],
              ...results[esp],
            ],
          },
        }
      );
    }
  });

  details.unshift({
    stack: [
      {
        text: [
          {
            text: `Data e Hora de interesse: `,
          },
          {
            text: `${moment(Date.now()).format('DD/MM/YYYY HH:mm:ss')} `,
          },
        ],
        style: 'time',
      },

      {
        text: `Total AIS: ${totalAis}`,
        style: 'totals',
      },
      {
        text: `Total Especializadas: ${totalEspecializadas}`,
        style: 'totals',
      },
      {
        text: `Total Geral: ${totalAis + totalEspecializadas}`,
        style: 'totals',
        margin: [0, 0, 0, 10],
      },
    ],
  });

  const footer: DynamicContent | Content = [
    {
      text: [
        {
          text:
            'Secretaria de Segurança Pública e Defesa Social. AV. Aguanambi - Aeroporto, Fortaleza - CE, 60415-390. Telefone: (85)3101-6501',
        },
      ],
      fontSize: 8,
      margin: [0, 10, 0, 45],
      alignment: 'center',
    },
  ];

  const docDefinitions: TDocumentDefinitions = {
    pageMargins: [20, 140, 20, 40],
    pageSize: 'A4',
    // @ts-ignore
    alignment: 'center',
    header: [reportTitle],
    content: [details],
    footer: [footer],
    styles: {
      title: {
        fontSize: 20,
        bold: true,
        alignment: 'center',
        margin: [0, 20, 0, 0],
      },
      time: {
        fontSize: 16,
        margin: [0, 0, 0, 20],
      },
      totals: {
        fontSize: 14,
        margin: [0, 0, 0, 2],
      },
      stack: {
        fontSize: 14,
        margin: [0, 0, 0, 10],
        columnGap: 370,
      },
    },
  };
  pdfMake.createPdf(docDefinitions).open({}, win);
};

export default pdfGen;

