/* eslint-disable react/prop-types */
import React from 'react';

import { Card } from 'antd';

const TimeLineItemCard = ({
  dataHora,
  fullname,
  operator,
  terminal,
  comment,
}) => {
  return (
    <Card
      style={{
        width: 300,
        borderRadius: '3px',
        display: 'flex',
        textAlign: 'start',
        boxShadow: '2px 2px 0px rgb(0 128 0 /11%)',
        marginRight: '350px',
      }}
    >
      <p>
        <b>Data:</b> {dataHora}
      </p>
      <p>
        <b>Nome:</b> {fullname || ''}
      </p>
      <p>
        <b>Operador:</b> {operator}
      </p>
      <p>
        <b>Terminal:</b> {terminal}
      </p>
      <p>
        <b>Comentário:</b> {comment}
      </p>
    </Card>
  );
};

export { TimeLineItemCard };

