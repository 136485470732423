import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  getAllPageSuccess,
  getAllSuccess,
  createSuccess,
  updateSuccess,
  deleteSuccess,
  getByIdSuccess,
  error,
  getAllPage,
} from './actions';

import { ServicePrefixo } from '~/services/modulos/servicePrefixo';
import { createSagaReutils, createAllTakeLastDefault } from '~/utils/reutils';

import ACTION from '../actionTypes';

const ACOES_DEFULT = createSagaReutils(
  `/prefixo`,
  ServicePrefixo,
  getAllPage,
  getAllSuccess,
  createSuccess,
  updateSuccess,
  deleteSuccess,
  getByIdSuccess,
  error
);

function* listPageableSaga({ params }) {
  try {
    const list = yield call(ServicePrefixo.getAllPageable, params);
    yield put(getAllPageSuccess(list));
  } catch (e) {
    yield put(error(e));
  }
}

export default all([
  ...createAllTakeLastDefault({ modulo: 'PREFIXO', ACOES: ACOES_DEFULT }),
  takeLatest(ACTION.PREFIXO_GET_SEARCH_PAGE, listPageableSaga),
]);
