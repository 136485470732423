import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  min-height: 55vh;
  padding: 50px 0px 0px 0px;
  width: 100%;
  border: 1px solid green;
  border-radius: 10px;
  position: relative;

  .title {
    font-size: 20px;
    font-weight: bold;
  }

  .infoCard {
    gap: 7px;
    margin: 10px 0px 10px 0px;

    padding: 10px;

    display: flex;
    align-items: center;
    background: rgba(238, 230, 0, 0.3);
    text-align: justify;
    width: 70%;
    border-radius: 10px;

    @media (max-width: 1000px) {
      width: 90%;
      font-size: 14px;
    }

    p {
      margin: 10px 10px 10px 0px;
    }
  }

  .icon {
    font-size: 16px;

    @media (max-width: 1000px) {
      display: none;
    }
  }

  .formClass {
    width: 90%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .itemsClass {
    width: 100%;
  }

  .submitButton {
    width: 120px;
    height: 40px !important;
    margin-bottom: 10px;
    font-size: 16px;
    border-radius: 3px;
  }

  .loading {
    background: rgba(230, 230, 230, 0.5) !important;
  }

  .selectWithoutDropdown {
    .ant-select-item-option-selected {
      display: none;
    }
  }

  .backButton {
    color: green;
    position: absolute;
    left: 40px;
    top: 20px;
    font-size: 16px;
    cursor: pointer;

    :hover {
      border-bottom: 1px solid green;
    }

    @media (max-width: 600px) {
      left: 10px;
    }
  }
`;

