import { MonitoringContext } from 'context/monitoring';
import { useContextSelector } from 'use-context-selector';

export function useMonitoringGoogleMap() {
  const map = useContextSelector(MonitoringContext, context => context.map);

  const setMap = useContextSelector(
    MonitoringContext,
    context => context.setMap
  );

  return {
    map,
    setMap,

  };
}

