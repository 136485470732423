import React, { useEffect, useCallback, useState, useMemo } from 'react';
import {
  Form,
  Spin,
  Card,
  Switch,
  Button,
  Table,
  Select,
  Popconfirm,
  Breadcrumb,
} from 'antd';
import { Router, useHistory, useParams, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  CheckCircleOutlined,
  CloseOutlined,
  DeleteOutlined,
  PlusCircleOutlined,
  SaveOutlined,
} from '@ant-design/icons';
import moment from 'moment';

import { toast } from 'react-toastify';
import TextArea from 'antd/lib/input/TextArea';
import ButtonComponet from '../../../components/button';
import { PageHeader } from '../../../components/PageHeader';
import { TITLE, BASE_ROLE } from './List';
import * as ACTIONS from '~/store/modules/veiculo/actions';
import { ModalCadastroEquipamento } from './Modal/ModalCadastroEquipamento';
import { ModalCadastroPrefixo } from './Modal/ModalCadastroPrefixo';
import { ModalDataAtivDesEquipamento } from './Modal/ModalDataAtivDesEquipamento';

import { Container } from './styles';
import { InputAntdCustom } from '~/components/FormAntd/InputAntd';
import { SelectAddItemAntd } from '~/components/FormAntd/SelectAddItemAntd';
import { ServicePrefixo } from '~/services/modulos/servicePrefixo';
import { SelectAntd } from '~/components/FormAntd/SelectComponents';
import { ServiceTipoVeiculo } from '~/services/modulos/tipoVeiculo';
import { ServiceVinculada } from '~/services/modulos/serviceVinculada';
import { ServiceAIS } from '~/services/modulos/serviceAIS';
import { ServiceLotacao } from '~/services/modulos/serviceLotacao';
import { ServiceTipoViatura } from '~/services/modulos/serviceTipoViatura';
import { ServiceEquipamento } from '~/services/modulos/serviceEquipamento';
import {
  columnsEquipamento,
  columnsPrefixo,
  formatDatasourceEquipamento,
  formatDatasourceHistorico,
  genericInsertAtivacao,
  parseJsonByEquipamentos,
  ruleAisEdit,
  ruleLotacaoEdit,
} from './rules';
import { ModalDataAtivDesPrefixo } from './Modal/ModalDataAtivDesPrefixo';
import { ServiceHistoricoVeiculoPrefixo } from '~/services/modulos/serviceHistoricoVeiculoPrefixo';
import { ServiceVeiculo } from '~/services/modulos/service_veiculo';
import { useAuthData } from 'hooks/auth/useAuthData';

const SUBTITLE = '';
const { Option } = Select;

export function CadastroVeiculoForm() {
  const { data } = useAuthData();
  const { roles } = data;

  const { id } = useParams();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const history = useHistory();
  const { form: entidade, loading } = useSelector(state => state.veiculo);

  const [visibleModal, setVisibleModal] = useState(false);
  const [visiblePrefixoModal, setVisiblePrefixoModal] = useState(false);
  const [visibleAtivModal, setVisibleAtivModal] = useState(false);
  const [visibleAtivDesPrefixoModal, setVisibleAtivDesPrefixoModal] = useState(
    false
  );

  const [prefixoList, setPrefixoList] = useState([]);
  const [tipoVeiculoList, setTipoVeiculoList] = useState([]);
  const [vinculaList, setVinculaList] = useState([]);
  const [aisList, setAISList] = useState([]);
  const [lotacaoList, setLotacaoList] = useState([]);
  const [tipoViaturaList, setTipoViaturaList] = useState([]);
  const [equipamentoList, setEquipamentoList] = useState([]);
  const [equipamentoOriginalList, equipamentoOriginalSetList] = useState([]);
  // const [historicoList, setHistoricoList] = useState([]);
  const [historicoOriginalList, setHistoricoOriginalList] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [
    dataSourceHistoricoPrefixos,
    setDataSourceHistoricoPrefixos,
  ] = useState([]);
  const [equipamentoSelect, setEquipamentoSelect] = useState([]);
  const [prefixoSelected, setPrefixoSelected] = useState(null);
  // const [codigoPrefixo, setCodigoPrefixo] = useState('');
  const [prefixoAtual, setPrefixoAtual] = useState('');

  const isPermitted = useCallback(
    role => {
      return roles.filter(item => item === role).length > 0;
    },
    [roles]
  );

  const showModal = () => {
    setVisibleModal(true);
  };

  const showPrefixoModal = () => {
    setVisiblePrefixoModal(true);
  };

  useEffect(() => {
    if (id) dispatch(ACTIONS.getById(id));
  }, [dispatch, id]);

  const loadPrefixo = useCallback(async () => {
    try {
      const prefixosData = await ServicePrefixo.getAll();
      //setDataSourceHistoricoPrefixos(entidade.prefixos);
      if (
        prefixosData &&
        Array.isArray(prefixosData) &&
        prefixosData.length > 0
      ) {
        setPrefixoList(
          prefixosData.map(p => {
            const { id: idPref, codigo } = p;
            return { id: idPref, codigo };
          })
        );
      }
    } catch (err) {
      toast.error('Erro ao carregar os prefixos!');
    }
  }, []);

  const loadHistorico = useCallback(async () => {
    if (id && entidade && Object.keys(entidade).length > 0) {
      const historicoData = await ServiceHistoricoVeiculoPrefixo.getAllByVeiculo(
        entidade.identificador
      );
      const historicoFormatado = historicoData.content.map(h => ({
        ...h,
        dataAtivacao: moment(h.dataAtivacao),
        dataDesativacao: h.dataDesativacao ? moment(h.dataDesativacao) : null,
        statusPrefixo: h.dataDesativacao ? 'Inativo' : 'Ativo',
        ativacao: moment(h.dataAtivacao)
          .local()
          .format('DD/MM/YYYY HH:mm:ss'),
        desativacao: h.dataDesativacao
          ? moment(h.dataDesativacao)
              .local()
              .format('DD/MM/YYYY HH:mm:ss')
          : '-',
      }));

      setHistoricoOriginalList(historicoFormatado);
    }
  }, [id, entidade]);

  const loadTipoVeiculo = useCallback(async () => {
    const tipoVeiculoData = await ServiceTipoVeiculo.getAll();
    setTipoVeiculoList(
      tipoVeiculoData.map(tvd => ({ key: tvd.id, value: tvd.nome }))
    );
  }, []);

  const loadVinculadas = useCallback(async () => {
    const vinculadasData = await ServiceVinculada.getAll();
    setVinculaList(
      vinculadasData.map(vin => ({ key: vin.id, value: vin.nome }))
    );
  }, []);

  const loadAIS = useCallback(async () => {
    const data = await ServiceAIS.getAll();
    setAISList(data.map(d => ({ key: d.id, value: d.nome })));
  }, []);

  const loadLotacao = useCallback(async () => {
    const data = await ServiceLotacao.getAll();
    setLotacaoList(data.map(d => ({ key: d.id, value: d.sigla })));
  }, []);

  const loadTipoViatura = useCallback(async () => {
    const data = await ServiceTipoViatura.getAll();
    setTipoViaturaList(data.map(d => ({ key: d.id, value: d.nome })));
  }, []);

  const loadEquipamento = useCallback(async () => {
    try {
      const data = await ServiceEquipamento.getAll();
      // setDataSource(data);
      equipamentoOriginalSetList(data);

      if (data && Array.isArray(data) && data.length > 0) {
        setEquipamentoList(
          data.map(d => {
            const informacoes = [];

            if (d.identificacao) {
              informacoes.push(d.identificacao);
            }

            if (d.modulo) {
              informacoes.push(d.modulo);
            }

            if (d.tipoEquipamento && d.tipoEquipamento.nome) {
              informacoes.push(d.tipoEquipamento.nome);
            }

            if (d.tipoTecnologia && d.tipoTecnologia.empresa) {
              informacoes.push(d.tipoTecnologia.empresa);
            }

            return {
              key: d.id,
              value: `${informacoes.join(' - ')}`,
            };
          })
        );
      }
    } catch (err) {
      toast.error('Erro ao carregar os equipamentos!');
    }
  }, []);

  useEffect(() => {
    loadPrefixo();
    loadTipoVeiculo();
    loadVinculadas();
    loadAIS();
    loadLotacao();
    loadTipoViatura();
    loadEquipamento();
    loadHistorico();
  }, [
    loadAIS,
    loadLotacao,
    loadPrefixo,
    loadTipoVeiculo,
    loadTipoViatura,
    loadVinculadas,
    loadEquipamento,
    loadHistorico,
  ]);

  const handleSetEntidade = useCallback(() => {
    if (entidade && entidade.id && id) {
      const {
        nome,
        ativo,
        disponivel,
        placaVeiculo,
        tipoVeiculo,
        tipoViatura,
        prefixos,
        equipamentos,
        ais,
        lotacaoes,
        vinculada,
        descricao,
        identificador,
      } = entidade;
      let prefixo;
      if (prefixos && prefixos.length > 0) {
        prefixo = prefixos.filter(p => !p.dataDesativacao)[0]?.prefixo.id;
      }

      let aisAtiva;
      if (ais && ais.length > 0) {
        aisAtiva = ais.filter(p => !p.dataDesativacao)[0].ais.id;
      }

      let lotacao;
      if (lotacaoes && lotacaoes.length > 0) {
        lotacao = lotacaoes.filter(p => !p.dataDesativacao)[0].lotacao.id;
      }

      setDataSource(
        parseJsonByEquipamentos(equipamentos, equipamentoOriginalList)
      );

      setDataSourceHistoricoPrefixos(historicoOriginalList);
      // setDataSourceHistoricoPrefixos(prefixos);

      if (historicoOriginalList.length > 0) {
        setPrefixoAtual(historicoOriginalList[0].prefixo.codigo);
      }

      form.setFieldsValue({
        nome,
        ativo,
        disponivel,
        placaVeiculo,
        tipoVeiculo: tipoVeiculo.id,
        tipoViatura: tipoViatura.id,
        // prefixo,
        lotacao,
        ais: aisAtiva,
        vinculada: vinculada ? vinculada.id : null,
        descricao,
        identificador,
      });
    }
  }, [entidade, equipamentoOriginalList, form, id, historicoOriginalList]);

  useEffect(() => {
    handleSetEntidade();
  }, [id, handleSetEntidade]);

  const handleSubmit = useCallback(
    values => {
      try {
        const {
          identificador,
          ativo,
          disponivel,
          tipoVeiculo,
          tipoViatura,
          vinculada,
          ais,
          lotacao,
          descricao,
        } = values;

        const dados = {
          identificador,
          disponivel,
          ativo,
          tipoViatura: { id: tipoViatura },
          tipoVeiculo: { id: tipoVeiculo },
          descricao,
        };

        if (!id) {
          // if (prefixo) {
          //   dados.prefixos = genericInsertAtivacao({
          //     name: 'prefixo',
          //     value: prefixo,
          //   });
          // }
          if (vinculada) {
            dados.vinculada = {
              id: vinculada,
            };
          }
          if (ais) {
            dados.ais = genericInsertAtivacao({
              name: 'ais',
              value: ais,
            });
          }

          dados.equipamentos = formatDatasourceEquipamento(dataSource);

          dados.prefixos = formatDatasourceHistorico(
            dataSourceHistoricoPrefixos
          );

          if (lotacao) {
            dados.lotacaoes = genericInsertAtivacao({
              name: 'lotacao',
              value: lotacao,
            });
          }

          ServiceVeiculo.saveVeiculo(dados);

          history.goBack();
          toast.success('Veículo criado com sucesso!');
          // dispatch(ACTIONS.create({ data: dados, history }));
        } else {
          dados.id = id;

          const { ais: aisUpdated, lotacaoes } = entidade;

          if (vinculada) {
            dados.vinculada = {
              id: vinculada,
            };
          }

          dados.ais = ruleAisEdit({ ais, aisUpdated });

          dados.equipamentos = formatDatasourceEquipamento(dataSource);

          dados.lotacaoes = ruleLotacaoEdit({
            lotacao,
            lotacaoes,
          });
          dados.prefixos = formatDatasourceHistorico(
            dataSourceHistoricoPrefixos
          );

          if (
            dados.equipamentos?.filter(e => !e.dataDesativacao).length > 1 ||
            dados.prefixos?.filter(p => !p.dataDesativacao).length > 1
          ) {
            throw new Error();
          }
          ServiceVeiculo.update(dados);

          history.goBack();
          toast.success('Veículo alterado com sucesso!');
        }

        // history.goBack();
      } catch (err) {
        console.log(err);
        toast.error('Erro ao salvar registro');
      }
    },
    [dataSource, entidade, id, history, dataSourceHistoricoPrefixos]
  );

  const mondalOk = useCallback(() => {
    setVisibleModal(false);
    loadEquipamento();
  }, [loadEquipamento]);

  const modalPrefixoOk = useCallback(
    values => {
      try {
        setPrefixoSelected(null);
        const localDateAtiv = moment(values.dataAtivacao).local();
        const localDateDes = values.dataDesativacao
          ? moment(values.dataDesativacao).local()
          : null;

        const dados = {
          prefixo: {
            id: values.prefixoId,
            codigo: values.codigo,
          },
          // veiculoId: id || -1,
          statusPrefixo: 'Inativo',
          dataAtivacao: localDateAtiv,
          dataDesativacao: values.dataDesativacao ? localDateDes : null,
          ativacao: localDateAtiv.format('DD/MM/YYYY HH:mm:ss'),
          desativacao: localDateDes
            ? localDateDes.format('DD/MM/YYYY HH:mm:ss')
            : '-',
        };

        if (
          !dataSourceHistoricoPrefixos.filter(
            h => h.prefixo.id === dados.prefixo.id
          ).length
        ) {
          setDataSourceHistoricoPrefixos([
            ...dataSourceHistoricoPrefixos,
            dados,
          ]);
        } else {
          setDataSourceHistoricoPrefixos(
            dataSourceHistoricoPrefixos.map(h =>
              h.prefixo.id === dados.prefixo.id ? dados : h
            )
          );
        }

        setPrefixoSelected(null);
        setVisibleAtivDesPrefixoModal(false);
        setVisiblePrefixoModal(false);
      } catch (err) {
        toast.error('Erro ao vincular prefixo ao veículo');
      }
    },
    [dataSourceHistoricoPrefixos]
  );

  const modalSetDtAtivacaoOk = useCallback(
    values => {
      setEquipamentoSelect(null);
      const equipamentoFiltered = equipamentoOriginalList.filter(
        e => e.id === equipamentoSelect
      );

      if (equipamentoFiltered && equipamentoFiltered.length > 0) {
        const localDateAtiv = moment(values.dataAtivacao).local();
        const localDateDes = values.dataDesativacao
          ? moment(values.dataDesativacao).local()
          : null;

        const dados = {
          key: equipamentoFiltered[0].id,
          tipoEquipamento: equipamentoFiltered[0].tipoEquipamento.nome,
          serie: equipamentoFiltered[0].identificacao,
          empresa: equipamentoFiltered[0].tipoTecnologia
            ? equipamentoFiltered[0].tipoTecnologia.empresa
            : null,
          ativacao: localDateAtiv.format('DD/MM/YYYY HH:mm:ss'),
          desativacao: localDateDes
            ? localDateDes.format('DD/MM/YYYY HH:mm:ss')
            : '',
          momentAtivacao: localDateAtiv,
          momentDesativacao: localDateDes,
        };

        if (dataSource.filter(d => d.key === dados.key).length > 0) {
          setDataSource(dataSource.map(d => (d.key === dados.key ? dados : d)));
        } else {
          setDataSource(prev => [...prev, dados]);
        }
        setVisibleAtivModal(false);
        setEquipamentoSelect();
      }
    },
    [dataSource, equipamentoOriginalList, equipamentoSelect]
  );

  const modalSetDtAtivacaoOnCancel = () => {
    setVisibleAtivModal(false);
    setEquipamentoSelect(null);
  };

  const modalPrefixoOnCancel = () => {
    setVisibleAtivDesPrefixoModal(false);
    setPrefixoSelected(null);
  };

  const handleResetForm = useCallback(() => {
    if (id) {
      handleSetEntidade();
    } else {
      form.resetFields();
    }
  }, [form, handleSetEntidade, id]);

  const validarData = (novaDataAtivacao, novaDataDesativacao) => {
    if (
      novaDataDesativacao &&
      moment(novaDataAtivacao)
        .local()
        .isAfter(moment(novaDataDesativacao).local())
    ) {
      return {
        status: false,
        mensagem:
          'A data de ativação não pode ser superior à data de desativação!',
      };
    }

    let first = true;
    let isSame = false;

    dataSourceHistoricoPrefixos.map(d => {
      if (
        moment(d.dataAtivacao)
          .local()
          .isSame(moment(novaDataAtivacao).local(), 'second')
      ) {
        isSame = true;
      }
    });

    if (isSame) {
      return {
        status: false,
        mensagem:
          'Não é possível inserir mais de um prefixo com a mesma data de ativação!',
      };
    }

    dataSourceHistoricoPrefixos.map(d => {
      if (
        d.prefixo.id !== prefixoSelected &&
        moment(d.dataAtivacao)
          .local()
          .isAfter(moment(novaDataAtivacao).local())
      ) {
        first = false;
      } else if (!d.dataDesativacao) {
        d.dataDesativacao = moment(novaDataAtivacao).local();
        d.desativacao = moment(novaDataAtivacao)
          .local()
          .format('DD/MM/YYYY HH:mm:ss');
      }
    });

    if (!first && !novaDataDesativacao) {
      return {
        status: false,
        mensagem:
          'Para inserir um prefixo inativo, é preciso informar a data de desativação!',
      };
    }
    return {
      status: true,
      mensagem: 'Validado com sucesso!',
    };
  };

  const handleDeletePrefixo = value => {
    setDataSourceHistoricoPrefixos(
      dataSourceHistoricoPrefixos.filter(d => d.prefixo.id !== value.prefixo.id)
    );
  };

  const handleDeleteEquipamento = value => {
    setDataSource(dataSource.filter(d => d.key !== value.key));
  };

  const compareData = (h1, h2) => {
    h1.statusPrefixo = 'Inativo';
    h2.statusPrefixo = 'Inativo';
    return moment(h1.dataAtivacao).isAfter(moment(h2.dataAtivacao)) ? -1 : 1;
  };

  useEffect(() => {
    const sortedArray = dataSourceHistoricoPrefixos.sort(compareData);

    if (sortedArray.length > 0 && !sortedArray[0].prefixo.dataDesativacao) {
      if (!sortedArray[0].dataDesativacao)
        sortedArray[0].statusPrefixo = 'Ativo';
      setPrefixoAtual(sortedArray[0].prefixo.codigo);
    }
    setDataSourceHistoricoPrefixos(sortedArray);
  }, [dataSourceHistoricoPrefixos]);

  // const coolumsWithAllColumns = useMemo(() => {
  //   return [
  //     ...columnsEquipamento,
  //     {
  //       title: 'Ações',
  //       key: 'action',
  //       align: 'center',
  //       render: (text, record) => (
  //         <div style={{ display: 'flex', justifyContent: 'center' }}>
  //           <Popconfirm
  //             title="Deseja excluir o registro?"
  //             onConfirm={() => {
  //               setDataSource(prev =>
  //                 prev.filter(value => value.key !== record.key)
  //               );
  //             }}
  //           >
  //             <Button
  //               type="danger"
  //               icon={<DeleteOutlined />}
  //               style={{
  //                 display: 'flex',

  //                 alignItems: 'center',

  //                 justifyContent: 'center',
  //               }}
  //             />
  //           </Popconfirm>
  //         </div>
  //       ),
  //     },
  //   ];
  // }, []);
  return (
    <Container>
      <Breadcrumb style={{ padding: '10px' }}>
        <Breadcrumb.Item>
          <Link to="/">Página Inicial</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to="/cadastros">Cadastros</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to="/veiculo">Veículos</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          {form.getFieldsValue().identificador
            ? form.getFieldsValue().identificador
            : 'Novo veiculo'}
        </Breadcrumb.Item>
      </Breadcrumb>
      <Card className="card" style={{ margin: 15 }}>
        <PageHeader
          title={`${id ? 'Edição' : 'Cadastro'} - ${TITLE}`}
          subTitle={SUBTITLE}
          baseRole={BASE_ROLE}
          activeBackHistory
        />

        <Spin spinning={loading}>
          <Form
            form={form}
            layout="vertical"
            initialValues={{ ativo: true, disponivel: true }}
            onFinish={handleSubmit}
            onReset={handleResetForm}
          >
            <section className="container">
              <div className="row">
                {/* placaVeiculo de Veiculo */}
                <div className="item">
                  <Form.Item
                    label="Placa do Veículo"
                    name="identificador"
                    rules={[
                      {
                        required: true,
                        message: 'Por favor, informe a placa veículo',
                      },
                    ]}
                  >
                    <InputAntdCustom size="large" />
                  </Form.Item>
                </div>

                {/* Prefixo */}
                <div className="item">
                  <Form.Item
                    label="Prefixo"
                    rules={[
                      {
                        required: true,
                        message: 'Por favor, informe o Prefixo',
                      },
                    ]}
                  >
                    <InputAntdCustom
                      size="large"
                      disabled
                      initialValue={prefixoAtual}
                      value={prefixoAtual}
                    />
                    {/* <SelectAddItemAntd
                      allowClear
                      disabled
                      showArrow={false}
                      size="large"
                      filterOption={(input, option) => {
                        const v = option.children;
                        return (
                          v.toLowerCase().indexOf(input.trim().toLowerCase()) >=
                          0
                        );
                      }}
                      dropdownRender={menu => (
                        <div>
                          {menu}
                          <Divider style={{ margin: '4px 0' }} />
                          <div
                            style={{
                              display: 'flex',
                              flexWrap: 'nowrap',
                              padding: 8,
                            }}
                          >
                            <Input
                              id="campoPrefixo"
                              value={codigoPrefixo}
                              onChange={e => {
                                setCodigoPrefixo(e.target.value.toUpperCase());
                              }}
                              style={{ flex: 'auto' }}
                            />
                            <Popconfirm
                              placement="top"
                              title="Deseja adicionar o Prefixo"
                              onConfirm={async () => {
                                if (codigoPrefixo) {
                                  try {
                                    await ServicePrefixo.create({
                                      codigo: codigoPrefixo,
                                      ativo: true,
                                    });
                                    document.getElementById(
                                      'campoPrefixo'
                                    ).value = codigoPrefixo;
                                    setCodigoPrefixo();
                                  } catch (err) {
                                    toast.error('Falha ao adicionar!');
                                  }
                                } else {
                                  toast.warn('Digite um valor para o prefixo?');
                                }
                              }}
                              okText="Sim"
                              cancelText="Não"
                            >
                              <Button type="primary">
                                <PlusOutlined /> Add
                              </Button>
                            </Popconfirm>
                          </div>
                        </div>
                      )}
                    >
                      {prefixoList.map(item => (
                        <Option key={item.id} value={item.id}>
                          {item.codigo}
                        </Option>
                      ))}
                    </SelectAddItemAntd> */}
                  </Form.Item>
                </div>

                {/* Tipo de Veículos */}
                <div className="item">
                  <Form.Item
                    label="Tipo de Veículo"
                    name="tipoVeiculo"
                    rules={[
                      {
                        required: true,
                        message: 'Por favor, informe o Tipo de Veículo',
                      },
                    ]}
                  >
                    <SelectAntd
                      virtual={false}
                      size="large"
                      data={tipoVeiculoList}
                    />
                  </Form.Item>
                </div>

                <div className="item">
                  <Form.Item
                    label="Vinculada"
                    name="vinculada"
                    rules={[
                      {
                        required: false,
                        message: 'Por favor, informe a vinculada',
                      },
                    ]}
                  >
                    <SelectAntd
                      virtual={false}
                      size="large"
                      data={vinculaList}
                    />
                  </Form.Item>
                </div>

                <div className="item">
                  <Form.Item
                    label="Finalidade"
                    name="tipoViatura"
                    rules={[
                      {
                        required: true,
                        message: 'Por favor, informe a finalidade',
                      },
                    ]}
                  >
                    <SelectAntd
                      virtual={false}
                      size="large"
                      data={tipoViaturaList}
                    />
                  </Form.Item>
                </div>

                <Form.Item
                  noStyle
                  shouldUpdate={(prev, current) =>
                    prev.tipoViatura !== current.tipoViatura
                  }
                >
                  {({ getFieldValue }) => {
                    const vinculo = getFieldValue('tipoViatura');
                    return vinculo === 1 ? (
                      <div className="item">
                        <Form.Item
                          label="AIS"
                          name="ais"
                          rules={[
                            {
                              required: false,
                              message: 'Por favor, informe a AIS',
                            },
                          ]}
                        >
                          <SelectAntd
                            virtual={false}
                            size="large"
                            data={aisList}
                          />
                        </Form.Item>
                      </div>
                    ) : null;
                  }}
                </Form.Item>

                <Form.Item
                  noStyle
                  shouldUpdate={(prev, current) =>
                    prev.tipoViatura !== current.tipoViatura
                  }
                >
                  {({ getFieldValue }) => {
                    const vinculo = getFieldValue('tipoViatura');
                    return vinculo === 1 ? (
                      <div className="item">
                        <Form.Item
                          label="Lotação"
                          name="lotacao"
                          rules={[
                            {
                              required: false,
                              message: 'Por favor, informe a Lotação',
                            },
                          ]}
                        >
                          <SelectAntd
                            virtual={false}
                            size="large"
                            data={lotacaoList}
                          />
                        </Form.Item>
                      </div>
                    ) : null;
                  }}
                </Form.Item>

                <div className="item">
                  <Form.Item
                    label="Descrição"
                    name="descricao"
                    // valuePropName="checked"
                  >
                    <TextArea />
                  </Form.Item>
                </div>

                <div className="item">
                  <Form.Item
                    label="Veículo ativo"
                    name="ativo"
                    valuePropName="checked"
                  >
                    <Switch />
                  </Form.Item>
                </div>

                <div className="item">
                  <Form.Item
                    label="Operante"
                    name="disponivel"
                    valuePropName="checked"
                  >
                    <Switch />
                  </Form.Item>
                </div>

                <div className="item" />
              </div>
              <div
                style={{
                  marginBottom: 10,
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <div style={{ display: 'flex', width: '100%' }}>
                  <div
                    style={{ display: 'flex', width: '100%' }}
                    className="ant-row ant-form-item"
                  >
                    <div
                      style={{ display: 'flex', width: '100%' }}
                      className="ant-col ant-form-item-label"
                    >
                      <div htmlFor="prefixo" title="Prefixo">
                        Equipamentos
                      </div>
                    </div>
                    <div
                      style={{ display: 'flex', width: '100%' }}
                      className="ant-col ant-form-item-control"
                    >
                      <div
                        style={{ display: 'flex', width: '100%' }}
                        className="ant-form-item-control-input"
                      >
                        <div
                          style={{ display: 'flex', width: '100%' }}
                          className="ant-form-item-control-input-content"
                        >
                          <SelectAntd
                            virtual={false}
                            style={{
                              display: 'flex',
                              flex: 1,
                              width: '100%',
                              marginRight: 10,
                            }}
                            size="large"
                            value={equipamentoSelect}
                            onChange={value => {
                              setEquipamentoSelect(value);
                            }}
                            data={equipamentoList}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginRight: 10,
                  }}
                >
                  <Button
                    className="botao-cheque"
                    type="primary"
                    shape="circle"
                    icon={<CheckCircleOutlined />}
                    size={20}
                    onClick={() => {
                      if (equipamentoSelect) setVisibleAtivModal(true);
                    }}
                  />
                </div>

                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Button
                    className="botao-add"
                    type="primary"
                    shape="circle"
                    icon={<PlusCircleOutlined />}
                    size={20}
                    onClick={showModal}
                  />
                </div>
              </div>
              <Table
                className="table"
                tableLayout="fixed"
                dataSource={dataSource}
                columns={columnsEquipamento({
                  onDelete: handleDeleteEquipamento,
                  setRegistroSelected: setEquipamentoSelect,
                  setVisibleModal: setVisibleAtivModal,
                })}
              />

              <div
                style={{
                  marginBottom: 10,
                  marginTop: 15,
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <div style={{ display: 'flex', width: '100%' }}>
                  <div
                    style={{ display: 'flex', width: '100%' }}
                    className="ant-row ant-form-item"
                  >
                    <div
                      style={{
                        display: 'flex',
                        width: '100%',
                      }}
                      className="ant-col ant-form-item-label"
                    >
                      <div htmlFor="prefixo" title="Prefixo">
                        Prefixos
                      </div>
                    </div>
                    {isPermitted('ROLE_ROTAS_CADASTROS') && (
                      <div
                        style={{ display: 'flex', width: '100%' }}
                        className="ant-col ant-form-item-control"
                      >
                        <div
                          style={{ display: 'flex', width: '100%' }}
                          className="ant-form-item-control-input"
                        >
                          <div
                            style={{
                              display: 'flex',
                              width: '100%',
                              marginRight: 10,
                            }}
                            className="ant-form-item-control-input-content"
                          >
                            <SelectAddItemAntd
                              style={{ width: '100%' }}
                              allowClear
                              showSearch
                              size="large"
                              value={prefixoSelected}
                              onChange={value => {
                                setPrefixoSelected(value);
                              }}
                              filterOption={(input, option) => {
                                const v = option.children;
                                return (
                                  v
                                    .toLowerCase()
                                    .indexOf(input.trim().toLowerCase()) >= 0
                                );
                              }}
                              dropdownRender={menu => <div>{menu}</div>}
                            >
                              {prefixoList.map(item => (
                                <Option key={item.id} value={item.id}>
                                  {item.codigo}
                                </Option>
                              ))}
                            </SelectAddItemAntd>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                {isPermitted('ROLE_ROTAS_CADASTROS') && (
                  <>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginRight: 10,
                      }}
                    >
                      <Button
                        className="botao-cheque"
                        type="primary"
                        shape="circle"
                        icon={<CheckCircleOutlined />}
                        size={20}
                        onClick={() => {
                          if (prefixoSelected)
                            setVisibleAtivDesPrefixoModal(true);
                        }}
                      />
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Button
                        className="botao-add"
                        type="primary"
                        shape="circle"
                        icon={<PlusCircleOutlined />}
                        size={20}
                        onClick={showPrefixoModal}
                      />
                    </div>
                  </>
                )}
              </div>

              <Table
                className="table"
                tableLayout="fixed"
                dataSource={dataSourceHistoricoPrefixos}
                columns={columnsPrefixo({
                  onDelete: handleDeletePrefixo,
                  setRegistroSelected: setPrefixoSelected,
                  setVisibleModal: setVisibleAtivDesPrefixoModal,
                })}
                sortDirections={false}
                rowKey="dataAtivacao"
              />
            </section>
            <div className="botoes">
              <ButtonComponet
                type="submit"
                buttonStyle="btn--primary"
                icon={<SaveOutlined />}
              >
                Salvar
              </ButtonComponet>
              <Popconfirm
                title="Deseja descartar as alterações?"
                onConfirm={handleResetForm}
              >
                <ButtonComponet
                  type="reset"
                  buttonStyle="btn--secondary"
                  icon={<CloseOutlined />}
                >
                  Cancelar
                </ButtonComponet>
              </Popconfirm>

              {/*  <button className="botao-salvar" type="submit" name="submit">
                <SaveOutlined />
                Salvar
              </button>
              <button className="botao-cancelar" type="submit" name="submit">
                <CloseOutlined />
                Cancelar
              </button> */}
            </div>
          </Form>
        </Spin>
      </Card>

      <ModalCadastroPrefixo
        visible={visiblePrefixoModal}
        onCancel={() => setVisiblePrefixoModal(false)}
        onOk={modalPrefixoOk}
        validarData={validarData}
      />

      <ModalDataAtivDesPrefixo
        prefixoSelected={prefixoSelected}
        visible={visibleAtivDesPrefixoModal}
        onCancel={modalPrefixoOnCancel}
        onOk={modalPrefixoOk}
        validarData={validarData}
        historicoList={dataSourceHistoricoPrefixos}
      />

      <ModalCadastroEquipamento
        visible={visibleModal}
        onCancel={() => setVisibleModal(false)}
        onOk={mondalOk}
      />

      <ModalDataAtivDesEquipamento
        visible={visibleAtivModal}
        onCancel={modalSetDtAtivacaoOnCancel}
        onOk={modalSetDtAtivacaoOk}
        equipamentoSelected={equipamentoSelect}
        equipamentoList={dataSource}
      />
    </Container>
  );
}
