/* eslint-disable */
import styled from 'styled-components';

export const Container = styled.div`
  .card {
    height: 100%;
    border-radius: 5px;
    height: 260px;
    width: 220px;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    font-size: 16px;
    background-color: white;
  }

  .cardTitle {
    display: flex;
    width: 100%;
  }

  .cardBody {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    width: 100%;
  }

  .cardFooter {
  }

  #valueAlert {
    font-weight: 600;
    font-size: 16px;
  }

  #SAIU_DA_CERCA {
    color: rgb(160, 160, 160);
  }

  .SAIU_DA_CERCA_MODAL_ALERT {
    border: 1px solid rgba(230, 230, 230, 1);
    /* box-shadow: 1px 1px 2px 1px rgb(150, 150, 150); */
    color: grey;
  }

  .LIMITE_VELOCIDADE_MODAL_ALERT {
    border: 1px solid rgba(230, 230, 230, 1);
    /* box-shadow: 1px 1px 1px 1px #0b918a; */
    color: #0b918a;
  }

  .FORA_AIS_MODAL_ALERT {
    border: 1px solid rgba(230, 230, 230, 1);
    /* box-shadow: 1px 1px 1px 1px rgb(255, 0, 0); */
    color: rgb(255, 0, 0);
  }

  .PARADA_SUPERIOR_15_MIN_MODAL_ALERT {
    border: 1px solid rgba(230, 230, 230, 1);
    /* box-shadow: 1px 1px 1px 1px #0e75ba; */
    color: #0e75ba;
  }

  #cardIcon {
    width: 40px;
    height: 40px;
  }
`;

