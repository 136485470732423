import React, { useCallback, useEffect, useState } from 'react';

import { useHistory, Link, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Spin, PageHeader, Breadcrumb, Timeline, Button } from 'antd';
import { toast } from 'react-toastify';
import { ReactComponent as LocalizationIcon } from '../../../../imgs/localization.svg';
import { ReactComponent as DocumentoIcon } from '../../../../imgs/documento.svg';
import { CardOcorrencia } from '../../componente/card';

import { OcorrenciaMapa } from './components/Mapa';
import style from './styles.module.css';
import BackgroundGoogleMaps from '../../../../imgs/background-googleMaps.png';

import { ServiceOcorrencia } from 'services/modulos/serviceOcorrencia';
import { pdfReport } from './pdfReport';
import { TimeLineItemCard } from './components/TimeLineItemCard';
import { OccurrenceSocket } from 'interfaces/vehicle';
import { getGeocode, getLatLng } from 'use-places-autocomplete';

import { v4 as uuidv4 } from 'uuid';
import { useJsApiLoader } from '@react-google-maps/api';
import ENV from 'config';
import { googleLibraries } from 'utils/functionsUtils';

export const OcorrenciaPorProtocolo = () => {
  // @ts-ignore
  const { id } = useParams();
  const history = useHistory();
  // @ts-ignore
  const { loading } = useSelector(state => state.ocorrencia);
  const [entidade, setEntidade] = useState<
    OccurrenceSocket & { hasPosition?: boolean }
  >();
  const [waitResumo, setWaitResumo] = useState(false);
  const [mapImg, setMapImg] = useState();
  const [logradouro, setLogradouro] = useState('');

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: ENV.googleMapsApiKey,
    libraries: googleLibraries,
    region: 'BR',
    language: 'pt-br',
  });

  const loadLatLngOccurrence = useCallback(
    async (address: string) => {
      if (isLoaded) {
        try {
          const geocode = await getGeocode({ address });

          if (!geocode.length) {
            throw new Error();
          }
          return getLatLng(geocode[0]);
        } catch (e) {
          console.log('e', e);
        }
      }
    },
    [getGeocode, isLoaded]
  );

  const load = useCallback(async () => {
    try {
      setWaitResumo(true);
      const res: OccurrenceSocket & {
        hasPosition?: boolean;
      } = await ServiceOcorrencia.getById({ id });

      res.hasPosition = true;

      if (!res.latLng || (res.x === 0.0 && res.y === 0.0)) {
        if (!res.endereco) {
          res.hasPosition = false;
        }

        const { lat, lng } = (await loadLatLngOccurrence(res.endereco)) || {
          lat: 0,
          lng: 0,
        };

        if (lat && lng) {
          res.latLng = {
            lat,
            lng,
          };
        } else {
          res.hasPosition = false;
        }
      }

      setEntidade(res);
    } catch (e) {
      toast.error('Erro ao carregar ocorrência. Tente novamente');
    } finally {
      setWaitResumo(false);
    }
  }, [id, loadLatLngOccurrence]);

  useEffect(() => {
    load();
  }, [load]);

  const subHours = (date: string) => {
    const data = new Date(date);
    data.setHours(data.getHours() - 3);
    return data.toLocaleString();
  };

  return (
    <>
      <Spin spinning={waitResumo}>
        <Breadcrumb style={{ padding: '10px' }}>
          <Breadcrumb.Item>
            {/* @ts-expect-error Server Component */}
            <Link to="/">Página Inicial</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            {/* @ts-expect-error Server Component */}
            <Link to="/ocorrencias">Ocorrências</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>{id}</Breadcrumb.Item>
        </Breadcrumb>
        <Spin spinning={loading}>
          <PageHeader
            className="site-page-header"
            onBack={() => history.goBack()}
            title="Visualização da Ocorrência"
            subTitle=""
          />
          <div style={{ textAlign: 'end' }}>
            <Button onClick={() => pdfReport(entidade, mapImg, logradouro)}>
              Gerar Relatório
            </Button>
          </div>
          <div className={style.container}>
            <div className={style.cardSpace}>
              <CardOcorrencia
                icon={<DocumentoIcon className={style.icon} />}
                tituloCard="Ocorrência"
                dados={[
                  {
                    title: 'Número',
                    value: entidade?.ocorrencia || '',
                  },

                  {
                    title: 'Agência',
                    value: entidade?.agencia || '',
                  },
                  {
                    title: 'Local',
                    value: entidade?.endereco || '',
                  },
                  {
                    title: 'Grupo',
                    value: entidade?.grupo || '',
                  },
                  {
                    title: 'Viaturas',
                    value: entidade?.viaturasAtribuidas || '',
                  },

                  {
                    title: 'Tipo Cod.',
                    value: `${entidade?.tipoCodigo}`,
                  },
                  {
                    title: 'Tipo Sub./Desc.',
                    value: `${entidade?.subTipoCodigo} - ${entidade?.subTipoDesc}`,
                  },
                  {
                    title: 'Descrição.',
                    value: entidade?.tipoDesc || '',
                  },
                ]}
              />

              <CardOcorrencia
                icon={<LocalizationIcon className={style.icon} />}
                tituloCard="Cronologia"
                dados={[
                  {
                    title: 'Criada em',
                    value: entidade?.dtCriacao
                      ? new Date(entidade?.dtCriacao).toLocaleString()
                      : '',
                  },
                  {
                    title: 'Comunicação',
                    value: entidade?.comunicacao
                      ? new Date(entidade?.comunicacao).toLocaleString()
                      : 'Sem informação',
                  },
                  {
                    title: 'Despacho',
                    value: entidade?.dtDespacho
                      ? new Date(entidade?.dtDespacho).toLocaleString()
                      : 'Sem informação',
                  },
                  {
                    title: 'Rota',
                    value: entidade?.dtEmRota
                      ? new Date(entidade?.dtEmRota).toLocaleString()
                      : 'Sem informação',
                  },
                  {
                    title: 'Chegada',
                    value: entidade?.dtChegada
                      ? new Date(entidade?.dtChegada).toLocaleString()
                      : 'Sem informação',
                  },
                  {
                    title: 'Chegada Transporte',
                    value: entidade?.dtTransporte
                      ? new Date(entidade?.dtTransporte).toLocaleString()
                      : 'Sem informação',
                  },
                  {
                    title: 'Encerramento',
                    value: entidade?.dtEncerramento
                      ? new Date(entidade?.dtEncerramento).toLocaleString()
                      : 'Sem informação',
                  },
                  {
                    title: 'Finalizações',
                    value: entidade?.finalizacoes || '',
                  },
                ]}
              />
            </div>
            <div
              className={style.comentario}
              style={{
                borderRadius: '5px',
                boxShadow: '1px 2px 2px #888888',
              }}
            >
              <div>
                <h1
                  style={{
                    textAlign: 'center',
                    padding: '15px',
                  }}
                >
                  Linha do Tempo
                </h1>
              </div>
              <Timeline
                mode="alternate"
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                {Object.keys(entidade || {}).length > 0 &&
                  entidade?.ocorrenciaCronologiaList.map(com => {
                    return (
                      <Timeline.Item
                        color="green"
                        className={style.timelineItemAntd}
                        key={uuidv4()}
                      >
                        <TimeLineItemCard
                          dataHora={com.dataHora ? subHours(com.dataHora) : ''}
                          operator={com.operador}
                          fullname={com.nomeCompleto}
                          terminal={com.terminal}
                          comment={com.comentario}
                        />
                      </Timeline.Item>
                    );
                  })}
              </Timeline>
            </div>
          </div>

          <div
            style={{
              height: 500,
              width: '100%',
              display: 'grid',
              marginTop: '3%',
            }}
          >
            {!entidade?.hasPosition && (
              <div className={style.errorGoogleMaps}>
                <img
                  style={{ width: '100%', height: '100%' }}
                  src={BackgroundGoogleMaps}
                  alt="maps.png"
                />
                <h1>Posição não fornecida!</h1>{' '}
              </div>
            )}
            {Object.keys(entidade || {}).length > 0 && entidade?.hasPosition && (
              <>
                <OcorrenciaMapa
                  isLoaded={isLoaded}
                  latLong={entidade?.latLng}
                  setMapImg={setMapImg}
                  setLogradouro={setLogradouro}
                />
              </>
            )}
          </div>
        </Spin>
      </Spin>
    </>
  );
};
