import styled from 'styled-components';

export const Container = styled.div`
  overflow: auto;

  .dataSourceSelect {
    display: flex;
    justify-content: center;
    position: absolute;
    top: 10px;
    left: 30%;
  }

  .ant-collapse-icon-position-end > .ant-collapse-item > .ant-collapse-header {
    padding-right: 90px;
    margin-right: 17%;
  }
  .ant-switch {
    background: #c4c4c4;
    .ant-switch-handle::before {
      background-color: #fc6836;
    }
  }

  .ant-switch-checked {
    .ant-switch-handle::before {
      background-color: #1a784a;
    }
  }
  #slotFiltro {
    --altura: 49%;
    --largura: 40%;
    --escalaCinza: 190;
    /*0 a 255*/
    position: absolute;
    width: var(--largura);
    max-height: var(--altura);
    padding-bottom: 0.5rem;
    top: 1.5%;
    left: 50px;
    color: var(--corPrincipalMaisEscura);
    background: rgba(
      var(--escalaCinza),
      var(--escalaCinza),
      var(--escalaCinza),
      0.97
    );
    border-radius: 2px;
    /* border-top-left-radius:0 !important; */
    /* border:1px solid #f00; */
    z-index: 999;
    /* overflow-y:auto; */
    /* overflow-y:auto; */
    /* overflow-x:visible; */
  }

  #slotFiltro > button {
    position: absolute;
    top: 0;
    right: 100%;
    font-size: 20pt;
    width: 2.5rem;
    height: 2.5rem;
    padding-bottom: 0.2rem;
    background: var(--corPrincipal);
    color: var(--corBranco);
    /* border:1px solid var(--corPrincipal); */
    border: none;
    border-radius: 2px !important;
    /* border-top-left-radius: 7px; */
    /* border-bottom-left-radius: 7px; */
    /* background:none; */
    z-index: 1000;
  }

  #slotFiltro > button:hover {
    background: var(--corPrincipalMaisEscura);
    color: var(--corAnaloga2MaisClara);
  }

  #slotFiltro > .btnFecha {
    background: var(--corComplementarMaisEscura);
    color: var(--corPrincipalMaisClara);
    /* box-shadow: -1px 2px 2px var(--corFundo4); */
  }

  #slotFiltro > .btnFecha:hover {
    color: var(--corAnaloga2MaisClara);
    background: var(--corComplementarEscura);
    border-color: var(--corComplementarEscura);
  }

  #slotFiltro > h2 {
    padding-bottom: 1rem;
    /* scrollbar */
  }

  .blocoFechado {
    width: 0 !important;
    padding: 0 !important;
  }

  .areaDeAlertaScroll {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
  }

  .switchToggle {
    --larguraPai: 2rem;
    position: relative;
    display: inline-block;
    width: var(--larguraPai);
    height: calc(var(--larguraPai) / 1.6);
  }

  .switchToggle .slider {
    --margemCima: 5px;
    --margemEsquerda: 0;
    position: absolute;
    cursor: pointer;
    top: var(--margemCima);
    left: 0;
    right: 0;
    bottom: calc(var(--margemCima) * -1);
    background: #c4c4c4;
    transition: 0.4s;
    border-radius: 20px;
  }

  .switchToggle .slider:before {
    --tamanho: calc(var(--larguraPai) * 0.375);
    position: absolute;
    content: '';
    height: var(--tamanho);
    width: var(--tamanho);
    left: calc(var(--tamanho) / 3);
    bottom: calc(var(--tamanho) / 3);
    background: #fc6836;
    transition: 0.4s;
    border-radius: 50%;
  }

  .switchToggle input:checked + .slider {
    /* background: var(--corPrincipalEscura); */
  }

  .switchToggle input:checked + .slider:before {
    background: #1a784a;
  }

  .switchToggle input:checked + .slider:before {
    transform: translateX(var(--tamanho));
  }

  .display-switch {
    display: none;
  }

  .grid {
    display: grid;
  }

  .grid-template-columns-1 {
    grid-template-columns: auto auto auto;
    grid-gap: 10px;
  }

  .grid-template-columns-2 {
    grid-template-columns: 1fr 1fr;
  }

  .separador {
    margin-top: 5px;
    margin-bottom: 5px;
    border: 1px solid #bdbdbd;
    width: 100%;
  }

  .separadorSubPanel {
    margin-top: 5px;
    margin-left: 50px;
    margin-bottom: 5px;
    border: 1px solid #bdbdbd;
    width: 90%;
  }

  .formes {
    width: 100%;
    display: flex;
    height: 150px;
    background: #acacac;
  }

  .panel {
    display: flex;
    width: 60vh;
    justify-items: left;
    align-items: flex-start;
    align-content: flex-start;
  }

  .subpanel {
    display: flex;
    width: 45vh;
    text-align: left;
    align-items: center;
  }

  /* Extra small devices (phones, 600px and down) */

  @media only screen and (max-width: 600px) {
    .ant-popover-placement-bottom,
    .ant-popover-placement-bottomLeft,
    .ant-popover-placement-bottomRight {
      width: 90%;
    }
    .ant-collapse-icon-position-end
      > .ant-collapse-item
      > .ant-collapse-header
      .ant-collapse-arrow {
      left: 285px;
    }
    .panel {
      display: flex;
      width: 40vh;
      justify-items: left;
      align-items: flex-start;
      align-content: flex-start;
    }

    .grid-template-columns-1 {
      grid-template-columns: 1fr 1fr;
      grid-gap: 10px;
    }
  }

  /* Small devices (portrait tablets and large phones, 600px and up) */

  @media only screen and (min-width: 600px) {
    .panel {
      display: flex;
      width: 40vh;
      justify-items: left;
      align-items: flex-start;
      align-content: flex-start;
    }
  }

  /* Medium devices (landscape tablets, 768px and up) */

  @media only screen and (min-width: 768px) {
    .panel {
      float: left;
      display: flex;
      width: 40vh;
      justify-items: left;
      align-items: flex-start;
      align-content: flex-start;
    }
  }

  /* Large devices (laptops/desktops, 992px and up) */

  @media only screen and (min-width: 992px) {
    .panel {
      display: flex;
      width: 45vh;
      justify-items: left;
      align-items: flex-start;
      align-content: flex-start;
    }
  }

  /* Extra large devices (large laptops and desktops, 1200px and up) */

  @media only screen and (min-width: 1200px) {
    .panel {
      display: flex;
      width: 50vh;
      justify-items: left;
      align-items: flex-start;
      align-content: flex-start;
    }
  }
`;

