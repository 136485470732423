/* eslint-disable */
import styled from 'styled-components';

export const ModalBodyContainer = styled.div`
  .previusPageIcon {
    border-radius: 50%;
    position: absolute;
    left: -15px;
    top: 122px;
    background-color: white !important;
  }

  .nextPageIcon {
    border-radius: 50%;
    position: absolute;
    right: -15px;
    top: 122px;
    color: black;
    background-color: white !important;
  }

  .previusPageIcon:hover,
  .nextPageIcon:hover {
    border: 1px solid green;
    color: green;
  }

  .ant-select-selector:hover {
    border-color: green !important;
  }

  .ant-picker-range:hover {
    border-color: green !important;
  }

`;

export const GenPdfButton = styled.div`
  
  display: flex;
  padding-top: 5px;
  padding-bottom: 10px;
  flex-grow: 1;

  .btnGerarRel {
    padding: 5px;
    background: white;
    border: 1px solid rgb(26, 120, 74);
    color: green;
  }

  .btnGerarRel:hover {
    border: 1px solid rgb(26,120,74);
    color: green;
  }

  .btnGerarCSV {
    padding: 5px;
    margin-left: 2%;
    background: white;
    border: 1px solid rgb(26, 120, 74);
    color: green;
  }

  .btnGerarCSV:hover {
    border: 1px solid rgb(26,120,74);
    color: green;
  }

  .btnForm {
    color: #fff !important;
    width: 200px;
    height: 40px;
    border: none;
    border-radius: 50px;
    font-weight: 500;
    font-size: 1em;
  }

  .btnSearch {
    background: #1a784a !important;
  }

  .btnClear {
    background: #fc6836 !important;
  }

  @media (max-width: 600px) {
    .ant-row-center {
      flex-direction: column !important;
    }

    .ant-col-8 {
      max-width: 100% !important;
    }
  }
`;

