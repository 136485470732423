import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  getAllPageSuccess,
  getAllSuccess,
  createSuccess,
  updateSuccess,
  deleteSuccess,
  getByIdSuccess,
  error,
  getAllPage,
} from './actions';

import { ServiceTipoVeiculo } from '~/services/modulos/tipoVeiculo';
import { createSagaReutils, createAllTakeLastDefault } from '~/utils/reutils';
import ACTION from '../actionTypes';

const ACOES_DEFULT = createSagaReutils(
  '/tipoVeiculo',
  ServiceTipoVeiculo,
  getAllPage,
  getAllSuccess,
  createSuccess,
  updateSuccess,
  deleteSuccess,
  getByIdSuccess,
  error
);

function* listPageableSaga({ params }) {
  try {
    const list = yield call(ServiceTipoVeiculo.getAllPageable, params);
    yield put(getAllPageSuccess(list));
  } catch (e) {
    yield put(error(e));
  }
}

export default all([
  ...createAllTakeLastDefault({ modulo: 'tipo_veiculo', ACOES: ACOES_DEFULT }),
  takeLatest(ACTION.TIPO_VEICULO_GET_SEARCH_PAGE, listPageableSaga),
]);
