import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Container } from './styles';

// import ViaturaAmarelo from '../../../imgs/viatura_amarelo.svg';
import PontoBaseCerca from '../../../imgs/ponto_base_cerca.svg';
import OcorrenciaIcon from '../../../imgs/ocorrence_icon.svg';

import { useLegendInfo } from 'hooks/monitoring/useLegendInfo';
import { loadVehicleIconsMap } from 'utils/monitoring_functions';

const stylesProps = {
  width: 20,
  height: 20,
};

function LegendaMapa() {
  const { data, mapOccurrences } = useLegendInfo();

  const [hide, setHide] = useState(false);

  const handleHideBar = useCallback(() => {
    setHide(prev => !prev);
  }, []);

  const numEfetivo = useMemo(() => data.length, [data.length]);

  const numEfetivoConectado = useMemo(
    () =>
      data.filter(e => e !== undefined && e.properties.statusConexaoViatura)
        .length,

    [data]
  );

  const numEfetivoError = useMemo(
    () => data.filter(e => e !== undefined && e.properties.error).length,

    [data]
  );

  const numOcorrencias = useMemo(
    () => mapOccurrences.current.filter(occ => occ.marker.map).length,

    [mapOccurrences.current]
  );

  useEffect(() => {}, [handleHideBar, hide]);

  return (
    <Container>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        {hide ? (
          <PlusOutlined
            style={{
              color: 'white',
              marginTop: '10px',
              marginRight: '10px',
              height: '3px',
            }}
            onClick={handleHideBar}
          />
        ) : (
          <MinusOutlined
            style={{
              color: 'white',
              marginRight: '5px',
              height: '3px',
              marginTop: '5px',
            }}
            onClick={handleHideBar}
          />
        )}
      </div>

      <ul className={hide ? 'hideBar' : ''}>
        <li key="ViaturaAzul">
          <img
            src={
              loadVehicleIconsMap(
                '',
                'DISPONÍVEL',
                false,
                false,
                false,
                true,
                false
              ).defaultIcon
            }
            alt="marker"
            style={stylesProps}
            className="viatura"
          />
          - Disponível
        </li>

        <li key="ViaturaLaranja">
          <img
            src={
              loadVehicleIconsMap(
                '',
                'DESPACHADO',
                false,
                false,
                false,
                true,
                false
              ).defaultIcon
            }
            alt="marker"
            style={stylesProps}
            className="viatura"
          />
          - Despachada
        </li>

        <li key="ViaturaAmareloQueimado">
          <img
            src={
              loadVehicleIconsMap(
                '',
                'EM ROTA',
                false,
                false,
                false,
                true,
                false
              ).defaultIcon
            }
            alt="marker"
            style={stylesProps}
            className="viatura"
          />
          - Em Rota
        </li>

        <li key="ViaturaVerde">
          <img
            src={
              loadVehicleIconsMap(
                '',
                'CHEGOU NO LOCAL',
                false,
                false,
                false,
                true,
                false
              ).defaultIcon
            }
            alt="marker"
            style={stylesProps}
            className="viatura"
          />
          - Chegou no local
        </li>

        <li key="ViaturaLaranjaClaro">
          <img
            src={
              loadVehicleIconsMap(
                '',
                'EM TRANSPORTE',
                false,
                false,
                false,
                true,
                false
              ).defaultIcon
            }
            alt="marker"
            style={stylesProps}
            className="viatura"
          />
          - Em Transporte
        </li>

        <li key="ViaturaCinza">
          <img
            src={
              loadVehicleIconsMap(
                '',
                'ALIMENTAÇÃO',
                false,
                false,
                false,
                true,
                false
              ).defaultIcon
            }
            alt="marker"
            style={stylesProps}
            className="viatura"
          />
          - Fora de serviço
        </li>

        <li key="ViaturaVioleta">
          <img
            src={
              loadVehicleIconsMap('', null, false, false, false, true, false)
                .defaultIcon
            }
            alt="marker"
            style={stylesProps}
            className="viatura"
          />
          - Desconectada
        </li>

        <li key="ViaturaMarrom">
          <img
            src={
              loadVehicleIconsMap(
                '',
                'SEM_CADASTRO',
                false,
                false,
                false,
                true,
                false
              ).defaultIcon
            }
            alt="marker"
            style={stylesProps}
            className="viatura"
          />
          - Sem cadastro
        </li>

        <li key="ViaturaRosa">
          <img
            src={
              loadVehicleIconsMap('', 'RÁDIO', false, false, false, true, false)
                .defaultIcon
            }
            alt="marker"
            style={stylesProps}
            className="viatura"
          />
          - Rádio
        </li>

        <li key="PontoBaseCerca">
          <img
            src={PontoBaseCerca}
            alt="marker"
            style={{ width: 20, height: 20 }}
            className="viatura"
          />
          - Ponto Base
        </li>

        <li key="Occurrence">
          <img
            src={OcorrenciaIcon}
            alt="marker"
            style={{ width: 20, height: 20 }}
            className="viatura"
          />
          - Ocorrência
        </li>

        <li key="ViaturaError">
          <img
            src={
              loadVehicleIconsMap(
                '',
                'DISPONÍVEL',
                false,
                false,
                false,
                true,
                true
              ).defaultIcon
            }
            alt="marker"
            style={stylesProps}
            className="viatura"
          />
          - Erro no GPS
        </li>

        <div className="slotRodape">
          <div className="rodape">
            <p>
              <b>Ocorrências:&nbsp;</b>

              {numOcorrencias}
            </p>

            <p>
              <b>Viaturas conectadas:&nbsp;</b>

              {numEfetivoConectado}
            </p>

            <p>
              <b>Viaturas com erro:&nbsp;</b>

              {numEfetivoError}
            </p>

            <p>
              <b>Total viaturas:&nbsp;</b>

              {numEfetivo}
            </p>

            <p>
              <i>Fonte: TELTRONIC</i>
            </p>
          </div>
        </div>
      </ul>
    </Container>
  );
}

export default LegendaMapa;

