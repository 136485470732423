import React, { useCallback, useState } from 'react';
import { Form, Row, Col, Space, InputNumber, Slider } from 'antd';
import { toast } from 'react-toastify';

import moment from 'moment';
import { Container } from './styles';
import iconRaio from '../../../../imgs/icon-raio.svg';

import Button from '../../../button';
import { useHistoryFormInfo } from 'hooks/monitoring/useHistoryFormInfo';
import { useHistoryResetFunctions } from 'hooks/history/useHistoryResetFunctions';
import { useHistoryRaioPonto } from 'hooks/history/useHistoryRaioPonto';
import { useHistoryOriginPoint } from 'hooks/history/useHistoryOriginPoint';
import { useHistoryDataType } from 'hooks/history/useHistoryDataType';
import { useHistorySeachByRadiusAll } from 'hooks/history/useHistorySeachByRadiusAll';
import debounce from 'lodash.debounce';
import './styles.css';

const config = {
  corFill: {
    padrao: '#369',
    inicio: '#004427',
    fim: '#963',
  },
  corBorda: '#eee',
  corPing: '#aaa',
  corLinha: '#993',
  raioPadrao: 5,
  difRadius: 2,
  intervaloMaximoSegundos: 5 * 60 * 60,
  raioBusca: {
    raioMinimo: 10,
    raioMaximo: 5000,
  },
};

export interface FormHistoricoRaioProps {
  onVisible: (value: boolean) => void;
}

export function FormHistoryRaioAll({ onVisible }: FormHistoricoRaioProps) {
  const [dtInicio, setDtInicio] = useState('');
  const [dtFim, setDtFim] = useState('');

  const [hrInicio, sethrInicio] = useState('');
  const [hrFim, sethrFim] = useState('');

  const [form] = Form.useForm();
  const { searchByRadiusAll } = useHistorySeachByRadiusAll();

  const { dataType } = useHistoryDataType();
  const { originPoint } = useHistoryOriginPoint();
  const { raioPonto, setRaioPonto } = useHistoryRaioPonto();
  const {
    choosingOrigin,
    setChoosingOrigin,
    setLoadingHistorico,
    resetAllData,
    cleanSelectedAis,
  } = useHistoryResetFunctions();
  const { setDataOccurrences } = useHistoryFormInfo();

  const { raioMinimo, raioMaximo } = config.raioBusca;

  const handleChangeDtInicio = useCallback(
    (value: string) => {
      setDtInicio(value);

      if (!dtFim) {
        setDtFim(value);
      }
    },
    [dtFim]
  );

  const debounceChangeDate = debounce((e: any) => {
    handleChangeDtInicio(e.target.value);
  }, 500);

  const onFinish = useCallback(async () => {
    resetAllData();
    cleanSelectedAis();
    setDataOccurrences([]);
    dataType.current = 'raio';
    // const { data } = values;
    const origem = originPoint;
    const raio = raioPonto;

    if (!origem || !origem.lat || !origem.lng) {
      toast.error('Por favor selecione um ponto de origem');
      return;
    }

    if (!raio || raio <= 0) {
      toast.error('Por favor defina um raio maior que 0');
      return;
    }

    if (!dtInicio || !hrFim || !hrInicio || !dtFim) {
      toast.error('Por favor, preencha a data inicio e fim.');
      return;
    }

    const momentInicio = moment(new Date(`${dtInicio}T${hrInicio}`));
    const momentFim = moment(new Date(`${dtFim}T${hrFim}`));

    let validHours = true;

    if (momentFim.isBefore(momentInicio)) {
      toast.info('Data inválida');
      setLoadingHistorico(false);

      return;
    }

    const secondsDiff = momentFim.diff(momentInicio, 'seconds');

    if (secondsDiff > config.intervaloMaximoSegundos) {
      toast.warn('Intervalo máximo de pesquisa é de 5 horas');
      validHours = false;
      setLoadingHistorico(false);
    }

    if (validHours) {
      const dataInicio = momentInicio.format('YYYY-MM-DDTHH:mm:ss');
      const dataFim = momentFim.format('YYYY-MM-DDTHH:mm:ss');

      try {
        await searchByRadiusAll({
          dataInicio,
          dataFim,
          origem,
          raio,
          groups: [],
        });
        onVisible(false);
      } catch (err) {
        toast.info('Consulta sem resultado');
      } finally {
        setLoadingHistorico(false);
      }
    }
  }, [
    resetAllData,
    cleanSelectedAis,
    setDataOccurrences,
    originPoint,
    raioPonto,
    dtInicio,
    dtFim,
    hrFim,
    hrInicio,
    setLoadingHistorico,
    searchByRadiusAll,
    onVisible,
  ]);

  const resetarCampos = () => {
    setChoosingOrigin(false);
    resetAllData();
    cleanSelectedAis();
    setRaioPonto(raioMinimo);
    form.resetFields();
    setDtInicio('');
    setDtFim('');
    sethrFim('');
    sethrInicio('');
  };

  return (
    <Container>
      <Col span={3}>
        <Button
          type="button"
          icon={
            <img
              src={iconRaio}
              alt="marker"
              style={{ width: 20, height: 20, margin: 5 }}
              className="marcForaAis"
            />
          }
          onClick={() => {
            setChoosingOrigin((prev: boolean) => !prev);
            if (choosingOrigin) {
              resetarCampos();
            } else {
              dataType.current = 'raio';
            }
          }}
        >
          {choosingOrigin ? 'Cancelar seleção' : 'Marcar Local'}
        </Button>
      </Col>

      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        // onFinishFailed={onFinishFailed}
      >
        <Row>
          {originPoint && (
            <div className="slider">
              <Col span={10}>
                <Slider
                  min={raioMinimo}
                  max={raioMaximo}
                  value={raioPonto}
                  // tooltipVisible={false}
                  onChange={e => {
                    setRaioPonto(e);
                  }}
                />
              </Col>

              <Col span={7}>
                <Form.Item>
                  <InputNumber
                    name="raio"
                    disabled
                    value={raioPonto}
                    formatter={value => `${value} m`}
                    parser={value => {
                      try {
                        return value?.trim().replace(' m', '');
                      } catch (err) {
                        return 0;
                      }
                    }}
                    min={20}
                    max={500}
                    onChange={e => {
                      setRaioPonto(e);
                    }}
                  />
                </Form.Item>
              </Col>
            </div>
          )}
        </Row>
        <br />
        <Row>
          <Col offset={1}>
            <Space direction="vertical" size={12}>
              {/* <Form.Item
                name="data"
                label="Período (Inical e final)"
                rules={[
                  { required: false, message: 'Por favor selecione a data!' },
                ]}
              > */}
              {/* <Datetime locale="de" /> */}
              <div className="ant-col ant-form-item-label">
                <span className="ant-form-item-required">Data inicial:</span>
              </div>

              <span>
                <input
                  style={{
                    height: 30,
                    padding: 5,
                    borderRadius: 10,
                    border: '1px solid #d9d9d9',
                  }}
                  type="date"
                  id="dtInicio"
                  name="dtInicio"
                  // value={dtInicio}
                  onChange={debounceChangeDate}
                />

                <input
                  style={{
                    height: 30,
                    padding: 5,
                    borderRadius: 10,
                    border: '1px solid #d9d9d9',
                  }}
                  type="time"
                  id="hrInicio"
                  name="hrInicio"
                  value={hrInicio}
                  step="1"
                  onChange={e => {
                    sethrInicio(e.target.value);
                  }}
                />
              </span>

              <div className="ant-col ant-form-item-label">
                <span className="ant-form-item-required">Data final:</span>
              </div>

              <span>
                <input
                  style={{
                    height: 30,
                    padding: 5,
                    borderRadius: 10,
                    border: '1px solid #d9d9d9',
                  }}
                  type="date"
                  id="meeting-time2"
                  name="meeting-time"
                  step="1"
                  value={dtFim}
                  onChange={e => {
                    setDtFim(e.target.value);
                  }}
                />

                <input
                  style={{
                    height: 30,
                    padding: 5,
                    borderRadius: 10,
                    border: '1px solid #d9d9d9',
                  }}
                  type="time"
                  id="meeting-time2"
                  name="meeting-time"
                  step="1"
                  value={hrFim}
                  onChange={e => {
                    sethrFim(e.target.value);
                  }}
                />
              </span>

              {/* <RangePicker format="DD/MM/YYYY HH:mm:ss" showTime /> */}
              {/* </Form.Item> */}
            </Space>
          </Col>
        </Row>

        <br />
        <Row justify="center" className="slotBotoes">
          <div className="grid-button">
            <Col>
              <Form.Item>
                <Button type="submit">Confirmar</Button>
              </Form.Item>
            </Col>

            <Col>
              <Form.Item>
                <Button
                  type="reset"
                  buttonStyle="btn--secondary"
                  onClick={resetarCampos}
                >
                  Limpar
                </Button>
              </Form.Item>
            </Col>
          </div>
        </Row>
      </Form>
    </Container>
  );
}
