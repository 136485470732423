// @ts-nocheck
import React, { useCallback, useEffect, useState } from 'react';

import { Spin } from 'antd';

import { KEY_VIN_ROLE_MAP } from '../../../../utils/mapGroupsMoniByRole';
import { ServiceVinculada } from 'services/modulos/serviceVinculada';
import {
  AgenciesProps,
  GroupsProps,
  GroupsFiltersProps,
} from 'interfaces/FiltersMapContext';
import { useFiltroAgencias } from 'hooks/monitoring/useFiltroAgencias';
import { useAuthData } from 'hooks/auth/useAuthData';
import { useHistoryResetFunctions } from 'hooks/history/useHistoryResetFunctions';
import { useFiltersMapSelectedAis } from 'hooks/filtersMap/useFiltersMapSelectedAis';
import { useFiltersMapAgencies } from 'hooks/filtersMap/useFiltersMapSelectedAgencies';
import { useFiltersMapGroups } from 'hooks/filtersMap/useFiltersMapGroups';

export default function FiltroAgencias() {
  const { setGroupsFilters, groupsFilters } = useFiltersMapGroups();

  const { setSelectedAgencies } = useFiltersMapAgencies();
  const { onSelectedAis } = useFiltersMapSelectedAis();

  const { data } = useAuthData();
  const { roles } = data;
  const { setData } = useFiltroAgencias();

  const { resetAllData, cleanSelectedAis } = useHistoryResetFunctions();

  const [agencies, setAgencies] = useState<AgenciesProps[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (agencies.length > 0) {
      let updated = false;
      agencies.forEach(v => {
        const exists = groupsFilters.agencies.find(g => g.nome === v.nome);
        if (exists && exists.checked !== v.checked) {
          updated = true;
        }
      });
      if (updated) {
        const vinculadasChecked = agencies.filter(v => v.checked);
        if (!vinculadasChecked.length) {
          cleanSelectedAis();
          setGroupsFilters((prev: GroupsFiltersProps) => {
            const groupsWithoutChecked: GroupsProps[] = prev.groups.map(
              (g: GroupsProps) => {
                g.checked = false;
                return g;
              }
            );
            return {
              groups: groupsWithoutChecked,
              agencies,
            };
          });
        } else {
          setGroupsFilters((prev: GroupsFiltersProps) => {
            return { ...prev, agencies };
          });
        }
      }
    }
  }, [
    cleanSelectedAis,
    groupsFilters,
    onSelectedAis,
    setData,
    setGroupsFilters,
    setSelectedAgencies,
    agencies,
  ]);

  const loadVinculada = useCallback(async () => {
    try {
      setLoading(true);

      const response = await ServiceVinculada.getAll();

      const vinculadaNew = (response || []).map((vin: AgenciesProps) => ({
        key: vin.id,
        id: vin.id,
        nome: vin.nome,
        checked: Boolean(vin.nome === 'PM') && data.isSecretario,
      }));

      setAgencies(
        vinculadaNew.filter((v: AgenciesProps) =>
          roles?.includes(KEY_VIN_ROLE_MAP[v.nome])
        )
      );
    } catch (e) {
      //
    } finally {
      setLoading(false);
    }
  }, [roles]);

  useEffect(() => {
    loadVinculada();
  }, [loadVinculada, resetAllData]);

  const handleChange = useCallback(
    ({ id, check, nome }: { id: string; check: boolean; nome: string }) => {
      resetAllData();
      setAgencies(prev =>
        prev.map(vin =>
          vin.id === id ? { ...vin, checked: !vin.checked } : vin
        )
      );

      if (check) {
        setSelectedAgencies((prev: AgenciesProps[]) =>
          prev.filter(vin => vin !== nome)
        );

        const list = document.getElementsByClassName(`VIN_${nome}`);

        for (let i = 0; i < list.length; i += 1) {
          list[i].classList.add('ocultoVin');
        }
      } else {
        setSelectedAgencies((prev: AgenciesProps[]) => [...prev, nome]);
        const list = document.getElementsByClassName(`VIN_${nome}`);

        for (let i = 0; i < list.length; i += 1) {
          list[i].classList.remove('ocultoVin');
        }
      }
    },
    [resetAllData, setSelectedAgencies]
  );

  return (
    <Spin spinning={loading}>
      <div className="grid  grid-template-columns-1">
        {agencies.map(vin => (
          <div key={vin.id}>
            <label htmlFor={`vin-${vin.id}`} className="switchToggle">
              <input
                type="checkbox"
                id={`vin-${vin.id}`}
                className="display-switch"
                checked={vin.checked}
                onChange={() => {
                  handleChange({
                    id: vin.id,
                    check: vin.checked,
                    nome: vin.nome,
                  });
                }}
              />

              <span className="slider" />
            </label>
            &nbsp;
            <span>{vin.nome}</span>
          </div>
        ))}
      </div>
    </Spin>
  );
}
