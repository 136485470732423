import React, { useEffect, useCallback, useState } from 'react';
import { Form, Spin, Card, Input, Select, Row, Col, Breadcrumb } from 'antd';
import { useHistory, useParams, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { CloseOutlined, SaveOutlined } from '@ant-design/icons';
// import moment from 'moment';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { PageHeader } from '../../../components/PageHeader';
import { TITLE, BASE_ROLE } from './index';
// import * as ACTIONS from '~/store/modules/veiculo/actions';
import { Container } from './styles';
import { InputAntdCustom } from '~/components/FormAntd/InputAntd';
// import { SelectAddItemAntd } from '~/components/FormAntd/SelectAddItemAntd';
// import { ServicePrefixo } from '~/services/modulos/servicePrefixo';
import { SelectAntd } from '~/components/FormAntd/SelectComponents';
import { ServiceEquipamento } from '~/services/modulos/serviceEquipamento';
import { ServiceTipoEquipamento } from '~/services/modulos/serviceTipoEquipamento';
import { ServiceTipoTecnologia } from '~/services/modulos/serviceTipoTecnologia';
import * as ACTIONS from '~/store/modules/equipamento/actions';

import Button from '../../../components/button';

const SUBTITLE = '';
const { Option } = Select;

export function CadastroEquipamentoForm() {
  const { id } = useParams();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [tipoEquipamentoList, setTipoEquipamentoList] = useState([]);
  const [tipoTecnologiaList, setTipoTecnologiaList] = useState([]);
  const { data, form: entidade } = useSelector(state => state.equipamento);

  // console.log("J", data);

  const getAllTipoEquipamento = useCallback(async () => {
    const dados = await ServiceTipoEquipamento.getAll();
    setTipoEquipamentoList(dados.map(d => ({ key: d.id, value: d.nome })));
  }, []);

  const getAllTipoTecnologia = useCallback(async () => {
    const dados = await ServiceTipoTecnologia.getAll();
    setTipoTecnologiaList(dados.map(d => ({ key: d.id, value: d.empresa })));
  }, []);

  useEffect(() => {
    getAllTipoEquipamento();
    getAllTipoTecnologia();
  }, [getAllTipoEquipamento, getAllTipoTecnologia]);

  useEffect(() => {
    if (id) dispatch(ACTIONS.getById(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (entidade && entidade.id && id) {
      const {
        tipoEquipamento,
        tipoTecnologia,
        identificacao,
        modulo,
      } = entidade;
      form.setFieldsValue({
        tipoEquipamento: tipoEquipamento ? tipoEquipamento.id : '',
        tipoTecnologia: tipoTecnologia ? tipoTecnologia.id : '',
        identificacao,
        modulo,
      });
    }
  }, [form, entidade, id]);

  // const onFinish = useCallback(
  //   async values => {
  //     try {
  //       setLoading(true);
  //       values.ativo = true;
  //       values.tipoTecnologia = {
  //         id: values.tipoTecnologia,
  //       };
  //       values.tipoEquipamento = {
  //         id: values.tipoEquipamento,
  //       };
  //       await ServiceEquipamento.create(values);
  //       // onOk();
  //       form.resetFields();
  //     } catch (err) {
  //       toast.error('Falha ao salvar os dados');
  //     } finally {
  //       setLoading(false);
  //     }
  //   },
  //   [form]
  // );

  const onFinish = useCallback(
    async values => {
      try {
        // console.log(event);
        setLoading(true);

        values.ativo = true;
        values.tipoTecnologia = {
          id: values.tipoTecnologia,
        };
        values.tipoEquipamento = {
          id: values.tipoEquipamento,
        };

        // console.log(data);
        if (id) {
          values.id = id;
          dispatch(ACTIONS.update(values));
        } else {
          const _equipamentoExistente = Object.values(data).filter(
            d => d.identificacao === values.identificacao
          );
          if (_equipamentoExistente.length > 0) {
            toast.error(
              'Já existe equipamento cadastrado com essa identificação'
            );
            return;
          }
          // console.log(values);
          await ServiceEquipamento.create(values);
          // onOk();
          form.resetFields();
          toast.success('Dados salvos com sucesso');
        }
      } catch (err) {
        toast.error('Falha ao salvar os dados');
      } finally {
        setLoading(false);
      }
    },
    [id, dispatch, data, form]
  );

  const handleCancela = useCallback(() => {
    history.push('/equipamentos');
  }, [history]);

  return (
    <Container>
      <Breadcrumb style={{ padding: '10px' }}>
        <Breadcrumb.Item>
          <Link to="/">Página Inicial</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to="/cadastros">Cadastros</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to="/equipamentos">Equipamentos</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Novo Equipamento</Breadcrumb.Item>
      </Breadcrumb>
      <Card className="card" style={{ margin: 15 }}>
        <PageHeader
          title={`${id ? 'Edição' : 'Cadastro'} - ${TITLE}`}
          subTitle={SUBTITLE}
          baseRole={BASE_ROLE}
          activeBackHistory
        />

        <Spin spinning={loading}>
          <Form
            form={form}
            layout="vertical"
            initialValues={{ ativo: true, disponivel: true }}
            onFinish={onFinish}
            // onSubmit={handleSubmit}
            // onFinish={handleSubmit}
          >
            <Row className="rowForm">
              <Col span={10}>
                <Form.Item
                  label="Tipo de equipamento"
                  name="tipoEquipamento"
                  rules={[
                    {
                      required: true,
                      message: 'Por favor, informe o Tipo do Equipamento',
                    },
                  ]}
                >
                  <SelectAntd size="large" data={tipoEquipamentoList} />
                </Form.Item>
              </Col>
              <Col span={10}>
                <Form.Item label="Módulo" name="modulo">
                  <InputAntdCustom size="large" />
                </Form.Item>
              </Col>
            </Row>

            <Row className="rowForm">
              <Col span={10}>
                <Form.Item
                  label="Empresa"
                  name="tipoTecnologia"
                  rules={[
                    {
                      required: true,
                      message: 'Por favor, informe o Tipo da Tecnologia',
                    },
                  ]}
                >
                  <SelectAntd size="large" data={tipoTecnologiaList} />
                </Form.Item>
              </Col>
              <Col span={10}>
                <Form.Item
                  label="IMEI/Nº série"
                  name="identificacao"
                  rules={[
                    {
                      required: true,
                      message: 'Por favor, informe o identificador do aparelho',
                    },
                  ]}
                >
                  <InputAntdCustom size="large" />
                </Form.Item>
              </Col>
            </Row>

            <div className="botoes">
              {/* <button className="botao-salvar" type="submit" name="submit">
                <SaveOutlined />
                Salvar
              </button>
              <button
                className="botao-cancelar"
                type="button"
                name="reset"
                onClick={handleCancela}
              >
                <CloseOutlined />
                Cancelar
              </button> */}
              <Button
                onClick={() => {
                  console.log('clicou');
                }}
                type="button"
                buttonStyle="btn--primary"
                icon={<SaveOutlined />}
                children="Salvar"
              />
              <Button
                onClick={() => {
                  console.log('clicou');
                }}
                type="button"
                buttonStyle="btn--secondary"
                icon={<CloseOutlined />}
                children="Cancelar"
              />
            </div>
          </Form>
        </Spin>
      </Card>
    </Container>
  );
}
