import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import moment from 'moment';
import logo64 from '../../imgs/encoded/brasaosspds.base64';

// export const pdfReport = data => {
//   const win = window.open('', '_blank');
//   pdfMake.vfs = pdfFonts.pdfMake.vfs;

//   const reportTitle = [
//     {
//       image: logo64,
//       width: 179,
//       height: 63,
//       alignment: 'center',
//       margin: [0, 10, 0, 0],
//     },
//     {
//       text: 'Relatório personalizado.\n',
//       bold: true,
//       fontSize: 13,
//       alignment: 'center',
//       margin: [0, 4, 0, 0],
//     },
//     {
//       text: `Segue abaixo a lista com informações de veículos (${data.length}).\n`,
//       bold: false,
//       fontSize: 9,
//       alignment: 'center',
//       margin: [0, 4, 0, 0],
//     },
//     {
//       text:
//         'Dados de posicionamento fornecidos pelas empresas CSBrasil e TELTRONIC\n',
//       bold: false,
//       fontSize: 9,
//       alignment: 'center',
//       margin: [0, 4, 0, 0],
//     },
//   ];

export const pdfReport = data => {
  const win = window.open('', '_blank');
  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  const reportTitle = [
    {
      image: logo64,
      width: 179,
      height: 63,
      alignment: 'center',
      margin: [0, 10, 0, 0],
    },
    {
      text: 'Relatório composição.\n',
      bold: true,
      fontSize: 13,
      alignment: 'center',
      margin: [0, 4, 0, 0],
    },
    {
      text: `Segue abaixo a lista de composição da viatura  (${data.length}).\n`,
      bold: false,
      fontSize: 9,
      alignment: 'center',
      margin: [0, 4, 0, 0],
    },
    {
      text:
        'Dados de historico de composição fornecidos pela empresa Hexagon.\n',
      bold: false,
      fontSize: 9,
      alignment: 'center',
      margin: [0, 4, 0, 0],
    },
  ];

  const results = data.map(c => {
    return [
      { text: c.mdt, fontSize: 9, margin: [0, 1, 0, 1] },
      { text: c.viatura, fontSize: 9, margin: [0, 1, 0, 1] },
      { text: c.ais, fontSize: 9, margin: [0, 1, 0, 1] },
      {
        text: moment(c.dataHoraConexao).format('DD-MM-YYYY  HH:mm:ss'),

        fontSize: 9,
        margin: [0, 1, 0, 1],
      },
      { text: c.comandante, fontSize: 9, margin: [0, 1, 0, 1] },
      { text: c.motorista, fontSize: 9, margin: [0, 1, 0, 1] },
      { text: c.patrulheiro, fontSize: 9, margin: [0, 1, 0, 1] },
    ];
  });

  const details = [
    {
      alignment: 'center',
      margin: [0, 0, 0, 0],
      layout: {
        fillColor(rowIndex) {
          return rowIndex % 2 === 0 ? '#CCCCCC' : null;
        },
      },

      table: {
        headerRows: 1,
        widths: [
          // 'auto',
          'auto',
          'auto',
          'auto',
          'auto',
          'auto',
          'auto',
          'auto',
        ],
        body: [
          [
            { text: 'Equipamento', style: 'tableHeader', fontSize: 9 },
            { text: 'Prefixo', style: 'tableHeader', fontSize: 9 },
            { text: 'AIS', style: 'tableHeader', fontSize: 9 },
            { text: 'Data/Hora Conexão', style: 'tableHeader', fontSize: 9 },
            { text: 'Comandante', style: 'tableHeader', fontSize: 9 },
            // { text: 'Longitude', style: 'tableHeader', fontSize: 9 },
            { text: 'Motorista', style: 'tableHeader', fontSize: 9 },
            { text: 'Patrulheiro', style: 'tableHeader', fontSize: 9 },
          ],
          ...results,
        ],
      },
    },
  ];

  const footer = [
    {
      text: [
        {
          text:
            'Secretaria de Segurança Pública e Defesa Social. Avenida Bezerra de Menezes, 581 - São Gerardo\n CEP 60325-003 . Fortaleza Ceará . Fone: (85) 3101-6501',
        },
      ],
      fontSize: 8,
      margin: [0, 10, 0, 45],
      alignment: 'center',
    },
  ];

  const docDefinitions = {
    // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
    pageMargins: [10, 140, 10, 40],
    pageSize: 'A4',
    alignment: 'center',
    header: [reportTitle],
    content: [details],
    footer: [footer],
  };

  pdfMake.createPdf(docDefinitions).open({}, win);
};
