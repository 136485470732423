import { TrackingHistoryContext } from 'context/tracking_history';
import { useContextSelector } from 'use-context-selector';

export function useHistorySearchedDateSlider() {
  const searchedDateSlider = useContextSelector(
    TrackingHistoryContext,
    context => context.searchedDateSlider
  );

  const setSearchedDataSlider = useContextSelector(
    TrackingHistoryContext,
    context => context.setSearchedDataSlider
  );

  return {
    searchedDateSlider,
    setSearchedDataSlider,
  };
}

