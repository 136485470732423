import { TrackingHistoryContext } from 'context/tracking_history';
import { useContextSelector } from 'use-context-selector';

export function useHistoryDataType() {
  const dataType = useContextSelector(
    TrackingHistoryContext,
    context => context.dataType
  );

  return {
    dataType,
  };
}

