import { Tag } from 'antd';
import { PropTypes } from 'prop-types';
import React from 'react';
import './styles.css';

export const ActionTag = ({ text }) => {
  return (
    <Tag className={`ActionTags ${text}`} key={text}>
      {text}
    </Tag>
  );
};

ActionTag.propTypes = {
  text: PropTypes.string,
};

ActionTag.defaultProps = {
  text: '-',
};
